// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-minus,
.button-plus {
  background-color: #fff;
}
.number-counter {
  border: 1px solid #ccc;
  padding: 13px;
  border-radius: 4px;
  height: 40px;
}
.number-counter input {
  border: none;
  text-align: center;
  padding: 0 !important;
  margin-top: 0 !important;
  appearance: textfield;
}
.count-input::-webkit-outer-spin-button,
.count-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 480px) {
  .number-counter.flx25 {
    flex: 0 0 48%;
    max-width: 48%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/NumberCounter.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;AACxB;AACA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,wBAAwB;EACxB,qBAAqB;AACvB;AACA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE;IACE,aAAa;IACb,cAAc;EAChB;AACF","sourcesContent":[".button-minus,\r\n.button-plus {\r\n  background-color: #fff;\r\n}\r\n.number-counter {\r\n  border: 1px solid #ccc;\r\n  padding: 13px;\r\n  border-radius: 4px;\r\n  height: 40px;\r\n}\r\n.number-counter input {\r\n  border: none;\r\n  text-align: center;\r\n  padding: 0 !important;\r\n  margin-top: 0 !important;\r\n  appearance: textfield;\r\n}\r\n.count-input::-webkit-outer-spin-button,\r\n.count-input::-webkit-inner-spin-button {\r\n  -webkit-appearance: none;\r\n  margin: 0;\r\n}\r\n\r\n@media screen and (max-width: 480px) {\r\n  .number-counter.flx25 {\r\n    flex: 0 0 48%;\r\n    max-width: 48%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
