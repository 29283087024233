import React, { useEffect, useState } from "react";
import CustomDropdown from "./CustomDropdown";
import "../components/Filter.css";
import MultiSelect from "./MultiSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";

const Filter = ({
  apiCall,
  activeTab,
  filters,
  agents,
  onClickFilter,
  handleTabClick,
  selectedFilt,
  filterVal,
  filterValue,
  setFilterValue,
  setSelectedFilterLable,
  clearFilterData,
  setClearFilterData,
}) => {
  const getValueObject = (dataArray, targetValue) => {
    return dataArray.find((item) => item.value === targetValue);
  };
  const [selectedFilter, setSelectedFilter] = useState(
    selectedFilt != "" ? selectedFilt : filters[0]
  );
  
  let updatedFilter='';
  var oldFilter = {};
  var getOldFilter = localStorage.getItem('allfilteroption');
  var oldFilterVal = getOldFilter ? JSON.parse(getOldFilter) : {};
  var currentTabFilterVal = oldFilterVal[activeTab] ? oldFilterVal[activeTab] : "";
  const [filterOption, setFilterOption] = useState(currentTabFilterVal!='' ? currentTabFilterVal : {
    "activetab": "",
    "filterValue": "",
    "filterLabel": "",
    "customStartDate": "",
    "customEndDate":"",
    "selectedFilter":"",
    "setSelectedStatus":""
  });

  //const [filterValue, setFilterValue] = useState("");

  const [dateRange, setDateRange] = useState([null, null]);
  
  const [startDate, endDate] = dateRange;
  const [dateRangeTravel, setDateRangeTravel] = useState([null, null]);
  const [startDateTravel, endDateTravel] = dateRangeTravel;
  const leadsVia = [
    { label: "Select Option", value: "" },
    { label: "Package", value: "package" },
    { label: "Autobuy", value: "autobuy" },
    { label: "Manual", value: "manully" },
    { label: "Website", value: "website" },
  ];
  const statusOptions = [
    { label: "Select Option", value: "" },
    { label: "Booked", value: "booked" },
    { label: "Advance Received", value: "ar" },
    { label: "Full Payment Received", value: "fpr" },
    { label: "New", value: "new" },
    { label: "Followup", value: "followup" },
    { label: "No Response", value: "No Response" },
    { label: "Junk", value: "Junk" },
    { label: "Irrelevant", value: "Irrelevant" },
    { label: "Not Interested", value: "Not Interested" },
    { label: "Hot", value: "hot" },
  ];
  const timeOptions = [
    { label: "Select Option", value: "" },
    { label: "Today", value: [moment().format("YYYY-MM-DD")] },
    {
      label: "Yesterday",
      value: [moment().subtract(1, "days").format("YYYY-MM-DD")],
    },
    {
      label: "Last 7 Days",
      value: [
        moment().subtract(7, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    },
    {
      label: "Last 30 Days",
      value: [
        moment().subtract(30, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    },
    {
      label: "This Month",
      value: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    },
    {
      label: "Custom",
      value: dateRange,
    },
  ];
  const TravelDateimeOptions = [
    { label: "Select Option", value: "" },
    { label: "Today", value: [moment().format("YYYY-MM-DD")] },
    {
      label: "Tommorow",
      value: [moment().add(1, "days").format("YYYY-MM-DD")],
    },
    {
      label: "Next 7 Days",
      value: [
        moment().format("YYYY-MM-DD"),
        moment().add(7, "days").format("YYYY-MM-DD"),
      ],
    },
    {
      label: "Next 30 Days",
      value: [
        moment().format("YYYY-MM-DD"),
        moment().add(30, "days").format("YYYY-MM-DD"),
      ],
    },
    {
      label: "This Month",
      value: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    },
    {
      label: "Custom",
      value: dateRangeTravel,
    },
  ];
  const travelDestination = ["Goa", "Mumbai", "Punjab", "Shimla", "Panji"];
  const travelDuration = ["1 Days", "2 Days", "3 Days", "4 Days", "5 Days"];
  const [selectedLeadVia, setSelectedLeadVia] = useState(
    leadsVia.find((option) => option.value === "Package")
  );
  const [selectedStatus, setSelectedStatus] = useState(
    statusOptions.find((option) => option.value === "Booked")
  );
  const [selectedLeadViaLabel, setSelectedLeadViaLabel] = useState(
    filterVal != "" ? getValueObject(leadsVia, filterVal) : leadsVia[0]
  );
  const [selectedStatusLabel, setSelectedStatusLabel] = useState(
    filterVal != "" ? getValueObject(statusOptions, filterVal) : statusOptions[0]
  );
  const [selectedLeadReceivedDateLabel, setSelectedLeadReceivedDateLabel] =
    useState(
      filterVal != "" ? getValueObject(timeOptions, filterVal) : timeOptions[0]
    );

  const [selectedLeadTravelDateLabel, setSelectedLeadTravelDateLabel] =
    useState(
      filterVal != "" ? getValueObject(timeOptions, filterVal) : timeOptions[0]
    );
  const [selectedLeadReceivedDate, setSelectedLeadReceivedDate] = useState(
    timeOptions[0].value
  );
  const [selectedLeadTravelDate, setSelectedLeadTravelDate] = useState(
    timeOptions[0].value
  );

  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);
    if (selectedOption.label === "Agent" && selectedAgent === undefined) {
      setFilterValue(agents[0].value);
    } else {
      setFilterValue("");
    }
  };
  const [selectedAgent, setSelectedAgent] = useState(
    filterVal ? getValueObject(agents, filterVal) : agents[0]
  );

  useEffect(() => {
    if (clearFilterData) {
       //handleClearFilterButtonClick();
      setClearFilterData(false);
    }
  }, [clearFilterData]);

  const getFriendlyFilterName = (filterValue) => {
    const filterNameMap = {
      leadReceivedDate: "Lead Received Date",
      leadTravelDate: "Lead Travel Date",
      agent: "Agent",
      email: "Email",
      status: "Status",
      leadId: "Lead ID",
      phone: "Phone",
      leadvia: "Lead Via",
    };

    return filterNameMap[filterValue] || filterValue;
  };
  const handleGoButtonClick = () => {
    updatedFilter = {...filterOption,
    "activetab": "",
    "filterValue": "",
    "filterLabel": "",
    "customStartDate": "",
    "customEndDate":"",
    "selectedFilter":"",
    "setSelectedStatus":"",
  }
  setFilterOption(updatedFilter);
    if (selectedFilter.value === "leadReceivedDate") {
      if (selectedLeadReceivedDateLabel.label === "Custom") {
        if (dateRange[0] && dateRange[1]) {
          onClickFilter(selectedFilter, [
            moment(dateRange[0]).format("YYYY-MM-DD"),
            moment(dateRange[1]).format("YYYY-MM-DD"),
          ]);
          updatedFilter = {
            ...filterOption,
            "selectedFilter":selectedFilter,
            "customStartDate":dateRange[0],
            "customEndDate":dateRange[1],
            "filterLabel":selectedLeadReceivedDateLabel.label,
            "filterValue":([
              moment(dateRange[0]).format("YYYY-MM-DD"),
              moment(dateRange[1]).format("YYYY-MM-DD"),
            ])  
          };
          var getoldfilter = localStorage.getItem('allfilteroption');
          if(getoldfilter){
            oldFilter = JSON.parse(getoldfilter);
          }
          oldFilter[activeTab] = updatedFilter;
          localStorage.setItem("allfilteroption", JSON.stringify(oldFilter));

        } else {
          toast.error(
            "Please select both start and end dates for the custom range."
          );
        }
      } else {
        onClickFilter(selectedFilter, selectedLeadReceivedDateLabel.value);
        updatedFilter = {
          ...filterOption,
          "selectedFilter":selectedFilter,
          "filterLabel":selectedLeadReceivedDateLabel.label,
          "filterValue":selectedLeadReceivedDateLabel.value  
        };
        var getoldfilter = localStorage.getItem('allfilteroption');
        if(getoldfilter){
          oldFilter = JSON.parse(getoldfilter);
        }
        oldFilter[activeTab] = updatedFilter;
        localStorage.setItem("allfilteroption", JSON.stringify(oldFilter));
      }
    } else if (selectedFilter.value === "leadTravelDate") {
      if (selectedLeadTravelDateLabel.label === "Custom") {
        if (dateRangeTravel[0] && dateRangeTravel[1]) {
          onClickFilter(selectedFilter, [
            moment(dateRangeTravel[0]).format("YYYY-MM-DD"),
            moment(dateRangeTravel[1]).format("YYYY-MM-DD"),
          ]);
          updatedFilter = {
            ...filterOption,
            "customStartDate":moment(dateRangeTravel[0]).format("YYYY-MM-DD"),
            "customEndDate":moment(dateRangeTravel[1]).format("YYYY-MM-DD"),
            "filterLabel":selectedLeadTravelDateLabel.label,
            "selectedFilter":selectedFilter,
            "filterValue":([
              moment(dateRangeTravel[0]).format("YYYY-MM-DD"),
              moment(dateRangeTravel[1]).format("YYYY-MM-DD"),
            ]) 
          };
          var getoldfilter = localStorage.getItem('allfilteroption');
          if(getoldfilter){
            oldFilter = JSON.parse(getoldfilter);
          }
          oldFilter[activeTab] = updatedFilter;
          localStorage.setItem("allfilteroption", JSON.stringify(oldFilter));
          
        } else {
          toast.error(
            "Please select both start and end dates for the custom range."
          );
        }
      } else {
        onClickFilter(selectedFilter, selectedLeadTravelDateLabel.value);
        updatedFilter = {
          ...filterOption,
          "filterLabel":selectedLeadTravelDateLabel.label,
          "filterValue":selectedLeadTravelDateLabel.value,
          "selectedFilter":selectedFilter
        };
        var getoldfilter = localStorage.getItem('allfilteroption');
        if(getoldfilter){
          oldFilter = JSON.parse(getoldfilter);
        }
        oldFilter[activeTab] = updatedFilter;
        localStorage.setItem("allfilteroption", JSON.stringify(oldFilter));
      }
    }else if (selectedFilter.value === "agent") {
      onClickFilter(selectedFilter, filterValue);
      updatedFilter = {
        ...filterOption,
        "selectedFilter":selectedFilter,
        "filterValue":filterValue,
        "selectedAgent":selectedAgent, 
      };
      var getoldfilter = localStorage.getItem('allfilteroption');
      if(getoldfilter){
        oldFilter = JSON.parse(getoldfilter);
      }
      oldFilter[activeTab] = updatedFilter;
      localStorage.setItem("allfilteroption", JSON.stringify(oldFilter));
    }
    else if (selectedFilter.value === "leadvia") {
      onClickFilter(selectedFilter, filterValue);
      updatedFilter = {
        ...filterOption,
        "selectedFilter":selectedFilter,
        "filterValue":filterValue,
        "selectedLeadViaLabel":selectedLeadViaLabel, 
      };
      var getoldfilter = localStorage.getItem('allfilteroption');
      if(getoldfilter){
        oldFilter = JSON.parse(getoldfilter);
      }
      oldFilter[activeTab] = updatedFilter;
      localStorage.setItem("allfilteroption", JSON.stringify(oldFilter));
    } 
    else if (
      selectedFilter.value === "destination" &&
      filterValue.length < 3
    ) {
      toast.error("Please enter at least 3 characters to search....");
    } else if (filterValue.length === 0) {
      toast.error(
        `${getFriendlyFilterName(selectedFilter.value)} cannot be blank`
      );
    } else {
      onClickFilter(selectedFilter, filterValue);
      updatedFilter = {
        ...filterOption,
        "selectedFilter":selectedFilter,
        "filterValue":filterValue,
        "setSelectedStatus":selectedStatusLabel, 
      };
      var getoldfilter = localStorage.getItem('allfilteroption');
      if(getoldfilter){
        oldFilter = JSON.parse(getoldfilter);
      }
      oldFilter[activeTab] = updatedFilter;
      localStorage.setItem("allfilteroption", JSON.stringify(oldFilter));
    }
  };
  const handleGoButtonClickApi = () => {
    var getoldfilter = localStorage.getItem('allfilteroption');
    if(getoldfilter){
      oldFilter = JSON.parse(getoldfilter);
      var currenttabfilter = oldFilter[activeTab];  
      if (currenttabfilter['selectedFilter'].value === "leadReceivedDate") {
        if (currenttabfilter['filterLabel'] === "Custom") {
          if (currenttabfilter['filterValue'][0] && currenttabfilter['filterValue'][1]) {
          onClickFilter(currenttabfilter['selectedFilter'], [
            moment(currenttabfilter['filterValue'][0]).format("YYYY-MM-DD"),
            moment(currenttabfilter['filterValue'][1]).format("YYYY-MM-DD"),
          ]);
        } else {
          toast.error(
            "Please select both start and end dates for the custom range."
          );
        }
      } else {
        onClickFilter(currenttabfilter['selectedFilter'], currenttabfilter['filterValue']);
      }
    } else if (currenttabfilter['selectedFilter'].value === "leadTravelDate") {
      if (currenttabfilter['filterLabel'] === "Custom") {
        if (currenttabfilter['filterValue'][0] && currenttabfilter['filterValue'][1]) {
          onClickFilter(currenttabfilter['selectedFilter'], [
            moment(currenttabfilter['filterValue'][0]).format("YYYY-MM-DD"),
            moment(currenttabfilter['filterValue'][1]).format("YYYY-MM-DD"),
          ]);
        } else {
          toast.error(
            "Please select both start and end dates for the custom range."
          );
        }
      } else {
        onClickFilter(currenttabfilter['selectedFilter'], currenttabfilter['filterValue']);
      }
    }else if (currenttabfilter['selectedFilter'].value === "agent") {
      onClickFilter(currenttabfilter['selectedFilter'], currenttabfilter['filterValue']);
      handleAgentSelect(currenttabfilter['selectedAgent'])
    }
    else if (currenttabfilter['selectedFilter'].value === "leadvia") {
      onClickFilter(currenttabfilter['selectedFilter'], currenttabfilter['filterValue']);
      handleLeadViaSelect(currenttabfilter['selectedLeadViaLabel'])
    }
    else if (
      selectedFilter.value === "destination" &&
      currenttabfilter['filterValue'].length < 3
    ) {
      toast.error("Please enter at least 3 characters to search....");
    } else if (currenttabfilter['filterValue'].length === 0) {
      toast.error(
        `${getFriendlyFilterName(selectedFilter.value)} cannot be blank`
      );
    } else {
      onClickFilter(currenttabfilter['selectedFilter'], currenttabfilter['filterValue']);
    }
  }
  };
  useEffect(() => { 
    handleClearFilterButtonClick();
  },[activeTab])
  useEffect(() => { 
    setSelectedFilter(filters[0]);
    var getoldfilter = localStorage.getItem('allfilteroption');
    if(getoldfilter){
      oldFilter = JSON.parse(getoldfilter);
      var currenttabfilter = oldFilter[activeTab];
      if(currenttabfilter && currenttabfilter['filterValue']){
        if(currenttabfilter['selectedFilter']){
          setSelectedFilter(currenttabfilter['selectedFilter']);
          setFilterValue(currenttabfilter['filterValue']);
          handleStatusSelect(currenttabfilter['setSelectedStatus'])
          if (currenttabfilter['selectedFilter'].value === "leadReceivedDate") {
            if (currenttabfilter['filterLabel'] === "Custom") {
              if (currenttabfilter['filterValue'][0] && currenttabfilter['filterValue'][1]) {
                setDateRange([
                  new Date(currenttabfilter["customStartDate"]),
                  new Date(currenttabfilter["customEndDate"]),
                ]);
              }
              setSelectedLeadReceivedDateLabel({
                label: currenttabfilter["filterLabel"],
                value: currenttabfilter['filterValue'],
              });
            }
            else{
              handleLeadReceivedDateChange({
                label: currenttabfilter["filterLabel"],
                value: currenttabfilter['filterValue'],
              });
            }
          }    
          else if (currenttabfilter['selectedFilter'].value === "leadTravelDate") {
            if (currenttabfilter['filterLabel'] === "Custom") {
              if (currenttabfilter['filterValue'][0] && currenttabfilter['filterValue'][1]) {
                setDateRangeTravel([
                  new Date(currenttabfilter["customStartDate"]),
                  new Date(currenttabfilter["customEndDate"]),
                ]);
              }
              setSelectedLeadTravelDateLabel({
                label: currenttabfilter["filterLabel"],
                value: currenttabfilter['filterValue'],
              });
            }
            else{
              handleLeadTravelDateChange({
                label: currenttabfilter["filterLabel"],
                value: currenttabfilter['filterValue'],
              })
            }
          }
          handleGoButtonClickApi(); 
        }
      }
    } 
  }, [activeTab]);

  const handleAgentSelect = (selectedOption) => {
    setSelectedAgent(selectedOption);
    setFilterValue(selectedOption.value);
  };

  const handleStatusSelect = (selectedOption) => {
    setSelectedStatus(selectedOption);
    setSelectedStatusLabel(selectedOption);
    setFilterValue(selectedOption.value);
    setSelectedFilterLable(selectedOption.label);
  };

  const handleLeadViaSelect = (selectedOption) => {
    setSelectedLeadVia(selectedOption);
    setSelectedLeadViaLabel(selectedOption);
    setFilterValue(selectedOption.value);
    setSelectedFilterLable(selectedOption.label);
  };
  const handleLeadReceivedDateChange = (date) => {
    setSelectedLeadReceivedDateLabel(date);
    setSelectedLeadReceivedDate(date);
    setFilterValue(date.value);
    setSelectedFilterLable(date.label);
  };

  const handleLeadTravelDateChange = (date) => {
    setSelectedLeadTravelDateLabel(date);
    setSelectedLeadTravelDate(date);
    setFilterValue(date.value);
    setSelectedFilterLable(date.label);
  };
  const handleClearFilterButtonClick = () => {
    setFilterValue("");
    setSelectedAgent(agents[0]);
    setSelectedFilter(filters[0]);
    setSelectedLeadReceivedDateLabel(timeOptions[0]);
    setSelectedLeadTravelDateLabel(timeOptions[0]);
    setSelectedLeadViaLabel(leadsVia[0]);
    setSelectedStatusLabel(statusOptions[0]);
    setClearFilterData(false);
    setDateRange([null, null]);
    setDateRangeTravel([null, null]);
  };
  const removeAllLocalStorage=()=>{
    var getOldFilter = localStorage.getItem('allfilteroption');
    var oldFilterVal = getOldFilter ? JSON.parse(getOldFilter) : {};
    if (oldFilterVal[activeTab]) {
      delete oldFilterVal[activeTab];
    }
    localStorage.setItem('allfilteroption', JSON.stringify(oldFilterVal));
    handleTabClick(activeTab);
    apiCall(activeTab,1);
  }
  const handleButtonClickClear = () => {
    handleClearFilterButtonClick();
    removeAllLocalStorage();
    
  };
  const handleDateRangeChange = (update) => {
    setDateRange(update);
  };
  const handleDateRangeTravelChange = (update) => {
    setDateRangeTravel(update);
  };
  
  
  return (
    <div className="filter-container my-custom-dropdowndata aic fww">
      <CustomDropdown
        options={filters}
        selectedOption={selectedFilter}
        onSelect={handleFilterChange}
      />
      {selectedFilter.value === "email" ||
      selectedFilter.value === "phone" ||
      selectedFilter.value === "name" ||
      selectedFilter.value === "leadId" ||
      selectedFilter.value === "destination" ? (
        <div className="input-container aic mb8">
          <input
            className="input-field"
            type="text"
            placeholder={`Enter ${selectedFilter.value}...`}
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
          />
          <button
            className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
            onClick={handleGoButtonClick}
          >
            GO
          </button>
          {filterValue !== "" && (
            <button
              className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleButtonClickClear}
            >
              Clear
            </button>
          )}
        </div>
      ) : null}
      {/* {selectedFilter.value === "destination" ? (
        <>
          <div>
            <MultiSelect
              travelDestination={travelDestination}
              placeholder={"Destination"}
            />
          </div>
          <button className="go-button ml24" onClick={handleGoButtonClick}>
            GO
          </button>
        </>
      ) : null} */}

      {selectedFilter.value === "travelDuration" ? (
        <>
          <div>
            <MultiSelect
              travelDestination={travelDuration}
              placeholder={"Duration"}
            />
          </div>
          <button
            className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
            onClick={handleGoButtonClick}
          >
            GO
          </button>
        </>
      ) : null}
      {selectedFilter.value === "leadReceivedDate" ? (
        <div className="report-date v-center">
          {selectedLeadReceivedDateLabel.label === "Custom" ? (
            <div className="mb8 v-center">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateRangeChange}
                isClearable={true}
                placeholderText="---Select Date Range---"
              />
              <button
                className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 "
                onClick={handleGoButtonClick}
              >
                GO
              </button>
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 "
                onClick={handleButtonClickClear}
              >
                Clear
              </button>
            </div>
          ) : (
            <div className="df aic">
              <CustomDropdown
                options={timeOptions}
                selectedOption={selectedLeadReceivedDateLabel}
                onSelect={handleLeadReceivedDateChange}
                // onSelect={(option) => setSelectedLeadReceivedDateLabel(option)}
              />
              <button
                className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleGoButtonClick}
              >
                GO
              </button>
              {filterValue !== "" && (
                <button
                  className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                  onClick={handleButtonClickClear}
                >
                  Clear
                </button>
              )}
            </div>
          )}
        </div>
      ) : null}
      {selectedFilter.value === "leadTravelDate" ? (
        <div className="report-date v-center">
          {selectedLeadTravelDateLabel.label === "Custom" ? (
            <div className="mb8 v-center">
              <DatePicker
                selectsRange={true}
                startDate={startDateTravel}
                endDate={endDateTravel}
                onChange={handleDateRangeTravelChange}
                isClearable={true}
                placeholderText="---Select Date Range---"
              />
              <button
                className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleGoButtonClick}
              >
                GO
              </button>
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleButtonClickClear}
              >
                Clear
              </button>
            </div>
          ) : (
            <div className="df aic">
              <CustomDropdown
                options={TravelDateimeOptions}
                selectedOption={selectedLeadTravelDateLabel}
                //onSelect={(option) => setSelectedLeadTravelDateLabel(option)}
                onSelect={handleLeadTravelDateChange}
              />
              <button
                className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleGoButtonClick}
              >
                GO
              </button>
              {filterValue !== "" && (
                <button
                  className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                  onClick={handleButtonClickClear}
                >
                  Clear
                </button>
              )}
            </div>
          )}
        </div>
      ) : null}

      {selectedFilter.value === "leadvia" ? (
        <>
          <div className="ml24 df aic">
            <CustomDropdown
              options={leadsVia}
              selectedOption={selectedLeadViaLabel}
              onSelect={handleLeadViaSelect}
            />
          </div>
          <button
            className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
            onClick={handleGoButtonClick}
          >
            GO
          </button>
          {filterValue !== "" && (
            <button
              className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleButtonClickClear}
            >
              Clear
            </button>
          )}
        </>
      ) : null}
      {selectedFilter.value === "status" ? (
        <>
          <div className="ml24 df aic">
            <CustomDropdown
              options={statusOptions}
              selectedOption={selectedStatusLabel}
              onSelect={handleStatusSelect}
            />
          </div>
          <button
            className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
            onClick={handleGoButtonClick}
          >
            GO
          </button>
          {filterValue !== "" && (
            <button
              className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleButtonClickClear}
            >
              Clear
            </button>
          )}
        </>
      ) : null}
      {selectedFilter.value === "agent" ? (
        <>
          <div className="ml24 df aic">
            <CustomDropdown
              options={agents}
              selectedOption={selectedAgent ? selectedAgent : agents[0]}
              onSelect={handleAgentSelect}
            />
          </div>
          <button
            className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
            onClick={handleGoButtonClick}
          >
            GO
          </button>
          {filterValue !== "" && (
            <button
              className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleButtonClickClear}
            >
              Clear
            </button>
          )}
        </>
      ) : null}
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default Filter;
