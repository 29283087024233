// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-list-show,
.star-fill-bar {
  width: 100%;
}

.rating-bar {
  height: 10px;
  background-color: #e0e0e0;
}

.fill-bar {
  height: 100%;
  transition: width 0.3s ease;
}

@media only screen and (max-width: 540px) {
  .review-card-container {
    flex-wrap: wrap;
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 24px;
  }
  .review-card-container .fs48 {
    font-size: 28px;
  }
  .review-list-show {
    max-width: 100%;
    padding-left: 0;
  }
  .star-fill-bar {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .review-card-container {
    padding-right: 24px;
    padding-left: 24px;
  }
  .review-list-show {
    max-width: 50%;
  }

  .review-card-container .fs48 {
    font-size: 36px;
  }
  .star-fill-bar {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ReviewCard.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,2BAA2B;AAC7B;;AAEA;EACE;IACE,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;IACf,eAAe;EACjB;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,cAAc;EAChB;;EAEA;IACE,eAAe;EACjB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".review-list-show,\r\n.star-fill-bar {\r\n  width: 100%;\r\n}\r\n\r\n.rating-bar {\r\n  height: 10px;\r\n  background-color: #e0e0e0;\r\n}\r\n\r\n.fill-bar {\r\n  height: 100%;\r\n  transition: width 0.3s ease;\r\n}\r\n\r\n@media only screen and (max-width: 540px) {\r\n  .review-card-container {\r\n    flex-wrap: wrap;\r\n    padding-right: 24px;\r\n    padding-left: 24px;\r\n    padding-top: 24px;\r\n  }\r\n  .review-card-container .fs48 {\r\n    font-size: 28px;\r\n  }\r\n  .review-list-show {\r\n    max-width: 100%;\r\n    padding-left: 0;\r\n  }\r\n  .star-fill-bar {\r\n    width: 100%;\r\n  }\r\n}\r\n\r\n@media only screen and (min-width: 768px) and (max-width: 1024px) {\r\n  .review-card-container {\r\n    padding-right: 24px;\r\n    padding-left: 24px;\r\n  }\r\n  .review-list-show {\r\n    max-width: 50%;\r\n  }\r\n\r\n  .review-card-container .fs48 {\r\n    font-size: 36px;\r\n  }\r\n  .star-fill-bar {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
