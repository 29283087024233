// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-item {
  border: 1px solid #ddd;
  border-right: 8px solid #584cdac2;
  flex: 0 0 30%;
  margin-right: 12px;
  max-width: 30%;
  padding: 10px;
}
.section-1 {
  flex: 60% 1;
  padding: 10px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
}

.section-2 {
  flex: 40% 1;
  padding: 10px;
  box-sizing: border-box;
}
@media screen and (max-width: 620px) {
  .card-content {
    flex-direction: column;
  }
  .card-item:not(:last-child) {
    margin-bottom: 12px;
  }
  .card-item {
    flex: 0 0 100%;
    margin-right: 24px;
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/MyReports.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,iCAAiC;EACjC,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,aAAa;AACf;AACA;EACE,WAAS;EACT,aAAa;EACb,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,WAAS;EACT,aAAa;EACb,sBAAsB;AACxB;AACA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;EACjB;AACF","sourcesContent":[".card-item {\r\n  border: 1px solid #ddd;\r\n  border-right: 8px solid #584cdac2;\r\n  flex: 0 0 30%;\r\n  margin-right: 12px;\r\n  max-width: 30%;\r\n  padding: 10px;\r\n}\r\n.section-1 {\r\n  flex: 60%;\r\n  padding: 10px;\r\n  box-sizing: border-box;\r\n  border-right: 1px solid #ccc;\r\n}\r\n\r\n.section-2 {\r\n  flex: 40%;\r\n  padding: 10px;\r\n  box-sizing: border-box;\r\n}\r\n@media screen and (max-width: 620px) {\r\n  .card-content {\r\n    flex-direction: column;\r\n  }\r\n  .card-item:not(:last-child) {\r\n    margin-bottom: 12px;\r\n  }\r\n  .card-item {\r\n    flex: 0 0 100%;\r\n    margin-right: 24px;\r\n    max-width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
