import React from "react";
import "../components/RadioButton.css";

const RadioButton = ({ options, selectedOption, onChange }) => {
  return (
    <div className="radio-button-container df fww">
      {options.map((option, index) => (
        <label key={index} className="mr48 ">
          <input
            type="radio"
            name={selectedOption}
            value={option.value}
            checked={option.value === selectedOption}
            onChange={() => onChange(option.value)}
            disabled={option.value==="copyFromLibrary" || option.value==="passwordProtected"}
          />
          <div className="df fdc">
           <p className="fs14"> {option.label}</p>
            {option.customText && (
              <span
                className={`fs12 mt4 ${
                  (option.value === "passwordProtected" || option.value === "copyFromLibrary")  ? "fc9" : "fc5"
                }`}
              >
                {option.customText}
              </span>
            )}
          </div>
        </label>
      ))}
    </div>
  );
};

export default RadioButton;
