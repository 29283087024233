// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-timings {
  display: flex;
  align-items: center;
}

.business-timings .toggle-day {
  display: flex;
  align-items: center;
  flex: 0 0 14%;
}

.time-select-container {
  display: flex;
  align-items: center;
  height: 48px;
  flex: 1 1;
}
/* 
.time-select-container span {
  margin: 0 24px;
} */

.off-text {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .business-timings .toggle-day {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 8px;
  }
  .off-text,
  .business-timings .toggle-day,
  .time-select-container {
    align-self: flex-start;
  }
  .off-text {
    height: 24px;
    margin-left: 0;
  }
  .business-timings {
    flex-direction: column;
  }
  .time-select-container select {
    margin-left: 0;
  }
  .time-select-container span {
    margin: 0 8px;
    white-space: nowrap;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/BusinessTimings.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,SAAO;AACT;AACA;;;GAGG;;AAEH;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE;IACE,cAAc;IACd,eAAe;IACf,kBAAkB;EACpB;EACA;;;IAGE,sBAAsB;EACxB;EACA;IACE,YAAY;IACZ,cAAc;EAChB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,cAAc;EAChB;EACA;IACE,aAAa;IACb,mBAAmB;EACrB;AACF","sourcesContent":[".business-timings {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.business-timings .toggle-day {\r\n  display: flex;\r\n  align-items: center;\r\n  flex: 0 0 14%;\r\n}\r\n\r\n.time-select-container {\r\n  display: flex;\r\n  align-items: center;\r\n  height: 48px;\r\n  flex: 1;\r\n}\r\n/* \r\n.time-select-container span {\r\n  margin: 0 24px;\r\n} */\r\n\r\n.off-text {\r\n  height: 48px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n@media screen and (max-width: 480px) {\r\n  .business-timings .toggle-day {\r\n    flex: 0 0 100%;\r\n    max-width: 100%;\r\n    margin-bottom: 8px;\r\n  }\r\n  .off-text,\r\n  .business-timings .toggle-day,\r\n  .time-select-container {\r\n    align-self: flex-start;\r\n  }\r\n  .off-text {\r\n    height: 24px;\r\n    margin-left: 0;\r\n  }\r\n  .business-timings {\r\n    flex-direction: column;\r\n  }\r\n  .time-select-container select {\r\n    margin-left: 0;\r\n  }\r\n  .time-select-container span {\r\n    margin: 0 8px;\r\n    white-space: nowrap;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
