import React from "react";
import "../components/Filters/FilterCheckBoxOption.css";

const PackageMultiCheckBox = ({ label, options, selectedOptions, onChange }) => {
  const handleOptionChange = (value) => {
    const updatedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((item) => item !== value)
      : [...selectedOptions, value];
    onChange(updatedOptions);
  };

  return (
    <div className="filter-option req-star">
      <p className="fs14 fw6 fc14 pt16 pb16">{label}<span className="fc4">*</span></p>
      <div className="filter-checkboxes df fww">
        {options.map((option) => (
          <label key={option.value} className="v-center mb8 flx25 ls1 fc5 fs14">
            <input
              type="checkbox"
              name="filterOption"
              value={option.value}
              checked={selectedOptions.includes(option.value)}
              onChange={() => handleOptionChange(option.value)}
              className="custom-checkbox-buy-lead"
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};

export default PackageMultiCheckBox;
