import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "../pages/CreateItinerary.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./PackagePricing.css";
import { FaAngleDown, FaPencilAlt, FaTrash } from "react-icons/fa";
import { IoLogoUsd } from "react-icons/io5";
import { FaIndianRupeeSign } from "react-icons/fa6";
import moment from "moment/moment";

const PackagePricing = ({
  tblLeadId,
  nid,
  renderNextButton,
  toast,
  renderBackButton,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currency, setCurrency] = useState("INR");
  const [tax, setTax] = useState("Yes");
  const [price, setPrice] = useState(null);
  const [adultTwinSharing, setAdultTwinSharing] = useState(null);
  const [extraBedCosting, setExtraBedCosting] = useState(null);
  const [singleAdult, setSingleAdult] = useState(null);
  const [childWithBed, setChildWithBed] = useState(null);
  const [childWithoutBed, setChildWithoutBed] = useState(null);
  const [editingPricing, setEditingPricing] = useState(null);
  const [blackoutFromDate, setBlackoutFromDate] = useState(null);
  const [blackoutToDate, setBlackoutToDate] = useState(null);
  const [surchargeBlackout, setSurchargeBlackout] = useState(false);
  const [priceBlackout, setPriceBlackout] = useState(null);
  const [priceTypeBlackout, setPriceTypeBlackout] = useState(null);
  const [pricing, setPricing] = useState([]);
  const [editPriceId, setEditPriceId] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [blackoutDates, setBlackoutDates] = useState([]);
  const [showPriceTypeDropdown, setShowPriceTypeDropdown] = useState(false);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [showTaxDropdown, setShowTaxDropdown] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const currencyOptions = ["INR", "USD"];
  const taxOptions = ["Yes", "No"];
  const pricingAndSeasonalityRef = useRef(null);
  const user = useSelector((state) => state.auth);
  const [authId, setAuthID] = useState(user.auth_id);
  const currencydropdown = useRef(null);
  const taxdropdown = useRef(null);
  const priceTypedropdown = useRef(null);
  const handleFromDateChange = (date) => {
    setStartDate(date);
  };
  const priceTypeOptions = ["Per person/Per day", "Total Package"];
  const handlepriceTypeSelect = (selectedPriceType) => {
    setPriceTypeBlackout(selectedPriceType);
    setShowPriceTypeDropdown(false);
  };
  const handleCurrencySelect = (selectedCurrency) => {
    setCurrency(selectedCurrency);
    setShowCurrencyDropdown(false);
  };
  const handleTaxSelect = (selectedTax) => {
    setTax(selectedTax);
    setShowTaxDropdown(false);
  };
  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };
  const resetForm = () => {
    setStartDate(null);
    setEndDate(null);
    setCurrency("INR");
    setTax("");
    setAdultTwinSharing("");
    setExtraBedCosting("");
    setSingleAdult("");
    setChildWithBed("");
    setChildWithoutBed("");
    setEditPriceId(null);
    setBlackoutFromDate(null);
    setBlackoutToDate(null);
    setSurchargeBlackout(false);
    setPriceBlackout("");
    setPriceTypeBlackout("");
  };
  const handleAddBlackoutDate = () => {
    if (!blackoutFromDate) {
      toast.error("Blackout Warning: From date missing.");
      return false;
    }
    if (!blackoutToDate) {
      toast.error("Blackout Warning: To date missing.");
      return false;
    }
    let stdate = parseInt(blackoutFromDate.toDateString());
    let endate = parseInt(blackoutToDate.toDateString());

    if (stdate > endate) {
      toast.error(
        "Blackout Warning: From date should be less or equal than To date."
      );
      return false;
    }
    if (
      surchargeBlackout &&
      (parseFloat(priceBlackout) <= 0 || priceBlackout == "")
    ) {
      toast.error("Blackout Warning: Price missing.");
      return false;
    }
    if (surchargeBlackout && priceTypeBlackout == "") {
      toast.error("Blackout Warning: Price type missing.");
      return false;
    }

    const newBlackoutDate = {
      fromDate: moment(blackoutFromDate).format("YYYY-MM-DD"),
      toDate: moment(blackoutToDate).format("YYYY-MM-DD"),
      blackOutSurcharge: surchargeBlackout ? true : false,
      price: parseFloat(priceBlackout) || 0,
      priceType: priceTypeBlackout || "per night",
    };

    if (editingIndex !== null) {
      const updatedDates = [...blackoutDates];
      updatedDates[editingIndex] = newBlackoutDate;
      setBlackoutDates(updatedDates);
      setEditingIndex(null);
    } else {
      setBlackoutDates([...blackoutDates, newBlackoutDate]);
    }

    setBlackoutFromDate(null);
    setBlackoutToDate(null);
    setSurchargeBlackout(false);
    setPriceBlackout("");
    setPriceTypeBlackout("");
  };

  const handleGetDeals = async (tblLeadId) => {
    try {
      const requestBody = {
        id: tblLeadId,
        auth_id: authId,
      };

      const getDealsApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=deals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const dealDetailsData = await getDealsApiResponse.json();
      const pricingArray = JSON.parse(
        dealDetailsData.data.deal_object.prices_struct_json || "[]"
      );
      setPricing(pricingArray);
    } catch (error) {
      console.error("API call error:", error);
    }
  };
  const handleApiPricePost = async () => {
    try {
      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

      const requestBody = {
        auth_id: authId,
        tbl_deal_id: tblLeadId,
        nid: nid,
        fromdate: formattedStartDate,
        todate: formattedEndDate,
        tax: tax === "Yes" ? "Y" : "N",
        adult_twin_sharing: adultTwinSharing,
        child_with_bed: childWithBed,
        child_without_bed: childWithoutBed,
        single_adult: singleAdult,
        extra_bed_costing: extraBedCosting,
        blackoutdates: blackoutDates,
        currency: currency,
        id: editPriceId ? editPriceId : "",
      };
      const postPriceApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=price`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const postPriceApiDetails = await postPriceApiResponse.json();
      handleGetDeals(tblLeadId);
      setEditPriceId(null);
      setEditingPricing(false);
      setBlackoutDates([]);
      setEditingIndex(null);
    } catch (error) {
      console.error("API call error:", error);
    }
  };
  const handleDeletePricing = async (id) => {
    try {
      const requestBody = {
        auth_id: authId,
        nid: nid,
        tbl_deal_id: tblLeadId,
        upid: id,
      };

      const postPolicyApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=deactiveprice`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const postPolicyApiDetails = await postPolicyApiResponse.json();
      handleGetDeals(tblLeadId);
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const handleAddPricing = () => {
    if (startDate === "" || startDate === null) {
      toast.error("Price Warning: From date missing.");
      return false;
    }
    if (endDate === "" || endDate === null) {
      toast.error("Price Warning: To date missing.");
      return false;
    }
    let stdate = parseInt(startDate.toDateString());
    let endate = parseInt(endDate.toDateString());

    if (stdate > endate) {
      toast.error(
        "Price Warning: From date should be less or equal than To date."
      );
      return false;
    }
    if (currency === "" || currency === null) {
      toast.error("Price Warning: Currency type missing.");
      return false;
    }
    if (tax === "" || tax === null) {
      toast.error("Price Warning: Tax included missing.");
      return false;
    }
    if (adultTwinSharing === "" || adultTwinSharing === null) {
      toast.error("Price Warning: Adult twin sharing price missing.");
      return false;
    }
    if (!blackoutFromDate && blackoutToDate) {
      toast.error("Blackout Warning: From date missing.");
      return false;
    }
    if (!blackoutToDate && blackoutFromDate) {
      toast.error("Blackout Warning: To date missing.");
      return false;
    }
    if (
      surchargeBlackout &&
      (parseFloat(priceBlackout) <= 0 || priceBlackout === "")
    ) {
      toast.error("Blackout Warning: Price missing.");
      return false;
    }
    if (surchargeBlackout && priceTypeBlackout === "") {
      toast.error("Blackout Warning: Price type missing.");
      return false;
    }
    handleApiPricePost();
    resetForm();
  };

  const handleEditPricing = (item) => {
  
    setEditingItem(item);
    setEditPriceId(item.id);
    if(item.fromdate && item.todate && item.fromdate!=="0000-00-00" && item.todate!=="0000-00-00"){
    const formattedStartDate = moment(item.fromdate).format("DD-MM-YY");
    const formattedEndDate = moment(item.todate).format("DD-MM-YY");
    setStartDate(moment(formattedStartDate, "DD-MM-YY").toDate());
    setEndDate(moment(formattedEndDate, "DD-MM-YY").toDate());
    }
    setCurrency(item.currency);
    setTax(item.tax === "Y" ? "Yes" : "No");
    setAdultTwinSharing(item.adult_twin_sharing);
    setExtraBedCosting(item.extra_bed_costing);
    setSingleAdult(item.single_adult);
    setChildWithBed(item.child_with_bed);
    setChildWithoutBed(item.child_without_bed);
    setBlackoutDates(item.blackoutdates);

    setEditingPricing(true);

    pricingAndSeasonalityRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const handleDeleteBlackoutDate = (index) => {
    const updatedBlackoutDates = [...blackoutDates];
    updatedBlackoutDates.splice(index, 1);
    setBlackoutDates(updatedBlackoutDates);
  };
  const handleEditBlackoutDate = (index) => {
    const editedDate = blackoutDates[index];
    setBlackoutFromDate(moment(editedDate.fromDate).toDate());
    setBlackoutToDate(moment(editedDate.toDate).toDate());
    setSurchargeBlackout(editedDate.blackOutSurcharge);
    setPriceBlackout(editedDate.price);
    setPriceTypeBlackout(editedDate.priceType);
    setEditingIndex(index);
  };
  useEffect(() => {
    if (tblLeadId) {
      handleGetDeals(tblLeadId);
    }
  }, [tblLeadId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        currencydropdown.current &&
        !currencydropdown.current.contains(event.target)
      ) {
        setShowCurrencyDropdown(false);
      }
      if (
        priceTypedropdown.current &&
        !priceTypedropdown.current.contains(event.target)
      ) {
        setShowPriceTypeDropdown(false);
      }

      if (taxdropdown.current && !taxdropdown.current.contains(event.target)) {
        setShowTaxDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="df fdc w100">
      <div className="fs14 fw4 mb24 c2 pl12 lh18">
        Craft precise Itinerary with transparent pricing and blackout dates for
        a tailored and reliable travel experience.
      </div>
      {pricing.length > 0 && (
        <>
          <div className="pl12 pb12">
            <div className="v-center jcsb">
              <p className="fs16 labels-heading fw4 fw4">
                Pricing and Seasonality
              </p>
            </div>
          </div>
          {pricing.map((priceItem, index) => (
            <div className="pricing-listing p12" key={priceItem.id}>
              <div key={priceItem.id}>
                <div className="df jcsb">
                  <div className="pricing-dates df jcsb flx75">
                    <div className="df jcsb flx40">
                      <p className="fs16 labels-heading fw4">From:</p>
                      <p className="fs16 fw6 labels-heading">
                        {priceItem.fromdate && priceItem.fromdate!=="0000-00-00"
                          ? moment(priceItem.fromdate).format("DD MMM YYYY")
                          : "NA"}
                      </p>
                    </div>
                    <div className="df jcsb flx40">
                      <p className="fs16 labels-heading fw4 ">Till:</p>
                      <p className="fs16 fw6 labels-heading">
                        {priceItem.todate && priceItem.fromdate!=="0000-00-00"
                          ? moment(priceItem.todate).format("DD MMM YYYY")
                          : "NA"}
                      </p>
                    </div>
                  </div>
                  <div className="df jce">
                    <FaPencilAlt
                      className="icon edit-icon mr8 cp"
                      onClick={() => handleEditPricing(priceItem)}
                    />
                    <FaTrash
                      className="icon edit-icon cp"
                      onClick={() => handleDeletePricing(priceItem.id)}
                    />
                  </div>
                </div>
                <div className="pricing-dates df jcsb flx75 mt24">
                  <div className="df jcsb flx40">
                    <p className="fs14 labels-heading fw4">
                      Adult twin sharing:
                    </p>
                    {priceItem.adult_twin_sharing !== null ? (
                      priceItem.adult_twin_sharing !== "" &&
                      priceItem.adult_twin_sharing !== "0" ? (
                        <p className="fs14 fw6 labels-heading df ml16">
                          {priceItem.currency === "INR" ? (
                            <FaIndianRupeeSign />
                          ) : (
                            <IoLogoUsd />
                          )}
                          {priceItem.adult_twin_sharing}
                        </p>
                      ) : (
                        "NA"
                      )
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div className="df jcsb flx40">
                    <p className="fs14 labels-heading fw4">
                      Extra bed costing:
                    </p>
                    {priceItem.extra_bed_costing !== null ? (
                      priceItem.extra_bed_costing !== "" &&
                      priceItem.extra_bed_costing !== "0" ? (
                        <p className="fs14 fw6 labels-heading df ml16">
                          {priceItem.currency === "INR" ? (
                            <FaIndianRupeeSign />
                          ) : (
                            <IoLogoUsd />
                          )}
                          {priceItem.extra_bed_costing}
                        </p>
                      ) : (
                        "NA"
                      )
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>
                <div className="pricing-dates df jcsb flx75 mt24">
                  <div className="df jcsb flx40">
                    <p className="fs14 labels-heading fw4">Single adult:</p>
                    {priceItem.single_adult !== null ? (
                      priceItem.single_adult !== "" &&
                      priceItem.single_adult !== "0" ? (
                        <p className="fs14 fw6 labels-heading df ml16">
                          {priceItem.currency === "INR" ? (
                            <FaIndianRupeeSign />
                          ) : (
                            <IoLogoUsd />
                          )}
                          {priceItem.single_adult}
                        </p>
                      ) : (
                        "NA"
                      )
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div className="df jcsb flx40">
                    <p className="fs14 labels-heading fw4">Child with bed:</p>
                    {priceItem.child_with_bed !== null ? (
                      priceItem.child_with_bed !== "" &&
                      priceItem.child_with_bed !== "0" ? (
                        <p className="fs14 fw6 labels-heading df ml16">
                          {priceItem.currency === "INR" ? (
                            <FaIndianRupeeSign />
                          ) : (
                            <IoLogoUsd />
                          )}
                          {priceItem.child_with_bed}
                        </p>
                      ) : (
                        "NA"
                      )
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>
                <div className="pricing-dates df jcsb flx75 mt24">
                  <div className="df jcsb flx40">
                    <p className="fs14 labels-heading fw4">
                      Child without bed:
                    </p>
                    {priceItem.child_without_bed !== null ? (
                      priceItem.child_without_bed !== "" &&
                      priceItem.child_without_bed !== "0" ? (
                        <p className="fs14 fw6 labels-heading df ml16">
                          {priceItem.currency === "INR" ? (
                            <FaIndianRupeeSign />
                          ) : (
                            <IoLogoUsd />
                          )}
                          {priceItem.child_without_bed}
                        </p>
                      ) : (
                        "NA"
                      )
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div className="df jcsb flx40">
                    <p className="fs14 labels-heading fw4">Tax Included:</p>
                    {priceItem.tax === "Y" ? "Yes" : "No"}
                  </div>
                </div>

                <div className="blackout-date-details">
                  {priceItem.blackoutdates &&
                    priceItem.blackoutdates.length > 0 && (
                      <h4 className="fs16 labels-heading fw4 pt12 mt16">
                        Blackout Dates:
                      </h4>
                    )}

                  {(priceItem.blackoutdates || []).map((dateItem, index) => (
                    <div
                      key={index} 
                      className="blackout-date-item df mt16 mb16 fs14"
                    >
                      <p className="mr16 dbo-items">
                        {index + 1}. From Date: {dateItem.fromDate || "NA"}
                      </p>
                      <p className="mr16 dbo-items">
                        To Date: {dateItem.toDate || "NA"}
                      </p>
                      {dateItem.blackOutSurcharge ? (
                        <p className="mr16 fw6">
                          {currency === "INR" ? (
                            <FaIndianRupeeSign />
                          ) : (
                            <IoLogoUsd />
                          )}
                          {dateItem.price !== "" && dateItem.price !== "0"
                            ? dateItem.price
                            : "NA"}{" "}
                          ({dateItem.priceType || "NA"})
                        </p>
                      ) : (
                        <p className="mr16 fw6">Complete Blackout</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </>
      )}

      <div className="w100" ref={pricingAndSeasonalityRef}>
        <div className="fs16 labels-heading fw4 pl12">
          {pricing.length === 0 && "Add Pricing and Seasonality"}
        </div>
        <div className="pricing-content p12">
          <div className="filter-travel-date">
            <div className="lead-calendar df mt24 jcsb pricing-calendar">
              <div className="flx50 v-center req-star from-date-inp">
                <label className="mr8 flx40 labels-heading fw4 fs14">
                  From Date<span>*</span>
                </label>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  selected={startDate}
                  onChange={handleFromDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="From Date"
                  className="cp bg7 brd1 fc5 fs14  br16 pl16 pt12 pb12"
                  isClearable={true}
                />
              </div>
              <div className="flx50 v-center req-star to-date-inp">
                <label className="mr8 flx40 labels-heading fw4 fs14">
                  To Date<span>*</span>
                </label>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  selected={endDate}
                  onChange={handleToDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="To Date"
                  className="cp bg7 brd1 fc5 fs14 br16 pl16 pt12 pb12 box-center"
                  isClearable={true}
                />
              </div>
            </div>
          </div>
          <div className=" df jcsb h48 mt16 curr-cont">
            <div className="flx50 v-center req-star currency-inp">
              <label className="mr8 flx40 labels-heading fw4 fs14">
                Currency<span>*</span>
              </label>
              <div className=" blackout-input mr16 w100">
                <div className="role-dropdown" ref={currencydropdown}>
                  <div
                    className="selected-role fs14"
                    onClick={() =>
                      setShowCurrencyDropdown(!showCurrencyDropdown)
                    }
                  >
                    {currency || "Select Currency"}{" "}
                    <FaAngleDown className="fc16 fs14" />
                  </div>
                  {showCurrencyDropdown && (
                    <ul className="role-options fs14">
                      {currencyOptions.map((option) => (
                        <li
                          key={option}
                          onClick={() => handleCurrencySelect(option)}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="flx1 v-center req-star adult-inp">
              <label className="mr8 flx40 labels-heading fw4 fs14">
                Adult Twin Sharing<span>*</span>
              </label>
              <div className="blackout-input mr16 w100">
                <input
                  type="number"
                  id="adultTwinSharing"
                  Min={0}
                  placeholder="Price per person for twin sharing"
                  value={adultTwinSharing}
                  onChange={(e) => setAdultTwinSharing(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className=" df jcsb h48  mt16 extra-cont">
            <div className="flx50 v-center extra-inp">
              <p className="mr8 flx40 labels-heading fw4 fs14">
                Extra bed costing
              </p>
              <div className=" blackout-input mr16 w100">
                <input
                  type="number"
                  id="extraBedCosting"
                  Min={0}
                  placeholder="Enter extra bed costing"
                  value={extraBedCosting}
                  onChange={(e) => setExtraBedCosting(e.target.value)}
                />
              </div>
            </div>
            <div className="flx1 v-center single-inp">
              <p className="mr8 flx40 labels-heading fw4 fs14">Single adult</p>
              <div className=" blackout-input mr16 w100">
                <input
                  type="number"
                  id="singleAdult"
                  Min={0}
                  placeholder="Enter single adult price"
                  value={singleAdult}
                  onChange={(e) => setSingleAdult(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className=" df jcsb h48 mt16 child-cont">
            <div className="flx50 v-center child-inp">
              <p className="mr8 flx40 labels-heading fw4 fs14">
                Child with bed
              </p>{" "}
              <div className=" blackout-input mr16 w100">
                <input
                  type="number"
                  id="childWithBed"
                  Min={0}
                  placeholder="Enter child with bed price"
                  value={childWithBed}
                  onChange={(e) => setChildWithBed(e.target.value)}
                />
              </div>
            </div>
            <div className="flx1 v-center childwo-inp">
              <p className="mr8 flx40 labels-heading fw4 fs14">
                Child without bed
              </p>
              <div className=" blackout-input mr16 w100">
                <input
                  type="number"
                  id="childWithoutBed"
                  Min={0}
                  placeholder="Enter child without bed price"
                  value={childWithoutBed}
                  onChange={(e) => setChildWithoutBed(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className=" df jcsb h48 mt16 mb24 tax-cont">
            <div className="flx50 v-center req-star tax-inp">
              <label className="mr8 flx40 labels-heading fw4 fs14">
                Tax Included<span>*</span>
              </label>
              <div className=" blackout-input mr16 w100">
                <div className="role-dropdown" ref={taxdropdown}>
                  <div
                    className="selected-role fs14"
                    onClick={() => setShowTaxDropdown(!showTaxDropdown)}
                  >
                    {tax || "Select Tax"} <FaAngleDown className="fc16 fs14" />
                  </div>
                  {showTaxDropdown && (
                    <ul className="role-options fs14">
                      {taxOptions.map((option) => (
                        <li
                          key={option}
                          onClick={() => handleTaxSelect(option)}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className=" v-center blackout-buttons brd-t1">
            <div className="fs18 labels-heading fw4 pt12">Blackout dates</div>
          </div>
          <div className="blackout-date">
            <div className="blackout-date-details">
              {blackoutDates.map((dateItem, index) => (
                <div
                  key={index}
                  className="blackout-date-item df mt24 mb16 jcsb"
                >
                  <div className="df blackout-date-items">
                    <p className="mr16 from-show-bod">
                      {index + 1}. From Date: {dateItem.fromDate}
                    </p>
                    <p className="mr16  to-show-bod">
                      To Date: {dateItem.toDate}
                    </p>
                    {dateItem.blackOutSurcharge ? (
                      <p className="mr16 fw6 v-center  msg-show-bod">
                        {currency === "INR" ? (
                          <FaIndianRupeeSign />
                        ) : (
                          <IoLogoUsd />
                        )}{" "}
                        {dateItem.price} ({dateItem.priceType})
                      </p>
                    ) : (
                      <p className="mr16 fw6 msg-show-bod">Complete Blackout</p>
                    )}
                  </div>
                  <FaPencilAlt
                    className="icon edit-icon mr8 cp"
                    onClick={() => handleEditBlackoutDate(index)}
                  />
                  <FaTrash
                    className="icon cp"
                    onClick={() => handleDeleteBlackoutDate(index)}
                  />
                </div>
              ))}
            </div>

            <div className="lead-calendar v-center mt24 fww">
              <div className="df fdc req-sta from-bod">
                <label className="mr8 labels-heading fw4 fs14 mb8">
                  From Date<span>*</span>
                </label>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  selected={blackoutFromDate}
                  onChange={(date) => setBlackoutFromDate(date)}
                  selectsStart
                  startDate={blackoutFromDate}
                  endDate={blackoutToDate}
                  placeholderText="From Date"
                  className="cp bg7 brd1 fc5 fs14 mr4 br16 pl16 pt12 pb12"
                  isClearable={true}
                />
              </div>
              <div className="df fdc req-star to-bod">
                <label className="mr8 flx40 labels-heading fw4 fs14 mb8">
                  To Date<span>*</span>
                </label>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  selected={blackoutToDate}
                  onChange={(date) => setBlackoutToDate(date)}
                  selectsEnd
                  startDate={blackoutFromDate}
                  endDate={blackoutToDate}
                  minDate={blackoutFromDate}
                  placeholderText="To Date"
                  className="cp bg7 brd1 fc5 fs14 br16 pl16 pt12 pb12 box-center"
                  isClearable={true}
                />
              </div>
              <div className="blackout-surcharge v-center mt24">
                <input
                  type="checkbox"
                  name="blackout-surcharge"
                  checked={surchargeBlackout}
                  className="mr8"
                  onChange={() => setSurchargeBlackout(!surchargeBlackout)}
                />
                <p className="mr16">Blackout Surcharge</p>
              </div>
              <button
                type="button"
                className="lead-buy-btn  v-center fc3 bg1 br24 fs14 cp pl24 mt24 pr24 pt10 pb10 ls1"
                onClick={handleAddBlackoutDate}
              >
                {editingIndex !== null ? "Update" : "Add"}
              </button>
            </div>
            {surchargeBlackout && (
              <div className="df surcharge mt12">
                <div className="blackout-input mr16 flx25 bi-price">
                  <input
                    type="number"
                    id="blackoutPrice"
                    placeholder="Enter Price"
                    min={1}
                    value={priceBlackout}
                    onChange={(e) => setPriceBlackout(e.target.value)}
                  />
                </div>
                <div className="blackout-input flx25 bi-type">
                  <div className="role-dropdown" ref={priceTypedropdown}>
                    <div
                      className="selected-role fs14"
                      onClick={() =>
                        setShowPriceTypeDropdown(!showPriceTypeDropdown)
                      }
                    >
                      {priceTypeBlackout || "Select Price Type"}
                      <FaAngleDown className="fc16 fs14" />
                    </div>
                    {showPriceTypeDropdown && (
                      <ul className="role-options fs14">
                        {priceTypeOptions.map((option) => (
                          <li
                            key={option}
                            onClick={() => handlepriceTypeSelect(option)}
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="df aic jcsb mt32">
            {renderBackButton()}
            <button
              type="button"
              className="lead-buy-btn fc3 bg1 br24 fs14 cp pl24 pr24 pt10 pb10 ls1 v-center ml16 mr16"
              onClick={handleAddPricing}
            >
              {editingPricing ? "Update Pricing" : "Save"}
            </button>
            {renderNextButton()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagePricing;