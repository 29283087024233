const getLeadViaTextColor = (leadVia) => {
  switch (leadVia) {
    case "Website":
      return "red"; 
    case "Manual":
      return "blue"; 
    case "Package":
      return "green"; 
    case "Auto Buy":
        return "orange";   
    default:
      return "#000"; 
  }
};

export { getLeadViaTextColor };
