import React from "react";
import "../components/ConfirmationDialog.css";

const ConfirmationDialog = ({ message, onCancel, onConfirm }) => {
  return (
    <div className="confirmation-overlay">
      
      <div className="confirmation-dialog">
        <div className="confirmation-content">
          <p className="lh16 ls1">{message}</p>
          <div className="confirmation-buttons">
            <button className="btn-cancel" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn-confirm" onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
