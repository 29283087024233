import React, { useEffect, useState } from "react";
import "./FilterCheckBoxOption.css";
import Tooltip from "../Tooltip";

const FilterCheckBoxOption = ({ label, options, selectedOptions, onChange, multiSelect = true, disabled }) => {
  
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const visibleOptions = options.length === 4 ? options : showMore ? options : options.slice(0, 3);

  const handleOptionChange = (value) => {
    if (multiSelect) {
      // For multi-select, toggle the selection
      const updatedOptions = selectedOptions.includes(value)
        ? selectedOptions.filter((item) => item !== value)
        : [...selectedOptions, value];
      onChange(updatedOptions);
    } else {
      // For single select, replace the selection
      const updatedOptions = selectedOptions.includes(value) ? [] : [value];
      onChange(updatedOptions);
      //onChange([value]);
    }
  };
  
    
    
  return (
    <div className="filter-option">
      <p className="fs14 fw6 fc14 pb16">{label}</p>
      
      {disabled && <Tooltip  title="Upgrade your plan to use this filter"> <div className="filter-checkboxes df fww w100">
        {visibleOptions.map((option) => (
          <label key={option.value} className="checkbox-label-buy-lead ls1 fc5 fs14">
            
            <input
              type="checkbox"
              name="filterOption"
              value={option.value}
              checked={selectedOptions.includes(option.value)}
              onChange={() => handleOptionChange(option.value)}
              className="custom-checkbox-buy-lead"
              disabled = {disabled}
              style={{cursor: disabled?'not-allowed':''}}
            />
            {option.label}
          </label>
        ))}
        {options.length > 3 && options.length !== 4 && (
          <div className="show-more fc22 fs14 cp ls1" onClick={toggleShowMore}>
            {showMore ? "Show Less" : `+ ${options.length - 3} More`}
          </div>
        )}
      </div></Tooltip>}
      {!disabled && <div className="filter-checkboxes df fww">
        {visibleOptions.map((option) => (
          <label key={option.value} className="checkbox-label-buy-lead ls1 fc5 fs14" >
            
            <input
              type="checkbox"
              name="filterOption"
              value={option.value}
              checked={selectedOptions.includes(option.value)}
              onChange={() => handleOptionChange(option.value)}
              className="custom-checkbox-buy-lead"
              disabled = {disabled}
              style={{cursor: disabled?'not-allowed':''}}
            />
            {option.label}
          </label>
        ))}
        {options.length > 3 && options.length !== 4 && (
          <div className="show-more fc22 fs14 cp ls1" onClick={toggleShowMore}>
            {showMore ? "Show Less" : `+ ${options.length - 3} More`}
          </div>
        )}
      </div>}
      
    </div>
  );
};

export default FilterCheckBoxOption;
