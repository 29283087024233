import React, { useState } from "react";
import "../components/Box.css";
import AppBarButton from "./AppBarButton";

const Addons = ({ addon, currentPlanName, className, rmNumber,handleSendMail }) => {
  const [showContactSales, setShowContactSales] = useState(false);

  const toggleContactSales = () => {
    setShowContactSales(!showContactSales);
    handleSendMail();
  };

  return (
    <div className={`boxbd p20 ${className}`}>
      <div className="v-center">
        <p className="fs16 fw5 fc15">{addon.name}</p>
      </div>
      <div className="plan mt16 fs14 fc16 lh22 ls1">{addon.text}</div>
      <div id="price" className="mt16 fw6 fc17">

      <span className="real-price" style={{ textDecoration: "line-through" }}>
          ₹{addon.price}
        </span>
        <span className="offer-price"> Free</span>
      
      </div>
      <div className="df jcsb mt8 jce">
        <AppBarButton
          content={showContactSales ? rmNumber : "Contact Sales"}
          color="#f4f3ff"
          marginRight={20}
          fontColor="#594cda"
          onClick={toggleContactSales}
        />
      </div>
    </div>
  );
};

export default Addons;
