import React, { useRef,useEffect } from "react";
import "./LeadDetailsPopup.css";
const SidePopup = ({onclose,children,show=true}) => {
  const formRef = useRef(null);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target) && show) {
      onclose(); 
      enableScroll();
    }
  };

  useEffect(() => {
    if (show) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [show]);
  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };
  const CloseMyPopup=()=>{
    onclose(); 
    enableScroll();
  }

  return (
    <div className="lead-popup-overlay">
      <div className={`lead-popup-content pr ${show ? "slide-in" : ""}`} ref={formRef}>
        <div>
          <button onClick={CloseMyPopup} className="lead-close-button">
            X
          </button>
        </div>
        <>
          {children} 
        </>
      </div>
    </div>
  );
};
export default SidePopup;
