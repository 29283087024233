const themeOptions = [
    { label: "Honeymoon", value: "honeymoon" },
    { label: "Friends", value: "friends" },
    { label: "Beach", value: "beach" },
    { label: "Adventure", value: "adventure" },
    { label: "Art", value: "art" },
    { label: "Backpacking", value: "backpacking" },
    { label: "Beaches", value: "beaches" },
    { label: "Culture", value: "culture" },
    { label: "Desert", value: "desert" },
    { label: "Eco-Friendly", value: "eco-friendly" },
    { label: "Family Holidays", value: "family-holidays" },
    { label: "Festivals", value: "festivals" },
    { label: "Food", value: "food" },
    { label: "Hills", value: "hills" },
    { label: "History", value: "history" },
    { label: "Luxury", value: "luxury" },
    { label: "Pilgrimage", value: "pilgrimage" },
    { label: "Road Trips", value: "road-trips" },
    { label: "Romance", value: "romance" },
    { label: "Solo trips", value: "solo-trips" },
    { label: "Trekking", value: "trekking" },
    { label: "Wellness", value: "wellness" },
    { label: "Wildlife", value: "wildlife" },
    { label: "Yoga & Meditation", value: "yoga-meditation" },
  ];
  export default themeOptions;
  