import React, { useState } from "react";
import "../pages/Catalog.css";
import { useTitle } from "../hooks/useTitle";
import catalogTwo from "../assets/catalog_two.png";
import premium from "../assets/premium.png";
import { MdPreview } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import Popup from "../components/Popup/Popup";
import CatalogData from "../data/CatalogData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CoverGallery from "../components/CoverGallery";
import { FaStar } from "react-icons/fa6";

const Catalog = () => {
  useTitle("My Plan -  Travel Agents CRM - Hellotravel");
  const [selectedItem, setSelectedItem] = useState("bg1");
  const [selectedFont, setSelectedFont] = useState("Roboto");
  const [openCoverPopup, setOpenCoverPopup] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const fontList = [
    "Roboto",
    "Arial",
    "Montserrat",
    "Poppins",
    "Protest Strike",
  ];
  const handleSelectedColor = (item) => {
    setSelectedItem(item);
  };
  const handleFontSelected = (font) => {
    setSelectedFont(font);
  };
  const handleSelectedColorFreeDomain = () => {
    window.open(
      "https://www.clickindia.com/business/clickindia-infomedia-pvt-ltd-7984919/",
      "_blank"
    );
  };
  const handleTemplatePreview = (tempName) => {
    window.open(
      `https://ihook.hellotravel.com/web-templates/${tempName}/index.html`,
      "_blank"
    );
  };
  const handleOpenCover = () => {
    setOpenCoverPopup(true);
  };
  const closeCoverPopup = () => {
    setOpenCoverPopup(false);
  };
  const handleSelectTemplate = (tempId) => {
    setSelectedTemplate(tempId);
    toast.success("Template selected successfully.");
  };
  return (
    <div className="df mr20">
      <div className="w75 pl20">
        <div className="account-details df fdc">
          <h3 className=" fc1 fw5 fs20 mt16 mb24">
            Catalog Settings
            <div className="fs14 fc16 mt8 fw4 lh16">
              Choose customized templates for your profile page.
            </div>
          </h3>
        </div>
        <div className="catalog-templates">
          <div className="catalog-templates-head mb12 fs16 ls1 lh16">
            Select a theme Template
          </div>
          <div className="catalog-templates-list df fww brd-b1 pb24 w100">
            {CatalogData.data.result.map((item, index) => (
              <div
                key={item.id}
                className="templates-catalog-single mr16 flx30 mb24"
              >
                <div
                  className={`catalog-images w100 br8 cp pr ${
                    selectedTemplate === item.id ? "selected" : ""
                  }`}
                  onClick={() => handleSelectTemplate(item.id)}
                >
                  <img
                    src={`https://ihook.hellotravel.com/web-templates/${item.template_name}/img/temp1.jpg`}
                    alt="catalog"
                    className="catalog-temp-image w100"
                  />
                  {item.sel === false && (
                    <img
                      src={premium}
                      alt="Premium Template"
                      className="premium-icon pa"
                    />
                  )}
                  {selectedTemplate === item.id && (
                    <>
                      <div className="temp-overlay"></div>
                      <TiTick className="tick fs36" />
                    </>
                  )}
                </div>
                <div className="tmp-details v-center jcsb mt8 fc5">
                  <p className="tmp-name fs14 ls1 lh16">{`TM${index + 1}`}</p>
                  <p
                    className="tmp-preview fs14 v-center ls1 lh16 cp fc19"
                    onClick={() => handleTemplatePreview(item.template_name)}
                  >
                    Preview <MdPreview className="ml8" />
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="catalog-color pt24 pb24 brd-b1">
            <div className="catalog-templates-head mb12 fs16 ls1 lh16">
              Select a Color
            </div>
            <ul className="color-list v-center">
              <li
                className={`bg1 ${selectedItem === "bg1" ? "selected" : ""}`}
                onClick={() => handleSelectedColor("bg1")}
              >
                {selectedItem === "bg1" && (
                  <>
                    <div className="color-overlay"></div>
                    <TiTick className="tick" />
                  </>
                )}
              </li>
              <li
                className={`bg3 ${selectedItem === "bg3" ? "selected" : ""}`}
                onClick={() => handleSelectedColor("bg3")}
              >
                {selectedItem === "bg3" && (
                  <>
                    <div className="color-overlay"></div>
                    <TiTick className="tick" />
                  </>
                )}
              </li>
              <li
                className={`bg4 ${selectedItem === "bg4" ? "selected" : ""}`}
                onClick={() => handleSelectedColor("bg4")}
              >
                {selectedItem === "bg4" && (
                  <>
                    <div className="color-overlay"></div>
                    <TiTick className="tick" />
                  </>
                )}
              </li>
              <li
                className={`bg9 ${selectedItem === "bg9" ? "selected" : ""}`}
                onClick={() => handleSelectedColor("bg9")}
              >
                {selectedItem === "bg9" && (
                  <>
                    <div className="color-overlay"></div>
                    <TiTick className="tick" />
                  </>
                )}
              </li>
              <li
                className={`bg10 ${selectedItem === "bg10" ? "selected" : ""}`}
                onClick={() => handleSelectedColor("bg10")}
              >
                {selectedItem === "bg10" && (
                  <>
                    <div className="color-overlay"></div>
                    <TiTick className="tick" />
                  </>
                )}
              </li>
            </ul>
          </div>
          <div className="catalog-font-list">
            <div className="catalog-font pt24 pb24 brd-b1">
              <div className="catalog-templates-head mb12">
                <p className="fs16 ls1 lh16">Font</p>
              </div>
              <div className="catalog-font-list df aic fww">
                {fontList.map((font, index) => (
                  <div
                    key={index}
                    className={`font-option ${
                      selectedFont === font ? "selected-font" : ""
                    }`}
                    onClick={() => handleFontSelected(font)}
                  >
                    {selectedFont === font && (
                      <div className="font-overlay"></div>
                    )}
                    {selectedFont === font && <TiTick className="tick" />}
                    <p
                      style={{ fontFamily: font }}
                      className="fs28 ls2 brd-b1 p8"
                    >
                      {"Aa"}
                    </p>
                    <p
                      style={{ fontFamily: font }}
                      className="fs14 mt8 ls2 pl8 pr8 pt4 pb4"
                    >
                      {font}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="catalog-cover pt24 pb24 brd-b1 ">
            <div className="catalog-templates-head mb12 v-center jcsb">
              <p className="fs16 ls1 lh16">Cover Image</p>
              <p
                className="catalog-cover-change fs14 fc4 ls2 tdu cp"
                onClick={handleOpenCover}
              >
                Change
              </p>
            </div>
            <div className="cover-img">
              <img src={catalogTwo} alt="cover" />
            </div>
          </div>
          <div className="publish-website pt24 pb24">
            <div className="catalog-templates-head mb32 fs14 ls1 lh16">
              <p className="fs16 mb12 ls1 lh16">Publish Your Website</p>
              <p className="fs14 lh16 ls1 fc5">
                You will need to connect your website to a domain to public. If
                you already have a domain you can connect here
              </p>
            </div>
            <div className="catalog-free-domain mb32 fs14 ls1 lh16">
              <p className="fs16 mb12 ">Your free domain</p>
              <p onClick={handleSelectedColorFreeDomain} className="fc1 cp">
                https://www.clickindia.com/business/clickindia-infomedia-pvt-ltd-7984919/
              </p>
            </div>
            <div className="catalog-custom-domain fs14 ls1 lh16">
              <p className="fs16 mb4">Already have a domain?</p>
              <div className="custom-domain-input v-center">
                <input
                  type="text"
                  placeholder="Enter domain URL"
                  className="bg6 flx75 mr16"
                />
                <div className="prem-feat br24 bg8 flx1 pt8 pb8 box-center fdc cp">
                  <p className="fs14 fc5 ls1 lh16 v-center">
                    <FaStar className="mr8 fc6" />
                    Premium Feature
                  </p>
                  <p className="fc9 fs12 tdu ls1 lh16">Activate Now</p>
                </div>
              </div>
              <p className="fs14 mt12 fc4 cp">
                Need help? Know how connect a custom domain
              </p>
            </div>
          </div>
          <div className="v-center jce born mt24 mb24">
            <button
              type="button"
              className="btn-blue bg1 br24 fs14 cp pl16 pr16 pt10 pb10 mr12"
            >
              Preview
            </button>
            <button
              type="button"
              className="btn-blue bg1 br24 fs14 cp pl16 pr16 pt10 pb10"
            >
              Publish Your Website
            </button>
          </div>
          {openCoverPopup && (
            <Popup
              onClose={closeCoverPopup}
              title={"Select or Upload Cover Images"}
            >
              <CoverGallery />
              <div className="df jce">
                <button
                  type="button"
                  className="btn-blue bg1 br24 fs14 cp pl16 pr16 pt10 pb10 mb12 df jce"
                  onClick={closeCoverPopup}
                >
                  Next
                </button>
              </div>
            </Popup>
          )}
          <ToastContainer position="bottom-right" />
        </div>
      </div>
    </div>
  );
};

export default Catalog;
