const CatalogData = {
  status: true,
  code: 200,
  error: "",
  data: {
    count: 21,

    result: [
      {
        id: "21",
        template_cat_id: "6",
        template_name: "product-template-5",
        sel: true,
      },
      {
        id: "1",
        template_cat_id: "6",
        template_name: "product-template-6",
        sel: false,
      },
      {
        id: "2",
        template_cat_id: "6",
        template_name: "product-template-7",
        sel: false,
      },
      {
        id: "3",
        template_cat_id: "6",
        template_name: "product-template-16",
        sel: false,
      },
      {
        id: "4",
        template_cat_id: "6",
        template_name: "product-template-8",
        sel: false,
      },
      {
        id: "5",
        template_cat_id: "6",
        template_name: "product-template-9",
        sel: false,
      },
      {
        id: "6",
        template_cat_id: "6",
        template_name: "product-template-11",
        sel: false,
      },
      {
        id: "7",
        template_cat_id: "6",
        template_name: "product-template-10",
        sel: false,
      },
      {
        id: "8",
        template_cat_id: "6",
        template_name: "product-template-13",
        sel: false,
      },
      {
        id: "9",
        template_cat_id: "6",
        template_name: "product-template-14",
        sel: false,
      },
      {
        id: "10",
        template_cat_id: "6",
        template_name: "product-template-15",
        sel: false,
      },
      {
        id: "11",
        template_cat_id: "6",
        template_name: "product-template-12",
        sel: false,
      },
      {
        id: "12",
        template_cat_id: "6",
        template_name: "product-template-17",
        sel: false,
      },
      {
        id: "13",
        template_cat_id: "6",
        template_name: "product-template-18",
        sel: false,
      },
      {
        id: "14",
        template_cat_id: "6",
        template_name: "product-template-19",
        sel: false,
      },
      {
        id: "15",
        template_cat_id: "6",
        template_name: "product-template-20",
        sel: false,
      },
      {
        id: "16",
        template_cat_id: "6",
        template_name: "product-template-21",
        sel: false,
      },
      {
        id: "17",
        template_cat_id: "6",
        template_name: "product-template-22",
        sel: false,
      },
      {
        id: "18",
        template_cat_id: "6",
        template_name: "product-template-23",
        sel: false,
      },
      {
        id: "19",
        template_cat_id: "6",
        template_name: "product-template-24",
        sel: false,
      },
      {
        id: "20",
        template_cat_id: "6",
        template_name: "product-template-25",
        sel: false,
      },
    ],
  },
};
export default CatalogData;
