import React, { useState } from "react";
import axios from "axios";
import "../components/CardLead.css";
import { BsCurrencyRupee } from "react-icons/bs";
import { IoLocationSharp, IoTicketSharp } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import {
  MdEmojiPeople,
  MdOutlineChildCare,
  MdOutlineEmail,
  MdCall,
  MdWhatsapp,
} from "react-icons/md";
import { BiChild } from "react-icons/bi";
import { GiTriplePlier } from "react-icons/gi";
import { FaHotel } from "react-icons/fa";
import { AiFillCalendar, AiOutlineClockCircle } from "react-icons/ai";
import { TbMathGreater } from "react-icons/tb";
import Soldout from "../assets/sold-out-image.jpg";
import Booked from "../assets/booked.jpg";
import { useSelector } from "react-redux";
import Tooltip from "./Tooltip";
// import { useDispatch } from "react-redux";
import trackEvent from "../utils/gaTracker";

const CardLead = ({
  lead,
  className,
  onClick,
  newLeadCount,
  updateFilter,
  param,
  toast,
}) => {
  const user = useSelector((state) => state.auth);
  const [leadResponse, setLeadResponse] = useState("0");

  const handleCardClick = () => {
    trackEvent("Buylead Page", "click", `Buylead Detail Popup`);
    onClick(lead);
  };
  const getBorderClass = (leadType) => {
    switch (leadType) {
      case "International" || "international":
        return "border-inbound";
      case "Domestic" || "domestic":
        return "border-domestic";
      case "Outbound" || "outbound":
        return "border-outbound";
      case "Inbound" || "inbound":
        return "border-international";
      default:
        return "brd1";
    }
  };
  if (!lead) {
    return null;
  }

  const { leadinfo, customerinfo } = lead;
  const qualityPercentage = lead.leadinfo.quality;
  function buy_lead_normal(leadid, type) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/services/buylead.php?fun=buy_lead`,
      headers: {},
      data: {
        id: leadid,
        type: type,
        auth_id: user.auth_id,
        param: param,
        filteredData: updateFilter,
      },
    }).then(function (response) {
      let resObj = response.data;
      setLeadResponse(resObj.status);
      toast.info(resObj.msg);
    });
  }
  const handleBuyLeadApi = (leadid) => {
    if (leadResponse === "2") {
      toast.warn("Please wait request is in under process");
    } else {
      setLeadResponse("2");
      buy_lead_normal(leadid, "nx");
    }
  };
  try {
    //customerinfo.name=utf8.decode(customerinfo.name);
    customerinfo.name = decodeURIComponent(escape(customerinfo.name));
  } catch (e) {
    customerinfo.name = customerinfo.name;
  }
  let displayName = customerinfo.name.split(" ")[0]; // Splits by space
  if (displayName.length > 15) {
    displayName = displayName.substring(0, 15); // Limits to 15 characters
  }
  return (
    <div
      className={`lead-card  ${getBorderClass(
        leadinfo.leadtype
      )} br16 pr mb16 ${className} w100`}
      onClick={handleCardClick}
    >
      {leadinfo.lead_booked === 1 && leadinfo.leadstaus === "sold" && (
        <div className="booked-image">
          <img src={Booked} alt="Booked" />
        </div>
      )}

      {leadinfo.leadstaus === "sold" && leadinfo.lead_booked === 0 && (
        <div className="sold-out-image">
          <img src={Soldout} alt="Sold Out" />
        </div>
      )}

      {leadinfo.lead_booked === 1 && leadinfo.leadstaus !== "sold" && (
        <div className="booked-image">
          <img src={Booked} alt="Booked" />
        </div>
      )}

      <div className="v-center outer-top brd-b1 jcsb">
        <div className="pl16 pr16 pt12 pb12 df flx1 buy-lead-time">
          <div className="v-center lead-time">
            <AiOutlineClockCircle className="mr4 fc19" />
            <Tooltip title="Lead Recived Time">
              <span className="fc5 fs14 mr4">{leadinfo.leadtime}</span>
            </Tooltip>
          </div>
          <Tooltip title="Destination">
            <p
              className="fc20 fs18 destination-text"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "24Ch",
              }}
            >
              {leadinfo.leaddestination}
            </p>
          </Tooltip>
        </div>
        <div className="buylead-time-info">
          <ul className="v-center mr8">
            <li className="df aic">
              <AiFillCalendar className="mr8 fs18" />
              <Tooltip title="Travel Date">
                <p className="fc14 fs14">{leadinfo.traveldates} </p>
              </Tooltip>
            </li>
            <li className="ml8">|</li>
            <li className="ml8 df">
              <Tooltip title="Travel Duration">
                {" "}
                <p className="fc14 fs14">{leadinfo.duration}</p>
              </Tooltip>
            </li>
            <li className="ml8">|</li>
            <li className="ml8 df aic">
              <BsCurrencyRupee />
              <Tooltip title="Total Budget">
                <p className="fc14 fs14">{leadinfo.budget}</p>
              </Tooltip>
            </li>
          </ul>
        </div>
      </div>
      <div className="leads-content df jcsb pl16 pr16 pt12 pb12">
        <div className="flx25 df fdc jcsb mr16 c-one buy-one">
          <Tooltip title="From City">
            {" "}
            <div className="df aic">
              <IoLocationSharp className="fc5 mr8 fs18" />
              <p className="text-row ls1 fs14 mr4 fc5">From:</p>
              <p
                className="text-row ls1 fc14 fs14 fw5"
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "15ch",
                }}
              >
                {leadinfo.fromcity}
              </p>
            </div>
          </Tooltip>
          <Tooltip title="Trip Theme">
            <div className="df aic mt12 captw">
              <GiTriplePlier className="fc5 mr8 fs18" />
              <p className="text-ellipsis ls1 fs14 fc14 fw5">
                {leadinfo.theme.length > 16
                  ? `${leadinfo.theme.slice(0, 16)}..`
                  : leadinfo.theme}
              </p>
            </div>
          </Tooltip>
          <div className="df aic mt12">
            <p className="text-row ls1  fc5 fs14 mr4">Pax:</p>
            {leadinfo.paxdetails === "Group" ||
            leadinfo.paxdetails === "Groups" ? (
              <span>{leadinfo.paxdetails}</span>
            ) : (
              <>
                <MdEmojiPeople className="fc5 fs18" />
                <Tooltip title="No of Adult">
                  <span className="fs14 fc14 fw5">
                    ({leadinfo.adults || 0})
                  </span>
                </Tooltip>
                <BiChild className="fc5 fs18 ml4" />
                <Tooltip title="No of Kids">
                  <span className="fs14 fc14 fw5">({leadinfo.child || 0})</span>
                </Tooltip>
                <MdOutlineChildCare className="fc5 fs18 ml4" />
                <Tooltip title="No of Infants">
                  <span className="fs14 fc14 fw5">
                    ({leadinfo.infants || 0})
                  </span>
                </Tooltip>
              </>
            )}
          </div>
          <Tooltip title="Lead Quality Score">
            <div className="df aic mt12 w100">
              <p className="text-row ls1 fs14 mr4 fc5">Quality:</p>
              <div
                className="quality-bar"
                style={{
                  backgroundColor: "#B7B7B7",
                  width: "100%",
                  height: "5px",
                  display: "block",
                  borderRadius: "24px",
                  flex: "0 0 50%",
                }}
              >
                <div
                  style={{
                    width: `${qualityPercentage}%`,
                    height: "100%",
                    backgroundColor: "#61850B",
                    borderRadius: "24px",
                  }}
                ></div>
              </div>
            </div>
          </Tooltip>
        </div>
        <div className="flx25 df fdc jcsb c-one  buy-two">
          <Tooltip title="Hotel Type">
            <div className="v-center">
              <FaHotel className="fc5 mr8" />
              <p className="text-row ls1 fs14 fc14 fw5">{leadinfo.hoteltype}</p>
            </div>
          </Tooltip>
          <Tooltip title="Tickets">
            <div className="v-center mt12">
              <IoTicketSharp className="fc5 mr8" />
              <p className="text-row ls1 fs14 fc14 fw5">{leadinfo.tickets}</p>
            </div>
          </Tooltip>
          <Tooltip title="Travel With">
            <div className="v-center mt12">
              <IoIosPeople className="fc5 mr8" />
              <span className="text-row ls1 fs14 fc14 fw5">
                {leadinfo.travel_with}
              </span>
            </div>
          </Tooltip>
          <div className="df mt12">
            <Tooltip title="Lead Source">
              <div className="v-center fww">
                <span className="text-row ls1 fs14 mr4 fc5">Source:</span>
                <span className="text-row ls1 fs14 fc14 fw5">
                  {leadinfo.leadsource}
                </span>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flx25 df fdc jcsb c-one  buy-three">
          <Tooltip title="Need To Book">
            <div className="v-center">
              <p className="text-row ls1 fs14 fc21">{leadinfo.needtobook}</p>
            </div>
          </Tooltip>
          <div className="df mt12">
            <Tooltip title="No of Times Sold">
              <div className="v-center w100">
                <p className="text-row ls1 fs14 fc5">Sold:&nbsp;</p>
                <p className="text-row ls1 fs14 fc14 fw5">
                  {leadinfo.nooftimesleadsold}{" "}
                  {leadinfo.nooftimesleadsold > 1 ? "Times" : "Time"}
                </p>
              </div>
            </Tooltip>
            {/* <div className="v-center w100">
              <input
                type="checkbox"
                name="block"
                value="blockOthers"
                className="mr8"
              />
              <p className="fs12 fc14 fw6">Block Others?</p>
            </div> */}
          </div>
        </div>
        <div className="flx25 v-center fdc c-one  buy-four">
          <div className="v-center">
            <p className="text-row ls1 fw5 fs14 mr4 fc20 captw">
              {displayName} {customerinfo.age}
            </p>
          </div>
          <Tooltip title="Customer Since">
            <div className="v-center mt12">
              <p className="text-row ls1 fs14 mr4 fc5">Since:</p>
              <p className="text-row ls1 fs14 mr4 fc14">
                {customerinfo.customersince}
              </p>
            </div>
          </Tooltip>
          <div className="v-center mt12">
            {customerinfo.whatsapp_verify === "1" && (
              <Tooltip title="Available On Whatsapp">
                {" "}
                <MdWhatsapp className="fs24 mr8 fc5" />
              </Tooltip>
            )}
            {customerinfo.email_verify === "1" && (
              <Tooltip title="Email verified">
                {" "}
                <MdOutlineEmail className="fs24 mr8 fc5" />
              </Tooltip>
            )}
            {customerinfo.phone_verify === "1" && (
              <Tooltip title="Phone verified">
                <MdCall className="fs22 fc5" />
              </Tooltip>
            )}
          </div>
          <div className="v-center mt12 buy-now-btn">
            {leadinfo.lead_booked !== 1 &&
              leadinfo.leadstaus !== "sold" &&
              leadResponse !== 1 &&
              leadinfo.isbuy !== 1 && (
                <button
                  className={`${
                    leadResponse === 1 || leadinfo.isbuy === 1 ? "bg4" : "bg1"
                  } pt12 pb12 pl24 pr24 fs14 fc3 fw5 br24 ls1 cp`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleBuyLeadApi(lead.id);
                    trackEvent("Buylead Page", "click", ` Buylead Buy Now`);
                  }}
                >
                  {leadResponse === 1 || leadinfo.isbuy === 1
                    ? "Bought"
                    : "Buy Now"}
                </button>
              )}
            {leadinfo.leadstaus !== "sold" &&
              (leadResponse === 1 || leadinfo.isbuy === 1) && (
                <button
                  className={`${
                    leadResponse === 1
                      ? "bg4"
                      : leadinfo.isbuy === 1
                      ? "bg4"
                      : "bg1"
                  } pt12 pb12 pl24 pr24 fs14 fc3 fw5 br24 ls1 cp`}
                  disabled
                >
                  {leadResponse === 1
                    ? "Bought"
                    : leadinfo.isbuy === 1
                    ? "Bought"
                    : "Buy Now"}
                </button>
              )}
          </div>
        </div>
      </div>
      <div className="greater-than-icon v-center" onClick={handleCardClick}>
        <Tooltip title="Lead Detail">
          <TbMathGreater className="cp fs18 fc3" />
        </Tooltip>
      </div>
    </div>
  );
};

export default CardLead;
