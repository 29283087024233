// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bannere-container {
  background-color: #fce8e6;
  /* background-color: #406dc9; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 8px 0;
}
.fc_yellow {
  color: #ffff21;
}
.fc_blackish {
  color: #485288;
}
.bg_yellow {
  background-color: #ffff21;
}
.fc_blue {
  color: #000;
}
/* .banner-btn {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
} */
.close-banner-btn {
    top: -12px;
    right: -12px;
    padding: 4px;
    font-size: 14px;
}
.ht-logo img {
  width: 24px;
}
/* .banner-subtext{
    padding-left:8%;
    padding-right:2%;
} */
.banner-btn {
  background-color: #594cda;
  padding:8px 16px;
  color: #fff;
}
.banner-subtext2{
  flex:0 0 100%;
  max-width: 99%;
}
.banner-subtext{
    flex:0 0 88%;
    max-width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/components/Banner.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,2CAA2C;EAC3C,aAAa;AACf;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,WAAW;AACb;AACA;;;;;GAKG;AACH;IACI,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;AACA;EACE,WAAW;AACb;AACA;;;GAGG;AACH;EACE,yBAAyB;EACzB,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,aAAa;EACb,cAAc;AAChB;AACA;IACI,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".bannere-container {\r\n  background-color: #fce8e6;\r\n  /* background-color: #406dc9; */\r\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;\r\n  margin: 8px 0;\r\n}\r\n.fc_yellow {\r\n  color: #ffff21;\r\n}\r\n.fc_blackish {\r\n  color: #485288;\r\n}\r\n.bg_yellow {\r\n  background-color: #ffff21;\r\n}\r\n.fc_blue {\r\n  color: #000;\r\n}\r\n/* .banner-btn {\r\n  border-top-left-radius: 24px;\r\n  border-bottom-left-radius: 24px;\r\n  border-top-right-radius: 0;\r\n  border-bottom-right-radius: 0;\r\n} */\r\n.close-banner-btn {\r\n    top: -12px;\r\n    right: -12px;\r\n    padding: 4px;\r\n    font-size: 14px;\r\n}\r\n.ht-logo img {\r\n  width: 24px;\r\n}\r\n/* .banner-subtext{\r\n    padding-left:8%;\r\n    padding-right:2%;\r\n} */\r\n.banner-btn {\r\n  background-color: #594cda;\r\n  padding:8px 16px;\r\n  color: #fff;\r\n}\r\n.banner-subtext2{\r\n  flex:0 0 100%;\r\n  max-width: 99%;\r\n}\r\n.banner-subtext{\r\n    flex:0 0 88%;\r\n    max-width: 90%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
