// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-info ul li:nth-child(even) {
  background-color: #fafafa;
}

.profile-info ul li:nth-child(odd) {
  background-color: #fff;
}
.profile-info {
  padding: 0 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Popup/PlanPopup.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,eAAe;AACjB","sourcesContent":[".profile-info ul li:nth-child(even) {\r\n  background-color: #fafafa;\r\n}\r\n\r\n.profile-info ul li:nth-child(odd) {\r\n  background-color: #fff;\r\n}\r\n.profile-info {\r\n  padding: 0 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
