import React, { useState, useRef, useEffect } from "react";
import "../Forms/StatusUpdateForm.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const StatusUpdateForm = ({
  data,
  onUpdateStatus,
  selectedStatus,
  onClose,
  reviewTravelMessage,
  setReviewTravelMessage,
  eventLabel,
  eventCategory
}) => {
  const [status, setStatus] = useState(() => {
    if (selectedStatus.booking_status === "1") {
      return "Advance Received";
    } else if (selectedStatus.booking_status === "2") {
      return "Full Payment Received";
    } else {
      return selectedStatus ? selectedStatus.status : "";
    }
  });

  const [followupDate, setFollowupDate] = useState(null);
  const [comment, setComment] = useState(selectedStatus?.comment || "");
  const [travelDates, setTravelDates] = useState(
    selectedStatus?.tbl_lead_travel_date
      ? moment(selectedStatus.tbl_lead_travel_date, "DD-MMM-YY").toDate()
      : null
  );
  const [destination, setDestination] = useState(
    selectedStatus?.tbl_lead_destination_value || ""
  );
  const [budget, setBudget] = useState(selectedStatus?.fk_tbl_budget_bkp || "");
  const [adults, setAdults] = useState(selectedStatus?.pax?.adult || 0);
  const [children, setChildren] = useState(selectedStatus?.pax?.child || 0);
  const [infants, setInfants] = useState(selectedStatus?.pax?.infants || 0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showStatusMessage, setShowStatusMessage] = useState(false);
  const [showStatusMessageTravelDate1, setShowStatusMessageTravelDate1] =
    useState(false);
  const [showStatusMessageTravelDate2, setShowStatusMessageTravelDate2] =
    useState(false);
  const [showStatusMessage3, setShowStatusMessage3] = useState(false);
  const [showStatusMessage4, setShowStatusMessage4] = useState(false);
  const currentDate = new Date();

  const statusMessage = "Please select follow up date.";
  const showStatusMessageTravelDatemsg1 = "Please select date";
  const showStatusMessageTravelDatemsg2 = "Date should be less than today";
  const showStatusMessagemsg3 = "Please enter destination";
  const showStatusMessagemsg4 = "Please enter amount";

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setIsDropdownOpen(false);
    //checkStatus(newStatus); // Check the status when it changes
  };

  const handleFollowupDateChange = (date) => {
    //alert(date);
    setFollowupDate(date);
  };

  const handleCommentsChange = (e) => {
    setComment(e.target.value);
  };

  const handleTravelDatesChange = (date) => {
    setTravelDates(date);
  };

  const handleDestinationChange = (e) => {
    setDestination(e.target.value);
  };

  const handleAdultsChange = (e) => {
    setAdults(parseInt(e.target.value));
  };

  const handleChildrenChange = (e) => {
    setChildren(parseInt(e.target.value));
  };

  const handleInfantsChange = (e) => {
    setInfants(parseInt(e.target.value));
  };
  const handleBudgetChange = (e) => {
    setBudget(e.target.value);
  };

  const handleUpdate = () => {
    setShowStatusMessage(false);
    setShowStatusMessage3(false);
    setShowStatusMessage4(false);
    if (
      shouldShowAdditionalFieldsMand() &&
      (followupDate == "" || followupDate == null) &&
      status !== "junk" &&
      status !== "Full Payment Received" &&
      selectedStatus.booking_status !== "2"
    ) {
      setShowStatusMessage(true);
      return false;
    }
    if (
      showAdditionalFieldsForStatus.includes(status) &&
      (travelDates == "" || travelDates == null)
    ) {
      setShowStatusMessageTravelDate2(false);
      setShowStatusMessageTravelDate1(true);
      return false;
    }
    if (
      showAdditionalFieldsForStatus.includes(status) &&
      (destination == "" || destination == null)
    ) {
      setShowStatusMessage3(true);
      return false;
    }

    if (
      showAdditionalFieldsForStatus.includes(status) &&
      (budget.trim() == "" || budget == null || budget <= 0)
    ) {
      setShowStatusMessage4(true);
      return false;
    }
    /*const givenDate = new Date(travelDates);
     if(showAdditionalFieldsForStatus.includes(status) &&  (givenDate > currentDate)){
      setShowStatusMessageTravelDate1(false);
      setShowStatusMessageTravelDate2(true);
      return false; 
     }*/

    const updatedStatus = {
      ...selectedStatus,
      status: status,
      followupdate: followupDate
        ? moment(followupDate).format("DD-MMM-YY h:mm a")
        : null,
      comment: comment,
      travelStartDate: travelDates
        ? moment(travelDates).format("DD-MMM-YY")
        : null,
      tbl_lead_travel_date: travelDates
        ? moment(travelDates).format("DD-MMM-YY")
        : null,
      tbl_lead_destination_value: destination,
      fk_tbl_budget_bkp: budget,
      pax: {
        adult: adults,
        child: children,
        infants: infants,
      },
    };

    // Call the callback function to send the updated data back to the parent component
    onUpdateStatus(updatedStatus);
    onClose();
  };

  const customDateFormat = "dd/MM/yyyy h:mm aa";

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //array of status values that should show the additional fields
  const showAdditionalFieldsForStatus = [
    "Booked",
    "Advance Received",
    "Full Payment Received",
  ];

  // Function to determine if additional fields should be displayed
  const shouldShowAdditionalFields = () => {
    return (
      // status === "Booked" ||
      // status === "Advance Received" ||
      // status === "Full Payment Received"
      status === "Junk"
      //status ==='No Response'
    );
  };
  const shouldShowAdditionalFieldsMand = () => {
    return (
      // status === "Booked" ||
      // status === "Advance Received" ||
      // status === "Full Payment Received"
      status === "Hot" || status === "Followup"
      //status ==='No Response'
    );
  };

  const checkStatus = (newStatus) => {
    if (
      ["Booked", "Advance Received", "Full Payment Received"].includes(
        newStatus
      )
    ) {
      setShowStatusMessage(true);
    } else {
      setShowStatusMessage(false);
    }
  };

  return (
    <div className="status-update-form">
      <div className="form-row-status">
        <div className="up-status">
          <label htmlFor="status" className="fc15 fw6 fs14 mb12 ls1">Status</label>
          <div
            className={`status-dropdown ${isDropdownOpen ? "open" : ""}`}
            ref={dropdownRef}
          >
            <div
              className="status-dropdown-trigger"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              style={{ textTransform: "capitalize" }}
            >
              {status === "Full Payment Received"
                ? status
                : selectedStatus.booking_status === "1"
                ? "Advance Received"
                : (selectedStatus.booking_status === "2"
                    ? "Full Payment Received"
                    : status) || "---- Select Status ----"}
            </div>
            {isDropdownOpen && (
              <div className="status-dropdown-options">
                {selectedStatus.booking_status !== "1" &&
                  selectedStatus.booking_status !== "2" && (
                    <div
                      className={`status-dropdown-option ${
                        status === "Booked" ? "selected" : ""
                      }`}
                      onClick={() => handleStatusChange("Booked")}
                    >
                      Booked
                    </div>
                  )}

                {selectedStatus.booking_status !== "2" && (
                  <div
                    className={`status-dropdown-option ${
                      status === "Advance Received" ? "selected" : ""
                    }`}
                    onClick={() => handleStatusChange("Advance Received")}
                  >
                    Advance Received
                  </div>
                )}
                {selectedStatus.booking_status !== "2" && (
                  <div
                    className={`status-dropdown-option ${
                      status === "Full Payment Received" ? "selected" : ""
                    }`}
                    onClick={() => handleStatusChange("Full Payment Received")}
                  >
                    Full Payment Received
                  </div>
                )}

                {isDropdownOpen &&
                  status != "Booked" &&
                  selectedStatus.booking_status !== "1" &&
                  selectedStatus.booking_status !== "2" && (
                    <>
                      <div
                        className={`status-dropdown-option ${
                          status === "Hot" ? "selected" : ""
                        }`}
                        onClick={() => handleStatusChange("Hot")}
                      >
                        Hot (Proposal Sent)
                      </div>
                      <div
                        className={`status-dropdown-option ${
                          status === "Followup" ? "selected" : ""
                        }`}
                        onClick={() => handleStatusChange("Followup")}
                      >
                        Followup
                      </div>
                      <div
                        className={`status-dropdown-option ${
                          status === "No Response" ? "selected" : ""
                        }`}
                        onClick={() => handleStatusChange("No Response")}
                      >
                        No Response
                      </div>
					  <div
                        className={`status-dropdown-option ${
                          status === "Not Interested" ? "selected" : ""
                        }`}
                        onClick={() => handleStatusChange("Not Interested")}
                      >
                        Not Interested
                      </div>
                      <div
                        className={`status-dropdown-option ${
                          status === "Irrelevant" ? "selected" : ""
                        }`}
                        onClick={() => handleStatusChange("Irrelevant")}
                      >
                        Irrelevant
                      </div>
                      <div
                        className={`status-dropdown-option ${
                          status === "Junk" ? "selected" : ""
                        }`}
                        onClick={() => handleStatusChange("Junk")}
                      >
                        Junk
                      </div>
					  
                    </>
                  )}
              </div>
            )}
            {/* {showStatusMessage && (
            <div className="status-message fc4 mt16 fs16">{statusMessage}</div>
          )} */}
          </div>
        </div>
        {!shouldShowAdditionalFields() && (
          <div className="calendar calendar-input df fdc">
            <label htmlFor="followupDate" className="fc15 fw6 fs14 ls1">Follow-up Date</label>
            <DatePicker
              dateFormat={customDateFormat}
              selected={followupDate}
              onChange={handleFollowupDateChange}
              placeholderText="---- Select Date ----"
              showIcon
              //isClearable={true}
              minDate={currentDate}
              //showMonthDropdown
              //showYearDropdown
              dropdownMode="select"
              peekNextMonth
              showTimeSelect
            />
          </div>
        )}
        {showStatusMessage && (
          <div className="status-message fc4 df jce w100 mr48 fs16 ">
            {statusMessage}
          </div>
        )}
      </div>
      {reviewTravelMessage && (
        <div className="status-message fc4 mt16 fs16">
          {reviewTravelMessage}
        </div>
      )}
      {showAdditionalFieldsForStatus.includes(status) && (
        <div className="scrollable-popup-content">
          <div className="df jcsb mt16 first">
            <div className="calendar calendar-input">
              <label htmlFor="travelDates" className="fc15 fw6 fs14 mb12 ls1">Travel Start Date</label>
              <DatePicker
                dateFormat={customDateFormat}
                selected={travelDates}
                onChange={handleTravelDatesChange}
                placeholderText="---- Select Travel Start Date ----"
                showIcon
              />
              {showStatusMessageTravelDate1 && (
                <div className="status-message fc4 mt8 w100 mr48 fs16 ">
                  {showStatusMessageTravelDatemsg1}
                </div>
              )}
              {showStatusMessageTravelDate2 && (
                <div className="status-message fc4 mt8 w100 mr48 fs16 ">
                  {showStatusMessageTravelDatemsg2}
                </div>
              )}
            </div>

            <div className="up-status input-design dest" >
              <label htmlFor="destination" className="fc15 fw6 fs14 mb12 ls1">Destination</label>
              <input
                type="text"
                id="destination"
                value={destination}
                onChange={handleDestinationChange}
                placeholder="Destination..."
              />
              {showStatusMessage3 && (
                <div className="status-message fc4 mt8 w100 mr48 fs16 ">
                  {showStatusMessagemsg3}
                </div>
              )}
            </div>
          </div>

          <div className="df jcsb mt16 first">
            <div className="input-design up-status">
              <label htmlFor="budget" className="fc15 fw6 fs14 mb12 ls1">Amount (₹)</label>
              <input
                type="number"
                id="budget"
                value={budget}
                onChange={handleBudgetChange}
                placeholder="Amount"
              />
              {showStatusMessage4 && (
                <div className="status-message fc4 mt8 w100 mr48 fs16 ">
                  {showStatusMessagemsg4}
                </div>
              )}
            </div>

            <div className="input-design up-status">
              <label htmlFor="adults" className="fc15 fw6 fs14 mb12 ls1">Number of Adults</label>
              <input
                type="number"
                id="adults"
                value={adults}
                min={1}
                onChange={handleAdultsChange}
              />
            </div>
          </div>
          <div className="df jcsb mt16 first">
            <div className="input-design up-status">
              <label htmlFor="children" className="fc15 fw6 fs14 mb12 ls1">Number of Children</label>
              <input
                type="number"
                id="children"
                value={children}
                min={0}
                onChange={handleChildrenChange}
              />
            </div>

            <div className="input-design up-status">
              <label htmlFor="infants" className="fc15 fw6 fs14 mb12 ls1">Number of Infants</label>
              <input
                type="number"
                id="infants"
                value={infants}
                min={0}
                onChange={handleInfantsChange}
              />
            </div>
          </div>
        </div>
      )}

      <div className="comments-input mt16">
        <label htmlFor="comments" className="fc15 fw6 fs14 mb12 ls1">Comments</label>
        <textarea
          type="text"
          id="comments"
          value={comment}
          onChange={handleCommentsChange}
          placeholder="Any Comments..."
          className="comments p12 br4"
        />
      </div>

      <div className="button-container mt32">
        <button type="button" className="btn-cancel" onClick={onClose}>
          Cancel
        </button>
        <button
          type="button"
          className="update-button btn-blue box-center"
          onClick={handleUpdate}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default StatusUpdateForm;
