// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-container{
    height: 80%;
}
.ql-editor{
    background-color: #fff;
}
.package-policies li {
    list-style-type: circle;
    margin-left: 16px;
    letter-spacing: .4px;
    line-height: 18px;
    padding-left: 8px;
    line-height: 24px;
    margin-top: 12px;
}
.package-policies{
    border-left: 2px solid #594cda;
    background-color: #f9f9f9;
    padding: 12px;
    margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/PackageBuilder/TextEditor.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,8BAA8B;IAC9B,yBAAyB;IACzB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".ql-container{\r\n    height: 80%;\r\n}\r\n.ql-editor{\r\n    background-color: #fff;\r\n}\r\n.package-policies li {\r\n    list-style-type: circle;\r\n    margin-left: 16px;\r\n    letter-spacing: .4px;\r\n    line-height: 18px;\r\n    padding-left: 8px;\r\n    line-height: 24px;\r\n    margin-top: 12px;\r\n}\r\n.package-policies{\r\n    border-left: 2px solid #594cda;\r\n    background-color: #f9f9f9;\r\n    padding: 12px;\r\n    margin-bottom: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
