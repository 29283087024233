// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.tooltip {
  position: absolute;
  background-color: black;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #000;
  top: 60%;
  left: 50%;
  transform: translateX(-20%) rotate(45deg);
  z-index: -1;
}

.icon:hover .tooltip {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tooltip.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,UAAU;EACV,YAAY;EACZ,SAAS;EACT,2BAA2B;EAC3B,mBAAmB;EACnB,UAAU;EACV,oCAAoC;EACpC,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,QAAQ;EACR,SAAS;EACT,yCAAyC;EACzC,WAAW;AACb;;AAEA;EACE,UAAU;AACZ","sourcesContent":["\r\n.tooltip {\r\n  position: absolute;\r\n  background-color: black;\r\n  color: #fff;\r\n  padding: 5px 10px;\r\n  border-radius: 4px;\r\n  font-size: 12px;\r\n  z-index: 1;\r\n  bottom: 100%;\r\n  left: 50%;\r\n  transform: translateX(-50%);\r\n  white-space: nowrap;\r\n  opacity: 0;\r\n  transition: opacity 0.2s ease-in-out;\r\n  min-width: 24px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.tooltip::after {\r\n  content: \"\";\r\n  position: absolute;\r\n  width: 12px;\r\n  height: 12px;\r\n  background-color: #000;\r\n  top: 60%;\r\n  left: 50%;\r\n  transform: translateX(-20%) rotate(45deg);\r\n  z-index: -1;\r\n}\r\n\r\n.icon:hover .tooltip {\r\n  opacity: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
