import React, { useState, useRef } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "./Tnc.css";
import { FaPencilAlt } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import trackEvent from "../utils/gaTracker";

const TextEditor = ({ insertData, removeTNCData, termsAndConditions }) => {
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [policies, setPolicies] = useState(termsAndConditions);
  const [newPolicy, setNewPolicy] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editPolicyId, setEditPolicyId] = useState(null); // Track the edited policy
  const quillRef = useRef(null);

  var modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const handleProcedureContentChange = (content) => {
    setContent(content);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleAddButtonClick = (e) => {
    e.preventDefault();
    if (title === "" || content === "") {
      alert("Terms & Conditions Title Or Description is empty!");
      return false;
    }

    if (title && content) {
      if (isEditing) {
        const updatedPolicies = policies;
        const nPolicy = { id: editPolicyId, title: title, content: content };
        updatedPolicies[editPolicyId] = nPolicy;
        //setNewPolicy(nPolicy);
        setPolicies(updatedPolicies);
        setIsEditing(false);
        setEditPolicyId(null);
        //insertData(updatedPolicies)
        insertData(nPolicy);
        trackEvent("Profile Page", "click", "T&C - Update T&C");
      } else {
        const nPolicy = { id: policies.length, title: title, content: content };
        setNewPolicy(nPolicy);
        setPolicies([...policies, nPolicy]);
        insertData(nPolicy);
        trackEvent("Profile Page", "click", "T&C - ADD");
      }

      setTitle("");
      setContent("");
      if (quillRef.current) {
        quillRef.current.getEditor().setText("");
      }
    }
  };

  const handleEditPolicy = (index) => {
    trackEvent("Profile Page", "click", "T&C - Edit");
    const policyToEdit = policies[index];
    if (policyToEdit) {
      setTitle(policyToEdit.title);
      setContent(policyToEdit.content);
      setEditPolicyId(index);
      setIsEditing(true);
    }
  };

  const handleDeletePolicy = (index) => {
    trackEvent("Profile Page", "click", "T&C - Delete");
    const updatepolicies = [...policies];
    updatepolicies.splice(index, 1);
    setPolicies(updatepolicies);
    removeTNCData(index);
  };

  return (
    <div className="flx100">
      {policies.map((item, index) => (
        <div className="package-policies" key={item.id}>
          <div className="title-policies-section">
            <div className="title-policies v-center jcsb">
              <h3 className="ls2 fs20 mb12 v-center fc1 fw5">{item.title}</h3>
              <div className="df jce cp">
                <FaPencilAlt
                  className="icon edit-icon mr8"
                  onClick={() => handleEditPolicy(index)}
                />
                <BsTrash
                  className="icon delete-icon"
                  onClick={() => handleDeletePolicy(index)}
                />
              </div>
            </div>
            <div
              className="policies-description"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </div>
        </div>
      ))}

      <form>
        <div className="pt16 bg5">
          <div className="form-group-settings mt12 req-star">
            <label className="labels-heading fw6">
              Title<span>*</span>
            </label>
            <input
              type="text"
              id="currency"
              placeholder="Enter Title"
              value={title}
              onChange={handleTitleChange}
            />
          </div>
          <div className="req-star">
            <label className="labels-heading fw6">
              Description<span>*</span>
            </label>
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              placeholder="write your description ...."
              onChange={handleProcedureContentChange}
              style={{ height: "220px", marginTop: "8px" }}
              ref={quillRef}
              value={content}
            ></ReactQuill>
          </div>
          <div className="v-center jce born mb24 mt16">
            <button
              className={`btn-blue bg1 br24 fs14 cp pl16 pr16 pt10 pb10`}
              onClick={handleAddButtonClick}
            >
              {isEditing ? "Update Policies" : "Add Policies"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TextEditor;
