import React from 'react';
import PropTypes from 'prop-types';
import '../components/AppBarButton.css';

const AppBarButton = ({ content, color, icon ,marginLeft,marginRight,onClick,fontColor,disabled  }) => {
  const buttonStyle = {
    backgroundColor: color,
    padding: '12px 20px',
    borderRadius: "64px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    fontSize: "14px",
    marginLeft: `${marginLeft}px`,
    marginRight: `${marginRight}px`,
    border: "none",
    color: fontColor,
    cursor: disabled ? "not-allowed" : "pointer", 
    position: "relative", 
  };
      
  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff70", 
    borderRadius: "64px",
    display: disabled ? "block" : "none", 
  };

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <button
      style={buttonStyle}
      onClick={handleClick}
      className='ls2 fc3'
      disabled={disabled}
    >
      {icon && <span className="button-icon">{icon}</span>}
      {content}
      <div style={overlayStyle} />
    </button>
  );
};

AppBarButton.propTypes = {
  content: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.element, 
  onClick: PropTypes.func,
  blurred: PropTypes.bool,
};

export default AppBarButton;