import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";

import Card from "../components/Card";
import "../pages/PackageBuilder.css";
import InnerHeader from "../components/InnerHeader";
import RadioButton from "../components/RadioButton";
import { FaAngleDown, FaCheck, FaCloudUploadAlt } from "react-icons/fa";
import PackageMultiCheckBox from "../components/PackageMultiCheckBox";
import CreateItinerary from "./CreateItinerary";
import SidePopup from "../components/Popup/SidePopup";
import image from "../assets/package.jpg";
import { SearchDropdown } from "../components/SearchDropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import SmallLoader from "../components/SmallLoader.js";
import Popup from "../components/Popup/Popup";

const PackageBuilder = () => {
  const { pid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectBuildPackage, setSelectBuildPackage] = useState("createNew");
  const [selectPackageVisibility, setSelectPackageVisibility] =
    useState("public");
  const [selectPackageType, setSelectPackageType] = useState("FL");

  const user = useSelector((state) => state.auth);
  const [authId, setAuthID] = useState(user.auth_id);
  const [baseUrl, setBaseUrl] = useState(user.base_url);
  const [siteUrl, setSiteUrl] = useState(user.site_url);

  const [selectedTheme, setSelectedTheme] = useState([]);
  const [duration, setDuration] = useState("");
  const [selectedDurationLabel, setSelectedDurationLabel] = useState("");
  const [selectedDurationValue, setSelectedDurationValue] = useState("");
  const [showDurationDropdown, setShowDurationDropdown] = useState(false);
  const [showCreateItinerary, setShowCreateItinerary] = useState(
    pid === "" || pid === undefined ? false : true
  );
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isUploadDocumentSelected, setUploadDocumentSelected] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isExtractionInProgress, setExtractionInProgress] = useState(false);

  const [libraryPopupVisible, setLibraryPopupVisible] = useState(false);
  const [isCopyLibrary, setCopyLibrary] = useState(false);
  const [libraryCards, setLibraryCards] = useState();
  const [tourDestination, setTourDestination] = useState([]);
  const [buildPackageId, setBuildPackageId] = useState(
    pid === "" || pid === undefined ? "" : pid
  );
  const dropdownRef = useRef(null);
  const [pdfMsgStatus, setPdfMsgStatus] = useState(false);

  const durationOptions = [
    { label: "2D/1N", value: "2 Days/ 1 Nights" },
    { label: "3D/2N", value: "3 Days/ 2 Nights" },
    { label: "4D/3N", value: "4 Days/ 3 Nights" },
    { label: "5D/4N", value: "5 Days/ 4 Nights" },
    { label: "6D/5N", value: "6 Days/ 5 Nights" },
    { label: "7D/6N", value: "7 Days/ 6 Nights" },
    { label: "8D/7N", value: "8 Days/ 7 Nights" },
    { label: "9D/8N", value: "9 Days/ 8 Nights" },
    { label: "10D/9N", value: "10 Days/ 9 Nights" },
    { label: "11D/10N", value: "11 Days/ 10 Nights" },
    { label: "12D/11N", value: "12 Days/ 11 Nights" },
    { label: "13D/12N", value: "13 Days/ 12 Nights" },
    { label: "14D/13N", value: "14 Days/ 13 Nights" },
    { label: "15D/14N", value: "15 Days/ 14 Nights" },
    { label: "16D/15N", value: "16 Days/ 15 Nights" },
    { label: "17D/16N", value: "17 Days/ 16 Nights" },
    { label: "18D/17N", value: "18 Days/ 17 Nights" },
    { label: "19D/18N", value: "19 Days/ 18 Nights" },
    { label: "20D/19N", value: "20 Days/ 19 Nights" },
    { label: "21D/20N", value: "21 Days/ 20 Nights" },
    { label: "22D/21N", value: "22 Days/ 21 Nights" },
  ];
  const handlePackageDurationSelect = (selectedDuration) => {
    setSelectedDurationLabel(selectedDuration.label);
    setSelectedDurationValue(selectedDuration.value);
    setShowDurationDropdown(false);
  };
  const themeOptions = [
    { label: "Adventure", value: "adventure" },
    { label: "Art", value: "art" },
    { label: "Backpacking", value: "backpacking" },
    { label: "Beach", value: "beach" },
    { label: "Culture", value: "culture" },
    { label: "Desert", value: "desert" },
    { label: "Eco-Friendly", value: "eco-friendly" },
    { label: "Family Holidays", value: "family holidays" },
    { label: "Festivals", value: "festivals" },
    { label: "Food", value: "food" },
    { label: "Hills", value: "hills" },
    { label: "History", value: "history" },
    { label: "Honeymoon", value: "honeymoon" },
    { label: "Luxury", value: "luxury" },
    { label: "Pilgrimage", value: "pilgrimage" },
    { label: "Road Trips", value: "road trips" },
    { label: "Romance", value: "romance" },
    { label: "Solo trips", value: "solo trips" },
    { label: "Trekking", value: "trekking" },
    { label: "Wellness", value: "wellness" },
    { label: "Wildlife", value: "wildlife" },
    { label: "Yoga", value: "yoga" },
];

function uploadFile(file){
let formData = new FormData();
    formData.append("file", file);
    formData.set("auth_id", authId);
    formData.set("fun", "uploadPdfData");

    axios.post(`${user.base_url}/subuser/pdf_generate_package.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const responsedata = response.data.data;
	
        //console.log(responsedata);
        if(response.data.login.status == '0'){
			 handleLogout();
		        return false;
        }else if(responsedata.status=='1'){
          setPopupVisible(false);
          setExtractionInProgress(false);
          setUploadProgress(100);

          toast.success(responsedata.msg);
          setBuildPackageId(responsedata.deal_id);
          setShowCreateItinerary(true);
          navigate("/package-builder/"+responsedata.deal_id);
        }else{
          toast.error(responsedata.msg);
          setUploadProgress(0);
          setExtractionInProgress(false);
          setPopupVisible(false);
          setSelectBuildPackage("createNew");
        }

      })
      .catch((error) => {

      });
  }

const handleLogout = () => {
    // Dispatch the logout action to clear user data
    dispatch(logout());
    // Redirect to the login page
    navigate("/login");
  };    


 // Function to handle file upload
 const handleFileUpload = async (event) => {

  const allowedTypess = [
    "application/pdf",
    "application/docx",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];


  const file = event.target.files[0];
 

  if (file) {
    if (allowedTypess.includes(file?.type)) {
     
    } else {
          setUploadProgress(0);
          setExtractionInProgress(false);
          toast.error("Please upload Only PDF file");
      return false;
    }
  }



  setUploadedFile(file);

  uploadFile(file);
  // Show the progress bar and start the upload process
  setUploadProgress(0);
  setExtractionInProgress(true);

  try {
    // Simulate file upload progress (you should replace this with actual upload logic)
    for (let progress = 0; progress <= 100; progress += 10) {
      setUploadProgress(progress);
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate progress every 500ms
    }

    // Simulate a dummy API call for document extraction (replace with your actual API call)
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Simulate API call delay

    // Simulate a successful response
    const dummyApiResponse = {
      status: "success",
      extractedText: "This is the extracted content of the document.",
    };

    // Handle the result as needed (replace with your actual handling)
    if (dummyApiResponse.status === "success") {
      //console.log("Document extracted:", dummyApiResponse.extractedText);
    } else {
      console.error("API call failed");
    }
  } catch (error) {
    console.error("Upload or API call error:", error);
  } finally {

    // Hide the progress bar
    //setPopupVisible(false);
    //setSelectBuildPackage("createNew");
    //setExtractionInProgress(false);
    //setUploadProgress(100);
  }
};

const closePopup = () => {
  setPopupVisible(false);
  setSelectBuildPackage("createNew");
};
const handleFileInputClick = () => {
  const fileInput = document.getElementById("file-upload");
  if (fileInput) {
    fileInput.click();
  }
};


  const closeLibrary = () => {
    setLibraryPopupVisible(false);
    setSelectBuildPackage("");
  };
  const handleCopyFromLibrary = async () => {
    try {
      const libraryApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?auth_id=${authId}&fun=packagelist`
      );
      const libraryData = await libraryApiResponse.json();

      const libraryCards = libraryData.data.result.map((item) => (
        <div key={item.id} className="brd1 p16 df mt24 mb24">
          <div className="left-image">
            <div className="image-contain pr">
              <img src={image} alt={item.title} />
            </div>
          </div>
          <div className="pl24">
            <div className="fs16 fc14 ls1 v-center">
              <p className="fs14 fw6">{item.title}</p>
            </div>
            <div className="fs14 pt24 fc5 ls1 lh22">
              <p>{`Price: ${item.price} ${item.price_symbol}`}</p>
              <p>{`${item.days} Days / ${item.nights} Nights`}</p>
            </div>
          </div>
        </div>
      ));

      setLibraryPopupVisible(true);
      setCopyLibrary(true);
      setLibraryCards(libraryCards);
    } catch (error) {
		toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const handleBuildPackageDetails = async () => {
    if (!tourDestination.length) {
      toast.error("Please Fill Tour Destinations.");
      return;
    } else if (!selectedDurationValue) {
      toast.error("Please Select Itinerary Duration.");
      return;
    } else if (!selectedTheme.length) {
      toast.error("Please Select Itinerary Theme.");
      return;
    }
    /*if (
      !tourDestination.length ||
      !selectedDurationValue ||
      !selectedTheme.length
    ) {
      toast("Please fill in all required fields.");
      return;
    }*/

    try {
      const requestBody = {
        auth_id: authId,
        duration: selectedDurationValue,
        destination_country: "destination_country",
        destinations_covered: tourDestination,
        themes: selectedTheme,
        package_type: selectPackageType,
        package_visibility: selectPackageVisibility,
      };
      const pkgDetailsApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=addorupdatedeals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const pkgDetails = await pkgDetailsApiResponse.json();
	  if (pkgDetails.data && pkgDetails.data.status==="1") {
        setBuildPackageId(pkgDetails.data.id);
        setShowCreateItinerary(true);
      }else if(pkgDetails.data && pkgDetails.data.status==="0"){
        toast.error("Failed to load data,Please try after some time");
      }
    } catch (error) {
		
		toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const handleThemeChange = (selectedTheme) => {
    setSelectedTheme(selectedTheme);
  };
  const handleTourDestinationChange = (selectedValues) => {
    // state update in PackageBuilder component
    setTourDestination(selectedValues);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDurationDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="main-content-padding">
      <InnerHeader heading="Create Itinerary" showButton={false} txtSubHeading={"Effortlessly craft your Itinerary with minimal data input: simplify the process and maximize impact."}/>

      <Card className="bg5 create-package brd1">
        {!showCreateItinerary && (pid === "" || pid === undefined) && (
          <>
            <section className="mb24 pl12 pr12 pt12 pb12 bg5 build-package">
              <div className="fw6 fs14 ls1 lh22 fc14 mb12">Build Itinerary</div>
              <div className="v-center mt24 ">
                <RadioButton
                  options={[
                    { label: "Create New", value: "createNew" },
                    { label: "Upload Document(.pdf,.docx files only)", value: "uploadDocument" },
                    {
                      label: "Copy from library",
                      value: "copyFromLibrary",
                      customText: "Coming Soon",
                    },
                    

                  ]}
                  selectedOption={selectBuildPackage}
                  onChange={(value) => {
                    setSelectBuildPackage(value);
                    if (value === "uploadDocument") {
                      setPopupVisible(true);
                      setUploadDocumentSelected(true);
                    }
                    else if (value === "copyFromLibrary") {
                      handleCopyFromLibrary();
                      setLibraryPopupVisible(true);
                      setCopyLibrary(true);
                    }
                  }}
                />
                {libraryPopupVisible && (
                  <SidePopup onclose={closeLibrary}>
                    <div className="v-center">
                      <p className="fs18">Select Itinerary From Library</p>
                    </div>
                    {libraryCards}
                  </SidePopup>
                )}

                {isPopupVisible && (
                  <Popup onClose={closePopup} title={"Upload Document"}>
                    {isUploadDocumentSelected && (
                      <div className="custom-file-upload-container">
                        <div
                          className="drag-drop-area cp"
                          onClick={handleFileInputClick}
                        >
                          <div className="custom-file-upload-icon">
                            <FaCloudUploadAlt className="fs48" />
                          </div>
                          <p className="fw6 fc1 fs16 ls1 cp">
                            Click here to upload .pdf,.docx files only
                          </p>
                          <p className="file-size-message mt24">
                            (Maximum file size: 25 MB)
                          </p>
                          <input
                            type="file"
                            accept=".pdf,.docx"
                            //accept=".pdf"
                            onChange={handleFileUpload}
                            id="file-upload"
                            className="dn"
                            
                          />
                        </div>
                      </div>
                    )}
                    {isExtractionInProgress && (
                      <div className="extraction-progress-container">
                        <div className={`uploadProgress !== 100?"file-progress-container":""`}>
                        {uploadProgress !== 100 &&
                          <div
                            className="file-progress-bar"
                            style={{ width: `${uploadProgress}%` }}
                          >
                            {uploadProgress}%
                          </div>
                         }
                          <div className="extraction-progress-text mt">
                            {uploadProgress !== 100 && "Uploading in progress, please wait..."}
                          </div>
                          {uploadProgress === 100 && (
                            <>
                            <div className="extraction-completed">
                              <FaCheck className="check-icon" />
                              Creating Itinerary Please Wait...
                            </div>

                            <SmallLoader className={"mb12"} />
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="button-container mt48">
                      <button
                        type="button"
                        className="btn-cancel"
                        onClick={closePopup}
                      >
                        Cancel
                      </button>
                    </div>
                  </Popup>
                )}
              </div>
            </section>
            <section className=" mb24 bg5 pl12 pr12 pt12 pb12 bortop borbottom">
              <div className="df fs14 ls1 lh22 fc14 mb12 df radio-header">
                <p className="fw6">Itinerary Visibility</p>
                <span className="ml8 fc5 fs14 ls1">
                  ( Control how this Itinerary is viewed )
                </span>
              </div>
              <div className="df mt24">
                <RadioButton
                  options={[
                    {
                      label: "Public",
                      value: "public",
                      customText: "Visible to everyone",
                    },
                    {
                      label: "Private",
                      value: "private",
                      customText:
                        "Itinerary viewable only by the shared traveler",
                    },
                    {
                      label: "Password protected",
                      value: "passwordProtected",
                      customText: "Coming Soon",
                    },
                  ]}
                  selectedOption={selectPackageVisibility}
                  onChange={(value) => setSelectPackageVisibility(value)}
                />
              </div>
            </section>
            <section className="mb24 pl12 pr12 pt12 pb12 bg5  bortop borbottom">
              <div className="fw6 fs14 ls1 lh22 fc14 mb12">Itinerary type</div>
              <div className="df mt24">
                <RadioButton
                  options={[
                    {
                      label: "Flexible departure",
                      value: "FL",
                      customText: "Departure dates can be changed by customers",
                    },
                    {
                      label: "Fixed departure",
                      value: "FI",
                      customText:
                        "Departure dates cannot be changed by customers",
                    },
                  ]}
                  selectedOption={selectPackageType}
                  onChange={(value) => setSelectPackageType(value)}
                />
              </div>
            </section>
            <section className="mb24 df bg5 pl12 pr12 pt12 pb24 bortop borbottom dest-theme">
              <div className="filter-cities flx45 mr16  req-star">
                <p className="fs14 fw6 fc14 pb12 package-tour-destination mt4">
                  Tour Destination<span className="fc4">*</span>
                </p>
                <SearchDropdown
                  setTourDestination={setTourDestination}
                  onSelectionChange={handleTourDestinationChange}
                />
              </div>
              <div className="flx45 req-star themes">
                <label
                  htmlFor="package duration"
                  className="fc15 fw6 fs14 mb12 ls1"
                >
                  Itinerary Duration<span>*</span>
                </label>

                <div className="role-dropdown mt12" ref={dropdownRef}>
                  <div
                    className="selected-role fs14"
                    onClick={() =>
                      setShowDurationDropdown(!showDurationDropdown)
                    }
                  >
                    {selectedDurationLabel || "Select Duration"}
                    <FaAngleDown className="fc16 fs14" />
                  </div>
                  {showDurationDropdown && (
                    <ul className="role-options fs14">
                      {durationOptions.map((option) => (
                        <li
                          key={option.label}
                          onClick={() => handlePackageDurationSelect(option)}
                        >
                          {option.label}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </section>
            <section className="mb24 bg5 p12  bortop borbottom">
              <PackageMultiCheckBox
                label="Itinerary Theme"
                options={themeOptions}
                selectedOptions={selectedTheme}
                onChange={handleThemeChange}
              />
            </section>
            <section className="mb24">
              <div className="fixed-buttons box-center">
                <button
                  type="button"
                  className="lead-buy-btn fc3 bg1 br24 fs14 cp pl24 pr24 pt10 pb10"
                  onClick={() => {
                    handleBuildPackageDetails();
                  }}
                >
                  Next
                </button>
              </div>
            </section>
          </>
        )}
        {showCreateItinerary && (
          <CreateItinerary newPackageId={buildPackageId} toast={toast} />
        )}
      </Card>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default PackageBuilder;
