import axios from "axios";
import React from "react";
import "./ImageGallery.css";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import trackEvent from "../utils/gaTracker";

const ImageGallery = ({
  authId,
  media,
  setMedia,
  mediaFile,
  setMediaFile,
  dataStatus,
  baseUrl,
  uploadUrl,
  deleteUrl,
  toast,
  eventLabel,
  eventCategory,
}) => {
  const handleMediaUpload = (event) => {
    const files = event.target.files;
    const newMedia = [...media];
    const newMediaFile = [...mediaFile];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];

    for (const file of files) {
      if (allowedTypes.includes(file?.type)) {
        uploadImg(file)
          .then((response) => {
            if (response.data.data.status === 1) {
              newMedia.push({
                type: file.type,
                url: URL.createObjectURL(file),
              });
              newMediaFile.push(file);
              trackEvent(eventCategory, "click", `${eventLabel} - ADD`);
            } else {
              toast.warn(`Image Already Exist`);
            }
            setMediaFile(newMediaFile);
            setMedia(newMedia);
          })
          .catch((error) => {
            toast.error("Problem in uploading");
          });
      } else {
        toast.error("Type not Supported");
      }
    }
  };

  function uploadImg(file) {
    let formData = new FormData();
    formData.append("file", file);
    formData.set("auth_id", authId);
    formData.set("fun", "uploadImg");

    return axios.post(`${baseUrl}${uploadUrl}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  const removeMedia = (index) => {
    deleteImg(media[index]);
    const newMedia = [...media];
    newMedia.splice(index, 1);
    setMedia(newMedia);
    trackEvent(eventCategory, "click", `${eventLabel} - Delete`);
  };

  function deleteImg(mediaimg) {
    axios({
      method: "post",
      url: `${baseUrl}${deleteUrl}`,
      headers: {},
      data: {
        auth_id: authId,
        img: mediaimg.url,
      },
    })
      .then((response) => {})
      .catch((error) => {});
  }

  const handleTrackingEvent = () => {
    trackEvent(eventCategory, "click", eventLabel);
  };
  return (
    <div className="image-gallery df fww p12">
      <div className="media-card-container df fww">
        <label
          htmlFor="mediaInput"
          className="media-upload-button box-center pr cp"
        >
          <span className="media-upload-icon">
            <BsFillPlusCircleFill className="mr8 fc16 fs36" />
          </span>{" "}
          Upload
        </label>
        <input
          type="file"
          id="mediaInput"
          /*multiple*/
          onChange={handleMediaUpload}
          onClick={handleTrackingEvent}
          accept="image/*, video/*"
          style={{ display: "none" }}
        />
        {dataStatus === true &&
          media.map((item, index) => (
            <div
              key={index}
              className={`media-card pr box-center ${
                item.type.startsWith("image/") ? "image-card" : "video-card"
              }`}
            >
              {item.type.startsWith("image/") ? (
                <img src={item.url} alt={`img ${index}`} />
              ) : (
                <video controls>
                  <source src={item.url} type={item.type} />
                  Your browser does not support the video tag.
                </video>
              )}
              <span
                className="remove-icon cp pa fs16"
                onClick={() => removeMedia(index)}
              >
                <AiOutlineClose />
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ImageGallery;
