// LeadDetailsPopup.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./LeadDetailsPopup.css";
import Tabs from "../Tabs";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { MdOutlineEmail, MdCall, MdWhatsapp, MdVerified } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Tooltip from "../Tooltip";
import trackEvent from "../../utils/gaTracker";

const LeadDetailsPopup = ({ lead, show, onClose, toast }) => {
  const user = useSelector((state) => state.auth);
  const [leadResponse, setLeadResponse] = useState("0");
  const [selectedTab, setSelectedTab] = useState("Lead Details");
  const [isBought, setIsBought] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSuccMessage, setShowSuccMessage] = useState("");
  const formRef = useRef(null);
  var designstatus = 1;

  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target) && show) {
      onClose();
    }
  };
  useEffect(() => {
    if (show) {
      disableScroll();
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      enableScroll();
      document.removeEventListener("mousedown", handleClickOutside);
    }
    setSelectedTab("Lead Details");
  }, [show]);

  const tabs = [
    { label: "Lead Details", value: "Lead Details" },
    { label: "Customer Details", value: "Customer Details" },
  ];
  const handleTabChange = (value) => {
    setSelectedTab(value);
    trackEvent("Buylead Page", "click", `Buylead Detail Popup Tab - ${value}`);
  };
  const blurEmailAndPhone = (text) => {
    // Replace the text with blurred characters
    return text
      .split("")
      .map((char) => (char === " " ? " " : "*"))
      .join("");
  };
  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const userPlan = "Basic";
  if (!show) return null;
  if (!lead) {
    return null;
  }
  const handlePopUp = (previewUrl) => {
    window.open(previewUrl, "_blank");
  };
  const { leadinfo, customerinfo } = lead;
  const handleBuyNow = (name) => {
    trackEvent(
      "Buylead Page",
      "click",
      `Buylead Detail Popup - LD - Buy Now-${name}`
    );
    if (leadResponse === "2") {
      toast.warn("Please wait request is in under process.");
    } else {
      setLeadResponse("2");
      axios({
        method: "post",
        url: `${user.base_url}/subuser/services/buylead.php?fun=buy_lead`,
        headers: {},
        data: {
          id: lead.id,
          type: "nx",
          auth_id: user.auth_id,
        },
      }).then(function (response) {
        let resObj = response.data;
        setLeadResponse(resObj.status);
        if (resObj.status === 1) {
          setIsBought(true);
        } else {
          setIsBought(false);
        }
        setShowSuccessMessage(true);
        toast.info(resObj.msg);
        setShowSuccMessage(resObj.msg);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
      });
    }
  };
  const handleSetAutoBuy = () => {
    navigate("/my-account?tab=lead-management");
  };

  let displayName = customerinfo.name.split(" ")[0]; // Splits by space
  return (
    <div className="lead-popup-overlay">
      <div
        className={`lead-popup-content pr ${show ? "slide-in" : ""}`}
        ref={formRef}
      >
        <div>
          <Tabs
            tabs={tabs}
            onTabChange={handleTabChange}
            showCheckboxes={false}
            showFilter={false}
            className={"flx50 fs14 ls2"}
          />
          <button onClick={onClose} className="lead-close-button">
            X
          </button>
        </div>

        {selectedTab === "Lead Details" ? (
          <div className="list-buy-details mt12">
            <div className="list-but-details-heading mt24">
              <p className="fc20 fs20 fw6 lh22">{leadinfo.leaddestination}</p>
              <div className="pr16 pt12 pb12 df">
                <p className="v-center mr8">
                  <AiOutlineClockCircle className="mr4 fc19" />
                  <span className="fc20 fs14 fw5">{leadinfo.leadtime}</span>
                </p>
              </div>
            </div>
            <div>
              <ul className="ls1 mt8">
                {leadinfo.paxdetails !== "NA" && (
                  <li className="df jcsb aic p12">
                    <p className="fc5 fs14">Pax Details</p>
                    <p className="ml16 fs14 fw5">{leadinfo.paxdetails}</p>
                  </li>
                )}

                {leadinfo.fromcity !== "NA" && (
                  <li className="df jcsb aic p12 bg7">
                    <p className="fc5 fs14">From City</p>
                    <p className="ml16 fs14 fw5">{leadinfo.fromcity}</p>
                  </li>
                )}
                {leadinfo.traveldates !== "NA" && (
                  <li className="df jcsb aic p12">
                    <p className="fc5 fs14">Travel Dates</p>
                    <p className="ml16 fs14 fw5">{leadinfo.traveldates}</p>
                  </li>
                )}
                {leadinfo.budget !== "NA" && (
                  <li className="df jcsb aic p12 bg7">
                    <p className="fc5 fs14">Total Budget</p>
                    <p className="ml16 fs14 fw5">₹ {leadinfo.budget}</p>
                  </li>
                )}
                {leadinfo.duration !== "NA" && (
                  <li className="df jcsb aic p12">
                    <p className="fc5 fs14">Duration</p>
                    <p className="ml16 fs14 fw5">{leadinfo.duration}</p>
                  </li>
                )}
                {leadinfo.theme !== "NA" && (
                  <li className="df jcsb aic p12 bg7">
                    <p className="fc5 fs14">Theme</p>
                    <p
                      className="ml16 fs14 fw5"
                      style={{ textTransform: "capitalize" }}
                    >
                      {leadinfo.theme}{" "}
                    </p>
                  </li>
                )}

                {leadinfo.hoteltype !== "NA" && (
                  <li className="df jcsb aic p12">
                    <p className="fc5 fs14">Hotel Type</p>
                    <p className="ml16 fs14 fw5">{leadinfo.hoteltype}</p>
                  </li>
                )}
                {leadinfo.requiredroom !== "NA" && (
                  <li className="df jcsb aic p12 bg7">
                    <p className="fc5 fs14">Required Room</p>
                    <p className="ml16 fs14 fw5">{leadinfo.requiredroom}</p>
                  </li>
                )}

                {leadinfo.transportation !== "NA" && (
                  <li className="df jcsb aic p12">
                    <p className="fc5 fs14">Transportation Mode</p>
                    <p className="ml16 fs14 fw5">{leadinfo.transportation}</p>
                  </li>
                )}
                {leadinfo.tickets !== "NA" && (
                  <li className="df jcsb aic p12 bg7">
                    <p className="fc5 fs14">Tickets</p>
                    <p className="ml16 fs14 fw5">{leadinfo.tickets}</p>
                  </li>
                )}
                {leadinfo.tickets_required !== "NA" &&
                  leadinfo.tickets_required !== "" && (
                    <li className="df jcsb aic p12">
                      <p className="fc5 fs14">Tickets Required</p>
                      <p className="ml16 fs14 fw5">
                        {leadinfo.tickets_required}
                      </p>
                    </li>
                  )}
                {leadinfo.bookingclass !== "NA" && (
                  <li className="df jcsb aic p12 bg7">
                    <p className="fc5 fs14">Booking Class</p>
                    <p className="ml16 fs14 fw5">{leadinfo.bookingclass}</p>
                  </li>
                )}

                {/* <li className="df jcsb aic p12">
                  <p className="fc5 fs14">No. of Times Lead Sold</p>
                  <p className="ml16 fs14 fw5">{leadinfo.nooftimesleadsold}</p>
                </li> */}
                {leadinfo.needtobook !== "" && (
                  <li className="df jcsb aic p12 ">
                    <p className="fc5 fs14">Need to Book</p>
                    <p className="ml16 fs14 fw5">
                      {leadinfo.needtobook === "" ? "NA" : leadinfo.needtobook}{" "}
                    </p>
                  </li>
                )}
                {leadinfo.leadsource !== "NA" && (
                  <li className="df jcsb aic p12 bg7">
                    <p className="fc5 fs14">Lead Source</p>
                    {leadinfo.leadsource === "Package" && (
                      <p
                        className="ml16 fs14 fw5 fc1 cp"
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePopUp(leadinfo.package_url);
                          trackEvent(
                            "Buylead Page",
                            "click",
                            ` Buylead Detail Popup - Lead Source`
                          );
                        }}
                      >
                        {leadinfo.leadsource}
                      </p>
                    )}
                    {leadinfo.leadsource !== "Package" && (
                      <p className="ml16 fs14 fw5">{leadinfo.leadsource}</p>
                    )}
                  </li>
                )}
              </ul>
            </div>
            <div className="fixed-buttons box-center fdc">
              <div>
                {/* <button
                  type="button"
                  className="btn-cancel cp"
                  onClick={handleSetAutoBuy}
                >
                  Set Auto Buy
                </button>
                <button type="button" className="btn-cancel cp">
                  View Similar
                </button> */}

                {leadinfo.lead_booked !== 1 &&
                  leadinfo.leadstaus !== "sold" &&
                  leadResponse !== 1 &&
                  leadinfo.isbuy !== 1 && (
                    <button
                      type="button"
                      className="lead-buy-btn fc3 bg1 br24 fs14 cp pl16 pr16 pt10 pb10"
                      onClick={() => handleBuyNow("Lead Details")}
                    >
                      {isBought
                        ? "Bought"
                        : leadinfo.isbuy === 1
                        ? "Bought"
                        : "Buy Now"}
                    </button>
                  )}
                {leadinfo.lead_booked !== 1 && leadinfo.leadstaus !== "sold" &&
                  (leadResponse === 1 || leadinfo.isbuy === 1) && (
                    <button
                      type="button"
                      className="lead-buy-btn fc3 bg1 br24 fs14 cp pl16 pr16 pt10 pb10"
                      disabled
                    >
                      {isBought
                        ? "Bought"
                        : leadinfo.isbuy === 1
                        ? "Bought"
                        : "Buy Now"}
                    </button>
                  )}
              </div>
              {showSuccessMessage && (
                <div
                  className={`success-message box-center ${
                    isBought ? "fc13" : "fc9"
                  } fs14 ls1 slide-in mt16`}
                >
                  {showSuccMessage}
                </div>
              )}
            </div>
          </div>
        ) : selectedTab === "Customer Details" ? (
          <>
            <ul className="ls1 mt8">
              <li className="df jcsb aic mb8 p12">
                <p className="fc20 fs18 v-center">
                  <FaUserCircle className="fs36 mr8" />
                  <span className="fc20 fs20 fw6 lh22">
                    {displayName} {customerinfo.age}
                  </span>
                </p>
                <p className="v-center">
                  {customerinfo.whatsapp_verify === 1 && (
                    <Tooltip title="Available On Whatsapp">
                      <MdWhatsapp className="fs24 mr8 fc5" />
                    </Tooltip>
                  )}
                  {customerinfo.email_verify === 1 && (
                    <Tooltip title="Email verified">
                      <MdOutlineEmail className="fs24 mr8 fc5" />
                    </Tooltip>
                  )}
                  {customerinfo.phone_verify === 1 && (
                    <Tooltip title="Phone verified">
                      <MdCall className="fs22 fc5" />
                    </Tooltip>
                  )}
                  {/* <MdVerified className="fs22 fc5" /> */}
                </p>
              </li>
              {customerinfo.customersince !== "NA" && (
                <li className="df jcsb aic p12 bg7">
                  <p className="fc5 fs14">Customer Since</p>
                  <p className="ml16 fs14 fw5">{customerinfo.customersince}</p>
                </li>
              )}
              {customerinfo.gender !== "NA" && (
                <li className="df jcsb aic p12">
                  <p className="fc5 fs14">Gender</p>
                  <p className="ml16 fs14 fw5">{customerinfo.gender}</p>
                </li>
              )}
              {customerinfo.maritalstatus !== "NA" && (
                <li className="df jcsb aic p12 bg7">
                  <p className="fc5 fs14">Marital Status</p>
                  <p className="ml16 fs14 fw5">{customerinfo.maritalstatus}</p>
                </li>
              )}
              <li className="df jcsb aic p12 ">
                <p className="fc5 fs14">Email</p>
                <p className={userPlan === "Basic" ? "blurred-text" : ""}>
                  {userPlan === "Basic"
                    ? "***********"
                    : "naveentiwari112@gmail.com"}
                </p>
              </li>
              <li className="df jcsb aic p12 bg7">
                <p className="fc5 fs14">Phone Number</p>
                <p
                  className={`fw6 ${
                    userPlan === "Basic" ? "blurred-text" : ""
                  }`}
                >
                  {blurEmailAndPhone("9818250039")}
                </p>
              </li>
              {customerinfo.noofinternationaltrips !== "NA" && (
                <li className="df jcsb aic p12">
                  <p className="fc5 fs14">No. of International Trips:</p>
                  <p className="ml16 fs14 fw5">
                    {customerinfo.noofinternationaltrips}
                  </p>
                </li>
              )}
              {customerinfo.visa !== "NA" && (
                <li className="df jcsb aic p12 bg7">
                  <p className="fc5 fs14">Visa</p>
                  <p className="ml16 fs14 fw5">{customerinfo.visa}</p>
                </li>
              )}
              {customerinfo.passport !== "NA" && (
                <li className="df jcsb aic p12 ">
                  <p className="fc5 fs14">Passport</p>
                  <p className="ml16 fs14 fw5">{customerinfo.passport}</p>
                </li>
              )}
              {/* <li className="df jcsb aic p12 bg7">
                <p className="fc5 fs14">Liquor Preference</p>
                <p className="ml16 fs14 fw5">{customerinfo.liquorpreference}</p>
              </li> */}

              {customerinfo.repeatbuyers !== "NA" && (
                <li className="df jcsb aic p12 bg7">
                  <p className="fc5 fs14">Repeated Travelers</p>
                  <p className="ml16 fs14 fw5">{customerinfo.repeatbuyers}</p>
                </li>
              )}
              {customerinfo.foodpreference !== "NA" && (
                <li className="df jcsb aic p12">
                  <p className="fc5 fs14">Food Preference</p>
                  <p className="ml16 fs14 fw5">{customerinfo.foodpreference}</p>
                </li>
              )}
              {/* <li className="df jcsb aic p12">
                <p className="fc5 fs14">Block Other users</p>
                <p className="ml16 fs14 fw5">{customerinfo.blockotherusers}</p>
              </li> */}
              {customerinfo.engagedonwhatsapp !== "NA" && (
                <li className="df jcsb aic p12 bg7">
                  <p className="fc5 fs14">Engaged on WhatsApp</p>
                  <p className="ml16 fs14 fw5">
                    {customerinfo.engagedonwhatsapp}
                  </p>
                </li>
              )}
              {customerinfo.engagedonphone !== "NA" && (
                <li className="df jcsb aic p12 ">
                  <p className="fc5 fs14">Engaged on Phone</p>
                  <p className="ml16 fs14 fw5">{customerinfo.engagedonphone}</p>
                </li>
              )}
            </ul>
            <div className="fixed-buttons box-center">
              {/* <button type="button" className="btn-cancel cp">
                  Set Auto Buy
                </button>
                <button type="button" className="btn-cancel cp">
                  View Similar
                </button> */}
              {leadinfo.leadstaus !== "sold" &&
                leadResponse !== 1 &&
                leadinfo.isbuy !== 1 && (
                  <button
                    type="button"
                    className="lead-buy-btn fc3 bg1 br24 fs14 cp pl16 pr16 pt10 pb10"
                    onClick={() => handleBuyNow("Customer Details")}
                  >
                    {isBought
                      ? "Bought"
                      : leadinfo.isbuy === 1
                      ? "Bought"
                      : "Buy Now"}
                  </button>
                )}
              {leadinfo.leadstaus !== "sold" &&
                (leadResponse === 1 || leadinfo.isbuy === 1) && (
                  <button
                    type="button"
                    className="lead-buy-btn fc3 bg1 br24 fs14 cp pl16 pr16 pt10 pb10"
                    disabled
                  >
                    {isBought
                      ? "Bought"
                      : leadinfo.isbuy === 1
                      ? "Bought"
                      : "Buy Now"}
                  </button>
                )}
            </div>
            {showSuccessMessage && (
              <div
                className={`success-message box-center ${
                  isBought ? "fc13" : "fc9"
                } fs14 ls1 slide-in mt16`}
              >
                {showSuccMessage}
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};
export default LeadDetailsPopup;
