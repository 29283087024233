import React, { useState, useEffect } from "react";
import Tabs from "../Tabs";
import PriceRangeSlider from "./PriceRangeSlider";
import FilterCheckBoxOption from "./FilterCheckBoxOption";
import DestinationSearch from "../DestinationSearch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import trackEvent from "../../utils/gaTracker";

const BuyLeadFilter = ({
  onFilterData,
  primium,
  filterPopupVisible,
  onClose,
  updateFilter,
  clearFilter,
  setClearFilter,
}) => {
  const tabs = [
    { label: "Filters", value: "Filters" },
    { label: "Premium Filters", value: "Premium Filters" },
  ];
  const initialFilter = JSON.parse(localStorage.getItem("buyFilterData")) || {
    selectedRecency: [],
    selectedCities: [],
    selectedFromCities: [],
    selectedTheme: [],
    selectedPassengers: [],
    priceRange: [],
    selectedHotel: [],
    selectedNeedBooked: [],
    selectedLeadStatus: [],
    selectedSold: [],
    selectedSource: [],
    selectedTrequired: [],
    selectedTbooked: [],
    selectedDuration: [],
    selectedMemberSince: [],
    selectedGender: [],
    selectedLeadType: [],
    selectedLeadQualty: [],
    startDate: "",
    endDate: "",
  };
  //state for filter options
  const [selectedRecency, setSelectedRecency] = useState(initialFilter.selectedRecency);
  const [selectedCities, setSelectedCities] = useState(initialFilter.selectedCities);
  const [selectedFromCities, setSelectedFromCities] = useState(initialFilter.selectedFromCities);
  const [selectedTheme, setSelectedTheme] = useState(initialFilter.selectedTheme);
  const [selectedPassengers, setSelectedPassengers] = useState(initialFilter.selectedPassengers);
  const [selectedTab, setSelectedTab] = useState("Filters");
  const [priceRange, setPriceRange] = useState(initialFilter.priceRange);
  const [selectedHotel, setSelectedHotel] = useState(initialFilter.selectedHotel);
  const [selectedNeedBooked, setSelectedNeedBooked] = useState(initialFilter.selectedNeedBooked);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState(initialFilter.selectedLeadStatus);
  const [selectedSold, setSelectedSold] = useState(initialFilter.selectedSold);
  const [selectedSource, setSelectedSource] = useState(initialFilter.selectedSource);
  const [selectedTrequired, setSelectedTrequired] = useState(initialFilter.selectedTrequired);
  const [selectedTbooked, setSelectedTbooked] = useState(initialFilter.selectedTbooked);
  const [selectedDuration, setSelectedDuration] = useState(initialFilter.selectedDuration);
  const [selectedMemberSince, setSelectedMemberSince] = useState(initialFilter.selectedMemberSince);
  const [selectedGender, setSelectedGender] = useState(initialFilter.selectedGender);
  const [selectedLeadType, setSelectedLeadType] = useState(initialFilter.selectedLeadType);

  const [selectedLeadQualty, setSelectedLeadQualty] = useState(initialFilter.selectedLeadQualty);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filter, setFilter] = useState(initialFilter);
  useEffect(() => {
    onFilterData(filter);
    localStorage.setItem("buyFilterData", JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    if (clearFilter) {
      setSelectedRecency([]);
      setSelectedCities([]);
      setSelectedFromCities([]);
      setSelectedTheme([]);
      setSelectedPassengers([]);
      setPriceRange([]);
      setSelectedHotel([]);
      setSelectedNeedBooked([]);
      setSelectedLeadStatus([]);
      setSelectedSold([]);
      setSelectedSource([]);
      setSelectedTrequired([]);
      setSelectedTbooked([]);
      setSelectedDuration([]);
      setSelectedMemberSince([]);
      setSelectedGender([]);
      setSelectedLeadType([]);
      setSelectedLeadQualty([]);
      setStartDate("");
      setEndDate("");
      setClearFilter(false);
      setFilter({
        selectedRecency: [],
        selectedCities: [],
        selectedFromCities: [],
        selectedTheme: [],
        selectedPassengers: [],
        priceRange: [],
        selectedHotel: [],
        selectedNeedBooked: [],
        selectedLeadStatus: [],
        selectedSold: [],
        selectedSource: [],
        selectedTrequired: [],
        selectedTbooked: [],
        selectedDuration: [],
        selectedMemberSince: [],
        selectedGender: [],
        selectedLeadType: [],
        selectedLeadQualty: [],
        startDate: "",
        endDate: "",
      });
    }
  }, [clearFilter, setClearFilter]);
  const leadTypeOptions = [
    { label: "Domestic", value: "domestic" },
    { label: "Inbound", value: "inbound" },
    { label: "Outbound", value: "outbound" },
    { label: "International", value: "international" },
  ];
  const needBookOptions = [
    { label: "Immediately", value: "immediately" },
    { label: "Just Exploring", value: "exploring" },
  ];
  const leadStatusOptions = [
    { label: "Verified Only", value: "verified" },
    // { label: "Unverified", value: "unverified" },
  ];
  const recencyOptions = [
    { label: "< 15 Min ago", value: "15-m" },
    { label: "< 30 Min ago ", value: "30-m" },
    { label: "< 10 Hours ago", value: "10-h" },
    { label: "< 1 Day ago", value: "1-d" },
    { label: "< 3 Days ago", value: "3-d" },
    { label: "< 5 Days ago", value: "5-d" },
  ];
  const themeOptions = [
    // { label: "Honeymoon", value: "honeymoon" },
    // { label: "Friends", value: "friends" },
    // { label: "Beach", value: "beach" },
    { label: "Adventure", value: "adventure" },
    { label: "Art", value: "art" },
    { label: "Backpacking", value: "backpacking" },
    { label: "Beaches", value: "beaches" },
    { label: "Culture", value: "culture" },
    { label: "Desert", value: "desert" },
    { label: "Eco-Friendly", value: "eco-friendly" },
    { label: "Family Holidays", value: "family holidays" },
    { label: "Festivals", value: "festivals" },
    { label: "Food", value: "food" },
    { label: "Hills", value: "hills" },
    { label: "History", value: "history" },
    { label: "Honeymoon", value: "honeymoon" },
    { label: "Luxury", value: "luxury" },
    { label: "Pilgrimage", value: "pilgrimage" },
    { label: "Road Trips", value: "road trips" },
    { label: "Romance", value: "romance" },
    { label: "Solo trips", value: "solo trips" },
    { label: "Trekking", value: "trekking" },
    { label: "Wellness", value: "wellness" },
    { label: "Wildlife", value: "wildlife" },
    { label: "Yoga", value: "yoga" },
  ];

  const passengersOptions = [
    { label: "10+ People", value: "5" },
    { label: "6 - 10 People", value: "4" },
    { label: "4 - 5 People", value: "3" },
    { label: "2 - 3 People", value: "2" },
    { label: "1 Person", value: "1" },
  ];

  const hotelOptions = [
    { label: "5+ Star", value: "5plusstar" },
    { label: "5 Star", value: "5 star" },
    { label: "4 Star", value: "4 star" },
    { label: "3 Star", value: "3 star" },
    { label: "2 Star", value: "2 star" },
    { label: "1 Star", value: "1 star" },
    { label: "Unrated", value: "unrated" },
    { label: "Service Apartment", value: "service apartment" },
    { label: "Guest House", value: "guest house" },
  ];

  const durationOptions = [
    { label: "14+ Days", value: "4" },
    { label: "8 - 14 Days", value: "3" },
    { label: "4 - 7 Days", value: "2" },
    { label: "1 - 3 Days", value: "1" },
  ];
  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    // { label: "Transgender", value: "transgender" },
  ];
  const tBookedOptions = [
    //{ label: "Both", value: "both" },
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  const tRequiredOptions = [
    //{ label: "Both", value: "both" },
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  const handlePriceChange = (values) => {
    setPriceRange(values);
    const updatedfilter = { ...filter, priceRange: values };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Total Budget - ${values}`);
  };
  const handleLeadQualty = (values) => {
    setSelectedLeadQualty(values);
    const updatedfilter = { ...filter, selectedLeadQualty: values };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Lead Quality - ${values}`);
  };
  const handlecityFilterData = (values) => {
    setSelectedCities(values);
    const updatedfilter = { ...filter, selectedCities: values };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter To City - ${values}`);
  };
  const handleFromcCityChange = (values) => {
    setSelectedCities(values);
    const updatedfilter = { ...filter, selectedFromCities: values };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter From City - ${values}`);
  };
  const handleTabChange = (value) => {
    setSelectedTab(value);
  };
  const handleRecencyChange = (value) => {
    setSelectedRecency(value);
    const updatedfilter = { ...filter, selectedRecency: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Recency - ${value}`);
  };

  const handleThemeChange = (value) => {
    setSelectedTheme(value);
    const updatedfilter = { ...filter, selectedTheme: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Theme - ${value}`);
  };

  const handlePassengersChange = (value) => {
    setSelectedPassengers(value);
    const updatedfilter = { ...filter, selectedPassengers: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Travelers - ${value}`);
  };
  const handleHotelChange = (value) => {
    setSelectedHotel(value);
    const updatedfilter = { ...filter, selectedHotel: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Hotel - ${value}`);
  };

  const handleDurationChange = (value) => {
    setSelectedDuration(value);
    const updatedfilter = { ...filter, selectedDuration: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Duration - ${value}`);
  };
  const handleGenderChange = (value) => {
    setSelectedGender(value);
    const updatedfilter = { ...filter, selectedGender: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Gender - ${value}`);
  };
  const handleMemberChange = (value) => {
    setSelectedMemberSince(value);
    const updatedfilter = { ...filter, selectedMemberSince: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Member Since - ${value}`);
  };
  const handletRequiredChange = (value) => {
    setSelectedTrequired(value);
    const updatedfilter = { ...filter, selectedTrequired: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Tickets Required - ${value}`);
  };
  const handletBookedChange = (value) => {
    setSelectedTbooked(value);
    const updatedfilter = { ...filter, selectedNeedBooked: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Tickets Booked - ${value}`);
  };
  const handleSoldChange = (value) => {
    setSelectedSold(value);
    const updatedfilter = { ...filter, selectedSold: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Lead Sold - ${value}`);
  };
  const handleSourceChange = (value) => {
    setSelectedSource(value);
    const updatedfilter = { ...filter, selectedSource: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Lead Source - ${value}`);
  };

  const handleNeedBookedChange = (value) => {
    setSelectedNeedBooked(value);
    const updatedfilter = { ...filter, selectedTbooked: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Need to book - ${value}`);
  };
  const handleLeadStatusChange = (value) => {
    setSelectedLeadStatus(value);
    const updatedfilter = { ...filter, selectedLeadStatus: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Lead Status - ${value}`);
  };
  const handleLeadTypeChange = (value) => {
    setSelectedLeadType(value);
    const updatedfilter = { ...filter, selectedLeadType: value };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter Lead Type - ${value}`);
  };
  const handleFromDateChange = (date) => {
    const istDate = new Date(date);
    const formattedDate = istDate.toISOString();
    var jun = moment(formattedDate);
    var newindiadate = jun.tz("Asia/Calcutta").format("YYYYMMDD");
    setStartDate((prev) => date);
    const updatedfilter = { ...filter, startDate: newindiadate };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter From Date - ${newindiadate}`);
  };

  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    const istDate = new Date(date);
    const formattedDate = istDate.toISOString();
    var jun = moment(formattedDate);
    var newindiadate = jun.tz("Asia/Calcutta").format("YYYYMMDD");
    setEndDate(date);
    const updatedfilter = { ...filter, endDate: newindiadate };
    setFilter(updatedfilter);
    trackEvent("Buylead Page", "click", `Filter To Date - ${newindiadate}`);
  };

  const sourceOptions = [
    { label: "Package", value: "package" },
    { label: "Non Package", value: "other" },
  ];
  const membereOptions = [
    { label: "10+ Years", value: "5" },
    { label: "5 - 10 Years", value: "4" },
    { label: "2 - 5 Years", value: "3" },
    { label: "1 - 2 Years", value: "2" },
    { label: "1 - 12 Months", value: "1" },
    { label: "0 - 1 Month", value: "0" },
  ];
  const soldOptions = [
    { label: "3 Times", value: "3" },
    { label: "2 Times", value: "2" },
    { label: "1 Time", value: "1" },
    { label: "Virgin", value: "0" },
  ];
  const handleBuyleadFilter = () => {
    onClose();
    trackEvent("Buylead Page", "click", `Filters Apply`);
  };
  return (
    <>
      <Tabs
        tabs={tabs}
        onTabChange={handleTabChange}
        showCheckboxes={false}
        showFilters={false}
        className={"flx50 fs14 ls2"}
      />
      <div className="buy-lead-filter-section pr">
        {filterPopupVisible && (
          <div className="close-btn pa" onClick={onClose}>
            X
          </div>
        )}
        {selectedTab === "Filters" && (
          <>
            <div className="filter-recency p12 brd-b1">
              <FilterCheckBoxOption
                label="Lead Type"
                options={leadTypeOptions}
                selectedOptions={selectedLeadType}
                onChange={handleLeadTypeChange}
                multiSelect={false}
              />
            </div>
            <div className="filter-cities p12 brd-b1">
              <p className="fs14 fw6 fc14">To Cities</p>
              <DestinationSearch
                onFilterCity={handlecityFilterData}
                MultiSelectcity={true}
              />
            </div>
            <div className="filter-passengers p12 brd-b1">
              <FilterCheckBoxOption
                label="Travelers"
                options={passengersOptions}
                selectedOptions={selectedPassengers}
                onChange={handlePassengersChange}
                multiSelect={true}
              />
            </div>
            <div className="filter-duration p12 brd-b1">
              <FilterCheckBoxOption
                label="Duration"
                options={durationOptions}
                selectedOptions={selectedDuration}
                onChange={handleDurationChange}
                multiSelect={true}
              />
            </div>
            <div className="filter-travel-date pl16 pb16 brd-b1">
              <p className="fs14 fw6 fc14 pt16">Travel Date</p>
              <div className="lead-calendar df  mt16">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={handleFromDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="From Date"
                  className="cp bg7 brd1 fc5 fs14 mr8 br16 pl8 pt12 pb12"
                  isClearable={true}
                />
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={handleToDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="To Date"
                  className="cp bg7 brd1 fc5 fs14 mr8 br16 pl8 pt12 pb12 box-center"
                  isClearable={true}
                />
                <div></div>
              </div>
            </div>
            <div className="filter-hotel p12 brd-b1">
              <FilterCheckBoxOption
                label="Hotel"
                options={hotelOptions}
                selectedOptions={selectedHotel}
                onChange={handleHotelChange}
                multiSelect={true}
              />
            </div>
            <div className="filter-budget p12 brd-b1">
              <p className="fs14 fw6 fc14">Total Budget</p>
              <div className="box-center fdc mt16 mb16">
                <PriceRangeSlider
                  min={0}
                  max={1000000}
                  value={priceRange}
                  onRangeChange={handlePriceChange}
                  currencyType={"₹"}
                />
              </div>
            </div>

            <div className="filter-theme p12 brd-b1">
              <FilterCheckBoxOption
                label="Theme"
                options={themeOptions}
                selectedOptions={selectedTheme}
                onChange={handleThemeChange}
                multiSelect={true}
              />
            </div>
          </>
        )}
        {selectedTab === "Premium Filters" && (
          <>
            <div className="filter-lead-status p12 brd-b1">
              <FilterCheckBoxOption
                label="Lead Status"
                options={leadStatusOptions}
                selectedOptions={selectedLeadStatus}
                onChange={handleLeadStatusChange}
                multiSelect={false}
                disabled={primium}
              />
            </div>
            <div className="filter-from-city p12 brd-b1">
              <p className="fs14 fw6 fc14">From City</p>
              <DestinationSearch
                onFilterCity={handleFromcCityChange}
                MultiSelectcity={true}
                readonly={primium}
              />
            </div>
            <div className="filter-need-book p12 brd-b1">
              <FilterCheckBoxOption
                label="Need to book"
                options={needBookOptions}
                selectedOptions={selectedNeedBooked}
                onChange={handleNeedBookedChange}
                multiSelect={true}
                disabled={primium}
              />
            </div>
            <div className="filter-source p12 brd-b1">
              <FilterCheckBoxOption
                label="Lead Source"
                options={sourceOptions}
                selectedOptions={selectedSource}
                onChange={handleSourceChange}
                disabled={primium}
              />
            </div>
            <div className="filter-lead-quality p12 brd-b1">
              <p className="fs14 fw6 fc14 pb8">Lead Quality</p>
              <div className="box-center fdc mt16 mb16">
                <PriceRangeSlider
                  min={0}
                  max={100}
                  value={priceRange}
                  onRangeChange={handleLeadQualty}
                  currencyType={"%"}
                  disabled={primium}
                />
              </div>
            </div>
            <div className="filter-member-since p12 brd-b1">
              <FilterCheckBoxOption
                label="Member Since"
                options={membereOptions}
                selectedOptions={selectedMemberSince}
                onChange={handleMemberChange}
                multiSelect={true}
                disabled={primium}
              />
            </div>
            <div className="filter-sold p12 brd-b1">
              <FilterCheckBoxOption
                label="Lead Sold"
                options={soldOptions}
                selectedOptions={selectedSold}
                onChange={handleSoldChange}
                multiSelect={true}
                disabled={primium}
              />
            </div>
            <div className="filter-ticket-required p12 brd-b1">
              <FilterCheckBoxOption
                label="Tickets Required"
                options={tRequiredOptions}
                selectedOptions={selectedTrequired}
                onChange={handletRequiredChange}
                multiSelect={false}
                disabled={primium}
              />
            </div>
            <div className="filter-ticket-booked p12 brd-b1">
              <FilterCheckBoxOption
                label="Tickets Booked"
                options={tBookedOptions}
                selectedOptions={selectedTbooked}
                onChange={handletBookedChange}
                multiSelect={false}
                disabled={primium}
              />
            </div>

            <div className="filter-recency p12 brd-b1">
              <FilterCheckBoxOption
                label="Recency"
                options={recencyOptions}
                selectedOptions={selectedRecency}
                onChange={handleRecencyChange}
                multiSelect={false}
                disabled={primium}
              />
            </div>
            <div className="filter-gender p12">
              <FilterCheckBoxOption
                label="Gender"
                options={genderOptions}
                selectedOptions={selectedGender}
                onChange={handleGenderChange}
                multiSelect={false}
                disabled={primium}
              />
            </div>
          </>
        )}
        {filterPopupVisible && (
          <div
            className="ps b0 zi2 p12 box-center bg1 w100 fc3 ls1 cp"
            onClick={handleBuyleadFilter}
          >
            Apply
          </div>
        )}
      </div>
    </>
  );
};

export default BuyLeadFilter;
