import React, { useState } from "react";
import "./CoverGallery.css";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CoverGallery = () => {
  const [media, setMedia] = useState([]);
  const [coverGallery, setCoverGallery] = useState([
    "https://www.hlimg.com/images/places2see/300X200/places_201807031530609151p.jpg",
    "https://www.hlimg.com/images/places2see/300X200/putrajaya_malaysia_perbadanan-putrajaya-01_credit_ellis_calvin_1515836506m.jpg",
    "https://www.hlimg.com/images/places2see/300X200/kl-1_1515410864m.jpg",
    "https://www.hlimg.com/images/places2see/300X200/placetosee_1507390207m1.jpg",
    "https://www.hlimg.com/images/slider/image-not-uploaded.jpg",
    "https://www.hlimg.com/images/places2see/300X200/Gozer_Kedarnath-Temple_Ice_1481283000m.jpg"
  ]);

  const [selectedImages, setSelectedImages] = useState([]);

  const handleMediaUpload = (event) => {
    const files = event.target.files;
    const newMedia = [];
    for (const file of files) {
      newMedia.push({
        type: file.type,
        url: URL.createObjectURL(file),
        upload: true,
        selected: false,
      });
    }
    setMedia([...media, ...newMedia]);
  };

  const removeMedia = (index) => {
    const newMedia = [...media];
    newMedia.splice(index, 1);
    setMedia(newMedia);
  };

  const toggleSelected = (index, source) => {
    if (selectedImages.length >= 5 && !selectedImages.includes(source)) {
      toast.warn("Maximum 5 images can be selected");
      return;
    }
    const updatedMedia = media.map((item, idx) => {
      if (idx === index) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });

    const updatedSelectedImages = [...selectedImages];
    if (updatedSelectedImages.includes(source)) {
      updatedSelectedImages.splice(updatedSelectedImages.indexOf(source), 1);
    } else {
      updatedSelectedImages.push(source);
    }
    // console.log(updatedSelectedImages);
    setSelectedImages(updatedSelectedImages);
    // console.log(updatedMedia);
    setMedia(updatedMedia);
  };

  return (
    <div className="image-gallerys df fww mb12">
      <div className="media-card-containers df fww">
        <label
          htmlFor="mediaInput"
          className="media-upload-buttons box-center pr cp"
        >
          <span className="media-upload-icons">
            <BsFillPlusCircleFill className="mr8 fc16 fs36" />
          </span>{" "}
          Upload
        </label>
        <input
          type="file"
          id="mediaInput"
          multiple
          onChange={handleMediaUpload}
          accept="image/*, video/*"
          style={{ display: "none" }}
        />
        {media.map((item, index) => (
          <div
            key={index}
            className={`media-cards pr box-center ${
              item.type.startsWith("image/") ? "image-cards" : "video-cards"
            } ${item.selected ? "selected" : ""}`}
            onClick={() => toggleSelected(index, item.url)}
          >
            {item.type.startsWith("image/") ? (
              <img src={item.url} alt={`img ${index}`} />
            ) : (
              <video controls>
                <source src={item.url} type={item.type} />
                Your browser does not support the video tag.
              </video>
            )}
            <span
              className="remove-icons cp pa fs16"
              onClick={() => removeMedia(index)}
            >
              <AiOutlineClose />
            </span>
          </div>
        ))}
        {coverGallery.map((image, index) => (
          <div
            className={`media-cards pr box-center image-cards cp ${
              selectedImages.includes(image) ? "selected" : ""
            }`}
            key={index}
            onClick={() => toggleSelected(index, image)}
          >
            <img src={image} alt="" />
          </div>
        ))}
        <ToastContainer position="bottom-right" />
      </div>
    </div>
  );
};

export default CoverGallery;
