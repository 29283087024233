import React, { useRef, useEffect } from "react";
const Popup = ({ onClose, title, children }) => {
  const formRef = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKeyPress);

    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      onClose();
    }
  };
  const handleEscapeKeyPress = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  return (
    <div className="popup show">
      <div className="popup-content" ref={formRef}>
        <div className="modal-header" style={{ borderBottom: "0px none" }}>
          <h3>{title}</h3>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default Popup;
