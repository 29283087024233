import React, { useEffect, useRef } from "react";
import "./LeadDetailsPopup.css";
import SearchListing from "../PackageBuilder/SearchListing";
import { GiModernCity } from "react-icons/gi";
const PackageCityPopup = ({
  show,
  onClose,
  cityResponse,
  onCitySelect,
  onSearch,
}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (show) {
      disableScroll();
      document.addEventListener("click", handleClickOutside);
    } else {
      enableScroll();
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };
  if (!show) return null;
  const handleItineraryCitySelect = (city) => {
    onCitySelect(city);
  };
  const handleCitySearch = (searchQuery) => {
    onSearch(searchQuery, "city");
  };

  return (
    <div className="lead-popup-overlay">
      <div
        ref={popupRef}
        className={`lead-popup-content p12 pr ${show ? "slide-in" : ""}`}
      >
        <div className="v-center jcsb">
          <p>Select City</p>
          <button onClick={onClose} className="fs18 fc1 bg5 cp">
            X
          </button>
        </div>
        <div className="builder-filters mt12">
          <SearchListing
            onSearch={handleCitySearch}
            placeholder={"Search City"}
          />
        </div>
        {cityResponse.length > 0 ? (
          cityResponse.map((item) => (
            <div
              className="popup-card-wrapper  brd1 pt16 pb16 pl8 pr8 df mt24 mb24"
              key={item.id}
              onClick={() => handleItineraryCitySelect(item)}
            >
              <div className="left-image">
                <div className="image-contain pr">
                  <img src={item.image} alt={item.state} />
                </div>
              </div>
              <div className="pop-text pl16 w100">
                <div className="fs16 fw6 fc1 ls1 captw">{item.place}</div>
                <div className="fs14 mt4 fc5 ls1 captw">
                  {item.state && <span>{item.state + ", "}</span>}
                  {item.country_name && <span>{item.country_name}</span>}
                </div>

                {item.description && (
                  <div className="fs14 pt24 fc5 ls1 lh22">
                    {item.description}...
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className=" df fdc">
            <div className="box-center box-center brd1 p24 mt24 bg7 df fdc br4">
              <p className="no-icon box-center br50 brd1 fc1">
                <GiModernCity />
              </p>
              <p className="fs16 ls1 lh14 fc1">No City Found</p>
            </div>
            <div className="mt24 fs16 ls1 lh18 fc1 tac">
              City missing! Please email us at
              <a href="mailto:helpdesk@hellotravel.com" className="ml4">helpdesk@hellotravel.com</a>
              , and we'll work on adding it soon
            </div>
          </div>
        )}
        {cityResponse.length > 0 && (
          <div className="mt24 fs16 ls1 lh18 fc1 tac mb12">
            Is your desired city not available? Please email us at
            <a href="mailto:helpdesk@hellotravel.com" className="ml4 mr4">helpdesk@hellotravel.com</a>
            and we'll make sure to add it soon.
          </div>
        )}
      </div>
    </div>
  );
};
export default PackageCityPopup;
