// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .confirmation-dialog {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 300px;
  }
  
  .confirmation-content p {
    font-size: 16px;
    margin-bottom: 48px;
  }
  
  .confirmation-buttons {
    display: flex;
    justify-content: center;
  }
  
  .btn-cancel,
  .btn-confirm {
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border: none;
    border-radius: 24px;
    font-size: 14px;
    outline: none;
    transition: background-color 0.2s ease-in-out;
  }
  
  
  .btn-confirm {
    background-color: #e63f3f;
    color: #fff;
  }
  
  .btn-confirm:hover {
    background-color: #cd4e4e;

  }
  `, "",{"version":3,"sources":["webpack://./src/components/ConfirmationDialog.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,2CAA2C;IAC3C,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB;EACzB;;EAEA;;IAEE,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,6CAA6C;EAC/C;;;EAGA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,yBAAyB;;EAE3B","sourcesContent":[".confirmation-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background: rgba(0, 0, 0, 0.6);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 1000;\r\n  }\r\n  \r\n  .confirmation-dialog {\r\n    background: #fff;\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);\r\n    text-align: center;\r\n    max-width: 300px;\r\n  }\r\n  \r\n  .confirmation-content p {\r\n    font-size: 16px;\r\n    margin-bottom: 48px;\r\n  }\r\n  \r\n  .confirmation-buttons {\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n  \r\n  .btn-cancel,\r\n  .btn-confirm {\r\n    padding: 10px 20px;\r\n    margin: 0 10px;\r\n    cursor: pointer;\r\n    border: none;\r\n    border-radius: 24px;\r\n    font-size: 14px;\r\n    outline: none;\r\n    transition: background-color 0.2s ease-in-out;\r\n  }\r\n  \r\n  \r\n  .btn-confirm {\r\n    background-color: #e63f3f;\r\n    color: #fff;\r\n  }\r\n  \r\n  .btn-confirm:hover {\r\n    background-color: #cd4e4e;\r\n\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
