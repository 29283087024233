// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-select-container select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 0 0 33%;
  max-width: 33%;
  /* margin-left: 16px; */
}
/* 
.time-select-container select:first-child {
  margin-right: 8px;
} */

.selected-time {
  margin-top: 10px;
}
@media screen and (max-width: 480px) {
  .time-select-container select {
    margin-left: 0;
    flex: 0 0 33%;
    max-width: 33%;
    display: flex;
    flex-wrap: wrap;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/TimeSelect.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,uBAAuB;AACzB;AACA;;;GAGG;;AAEH;EACE,gBAAgB;AAClB;AACA;EACE;IACE,cAAc;IACd,aAAa;IACb,cAAc;IACd,aAAa;IACb,eAAe;EACjB;AACF","sourcesContent":[".time-select-container select {\r\n  padding: 10px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 5px;\r\n  flex: 0 0 33%;\r\n  max-width: 33%;\r\n  /* margin-left: 16px; */\r\n}\r\n/* \r\n.time-select-container select:first-child {\r\n  margin-right: 8px;\r\n} */\r\n\r\n.selected-time {\r\n  margin-top: 10px;\r\n}\r\n@media screen and (max-width: 480px) {\r\n  .time-select-container select {\r\n    margin-left: 0;\r\n    flex: 0 0 33%;\r\n    max-width: 33%;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
