// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.work-left input,
.work-left textarea {
  width: 100%;
  max-width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}
.work-right {
  flex: 0 0 47%;
}

@media only screen and (max-width: 480px) {
  .show-work-description {
    padding-left: 0;
  }
  .show-work-text{
    margin: 24px 0;
  }
  .how-it-worksgrp {
    flex-direction: column;
  }
  .work-left.flx50{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .work-right {
    margin-top: 0;
  }
  .image-preview{
    width: 100% !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .w66 {
    width: 100%;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .w66 {
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Profile.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,aAAa;AACf;AACA;EACE,aAAa;AACf;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,cAAc;EAChB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,cAAc;IACd,eAAe;EACjB;EACA;IACE,aAAa;EACf;EACA;IACE,sBAAsB;EACxB;AACF;AACA;EACE;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".work-left input,\r\n.work-left textarea {\r\n  width: 100%;\r\n  max-width: 100%;\r\n  padding: 12px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 4px;\r\n  font-size: 14px;\r\n  outline: none;\r\n}\r\n.work-right {\r\n  flex: 0 0 47%;\r\n}\r\n\r\n@media only screen and (max-width: 480px) {\r\n  .show-work-description {\r\n    padding-left: 0;\r\n  }\r\n  .show-work-text{\r\n    margin: 24px 0;\r\n  }\r\n  .how-it-worksgrp {\r\n    flex-direction: column;\r\n  }\r\n  .work-left.flx50{\r\n    flex: 0 0 100%;\r\n    max-width: 100%;\r\n  }\r\n  .work-right {\r\n    margin-top: 0;\r\n  }\r\n  .image-preview{\r\n    width: 100% !important;\r\n  }\r\n}\r\n@media only screen and (min-width: 481px) and (max-width: 768px) {\r\n  .w66 {\r\n    width: 100%;\r\n  }\r\n}\r\n@media only screen and (min-width: 769px) and (max-width: 1024px) {\r\n  .w66 {\r\n    width: 80%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
