import React, { useState } from "react";
import CustomDropdown from "./CustomDropdown";
import "../components/FilterPackage.css";
import MultiSelect from "./MultiSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "./Checkbox";
import moment from "moment/moment";
//import datacity from "../data/pttt"

const Filter = ({
  filters,
  agents,
  showCheckboxes,
  onClickFilterGo,
  setPackagesData,
  param,
}) => {
  const [selectedFilter, setSelectedFilter] = useState(filters[0]);
  const [filterValue, setFilterValue] = useState("");
  const [selectedAgent, setSelectedAgent] = useState(agents[0]);
  const [multiSelect, setMultiSelect] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const leadsVia = [
    { label: "Select Option", value: "" },
    { label: "Package", value: "Package" },
    { label: "Autobuy", value: "Autobuy" },
    { label: "Manual", value: "Manual" },
  ];
  const timeOptions = [
    { label: "Select Option", value: "" },
    { label: "Today", value: [moment().format("YYYY-MM-DD")] },
    {
      label: "Yesterday",
      value: [moment().subtract(1, "days").format("YYYY-MM-DD")],
    },
    {
      label: "Last 7 Days",
      value: [moment().subtract(7, "days").format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")],
    },
    {
      label: "Last 30 Days",
      value: [moment().subtract(30, "days").format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")],
    },
    {
      label: "This Month",
      value: [moment().startOf("month").format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")],
    },
    {
      label: "Custom",
      value: dateRange,
    },
  ];
  const dayTypeOptions = [
    { label: "Day", value: "day" },
    { label: "Month", value: "month" },
  ];
  const leadStatusOptions = [
    { label: "Select Option", value: "" },
    { label: "Hot", value: "Hot" },
    { label: "Follow-up", value: "Follow-up" },
    { label: "Booked", value: "Booked" },
  ];
  const packageTypeOptions = [
    { label: "Select Option", value: "" },
    { label: "Customized", value: "FL" },
    { label: "Fixed", value: "FI" },
  ];
  const packageStatusOptions = [
    { label: "Select Option", value: "" },
    { label: "Live", value: "Live" },
    { label: "Reject", value: "Reject" },
    { label: "Draft", value: "Draft" },
  ];
  const packageVisibilityOptions = [
    { label: "Select Option", value: "" },
    { label: "Public", value: "public" },
    { label: "Private", value: "private" },
  ];
  const packageListstatus = [
    { label: "Select Option", value: "" },
    { label: "Active", value: "1" },
    { label: "Inactive", value: "4" },
    { label: "In Progress", value: "2" },
    { label: "Rejected", value: "3" },
    { label: "Draft", value: "5" },
  ];
  const [selectedDayType, setSelectedDayType] = useState("day");
  const travelDestination = ["Goa", "Manali", "Bhopal"];
  const travelDuration = [
    "1 Days",
    "2 Days",
    "3 Days",
    "4 Days",
    "5 Days",
    "6 Days",
    "7 Days",
    "8 Days",
    "9 Days",
    "10 Days",
    "11 Days",
    "12 Days",
    "13 Days",
    "14 Days",
    "15 Days",
    "16 Days",
    "17 Days",
    "18 Days",
    "19 Days",
    "20 Days",
    "21 Days",
    "22 Days",
  ];
  const [selectedLeadVia, setSelectedLeadVia] = useState(
    leadsVia.find((option) => option.value === "Package")
  );
  const [selectedLeadType, setSelectedLeadType] = useState("Domestic");
  const leadTypeOptions = ["Domestic", "International"];
  const [selectedLeadReceivedDateLabel, setSelectedLeadReceivedDateLabel] =
    useState(timeOptions[0]);

  const [selectedLeadTravelDateLabel, setSelectedLeadTravelDateLabel] =
    useState(timeOptions[0]);
  const [selectedLeadReceivedDate, setSelectedLeadReceivedDate] =
    useState(null);
  const [selectedLeadTravelDate, setSelectedLeadTravelDate] = useState(null);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState(
    leadStatusOptions[0].label
  );
  const [selectedPackageType, setSelectedPackageType] = useState(
    packageTypeOptions[0].label
  );
  const [packageStatus, setPackageStatus] = useState(
    packageStatusOptions[0].label
  );
  const [packageVisibility, setPackageVisibility] = useState(
    packageVisibilityOptions[0].label
  );
  const [status, setStatus] = useState(packageListstatus[0].label);

  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);
    setFilterValue("");
  };

  const handleMultiSelect = (items) => {
    setMultiSelect(items);
    setFilterValue(items);
    //handleFormSetting('prefLang',items);
  };
  const handleGoButtonClick = () => {
    if (selectedFilter.value === "lastupdated") {
      if (selectedLeadTravelDateLabel.label === "Custom") {
        // console.log("lastupdated11111",selectedFilter,dateRange,selectedLeadTravelDateLabel);
        onClickFilterGo(selectedFilter,  [
            moment(dateRange[0]).format("YYYY-MM-DD"),
            moment(dateRange[1]).format("YYYY-MM-DD"),
          ]);
      } else {
        onClickFilterGo(selectedFilter, selectedLeadTravelDateLabel.value);
      }
    } else {
      //setPackagesData([]);
      onClickFilterGo(selectedFilter, filterValue);
    }
  };

  const handleClearFilterButtonClick = () => {
    setSelectedFilter(filters[0]);
    setSelectedLeadTravelDateLabel(timeOptions[0]);
    setDateRange([null,null])
    setFilterValue("");
    setMultiSelect([]);
    onClickFilterGo(selectedFilter, "");
  };

  const handleAgentSelect = (selectedOption) => {
    setSelectedAgent(selectedOption);
  };

  const handleLeadViaSelect = (selectedOption) => {
    setSelectedLeadVia(selectedOption);
  };

  const handleLeadReceivedDateChange = (date) => {
    setSelectedLeadReceivedDate(date);
    setFilterValue(date.value);
  };
  const handleLeadTravelDateChange = (date) => {
    setSelectedLeadTravelDate(date);
    setFilterValue(date.value);
  };
  const handleLeadTypeSelect = (selectedOption) => {
    setSelectedLeadType(selectedOption.label);
    setFilterValue(selectedOption.value);
  };
  const handleDayTypeSelect = (selectedOption) => {
    setSelectedDayType(selectedOption.label);
    setFilterValue(selectedOption.value);
  };
  const handleLeadStatusSelect = (selectedOption) => {
    setSelectedLeadStatus(selectedOption.label);
    setFilterValue(selectedOption.value);
  };
  const handlePackageTypeSelect = (selectedOption) => {
    setSelectedPackageType(selectedOption.label);
    setFilterValue(selectedOption.value);
  };
  const handlePackageVisibility = (selectedOption) => {
    setPackageVisibility(selectedOption.label);
    setFilterValue(selectedOption.value);
  };
  const handlePackageStatus = (selectedOption) => {
    setPackageStatus(selectedOption.label);
    setFilterValue(selectedOption.value);
  };
  const handleStatus = (selectedOption) => {
    setStatus(selectedOption.label);
    setFilterValue(selectedOption.value);
  };
  const optionLabels = ["Outbound", "Domestic", "Inbound"];
  const [checkboxValues, setCheckboxValues] = useState({});

  const handleCheckboxChange = (value) => {
    // Used a callback function to log the updated state synchronously
    setCheckboxValues((prevValues) => {
      const updatedValues = { ...prevValues, [value]: !prevValues[value] };
      return updatedValues;
    });
  };

  const handleDateRangeChange = (update) => {
    // console.log("Selected Date Range:", update);

    // let formattedStartDate = null;
    // let formattedEndDate = null;

    // if (update[0] !== null) {
    //   formattedStartDate = moment(update[0]).format("YYYY-MM-DD");
    // }

    // if (update[1] !== null) {
    //   formattedEndDate = moment(update[1]).format("YYYY-MM-DD");
    // }

    // const updatedDateRange = [formattedStartDate, formattedEndDate];

    setDateRange(update);
  };

  return (
    <div className="filter-container">
      <div className="df aic fww">
        <CustomDropdown
          options={filters}
          selectedOption={selectedFilter}
          onSelect={handleFilterChange}
        />
        {selectedFilter.value === "email" ||
        selectedFilter.value === "phone" ||
        selectedFilter.value === "name" ||
        selectedFilter.value === "leadId" ||
        selectedFilter.value === "id" ? (
          <div className="input-container aic mb8">
            <input
              className="input-field"
              type="text"
              placeholder={`Enter ${
                selectedFilter.value === "leadId" ? "Lead " : ""
              }${selectedFilter.value}...`}
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1  ml24"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
            {param.filtervalue && (
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 "
                onClick={handleClearFilterButtonClick}
              >
                Clear
              </button>
            )}
          </div>
        ) : null}
        {selectedFilter.value === "destination" ? (
          <>
            <div className="">
              <MultiSelect
                travelDestination={travelDestination}
                placeholder={"Destination"}
                onSave={handleMultiSelect}
                selectedItems={multiSelect}
                setSelectedItems={setMultiSelect}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
            {param.filtervalue && (
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleClearFilterButtonClick}
              >
                Clear
              </button>
            )}
          </>
        ) : null}
        {selectedFilter.value === "travelDuration" ? (
          <>
            <div>
              <MultiSelect
                travelDestination={travelDuration}
                placeholder={"Duration"}
                onSave={handleMultiSelect}
                selectedItems={multiSelect}
                setSelectedItems={setMultiSelect}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
            {param.filtervalue && (
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleClearFilterButtonClick}
              >
                Clear
              </button>
            )}
          </>
        ) : null}
        {selectedFilter.value === "leadReceivedDate" ? (
          <div className="report-date">
            {selectedLeadReceivedDateLabel.value === "custom" ? (
              <div className="df aic ">
                <DatePicker
                  dateFormat="dd-MMM-yyyy"
                  selected={selectedLeadReceivedDate}
                  onChange={handleLeadReceivedDateChange}
                  placeholderText="---- Select Date ----"
                  showIcon
                  isClearable={true}
                />
                <button
                  className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24 mt8"
                  onClick={handleGoButtonClick}
                >
                  GO
                </button>
                {param.filtervalue && (
                  <button
                    className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                    onClick={handleClearFilterButtonClick}
                  >
                    Clear
                  </button>
                )}
              </div>
            ) : (
              <div className="df aic">
                <CustomDropdown
                  options={timeOptions}
                  selected={selectedLeadReceivedDate}
                  onChange={handleLeadReceivedDateChange}
                />
                <button
                  className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
                  onClick={handleGoButtonClick}
                >
                  GO
                </button>
                {param.filtervalue && (
                  <button
                    className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                    onClick={handleClearFilterButtonClick}
                  >
                    Clear
                  </button>
                )}
              </div>
            )}
          </div>
        ) : null}
        {selectedFilter.value === "lastupdated" ? (
          <div className="report-date df">
            {selectedLeadTravelDateLabel.label === "Custom" ? (
              <div className="mb8 df aic">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleDateRangeChange}
                  isClearable={true}
                  placeholderText="---Select Date Range---"
                />
                <button
                  className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
                  onClick={handleGoButtonClick}
                >
                  GO
                </button>
                {param.filtervalue && (
                  <button
                    className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                    onClick={handleClearFilterButtonClick}
                  >
                    Clear
                  </button>
                )}
              </div>
            ) : (
              <div className="df aic">
                <CustomDropdown
                  options={timeOptions}
                  selectedOption={selectedLeadTravelDateLabel}
                  onSelect={(option) => setSelectedLeadTravelDateLabel(option)}
                />
                <button
                  className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
                  onClick={handleGoButtonClick}
                >
                  GO
                </button>
                {param.filtervalue && (
                  <button
                    className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                    onClick={handleClearFilterButtonClick}
                  >
                    Clear
                  </button>
                )}
              </div>
            )}
          </div>
        ) : null}

        {selectedFilter.value === "leadVia" ? (
          <>
            <div className="df aic jcc mt8">
              <CustomDropdown
                options={leadsVia}
                selectedOption={selectedLeadVia}
                onSelect={handleLeadViaSelect}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
            {param.filtervalue && (
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleClearFilterButtonClick}
              >
                Clear
              </button>
            )}
          </>
        ) : null}
        {selectedFilter.value === "agent" ? (
          <>
            <div className="mt8">
              <CustomDropdown
                options={agents}
                selectedOption={selectedAgent}
                onSelect={handleAgentSelect}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
            {param.filtervalue && (
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleClearFilterButtonClick}
              >
                Clear
              </button>
            )}
          </>
        ) : null}
        {selectedFilter.value === "leadType" ? (
          <>
            <div className="df aic">
              <CustomDropdown
                options={leadTypeOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
                selectedOption={{
                  label: selectedLeadType,
                  value: selectedLeadType,
                }}
                onSelect={handleLeadTypeSelect} // Update the selected lead type
              />
            </div>

            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
            {param.filtervalue && (
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleClearFilterButtonClick}
              >
                Clear
              </button>
            )}
          </>
        ) : null}
        {selectedFilter.value === "dayType" ? (
          <>
            <div className=" df aic">
              <CustomDropdown
                options={dayTypeOptions.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                selectedOption={{
                  label: selectedDayType,
                  value: selectedDayType,
                }}
                onSelect={handleDayTypeSelect}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
            {param.filtervalue && (
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleClearFilterButtonClick}
              >
                Clear
              </button>
            )}
          </>
        ) : null}
        {selectedFilter.value === "leadStatus" ? (
          <>
            <div className=" df aic">
              <CustomDropdown
                options={leadStatusOptions.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                selectedOption={{
                  label: selectedLeadStatus,
                  value: selectedLeadStatus,
                }}
                onSelect={handleLeadStatusSelect}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
            {param.filtervalue && (
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleClearFilterButtonClick}
              >
                Clear
              </button>
            )}
          </>
        ) : null}
        {selectedFilter.value === "type" ? (
          <>
            <div className=" df aic">
              <CustomDropdown
                options={packageTypeOptions.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                selectedOption={{
                  label: selectedPackageType,
                  value: selectedPackageType,
                }}
                onSelect={handlePackageTypeSelect}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
            {param.filtervalue && (
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleClearFilterButtonClick}
              >
                Clear
              </button>
            )}
          </>
        ) : null}
        {selectedFilter.value === "packageStatus" ? (
          <>
            <div className=" df aic">
              <CustomDropdown
                options={packageStatusOptions.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                selectedOption={{
                  label: packageStatus,
                  value: packageStatus,
                }}
                onSelect={handlePackageStatus}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
            {param.filtervalue && (
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleClearFilterButtonClick}
              >
                Clear
              </button>
            )}
          </>
        ) : null}
        {selectedFilter.value === "packageVisibility" ? (
          <>
            <div className=" df aic">
              <CustomDropdown
                options={packageVisibilityOptions.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                selectedOption={{
                  label: packageVisibility,
                  value: packageVisibility,
                }}
                onSelect={handlePackageVisibility}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
            {param.filtervalue && (
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleClearFilterButtonClick}
              >
                Clear
              </button>
            )}
          </>
        ) : null}
        {selectedFilter.value === "status" ? (
          <>
            <div className=" df aic">
              <CustomDropdown
                options={packageListstatus.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                selectedOption={{
                  label: status,
                  value: status,
                }}
                onSelect={handleStatus}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1 ml24"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
            {param.filtervalue && (
              <button
                className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                onClick={handleClearFilterButtonClick}
              >
                Clear
              </button>
            )}
          </>
        ) : null}
      </div>
      {showCheckboxes && (
        <div className="df aic pl8 pr8 pt16">
          {optionLabels.map((label, index) => (
            <Checkbox
              key={label}
              label={label}
              checked={checkboxValues[label]}
              onChange={() => handleCheckboxChange(label)}
              value={label}
              className={index > 0 ? "ml16" : ""}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Filter;
