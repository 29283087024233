// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-gallerys {
    justify-content: flex-start; 
    align-items: stretch; 
  }
  
  .media-cards {
    border: 1px solid #e4e4e4;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
    padding: 10px;
    width: 200px;
  }
  
  .image-cards img {
    max-width: 100%;
    max-height: 100px;
  }
  
  .video-cards video {
    max-width: 100%;
    max-height: 100px;
  }
  
  .remove-icons {
    top: 2px;
    right: 4px;
    color: #d50707;
  }
  
  .media-upload-buttons {
    padding: 32px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    transition: background-color 0.2s;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px; 
    width: 200px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  
.media-upload-buttons:hover {
    background-color: #e0e0e0;
  }
  .media-card-containers {
    justify-content: flex-start;
    align-items: stretch;     
    border: 1px solid #e4e4e4;
  }`, "",{"version":3,"sources":["webpack://./src/components/CoverGallery.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,oBAAoB;EACtB;;EAEA;IACE,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,YAAY;EACd;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,QAAQ;IACR,UAAU;IACV,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,iCAAiC;IACjC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,wCAAwC;EAC1C;;;AAGF;IACI,yBAAyB;EAC3B;EACA;IACE,2BAA2B;IAC3B,oBAAoB;IACpB,yBAAyB;EAC3B","sourcesContent":[".image-gallerys {\r\n    justify-content: flex-start; \r\n    align-items: stretch; \r\n  }\r\n  \r\n  .media-cards {\r\n    border: 1px solid #e4e4e4;\r\n    margin-top: 8px;\r\n    margin-bottom: 8px;\r\n    margin-left: 8px;\r\n    padding: 10px;\r\n    width: 200px;\r\n  }\r\n  \r\n  .image-cards img {\r\n    max-width: 100%;\r\n    max-height: 100px;\r\n  }\r\n  \r\n  .video-cards video {\r\n    max-width: 100%;\r\n    max-height: 100px;\r\n  }\r\n  \r\n  .remove-icons {\r\n    top: 2px;\r\n    right: 4px;\r\n    color: #d50707;\r\n  }\r\n  \r\n  .media-upload-buttons {\r\n    padding: 32px;\r\n    border: 1px solid #ccc;\r\n    background-color: #f0f0f0;\r\n    transition: background-color 0.2s;\r\n    margin-top: 8px;\r\n    margin-bottom: 8px;\r\n    margin-left: 8px; \r\n    width: 200px; \r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n  }\r\n  \r\n  \r\n.media-upload-buttons:hover {\r\n    background-color: #e0e0e0;\r\n  }\r\n  .media-card-containers {\r\n    justify-content: flex-start;\r\n    align-items: stretch;     \r\n    border: 1px solid #e4e4e4;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
