import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InnerHeader from "../components/InnerHeader";
import LeadDetail from "./LeadDetail";
import LeadListing from "../components/LeadListing";
import { FaEdit, FaInfoCircle, FaQuoteLeft, FaThumbsUp } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import Popup from "../components/Popup/Popup";
import StatusUpdateForm from "../components/Forms/StatusUpdateForm";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import DotLoader from "../components/DotLoader.js";
import { useTitle } from "../hooks/useTitle";
import SmallLoader from "../components/SmallLoader.js";
import trackEvent from "../utils/gaTracker";

const MyLeads = () => {
  const { id } = useParams();
  useTitle("My Leads -  Travel Agents CRM - Hellotravel");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);

  const [selectedLead, setSelectedLead] = useState(null);
  const [quoteContent, setQuoteContent] = useState(null);
  const [showQuoteContent, setShowQuoteContent] = useState(false);
  const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);
  const [isReviewPopupOpen, setIsReviewPopupOpen] = useState(false);
  const [isLeadDetailEditable, setIsLeadDetailEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRowClickInTable = (leadData) => {
    setIsLoading(true);
    setQuoteContent(null);
    setShowQuoteContent(false);
    setTimeout(() => {
      setSelectedLead(leadData);
      setIsLoading(false);
    }, 1500);
  };

  const handleCloseStatusPopup = () => {
    setIsStatusPopupOpen(false);
  };

  const handleCloseReviewPopup = () => {
    setIsReviewPopupOpen(false);
  };
  // Function to update the status of the selected lead
  const updateLeadStatus = (newStatus) => {
    setSelectedLead((prevLead) => ({
      ...prevLead,
      status: newStatus.status,
    }));
    setIsStatusPopupOpen(false);
  };
  const hendelRedirect = () => {
    setSelectedLead(null);
    if (id) {
      navigate("/my-leads/");
    }
  };

  return (
    <>
      <div className="main-content-padding">
        {!selectedLead && !id && (
          <InnerHeader
            heading="My Leads"
            iconText="Buy Leads"
            leftContent={null}
            txtSubHeading="Effortlessly access and manage your leads, unlocking sales and growth opportunities with ease."
          />
        )}
        {isLoading ? (
          <div className="overlay-loader ">
            <SmallLoader />
          </div>
        ) : selectedLead || id ? (
          <LeadDetail
            leadData={selectedLead}
            showQuoteContent={showQuoteContent}
            InnerHeader={
              <InnerHeader
                heading={selectedLead || id ? "Lead Detail" : "My Leads"}
                iconText={selectedLead || id ? null : "Buy Leads"}
                txtSubHeading=""
                leftContent={
                  selectedLead || id ? (
                    <div
                      onClick={() => {
                        hendelRedirect();
                        trackEvent("Lead Detail", "click", `Back`);
                      }}
                      className="back-to-leadlist"
                    >
                      <BiArrowBack className="cp" />
                    </div>
                  ) : null
                }
              />
            }
            setShowQuoteContent={setShowQuoteContent}
            isLeadDetailEditable={isLeadDetailEditable}
          />
        ) : (
          <LeadListing onRowClick={handleRowClickInTable} />
        )}
        {isStatusPopupOpen && (
          <Popup onClose={handleCloseStatusPopup} title={"Update Status"}>
            <StatusUpdateForm
              selectedStatus={selectedLead}
              onUpdateStatus={updateLeadStatus}
              onClose={handleCloseStatusPopup}
            />
          </Popup>
        )}
        {isReviewPopupOpen && (
          <Popup onClose={handleCloseStatusPopup} title={"Ask for Review"}>
            <StatusUpdateForm
              selectedStatus={selectedLead}
              onUpdateStatus={updateLeadStatus}
              onClose={handleCloseReviewPopup}
            />
          </Popup>
        )}
      </div>
    </>
  );
};

export default MyLeads;
