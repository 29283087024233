import React, { useState,useEffect } from "react";
import htLogo from "../assets/htlogo.png";
import "./Banner.css";
import { Link } from "react-router-dom";

const Banner = ({condition}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseBanner = () => {
    saveDataWithExpiry("bannerPop",1);
    setIsOpen(false);
  };
  
useEffect(() => {
  if(getDataWithExpiry("bannerPop")){
    setIsOpen(false);
  }
  else{
    setIsOpen(true);
  }
}, []);
function saveDataWithExpiry(key, value) {
  const now = new Date();
  const item = {
      value: value,
      expiry: now.getTime() + 24 * 60 * 60 * 1000 // 24 hours in milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item));
}
function getDataWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
      return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
  }
  return item.value;
}
  return (
    <>
      {isOpen && (
        <div className="bannere-container p12 br4">
          <div className="banner-content df aic fww pr">
            <div className={`${condition !== 'itilist' ? "banner-subtext" : "banner-subtext2"} fc_blackish fs16 ls1 lh22`}>
              <span className="fw6 mr4">Attention:Critical Update!</span>If you
              haven't updated your itineraries yet, please ensure they comply
              with our latest posting form{" "}
              <span className="fw6">before July 31, 2024</span>. If not updated,
              they'll be unpublished. Please update your itineraries now to
              maintain quality standards.
            </div>
            {condition!='itilist' && <div className="btn-container flx1 df jce">
              <Link to="/packages">
                <button className="banner-btn ls1 br24 fs14 fw5 cp fc_yellow ml4">
                  Update&nbsp;Now
                </button>
              </Link>
            </div>}
            <div className="close-banner-btn pa fc9 cp" onClick={handleCloseBanner}>X</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
