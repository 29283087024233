// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-container{
  height: 80%;
}
.ql-editor{
  background-color: #fff;
}
.package-policies li {
  list-style-type: circle;
  margin-left: 16px;
  letter-spacing: .4px;
  line-height: 18px;
  padding-left: 8px;
  line-height: 24px;
  margin-top: 12px;
}
.bl4{
  border-left: 4px solid #594cda;
}

.term-button {
    align-self: flex-end;
    padding: 10px 20px;
}

@media only screen and (max-width: 480px) {
  .term-item {
    flex-direction: column;
    padding-bottom: 0;
  }
  .image-preview{
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Tnc.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,sBAAsB;AACxB;AACA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,oBAAoB;EACpB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,8BAA8B;AAChC;;AAEA;IACI,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;EACE;IACE,sBAAsB;IACtB,iBAAiB;EACnB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".ql-container{\r\n  height: 80%;\r\n}\r\n.ql-editor{\r\n  background-color: #fff;\r\n}\r\n.package-policies li {\r\n  list-style-type: circle;\r\n  margin-left: 16px;\r\n  letter-spacing: .4px;\r\n  line-height: 18px;\r\n  padding-left: 8px;\r\n  line-height: 24px;\r\n  margin-top: 12px;\r\n}\r\n.bl4{\r\n  border-left: 4px solid #594cda;\r\n}\r\n\r\n.term-button {\r\n    align-self: flex-end;\r\n    padding: 10px 20px;\r\n}\r\n\r\n@media only screen and (max-width: 480px) {\r\n  .term-item {\r\n    flex-direction: column;\r\n    padding-bottom: 0;\r\n  }\r\n  .image-preview{\r\n    width: 100%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
