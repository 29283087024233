

import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import {Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';

import SignUp from './pages/SignupForm';
import { useDispatch } from "react-redux";
import { setUser } from "./store/authSlice.js";
//import ReactGA from 'react-ga';
//import {BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
//import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//const TRACKING_ID = "G-EH3W0WJ4YX"; // OUR_TRACKING_ID
//ReactGA.initialize(TRACKING_ID);


function App() {
useEffect(() => {
    // Initialize Google Analytics
    initGA();

    // Track a page view
    window.gtag('config', 'G-EH3W0WJ4YX', {
      page_path: window.location.pathname,
    });

  }, []);

	const initGA = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }  
    window.gtag = gtag; 
    gtag('js', new Date());
    gtag('config', 'G-EH3W0WJ4YX');
  };

  const dispatch = useDispatch();
  // useEffect(() => {
    // Local storage se data retrieve hoga isse
    const getSavedLocalData = JSON.parse(localStorage.getItem('htloginDetails'));

    if (getSavedLocalData) {
      // yaha se redux mei data set ho jaaega
      dispatch(setUser(getSavedLocalData));
    }
  // }, [dispatch]);

  
  const isLoggedIn = useSelector((state) => state.auth.userid!==null);
/*
    useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
*/
  return (

    
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signup/:rmid" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
	    <Route path="/login/:id" element={<Login />} />
        {isLoggedIn && isLoggedIn ? (
          <Route path="/*" element={<Layout/>} />
        ) : (
          <Route
            path="/*"
            element={
              isLoggedIn ? (
                <Layout />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        )}
      </Routes>

    </div>

  );
}

export default App;
