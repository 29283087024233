import React, { useState, useRef, useEffect } from "react";
import "./Filter.css";
import { FaAngleDown } from "react-icons/fa";

const MultiSelect = ({ travelDestination, placeholder, onSave ,selectedItems,setSelectedItems}) => {
 
  const [suggestions, setSuggestions] = useState(travelDestination);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleItemSelect = (item) => {
    if (!selectedItems.includes(item)) {
    setSelectedItems((prevSelectedItems) => {

      if (!prevSelectedItems.includes(item)) {
        return [...prevSelectedItems, item];
      }
      return prevSelectedItems;
      
    });
    // Invoke the onSave callback to pass the selected items
    onSave([...selectedItems, item]); // Update selected items when a new item is selected
  }
  };
  

  const handleItemRemove = (item) => {
    const updatedItems = selectedItems.filter(
      (selectedItem) => selectedItem !== item
    );
    setSelectedItems(updatedItems);
    // Invoke the onSave callback to pass the updated selected items
    onSave(updatedItems);
  };
  
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="destination-multi-select mb8 pr ">
      <div className="selected-items " onClick={toggleDropdown}>
        {selectedItems ===undefined || selectedItems.length <= 0? (
          <div className="placeholder v-center fs14">{`Select ${placeholder}`}</div>
        ) : (
          selectedItems.map((item, index) => (
            <div key={index} className="selected-item">
              {item}
              <button
                className="remove-button"
                onClick={() => handleItemRemove(item)}
              >
                &times;
              </button>
            </div>
          ))
        )}
      </div>
      {isDropdownOpen && (
        // <div className="suggestions" ref={dropdownRef} >
        //   {suggestions.map((suggestion, index) => (
        //     <div
        //       key={index}
        //       className="suggestion-item"
        //       onClick={() => handleItemSelect(suggestion)}
        //     >
        //       {suggestion}
        //     </div>
        //   ))}
        // </div>
        <ul className="role-options fs14" ref={dropdownRef}>
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleItemSelect(suggestion)}>
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiSelect;
