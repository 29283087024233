import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "./LeadDetailsPopup.css";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import SearchListing from "../PackageBuilder/SearchListing";
import { FaAngleDown } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaHotel } from "react-icons/fa6";
import ImageUpload from "../ImageUpload";
const PackageHotelPopup = ({
  show,
  onClose,
  hotelResponse,
  onHotelSelect,
  toast,
  onSearch,
}) => {
  const user = useSelector((state) => state.auth);
  const [authId, setAuthID] = useState(user.auth_id);
  const [baseUrl, setBaseUrl] = useState(user.base_url);
  const [siteUrl, setSiteUrl] = useState(user.site_url);
  const [isAddHotelFormVisible, setAddHotelFormVisible] = useState(false);
  const [hotelDetails, setHotelDetails] = useState({
    hotel_name: "",
    hotel_type: "",
    hotel_city: "",
    includes: "",
    room_type: "",
  });
  const [showRatingDropdown, setShowRatingDropdown] = useState(false);
  const [uploadImgLocal, setUploadImgLocal] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [imgFile, setImgFile] = useState("");

  const dropdownRef = useRef(null);
  const popupRef = useRef(null);

  useEffect(() => {
    if (show) {
      disableScroll();
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      enableScroll();
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);
  useEffect(() => {
    setHotelDetails((prevDetails) => ({
        ...prevDetails,
        hotel_image: imgFile,hotel_store:'manual',
    }));
}, [imgFile]);

  const handleClickOutside = (event) => {
    if (event.target.closest(".lead-popup-content") === null) {
      onClose();
      setAddHotelFormVisible(false);
    }
  };

  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };
  if (!show) return null;
  const renderStars = (starRating) => {
    const filledStars = Array.from({ length: starRating }, (_, index) => (
      <AiFillStar key={index} className="fc6" />
    ));
    const outlineStars = Array.from({ length: 5 - starRating }, (_, index) => (
      <AiOutlineStar key={index} />
    ));

    return (
      <p className="">
        {filledStars}
        {outlineStars}
      </p>
    );
  };
  const handleItineraryHotelSelect = (hotel) => {
    onHotelSelect(hotel);
  };
  const handleAddHotelClick = () => {
    setAddHotelFormVisible(true);
  };

  const handleAddHotelSubmit = () => {
    if (!hotelDetails.hotel_name) {
      toast.error("Enter hotel name.");
      return false;
    }
    if (!hotelDetails.hotel_type) {
      toast.error("Select hotel type.");
      return false;
    }
    if (!hotelDetails.hotel_city) {
      toast.error("Enter hotel city name.");
      return false;
    }
    
    //console.log(hotelDetails);return;
    onHotelSelect(hotelDetails);
    setAddHotelFormVisible(false);
    setHotelDetails({
      hotel_name: "",
      hotel_type: "",
      hotel_city: "",
      includes: "",
      room_type: "",
    });
  };

  const handleHotelChange = (field, value) => {
    setHotelDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };
  const handleHotelSearch = (searchQuery) => {
    onSearch(searchQuery, "hotel");
  };

  const hotelTypeOptions = [
    "1 Star",
    "2 Star",
    "3 Star",
    "4 Star",
    "5 Star",
    "5+ Star",
    "Unrated",
    "Guest House",
    "Service Apartment",
  ];

  const hotelTypeMapping = {
    "1 Star": "1",
    "2 Star": "2",
    "3 Star": "3",
    "4 Star": "4",
    "5 Star": "5",
    "5+ Star": "5+",
    Unrated: "Unrated",
    "Guest House": "Guest House",
    "Service Apartment": "Service Apartment",
  };
  const handleHotelTypeSelect = (selectedHotelType) => {
    setShowRatingDropdown(false);
    setHotelDetails((prevDetails) => ({
      ...prevDetails,
      hotel_type: hotelTypeMapping[selectedHotelType],
    }));
  };

  return (
    <div className="lead-popup-overlay">
      <div
        className={`lead-popup-content pr p12 ${show ? "slide-in" : ""}`}
        ref={popupRef}
      >
        <div className="v-center jcsb">
          {isAddHotelFormVisible ? (
            <button
              onClick={() => setAddHotelFormVisible(false)}
              className="transfer-back bg5 cp fc1 v-center"
            >
              <IoMdArrowRoundBack className="mr8" />
              Back to Hotel
            </button>
          ) : (
            <p>Select Hotel</p>
          )}

          <button
            onClick={() => {
              onClose();
              setAddHotelFormVisible(false);
            }}
            className="fs18 fc1 bg5 cp"
          >
            X
          </button>
        </div>
        {/* Add Hotel Form */}
        {isAddHotelFormVisible ? (
          <div className="flight-form mt12">
            <p className="box-center">Add Hotel</p>
            <div className="box-center fs14 fw4 mb24 c2 lh14">
              Include hotel details for a tailored stay.
            </div>
            <div className="df hotel-popup">
              <div className="flx48 req-star hotel-name">
                <label>
                  Hotel Name<span>*</span>
                </label>
                <input
                  type="text"
                  name="hotel_name"
                  value={hotelDetails.hotel_name}
                  onChange={(e) =>
                    handleHotelChange("hotel_name", e.target.value)
                  }
                  className="form-input"
                />
              </div>
              <div className="flx48 ml24 req-star hotel-type">
                <label>
                  Hotel Type <span>*</span>
                </label>

                <div className="role-dropdown hotel-forms" ref={dropdownRef}>
                  <div
                    className="selected-role fs14"
                    onClick={() => setShowRatingDropdown(!showRatingDropdown)}
                  >
                    {hotelDetails.hotel_type && !isNaN(hotelDetails.hotel_type)
                      ? `${hotelDetails.hotel_type} Star`
                      : hotelDetails.hotel_type || "Select Hotel Type"}
                    <FaAngleDown className="fc16 fs14" />
                  </div>

                  {showRatingDropdown && (
                    <ul className="role-options fs14">
                      {hotelTypeOptions.map((option) => (
                        <li
                          key={option}
                          onClick={() => handleHotelTypeSelect(option)}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="df mt8 hotel-city-cont">
              <div className="flx48 req-star hotel-city">
                <label>
                  Hotel City <span>*</span>
                </label>
                <input
                  type="text"
                  name="city_name"
                  value={hotelDetails.hote_city}
                  onChange={(e) =>
                    handleHotelChange("hotel_city", e.target.value)
                  }
                  className="form-input"
                />
              </div>
              <div className="flx48 hotel-rtype ml24 mt8">
                <label>Room Type</label>
                <input
                  type="text"
                  name="room_type"
                  value={hotelDetails.room_type}
                  onChange={(e) =>
                    handleHotelChange("room_type", e.target.value)
                  }
                  className="form-input"
                />
              </div>
            </div>
            <div className="flx100 req-star hotel-inc mt8">
              <label className="inc mb12">Inclusions</label>
              <input
                type="text"
                name="includes"
                value={hotelDetails.includes}
                onChange={(e) => handleHotelChange("includes", e.target.value)}
                className="form-input"
              />
            </div>
            <div className="req-star actv-img-upload mt16">
              <label>
                Hotel Image
              </label>
              <ImageUpload
              authId={authId}
              baseUrl={baseUrl}
              imageUrl={profileImg}
              folder="agent-signature-image/userimg/"
              uploadUrl="/subuser/package_insert_hotel_image.php?fun=hotelimageupload"
              deleteUrl="/subuser/package_insert_hotel_image.php?fun=hotelimagedelete"
              setWorkImage={setProfileImg}
              delimg={true}
              toast={toast}
              eventLabel={"Upload Image"}
              eventCategory={"Settings Page"}
              uploadImgLocal={uploadImgLocal}
              setUploadImgLocal={setUploadImgLocal}
              hotelDetails={hotelDetails}
              uploadtype={"hotelimage"}
              setImgFile={setImgFile}
              
            />
            </div>
            <div className="box-center">
              <button
                onClick={handleAddHotelSubmit}
                className="form-button submit-button bg1 fc4 mt16"
              >
                Add Hotel
              </button>
              {/* <div
                onClick={() => setAddHotelFormVisible(false)}
                className="fc14 mt16 cp"
              >
                Back
              </div> */}
            </div>
          </div>
        ) : (
          <>
            <div className="builder-filters mt12">
              <SearchListing
                onSearch={handleHotelSearch}
                placeholder={"Search Hotel"}
              />
            </div>
            {hotelResponse.length > 0 ? (
              hotelResponse.map((item) => (
                <div
                  className="popup-card-wrapper brd1 pt16 pb16 pl8 pr8 df mt24 mb24"
                  key={item.id}
                  onClick={() => handleItineraryHotelSelect(item)}
                >
                  <div className="left-image">
                    <div className="image-contain pr">
                      <img src={item.image} alt={item.city_name} />
                    </div>
                  </div>
                  <div className="pop-text pl16 w100">
                    <div className="v-center">
                      <p className="fc1 fw6 fs16 ls1 lh18 fww">
                        {item.hotel_name}
                      </p>
                    </div>
                    <div className="v-center mt4">
                      {renderStars(parseInt(item.hotel_type, 10))}
                    </div>
                    <div className="fs14 fw5 ls1 lh18 fc16 mt4">
                      {item.hotel_address}
                    </div>
                    <div className="fs14 pt24 fc5 ls1 lh22">
                      {item.includes && item.includes !== "" && (
                        <p>
                          Includes: <span>{item.includes}</span>
                        </p>
                      )}
                      {item.room_type && item.room_type !== "" && (
                        <p>
                          Room Type: <span>{item.room_type}</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="box-center box-center brd1 p24 mt24 bg7 df fdc br4 mb24">
                <p className="no-icon box-center br50 brd1 fc1">
                  <FaHotel />
                </p>
                <p className="fs16 ls1 lh14 not-found fc1">No Hotel Found</p>
              </div>
            )}
            <div className="fixed-button">
              <button
                onClick={handleAddHotelClick}
                className="bg1 p12 fc3 w100 br4 cp"
              >
                Add Hotel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PackageHotelPopup;
