import React, { useState, useEffect, useRef } from "react";
import "../components/CustomDropdown.css";

const CustomDropdown = ({ options, selectedOption, onSelect, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`custom-dropdown mr8 ${isOpen ? "open" : ""}`}
      onClick={() => setIsOpen(!isOpen)}
      ref={dropdownRef}
    >
      <div className="selected-option">
        {selectedOption ? selectedOption.label : placeholder}
      </div>
      <div className="dropdown-arrow"></div>
      {isOpen && (
        <ul className="options">
          {options.map((option) => (
            <li key={option.value} onClick={() => handleSelect(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
