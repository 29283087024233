import axios from "axios";
import React, { useState, useEffect } from "react";
import "../pages/Team.css";
import { BsTrash, BsToggleOn, BsToggleOff } from "react-icons/bs";
import { FaPencilAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Card from "../components/Card";
import AddTeamMemberForm from "../components/AddTeamMemberForm";
import SucessMessage from "../components/SucessMessage";
import ConfirmationDialog from "../components/ConfirmationDialog";
import Dropdown from "../components/TeamDropdown";
import Tooltip from "../components/Tooltip";
import InnerHeader from "../components/InnerHeader";
import SmallLoader from "../components/SmallLoader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../hooks/useTitle";
import { ToastContainer, toast } from "react-toastify";
import trackEvent from "../utils/gaTracker";

const Team = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useTitle("My Team -  Travel Agents CRM - Hellotravel");

  const user = useSelector((state) => state.auth);

  const userpkgid = user.otherdata.other_details.PKGID;

  var paidplan = false;

  if (userpkgid <= 0) {
    paidplan = true;
    // paidplan = false;
  }

  const [loginStatus, setLoginStatus] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [roleOptions, setroleOptions] = useState([]);
  const [roleOptionsHash, setroleOptionsHash] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [errorStatus, setErrorStatus] = useState(false);
  const [authId, setAuthID] = useState(user.auth_id);
  const [showPopup, setShowPopup] = useState(false);
  const [editedMember, setEditedMember] = useState(null);
  const [successType, setSuccessType] = useState(null);
  const [errorType, setErrorType] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const [showDeleteSuccessMessage, setShowDeleteSuccessMessage] =
    useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(" Status");
  const [selectedRole, setSelectedRole] = useState("Role");
  const [selectedStatusId, setSelectedStatusId] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [toggleSuccessMessage, setToggleSuccessMessage] = useState("");
  const [toggleErrorMessage, setToggleErrorMessage] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [autoLoader, setAutoLoader] = useState(true);
  const [isCheckLoginType, setisCheckLoginType] = useState([]);

  const handleLogout = () => {
    // Dispatch the logout action to clear user data
    dispatch(logout());
    // Redirect to the login page
    navigate("/login");
  };

  useEffect(() => {
    getUsers("", "");
    getRoles();
  }, []);

  function getUsers(status, role) {
    setAutoLoader(true);
    axios({
      method: "post",
      url: `${user.base_url}/subuser/user.php`,
      headers: {},
      headers: {},
      data: {
        status: status,
        role: role,
        auth_id: authId,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
      }
      setisCheckLoginType(response.data.login);
      setTeamMembers(response.data.data.datalisting);

      setLoginStatus(response.data.login.status);

      setAutoLoader(false);
    });
  }

  function getRoles() {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/user.php?fun=getrole`,
      headers: {},
      data: {
        auth_id: authId,
      },
    }).then(function (response) {
      setroleOptions(response.data.data.rowdetail);
      setroleOptionsHash(response.data.data.rowdetailhash);
    });
  }

  useEffect(() => {
    if (showPopup) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [showPopup]);

  const handleEditTeamMember = (member) => {
    trackEvent("My Team", "click", "Edit");
    setEditedMember(member);
    setShowPopup(true);
    disableScroll();
    setShowDeleteSuccessMessage(false);
  };

  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const toggleLeadAutoAssign = (checkbox, id, role, v) => {
    trackEvent("My Team", "click", "Lead Auto Assign");

    if (checkbox.target.checked) {
      LeadManage(id, role, "LeadAutoAssign", v, 1);
    } else {
      LeadManage(id, role, "LeadAutoAssign", v, 0);
    }

    if (role === "Sales") {
      setTeamMembers((prevState) =>
        prevState.map((member) =>
          member.TBL_LOGIN_ID === id
            ? { ...member, leadAutoAssign: !member.leadAutoAssign }
            : member
        )
      );
    }
  };

  const toggleBuyLeadAccess = (checkbox, id, role, v) => {
    trackEvent("My Team", "click", "Buy lead Access");
    if (checkbox.target.checked) {
      LeadManage(id, role, "BuyLeadAccess", v, 1);
    } else {
      LeadManage(id, role, "BuyLeadAccess", v, 0);
    }

    if (role === "Sales") {
      setTeamMembers((prevState) =>
        prevState.map((member) =>
          member.TBL_LOGIN_ID === id
            ? { ...member, buyLeadAccess: !member.buyLeadAccess }
            : member
        )
      );
    }
  };

  function LeadManage(id, role, type, v, checkbox) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/user.php?fun=LeadManage`,
      headers: {},
      data: {
        id: id,
        type: type,
        v: v,
        checkbox: checkbox,
        auth_id: authId, // This is the body part
      },
    }).then((response) => {
      //setTeamMembers(response.data.data.datalisting);
    });
  }

  const toggleSwitch = (id) => {
    trackEvent("My Team", "click", "Active/Deactive");
    const memberIndex = teamMembers.findIndex(
      (member) => member.TBL_LOGIN_ID === id
    );

    if (memberIndex !== -1) {
      const updatedTeamMembers = [...teamMembers];
      const toggledMember = { ...updatedTeamMembers[memberIndex] };

      toggledMember.isToggled = !toggledMember.isToggled;
      updatedTeamMembers[memberIndex] = toggledMember;

      setTeamMembers(updatedTeamMembers);
      const memberName = toggledMember.TBL_LOGIN_EMAIL;
      if (toggledMember.isToggled) {
        leadStatusupadte(id, "Active");

        setSuccessType(null);
        setErrorType(null);
        setSuccessMessage(false);
        setErrorMessage(false);
        setToggleErrorMessage(false);
        setShowDeleteSuccessMessage(false);
        toast.success(`${memberName} Activate Successfully`);
      } else {
        leadStatusupadte(id, "Deactive");
        setSuccessType(null);
        setErrorType(null);
        setSuccessMessage(false);
        setErrorMessage(false);
        setToggleSuccessMessage(false);
        setShowDeleteSuccessMessage(false);
        toast.success(`${memberName} Deactivate Successfully`);
      }
    }
  };

  const handleAddTeamMember = (formData) => {
    if (editedMember) {
      formData.USER_ROLE = roleOptionsHash[formData.roleId];
      setTeamMembers((prevState) =>
        prevState.map((member) =>
          member.TBL_LOGIN_ID === editedMember.TBL_LOGIN_ID
            ? { ...member, ...formData }
            : member
        )
      );

      updateUser(formData, editedMember.TBL_LOGIN_ID);
    } else {
      saveUsers(formData);
    }

    setShowPopup(false);
    enableScroll();
    setShowDeleteSuccessMessage(false);
  };

  //update a user

  function updateUser(formData, id) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/user.php?fun=updateUser`,
      headers: {},
      data: {
        id: id,
        role: formData.roleId,
        auth_id: authId, // This is the body part
      },
    }).then(function (response) {
      if (response.data.data.status === "1") {
        setErrorType(null);
        setErrorMessage(false);
        setToggleSuccessMessage(false);
        setToggleErrorMessage(false);
        setSuccessType("sucess");
        toast.success("Role updated successfully");
      } else {
        setSuccessType(null);
        setToggleErrorMessage(false);
        setToggleSuccessMessage(false);
        setSuccessMessage(false);
        setErrorType("error");
        toast.error("Something went wrong");
      }
    });
  }

  //add a new member
  function saveUsers(formData) {
    const newMember = {
      auth_id: authId,
      ...formData,
      status: "invited",
      isToggled: true,
      lastLogin: "",
    };

    // return false;

    axios({
      method: "post",
      url: `${user.base_url}/subuser/user.php?fun=create`,
      headers: {},
      data: newMember,
    }).then(function (response) {
      if (response.data.data.created.length > 0) {
        setToggleErrorMessage(false);
        setToggleSuccessMessage(false);
        setSuccessType("sucess");
        setSuccessMessage(response.data.data.created);
      } else {
        setSuccessType(null);
        setSuccessMessage(false);
      }

      if (response.data.data.exist.length > 0) {
        setToggleErrorMessage(false);
        setToggleSuccessMessage(false);
        setErrorType("error");
        setErrorMessage(response.data.data.exist);
      } else {
        setErrorType(null);
        setErrorMessage(false);
      }

      if (response.data.data.invalidmail.length > 0) {
        setAlertType("error");
        setAlertMessage(response.data.data.invalidmail);
      } else {
        setAlertType(null);
        setAlertMessage(false);
      }

      response.data.data.createdUser.map((r, i) => {
        setTeamMembers((prevState) => {
          const updatedMembers = [...prevState, r];
          return updatedMembers;
        });
      });
    });
  }

  const handleDeleteTeamMember = (id) => {
    trackEvent("My Team", "click", "Delete Icon");

    const member = teamMembers.find((member) => member.TBL_LOGIN_ID === id);

    if (member) {
      setConfirmationMessage(
        <>
          Are you sure you want to delete{" "}
          <span style={{ color: "#594cda", fontWeight: "600" }}>
            {member.TBL_LOGIN_EMAIL}
          </span>
          ?
        </>
      );

      setMemberToDelete(id);
      setShowDeleteConfirmation(true);
      disableScroll();
    }
  };

  const confirmDelete = () => {
    trackEvent("My Team Page", "click", `Confirm Delete`);

    if (memberToDelete !== null) {
      leadStatusupadte(memberToDelete, "Deleted");
      setTeamMembers((prevState) =>
        prevState.filter((member) => member.TBL_LOGIN_ID !== memberToDelete)
      );

      setErrorMessage("");
      setToggleErrorMessage("");
      setToggleSuccessMessage("");
      toast.success("Member Deleted Successfully");
      setSuccessType(null);
      setMemberToDelete(null);
      enableScroll();
    }
    setShowDeleteConfirmation(false);
  };

  function leadStatusupadte(id, status) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/user.php?fun=updateStatus`,
      headers: {},
      data: {
        id: id,
        status: status,
        auth_id: authId, // This is the body part
      },
    }).then(function (response) {
      //setTeamMembers(response.data.data.datalisting);
    });
  }

  const handleCancel = () => {
    setShowDeleteConfirmation(false);
    enableScroll();
  };

  const handleResendEmail = (member) => {
    trackEvent("My Team", "click", "Resend Invitation");

    axios({
      method: "post",
      url: `${user.base_url}/subuser/mailer_template.php?fun=resendInvitaionMail`,
      headers: {},
      data: {
        auth_id: authId,
        role: member.FK_ROLEID,
        email: member.TBL_LOGIN_EMAIL,
      },
    })
      .then((response) => {
        toast.success("Invitation email sent successfully");
      })
      .catch((error) => {
        toast.error("Failed to send.Please try after sometime");
      });
  };

  const openPopupHandler = () => {
    trackEvent("My Team ", "click", "Add New Member");
    if (paidplan) {
      setSuccessType(null);
      setSuccessMessage(false);
      setToggleErrorMessage(false);
      setShowDeleteSuccessMessage(false);
      setToggleSuccessMessage(false);
      // setErrorType("error");
      // setErrorMessage("Please upgrade your plan to add new member");
      toast.error("Please upgrade your plan to add new member");
      return false;
    }

    setShowPopup(true);
    disableScroll();
  };

  const statusOptions = [
    { ID: "", ROLE_NAME: "All" },
    { ID: "1", ROLE_NAME: "Active" },
    { ID: "2", ROLE_NAME: "Deactivated" },
    { ID: "3", ROLE_NAME: "Invited" },
  ];
  // const roleOptions = ["Admin", "Editor", "Sales Agent"];

  const handleStatusChange = (status, id) => {
    trackEvent("My Team Page", "click", `Status #${status}`);
    setSelectedStatus(status);
    setSelectedStatusId(id);
    setIsFilter(true);
    getUsers(id, selectedRoleId);
  };

  const handleRoleChange = (role, id) => {
    trackEvent("My Team Page", "click", `Role #${role}`);
    setSelectedRole(role);
    setSelectedRoleId(id);
    setIsFilter(true);
    getUsers(selectedStatusId, id);
  };

  const handleClearFilter = () => {
    setSelectedRole("Role");
    setSelectedRoleId("");
    setSelectedStatus("Status");
    setSelectedStatusId("");
    setIsFilter(false);
    getUsers("", "");
  };

  return (
    <>
      {successType && (
        <SucessMessage
          successType={successType}
          message={successMessage}
          msgColor="sucess"
          onClose={() => setSuccessType(null)}
        />
      )}
      {errorType && (
        <SucessMessage
          errorType={errorType}
          message={errorMessage}
          msgColor="error"
          onClose={() => setErrorType(null)}
        />
      )}
      {alertType && (
        <SucessMessage
          alertType={alertType}
          message={alertMessage}
          msgColor="error"
          onClose={() => setAlertType(null)}
        />
      )}
      {showDeleteSuccessMessage && (
        <SucessMessage
          successType={successType}
          message={successMessage}
          msgColor="error"
          onClose={() => setShowDeleteSuccessMessage(false)}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationDialog
          message={confirmationMessage}
          onConfirm={confirmDelete}
          onCancel={handleCancel}
        />
      )}
      {toggleSuccessMessage && (
        <SucessMessage
          successType="success"
          msgColor="sucess"
          message={toggleSuccessMessage}
          onClose={() => setToggleSuccessMessage("")}
        />
      )}
      {toggleErrorMessage && (
        <SucessMessage
          successType="success"
          msgColor="error"
          message={toggleErrorMessage}
          onClose={() => setToggleErrorMessage("")}
        />
      )}

      {loginStatus === 1 && (
        <div className="main-content-padding">
          <InnerHeader
            heading={"My Team"}
            onClick={openPopupHandler}
            // icon={<BsPlus />}
            iconText={"Add New Member"}
            showButton={true}
            txtSubHeading="Effortlessly set up your team, inviting and efficiently managing members with different roles such as admin, editor, or sales agent."
          />

          <Card className={"bg5"}>
            <div className="teams-filters v-center fww">
              <Dropdown
                label="Status"
                options={statusOptions}
                selectedValue={selectedStatus}
                onValueChange={handleStatusChange}
              />

              <Dropdown
                label="Role"
                options={roleOptions}
                selectedValue={selectedRole}
                onValueChange={handleRoleChange}
              />

              {isFilter && (
                <button
                  className="clear-filter bg19 fs12 pl12 pr12 pt8 pb8 fc9 cp br16 ls1"
                  onClick={handleClearFilter}
                >
                  Clear
                </button>
              )}
            </div>

            {autoLoader && (
              <div className="box-center mb12">
                <SmallLoader className={"mb12"} />
              </div>
            )}
            <div
              className="booked df w100 fdc mt16"
              style={{ overflow: "auto" }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Name / Email</th>
                    <th>Mobile</th>
                    <th>Role</th>
                    <th>Lead Auto Assign</th>
                    <th>Buy lead Access</th>
                    {/* <th>Status</th> */}
                    {/* <th>Last Login</th> */}
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {loginStatus === 1 && teamMembers.length > 0 && (
                  <tbody>
                    {teamMembers.map((member) => (
                      <tr key={member.TBL_LOGIN_ID}>
                        <td>
                          <span
                            style={{
                              display: "block",
                              fontWeight: 700,
                              fontSize: "14px",
                            }}
                          >
                            {member.TBL_LOGIN_CONTACTPERSON}
                          </span>
                          {isCheckLoginType.access_cid_by<=0 && <span style={{ fontSize: "12px" }}>
                            {member.TBL_LOGIN_EMAIL}
                          </span>}
                        </td>
                        <td>{member.TBL_LOGIN_MOBILE}</td>
                        <td className={`${member.USER_ROLE ? "fc1" : "fc14"}`}>
                          {member.USER_ROLE ? member.USER_ROLE : "NA"}{" "}
                        </td>

                        <td>
                          <label
                            className={`custom-checkbox ${
                              member.USER_ROLE !== "Sales" ? "disabled" : ""
                            }`}
                          >
                            <input
                              type="checkbox"
                              checked={member.leadAutoAssign}
                              onChange={(e) =>
                                toggleLeadAutoAssign(
                                  e,
                                  member.TBL_LOGIN_ID,
                                  member.USER_ROLE,
                                  member.AUTO_ASSIGN
                                )
                              }
                              disabled={member.USER_ROLE !== "Sales"}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </td>
                        <td>
                          <label
                            className={`custom-checkbox ${
                              member.USER_ROLE !== "Sales" ? "disabled" : ""
                            }`}
                          >
                            <input
                              type="checkbox"
                              checked={member.buyLeadAccess}
                              onChange={(e) =>
                                toggleBuyLeadAccess(
                                  e,
                                  member.TBL_LOGIN_ID,
                                  member.USER_ROLE,
                                  member.allow_subuser
                                )
                              }
                              disabled={member.USER_ROLE !== "Sales"}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </td>

                        {/* <td className="status-cell">
                    <span className="status-badge"></span>
                    {member.TBL_LOGIN_STATUS}
                  </td> */}

                        {/* <td>
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#76838f",
                        display: "block",
                      }}
                    >

                      {member.DATE}

                    </span>
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        opacity: "0.9",
                        color: "#748391",
                      }}
                    >
                       {member.TIME}
                     
                    </span>
                  </td> */}

                        <td className="fc5 fw6 ls1">
                          {member.TBL_LOGIN_STATUS === "Invited" ||
                          member.TBL_LOGIN_STATUS === "Decline" ? (
                            member.TBL_LOGIN_STATUS
                          ) : (
                            <div>
                              <span
                                style={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  color: "#76838f",
                                  display: "block",
                                }}
                              >
                                {member.DATE}
                              </span>
                              <span
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  opacity: "0.9",
                                  color: "#748391",
                                }}
                              >
                                {member.TIME}
                              </span>
                            </div>
                          )}
                        </td>

                        <td className="df mt2 box-center">
                          <Tooltip
                            title={
                              member.isToggled
                                ? "Active Member"
                                : "Deactivate Member"
                            }
                          >
                            <label>
                              <input
                                type="checkbox"
                                checked={member.isToggled ? 1 : 0}
                                onChange={() =>
                                  toggleSwitch(member.TBL_LOGIN_ID)
                                }
                                disabled={isCheckLoginType.access_cid_by>0?true:false}
                                style={{
                                  display: "none",
                                }}
                              />

                              <span
                                className={`custom-toggle ${
                                  member.isToggled ? "toggle-on" : "toggle-off"
                                }`}
                              >
                                {member.isToggled ? (
                                  <BsToggleOn />
                                ) : (
                                  <BsToggleOff />
                                )}
                              </span>
                            </label>
                          </Tooltip>
                          <div className="action-icons" >
                            <Tooltip title="Edit">
                              <FaPencilAlt
                              
                                className={`${isCheckLoginType.access_cid_by>0?"disabled-input":"edit-icon cp"} icon fc5`}
                                options={roleOptions}
                                style={{
                                  verticalAlign: "middle",
                                 
                                }}
                                onClick={() => {if(isCheckLoginType.access_cid_by<=0){handleEditTeamMember(member)}}}
                              />
                            </Tooltip>
                            <Tooltip title="Delete">
                              <BsTrash
                              
                                className={`${isCheckLoginType.access_cid_by>0?"disabled-input":"delete-icon cp"} icon`}
                                style={{ verticalAlign: "middle" }}
                                onClick={() =>{if(isCheckLoginType.access_cid_by<=0){
                                  handleDeleteTeamMember(member.TBL_LOGIN_ID)
                                }}}
                              />
                            </Tooltip>
                            <Tooltip title="Resend Email">
                              {(member.TBL_LOGIN_STATUS === "Invited" ||
                                member.TBL_LOGIN_STATUS === "Decline") && (
                                <MdEmail
                                  className="icon mail-icon fc5"
                                  style={{ verticalAlign: "middle" }}
                                  onClick={() => handleResendEmail(member)}
                                />
                              )}
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </Card>
          {showPopup && (
            <AddTeamMemberForm
              onClose={() => {
                setShowPopup(false);
                setEditedMember(null);
                enableScroll();
              }}
              onSubmit={handleAddTeamMember}
              show={showPopup}
              editedMember={editedMember}
              setSuccessType={setSuccessType}
              setErrorType={setErrorType}
              setAlertType={setAlertType}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              setAlertMessage={setAlertMessage}
              roleOptions={roleOptions}
              setErrorStatus={setErrorStatus}
              setFormErrors={setFormErrors}
              errorStatus={errorStatus}
              formErrors={formErrors}
            />
          )}
        </div>
      )}
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default Team;
