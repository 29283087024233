// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-label-buy-lead {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    flex: 0 0 50%;
}
.custom-checkbox-buy-lead {
    position: relative;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #808080;
    background: #FFF;
    margin-right: 10px;
    cursor: pointer;
}
.custom-checkbox-buy-lead::before {
    content: '\\2713';
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    color: #fff;
    opacity: 0;
    background-color: #F59F31;
    display: flex;
    align-items: center;
    border: 1px solid #F59F31;
    justify-content: center;
}
.custom-checkbox-buy-lead:checked::before {
    opacity: 1;
}
.custom-checkbox-buy-lead:checked .checkbox-label-buy-lead {
    color: #F59F31;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/FilterCheckBoxOption.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,gCAAgC;IAChC,WAAW;IACX,UAAU;IACV,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,uBAAuB;AAC3B;AACA;IACI,UAAU;AACd;AACA;IACI,cAAc;AAClB","sourcesContent":[".checkbox-label-buy-lead {\r\n    display: flex;\r\n    align-items: center;\r\n    margin-bottom: 8px;\r\n    flex: 0 0 50%;\r\n}\r\n.custom-checkbox-buy-lead {\r\n    position: relative;\r\n    appearance: none;\r\n    width: 18px;\r\n    height: 18px;\r\n    border: 1px solid #808080;\r\n    background: #FFF;\r\n    margin-right: 10px;\r\n    cursor: pointer;\r\n}\r\n.custom-checkbox-buy-lead::before {\r\n    content: '\\2713';\r\n    font-size: 14px;\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    width: 100%;\r\n    transform: translate(-50%, -50%);\r\n    color: #fff;\r\n    opacity: 0;\r\n    background-color: #F59F31;\r\n    display: flex;\r\n    align-items: center;\r\n    border: 1px solid #F59F31;\r\n    justify-content: center;\r\n}\r\n.custom-checkbox-buy-lead:checked::before {\r\n    opacity: 1;\r\n}\r\n.custom-checkbox-buy-lead:checked .checkbox-label-buy-lead {\r\n    color: #F59F31;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
