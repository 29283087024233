import React from "react";
import privateImg from "../../assets/private.png";
import publicImg from "../../assets/public.png";
import groupImg from "../../assets/group.png";
import plane from "../../assets/plane.png";
import train from "../../assets/train.png";
import bus from "../../assets/bus.png";
import noTransfer from "../../assets/notransfer.png";
import sedan from "../../assets/sedan.png";
import tempo from "../../assets/tempo.png";
import muv from "../../assets/sedan.png";
import metro from "../../assets/metro.png";
import hatchpack from "../../assets/hatchpack.png";
import suv from "../../assets/suv.png";
import boat from "../../assets/boat.png";
import { MdFlight } from "react-icons/md";
import "./TransferCard.css";
import { FaTrain, FaExchangeAlt } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";

const TransferCard = ({
  transferData,
  onTransferDeleteClick,
  ontransferChange,
  onTranferChangeClick,
}) => {
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <>
      {transferData.map((transferItem, index) => (
        <div
          key={index}
          className="brd1 pt16 pb16 pl8 df mb16 bg5 df fdc pr8 trans-head"
        >
          {transferItem.type === "Flight" || transferItem.type === "Train" ? (
            // First design
            <div>
              <div className="fs14 fc14 ls2 df jcsb">
                <div>{`${transferItem.departure_city} to ${transferItem.arrival_city}`}</div>
                <div className="df">
                  <div className="mr8">{`${transferItem.name} - ${transferItem.vehicle_number}`}</div>
                  <div className="df">
                    <p
                      className="fs14 fw5 cp ls1 pr8"
                      onClick={() => {
                        onTranferChangeClick();
                        ontransferChange(transferItem);
                      }}
                    >
                      <FaExchangeAlt />
                    </p>
                    <p
                      className="fs14 fw5 cp ls1"
                      onClick={() => {
                        onTransferDeleteClick(transferItem.id);
                      }}
                    >
                      <BsTrash />
                    </p>
                  </div>
                </div>
              </div>
              <div className="transfer-image pt16 v-center custom-transfer">
                <div className="image-contain mr24">
                  <img
                    src={
                      transferItem.type === "Flight"
                        ? plane
                        : transferItem.type === "Train"
                        ? train
                        : publicImg
                    }
                    alt={transferItem.type}
                  />
                </div>
                <div className="cust-trans-detail v-center w100">
                  <div className="fs14 ls1 lh22 ">
                    <p className="mb8 fw6 ls2">From</p>
                    <p>{transferItem.departure_city}</p>
                  </div>
                  <div className="mt32 mr16 ml16 flight-line v-center pr">
                    <div className="icon-container">
                      {transferItem.type === "Flight" ? (
                        <MdFlight className="pa rotated-icon fc1" />
                      ) : (
                        <FaTrain className="pa fc1" />
                      )}
                    </div>
                  </div>
                  <div className="fs14 ls1 lh22">
                    <p className="mb8 fw6 ls2">Destination</p>
                    <p>{transferItem.arrival_city}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // Second design
            <div>
              <div className="fs14 fc14 ls2 df jcsb">
                <div>
                  {transferItem.name !== "No Transfer" &&
                    `${capitalizeFirstLetter(
                      transferItem.transfer_mode
                    )} transfer`}
                </div>
                <div className="df">
                  <p
                    className="fs14 fw5 cp ls1 pr8"
                    onClick={() => {
                      onTranferChangeClick();
                      ontransferChange(transferItem);
                    }}
                  >
                    <FaExchangeAlt />
                  </p>
                  <p
                    className="fs14 fw5 cp ls1 pr8"
                    onClick={() => {
                      onTransferDeleteClick(transferItem.id);
                    }}
                  >
                    <BsTrash />
                  </p>
                </div>
              </div>
              <div className="df transfer-image pt16 ">
                <div className="image-contain">
                  <img
                    src={
                      transferItem.transfer_mode === "Private"
                        ? privateImg
                        : transferItem.transfer_mode === "Public"
                        ? publicImg
                        : transferItem.name === "Bus"
                        ? bus
                        : transferItem.name === "SUV"
                        ? suv
                        : transferItem.name === "Sedan"
                        ? sedan
                        : transferItem.name === "Hatchback"
                        ? hatchpack
                        : transferItem.name === "Tempo Traveller"
                        ? tempo
                        : transferItem.name === "No Transfer"
                        ? noTransfer
                        : transferItem.name === "Metro/City Bus"
                        ? metro
                        : transferItem.name === "MUV"
                        ? muv
                        : transferItem.name === "Ship/Boat"
                        ? boat
                        : suv
                    }
                    alt={"Mode of Transfer"}
                  />
                </div>
                <div className="fs14 ls1 lh22 df jcc transfer-text">
                  <div className="df fdc">
                    <p className="mb8 fw6 ls2">
                      {transferItem.name === "No Transfer"
                        ? "No Transfer"
                        : "Type of transfer"}
                    </p>
                    {transferItem.name === "No Transfer"
                      ? "You've selected 'No Transfer'. Enhance your clients' experience by adding a transfer option for greater convenience."
                      : transferItem.name}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default TransferCard;
