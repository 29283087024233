import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import ImageUpload from "../components/ImageUpload";
import "../pages/Settings.css";
import { TiTick } from "react-icons/ti";
import Popup from "../components/Popup/Popup";
import OtpVerify from "../components/Forms/OtpVerify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import MultiSelect from "../components/MultiSelect";
import { FaAngleDown } from "react-icons/fa";
import SmallLoader from "../components/SmallLoader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import Toggle from "../components/Toggle.js";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTitle } from "../hooks/useTitle";
import trackEvent from "../utils/gaTracker";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useTitle("My Setting -  Travel Agents CRM - Hellotravel");

  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const passwordRef = useRef(null);
  const user = useSelector((state) => state.auth);

  const [authId, setAuthID] = useState(user.auth_id);
  const [baseUrl, setBaseUrl] = useState(user.base_url);
  const [siteUrl, setSiteUrl] = useState(user.site_url);

  const [dataStatus, setDataStatus] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailExist, setEmailExist] = useState("");
  const [phoneVerify, setPhoneVerify] = useState("0");
  const [emailVerify, setEmailVerify] = useState("0");
  const [otpStatusVerify, setOtpStatusVerify] = useState("");
  const [otpVerify, setOtpVerify] = useState("");
  const [phoneNumberExist, setPhoneNumberExist] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [gender, setGender] = useState("");
  //const [workImage, setWorkImage] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [isPhoneVerificationOpen, setIsPhoneVerificationOpen] = useState(false);
  const [loadingPhoneVerification, setLoadingPhoneVerification] =
    useState(false);
  const [showGenderDropdown, setShowGenderDropdown] = useState(false);
  const [countryCodeDropdown, setCountryCodeDropdown] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const [errorStatus, setErrorStatus] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [passwordOnOff, setPasswordOnOff] = useState(false);
  const [initialValue, setInitialValue] = useState(false);
  const [isCheckLoginType, setisCheckLoginType] = useState([]);
  const [uploadImgLocal, setUploadImgLocal] = useState("");
  const bottomRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setCountryCodeDropdown(false);
      }

      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setShowGenderDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  
  useEffect(() => {
    const pathname = location.search;
    if (pathname === `?tab=Settings&section=newpass`) {
      setInitialValue(true);
      setPasswordOnOff(true);
      bottomRef.current.scrollIntoView({ behavior: "smooth" , block: "start"});
    }
  }, [location.search]);

  const passwordHandle = () => {
    if (passwordOnOff === false) {
      trackEvent("Setting Page", "click", `Reset Password ON`);
      setPasswordOnOff(true);
    } else {
      setPasswordOnOff(false);
      trackEvent("Setting Page", "click", `Reset Password OFF`);
    }
  };

  const openPhoneVerification = () => {
    setIsPhoneVerificationOpen(true);
  };

  const closePhoneVerification = () => {
    setIsPhoneVerificationOpen(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSaveChanges = () => {
    setLoadingPhoneVerification(true);
    setTimeout(() => {
      setLoadingPhoneVerification(false);
      openPhoneVerification();
    }, 2000);
  };

  const genderOptions = {
    Male: "Male",
    Female: "Female",
    "Prefer Not To Say": "Prefer Not To Say",
  };

  const countryCodeOptions = user.country_code;

  const preferredLanguages = [
    "Hindi",
    "English",
    "Marathi",
    "Bengali",
    "Punjabi",
    "Gujarati",
    "Assamese",
    "Kannada",
    "Kashmiri",
    "Konkani",
    "Malayalam",
    "Manipuri",
    "Nepali",
    "Oriya",
    "Sanskrit",
    "Sindhi",
    "Tamil",
    "Telugu",
    "Urdu",
    "Bodo",
    "Santhali",
    "Maithili",
    "Dogri",
    "Other",
  ];

  const [prefLang, setPrefLang] = useState([]);

  const mySettingValidate = (newPassword) => {
    const errors = {};
    errors.newPassword = [];
    errors.authenticate = [];
    errors.unauthenticate = [];

    if (newPassword.length === 0) {
      errors.newPassword.push("New Password is empty!");
      setErrorStatus(true);
    }

    if (newPassword.length > 0) {
      const pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
      const isValid = pattern.test(newPassword);

      if (isValid) {
      } else {
        errors.newPassword.push(
          "Allow alphanumeric characters (letters and digits) Require a minimum length of 8 characters"
        );
        setErrorStatus(true);
      }
    }

    const returnError = {};

    if (errors.newPassword.length === 0) {
      returnError.status = false;
      setErrorStatus(false);
    } else {
      returnError.status = true;
    }

    returnError.errors = errors;

    return returnError;
  };

  const handleFormSetting = (key, value) => {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=settingupdate`,
      headers: {},
      data: {
        auth_id: authId,
        key: key,
        value: value,
      },
    }).then(function (response) {
      if (response.data.data.emailstatus === 0) {
        setEmailExist(response.data.data.msg);
      } else {
        setEmailExist("");
      }
      if (response.data.data.mobilestatus === 0) {
        setPhoneNumberExist(response.data.data.msg);
      } else {
        setPhoneVerify("0");
        setPhoneNumberExist("");
      }
    });
  };

  const handleMySettingPop = () => {
    //window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/");
  };

  const handleMySetting = async (e) => {
    trackEvent("Setting Page", "click", `Reset Password - Save`);
    e.preventDefault();
    setFormSubmitted(true);
    const settingData = mySettingValidate(newPassword);

    if (settingData.status === true) {
      setFormErrors(settingData.errors);
      return false;
    } else {
      try {
        const response = await axios.post(
          `${user.base_url}/subuser/myaccount.php?fun=settingupdate`,
          {
            auth_id: authId,
            key: "newPassword",
            value: newPassword,
          }
        );

        if (response.data.data && response.data.data.status === 1) {
          settingData.errors.newPassword.push("Password Successfully Changed");
          setFormErrors(settingData.errors);

          navigate("/");
        } else {
          settingData.errors.newPassword.push("Something Went Wrong");
          setFormErrors(settingData.errors);
          return false;
          // Handle unsuccessful login here (e.g., show an error message)
        }
      } catch (error) {
        settingData.errors.newPassword.push("Something Went Wrong");
        setFormErrors(settingData.errors);
        return false;
      } finally {
      }
    }
  };

  useEffect(() => {
    getSettingData();
  }, []);

  function getSettingData() {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=setting`,
      headers: {},
      data: {
        auth_id: authId,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }
      setisCheckLoginType(response.data.login);
      setName(response.data.data.data.TBL_LOGIN_CONTACTPERSON);
      setEmail(response.data.data.data.TBL_LOGIN_EMAIL);
      if (response.data.data.data.TBL_MOBILE_ORIGINAL !== "") {
        setPhoneNumber(response.data.data.data.TBL_MOBILE_ORIGINAL);
      }
      if (
        response.data.data.data.mobile_verify === "0" &&
        response.data.data.data.TBL_MOBILE_ORIGINAL === ""
      ) {
        setPhoneVerify("2");
      } else {
        setPhoneVerify(response.data.data.data.mobile_verify);
      }
      setEmailVerify(response.data.data.data.email_verify);
      if (response.data.data.data.USER_SIGNATURE_IMAGE) {
        setProfileImg(response.data.data.data.USER_SIGNATURE_IMAGE_FULLPATH);
        // console.log(
        //   "response.data.data.data.USER_SIGNATURE_IMAGE",
        //   `https://www.hlimg.com/images/logo/${response.data.data.data.USER_SIGNATURE_IMAGE}`
        // );
      }

      setCountryCode(response.data.data.data.TBL_COUNTRY_CODE);

      if (response.data.data.data.extrainfo) {
        let extrainfo = JSON.parse(response.data.data.data.extrainfo);
        setGender(extrainfo.gender);
        setPrefLang(extrainfo.lang);
        // setPhoneVerify(extrainfo.mobile_verify);
        // setEmailVerify(extrainfo.email_verify);
      }
      setDataStatus(true);
    });
  }

  const handleLogout = () => {
    // Dispatch the logout action to clear user data
    dispatch(logout());
    // Redirect to the login page
    navigate("/login");
  };

  const setNameFun = (value) => {
    handleFormSetting("name", value);
    setName(value);
  };

  const setEmailFun = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(value)) {
      setEmailExist("Invalid email: " + value);

      return false;
    }

    handleFormSetting("email", value);
  };

  const setPhoneNumberFun = (value) => {
    // let mobileNumber = value;
    // setPhoneNumber(value);

    if (value.length === 0) {
      setPhoneNumberExist("Mobile No Empty ");
    } else {
      setPhoneNumber(value);
      if (countryCode === "91") {
        const mobileRegex = /^[6789]\d{9}$/i;

        if (!mobileRegex.test(value)) {
          setPhoneNumberExist("Invalid Mobile No: " + value);
        } else {
          setPhoneNumberExist("");
          handleFormSetting("phoneNumber", value);
        }
      } else {
        const mobileRegex = /^\d{5,20}$/i;

        if (!mobileRegex.test(value)) {
          setPhoneNumberExist("Invalid Mobile No: " + value);
        } else {
          setPhoneNumberExist("");
          handleFormSetting("phoneNumber", value);
        }
      }
    }
  };

  const handleGenderSelect = (selectedGender, value) => {
    handleFormSetting("gender", value);
    setGender(selectedGender);
    setShowGenderDropdown(false);
  };

  const handleCountryCodeSelect = (selectedCountry) => {
    handleFormSetting("countryCode", selectedCountry);
    setCountryCode(selectedCountry);
    setCountryCodeDropdown(false);
  };

  const handleLangSelect = (items) => {
    setPrefLang(items);
    handleFormSetting("prefLang", items);
  };
  const handleMobileVerification = () => {
    trackEvent("Setting Page", "click", `Mobile Verify`);
    if (countryCode === null) {
      setPhoneNumberExist("Select country code first");
      return false;
    }
    setLoadingPhoneVerification(true);
    setTimeout(() => {
      setLoadingPhoneVerification(false);
      openPhoneVerification();
    }, 2000);

    otpHandler(phoneNumber);
  };

  function otpHandler(mobile) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=OTPSEND`,
      headers: {},
      data: {
        auth_id: authId, // This is the body part
        mobile: mobile,
      },
    }).then(function (response) {});
  }

  const varifyOTPFunHandle = (otp) => {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=OTPAuth`,
      headers: {},
      data: {
        auth_id: authId, // This is the body part
        otp: otp,
      },
    }).then(function (response) {
      setOtpVerify(response.data.data.msg);
      setOtpStatusVerify(response.data.data.status);

      setPhoneVerify(response.data.data.status);
    });
  };

  const handleEmailVerification = () => {
    trackEvent("Setting Page", "click", `Email Verify`);
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=emailVarify`,
      headers: {},
      data: {
        auth_id: authId,
      },
    }).then(function (response) {
      if (response.data.data.status === "1") {
        setEmailVerified(true);
      }
    });
  };

  return (
    <div className="account-details df w66 fdc settings main-content-padding">
      <h3 className="fc1 fw5 fs20 mb24">
        Settings
        <div className="fs14 fc16 mt8 fw4 lh16">
          Personalize your information and secure your account with email &
          mobile verification
        </div>
      </h3>
      {dataStatus === true && (
        <div className="upload-image v-center">
          <p className="flx1 fc14 fw5 fs14 mb12 ls1">Upload Your Photo</p>
          <div className="image-upload-compoenent">
            <ImageUpload
              authId={authId}
              baseUrl={baseUrl}
              imageUrl={profileImg}
              siteUrl={user.img_url}
              folder="agent-signature-image/userimg/"
              uploadUrl="/subuser/myaccount.php?fun=uploadImg&type=profile"
              deleteUrl="/subuser/myaccount.php?fun=deleteImg&type=profile"
              setWorkImage={setProfileImg}
              delimg={true}
              toast={toast}
              eventLabel={"Upload Image"}
              eventCategory={"Settings Page"}
              uploadImgLocal={uploadImgLocal}
              setUploadImgLocal={setUploadImgLocal}
            />
          </div>
        </div>
      )}

      {dataStatus === true && (
        <div className="form-group-settings">
          <p className="fc14 fw5 fs14 ls1">Your Name</p>
          <input
            type="text"
            id="name"
            placeholder="Enter Name"
            defaultValue={name}
            onBlur={(e) => setNameFun(e.target.value)}
          />
          <div className="form-error-messages error mt8 blink-text-normal-error">
            {Object.keys(formErrors).map((key) => (
              <div key={key}>
                {key === "name" &&
                  formErrors[key].map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
              </div>
            ))}
          </div>
        </div>
      )}

      {dataStatus === true && (
        <div className="form-group-settings ">
          <div className="df jcsb aic">
            <p className="fc14 fw5 fs14 ls1">Email Address</p>
            <span
              className={` fc1 fs14 ls1 ${emailVerified ? "verified" : ""}`}
              onClick={() => {
                if (!emailVerified && emailVerify === "0") {
                  handleEmailVerification();
                }
              }}
            >
              <div className="loader-container df aic">
                {emailVerify === "1" ? (
                  <p className="v-center verification-status verified">
                    Verified <TiTick className="fs14" />
                  </p>
                ) : emailVerified ? (
                  <p className={`v-center verification-status verified`}>
                    Sent an email <TiTick className="fs14" />
                  </p>
                ) : (
                  <p className="cp fc1 fs14">Verify</p>
                )}
              </div>
            </span>
          </div>

          {email ? (
            <input
              type="text"
              id="email"
              placeholder="Enter email address"
              value={isCheckLoginType.access_cid_by>0?"******@gmail.com":email}
              // onBlur={(e) =>
              //   setEmailFun(e.target.value)
              // }
              className={`${isCheckLoginType.access_cid_by>0?"blurred-text":""}`}
              disabled={email ? true : false}
            />
          ) : (
            <input
              type="text"
              id="email"
              placeholder="Enter email address"
              value={isCheckLoginType.access_cid_by>0?"******@gmail.com":email}
              onBlur={(e) => setEmailFun(e.target.value)}
              className={`${isCheckLoginType.access_cid_by>0?"blurred-text":""}`}
              // readOnly={emailVerify==='1' ? true : false}
            />
          )}

          <div className="form-error-messages error mt8 blink-text-normal-error">
            {emailExist}
          </div>
        </div>
      )}

      {dataStatus === true && (
        <div className="form-group-settings">
          <div className="df jcsb aic">
            <p className="fc14 fw5 fs14 ls1 fww">
              Phone Number ( With country code )
            </p>

            {countryCode === "91" && phoneVerify === "0" ? (
              <div className="loader-container df aic">
                {loadingPhoneVerification ? (
                  <SmallLoader />
                ) : (
                  <p className="cp fc1 fs14" onClick={handleMobileVerification}>
                    Verify
                  </p>
                )}
              </div>
            ) : (
              <p
                className={` cp fc1 fs14 ${
                  phoneVerify === "1"
                    ? "v-center verification-status verified"
                    : ""
                }`}
              >
                {countryCode === "91"
                  ? phoneVerify === "1"
                    ? "Verified"
                    : phoneVerify === "0"
                    ? "Verify"
                    : ""
                  : ""}
                {countryCode === "91" && phoneVerify === "1" && (
                  <TiTick className="fs14" />
                )}
              </p>
            )}
          </div>
          <div className="input-group df pr w100 fww aisc">
            <div className="input-group-prepend df country-code-set">
              <div className="role-dropdown" ref={dropdownRef}>
                <div
                  className="selected-role fs14 h40 country-code"
                  onClick={() => setCountryCodeDropdown(!countryCodeDropdown)}
                >
                  {countryCodeOptions[countryCode] || "Country Code"}{" "}
                  <FaAngleDown className="fc16 fs14" />
                </div>
                {countryCodeDropdown &&
                  (phoneVerify === "2" || phoneVerify === "0") && (
                    <ul className="role-options fs14">
                      {phoneVerify === "0" &&
                        Object.entries(countryCodeOptions).map(
                          ([key, value]) => (
                            <li
                              key={key}
                              value={value}
                              onClick={() =>
                                handleCountryCodeSelect(key, value)
                              }
                            >
                              {key}
                            </li>
                          )
                        )}
                    </ul>
                  )}
              </div>
            </div>

            <input
              type="text"
              className="form-control br4"
              maxLength={15}
              placeholder="Enter phone number"
              id="phoneNumber"
              name="phoneNumber"
              defaultValue={phoneNumber}
              onBlur={(e) => setPhoneNumberFun(e.target.value)}
              disabled={
                phoneVerify === "0" || phoneVerify === "2" ? false : true
              }
            />
          </div>
          <div className="form-error-messages error mt8 blink-text-normal-error">
            {phoneNumberExist}
          </div>
        </div>
      )}

      {dataStatus === true && (
        <div className="form-group-settings">
          <label htmlFor="gender" className="fc14 fw5 fs14 mb12 ls1">
            Gender
          </label>
          <div className="role-dropdown" ref={dropdownRef1}>
            <div
              className="selected-role fs14"
              value={gender}
              onClick={() => setShowGenderDropdown(!showGenderDropdown)}
            >
              {gender || "Select Gender"} <FaAngleDown className="fc16 fs14" />
            </div>
            {showGenderDropdown && (
              <ul className="role-options fs14">
                {/* {genderOptions.map((option) => (
                <li key={option} onClick={() => handleGenderSelect(option)}>
                  {option}
                </li>
              ))} */}

                {Object.entries(genderOptions).map(([key, value]) => (
                  <li
                    key={key}
                    value={value}
                    onClick={() => handleGenderSelect(key, value)}
                  >
                    {key}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="form-error-messages error mt8 blink-text-normal-error">
            {Object.keys(formErrors).map((key) => (
              <div key={key}>
                {key === "gender" &&
                  formErrors[key].map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
              </div>
            ))}
          </div>
        </div>
      )}

      {dataStatus === true && (
        <div className="form-group-settings mutli-select">
          <p className="fc14 fw5 fs14 mb12 ls1">Preferred Languages</p>

          <MultiSelect
            travelDestination={preferredLanguages}
            placeholder={"Preferred Languages"}
            onSave={handleLangSelect}
            selectedItems={prefLang} // Pass selected languages as a prop
            setSelectedItems={setPrefLang}
          />
        </div>
      )}

      <div className="reset-password mt24">
        <div className="reset-pass-head mt24 v-center">
          <p className=" fc1 fs18 ls1 lh22 mr20 ">Reset Your Password</p>

          {dataStatus && (
            <Toggle initialValue={initialValue} onChange={passwordHandle} />
          )}
          <p className="ls1 fc16 fs14 mt4 mb24 lh22">
            {/* A One-Time Password has been sent to 9818250039 */}
          </p>
        </div>
        <div className="fs14 fc16 mt10 fw4">
          Secure your account with a new password
        </div>
        {passwordOnOff && (
          <div className="form-group-settings">
            <p className="fc14 fw5 fs14 ls1 mt16">New Password</p>
            <div className="password-input pr">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Reset your password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                disabled={isCheckLoginType.access_cid_by>0?true:false}
              />
              <span className="mt10" onClick={togglePasswordVisibility}>
                {!showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
              <button
                type="button"
                className={`${isCheckLoginType.access_cid_by>0?"disabled-input":"cp"} btn-blue bg1 br24 fs14 pl16 pr16 pt10 pb10 mt12 jce df`}
                disabled={isCheckLoginType.access_cid_by>0?true:false}
                onClick={handleMySetting}
              >
                Save Password
              </button>
            </div>

            <div className="form-error-messages error mt8 blink-text-normal-error">
              {Object.keys(formErrors).map((key) => (
                <div key={key}>
                  {key === "newPassword" &&
                    formErrors[key].map((error, index) => (
                      <p key={index}>{error}</p>
                    ))}
                </div>
              ))}
            </div>
          </div>
        )}
        <div ref={bottomRef}></div>
        <div className="form-error-messages error mt8 blink-text-normal-success">
          {Object.keys(formErrors).map((key) => (
            <div key={key}>
              {key === "authenticate" &&
                formErrors[key].map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
            </div>
          ))}
        </div>

        <div className="form-error-messages error mt8 blink-text-normal-error">
          {Object.keys(formErrors).map((key) => (
            <div key={key}>
              {key === "unauthenticate" &&
                formErrors[key].map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
            </div>
          ))}
        </div>
        {/* <div className="button-container born">
          {!passwordOnOff && (
            <button
              type="button"
              className="btn-blue bg1 br24 fs14 cp pl16 pr16 pt10 pb10"
              onClick={handleMySettingPop}
            >
              Save Changes
            </button>
          )}
        </div> */}
        <div ref={passwordRef}></div>
      </div>
      <div>
        <ToastContainer position="bottom-right" />
      </div>
      {isPhoneVerificationOpen && (
        <Popup
          onClose={closePhoneVerification}
          title={"Mobile Phone Verification"}
        >
          <OtpVerify
            onClose={closePhoneVerification}
            varifyOTPFun={varifyOTPFunHandle}
            otpVerifyMsgStatus={otpVerify}
            otpStatusVerify={otpStatusVerify}
            phoneNumber={phoneNumber}
          />
        </Popup>
      )}
    </div>
  );
};

export default Settings;
