// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rowDays,
.business-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-25 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-42 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}
.col-58 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}
.col-75 {
  flex: 0 0 75%;
  max-width: 75%;
}
.digilocker-btn {
  align-self: flex-end;
  padding: 13px 16px;
}

@media screen and (max-width: 480px) {
  .address-proof .form-group-settings,
  .upload-certificates .form-group-settings {
    flex-direction: column;
  }
  .reg-proof {
    margin-bottom: 12px;
  }
  .address-proof .flx50,
  .upload-certificates .flx50 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bank-details,
  .upload-certificates {
    padding-top: 8px;
  }
  .address-proof.mt48 {
    margin-top: 24px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .w66 {
    width: 100%;
  }
  .address-proof .flx50,
  .upload-certificates .flx50 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .reg-proof {
    margin-bottom: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Kyc.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,cAAc;AAChB;AACA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE;;IAEE,sBAAsB;EACxB;EACA;IACE,mBAAmB;EACrB;EACA;;IAEE,cAAc;IACd,eAAe;EACjB;EACA;;IAEE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,WAAW;EACb;EACA;;IAEE,cAAc;IACd,eAAe;EACjB;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".rowDays,\r\n.business-row {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  margin-right: -15px;\r\n  margin-left: -15px;\r\n}\r\n.col-25 {\r\n  flex: 0 0 25%;\r\n  max-width: 25%;\r\n}\r\n.col-42 {\r\n  flex: 0 0 41.66667%;\r\n  max-width: 41.66667%;\r\n}\r\n.col-58 {\r\n  flex: 0 0 58.33333%;\r\n  max-width: 58.33333%;\r\n}\r\n.col-75 {\r\n  flex: 0 0 75%;\r\n  max-width: 75%;\r\n}\r\n.digilocker-btn {\r\n  align-self: flex-end;\r\n  padding: 13px 16px;\r\n}\r\n\r\n@media screen and (max-width: 480px) {\r\n  .address-proof .form-group-settings,\r\n  .upload-certificates .form-group-settings {\r\n    flex-direction: column;\r\n  }\r\n  .reg-proof {\r\n    margin-bottom: 12px;\r\n  }\r\n  .address-proof .flx50,\r\n  .upload-certificates .flx50 {\r\n    flex: 0 0 100%;\r\n    max-width: 100%;\r\n  }\r\n  .bank-details,\r\n  .upload-certificates {\r\n    padding-top: 8px;\r\n  }\r\n  .address-proof.mt48 {\r\n    margin-top: 24px;\r\n  }\r\n}\r\n\r\n@media only screen and (min-width: 481px) and (max-width: 768px) {\r\n  .w66 {\r\n    width: 100%;\r\n  }\r\n  .address-proof .flx50,\r\n  .upload-certificates .flx50 {\r\n    flex: 0 0 100%;\r\n    max-width: 100%;\r\n  }\r\n  .reg-proof {\r\n    margin-bottom: 12px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
