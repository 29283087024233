import React, { useState } from "react";
import "../components/Box.css";
import AppBarButton from "./AppBarButton";
import trackEvent from "../utils/gaTracker";

const Box = ({
  plan,
  currentPlanName,
  className,
  rmNumber,
  onViewAllClick,
  handleSendMail,
}) => {
  const isUpgradeDisabled =
    currentPlanName === plan.find((item) => item.key === "Name")?.value;
  const [buttonText, setButtonText] = useState(
    isUpgradeDisabled ? "Upgrade" : "Contact Sales"
  );

  const handleContactSalesClick = () => {
    trackEvent("My Plan Page", "click", `Contact Sales - ${plan[0].value}`);
    if (!isUpgradeDisabled) {
      // Toggle between "Contact Sales" and RM Number
      setButtonText((prevText) =>
        prevText === "Contact Sales" ? rmNumber : "Contact Sales"
      );
    }

    handleSendMail();
  };

  return (
    <div className={`boxbd p20 ${className}`}>
      <div className="v-center">
        <p className="fs16 fw5 fc15">
          {plan.find((item) => item.key === "Name")?.value}
          {currentPlanName ===
          plan.find((item) => item.key === "Name")?.value ? (
            <span className="fw5 fc1 pl8">(active)</span>
          ) : (
            ""
          )}
        </p>
      </div>

      <div className="plan mt16">
        <ul className="pl16 list-unstyled">
          {plan.map(
            (item, index) =>
              item.status === "1" && (
                <li className="mb8 fs14 fc16 lh18 ls1" key={index}>
                  {item.key}: {item.value}
                </li>
              )
          )}
        </ul>
      </div>

      <div id="price" className="mt16 fw6 fc17">
        <span className="real-price" style={{ textDecoration: "line-through" }}>
          ₹{plan.find((item) => item.key === "Price")?.value}
        </span>
        <span className="offer-price">
          {" "}
          ₹{plan.find((item) => item.key === "Inaugural offer")?.value}
        </span>
      </div>
      <div className="df jcsb mt8 ls3 aic">
        <p
          className="fs12 cp tdu fc1"
          onClick={() => onViewAllClick(plan)}
        >
          View All
        </p>
        <AppBarButton
          content={buttonText}
          color="#594cda"
          onClick={handleContactSalesClick}
          disabled={isUpgradeDisabled}
          fontColor="#fff"
        />
      </div>
    </div>
  );
};

export default Box;
