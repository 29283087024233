// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
th,
td {
  padding: 12px 8px;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
}

tr.even {
  background-color: #f9f9f9;
}
`, "",{"version":3,"sources":["webpack://./src/components/ReportTable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;;EAEE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".table-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  margin-top: 20px;\r\n}\r\nth,\r\ntd {\r\n  padding: 12px 8px;\r\n  text-align: center;\r\n  font-size: 14px;\r\n  white-space: nowrap;\r\n}\r\n\r\ntr.even {\r\n  background-color: #f9f9f9;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
