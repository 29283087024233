// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transfer .left-image {
  min-width: 150px;
  max-width: 150px;
}
.popup-card-wrapper.transfer .left-image img {
  min-width: 150px;
  max-width: 150px;
  min-height: 150px;
  max-height: 150px;
}
.option-card.selected {
  background: #e0e0e0;
}
.option-card {
  background: #f9f9f9;
}

.flight-form p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.flight-form label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

.flight-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px;
}
.flight-form input:focus {
  outline: none;
}
.flight-form button {
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
}
.transfer-icon-list {
  width: 56px;
  height: 56px;
}

.transfer-icon-list img {
  width: 56px;
  height: 56px;
}
.dep-time .react-datepicker__input-container,
.arrival-time .react-datepicker__input-container {
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Popup/PackageTransferPopup.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,WAAW;EACX,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;AACA;;EAEE,sBAAsB;AACxB","sourcesContent":[".transfer .left-image {\r\n  min-width: 150px;\r\n  max-width: 150px;\r\n}\r\n.popup-card-wrapper.transfer .left-image img {\r\n  min-width: 150px;\r\n  max-width: 150px;\r\n  min-height: 150px;\r\n  max-height: 150px;\r\n}\r\n.option-card.selected {\r\n  background: #e0e0e0;\r\n}\r\n.option-card {\r\n  background: #f9f9f9;\r\n}\r\n\r\n.flight-form p {\r\n  font-size: 18px;\r\n  font-weight: bold;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.flight-form label {\r\n  display: block;\r\n  margin-bottom: 5px;\r\n  font-size: 14px;\r\n}\r\n\r\n.flight-form input {\r\n  width: 100%;\r\n  padding: 8px;\r\n  margin-bottom: 10px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 4px;\r\n  height: 40px;\r\n}\r\n.flight-form input:focus {\r\n  outline: none;\r\n}\r\n.flight-form button {\r\n  color: #fff;\r\n  padding: 10px;\r\n  border: none;\r\n  border-radius: 4px;\r\n  cursor: pointer;\r\n  font-size: 16px;\r\n  margin-right: 10px;\r\n}\r\n.transfer-icon-list {\r\n  width: 56px;\r\n  height: 56px;\r\n}\r\n\r\n.transfer-icon-list img {\r\n  width: 56px;\r\n  height: 56px;\r\n}\r\n.dep-time .react-datepicker__input-container,\r\n.arrival-time .react-datepicker__input-container {\r\n  width: 100% !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
