import React from "react";
import { FaExchangeAlt } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";

const CityCard = ({
  cityData,
  onCityChangeClick,
  onCityChange,
  onCityDeleteClick,
}) => {
  return (
    <>
      {cityData.map((city) => (
        <div className="brd1 pt16 pb16 pl8 pr4 df  mb24 city-card-container select-itinenary-card" key={city.id}>
          <div className="left-image w33">
            <div className="image-contain pr">
              <img src={city.city_image} alt={city.city_name} />
            </div>
          </div>
          <div className="pl16 w100 city-rightcard-text">
            <div className="v-center jcsb">
              <p className="fc1 fs16 fw6 ls1  captw">{city.city_name}</p>
              <div className="df">
                <p
                  className="fs16 fw5 cp ls1 pr8"
                  onClick={() => {
                    onCityChangeClick();
                    onCityChange(city);
                  }}
                >
                  <FaExchangeAlt />
                </p>
                <p
                  className="fs16 fw5 cp ls1 pr8"
                  onClick={() => {
                    onCityDeleteClick(city.id);
                  }}
                >
                  <BsTrash />
                </p>
              </div>
            </div>
            <div className="fs14 fw5 ls1 lh18 fc16 mt4  captw">{city.city_state}</div>
	      <div className="fs14 fc5 ls1 captw">
              {city.city_name !== "" ? city.city_name + "" : ""}
              {city.country_name ? ", " + city.country_name + "" : ""}
            </div>
	      {city.description && <div className="fs14 pt24 fc5 ls1 lh22">
		      {city.description}...
            </div>}
          </div>
        </div>
      ))}
    </>
  );
};

export default CityCard;
