// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component-container {
    margin: 12px 4px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 100%;
}
.status.sold-out {
    background-color: #f30909;
    color: #fff;
}

.image-container {
    min-width: 35%;
    max-width: 35%;
    padding: 10px 8px;
    padding-bottom: 0;
}

.image-container img {
    max-width: 100%;
    max-height: 124px;
    min-height: 124px;
}

.status {
    top: 4px;
    left: 4px;
    padding: 4px;
}

.info-container {
    width: 100%;
    padding: 10px;
    padding-bottom: 0;
}


.preview-select-button {
    padding: 6px 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PackageInformation.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,wCAAwC;IACxC,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,cAAc;IACd,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,QAAQ;IACR,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;AACrB;;;AAGA;IACI,gBAAgB;AACpB","sourcesContent":[".component-container {\r\n    margin: 12px 4px;\r\n    border: 1px solid #ccc;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n    max-width: 100%;\r\n}\r\n.status.sold-out {\r\n    background-color: #f30909;\r\n    color: #fff;\r\n}\r\n\r\n.image-container {\r\n    min-width: 35%;\r\n    max-width: 35%;\r\n    padding: 10px 8px;\r\n    padding-bottom: 0;\r\n}\r\n\r\n.image-container img {\r\n    max-width: 100%;\r\n    max-height: 124px;\r\n    min-height: 124px;\r\n}\r\n\r\n.status {\r\n    top: 4px;\r\n    left: 4px;\r\n    padding: 4px;\r\n}\r\n\r\n.info-container {\r\n    width: 100%;\r\n    padding: 10px;\r\n    padding-bottom: 0;\r\n}\r\n\r\n\r\n.preview-select-button {\r\n    padding: 6px 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
