import React, { useRef, useEffect } from "react";
import "../Popup/PlanPopup.css";
const PlanPopup = ({ show, children, onClose,className }) => {
  const formRef = useRef(null);

  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target) && show) {
      onClose();
      enableScroll();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (show) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [show]);
  return (
    <div className="lead-popup-overlay">
      <div
        className={`lead-popup-content pr ${show ? "slide-in-popup " : "slide-out-popup"} ${className}`}
        ref={formRef}
      >
        <div className="modal-body pr">{children}</div>
      </div>
    </div>
  );
};
export default PlanPopup;
