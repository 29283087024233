import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "./LeadDetailsPopup.css";
import SearchListing from "../PackageBuilder/SearchListing";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BiCycling } from "react-icons/bi";
import ImageUpload from "../ImageUpload";

const PackageActivityPopup = ({
  show,
  onClose,
  activityResponse,
  onActivitySelect,
  toast,
  onSearch,
}) => {
  const user = useSelector((state) => state.auth);
  const [authId, setAuthID] = useState(user.auth_id);
  const [baseUrl, setBaseUrl] = useState(user.base_url);
  const [siteUrl, setSiteUrl] = useState(user.site_url);
  const [isAddActivityFormVisible, setAddActivityFormVisible] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [uploadImgLocal, setUploadImgLocal] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [activityDetails, setActivityDetails] = useState({
    activity_name: "",
    activity_city: "",
    description: "",
  });
  const popupRef = useRef(null);

  useEffect(() => {
    if (show) {
      disableScroll();
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      enableScroll();
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);
  useEffect(() => {
    setActivityDetails((prevDetails) => ({
        ...prevDetails,
        activity_image: imgFile,activity_store:'manual',
    }));
}, [imgFile]);

  const handleClickOutside = (event) => {
    if (event.target.closest(".lead-popup-content") === null) {
      onClose();
      setAddActivityFormVisible(false);
    }
  };
  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };
  if (!show) return null;
  const handleItineraryActivitySelect = (activity) => {
    onActivitySelect(activity);
  };
  const handleAddActivityClick = () => {
    setAddActivityFormVisible(true);
  };
  const handleAddActivitySubmit = () => {
    if (!activityDetails.activity_name) {
      toast.error("Enter activity name.");
      return false;
    }
    if (!activityDetails.activity_city) {
      toast.error("Enter activity city.");
      return false;
    }
    onActivitySelect(activityDetails);
    setAddActivityFormVisible(false);
  };
  const handleActivityChange = (field, value) => {
    setActivityDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };
  const handleActivitySearch = (searchQuery) => {
    onSearch(searchQuery, "activity");
    // console.log("Search query listing:", searchQuery);
  };
  return (
    <div className="lead-popup-overlay">
      <div
        className={`lead-popup-content p12 pr ${show ? "slide-in" : ""}`}
        ref={popupRef}
      >
        <div className="v-center jcsb">
          {isAddActivityFormVisible ? (
            <button
              onClick={() => setAddActivityFormVisible(false)}
              className="transfer-back bg5 cp fc1 v-center"
            >
              <IoMdArrowRoundBack className="mr8" />
              Back to Activity
            </button>
          ) : (
            <p>Select Activity</p>
          )}
          {/* <p
              className={`fs16 ${isAddActivityFormVisible ? "cp" : ""}`}
              onClick={
                isAddActivityFormVisible
                  ? () => setAddActivityFormVisible(false)
                  : null
              }
            >
            
              {isAddActivityFormVisible ? "Back to Activity" : "Select Activity"}
            </p> */}
          <button
            onClick={() => {
              onClose();
              setAddActivityFormVisible(false);
            }}
            className="fs18 fc1 bg5 cp"
          >
            X
          </button>
        </div>
        {/* Add activity Form */}
        {isAddActivityFormVisible ? (
          <div className="flight-form mt12">
            <p className="box-center">Add Activity</p>
            <div className="box-center fs14 fw4 mb24 c2 lh14">
              Enter activity information to create a engaging travel experience
              for your clients.
            </div>
            <div className="df activity-form-cont">
              <div className="flx48 req-star actv-name">
                <label>
                  Activity Name<span>*</span>
                </label>
                <input
                  type="text"
                  name="activity_name"
                  value={activityDetails.activity_name}
                  onChange={(e) =>
                    handleActivityChange("activity_name", e.target.value)
                  }
                  className="form-input"
                />
              </div>
              <div className="flx48 ml24 req-star actv-city">
                <label>
                  Activity City<span>*</span>
                </label>
                <input
                  type="text"
                  name="activity_city"
                  value={activityDetails.activity_city}
                  onChange={(e) =>
                    handleActivityChange("activity_city", e.target.value)
                  }
                  className="form-input"
                />
              </div>
            </div>

            <div className=" activity-form-cont mt8">
              <div className=" req-star actv-descp">
                <label>About Activity</label>
                <textarea
                  type="text"
                  name="activity_about"
                  value={activityDetails.description}
                  onChange={(e) =>
                    handleActivityChange("description", e.target.value)
                  }
                  className="form-input"
                />
              </div>
            </div>
            <div className="req-star actv-img-upload mt16">
              <label>Activity Image</label>
              <ImageUpload 
                authId={authId}
                baseUrl={baseUrl}
                imageUrl={profileImg}
                folder="agent-signature-image/userimg/"
                uploadUrl="/subuser/package_insert_hotel_image.php?fun=activityimageupload"
                deleteUrl="/subuser/package_insert_hotel_image.php?fun=activityimagedelete"
                setWorkImage={setProfileImg}
                delimg={true}
                toast={toast}
                eventLabel={"Upload Image"}
                eventCategory={"Settings Page"}
                uploadImgLocal={uploadImgLocal}
                setUploadImgLocal={setUploadImgLocal}
                activityDetails={activityDetails}
                uploadtype={"activityimage"}
                setImgFile={setImgFile}
              />
            </div>
            <div className="box-center">
              <button
                onClick={handleAddActivitySubmit}
                className="form-button submit-button bg1 fc4 mt16"
              >
                Add Activity
              </button>
              {/* <div
                onClick={() => setAddActivityFormVisible(false)}
                className="fc14 mt16"
              >
                Back
              </div> */}
            </div>
          </div>
        ) : (
          <>
            <div className="builder-filters mt12">
              <SearchListing
                onSearch={handleActivitySearch}
                placeholder={"Search Activity"}
              />
            </div>
            {activityResponse.length > 0 ? (
              activityResponse.map((item) => (
                <div
                  className="popup-card-wrapper brd1 pt16 pb16 pl8 pr8 df mt24 mb24"
                  key={item.id}
                  onClick={() => handleItineraryActivitySelect(item)}
                >
                  <div className="left-image">
                    <div className="image-contain pr">
                      <img src={item.activity_image} alt="packages" />
                    </div>
                  </div>
                  <div className="pop-text pl16 w100">
                    <div className="v-center">
                      <p className="fc1 fs16 fw6 ls1 lh18 captw">
                        {item.activity_name}
                      </p>
                    </div>
                    <div className="fs14 fw5 ls1 lh18 fc16 mt4 captw">
                      {item.activity_city}
                    </div>
                    {item.description !== "" && (
                      <div className="fs14 pt24 fc5 ls1 lh22">
                        {item.description}...
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="box-center box-center brd1 p24 mt24 bg7 df fdc br4 mb24">
                <p className="no-icon box-center br50 brd1 fc1">
                  <BiCycling />
                </p>
                <p className="fs16 ls1 lh14 not-found fc1">No Activity Found</p>
              </div>
            )}
            <div className="fixed-button">
              <button
                onClick={handleAddActivityClick}
                className="bg1 p12 fc3 w100 br4 cp"
              >
                Add Activity
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default PackageActivityPopup;
