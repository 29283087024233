import React from "react";
import defaultActivity from "../../assets/activitydefault.jpg";
import { FaExchangeAlt } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";

const ActivityCard = ({
  activityData,
  onActivityChangeClick,
  onActivityChange,
  onActivityDeleteClick,
}) => {
  return (
    <>
      {activityData.map((activity) => (
        <div className="brd1 pt16 pb16 pl8 pr4 df mb16 w100 select-itinenary-card" key={activity.id}>
          <div className="left-image w33">
            <div className="image-contain pr">
              <img
                src={
                  activity.activity_image
                    ? activity.activity_image
                    : defaultActivity
                }
                alt="activity"
              />
            </div>
          </div>
          <div className="pl16 w100 activity-rightcard-text">
            <div className="v-center jcsb">
              <p className="fc1 fs16 fw6 ls1 lh18">{activity.activity_name}</p>
              <div className="df">
                <p
                  className="fs14 fw5 cp ls1 pr8"
                  onClick={() => {
                    onActivityChangeClick();
                    onActivityChange(activity);
                  }}
                >
                  <FaExchangeAlt />
                </p>
                <p
                  className="fs14 fw5 cp ls1 pr8"
                  onClick={() => {
                    onActivityDeleteClick(activity.id);
                  }}
                >
                  <BsTrash />
                </p>
              </div>
            </div>
            <div className="fs14 fw5 ls1 lh18 fc16 mt4 captw">
              {activity.activity_city}
            </div>
            {activity.description && (
              <div className="fs14 pt24 fc5 ls1 lh22">
                {activity.description}
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default ActivityCard;
