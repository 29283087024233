import React, { useState, useEffect, useRef } from "react";
import "../pages/CreateItinerary.css";
import Tabs from "../components/Tabs";
import { RiHotelLine } from "react-icons/ri";
import {
  MdSkateboarding,
  MdNavigateNext,
  MdArrowBackIos,
  MdOutlineNavigateNext,
} from "react-icons/md";
import { GrNext } from "react-icons/gr";
import { FaCarSide } from "react-icons/fa";
import { BiSolidPencil } from "react-icons/bi";
import { IoLocationSharp } from "react-icons/io5";
import PackageCityPopup from "../components/Popup/PackageCityPopup";
import "react-datepicker/dist/react-datepicker.css";
import PackageHotelPopup from "../components/Popup/PackageHotelPopup";
import PackageActivityPopup from "../components/Popup/PackageActivityPopup";
import PackageTransferPopup from "../components/Popup/PackageTransferPopup";
import PackagePricing from "./PackagePricing";
import TextEditor from "../components/PackageBuilder/TextEditor";
import HotelCard from "../components/PackageBuilder/HotelCard";
import ActivityCard from "../components/PackageBuilder/ActivityCard";
import CityCard from "../components/PackageBuilder/CityCard";
import TransferCard from "../components/PackageBuilder/TransferCard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import { FaCarAlt, FaHotel } from "react-icons/fa";
import { SearchDropdown } from "../components/SearchDropdown";
import { useParams } from "react-router-dom";
import Inclusion from "../components/PackageBuilder/Inclusion";
import destinationOptions from "../data/DestinationData";
import themeOptions from "../data/ThemeOptions";
import { SearchTheme } from "../components/SearchTheme.js";
import noActivityImg from "../assets/noactivity.jpeg";
import { useTitle } from "../hooks/useTitle";

const CreateItinerary = ({ newPackageId, toast }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pid } = useParams();
  useTitle("Create Itinerary -  Travel Agents CRM - Hellotravel");

  newPackageId = pid ? pid : newPackageId;
  const titleDownRef = useRef(null);
  const tabs = [
    { label: "Itinerary", value: "itinerary" },
    { label: "Inclusion", value: "inclusion" },
    { label: "Pricing", value: "pricing" },
    { label: "Policies", value: "policies" },
  ];
  const user = useSelector((state) => state.auth);
  const fcp_uid = user.login_uid;
  const [authId, setAuthID] = useState(user.auth_id);
  const [baseUrl, setBaseUrl] = useState(user.base_url);
  const [siteUrl, setSiteUrl] = useState(user.site_url);

  const [selectedTab, setSelectedTab] = useState("itinerary");
  const [popupVisible, setPopupVisible] = useState(false);
  const [hotelVisible, setHotelVisible] = useState(false);
  const [activityVisible, setActivityVisible] = useState(false);
  const [transferVisible, setTransferVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("City");
  const [selectedDay, setSelectedDay] = useState("1");
  const [hotelListing, setHotelListing] = useState([]);
  const [cityListing, setCityListing] = useState([]);
  const [activityListing, setActivityListing] = useState([]);
  const [selectedItemItinerary, setSelectedItemItinerary] =
    useState("DAYS PLAN");
  const [apiDealData, setApiDealData] = useState(null);
  const [itinarayJsonState, setItinarayJsonState] = useState({});
  // states for selected city,hotel,activity,transfer and day selected
  const [newPackageIds, setNewPackageIds] = useState(newPackageId);
  const [itineraryId, setItineraryId] = useState("");
  const [nid, setNid] = useState("");
  const [tblLeadId, setTblLeadId] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [selectedTransfer, setSelectedTransfer] = useState([]);
  const [dayData, setDayData] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  // states for changes the city,hotel,activity ids
  const [changeCity, setChangeCity] = useState({});
  const [changeHotel, setChangeHotel] = useState({});
  const [changeActivity, setChangeActivity] = useState({});
  const [changeTransfer, setChangeTransfer] = useState({});
  const [editing, setEditing] = useState(true);
  const [daysArray, setDaysArray] = useState([]);
  // states for package builder
  const [selectPackageVisibility, setSelectPackageVisibility] = useState("");
  const [selectPackageType, setSelectPackageType] = useState("");
  const [title, setTitle] = useState([]);
  const [duration, setDuration] = useState("");
  const [tourDestination, setTourDestination] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [inclusion, setInclusion] = useState([]);
  const [exclusion, setExclusion] = useState([]);
  const [searchCity, setSearchCity] = useState("");
  const [inTransit, setInTransit] = useState("0");
  const [nextActiveTab, setNextActiveTab] = useState("1");

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleLogout = () => {
    // Dispatch the logout action to clear user data
    dispatch(logout());
    // Redirect to the login page
    navigate("/login");
  };

  const handleSaveClick = () => {
    handleUpdatePackageDetails();
  };
  const handleTourDestinationChange = (selectedValues) => {
    // state update in PackageBuilder component
    setTourDestination(selectedValues);
    handleOnChangeUpdateData(selectedValues, "destinations_covered");
  };
  const handleThemeChange = (selectedTheme) => {
    setSelectedTheme(selectedTheme);
    handleOnChangeUpdateData(selectedTheme, "themes");
  };

  const handlePublishData = async () => {
    console.log("publish");
    try {
      const requestBody = {
        auth_id: authId,
        nid: nid,
      };
      const publishresponce = await fetch(
        `${user.base_url}/subuser/getTitleSuggestion.php?fun=packagechecker`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const datapublish = await publishresponce.json();
      if (datapublish.login.status === 0) {
        handleLogout();
        return false;
      }

      if (datapublish.data.status === 1) {
        toast.success(datapublish.data.msg);

        setTimeout(() => {
          navigate("/packages");
        }, 6000);
      }
      if (datapublish.data.status === 0) {
        toast.error(datapublish.data.msg);}
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };

  const handleTitleChange = async (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      setTitle(value);
    }

    if (value.trim() !== "") {
      try {
        const requestBody = {
          id: newPackageId,
          auth_id: authId,
        };
        const getSuggestionsApiResponse = await fetch(
          `${user.base_url}/subuser/getTitleSuggestion.php?fun=genrateTitleSugg`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        const suggestionsData = await getSuggestionsApiResponse.json();
        setSuggestions(
          suggestionsData.status === 1 ? suggestionsData.suggestion : []
        );
      } catch (error) {
        toast.error("An error occurred while processing the request");
        console.error("API call error:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleInclusionChange = (e) => {
    const { value } = e.target;
    setInclusion(value);
  };

  const handleExclusionChange = (e) => {
    const { value } = e.target;
    setExclusion(value);
  };
  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    if (name === "visibility") {
      setSelectPackageVisibility(value);
      handleOnChangeUpdateData(value, "package_visibility");
    } else if (name === "type") {
      setSelectPackageType(value);
      handleOnChangeUpdateData(value, "package_type");
    } else if (name === "duration") {
      setDuration(value);
      handleOnChangeUpdateData(value, "duration");
    }
  };
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const handleSuggestionClick = (suggestion) => {
    setTitle(suggestion);
    handleOnChangeUpdateData(suggestion, "title");

    setSuggestions([]);
  };
  const handleTabChange = (value) => {
    setSelectedTab(value);
  };
  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };
  const toggleHotelPopup = () => {
    setHotelVisible(!hotelVisible);
  };
  const toggleActivityPopup = () => {
    setActivityVisible(!activityVisible);
  };
  const toggleTransferPopup = () => {
    setTransferVisible(false);
  };
  const handleUpdatePackageDetails = async () => {
    if (!title.length) {
      alert("Please fill the title.");
      return;
    }
    try {
      // Convert to strings if they are not
      const inclusionArray =
        inclusion && typeof inclusion === "string" ? inclusion.split(",") : [];
      const exclusionArray =
        exclusion && typeof exclusion === "string" ? exclusion.split(",") : [];

      const pkgDetailsApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=addorupdatedeals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            title: title ? title : "",
            package_visibility: selectPackageVisibility
              ? selectPackageVisibility
              : "",
            package_type: selectPackageType ? selectPackageType : "",
            duration: duration ? duration : "",
            destinations_covered: tourDestination ? tourDestination : "",
            themes: selectedTheme ? selectedTheme : "",
            inclusion_exclusion: {
              inclusion: inclusionArray,
              exclusion: exclusionArray,
            },
            nid: nid,
            id: newPackageId,
            auth_id: authId,
          }),
        }
      );
      const pkgDetails = await pkgDetailsApiResponse.json();
      if (pkgDetails.login.status === 0) {
        handleLogout();
        return false;
      }

      handleGetDeals(newPackageId);
      setEditing(false);
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const updateHandleGetDeals = async (id, indexx) => {
    try {
      const requestBody = { id: id, auth_id: authId };
      const getDealsApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=deals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const dealDetailsData = await getDealsApiResponse.json();
      if (dealDetailsData.login.status === 0) {
        handleLogout();
        return false;
      }
      setApiDealData(dealDetailsData);
      setNid(dealDetailsData.data.deal_object.nid);
      setTblLeadId(dealDetailsData.data.deal_object.id);
      setItinarayJsonState(
        JSON.parse(
          dealDetailsData.data.deal_object.itennary_struct_json || "{}"
        )
      );
      const firstItemId = Object.keys(
        JSON.parse(
          dealDetailsData.data.deal_object.itennary_struct_json || "{}"
        )
      )[indexx - 1];
      const firstItemData = JSON.parse(
        dealDetailsData.data.deal_object.itennary_struct_json || "{}"
      )[firstItemId];
      const selectedDayId = firstItemData.id;
      handleDayClick(firstItemId, firstItemData);
      /*JSON.parse(
        dealDetailsData.data.deal_object.itennary_struct_json || "{}"
      )[firstItemId].id;*/
      setItineraryId(selectedDayId);
      setTitle(dealDetailsData.data.deal_object.title);
      setSelectPackageType(dealDetailsData.data.deal_object.package_type);
      setSelectPackageVisibility(
        dealDetailsData.data.deal_object.package_visibility
      );
      setDuration(dealDetailsData.data.deal_object.duration);
      setTourDestination(dealDetailsData.data.deal_object.destinations_covered);
      setSelectedTheme(dealDetailsData.data.deal_object.themes);
      // Extract and set inclusion and exclusion states
      const inclusionData =
        dealDetailsData?.data?.deal_object?.inclusion_exclusion?.inclusion ||
        [];
      const exclusionData =
        dealDetailsData?.data?.deal_object?.inclusion_exclusion?.exclusion ||
        [];

      setInclusion(inclusionData);
      setExclusion(exclusionData);

      // Create daysArray and set it in the state
      const itennaryStructJson =
        dealDetailsData?.data?.deal_object?.itennary_struct_json || "{}";
      const itennaryData = JSON.parse(itennaryStructJson);

      const updatedDaysArray = Object.keys(itennaryData).map((day) => ({
        id: day,
        data: itennaryData[day],
      }));
      setDaysArray(updatedDaysArray);
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };

  const handleGetDeals = async (id) => {
    try {
      const requestBody = { id: id, auth_id: authId };
      const getDealsApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=deals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const dealDetailsData = await getDealsApiResponse.json();
      if (dealDetailsData.login.status === 0) {
        handleLogout();
        return false;
      }
      setApiDealData(dealDetailsData);
      setNid(dealDetailsData.data.deal_object.nid);
      setTblLeadId(dealDetailsData.data.deal_object.id);
      setItinarayJsonState(
        JSON.parse(
          dealDetailsData.data.deal_object.itennary_struct_json || "{}"
        )
      );
      const firstItemId = Object.keys(
        JSON.parse(
          dealDetailsData.data.deal_object.itennary_struct_json || "{}"
        )
      )[0];
      const firstItemData = JSON.parse(
        dealDetailsData.data.deal_object.itennary_struct_json || "{}"
      )[firstItemId];
      const selectedDayId = firstItemData.id;
      setSelectedDay(selectedDayId);
      handleDayClick(firstItemId, firstItemData);
      /*JSON.parse(
        dealDetailsData.data.deal_object.itennary_struct_json || "{}"
      )[firstItemId].id;*/
      setItineraryId(selectedDayId);
      setTitle(dealDetailsData.data.deal_object.title);
      setSelectPackageType(dealDetailsData.data.deal_object.package_type);
      setSelectPackageVisibility(
        dealDetailsData.data.deal_object.package_visibility
      );
      setDuration(dealDetailsData.data.deal_object.duration);
      setTourDestination(dealDetailsData.data.deal_object.destinations_covered);
      setSelectedTheme(dealDetailsData.data.deal_object.themes);
      // Extract and set inclusion and exclusion states
      const inclusionData =
        dealDetailsData?.data?.deal_object?.inclusion_exclusion?.inclusion ||
        [];
      const exclusionData =
        dealDetailsData?.data?.deal_object?.inclusion_exclusion?.exclusion ||
        [];

      setInclusion(inclusionData);
      setExclusion(exclusionData);

      // Create daysArray and set it in the state
      const itennaryStructJson =
        dealDetailsData?.data?.deal_object?.itennary_struct_json || "{}";
      const itennaryData = JSON.parse(itennaryStructJson);

      const updatedDaysArray = Object.keys(itennaryData).map((day) => ({
        id: day,
        data: itennaryData[day],
      }));
      setDaysArray(updatedDaysArray);
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const handleApiCityListing = async (searchQuery) => {
    try {
      let city = tourDestination;

      const requestBody = {
        auth_id: authId,
        city: city,
        search: searchQuery ? searchQuery : "",
      };

      const cityListingApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=ptslisting`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const cityListingJson = await cityListingApiResponse.json();
      if (cityListingJson.login.status === 0) {
        handleLogout();
        return false;
      }

      setPopupVisible(true);
      setCityListing(cityListingJson.data.result);
      setSearchCity("");
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const handleApiHotelListing = async (searchQuery) => {
    try {
      let city = tourDestination;
      if (selectedCity.length > 0) {
        city = [selectedCity[0].city_name];
      }
      else{
        toast.warn("Please select city first");
        return false;
      }
      const requestBody = {
        auth_id: authId,
        city: city,
        search: searchQuery ? searchQuery : "",
      };

      const hotelListingApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=hotellisting`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const hotelListing = await hotelListingApiResponse.json();
      if (hotelListing.login.status === 0) {
        handleLogout();
        return false;
      }
      setHotelVisible(true);
      setHotelListing(hotelListing.data.result);
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const handleApiActivityListing = async (searchQuery) => {
    try {
      let city = tourDestination;
      if (selectedCity.length > 0) {
        city = [selectedCity[0].city_name];
      }
      else{
        toast.warn("Please select city first");
        return false;
      }

      const requestBody = {
        auth_id: authId,
        city: city,
        search: searchQuery ? searchQuery : "",
      };

      const activityListingApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=activitylist`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const activityListing = await activityListingApiResponse.json();
      if (activityListing.login.status === 0) {
        handleLogout();
        return false;
      }

      setActivityVisible(true);
      setActivityListing(activityListing.data.result);
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const handleApiCitySelect = async (city) => {
    try {
      const requestBody = {
        auth_id: authId,
        nid: nid,
        itinerary_id: itineraryId,
        tbl_deal_id: tblLeadId,
        hellotravel_id: "",
        city_name:
          city.city_name !== "" && city.city_name !== undefined
            ? city.city_name
            : city.place,
        city_image:
          city.city_image !== "" && city.city_image !== undefined
            ? city.city_image
            : city.image,
        city_type:
          city.city_type !== "" && city.city_type !== undefined
            ? city.city_type
            : city.type,
        city_state:
          city.city_state !== "" && city.city_state !== undefined
            ? city.city_state
            : city.city_state,
        country_name: city.country_name,
        id: changeCity ? changeCity.id : "",
        description: city.description ? city.description : "",
      };
      const postCityApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=city`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const postCityApiDetails = await postCityApiResponse.json();
      if (postCityApiDetails.login.status === 0) {
        handleLogout();
        return false;
      }
      updateHandleGetDeals(newPackageId, selectedDay);

      // handleGetDeals(newPackageId);
      setSelectedCity(postCityApiDetails.data.city_obj);
      setChangeCity({});
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const handleApiHotelSelect = async (hotel) => {
    try {
      const requestBody = {
        auth_id: authId,
        nid: nid,
        itinerary_id: itineraryId ? itineraryId : dayData.id,
        tbl_deal_id: tblLeadId,
        hotelname: hotel.hotel_name,
        room_type: hotel.room_type ? hotel.room_type : "",
        includes: hotel.includes ? hotel.includes : "",
        hote_type: hotel.hote_type,
        hotel_city: hotel.hotel_address || hotel.hotel_city || hotel.city_name || selectedCity[0].city_name || "",
        property_type: hotel.property_type,
        hotel_image: hotel.image || hotel.hotel_image || "",
        hotel_store: hotel.hotel_store ? hotel.hotel_store: "",
        hote_type: hotel.hotel_type ? hotel.hotel_type : "",
        id: changeHotel ? changeHotel.id : "",
        in_transit: hotel.in_transit ? hotel.in_transit : "0",
      };
      const postHotelApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=hotel`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const postHotelApiDetails = await postHotelApiResponse.json();
      if (postHotelApiDetails.login.status === 0) {
        handleLogout();
        return false;
      }
      updateHandleGetDeals(newPackageId, selectedDay);
      setSelectedHotel(postHotelApiDetails.data.hotel_obj);
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const handleApiActivitySelect = async (activity) => {
    try {
      const requestBody = {
        auth_id: authId,
        nid: nid,
        itinerary_id: itineraryId,
        tbl_deal_id: tblLeadId,
        activity_name: activity.activity_name ? activity.activity_name : "",
        activity_type: "3246546576i",
        activity_image: activity.activity_image ? activity.activity_image : "",
        activity_city:  activity.activity_city || activity.city || selectedCity[0].city_name || "",
        activity_country: "",
        activity_id: activity.activity_id ? activity.activity_id : "",
        activity_url: activity.ttd_url ? "https://www.hellotravel.com/"+activity.ttd_url : "",
        activity_store: activity.activity_store ? activity.activity_store : "",
        description: activity.description,
        id: changeActivity ? changeActivity.id : "",
        in_transit: activity.in_transit ? activity.in_transit : "0",
      };
      const postActivityApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=activity`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const postActivityApiDetails = await postActivityApiResponse.json();
      if (postActivityApiDetails.login.status === 0) {
        handleLogout();
        return false;
      }
      updateHandleGetDeals(newPackageId, selectedDay);
      setSelectedActivity(postActivityApiDetails.data.activity_obj);
      setChangeActivity({});
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };

  const handleSameAsPreviousDayTransfer = () => {
    const currentIndex = daysArray.findIndex((day) => day.id === selectedDay);

    if (currentIndex > 0) {
      const previousDayData = daysArray[currentIndex - 1].data;
      previousDayData.transfer.map((transObj) =>
        handleApiTransferSelect(transObj)
      );
    }
    /*	
    if (currentIndex > 0) {
      const previousDayData = daysArray[currentIndex - 1].data;
	  previousDayData.transfer.map((transObj) => (
      		handleApiTransferSelect(transObj);
	  );
	 );

    }
    */
  };

  const handleApiTransferSelect = async (transfer) => {
    try {
      const requestBody = {
        auth_id: authId,
        nid: nid,
        itinerary_id: itineraryId,
        tbl_deal_id: tblLeadId,
        id: changeTransfer ? changeTransfer.id : "",
        in_transit: transfer.in_transit ? transfer.in_transit : "0",
      };

      if (transfer.selectedTypeOption === "Flight") {
        requestBody.name = transfer.flightDetails.flightName || "";
        requestBody.type = transfer.selectedTypeOption || "";
        requestBody.transfer_mode = transfer.selectedType || "";
        requestBody.departure_time = transfer.flightDetails.departureTime || "";
        requestBody.arrival_time = transfer.flightDetails.arrivalTime || "";
        requestBody.arrival_city = transfer.flightDetails.to || "";
        requestBody.departure_city = transfer.flightDetails.from || "";
        requestBody.vehicle_number = transfer.flightDetails.flightNumber || "";
      } else if (transfer.selectedTypeOption === "Train") {
        requestBody.name = transfer.trainDetails.trainName || "";
        requestBody.type = transfer.selectedTypeOption || "";
        requestBody.transfer_mode = transfer.selectedType || "";
        requestBody.departure_time = transfer.trainDetails.departureTime || "";
        requestBody.arrival_time = transfer.trainDetails.arrivalTime || "";
        requestBody.arrival_city = transfer.trainDetails.to || "";
        requestBody.departure_city = transfer.trainDetails.from || "";
        requestBody.vehicle_number = transfer.trainDetails.trainNumber || "";
      } else {
        requestBody.name = transfer.selectedTypeOption || transfer.name || "";
        requestBody.transfer_mode =
          transfer.selectedType || transfer.transfer_mode || "";
      }

      const postTransferApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=transfer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const postTransferApiDetails = await postTransferApiResponse.json();
      if (postTransferApiDetails.login.status === 0) {
        handleLogout();
        return false;
      }
      updateHandleGetDeals(newPackageId, selectedDay);
      setSelectedTransfer(postTransferApiDetails.data.transfer_obj);
      setChangeTransfer({});
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };

  const handleCityClick = () => {
    handleApiCityListing();
  };
  const handlehotelClick = () => {

    handleApiHotelListing();
  };
  const handleactivityClick = () => {
    handleApiActivityListing();
  };
  const handleTransferClick = () => {
    setTransferVisible(true);
  };
  // const itennaryStructJson =
  //   apiDealData?.data?.deal_object?.itennary_struct_json || "{}";
  // const itennaryData = JSON.parse(itennaryStructJson);

  // const daysArray = Object.keys(itennaryData).map((day) => ({
  //   id: day,
  //   data: itennaryData[day],
  // }));
  const handleDayClick = (dayId, dayData) => {
    setSelectedDay(dayId);
    setItineraryId(dayData.id);
    setDayData(dayData);
    // Extract hotel, city, and activity information
    const selectedHotel = dayData.hotels.length > 0 ? dayData.hotels : [];
    const selectedCity =
      dayData.destination_city.length > 0 ? dayData.destination_city : [];
    const selectedActivity =
      dayData.activity.length > 0 ? dayData.activity : [];
    const selectedTransfer =
      dayData.transfer.length > 0 ? dayData.transfer : [];
    setSelectedHotel(selectedHotel);
    setSelectedCity(selectedCity);
    setSelectedActivity(selectedActivity);
    setSelectedTransfer(selectedTransfer);
  };
  const handleItineraryCitySelect = (city) => {
    handleApiCitySelect(city);
    togglePopup();
  };
  const handleItineraryHotelSelect = (hotel) => {
    handleApiHotelSelect(hotel);
    toggleHotelPopup();
  };
  const handleItineraryActivitySelect = (activity) => {
    handleApiActivitySelect(activity);
    toggleActivityPopup();
  };
  const handleItineraryTransferSelect = (transfer) => {
    handleApiTransferSelect(transfer);
    toggleTransferPopup();
  };
  const handleCityChange = (cityObject) => {
    setChangeCity(cityObject);
    handleApiCityListing();
  };
  const handleHotelChange = (hotelObject) => {
    handleApiHotelListing();
    setChangeHotel(hotelObject);
  };

  const handleActivityChange = (activityObject) => {
    handleApiActivityListing();
    setChangeActivity(activityObject);
  };
  const handleTransferChange = (transferObject) => {
    setChangeTransfer(transferObject);
  };
  const handleApiDeleteCity = async (id) => {
    try {
      const requestBody = {
        auth_id: authId,
        nid: nid,
        tbl_deal_id: tblLeadId,
        upid: id,
        itinerary_id: itineraryId,
      };

      const deleteCityApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=deactivecity`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const cityDeleteJson = await deleteCityApiResponse.json();
      if (cityDeleteJson.login.status === 0) {
        handleLogout();
        return false;
      }
      if (cityDeleteJson.data && cityDeleteJson.data.status === "1") {
        toast.success("City Deleted Successfully");
      } else {
        toast.error("Something went wrong");
      }
      setSelectedCity(cityDeleteJson.data.city_obj);
      updateHandleGetDeals(newPackageId, selectedDay);
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const handleApiDeleteHotel = async (id) => {
    try {
      const requestBody = {
        auth_id: authId,
        nid: nid,
        tbl_deal_id: tblLeadId,
        upid: id,
        itinerary_id: itineraryId,
      };

      const deleteHotelApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=deactivehotel`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const hotelDeleteJson = await deleteHotelApiResponse.json();
      if (hotelDeleteJson.login.status === 0) {
        handleLogout();
        return false;
      }

      if (hotelDeleteJson.data && hotelDeleteJson.data.status === "1") {
        toast.success("Hotel Deleted Successfully");
      } else {
        toast.error("Something went wrong");
      }

      setSelectedHotel(hotelDeleteJson.data.hotel_obj);
      updateHandleGetDeals(newPackageId, selectedDay);
      //handleGetDeals(tblLeadId);
      setChangeHotel({});
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const handleApiDeleteActivity = async (id) => {
    try {
      const requestBody = {
        auth_id: authId,
        nid: nid,
        tbl_deal_id: tblLeadId,
        upid: id,
        itinerary_id: itineraryId,
      };

      const deleteActivityApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=deactiveactivity`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const activityDeleteJson = await deleteActivityApiResponse.json();
      if (activityDeleteJson.login.status === 0) {
        handleLogout();
        return false;
      }
      if (activityDeleteJson.data && activityDeleteJson.data.status === "1") {
        toast.success("Activity Deleted Successfully");
      } else {
        toast.error("Something went wrong");
      }

      setSelectedActivity(activityDeleteJson.data.activity_obj);
      updateHandleGetDeals(newPackageId, selectedDay);
      //handleGetDeals(tblLeadId);
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const handleApiDeleteTransfer = async (id) => {
    try {
      const requestBody = {
        auth_id: authId,
        nid: nid,
        tbl_deal_id: tblLeadId,
        upid: id,
        itinerary_id: itineraryId,
      };

      const deleteTransferApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=deactivetransfer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const transferDeleteJson = await deleteTransferApiResponse.json();
      if (transferDeleteJson.login.status === 0) {
        handleLogout();
        return false;
      }
      if (transferDeleteJson.data && transferDeleteJson.data.status === "1") {
        toast.success("Transfer Deleted Successfully");
      } else {
        toast.error("Something went wrong");
      }

      setSelectedTransfer(transferDeleteJson.data.transfer_obj);
      updateHandleGetDeals(newPackageId, selectedDay);
      //  handleGetDeals(tblLeadId);
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };
  const handleNextButtonClick = () => {
    switch (selectedOption) {
      case "City":
        setSelectedOption("Hotel");
        break;

      case "Hotel":
        setSelectedOption("Activity");
        break;

      case "Activity":
        setSelectedOption("Transfer");
        break;

      case "Transfer":
        // index of the currently selected day
        const currentIndex = daysArray.findIndex(
          (day) => day.id === selectedDay
        );
        if (currentIndex === daysArray.length - 1) {
          setSelectedTab("inclusion");
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          setSelectedOption("City");
        }
        // Check if there is a next day
        if (currentIndex < daysArray.length - 1) {
          //  next day id
          const selectedDay = daysArray[currentIndex + 1].id;
          const nextDayId = daysArray[currentIndex + 1].data.id;

          // Set the itineraryId to the next day's ID
          setItineraryId(nextDayId);
          // Set the selected day to the next day
          setSelectedDay(selectedDay);

          // Set other details for the new day, such as hotel, city, etc.
          const nextDayData = daysArray[currentIndex + 1].data;

          // Extract set hotel, city, and activity information
          const nextDayHotels =
            nextDayData.hotels.length > 0 ? nextDayData.hotels : [];
          const nextDayCity =
            nextDayData.destination_city.length > 0
              ? nextDayData.destination_city
              : [];
          const nextDayActivity =
            nextDayData.destination_city.length > 0 ? nextDayData.activity : [];
          const nextDayTransfer =
            nextDayData.transfer.length > 0 ? nextDayData.transfer : [];

          setSelectedHotel(nextDayHotels);
          setSelectedCity(nextDayCity);
          setSelectedActivity(nextDayActivity);
          setSelectedTransfer(nextDayTransfer);
        }
        break;

      default:
        // Handle default case
        break;
    }
  };
  const handleSearchQueryCity = (searchQuery, atype) => {
    setSearchCity(searchQuery);
    if (atype == "city") {
      handleApiCityListing(searchQuery);
    } else if (atype == "hotel") {
      handleApiHotelListing(searchQuery);
    } else if (atype == "activity") {
      handleApiActivityListing(searchQuery);
    }
  };

  const handleSameAsPreviousDayCity = () => {
    const currentIndex = daysArray.findIndex((day) => day.id === selectedDay);

    if (currentIndex > 0) {
      const previousDayData = daysArray[currentIndex - 1].data;
      handleApiCitySelect(previousDayData.destination_city[0]);
    }
  };

  const handleSameAsPreviousDayHotel = () => {
    const currentIndex = daysArray.findIndex((day) => day.id === selectedDay);

    if (currentIndex > 0) {
      const previousDayData = daysArray[currentIndex - 1].data;
      handleApiHotelSelect(previousDayData.hotels[0]);
    }
  };
  const currentIndex = daysArray.findIndex((day) => day.id === selectedDay);

  const handleNextTabMove = () => {
    const currentIndex = tabs.findIndex((tab) => tab.value === selectedTab);
    if (currentIndex < tabs.length - 1) {
      const nextTab = tabs[currentIndex + 1].value;
      setSelectedTab(nextTab);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleBack = () => {
    const currentIndex = tabs.findIndex((tab) => tab.value === selectedTab);
    if (currentIndex > 0) {
      const previousTab = tabs[currentIndex - 1].value;
      setSelectedTab(previousTab);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const renderNextButton = () => {
    if (selectedTab !== "policies") {
      return (
        <button
          type="button"
          className="lead-buy-btn fc1 br24 bg5  tdu cp pr24 pt10 pb10 df aic"
          onClick={handleNextTabMove}
        >
          Next <GrNext className="ml8 fs14 fc1" />
        </button>
      );
    }
    return null;
  };
  const renderBackButton = () => {
    if (selectedTab !== "itinerary") {
      return (
        <button
          type="button"
          className="lead-buy-btn fc1 br24 bg5 fs16 tdu cp pr24 pt10 pb10 df aic"
          onClick={handleBack}
        >
          <MdArrowBackIos className="ml8 fs14 fc1" /> Back
        </button>
      );
    }
    return null;
  };

  const handlePreview = () => {
    const previewUrl = `https://www.hellotravel.com/hellotravel2.0/package_itinerary_detail.php?nid=${nid}&fcpuid=${fcp_uid}`;
    window.open(previewUrl, "_blank");
  };

  const handleSaveAsDraft = () => {
    handleOnChangeUpdateData("5", "status");
    toast.success("Saved As Draft successfully");
    setTimeout(() => {
      navigate("/packages");
    }, 6000);
  };

  const handleInTransit = () => {
    const transit = {
      auth_id: authId,
      nid: nid,
      itinerary_id: itineraryId,
      tbl_deal_id: tblLeadId,
      hotelname: "",
      room_type: "",
      includes: "",
      hote_type: "",
      hotel_city: "",
      property_type: "",
      hotel_image: "",
    };
    const inTransitValue = "1";
    handleApiHotelSelect({ ...transit, in_transit: inTransitValue });
  };
  const handleNoActivity = () => {
    const noActivity = {
      auth_id: authId,
      nid: nid,
      itinerary_id: itineraryId,
      tbl_deal_id: tblLeadId,
      activity_name: "In Transit or Day at Leisure",
      activity_type: "",
      activity_image: noActivityImg,
      activity_city: "No sightseeing planned",
      activity_country: "",
      activity_id: "",
      description: "",
      in_transit: "",
      id: changeActivity ? changeActivity.id : "",
    };
    const inTransitValue = "1";
    handleApiActivitySelect({ ...noActivity, in_transit: inTransitValue });
  };

  const handleNoTransfer = () => {
    const noTransfer = {
      auth_id: authId,
      nid: nid,
      itinerary_id: itineraryId,
      tbl_deal_id: tblLeadId,
      id: changeTransfer ? changeTransfer.id : "",
      selectedTypeOption: "No Transfer",
      selectedType: "No Transfer",
    };
    const inTransitValue = "1";
    handleApiTransferSelect({ ...noTransfer, in_transit: inTransitValue });
  };
  useEffect(() => {
    if (newPackageId) {
      handleGetDeals(newPackageId);
      window.scrollTo(0, 0);
    }
  }, [newPackageId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        titleDownRef.current &&
        !titleDownRef.current.contains(event.target)
      ) {
        setSuggestions([]);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOnChangeUpdateData = async (value, type) => {
    const commonProperties = {
      id: newPackageId,
      nid: nid,
      auth_id: authId,
    };
    if (type == "title") {
      value = value.trim();
    }
    const requestBody = {
      ...commonProperties,
      [type]: value || "",
    };
    if (value == "") {
      toast.error("Field should be not blank");
      return false;
    }

    try {
      const pkgDetailsApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=addorupdatedeals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const pkgDetails = await pkgDetailsApiResponse.json();
      if (pkgDetails.login.status === 0) {
        handleLogout();
        return false;
      }
      handleGetDeals(newPackageId);
    } catch (error) {
      toast.error("An error occurred while processing the request");
      console.error("API call error:", error);
    }
  };

  /*
  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        if (newPackageId) {
          const requestBody = {
            id: newPackageId,
            auth_id: authId,
          };
          const getSuggestionsApiResponse = await fetch(
            `${user.base_url}/subuser/getTitleSuggestion.php?fun=genrateTitleSugg`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          );
          const suggestionsData = await getSuggestionsApiResponse.json();
          setSuggestions(
            suggestionsData.status === 1 ? suggestionsData.suggestion : []
          );
        }
      } catch (error) {
        console.error("API call error:", error);
      }
    };

    fetchSuggestions();
  }, [newPackageId]);
  */
  return (
    <div className="df itinerary-container">
      <div className="left-itinerary">
        <div className="mb24">
          <Tabs
            tabs={tabs}
            showCheckboxes={false}
            showFilter={false}
            onTabChange={handleTabChange}
            nextActiveTab={nextActiveTab}
            selectedTab={selectedTab}
          />
        </div>
        {selectedTab === "itinerary" && (
          <div className="itinerary-plans">
            <ul className="df p20 bg19 wsnw itinenary-plan-list">
              <li
                className={`pt8 pb8 pl16 pr16 v-center ls1 cp ${
                  selectedItemItinerary === "DAYS PLAN" ? "active" : ""
                }`}
                onClick={() => {
                  setSelectedItemItinerary("DAYS PLAN");
                  setSelectedOption("City");
                }}
              >
                <p className="fs14 pl8">
                  {apiDealData && apiDealData.data.deal_object.days} DAYS PLAN
                </p>
              </li>
              <li
                className={`pt8 pb8 pl16 pr16 v-center ls1 cp ${
                  selectedItemItinerary === "HOTELS" ? "active" : ""
                }`}
                onClick={() => {
                  setSelectedItemItinerary("HOTELS");
                  setSelectedOption("Hotel");
                }}
              >
                <RiHotelLine className="mr4 fs16 " />
                <p className="fs14">HOTELS</p>
              </li>
              <li
                className={`pt8 pb8 pl16 pr16 v-center ls1 cp ${
                  selectedItemItinerary === "ACTIVITIES" ? "active" : ""
                }`}
                onClick={() => {
                  setSelectedItemItinerary("ACTIVITIES");
                  setSelectedOption("Activity");
                }}
              >
                <MdSkateboarding className="mr4 fs16" />
                <p className="fs14">ACTIVITIES</p>
              </li>
              <li
                className={`pt8 pb8 pl16 pr16 v-center ls1 cp ${
                  selectedItemItinerary === "TRANSFERS" ? "active" : ""
                }`}
                onClick={() => {
                  setSelectedItemItinerary("TRANSFERS");
                  setSelectedOption("Transfer");
                }}
              >
                <FaCarSide className="mr4 fs16" />
                <p className="fs14">TRANSFERS</p>
              </li>
            </ul>
          </div>
        )}
        <div className="display-itenerary-details df">
          <div className="lead-details-popup-container">
            <PackageCityPopup
              show={popupVisible}
              onClose={togglePopup}
              cityResponse={cityListing}
              onCitySelect={handleItineraryCitySelect}
              onSearch={handleSearchQueryCity}
            />
            <PackageHotelPopup
              show={hotelVisible}
              onClose={toggleHotelPopup}
              hotelResponse={hotelListing}
              onHotelSelect={handleItineraryHotelSelect}
              nid={nid}
              itineraryId={itineraryId}
              tblLeadId={tblLeadId}
              onSearch={handleSearchQueryCity}
              toast={toast}
            />
            <PackageActivityPopup
              show={activityVisible}
              onClose={toggleActivityPopup}
              nid={nid}
              itineraryId={itineraryId}
              tblLeadId={tblLeadId}
              activityResponse={activityListing}
              onActivitySelect={handleItineraryActivitySelect}
              onSearch={handleSearchQueryCity}
              toast={toast}
            />
            <PackageTransferPopup
              show={transferVisible}
              onClose={toggleTransferPopup}
              onTransferSelect={handleItineraryTransferSelect}
              toast={toast}
            />
          </div>
          {selectedTab === "itinerary" && (
            <>
              <div className="left-side-days-display">
                <p className="mb16 day-heading">Day Plan</p>
                <ul className="days-plan">
                  {daysArray.map((item) => (
                    <li
                      key={item.id}
                      className={`day-list fs14 fc5 mb16 v-center pr pl12 cp ${
                        selectedDay === item.id ? "active" : ""
                      }`}
                      onClick={() => handleDayClick(item.id, item.data)}
                    >
                      <p className="circle-day"></p>Day {item.id}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="right-side-days-info flx1 pt12">
                <div className="bg20 df">
                  <div className="day-wise-info">
                    Day {selectedDay} Information
                  </div>
                </div>
                <div className="v-center mt4 mb16 p12 itinerary-active-tabs">
                  <div
                    className={`select-itinerary box-center p12 brd1 mr8 cp fs14 ${
                      selectedOption === "City" ? "active" : ""
                    }`}
                    onClick={() => setSelectedOption("City")}
                  >
                    CITY
                  </div>
                  <div
                    className={`select-itinerary box-center p12 brd1 mr8 cp fs14 ${
                      selectedOption === "Hotel" ? "active" : ""
                    }`}
                    onClick={() => setSelectedOption("Hotel")}
                  >
                    HOTEL
                  </div>
                  <div
                    className={`select-itinerary box-center p12 brd1 mr8 cp fs14 ${
                      selectedOption === "Activity" ? "active" : ""
                    }`}
                    onClick={() => setSelectedOption("Activity")}
                  >
                    ACTIVITY
                  </div>
                  <div
                    className={`select-itinerary box-center p12 brd1 cp fs14 ${
                      selectedOption === "Transfer" ? "active" : ""
                    }`}
                    onClick={() => setSelectedOption("Transfer")}
                  >
                    TRANSFER
                  </div>
                </div>

                <div className="df fdc pl12 pb24 card-placer">
                  <div className="">
                    {selectedOption === "City" ? (
                      <>
                        <div className="fs14 fw4 mb24 c2 lh18">
                          Craft extraordinary journeys by selecting the perfect
                          destination for travelers.
                        </div>

                        <div className="w100">
                          {selectedCity.length > 0 && (
                            <CityCard
                              cityData={selectedCity}
                              onCityChangeClick={() => setPopupVisible(true)}
                              onCityChange={handleCityChange}
                              onCityDeleteClick={handleApiDeleteCity}
                            />
                          )}
                        </div>
                        {selectedCity.length <= 0 && (
                          <div className="select-card bg20 brd1 v-center">
                            <div
                              className="ls2 fc3 bg1 v-center pt8 pb8 pl16 pr16 select-button br24 cp"
                              onClick={handleCityClick}
                            >
                              <p className="fs14">Select City</p>
                            </div>
                            <div className="h48 v-center cp ml16">
                              <p
                                className="fs14 fc1"
                                onClick={handleSameAsPreviousDayCity}
                              >
                                {currentIndex > 0 &&
                                  `Same as Day ${currentIndex}`}
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="df jce mt12">
                          <div
                            className="ls2 fc3 v-center select-button br8 jce cp"
                            onClick={handleNextButtonClick}
                          >
                            <p className="fs14 fc1">Next</p>
                            <MdNavigateNext className="ml8 fs18 fc1" />
                          </div>
                        </div>
                      </>
                    ) : selectedOption === "Hotel" ? (
                      <div className="w100">
                        <div className="fs14 fw4 mb24 c2 lh18">
                          Enhance your package by seamlessly adding hotel
                          details for a tailored stay.
                        </div>
                        {selectedHotel.length > 0 && (
                          <HotelCard
                            hotelData={selectedHotel}
                            onHotelChangeClick={() => setHotelVisible(selectedCity.length>0 ? true: false)}
                            onHotelChange={handleHotelChange}
                            onHotelDeleteClick={handleApiDeleteHotel}
                          />
                        )}
                        {selectedHotel.length <= 0 && (
                          <div className="select-card bg20 brd1 v-center">
                            <div
                              className="ls2 fc3 bg1 v-center pt8 pb8 pl16 pr16 select-button br24 mr24 cp"
                              onClick={handlehotelClick}
                            >
                              <p className="fs14">Select Hotel</p>
                            </div>
                            <div
                              className="fs14 ls1 fw6 cp in-transit br24 bg5 ml16 pt8 pb8 pl16 pr16"
                              onClick={handleInTransit}
                            >
                              In Transit?
                            </div>

                            <div className="h48 v-center cp ml16">
                              <p
                                className="fs14 fc1"
                                onClick={handleSameAsPreviousDayHotel}
                              >
                                {currentIndex > 0 &&
                                  `Same as Day ${currentIndex}`}
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="df jce mt12">
                          <div
                            className="ls2 fc3 v-center select-button br8 jce cp"
                            onClick={handleNextButtonClick}
                          >
                            <p className="fs14 fc1">Next</p>
                            <MdNavigateNext className="ml8 fs18 fc1" />
                          </div>
                        </div>
                      </div>
                    ) : selectedOption === "Activity" ? (
                      <div className="w100">
                        <div className="fs14 fw4 mb24 c2 lh18">
                          Select activities to create a engaging travel
                          experience for travelers.
                        </div>
                        {selectedActivity && (
                          <ActivityCard
                            activityData={selectedActivity}
                            onActivityChangeClick={() =>
                              setActivityVisible(selectedCity.length>0 ? true: false)
                            }
                            onActivityChange={handleActivityChange}
                            onActivityDeleteClick={handleApiDeleteActivity}
                          />
                        )}
                        {(selectedActivity[0] === undefined ||
                          selectedActivity[0]?.in_transit === "0") && (
                          <div className="select-card bg20 brd1 v-center">
                            <div
                              className="ls2 fc3 bg1 v-center pt8 pb8 pl16 pr16 select-button br24 mr24 cp"
                              onClick={handleactivityClick}
                            >
                              <p className="fs14">Select Activity</p>
                            </div>

                            {selectedActivity.length === 0 && (
                              <div
                                className="fc1 fs14 ls1 cp ml16 brd1 pt8 pb8 pl16 pr16 bg5 br24"
                                onClick={handleNoActivity}
                              >
                                No Activity
                              </div>
                            )}
                          </div>
                        )}

                        <div className="df jce mt12">
                          <div
                            className="ls2 fc3 v-center select-button br8 jce cp"
                            onClick={handleNextButtonClick}
                          >
                            <p className="fs14 fc1">Next</p>
                            <MdNavigateNext className="ml8 fs18 fc1" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="w100">
                        <div className="fs14 fw4 mb24 c2 lh18">
                          Ensure seamless travel with included transfer details
                          for stress-free journeys.
                        </div>
                        {selectedTransfer && (
                          <TransferCard
                            transferData={selectedTransfer}
                            onTranferChangeClick={() =>
                              setTransferVisible(true)
                            }
                            ontransferChange={handleTransferChange}
                            onTransferDeleteClick={handleApiDeleteTransfer}
                          />
                        )}

                        {(selectedTransfer[0] === undefined ||
                          selectedTransfer[0]?.in_transit === "0") && (
                          <div className="select-card bg20 brd1 v-center">
                            <div
                              className="ls2 fc3 bg1 v-center pt8 pb8 pl16 pr16 select-button br24 mr24 cp"
                              onClick={handleTransferClick}
                            >
                              <p className="fs14">Select Transfer</p>
                            </div>

                            {selectedTransfer.length === 0 && (
                              <div
                                className="fc1 fs14 ls1 cp ml16 brd1 pt8 pb8 pl16 pr16 bg5 br24"
                                onClick={handleNoTransfer}
                              >
                                No Transfer
                              </div>
                            )}
                            {selectedTransfer.length === 0 && (
                              <div className="h48 v-center ml16 cp">
                                <p
                                  className="fs14 fc1"
                                  onClick={handleSameAsPreviousDayTransfer}
                                >
                                  {currentIndex > 0 &&
                                    `Same as Day ${currentIndex}`}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="df jce mt12">
                          <div
                            className="ls2 fc3 v-center select-button br8 jce cp"
                            onClick={handleNextButtonClick}
                          >
                            <p className="fs14 fc1">Next</p>
                            <MdNavigateNext className="ml8 fs18 fc1" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {selectedTab === "pricing" && (
            <PackagePricing
              tblLeadId={tblLeadId}
              nid={nid}
              renderNextButton={renderNextButton}
              renderBackButton={renderBackButton}
              toast={toast}
            />
          )}
          {selectedTab === "policies" && (
            <TextEditor
              tblLeadId={tblLeadId}
              nid={nid}
              renderBackButton={renderBackButton}
              toast={toast}
            />
          )}
          {selectedTab === "inclusion" && (
            <Inclusion
              tblLeadId={tblLeadId}
              nid={nid}
              renderNextButton={renderNextButton}
              renderBackButton={renderBackButton}
              toast={toast}
            />
          )}
        </div>

        <div className="box-center pr mt48 itinerary-buttons fww desk-publish">
          <button
            type="button"
            className="btn-cancel cp"
            onClick={handlePreview}
          >
            Preview
          </button>
          <button
            type="button"
            className="lead-buy-btn fc3 bg1 br24 fs14 cp pl24 pr24 pt10 pb10 mr8"
            onClick={handleSaveAsDraft}
          >
            Draft
          </button>

          <button
            type="button"
            className="lead-buy-btn fc3 bg1 br24 fs14 cp pl24 pr24 pt10 pb10"
            onClick={handlePublishData}
          >
            Publish
          </button>
        </div>
      </div>

      <div className="right-itinerary flx1 ml24 mt8 mr8 ">
        <div className=" brd1">
          <div className="bg19 fc14 p12 v-center jcsb">
            {editing ? (
              <>
                <span className="fs18 labels-heading fw4 fw4">
                  Itinerary Details
                </span>
              </>
            ) : (
              <>
                <span className="fs18 labels-heading fw4 fw4">
                  Enter Itinerary Details
                </span>
                <BiSolidPencil onClick={handleEditClick} className="cp" />
              </>
            )}
          </div>
          <div className="fc14 p12 v-center jcsb">
            <ul className="w100">
              <div className=" fs14 fw4 mb24 c2 lh16">
                Enter the details of your itinerary.
              </div>
              <li className="df fdc mb12 mt8 package-title">
                <label
                  className={`labels-heading ${
                    editing ? "fw4 fs14" : "fw6"
                  } fs14`}
                >
                  Title
                </label>
                {editing ? (
                  <div className="pkg-autocomplete pkg" ref={titleDownRef}>
                    <input
                      type="text"
                      name="title"
                      value={title}
                      onClick={handleTitleChange}
                      onChange={handleTitleChange}
                      onBlur={(e) =>
                        handleOnChangeUpdateData(e.target.value, "title")
                      }
                      autoComplete="off"
                      placeholder="Enter Itinerary title"
                    />
                    {suggestions.length > 0 && title && title.length > 0 && (
                      <div className="pkg-suggestions lh18">
                        {suggestions.map((suggestion, index) => (
                          <div
                            key={index}
                            className="pkg-suggestion-item"
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <span className="fs14 title-span ofh">{title}</span>
                )}
              </li>
              <li className="df fdc mb12 mt8">
                <label
                  className={`labels-heading ${
                    editing ? "fw4 fs14" : "fw6"
                  } fs14`}
                >
                  Itinerary Visibility
                </label>
                {editing ? (
                  <div className="pricing-dropdown">
                    <select
                      name="visibility"
                      value={selectPackageVisibility}
                      onChange={handleDropdownChange}
                    >
                      <option value="public">Public</option>
                      <option value="private">Private</option>
                    </select>
                  </div>
                ) : (
                  <span className="fs14">{selectPackageVisibility}</span>
                )}
              </li>
              <li className="df fdc mb12">
                <label
                  className={`labels-heading ${
                    editing ? "fw4 fs14" : "fw6"
                  } fs14`}
                >
                  Itinerary Type
                </label>
                {editing ? (
                  <div className="pricing-dropdown ">
                    <select
                      name="type"
                      value={selectPackageType}
                      onChange={handleDropdownChange}
                    >
                      <option value="FL">Flexible</option>
                      <option value="FI">Fixed</option>
                    </select>
                  </div>
                ) : (
                  <span className="fs14">{selectPackageType}</span>
                )}
              </li>
              <li className="df fdc mb12">
                <label
                  className={`labels-heading ${
                    editing ? "fw4 fs14" : "fw6"
                  } fs14`}
                >
                  Itinerary Duration
                </label>
                {editing ? (
                  <div className="pricing-dropdown ">
                    <select
                      name="duration"
                      value={duration}
                      onChange={handleDropdownChange}
                    >
                      <option value="2 Days/ 1 Nights">2D/1N</option>
                      <option value="3 Days/ 2 Nights">3D/2N</option>
                      <option value="4 Days/ 3 Nights">4D/3N</option>
                      <option value="5 Days/ 4 Nights">5D/4N</option>
                      <option value="6 Days/ 5 Nights">6D/5N</option>
                      <option value="7 Days/ 6 Nights">7D/6N</option>
                      <option value="8 Days/ 7 Nights">8D/7N</option>
                      <option value="9 Days/ 8 Nights">9D/8N</option>
                      <option value="10 Days/ 9 Nights">10D/9N</option>
                      <option value="11 Days/ 10 Nights">11D/10N</option>
                      <option value="12 Days/ 11 Nights">12D/11N</option>
                      <option value="13 Days/ 12 Nights">13D/12N</option>
                      <option value="14 Days/ 13 Nights">14D/13N</option>
                      <option value="15 Days/ 14 Nights">15D/14N</option>
                      <option value="16 Days/ 15 Nights">16D/15N</option>
                      <option value="17 Days/ 16 Nights">17D/16N</option>
                      <option value="18 Days/ 17 Nights">18D/17N</option>
                      <option value="19 Days/ 18 Nights">19D/18N</option>
                      <option value="20 Days/ 19 Nights">20D/19N</option>
                      <option value="21 Days/ 20 Nights">21D/20N</option>
                      <option value="22 Days/ 21 Nights">22D/21N</option>
                    </select>
                  </div>
                ) : (
                  <span className="fs14">{duration}</span>
                )}
              </li>
              <li className="df fdc mb12">
                <label
                  className={`labels-heading ${
                    editing ? "fw4 fs14" : "fw6"
                  } fs14`}
                >
                  Tour Destination
                </label>
                {editing ? (
                  <div className="pricing-dropdown ">
                    <SearchDropdown
                      setTourDestination={setTourDestination}
                      tourDestination={tourDestination}
                      onSelectionChange={handleTourDestinationChange}
                      optionsList={destinationOptions}
                    />
                  </div>
                ) : (
                  <span className="fs14">{tourDestination}</span>
                )}
              </li>

              <li className="df fdc mb12">
                <label
                  className={`labels-heading ${
                    editing ? "fw4 fs14" : "fw6"
                  } fs14`}
                >
                  Itinerary Theme
                </label>
                {editing ? (
                  <div className="pricing-dropdown ">
                    <SearchTheme
                      setTourDestination={setSelectedTheme}
                      tourDestination={selectedTheme}
                      onSelectionChange={handleThemeChange}
                      optionsList={themeOptions}
                    />
                  </div>
                ) : (
                  <span className="fs14">{selectedTheme}</span>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="box-center pr mt48 itinerary-buttons fww mobile-publish">
        <button type="button" className="btn-cancel cp" onClick={handlePreview}>
          Preview
        </button>
        <button
          type="button"
          className="lead-buy-btn fc3 bg1 br24 fs14 cp pl24 pr24 pt10 pb10 mr8"
          onClick={handleSaveAsDraft}
        >
          Draft
        </button>

        <button
          type="button"
          className="lead-buy-btn fc3 bg1 br24 fs14 cp pl24 pr24 pt10 pb10"
          onClick={handlePublishData}
        >
          Publish
        </button>
      </div>
    </div>
  );
};

export default CreateItinerary;
