import React, { useState, useEffect, useRef } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "../PackageBuilder/TextEditor.css";
import { FaPencilAlt } from "react-icons/fa";

import { useSelector } from 'react-redux';
const Inclusion = ({ tblLeadId, nid, renderNextButton,renderBackButton,toast  }) => {
  const [inclusion, setInclusion] = useState("");
  const [exclusion, setExclusion] = useState("");
  const [inclusionData, setInclusionData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const quillRef = useRef(null);
  const formRef = useRef(null);
  const user = useSelector((state) => state.auth);
  const [authId, setAuthID] = useState(user.auth_id);
  const [baseUrl, setBaseUrl] = useState(user.base_url);
  const [siteUrl, setSiteUrl] = useState(user.site_url);
  const handleEditButtonClick = (id) => {
    setIsEditing(true);
    const selectedPolicy = inclusionData.find((item) => item.id === id);
    if (selectedPolicy) {
      setInclusion(selectedPolicy.inclusion.join(""));
      setExclusion(selectedPolicy.exclusion.join(""));
    }
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleGetDeals = async (id) => {
    try {
      const requestBody = { id: id, auth_id: authId };
      const getDealsApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=deals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const dealDetailsData = await getDealsApiResponse.json();
      const inclusionExclusion =
        dealDetailsData.data.deal_object.inclusion_exclusion;

      setInclusionData([inclusionExclusion]);
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const handleAddButtonClick = async (e) => {
    e.preventDefault();
    if(!inclusion && !exclusion){
      toast.error("Please fill inclusion or exclusion.");
      return false;
    }


    try {
      const pkgDetailsApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=addorupdatedeals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            auth_id: authId,
            id: tblLeadId,
            inclusion_exclusion: {
              inclusion: [inclusion],
              exclusion: [exclusion],
            },
          }),
        }
      );

      const pkgDetails = await pkgDetailsApiResponse.json();
      handleGetDeals(tblLeadId);
      setInclusion("");
      setExclusion("");
      setIsEditing(false);
      if (quillRef.current) {
        quillRef.current.getEditor().setText("");
      }
    } catch (error) {
      console.error("API call error:", error);
    }
  };
  useEffect(() => {
    handleGetDeals(tblLeadId);
  }, [tblLeadId]);
  return (
    <div className="flx100 p12">
       {inclusionData.map(
        (item, index) =>{
          const isEmptyData =
          item.inclusion.length >0  &&
          item.exclusion.length >0;
           return isEmptyData &&(
            <div className="package-policies df jcsb" key={item.index}>
              <div className="mb16">
                {item.inclusion.length > 0 &&
                  item.inclusion[0] !== "<p><br></p>" && (
                    <>
                      <div className="title-policies v-center">
                        <h3 className="ls2 fs16 mb12 v-center fc1 fw5">
                          Inclusion
                        </h3>
                      </div>

                      <div
                        className="policies-description fs14"
                        dangerouslySetInnerHTML={{ __html: item.inclusion[0] }}
                      />
                    </>
                  )}
                {item.exclusion.length > 0 &&
                  item.exclusion[0] !== "<p><br></p>" && (
                    <>
                      <div className="exclusion mt24">
                        <h3 className="ls2 fs16 mb12 v-center fc1 fw5">
                          Exclusion
                        </h3>
                      </div>

                      <div
                        className="policies-description fs14"
                        dangerouslySetInnerHTML={{ __html: item.exclusion[0] }}
                      />
                    </>
                  )}
              </div>
              <div className="df jce">
                <FaPencilAlt
                  className="icon edit-icon mr8 cp"
                  onClick={() => handleEditButtonClick(item.id)}
                />
              </div>
            </div>
          )
        }
        
          
      )}

      <form className="bg5 br16" ref={formRef}>
        <div className="req-star">
          <label className="labels-heading fw6 fs14">
            Inclusion
          </label>
          <ReactQuill
            theme="snow"
            modules={{
              toolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
              ],
            }}
            formats={[
              "header",
              "height",
              "bold",
              "italic",
              "underline",
              "list",
              "color",
              "bullet",
              "indent",
              "link",
              "image",
              "align",
              "size",
            ]}
            placeholder="Add Inclusion"
            onChange={setInclusion}
            style={{ height: "150px", marginTop: "8px" }}
            ref={quillRef}
            value={inclusion}
          />
        </div>
        <div className="req-star mt40">
          <label className="labels-heading fw6 fs14">Exclusion</label>
          <ReactQuill
            theme="snow"
            modules={{
              toolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
              ],
            }}
            formats={[
              "header",
              "height",
              "bold",
              "italic",
              "underline",
              "list",
              "color",
              "bullet",
              "indent",
              "link",
              "image",
              "align",
              "size",
            ]}
            placeholder="Add Exclusion"
            onChange={setExclusion}
            style={{ height: "150px", marginTop: "8px" }}
            ref={quillRef}
            value={exclusion}
          />
        </div>
        <div className="df aic jcsb mt36">
          {renderBackButton()}
          <button
            className={`term-button br24 bg1 born cp fc3 v-center fs14`}
            onClick={handleAddButtonClick}
          >
            {isEditing ? "Update" : "Add"}
          </button>
          {renderNextButton()}
        </div>
      </form>
    </div>
  );
};

export default Inclusion;
