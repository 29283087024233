import React, { useState } from "react";
import "../components/InnerHeader.css";
import { Link } from "react-router-dom";
import trackEvent from "../utils/gaTracker";
import PlanPopup from "./Popup/PlanPopup";

const InnerHeader = ({
  heading,
  txtSubHeading,
  onClick,
  icon,
  iconText,
  leftContent,
  showButton,
  howitworksShow,
}) => {
  const [howItWorksVisible, setHowItWorksVisible] = useState(false);
  const handelClickItinenary = () => {
    trackEvent("My Itineraries Page ", "click", "Add Itineraries");
  };
  return (
    <>
      <div className="df aic pt8  inner-header fww">
        <div className="df fdc flx75">
          <div className="flx1 fs22 fw6 fc14 df">
            {leftContent}
            <h1 className="wsnw">{heading}</h1>
          </div>
          {!leftContent ? (
            <div className="fs14 fc5 fw4 mt8 lh18">
              {txtSubHeading
                ? txtSubHeading
                : "Unlocking possibilities: Explore the potential and personalization of 'My Itineraries'"}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="myteam-button v-center jce flx1">
          {showButton && !leftContent ? (
            onClick ? (
              <button onClick={onClick} className="btn-blue">
                {icon} <p className="ml12">{iconText}</p>
              </button>
            ) : howitworksShow ? (
              <p
                className="fc1 fs14 ls2 cp tdu"
                onClick={() => {
                  setHowItWorksVisible(true);
                  trackEvent(
                    "Buy Lead Page ",
                    "click",
                    "How It Works"
                  );
                }}
              >
                How It Works
              </p>
            ) : (
              <Link to="/package-builder">
                <button onClick={handelClickItinenary} className="btn-blue">
                  {icon} <p className="ml12">{iconText}</p>
                </button>
              </Link>
            )
          ) : (
            ""
          )}
          {howItWorksVisible && (
            <PlanPopup
              show={howItWorksVisible}
              onClose={() => {
                setHowItWorksVisible(false);
              }}
              className="hitworks-popup"
            >
              <div className="lead-how-it-works">
                <div className="v-center jcsb">
                  <p className="p16 fc1 fs18 tdu">How It Works</p>
                  <p
                    className="pr16 cp"
                    onClick={() => {
                      setHowItWorksVisible(false);
                    }}
                  >
                    X
                  </p>
                </div>
                <div className="how-works-step p16">
                  <div className="df brd-b1 fdc">
                    <div className="df">
                      <p className="fc1 fs14">1</p>
                      <span className="ml16">Set Up Your Account:</span>
                    </div>
                    <p className="ml24 lh22 fc5 mt16  pb16 fs14">
                      Begin by signing up and creating your Hellotravel admin
                      account. Choose the plan that best fits your specific
                      needs.
                    </p>
                  </div>
                  <div className="df brd-b1 fdc mt16">
                    <div className="df">
                      <p className="fc1 fs14">2</p>
                      <span className="ml16">Set Lead Preferences:</span>
                    </div>
                    <p className="ml24 lh22 fc5 mt16 pb16 fs14">
                      Customize your lead preferences to match your specific
                      requirements. Get leads automatically delivered to your
                      inbox, streamlining your lead acquisition process.
                    </p>
                  </div>
                  <div className="df brd-b1 fdc mt16">
                    <div className="df">
                      <p className="fc1 fs14">3</p>
                      <span className="ml16">Create Itineraries:</span>
                    </div>
                    <p className="ml24 lh22 fc5 mt16 pb16 fs14">
                      Craft both public and private itineraries and effortlessly
                      share them with potential travelers. Showcase your
                      expertise and entice your audience.
                    </p>
                  </div>
                  <div className="df fdc mt16">
                    <div className="df">
                      <p className="fc1 fs14">4</p>
                      <span className="ml16">Choose Leads:</span>
                    </div>
                    <p className="ml24 lh22 fc5 mt16  pb16 fs14">
                      Manual lead selection allows you to pick and purchase
                      leads that align with your current demands, ensuring
                      flexibility and control.
                    </p>
                  </div>
                </div>
              </div>
            </PlanPopup>
          )}
        </div>
      </div>
    </>
  );
};

export default InnerHeader;
