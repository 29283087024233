// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp-form {
    gap: 10px;
    margin-top: 36px;
  }
  
  .otp-input {
    width: 48px;
    height: 48px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  /* Add additional styling for focused input */
  .otp-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
   /* sanju Css */
.blink-text-normal-error {
  animation: blink 2s linear infinite;
  color: red;
  font-size: 14px;
  font-weight:500;
}

.blink-text-normal-success {
  animation: blink 2s linear infinite;
  color: green;
  font-size: 14px;
  font-weight:500;
}
`, "",{"version":3,"sources":["webpack://./src/components/Forms/OtpVerify.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;EACf;;EAEA,6CAA6C;EAC7C;IACE,qBAAqB;IACrB,0CAA0C;EAC5C;GACC,cAAc;AACjB;EACE,mCAAmC;EACnC,UAAU;EACV,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,mCAAmC;EACnC,YAAY;EACZ,eAAe;EACf,eAAe;AACjB","sourcesContent":[".otp-form {\r\n    gap: 10px;\r\n    margin-top: 36px;\r\n  }\r\n  \r\n  .otp-input {\r\n    width: 48px;\r\n    height: 48px;\r\n    text-align: center;\r\n    font-size: 18px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    outline: none;\r\n  }\r\n  \r\n  /* Add additional styling for focused input */\r\n  .otp-input:focus {\r\n    border-color: #007bff;\r\n    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);\r\n  }\r\n   /* sanju Css */\r\n.blink-text-normal-error {\r\n  animation: blink 2s linear infinite;\r\n  color: red;\r\n  font-size: 14px;\r\n  font-weight:500;\r\n}\r\n\r\n.blink-text-normal-success {\r\n  animation: blink 2s linear infinite;\r\n  color: green;\r\n  font-size: 14px;\r\n  font-weight:500;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
