const trackEvent = (category, action, label = "") => {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
    });
  };
  
  export default trackEvent;

  
  