const optionList = [
  {  value: "Manali", label: "Manali, India" },
  {  value: "Shimla", label: "Shimla, India" },
  {  value: "Kerala", label: "Kerala, India" },
  {  value: "Goa", label: "Goa, India" },
  {  value: "Munnar", label: "Munnar, India" },
  {  value: "Kufri", label: "Kufri, India" },
  {  value: "Thekkady", label: "Thekkady, India" },
  {  value: "Darjeeling", label: "Darjeeling, India" },
  {  value: "Ooty", label: "Ooty, India" },
  {  value: "Cochin", label: "Cochin, India" },
  {  value: "Alleppey", label: "Alleppey, India" },
  {  value: "Gangtok", label: "Gangtok, India" },
  {  value: "Kullu", label: "Kullu, India" },
  {  value: "Delhi", label: "Delhi, India" },
  {  value: "Chandigarh", label: "Chandigarh, India" },
  {  value: "Port Blair", label: "Port Blair, India" },
  {  value: "Coonoor", label: "Coonoor, India" },
  {  value: "Nainital", label: "Nainital, India" },
  {  value: "Mumbai", label: "Mumbai, India" },
  {  value: "Manikaran", label: "Manikaran, India" },
  {  value: "Coorg", label: "Coorg, India" },
  {  value: "Srinagar", label: "Srinagar, India" },
  {  value: "Sikkim", label: "Sikkim, India" },
  {  value: "Haridwar", label: "Haridwar, India" },
  {  value: "Rishikesh", label: "Rishikesh, India" },
  {  value: "Agra", label: "Agra, India" },
  {  value: "Jaipur", label: "Jaipur, India" },
  {  value: "Shillong", label: "Shillong, India" },
  {  value: "Panchgani", label: "Panchgani, India" },
  {  value: "Mysore", label: "Mysore, India" },
  {  value: "Mahabaleshwar", label: "Mahabaleshwar, India" },
  {  value: "Gulmarg", label: "Gulmarg, India" },
  {  value: "Katra", label: "Katra, India" },
  {  value: "Bangalore", label: "Bangalore, India" },
  {  value: "Guwahati", label: "Guwahati, India" },
  {  value: "Jammu", label: "Jammu, India" },
  {  value: "Dharamshala", label: "Dharamshala, India" },
  {  value: "Kedarnath", label: "Kedarnath, India" },
  {  value: "Kasol", label: "Kasol, India" },
  {  value: "Lonavala", label: "Lonavala, India" },
  {  value: "Pahalgam", label: "Pahalgam, India" },
  {  value: "Kolkata", label: "Kolkata, India" },
  {  value: "Dalhousie", label: "Dalhousie, India" },
  {  value: "Himachal Pradesh", label: "Himachal Pradesh, India" },
  {  value: "Ranikhet", label: "Ranikhet, India" },
  {  value: "Hyderabad", label: "Hyderabad, India" },
  {  value: "Kodaikanal", label: "Kodaikanal, India" },
  {  value: "Badrinath", label: "Badrinath, India" },
  {  value: "Udaipur", label: "Udaipur, India" },
  {  value: "Chennai", label: "Chennai, India" },
  {  value: "Uttarakhand", label: "Uttarakhand, India" },
  {  value: "Dehradun", label: "Dehradun, India" },
  {  value: "Shirdi", label: "Shirdi, India" },
  {  value: "Rajasthan", label: "Rajasthan, India" },
  {  value: "Lansdowne", label: "Lansdowne, India" },
  {  value: "Kanyakumari", label: "Kanyakumari, India" },
  {  value: "Pune", label: "Pune, India" },
  {  value: "Lakshadweep", label: "Lakshadweep, India" },
  {  value: "Amritsar", label: "Amritsar, India" },
  {  value: "Ahmedabad", label: "Ahmedabad, India" },
  {  value: "Tawang", label: "Tawang, India" },
  {  value: "Kochi", label: "Kochi, India" },
  {  value: "Kasauli", label: "Kasauli, India" },
  {  value: "Bengaluru", label: "Bengaluru, India" },
  {  value: "Mount Abu", label: "Mount Abu, India" },
  {  value: "Kalimpong", label: "Kalimpong, India" },
  {  value: "Mcleodganj", label: "Mcleodganj, India" },
  {  value: "Lakshadweep Islands", label: "Lakshadweep Islands, India" },
  {  value: "Pelling", label: "Pelling, India" },
  {  value: "Tirupati", label: "Tirupati, India" },
  {  value: "Matheran", label: "Matheran, India" },
  {  value: "Varanasi", label: "Varanasi, India" },
  {  value: "Auli", label: "Auli, India" },
  {  value: "Madurai", label: "Madurai, India" },
  {  value: "Kausani", label: "Kausani, India" },
  {  value: "Ajmer", label: "Ajmer, India" },
  {  value: "Wayanad", label: "Wayanad, India" },
  {  value: "Kaziranga", label: "Kaziranga, India" },
  {  value: "Coimbatore", label: "Coimbatore, India" },
  {  value: "Pondicherry", label: "Pondicherry, India" },
  {  value: "Madikeri", label: "Madikeri, India" },
  {  value: "Visakhapatnam", label: "Visakhapatnam, India" },
  {  value: "Gangotri", label: "Gangotri, India" },
  {  value: "Puri", label: "Puri, India" },
  {  value: "Jodhpur", label: "Jodhpur, India" },
  {  value: "Puducherry", label: "Puducherry, India" },
  {  value: "Somnath", label: "Somnath, India" },
  {  value: "Trivandrum", label: "Trivandrum, India" },
  {  value: "Lachung", label: "Lachung, India" },
  {  value: "Khajjiar", label: "Khajjiar, India" },
  {  value: "Dwarka", label: "Dwarka, India" },
  {  value: "Aurangabad", label: "Aurangabad, India" },
  {  value: "Mathura", label: "Mathura, India" },
  {  value: "Zuluk", label: "Zuluk, India" },
  {  value: "Nashik", label: "Nashik, India" },
  {  value: "Kumarakom", label: "Kumarakom, India" },
  {  value: "Saputara", label: "Saputara, India" },
  {  value: "Rameshwaram", label: "Rameshwaram, India" },
  {  value: "Patnitop", label: "Patnitop, India" },
  {  value: "Gujarat", label: "Gujarat, India" },
  {  value: "Vrindavan", label: "Vrindavan, India" },
  {  value: "Srisailam", label: "Srisailam, India" },
  {  value: "Jaisalmer", label: "Jaisalmer, India" },
  {  value: "Meghalaya", label: "Meghalaya, India" },
  {  value: "Yercaud", label: "Yercaud, India" },
  {  value: "Lachen", label: "Lachen, India" },
  {  value: "Solang Valley", label: "Solang Valley, India" },
  {  value: "Bhubaneswar", label: "Bhubaneswar, India" },
  {  value: "Ujjain", label: "Ujjain, India" },
  {  value: "Sangla", label: "Sangla, India" },
  {  value: "Rameswaram", label: "Rameswaram, India" },
  {  value: "Namchi", label: "Namchi, India" },
  {  value: "Almora", label: "Almora, India" },
  {  value: "Nagpur", label: "Nagpur, India" },
  {  value: "Mahabalipuram", label: "Mahabalipuram, India" },
  {  value: "Ratnagiri", label: "Ratnagiri, India" },
  {  value: "Pushkar", label: "Pushkar, India" },
  {  value: "Nandi Hills", label: "Nandi Hills, India" },
  {  value: "Shiv Khori", label: "Shiv Khori, India" },
  {  value: "Assam", label: "Assam, India" },
  {  value: "Chopta", label: "Chopta, India" },
  {  value: "Hampi", label: "Hampi, India" },
  {  value: "Lavasa", label: "Lavasa, India" },
  {  value: "Gokarna", label: "Gokarna, India" },
  {  value: "Alappuzha", label: "Alappuzha, India" },
  {  value: "Arunachal Pradesh", label: "Arunachal Pradesh, India" },
  {  value: "Dhanaulti", label: "Dhanaulti, India" },
  {  value: "Bikaner", label: "Bikaner, India" },
  {  value: "Kovalam", label: "Kovalam, India" },
  {  value: "Leh", label: "Leh, India" },
  {  value: "Bagdogra", label: "Bagdogra, India" },
  {  value: "Ladakh", label: "Ladakh, India" },
  {  value: "Havelock Island", label: "Havelock Island, India" },
  {  value: "Corbett", label: "Corbett, India" },
  {
     value: "Andaman And Nicobar Islands",
    label: "Andaman And Nicobar Islands, India",
  },
  {  value: "Kathgodam", label: "Kathgodam, India" },
  {  value: "Sonmarg", label: "Sonmarg, India" },
  {  value: "Bomdila", label: "Bomdila, India" },
  {  value: "Cherrapunjee", label: "Cherrapunjee, India" },
  {  value: "Cherrapunji", label: "Cherrapunji, India" },
  {  value: "Rohtang", label: "Rohtang, India" },
  {  value: "Bhalukpong", label: "Bhalukpong, India" },
  {  value: "Karnataka", label: "Karnataka, India" },
  {  value: "Jammu And Kashmir", label: "Jammu And Kashmir, India" },
  {  value: "Pachmarhi", label: "Pachmarhi, India" },
  {  value: "Agatti Island", label: "Agatti Island, India" },
  {  value: "Dirang", label: "Dirang, India" },
  {  value: "Triund", label: "Triund, India" },
  {  value: "Lucknow", label: "Lucknow, India" },
  {  value: "Baratang", label: "Baratang, India" },
  {  value: "Panjim", label: "Panjim, India" },
  {  value: "Palampur", label: "Palampur, India" },
  {  value: "Chail", label: "Chail, India" },
  {  value: "Yamunotri", label: "Yamunotri, India" },
  {  value: "Sillery Gaon", label: "Sillery Gaon, India" },
  {  value: "Naggar", label: "Naggar, India" },
  {  value: "Tosh", label: "Tosh, India" },
  {  value: "Maharashtra", label: "Maharashtra, India" },
  {  value: "Tamil Nadu", label: "Tamil Nadu, India" },
  {  value: "Aritar", label: "Aritar, India" },
  {  value: "Reshikhola", label: "Reshikhola, India" },
  {  value: "Kalpa", label: "Kalpa, India" },
  {  value: "Dawki", label: "Dawki, India" },
  {  value: "Sarahan", label: "Sarahan, India" },
  {  value: "Nathang Valley", label: "Nathang Valley, India" },
  {  value: "Rudraprayag", label: "Rudraprayag, India" },
  {  value: "Rajkot", label: "Rajkot, India" },
  {  value: "Bhagsunag", label: "Bhagsunag, India" },
  {  value: "Mawlynnong", label: "Mawlynnong, India" },
  {  value: "Diu", label: "Diu, India" },
  {  value: "Kheerganga", label: "Kheerganga, India" },
  {  value: "Rampur", label: "Rampur, India" },
  {  value: "Kalpeni Island", label: "Kalpeni Island, India" },
  {  value: "Goa Velha", label: "Goa Velha, India" },
  {  value: "Patna", label: "Patna, India" },
  {  value: "Indore", label: "Indore, India" },
  {  value: "Mashobra", label: "Mashobra, India" },
  {  value: "Kavarathi", label: "Kavarathi, India" },
  {  value: "Chikmagalur", label: "Chikmagalur, India" },
  {  value: "North Bay Island", label: "North Bay Island, India" },
  {  value: "Joshimath", label: "Joshimath, India" },
  {  value: "Nasik", label: "Nasik, India" },
  {  value: "Taacharu", label: "Taacharu, India" },
  {  value: "West Bengal", label: "West Bengal, India" },
  {  value: "Vizag", label: "Vizag, India" },
  {  value: "Varca", label: "Varca, India" },
  {  value: "Chamba", label: "Chamba, India" },
  {  value: "Malvan", label: "Malvan, India" },
  {  value: "Ramnagar", label: "Ramnagar, India" },
  {  value: "Gopalpur", label: "Gopalpur, India" },
  {  value: "Binsar", label: "Binsar, India" },
  {  value: "Sarchu", label: "Sarchu, India" },
  {  value: "Bhopal", label: "Bhopal, India" },
  {  value: "Barkot", label: "Barkot, India" },
  {  value: "Malana", label: "Malana, India" },
  {  value: "Bangaram Island", label: "Bangaram Island, India" },
  {  value: "Gurgaon", label: "Gurgaon, India" },
  {  value: "Bhavnagar", label: "Bhavnagar, India" },
  {  value: "Mirik", label: "Mirik, India" },
  {  value: "Calangute", label: "Calangute, India" },
  {  value: "North Goa", label: "North Goa, India" },
  {  value: "Brahampur", label: "Brahampur, India" },
  {  value: "Daringbadi", label: "Daringbadi, India" },
  {  value: "Jamnagar", label: "Jamnagar, India" },
  {  value: "Kangra Valley", label: "Kangra Valley, India" },
  {  value: "Mysuru", label: "Mysuru, India" },
  {  value: "Kaza", label: "Kaza, India" },
  {  value: "Udupi", label: "Udupi, India" },
  {  value: "Siliguri", label: "Siliguri, India" },
  {  value: "Tamabil", label: "Tamabil, India" },
  {  value: "Sandakphu", label: "Sandakphu, India" },
  {  value: "Surat", label: "Surat, India" },
  {  value: "South Goa", label: "South Goa, India" },
  {  value: "Devprayag", label: "Devprayag, India" },
  {  value: "Neil Island", label: "Neil Island, India" },
  {  value: "Thane", label: "Thane, India" },
  {  value: "Pratapgarh", label: "Pratapgarh, India" },
  {  value: "Keylong", label: "Keylong, India" },
  {  value: "Trichy", label: "Trichy, India" },
  {  value: "Allahabad", label: "Allahabad, India" },
  {  value: "Vadodara", label: "Vadodara, India" },
  {  value: "Nicobar", label: "Nicobar, India" },
  {  value: "Rajgir", label: "Rajgir, India" },
  {  value: "Anjuna", label: "Anjuna, India" },
  {  value: "Baga", label: "Baga, India" },
  {  value: "Jabalpur", label: "Jabalpur, India" },
  {  value: "Khandala", label: "Khandala, India" },
  {  value: "Nako", label: "Nako, India" },
  {  value: "Phalut", label: "Phalut, India" },
  {  value: "Uttarkashi", label: "Uttarkashi, India" },
  {  value: "Amarnath", label: "Amarnath, India" },
  {  value: "Araku Valley", label: "Araku Valley, India" },
  {  value: "Alibag", label: "Alibag, India" },
  {  value: "Noida", label: "Noida, India" },
  {  value: "Mangalore", label: "Mangalore, India" },
  {  value: "Bangkok", label: "Bangkok, Thailand" },
  {  value: "Koh Samui", label: "Koh Samui, Thailand" },
  {  value: "Thiruvananthapuram", label: "Thiruvananthapuram, India" },
  {  value: "Imphal", label: "Imphal, India" },
  {  value: "Dapoli", label: "Dapoli, India" },
  {  value: "Kibber", label: "Kibber, India" },
  {  value: "Ganpatipule", label: "Ganpatipule, India" },
  {  value: "Pratapgad", label: "Pratapgad, India" },
  {  value: "Pathankot", label: "Pathankot, India" },
  {  value: "Tuljapur", label: "Tuljapur, India" },
  {  value: "Konark", label: "Konark, India" },
  {  value: "Tehri", label: "Tehri, India" },
  {  value: "Akkalkot", label: "Akkalkot, India" },
  {  value: "Rackcham", label: "Rackcham, India" },
  {  value: "Daman", label: "Daman, India" },
  {  value: "Pandharpur", label: "Pandharpur, India" },
  {  value: "Chamoli", label: "Chamoli, India" },
  {  value: "Ranakpur", label: "Ranakpur, India" },
  {  value: "Nubra", label: "Nubra, India" },
  {  value: "Yumthang", label: "Yumthang, India" },
  {  value: "Digha", label: "Digha, India" },
  {  value: "Bhimtal", label: "Bhimtal, India" },
  {  value: "Munsiyari", label: "Munsiyari, India" },
  {  value: "Chittorgarh", label: "Chittorgarh, India" },
  {  value: "Yelagiri", label: "Yelagiri, India" },
  {  value: "Karjat", label: "Karjat, India" },
  {  value: "Khajuraho", label: "Khajuraho, India" },
  {  value: "Nahan", label: "Nahan, India" },
  {  value: "Pipariya", label: "Pipariya, India" },
  {  value: "Mangaluru", label: "Mangaluru, India" },
  {  value: "Ghaziabad", label: "Ghaziabad, India" },
  {  value: "Naldehra", label: "Naldehra, India" },
  {  value: "Vengurla", label: "Vengurla, India" },
  {  value: "Kanchipuram", label: "Kanchipuram, India" },
  {  value: "Santhanpara", label: "Santhanpara, India" },
  {  value: "Andhra Pradesh", label: "Andhra Pradesh, India" },
  {  value: "Kanpur", label: "Kanpur, India" },
  {  value: "Bharatpur", label: "Bharatpur, India" },
  {  value: "Dharmasthala", label: "Dharmasthala, India" },
  {  value: "Ravangla", label: "Ravangla, India" },
  {  value: "Kumbhalgarh", label: "Kumbhalgarh, India" },
  {  value: "Trimbakeshwar", label: "Trimbakeshwar, India" },
  {  value: "Kotdwara", label: "Kotdwara, India" },
  {  value: "Badami", label: "Badami, India" },
  {  value: "Kollur", label: "Kollur, India" },
  {  value: "Gurugram", label: "Gurugram, India" },
  {  value: "Guptkashi", label: "Guptkashi, India" },
  {  value: "Kargil", label: "Kargil, India" },
  {  value: "Kumbakonam", label: "Kumbakonam, India" },
  {  value: "Raipur", label: "Raipur, India" },
  {  value: "Bir", label: "Bir, India" },
  {  value: "Pedong", label: "Pedong, India" },
  {  value: "Jhansi", label: "Jhansi, India" },
  {  value: "Bodhgaya", label: "Bodhgaya, India" },
  {  value: "Omkareshwar", label: "Omkareshwar, India" },
  {  value: "Ranthambore", label: "Ranthambore, India" },
  {  value: "Bandipur", label: "Bandipur, India" },
  {  value: "Kohima", label: "Kohima, India" },
  {  value: "Ludhiana", label: "Ludhiana, India" },
  {  value: "Nalanda", label: "Nalanda, India" },
  {  value: "Island", label: "Island, India" },
  {  value: "Lataguri", label: "Lataguri, India" },
  {  value: "Mussoorie", label: "Mussoorie, India" },
  {  value: "Dabolim", label: "Dabolim, India" },
  {  value: "Barsaini", label: "Barsaini, India" },
  {  value: "Fatehpur Sikri", label: "Fatehpur Sikri, India" },
  {  value: "Kanatal", label: "Kanatal, India" },
  {  value: "Ranchi", label: "Ranchi, India" },
  {  value: "Sundarban", label: "Sundarban, India" },
  {  value: "Kanha National Park", label: "Kanha National Park, India" },
  {  value: "Mandarmani", label: "Mandarmani, India" },
  {  value: "Faridabad", label: "Faridabad, India" },
  {  value: "Poovar", label: "Poovar, India" },
  {  value: "Kundalpur", label: "Kundalpur, India" },
  {  value: "Gunawaji", label: "Gunawaji, India" },
  {  value: "Pawapuri", label: "Pawapuri, India" },
  {  value: "Parasnath", label: "Parasnath, India" },
  {  value: "Madhya Pradesh", label: "Madhya Pradesh, India" },
  {  value: "Navi Mumbai", label: "Navi Mumbai, India" },
  {  value: "Kabini", label: "Kabini, India" },
  {  value: "Gwalior", label: "Gwalior, India" },
  {  value: "Aamby Valley", label: "Aamby Valley, India" },
  {  value: "Mandi", label: "Mandi, India" },
  {  value: "Calicut", label: "Calicut, India" },
  {  value: "Rishikhola", label: "Rishikhola, India" },
  {  value: "Rishop", label: "Rishop, India" },
  {  value: "Khardungla", label: "Khardungla, India" },
  {  value: "Junagadh", label: "Junagadh, India" },
  {  value: "Mauritius", label: "Mauritius, India" },
  {  value: "Gaya", label: "Gaya, India" },
  {  value: "Dharamkot", label: "Dharamkot, India" },
  {  value: "Horanadu", label: "Horanadu, India" },
  {  value: "Moreh", label: "Moreh, India" },
  {  value: "Dimapur", label: "Dimapur, India" },
  {  value: "Loktak", label: "Loktak, India" },
  {  value: "Secunderabad", label: "Secunderabad, India" },
  {  value: "Igatpuri", label: "Igatpuri, India" },
  {  value: "Lepchajagat Village", label: "Lepchajagat Village, India" },
  {  value: "Bangaram", label: "Bangaram, India" },
  {  value: "Odisha", label: "Odisha, India" },
  {  value: "Palani", label: "Palani, India" },
  {  value: "Sar Pass", label: "Sar Pass, India" },
  {  value: "Kota", label: "Kota, India" },
  {  value: "Mukteshwar", label: "Mukteshwar, India" },
  {  value: "Kangra", label: "Kangra, India" },
  {  value: "Periyar", label: "Periyar, India" },
  {  value: "Howrah", label: "Howrah, India" },
  {  value: "Tanjore", label: "Tanjore, India" },
  {  value: "Anegundi", label: "Anegundi, India" },
  {  value: "Kolhapur", label: "Kolhapur, India" },
  {  value: "Hansi", label: "Hansi, India" },
  {  value: "Guruvayoor", label: "Guruvayoor, India" },
  {  value: "Ambaji", label: "Ambaji, India" },
  {  value: "Palakkad", label: "Palakkad, India" },
  {  value: "Sringeri", label: "Sringeri, India" },
  {  value: "Srirangam", label: "Srirangam, India" },
  {  value: "Horsley Hills", label: "Horsley Hills, India" },
  {  value: "Rohtak", label: "Rohtak, India" },
  {  value: "Tezpur", label: "Tezpur, India" },
  {  value: "Gadiara", label: "Gadiara, India" },
  {  value: "Anjarle", label: "Anjarle, India" },
  {  value: "Chalal", label: "Chalal, India" },
  {  value: "Bhuj", label: "Bhuj, India" },
  {  value: "Vijayawada", label: "Vijayawada, India" },
  {  value: "Rasol", label: "Rasol, India" },
  {  value: "Manirarn", label: "Manirarn, India" },
  {  value: "Fagu", label: "Fagu, India" },
  {  value: "Thanjavur", label: "Thanjavur, India" },
  {  value: "Bodh Gaya", label: "Bodh Gaya, India" },
  {  value: "Punjab", label: "Punjab, India" },
  {  value: "Kushalnagar", label: "Kushalnagar, India" },
  {  value: "Chopta Valley", label: "Chopta Valley, India" },
  {  value: "Gurudongmar", label: "Gurudongmar, India" },
  {  value: "Tumling", label: "Tumling, India" },
  {  value: "Andaman And Nicobar", label: "Andaman And Nicobar, India" },
  {  value: "Gandhinagar", label: "Gandhinagar, India" },
  {  value: "Paro", label: "Paro, Bhutan" },
  {  value: "Genting Highlands", label: "Genting Highlands, Malaysia" },
  {  value: "Male", label: "Male, Maldives" },
  {  value: "Joshinath", label: "Joshinath, India" },
  {  value: "Gir", label: "Gir, India" },
  {  value: "Kushinagar", label: "Kushinagar, India" },
  {  value: "Raichur", label: "Raichur, India" },
  {  value: "Gangasagar", label: "Gangasagar, India" },
  {  value: "Ramanagaram", label: "Ramanagaram, India" },
  {  value: "Gorakhpur", label: "Gorakhpur, India" },
  {  value: "Ayodhya", label: "Ayodhya, India" },
  {  value: "Lumbini", label: "Lumbini, India" },
  {  value: "Agartala", label: "Agartala, India" },
  {  value: "Uttar Pradesh", label: "Uttar Pradesh, India" },
  {  value: "Kelshi", label: "Kelshi, India" },
  {  value: "Jamshedpur", label: "Jamshedpur, India" },
  {  value: "Panvel", label: "Panvel, India" },
  {  value: "Pithapuram", label: "Pithapuram, India" },
  {  value: "Samalkot", label: "Samalkot, India" },
  {  value: "Karwar", label: "Karwar, India" },
  {  value: "Vellore", label: "Vellore, India" },
  {  value: "Chitrakoot", label: "Chitrakoot, India" },
  {  value: "Raichak", label: "Raichak, India" },
  {  value: "Jalandhar", label: "Jalandhar, India" },
  {  value: "Dibrugarh", label: "Dibrugarh, India" },
  {  value: "Shivpuri", label: "Shivpuri, India" },
  {  value: "Orchha", label: "Orchha, India" },
  {  value: "Chittoor", label: "Chittoor, India" },
  {  value: "Warangal", label: "Warangal, India" },
  {  value: "Chakrata", label: "Chakrata, India" },
  {  value: "Bijapur", label: "Bijapur, India" },
  {  value: "Chikkamagaluru", label: "Chikkamagaluru, India" },
  {  value: "Delwara", label: "Delwara, India" },
  {  value: "Porbandar", label: "Porbandar, India" },
  {  value: "Dervan", label: "Dervan, India" },
  {  value: "Shivamogga", label: "Shivamogga, India" },
  {  value: "Tarakeswar", label: "Tarakeswar, India" },
  {  value: "Silvassa", label: "Silvassa, India" },
  {  value: "Vaishali", label: "Vaishali, India" },
  {  value: "Hadimba", label: "Hadimba, India" },
  {  value: "Sadhupul", label: "Sadhupul, India" },
  {  value: "Shrivardhan", label: "Shrivardhan, India" },
  {  value: "Durshet", label: "Durshet, India" },
  {  value: "Lolegaon", label: "Lolegaon, India" },
  {  value: "Solan", label: "Solan, India" },
  {  value: "Nameri", label: "Nameri, India" },
  {  value: "Orissa", label: "Orissa, India" },
  {  value: "Kozhikode", label: "Kozhikode, India" },
  {  value: "Neemrana", label: "Neemrana, India" },
  {  value: "Sabarimala", label: "Sabarimala, India" },
  {  value: "Himalayas", label: "Himalayas, India" },
  {  value: "Haryana", label: "Haryana, India" },
  {  value: "Nanded", label: "Nanded, India" },
  {  value: "Sasan", label: "Sasan, India" },
  {  value: "Courtallam", label: "Courtallam, India" },
  {  value: "Ernakulam", label: "Ernakulam, India" },
  {  value: "Amboli", label: "Amboli, India" },
  {  value: "Jispa", label: "Jispa, India" },
  {  value: "Yumthang Valley", label: "Yumthang Valley, India" },
  {  value: "Hemalkasa", label: "Hemalkasa, India" },
  {  value: "Bareilly", label: "Bareilly, India" },
  {  value: "New Jalpaiguri", label: "New Jalpaiguri, India" },
  {  value: "Maheshwar", label: "Maheshwar, India" },
  {  value: "Bhangarh", label: "Bhangarh, India" },
  {  value: "Tarkarli", label: "Tarkarli, India" },
  {  value: "Tabo", label: "Tabo, India" },
  {  value: "Roychak", label: "Roychak, India" },
  {  value: "Velankanni", label: "Velankanni, India" },
  {  value: "Santiniketan", label: "Santiniketan, India" },
  {  value: "Kinnaur", label: "Kinnaur, India" },
  {  value: "Varkala", label: "Varkala, India" },
  {  value: "Chilka", label: "Chilka, India" },
  {  value: "Harihareshwar", label: "Harihareshwar, India" },
  {  value: "Ramoji", label: "Ramoji, India" },
  {  value: "Parambikulam", label: "Parambikulam, India" },
  {  value: "Thirunallur", label: "Thirunallur, India" },
  {  value: "Kanchanoor", label: "Kanchanoor, India" },
  {  value: "Keezhperumpallam", label: "Keezhperumpallam, India" },
  {  value: "Nathula", label: "Nathula, India" },
  {  value: "Chungthang", label: "Chungthang, India" },
  {  value: "Bheemeshwari", label: "Bheemeshwari, India" },
  {  value: "Tiruvannamalai", label: "Tiruvannamalai, India" },
  {  value: "Mangan", label: "Mangan, India" },
  {  value: "Thimphu", label: "Thimphu, Bhutan" },
  {  value: "Wayyand", label: "Wayyand, India" },
  {  value: "Andro", label: "Andro, India" },
  {  value: "Panaji", label: "Panaji, India" },
  {  value: "Murdeshwar", label: "Murdeshwar, India" },
  {  value: "Nagarhole", label: "Nagarhole, India" },
  {  value: "Dooars", label: "Dooars, India" },
  {  value: "Chidambaram", label: "Chidambaram, India" },
  {  value: "Jagdalpur", label: "Jagdalpur, India" },
  {  value: "Bilaspur", label: "Bilaspur, India" },
  {  value: "Sawai Madhopur", label: "Sawai Madhopur, India" },
  {  value: "Gondal", label: "Gondal, India" },
  {  value: "Meerut", label: "Meerut, India" },
  {  value: "Giridih", label: "Giridih, India" },
  {  value: "Dandeli", label: "Dandeli, India" },
  {  value: "Mandu", label: "Mandu, India" },
  {  value: "Minicoy Island", label: "Minicoy Island, India" },
  {  value: "Anandvan", label: "Anandvan, India" },
  {  value: "Hassan", label: "Hassan, India" },
  {  value: "Manebhanjyang", label: "Manebhanjyang, India" },
  {  value: "Kalapokhri", label: "Kalapokhri, India" },
  {  value: "Sasan Gir", label: "Sasan Gir, India" },
  {  value: "Sillerygaon", label: "Sillerygaon, India" },
  {  value: "Alwar", label: "Alwar, India" },
  {  value: "Kavaratti Island", label: "Kavaratti Island, India" },
  {  value: "Loleygaon", label: "Loleygaon, India" },
  {  value: "Murudeshwar", label: "Murudeshwar, India" },
  {  value: "Kamshet", label: "Kamshet, India" },
  {  value: "Deoghar", label: "Deoghar, India" },
  {  value: "Bihar", label: "Bihar, India" },
  {  value: "Silerygaon", label: "Silerygaon, India" },
  {  value: "Govindghat", label: "Govindghat, India" },
  {  value: "Ganeshgudi", label: "Ganeshgudi, India" },
  {  value: "Bhandardara", label: "Bhandardara, India" },
  {  value: "Sham Valley", label: "Sham Valley, India" },
  {  value: "Jaldapara", label: "Jaldapara, India" },
  {  value: "Patiala", label: "Patiala, India" },
  {  value: "Subramanya", label: "Subramanya, India" },
  {  value: "Kalyan", label: "Kalyan, India" },
  {  value: "Murud Harnai", label: "Murud Harnai, India" },
  {  value: "Solangnala", label: "Solangnala, India" },
  {  value: "Sawantvadi", label: "Sawantvadi, India" },
  {  value: "Tiruchendur", label: "Tiruchendur, India" },
  {  value: "Virpur", label: "Virpur, India" },
  {  value: "Langkawi", label: "Langkawi, India" },
  {  value: "Phuentsholing", label: "Phuentsholing, India" },
  {  value: "Baltal", label: "Baltal, India" },
  {  value: "Moradabad", label: "Moradabad, India" },
  {  value: "Hazur Sahib", label: "Hazur Sahib, India" },
  {  value: "Sasangir", label: "Sasangir, India" },
  {  value: "Sagar", label: "Sagar, India" },
  {  value: "Nainadevi", label: "Nainadevi, India" },
  {  value: "Barsheni", label: "Barsheni, India" },
  {  value: "Patliputra", label: "Patliputra, India" },
  {  value: "Thrissur", label: "Thrissur, India" },
  {  value: "Haldwani", label: "Haldwani, India" },
  {  value: "Jhandi", label: "Jhandi, India" },
  {  value: "Pasighat", label: "Pasighat, India" },
  {  value: "Ziro", label: "Ziro, India" },
  {  value: "Chhindwara", label: "Chhindwara, India" },
  {  value: "Pangot", label: "Pangot, India" },
  {  value: "Tirunelveli", label: "Tirunelveli, India" },
  {  value: "Ajanta", label: "Ajanta, India" },
  {  value: "Chintpurni", label: "Chintpurni, India" },
  {  value: "Tsomgolake", label: "Tsomgolake, India" },
  {  value: "Murshidabad", label: "Murshidabad, India" },
  {  value: "Mayapur", label: "Mayapur, India" },
  {  value: "Kutch", label: "Kutch, India" },
  {  value: "Neelkanth", label: "Neelkanth, India" },
  {  value: "Ghangaria", label: "Ghangaria, India" },
  {  value: "Hisar", label: "Hisar, India" },
  {  value: "Tiruttani", label: "Tiruttani, India" },
  {  value: "Dabhol Bandar", label: "Dabhol Bandar, India" },
  {  value: "Chiplun", label: "Chiplun, India" },
  {  value: "Tirupathi", label: "Tirupathi, India" },
  {  value: "Ambala", label: "Ambala, India" },
  {  value: "Mandvi", label: "Mandvi, India" },
  {  value: "Rajahmundry", label: "Rajahmundry, India" },
  {  value: "Cuttack", label: "Cuttack, India" },
  {  value: "Amravati", label: "Amravati, India" },
  {  value: "Changu", label: "Changu, India" },
  {  value: "Dhanolti", label: "Dhanolti, India" },
  {  value: "Shivthar Ghal", label: "Shivthar Ghal, India" },
  {  value: "Narkanda", label: "Narkanda, India" },
  {  value: "Belgaum", label: "Belgaum, India" },
  {  value: "Daporijo", label: "Daporijo, India" },
  {  value: "Mlenyadri", label: "Mlenyadri, India" },
  {  value: "Tadoba", label: "Tadoba, India" },
  {  value: "Mandawa", label: "Mandawa, India" },
  {  value: "Parwanoo", label: "Parwanoo, India" },
  {  value: "Vagamon", label: "Vagamon, India" },
  {  value: "Kottayam", label: "Kottayam, India" },
  {  value: "Palitana", label: "Palitana, India" },
  {  value: "Dantewada", label: "Dantewada, India" },
  {  value: "Viper Island", label: "Viper Island, India" },
  {  value: "Kadmat", label: "Kadmat, India" },
  {  value: "Roorkee", label: "Roorkee, India" },
  {  value: "Pokhara", label: "Pokhara, India" },
  {  value: "Bhubaneshwar", label: "Bhubaneshwar, India" },
  {  value: "Rameshewaram", label: "Rameshewaram, India" },
  {  value: "Jharkhand", label: "Jharkhand, India" },
  {  value: "Nathang", label: "Nathang, India" },
  {  value: "Manipal", label: "Manipal, India" },
  {  value: "Kalpetta", label: "Kalpetta, India" },
  {  value: "Salem", label: "Salem, India" },
  {  value: "Jayanti", label: "Jayanti, India" },
  {  value: "Mawsynram", label: "Mawsynram, India" },
  {  value: "Guntur", label: "Guntur, India" },
  {  value: "Bhaderwah", label: "Bhaderwah, India" },
  {  value: "Gulbarga", label: "Gulbarga, India" },
  {  value: "Silchar", label: "Silchar, India" },
  {  value: "Nagaland", label: "Nagaland, India" },
  {  value: "Kannur", label: "Kannur, India" },
  {  value: "Agumbe", label: "Agumbe, India" },
  {  value: "Konkan", label: "Konkan, India" },
  {  value: "Solapur", label: "Solapur, India" },
  {  value: "Lepchajagat", label: "Lepchajagat, India" },
  {  value: "Karnal", label: "Karnal, India" },
  {  value: "Baratang Island", label: "Baratang Island, India" },
  {  value: "Timber Trail", label: "Timber Trail, India" },
  {  value: "Tsomgo", label: "Tsomgo, India" },
  {  value: "Nagar", label: "Nagar, India" },
  {  value: "Bhuntar", label: "Bhuntar, India" },
  {  value: "Nanpara", label: "Nanpara, India" },
  {  value: "Bhilwara", label: "Bhilwara, India" },
  {  value: "Aligarh", label: "Aligarh, India" },
  {  value: "Harsil", label: "Harsil, India" },
  {  value: "Srikhola", label: "Srikhola, India" },
  {  value: "Tonglu", label: "Tonglu, India" },
  {  value: "Nathdwara", label: "Nathdwara, India" },
  {  value: "Asansol", label: "Asansol, India" },
  {  value: "Manipur", label: "Manipur, India" },
  {  value: "Sanchi", label: "Sanchi, India" },
  {  value: "Manebhanjan", label: "Manebhanjan, India" },
  {  value: "Bandhavgarh", label: "Bandhavgarh, India" },
  {  value: "Tirathgarh", label: "Tirathgarh, India" },
  {  value: "Tsomoriri", label: "Tsomoriri, India" },
  {  value: "Bhedaghat", label: "Bhedaghat, India" },
  {  value: "Pithoragarh", label: "Pithoragarh, India" },
  {  value: "Kurukshetra", label: "Kurukshetra, India" },
  {  value: "Bhitarkanika", label: "Bhitarkanika, India" },
  {  value: "Hubli", label: "Hubli, India" },
  {  value: "Abbey Falls", label: "Abbey Falls, India" },
  {  value: "Chitwan", label: "Chitwan, India" },
  {  value: "Suryanelli", label: "Suryanelli, India" },
  {  value: "Hospet", label: "Hospet, India" },
  {  value: "Vapi", label: "Vapi, India" },
  {  value: "Banaras", label: "Banaras, India" },
  {  value: "Khopoli", label: "Khopoli, India" },
  {  value: "Biling", label: "Biling, India" },
  {  value: "Sonprayag", label: "Sonprayag, India" },
  {  value: "Kalka", label: "Kalka, India" },
  {  value: "Kollam", label: "Kollam, India" },
  {  value: "Chandrapur", label: "Chandrapur, India" },
  {  value: "Athirapally", label: "Athirapally, India" },
  {  value: "Bhojbasa", label: "Bhojbasa, India" },
  {  value: "Mohali", label: "Mohali, India" },
  {  value: "Ankola", label: "Ankola, India" },
  {  value: "Parvati", label: "Parvati, India" },
  {  value: "Dhanbad", label: "Dhanbad, India" },
  {  value: "Govind Ghat", label: "Govind Ghat, India" },
  {  value: "Thinnakara", label: "Thinnakara, India" },
  {  value: "Muzaffarnagar", label: "Muzaffarnagar, India" },
  {  value: "Bhadrachalam", label: "Bhadrachalam, India" },
  {  value: "Udipi", label: "Udipi, India" },
  {  value: "Spiti Valley", label: "Spiti Valley, India" },
  {  value: "Shimoga", label: "Shimoga, India" },
  {  value: "Jalpaiguri", label: "Jalpaiguri, India" },
  {  value: "Rudrapur", label: "Rudrapur, India" },
  {  value: "Bhowali", label: "Bhowali, India" },
  {  value: "Diveagar", label: "Diveagar, India" },
  {  value: "Hogenakkal", label: "Hogenakkal, India" },
  {  value: "Pattadakal", label: "Pattadakal, India" },
  {  value: "Bidar", label: "Bidar, India" },
  {  value: "Chikhaldara", label: "Chikhaldara, India" },
  {  value: "Akshardham", label: "Akshardham, India" },
  {  value: "Durg", label: "Durg, India" },
  {  value: "Bhilai", label: "Bhilai, India" },
  {  value: "Guruvayur", label: "Guruvayur, India" },
  {  value: "Aihole", label: "Aihole, India" },
  {  value: "Tiruchirappalli", label: "Tiruchirappalli, India" },
  {  value: "Yusmarg", label: "Yusmarg, India" },
  {  value: "Jorhat", label: "Jorhat, India" },
  {  value: "Unakoti", label: "Unakoti, India" },
  {  value: "Rourkela", label: "Rourkela, India" },
  {  value: "Rishyap", label: "Rishyap, India" },
  {  value: "Ramgarh", label: "Ramgarh, India" },
  {  value: "Punakha", label: "Punakha, India" },
  {  value: "Tripura", label: "Tripura, India" },
  {  value: "Greater Noida", label: "Greater Noida, India" },
  {  value: "Skandagiri Hills", label: "Skandagiri Hills, India" },
  {  value: "Kailash Mansarovar", label: "Kailash Mansarovar, India" },
  {  value: "Kukke Subramanya", label: "Kukke Subramanya, India" },
  {  value: "Kolad", label: "Kolad, India" },
  {  value: "Raigad", label: "Raigad, India" },
  {  value: "Radhanagar", label: "Radhanagar, India" },
  {  value: "North Bay", label: "North Bay, India" },
  {  value: "Margao", label: "Margao, India" },
  {  value: "Gomukh", label: "Gomukh, India" },
  {  value: "Madras", label: "Madras, India" },
  {  value: "Nilambur", label: "Nilambur, India" },
  {  value: "Padong", label: "Padong, India" },
  {  value: "Satara", label: "Satara, India" },
  {  value: "Itanagar", label: "Itanagar, India" },
  {  value: "Indrahar Pass", label: "Indrahar Pass, India" },
  {  value: "Chilika", label: "Chilika, India" },
  {  value: "Gokul", label: "Gokul, India" },
  {  value: "Deoria Tal", label: "Deoria Tal, India" },
  {  value: "Kodungallur", label: "Kodungallur, India" },
  {  value: "Pahal", label: "Pahal, India" },
  {  value: "Khonoma", label: "Khonoma, India" },
  {  value: "Muzaffarpur", label: "Muzaffarpur, India" },
  {  value: "Patan", label: "Patan, India" },
  {  value: "Alipura", label: "Alipura, India" },
  {  value: "Muthathi", label: "Muthathi, India" },
  {  value: "Wagha Border", label: "Wagha Border, India" },
  {  value: "Chandannagar", label: "Chandannagar, India" },
  {  value: "Gaurikund", label: "Gaurikund, India" },
  {  value: "Munsyari", label: "Munsyari, India" },
  {  value: "Sattal", label: "Sattal, India" },
  {  value: "Alchi", label: "Alchi, India" },
  {  value: "Karla", label: "Karla, India" },
  {  value: "Jalgaon", label: "Jalgaon, India" },
  {  value: "Chitkul", label: "Chitkul, India" },
  {  value: "Vasundhara", label: "Vasundhara, India" },
  {  value: "Shivkhori", label: "Shivkhori, India" },
  {  value: "Balasore", label: "Balasore, India" },
  {  value: "Faizabad", label: "Faizabad, India" },
  {  value: "Govardhan", label: "Govardhan, India" },
  {  value: "Gao", label: "Gao, India" },
  {  value: "Kodagu", label: "Kodagu, India" },
  {  value: "Rose Island", label: "Rose Island, India" },
  {  value: "Nellore", label: "Nellore, India" },
  {  value: "Thenmala", label: "Thenmala, India" },
  {  value: "Ankleshwar", label: "Ankleshwar, India" },
  {  value: "Jhalong", label: "Jhalong, India" },
  {  value: "Diskit", label: "Diskit, India" },
  {  value: "Abu Road", label: "Abu Road, India" },
  {  value: "Spitalyi", label: "Spitalyi, India" },
  {  value: "Kazerbaijana", label: "Kazerbaijana, India" },
  {  value: "Mawphu", label: "Mawphu, India" },
  {  value: "Guptakashi", label: "Guptakashi, India" },
  {  value: "Valsad", label: "Valsad, India" },
  {  value: "Sirsi", label: "Sirsi, India" },
  {  value: "Bhimashankar", label: "Bhimashankar, India" },
  {  value: "Khardungla Top", label: "Khardungla Top, India" },
  {  value: "Wandoor", label: "Wandoor, India" },
  {  value: "Rewari", label: "Rewari, India" },
  {  value: "Bathinda", label: "Bathinda, India" },
  {  value: "Nagercoil", label: "Nagercoil, India" },
  {  value: "Ratlam", label: "Ratlam, India" },
  {  value: "Fatehpur", label: "Fatehpur, India" },
  {  value: "Ahmadabad", label: "Ahmadabad, India" },
  {  value: "Akola", label: "Akola, India" },
  {  value: "Sariska", label: "Sariska, India" },
  {  value: "Bolpur", label: "Bolpur, India" },
  {  value: "Neermahal", label: "Neermahal, India" },
  {  value: "Madhopur", label: "Madhopur, India" },
  {  value: "Malana Dam", label: "Malana Dam, India" },
  {  value: "Dhauli", label: "Dhauli, India" },
  {  value: "Vythiri", label: "Vythiri, India" },
  {  value: "Calcutta", label: "Calcutta, India" },
  {  value: "Vagator", label: "Vagator, India" },
  {  value: "Charkhole", label: "Charkhole, India" },
  {  value: "Kanya Kumari", label: "Kanya Kumari, India" },
  {  value: "Rado Valley", label: "Rado Valley, India" },
  {  value: "Ellappatti Camp", label: "Ellappatti Camp, India" },
  {  value: "Jind", label: "Jind, India" },
  {  value: "Panipat", label: "Panipat, India" },
  {  value: "Sarnath", label: "Sarnath, India" },
  {  value: "Kakinada", label: "Kakinada, India" },
  {  value: "Yuksom", label: "Yuksom, India" },
  {  value: "Malda", label: "Malda, India" },
  {  value: "Ganapatipule", label: "Ganapatipule, India" },
  {  value: "Thiksey", label: "Thiksey, India" },
  {  value: "Pykara", label: "Pykara, India" },
  {  value: "Jharsuguda", label: "Jharsuguda, India" },
  {  value: "Aizawl", label: "Aizawl, India" },
  {  value: "Bakkhali", label: "Bakkhali, India" },
  {  value: "Ellora", label: "Ellora, India" },
  {  value: "Thirunallar", label: "Thirunallar, India" },
  {  value: "Kanjanur", label: "Kanjanur, India" },
  {  value: "Alangudi", label: "Alangudi, India" },
  {  value: "Thiruvenkadu", label: "Thiruvenkadu, India" },
  {  value: "Vaitheeswaran Koil", label: "Vaitheeswaran Koil, India" },
  {  value: "Suriyanar Koil", label: "Suriyanar Koil, India" },
  {  value: "Tungnath", label: "Tungnath, India" },
  {  value: "Sonipat", label: "Sonipat, India" },
  {  value: "Jageshwar", label: "Jageshwar, India" },
  {  value: "Hikkim", label: "Hikkim, India" },
  {  value: "Chottanikkara", label: "Chottanikkara, India" },
  {  value: "Hubballi", label: "Hubballi, India" },
  {  value: "Anantapur", label: "Anantapur, India" },
  {  value: "Bharuch", label: "Bharuch, India" },
  {  value: "Kosi", label: "Kosi, India" },
  {  value: "Cuddalore", label: "Cuddalore, India" },
  {  value: "Mizoram", label: "Mizoram, India" },
  {  value: "Karimnagar", label: "Karimnagar, India" },
  {  value: "Malshej Ghat", label: "Malshej Ghat, India" },
  {  value: "Dombivli", label: "Dombivli, India" },
  {  value: "Chokhi Dhani", label: "Chokhi Dhani, India" },
  {  value: "Tanakpur", label: "Tanakpur, India" },
  {  value: "Baroda", label: "Baroda, India" },
  {  value: "Pench", label: "Pench, India" },
  {  value: "Ganagapur", label: "Ganagapur, India" },
  {  value: "Pandharpuur", label: "Pandharpuur, India" },
  {  value: "Athirappally", label: "Athirappally, India" },
  {  value: "Thirunageswaram", label: "Thirunageswaram, India" },
  {  value: "Durgapur", label: "Durgapur, India" },
  {  value: "Ghum", label: "Ghum, India" },
  {  value: "Lingtam", label: "Lingtam, India" },
  {  value: "Satkosia", label: "Satkosia, India" },
  {  value: "Baralacha", label: "Baralacha, India" },
  {  value: "Bhagsu", label: "Bhagsu, India" },
  {  value: "Naddi", label: "Naddi, India" },
  {  value: "Pong", label: "Pong, India" },
  {  value: "Agara", label: "Agara, India" },
  {  value: "Khir Ganga", label: "Khir Ganga, India" },
  {  value: "Kharagpur", label: "Kharagpur, India" },
  {  value: "Scandal Point", label: "Scandal Point, India" },
  {  value: "Lakkar Bazaar", label: "Lakkar Bazaar, India" },
  {  value: "Suntalekhola", label: "Suntalekhola, India" },
  {  value: "Connor", label: "Connor, India" },
  {  value: "Sultanpur", label: "Sultanpur, India" },
  {  value: "Lapchakha", label: "Lapchakha, India" },
  {  value: "Santrabarie", label: "Santrabarie, India" },
  {  value: "Vantawang", label: "Vantawang, India" },
  {  value: "Champai", label: "Champai, India" },
  {  value: "Telangana", label: "Telangana, India" },
  {  value: "Kurnool", label: "Kurnool, India" },
  {  value: "Skandagiri Trek", label: "Skandagiri Trek, India" },
  {  value: "Konaseema", label: "Konaseema, India" },
  {  value: "Nagarjunasagar", label: "Nagarjunasagar, India" },
  {  value: "Ramanagara", label: "Ramanagara, India" },
  {  value: "Shegaon", label: "Shegaon, India" },
  {  value: "Chaukori", label: "Chaukori, India" },
  {  value: "Sitapur", label: "Sitapur, India" },
  {  value: "Kunzum", label: "Kunzum, India" },
  {  value: "Sikar", label: "Sikar, India" },
  {  value: "Veraval", label: "Veraval, India" },
  {  value: "Auroville", label: "Auroville, India" },
  {  value: "Azamgarh", label: "Azamgarh, India" },
  {  value: "Kashi", label: "Kashi, India" },
  {  value: "Jagannath Puri", label: "Jagannath Puri, India" },
  {  value: "Baharampur", label: "Baharampur, India" },
  {  value: "Someshwar", label: "Someshwar, India" },
  {  value: "Kanchi", label: "Kanchi, India" },
  {  value: "Chaubatia", label: "Chaubatia, India" },
  {  value: "Gavi", label: "Gavi, India" },
  {  value: "Sawantwad", label: "Sawantwad, India" },
  {  value: "Jaigadh", label: "Jaigadh, India" },
  {  value: "Berhampur", label: "Berhampur, India" },
  {  value: "Pashupati", label: "Pashupati, India" },
  {  value: "Korba", label: "Korba, India" },
  {  value: "Latur", label: "Latur, India" },
  {  value: "Masinagudi", label: "Masinagudi, India" },
  {  value: "Jaunpur", label: "Jaunpur, India" },
  {  value: "Darbhanga", label: "Darbhanga, India" },
  {  value: "Batal", label: "Batal, India" },
  {  value: "Alipurduar", label: "Alipurduar, India" },
  {  value: "Ramdhura", label: "Ramdhura, India" },
  {  value: "Begusarai", label: "Begusarai, India" },
  {  value: "Kankhal", label: "Kankhal, India" },
  {  value: "Bhandara", label: "Bhandara, India" },
  {  value: "Bageshwar", label: "Bageshwar, India" },
  {  value: "Idukki", label: "Idukki, India" },
  {  value: "Pali", label: "Pali, India" },
  {  value: "Aluva", label: "Aluva, India" },
  {  value: "Kamarpukur", label: "Kamarpukur, India" },
  {  value: "Naukuchiatal", label: "Naukuchiatal, India" },
  {  value: "Sawantwadi", label: "Sawantwadi, India" },
  {  value: "Tarkeshwar", label: "Tarkeshwar, India" },
  {  value: "Borong", label: "Borong, India" },
  {  value: "Manesar", label: "Manesar, India" },
  {  value: "Naldhara", label: "Naldhara, India" },
  {  value: "Chail Palace", label: "Chail Palace, India" },
  {  value: "Kokernag", label: "Kokernag, India" },
  {  value: "Vashi", label: "Vashi, India" },
  {  value: "Bhiwadi", label: "Bhiwadi, India" },
  {  value: "Sangli", label: "Sangli, India" },
  {  value: "Bharmour", label: "Bharmour, India" },
  {  value: "Unnao", label: "Unnao, India" },
  {  value: "Minakshi Puram", label: "Minakshi Puram, India" },
  {  value: "Malappuram", label: "Malappuram, India" },
  {  value: "Pipli", label: "Pipli, India" },
  {  value: "Hirapur", label: "Hirapur, India" },
  {  value: "Angul", label: "Angul, India" },
  {  value: "Japan", label: "Japan, India" },
  {  value: "Virar", label: "Virar, India" },
  {  value: "Bundi", label: "Bundi, India" },
  {  value: "Sangrur", label: "Sangrur, India" },
  {  value: "Valera Falls", label: "Valera Falls, India" },
  {  value: "Mandwa", label: "Mandwa, India" },
  {  value: "Gandhidham", label: "Gandhidham, India" },
  {  value: "Yamuna Nagar", label: "Yamuna Nagar, India" },
  {  value: "Kaudiyala", label: "Kaudiyala, India" },
  {  value: "Srikakulam", label: "Srikakulam, India" },
  {  value: "Dhordo", label: "Dhordo, India" },
  {  value: "Sambalpur", label: "Sambalpur, India" },
  {  value: "Uttarey", label: "Uttarey, India" },
  {  value: "Mana", label: "Mana, India" },
  {  value: "Kausan", label: "Kausan, India" },
  {  value: "Morbi", label: "Morbi, India" },
  {  value: "Kopargaon", label: "Kopargaon, India" },
  {  value: "Kirti Nagar", label: "Kirti Nagar, India" },
  {  value: "Kalaburagi", label: "Kalaburagi, India" },
  {  value: "Saharanpur", label: "Saharanpur, India" },
  {  value: "Narayan Sarovar", label: "Narayan Sarovar, India" },
  {  value: "Ganga Sagar", label: "Ganga Sagar, India" },
  {  value: "Dasada", label: "Dasada, India" },
  {  value: "Abhayapuri", label: "Abhayapuri, India" },
  {  value: "Pin Valley", label: "Pin Valley, India" },
  {  value: "Bhiwandi", label: "Bhiwandi, India" },
  {  value: "Rewalsar", label: "Rewalsar, India" },
  {  value: "Mirzapur", label: "Mirzapur, India" },
  {  value: "Chandratal", label: "Chandratal, India" },
  {  value: "Vishakhapatnam", label: "Vishakhapatnam, India" },
  {  value: "Herur", label: "Herur, India" },
  {  value: "Kadapa", label: "Kadapa, India" },
  {  value: "Nagarkot", label: "Nagarkot, India" },
  {  value: "Rumtek", label: "Rumtek, India" },
  {  value: "Simikot", label: "Simikot, India" },
  {  value: "Mehsana", label: "Mehsana, India" },
  {  value: "Hoshiarpur", label: "Hoshiarpur, India" },
  {  value: "Kashid", label: "Kashid, India" },
  {  value: "Pipalkoti", label: "Pipalkoti, India" },
  {  value: "Pemayangtse", label: "Pemayangtse, India" },
  {  value: "Balotra", label: "Balotra, India" },
  {  value: "Erode", label: "Erode, India" },
  {  value: "Ahmednagar", label: "Ahmednagar, India" },
  {  value: "Kashmandu", label: "Kashmandu, Nepal" },
  {  value: "Malaysia", label: "Malaysia, Malaysia" },
  {  value: "Aalborg", label: "Aalborg, Denmark" },
  {  value: "Abakan", label: "Abakan, Russia" },
  {  value: "Aberdare", label: "Aberdare, Kenya" },
  {  value: "Accra", label: "Accra, Ghana" },
  {  value: "Acropolis", label: "Acropolis, Greece" },
  {  value: "Addis", label: "Addis, Ethiopia" },
  {  value: "Adelaide", label: "Adelaide, Australia" },
  {  value: "Afghanistan", label: "Afghanistan, Afghanistan" },
  {  value: "Africa", label: "Africa, Africa" },
  {  value: "Alabama", label: "Alabama, United States Of America" },
  {  value: "Alaska", label: "Alaska, United States Of America" },
  {  value: "Albania", label: "Albania, Colombia" },
  {  value: "Alexandria", label: "Alexandria, Palestine" },
  {  value: "Alicante", label: "Alicante, Spain" },
  {  value: "Allentown", label: "Allentown, United States Of America" },
  {  value: "Allestree", label: "Allestree, Australia" },
  {  value: "Almaty", label: "Almaty, Kazakhstan" },
  {  value: "Alpine", label: "Alpine, Italy" },
  {  value: "Alta", label: "Alta, Europe" },
  {  value: "Amalfi", label: "Amalfi, Greece" },
  {  value: "Amarapura", label: "Amarapura, Myanmar" },
  {  value: "Amarillo", label: "Amarillo, United States Of America" },
  {  value: "Ambla", label: "Ambla, Estonia" },
  {  value: "Amboseli", label: "Amboseli, Kenya" },
  {  value: "Amerstdam", label: "Amerstdam, United Kingdom" },
  {  value: "Amman", label: "Amman, Jordan" },
  {  value: "Amsterdam", label: "Amsterdam, Netherlands" },
  {  value: "Anaheim", label: "Anaheim, United States Of America" },
  {  value: "Anchorage", label: "Anchorage, United States Of America" },
  {  value: "Andorra", label: "Andorra, Europe" },
  {  value: "Angkor Wat", label: "Angkor Wat, Cambodia" },
  {  value: "Angor", label: "Angor, MiddleEast" },
  {  value: "Ankara", label: "Ankara, Turkey" },
  {  value: "Annemasse", label: "Annemasse, Europe" },
  {  value: "Anruadhapura", label: "Anruadhapura, Sri Lanka" },
  {  value: "Antaliya", label: "Antaliya, Turkey" },
  {  value: "Antalya", label: "Antalya, Turkey" },
  {  value: "Antananarivo", label: "Antananarivo, Madagascar" },
  {  value: "Antarctica", label: "Antarctica, Antarctica" },
  {  value: "Antipolo", label: "Antipolo, Philippines" },
  {  value: "Antwerp", label: "Antwerp, Belgium" },
  {  value: "Anuradhapura", label: "Anuradhapura, Sri Lanka" },
  {  value: "Aomori", label: "Aomori, Japan" },
  {  value: "Aphrodisias", label: "Aphrodisias, Turkey" },
  {  value: "Aquarium", label: "Aquarium, Singapore" },
  {  value: "Arab", label: "Arab, United States Of America" },
  {  value: "Arbaeen", label: "Arbaeen, MiddleEast" },
  {  value: "Ardebil", label: "Ardebil, Iran" },
  {  value: "Arenal", label: "Arenal, Costa Rica" },
  {  value: "Arizona", label: "Arizona, United States Of America" },
  {  value: "Arkhangelsk", label: "Arkhangelsk, Russia" },
  {  value: "Armenia", label: "Armenia, Europe" },
  {  value: "Arusha", label: "Arusha, Tanzania" },
  {  value: "Asturias", label: "Asturias, Spain" },
  {  value: "Aswan", label: "Aswan, Egypt" },
  {  value: "Athens", label: "Athens, Greece" },
  {  value: "Athina", label: "Athina, Greece" },
  {  value: "Atlanta", label: "Atlanta, United States Of America" },
  {  value: "Atlantic", label: "Atlantic, United States Of America" },
  {  value: "Auckland", label: "Auckland, New Zealand" },
  {  value: "Audincourt", label: "Audincourt, France" },
  {  value: "Nice", label: "Nice, France" },
  {  value: "Aukana", label: "Aukana, Sri Lanka" },
  {  value: "Austin", label: "Austin, United States Of America" },
  {  value: "Australia", label: "Australia, Australia" },
  {  value: "Australian", label: "Australian, Australia" },
  {  value: "Austrelia", label: "Austrelia, New Zealand" },
  {  value: "Austria", label: "Austria, France" },
  {  value: "Ayutthaya", label: "Ayutthaya, Thailand" },
  {  value: "Azad", label: "Azad, Pakistan" },
  {  value: "Azerbaijan", label: "Azerbaijan, Azerbaijan" },
  {  value: "Bacolod", label: "Bacolod, Philippines" },
  {  value: "Bagan", label: "Bagan, Myanmar" },
  {  value: "Baghdad", label: "Baghdad, MiddleEast" },
  {  value: "Baguio", label: "Baguio, Philippines" },
  {  value: "Bahamas", label: "Bahamas, Bahamas" },
  {  value: "Bahrain", label: "Bahrain, Bahrain" },
  {  value: "Baitul", label: "Baitul, MiddleEast" },
  {  value: "Baja", label: "Baja, Mexico" },
  {  value: "Baku", label: "Baku, Azerbaijan" },
  {  value: "Balakong", label: "Balakong, Malaysia" },
  {  value: "Balestrand", label: "Balestrand, Europe" },
  {  value: "Balibago", label: "Balibago, Philippines" },
  {  value: "Ballina", label: "Ballina, Ireland" },
  {  value: "Balore", label: "Balore, United States Of America" },
  {  value: "Baltimore", label: "Baltimore, United States Of America" },
  {  value: "Baltra", label: "Baltra, Ecuador" },
  {  value: "Bandar", label: "Bandar, Brunei" },
  {  value: "Bandarawela", label: "Bandarawela, Sri Lanka" },
  {  value: "Bandelierkop", label: "Bandelierkop, South Africa" },
  {  value: "Bandos", label: "Bandos, Maldives" },
  {  value: "Bandung", label: "Bandung, Indonesia" },
  {  value: "Bangladesh", label: "Bangladesh, Bangladesh" },
  {  value: "Barcelona", label: "Barcelona, Spain" },
  {  value: "Barisal", label: "Barisal, Bangladesh" },
  {  value: "Barong", label: "Barong, Indonesia" },
  {  value: "Batan Island", label: "Batan Island, Philippines" },
  {  value: "Baton", label: "Baton, United States Of America" },
  {  value: "Batticaloa", label: "Batticaloa, Sri Lanka" },
  {  value: "Batu", label: "Batu, Singapore" },
  {  value: "Batucaves", label: "Batucaves, Malaysia" },
  {  value: "Batumi", label: "Batumi, Georgia" },
  {  value: "Bedford", label: "Bedford, Great Britain" },
  {  value: "Bedugul", label: "Bedugul, Indonesia" },
  {  value: "Behrensdorf", label: "Behrensdorf, Germany" },
  {  value: "Beijing", label: "Beijing, China" },
  {  value: "Beira", label: "Beira, Mozambique" },
  {  value: "Beirut", label: "Beirut, Lebanon" },
  {  value: "Belgium", label: "Belgium, Monaco" },
  {  value: "Belgorod", label: "Belgorod, Russia" },
  {  value: "Belize", label: "Belize, South America" },
  {  value: "Bengkong", label: "Bengkong, Indonesia" },
  {  value: "Benin", label: "Benin, Benin" },
  {  value: "Benoa", label: "Benoa, Indonesia" },
  {  value: "Bergen", label: "Bergen, Europe" },
  {  value: "Berlin", label: "Berlin, Germany" },
  {  value: "Bermingham", label: "Bermingham, United Kingdom" },
  {  value: "Bermuda", label: "Bermuda, Bermuda" },
  {  value: "Bern", label: "Bern, Europe" },
  {  value: "Bethlehem", label: "Bethlehem, Israel" },
  {  value: "Bhagdad", label: "Bhagdad, MiddleEast" },
  {  value: "Bhaktapur", label: "Bhaktapur, Nepal" },
  {  value: "Bhurj", label: "Bhurj, United Arab Emirates" },
  {  value: "Bhutan", label: "Bhutan, Bhutan" },
  {  value: "Biarritz", label: "Biarritz, France" },
  {  value: "Bicol", label: "Bicol, Philippines" },
  {  value: "Bicton", label: "Bicton, Australia" },
  {  value: "Bidart", label: "Bidart, France" },
  {  value: "Birmingham", label: "Birmingham, Great Britain" },
  {  value: "Bishkek", label: "Bishkek, Kyrgyzstan" },
  {  value: "Blanquilla", label: "Blanquilla, Caribbean" },
  {  value: "Blasket", label: "Blasket, Ireland" },
  {  value: "Bogor", label: "Bogor, Indonesia" },
  {  value: "Bogota", label: "Bogota, Colombia" },
  {  value: "Bogra", label: "Bogra, Bangladesh" },
  {  value: "Bolivar", label: "Bolivar, Colombia" },
  {  value: "Bolivia", label: "Bolivia, Bolivia" },
  {  value: "Bolwarra", label: "Bolwarra, Australia" },
  {  value: "Bora", label: "Bora, French Polynesia" },
  {  value: "Borabora", label: "Borabora, France" },
  {  value: "Bosnai", label: "Bosnai, Europe" },
  {  value: "Bosnia", label: "Bosnia, Malaysia" },
  {  value: "Boston", label: "Boston, United States Of America" },
  {  value: "Botswana", label: "Botswana, South Africa" },
  {  value: "Bratislava", label: "Bratislava, Austria" },
  {  value: "Brazil", label: "Brazil, Brazil" },
  {  value: "Brentwood", label: "Brentwood, United Kingdom" },
  {  value: "Brisbane", label: "Brisbane, Australia" },
  {  value: "British", label: "British, Canada" },
  {  value: "Brno", label: "Brno, Czech Republic" },
  {  value: "Brooklyn", label: "Brooklyn, United States Of America" },
  {  value: "Bruges", label: "Bruges, Belgium" },
  {  value: "Brussels", label: "Brussels, Belgium" },
  {  value: "Bucaramanga", label: "Bucaramanga, Colombia" },
  {  value: "Bucharest", label: "Bucharest, Romania" },
  {  value: "Budapest", label: "Budapest, Hungary" },
  {  value: "Buena", label: "Buena, United States Of America" },
  {  value: "Buffalo", label: "Buffalo, United States Of America" },
  {  value: "Bullet", label: "Bullet, Japan" },
  {  value: "Bumthang", label: "Bumthang, Bhutan" },
  {  value: "Bunaken", label: "Bunaken, Indonesia" },
  {  value: "Burkina", label: "Burkina, Africa" },
  {  value: "Burlington", label: "Burlington, Canada" },
  {  value: "Burundi", label: "Burundi, Burundi" },
  {  value: "Buyan", label: "Buyan, Indonesia" },
  {  value: "Cairns", label: "Cairns, Australia" },
  {  value: "Cairo", label: "Cairo, Egypt" },
  {  value: "Calgary", label: "Calgary, Canada" },
  {  value: "California", label: "California, United States Of America" },
  {  value: "Cambodia", label: "Cambodia, Cambodia" },
  {  value: "Cameroon", label: "Cameroon, Cameroon" },
  {  value: "Camperdown", label: "Camperdown, Australia" },
  {  value: "Campleakey", label: "Campleakey, Indonesia" },
  {  value: "Canada", label: "Canada, Canada" },
  {  value: "Canadaperu", label: "Canadaperu, South Africa" },
  {  value: "Cancun", label: "Cancun, United States Of America" },
  {  value: "Cannes", label: "Cannes, France" },
  {  value: "Canning", label: "Canning, Canada" },
  {  value: "Canton", label: "Canton, United States Of America" },
  {  value: "Cao", label: "Cao, Far East" },
  {  value: "Capetown", label: "Capetown, Kenya" },
  {  value: "Cape_Town", label: "Cape_Town, South Africa" },
  {  value: "Capitol", label: "Capitol, United States Of America" },
  {  value: "Capo", label: "Capo, Europe" },
  {  value: "Cappadocia", label: "Cappadocia, Turkey" },
  {  value: "Caribbean", label: "Caribbean, Caribbean" },
  {  value: "Carnegie", label: "Carnegie, Australia" },
  {  value: "Carpathians", label: "Carpathians, Romania" },
  {  value: "Cary", label: "Cary, United States Of America" },
  {  value: "Casablanca", label: "Casablanca, Morocco" },
  {  value: "Cayman", label: "Cayman, Cayman Islands" },
  {  value: "Celuk", label: "Celuk, Indonesia" },
  {  value: "Central", label: "Central, Singapore" },
  {  value: "Cerfs", label: "Cerfs, Mauritius" },
  {  value: "Cesky", label: "Cesky, Czech Republic" },
  {  value: "Changmai", label: "Changmai, Thailand" },
  {  value: "Changunarayan", label: "Changunarayan, Nepal" },
  {  value: "Chania", label: "Chania, Greece" },
  {  value: "Charvak", label: "Charvak, Uzbekistan" },
  {  value: "Chau", label: "Chau, Vietnam" },
  {  value: "Chefchaoun", label: "Chefchaoun, Morocco" },
  {  value: "Chegaga", label: "Chegaga, Morocco" },
  {  value: "Chelela", label: "Chelela, Bhutan" },
  {  value: "Chengde", label: "Chengde, China" },
  {  value: "Chengdu", label: "Chengdu, China" },
  {  value: "Cheras", label: "Cheras, Malaysia" },
  {  value: "Chiang", label: "Chiang, Thailand" },
  {  value: "Chiapas", label: "Chiapas, Mexico" },
  {  value: "Chicago", label: "Chicago, United States Of America" },
  {
     value: "Chickamaugalur",
    label: "Chickamaugalur, United States Of America",
  },
  {  value: "Chilaw", label: "Chilaw, Sri Lanka" },
  {  value: "Chile", label: "Chile, Chile" },
  {  value: "Chimgan", label: "Chimgan, Uzbekistan" },
  {  value: "China", label: "China, China" },
  {  value: "Chinagreatwall", label: "Chinagreatwall, China" },
  {  value: "Chinatown", label: "Chinatown, Malaysia" },
  {  value: "Chino", label: "Chino, United States Of America" },
  {  value: "Chisinau", label: "Chisinau, Moldova" },
  {  value: "Chitran", label: "Chitran, Nepal" },
  {  value: "Chittagong", label: "Chittagong, Bangladesh" },
  {  value: "Chongqing", label: "Chongqing, China" },
  {  value: "Christchurch", label: "Christchurch, New Zealand" },
  {  value: "Cimahi", label: "Cimahi, Indonesia" },
  {  value: "Ciudad", label: "Ciudad, United States Of America" },
  {  value: "Claremont", label: "Claremont, United States Of America" },
  {  value: "Cleveland", label: "Cleveland, United States Of America" },
  {  value: "Clonmacnoise", label: "Clonmacnoise, Ireland" },
  {  value: "Codrington", label: "Codrington, Australia" },
  {  value: "Cognac", label: "Cognac, France" },
  {  value: "Cologne", label: "Cologne, Switzerland" },
  {  value: "Colombia", label: "Colombia, Colombia" },
  {  value: "Colombo", label: "Colombo, Sri Lanka" },
  {  value: "Combo", label: "Combo, Australia" },
  {  value: "Comillas", label: "Comillas, Spain" },
  {  value: "Comoros", label: "Comoros, Comoros" },
  {  value: "Conakry", label: "Conakry, Guinea" },
  {  value: "Connemara", label: "Connemara, Ireland" },
  {  value: "Copenhagen", label: "Copenhagen, Denmark" },
  {  value: "Corfu", label: "Corfu, United States Of America" },
  {  value: "Coria", label: "Coria, Spain" },
  {  value: "Cork", label: "Cork, Ireland" },
  {  value: "Coron", label: "Coron, Philippines" },
  {  value: "Corsica", label: "Corsica, France" },
  {  value: "Costa", label: "Costa, Costa Rica" },
  {  value: "Cranbrook", label: "Cranbrook, Canada" },
  {  value: "Crest", label: "Crest, United States Of America" },
  {  value: "Croatia", label: "Croatia, Italy" },
  {  value: "Crook", label: "Crook, United States Of America" },
  {  value: "Crouse", label: "Crouse, United Kingdom" },
  {  value: "Cuba", label: "Cuba, Cuba" },
  {  value: "Curacao", label: "Curacao, Switzerland" },
  {  value: "Curepipe", label: "Curepipe, Mauritius" },
  {  value: "Cuzco", label: "Cuzco, Peru" },
  {  value: "Cyprus", label: "Cyprus, Cyprus" },
  {  value: "Dades", label: "Dades, Morocco" },
  {  value: "Daegu", label: "Daegu, South Korea" },
  {  value: "Dakar", label: "Dakar, Senegal" },
  {  value: "Dalat", label: "Dalat, Vietnam" },
  {  value: "Dallas", label: "Dallas, United States Of America" },
  {  value: "Dambulla", label: "Dambulla, Sri Lanka" },
  {  value: "Dammam", label: "Dammam, Saudi Arabia" },
  {  value: "Denmark", label: "Denmark, Europe" },
  {  value: "Dargi", label: "Dargi, Far East" },
  {  value: "Darlinghurst", label: "Darlinghurst, Australia" },
  {  value: "Dartmouth", label: "Dartmouth, Canada" },
  {  value: "Davao", label: "Davao, Philippines" },
  {  value: "Deira", label: "Deira, United Arab Emirates" },
  {  value: "Delray", label: "Delray, United States Of America" },
  {  value: "Denarau", label: "Denarau, Fiji" },
  {  value: "Denpasar", label: "Denpasar, Indonesia" },
  {  value: "Denver", label: "Denver, United States Of America" },
  {  value: "Detroit", label: "Detroit, United States Of America" },
  {  value: "Dexter", label: "Dexter, Singapore" },
  {  value: "Dhaka", label: "Dhaka, Bangladesh" },
  {  value: "Dhow", label: "Dhow, United Arab Emirates" },
  {  value: "Dhudh", label: "Dhudh, Nepal" },
  {  value: "Dhulikhel", label: "Dhulikhel, Nepal" },
  {  value: "Diani", label: "Diani, Kenya" },
  {  value: "Dinajpur", label: "Dinajpur, Bangladesh" },
  {  value: "Dingboche", label: "Dingboche, Nepal" },
  {  value: "Dirapuk", label: "Dirapuk, Nepal" },
  {  value: "Disneyland", label: "Disneyland, China" },
  {  value: "Division", label: "Division, Bangladesh" },
  {  value: "Djibouti", label: "Djibouti, Djibouti" },
  {  value: "Dlingo", label: "Dlingo, Indonesia" },
  {  value: "Dochula", label: "Dochula, Bhutan" },
  {  value: "Doha", label: "Doha, Qatar" },
  {  value: "Dominica", label: "Dominica, Dominica" },
  {  value: "Dominican", label: "Dominican, Dominican Republic" },
  {  value: "Dongguan", label: "Dongguan, Far East" },
  {  value: "Dorset", label: "Dorset, United Kingdom" },
  {  value: "Drakensberg", label: "Drakensberg, South Africa" },
  {  value: "Drukgyel", label: "Drukgyel, Bhutan" },
  {  value: "Drukgyeldzong", label: "Drukgyeldzong, Bhutan" },
  {  value: "Dubai", label: "Dubai, United Arab Emirates" },
  {  value: "Dublin", label: "Dublin, Ireland" },
  {  value: "Dubrovnik", label: "Dubrovnik, Europe" },
  {  value: "Dumaguete", label: "Dumaguete, Philippines" },
  {  value: "Dunes", label: "Dunes, Morocco" },
  {  value: "Ecuador", label: "Ecuador, Ecuador" },
  {  value: "Edinburgh", label: "Edinburgh, United Kingdom" },
  {  value: "Egypt", label: "Egypt, Israel" },
  {  value: "Elsalvador", label: "Elsalvador, United States Of America" },
  {  value: "Engelberg", label: "Engelberg, Switzerland" },
  {  value: "Epcot", label: "Epcot, United States Of America" },
  {  value: "Ephesus", label: "Ephesus, Turkey" },
  {  value: "Equador", label: "Equador, South America" },
  {  value: "Eritrea", label: "Eritrea, Eritrea" },
  {  value: "Estonia", label: "Estonia, Finland" },
  {  value: "Etali", label: "Etali, South America" },
  {  value: "Ethiopia", label: "Ethiopia, Ethiopia" },
  {  value: "Eureka", label: "Eureka, United States Of America" },
  {  value: "Everest", label: "Everest, Nepal" },
  {  value: "Faridpur", label: "Faridpur, Bangladesh" },
  {  value: "Faro", label: "Faro, Portugal" },
  {  value: "Fethiye", label: "Fethiye, Turkey" },
  {  value: "Fihalhohi", label: "Fihalhohi, Maldives" },
  {  value: "Fiji", label: "Fiji, Australia" },
  {  value: "Finland", label: "Finland, Finland" },
  {  value: "Floreana", label: "Floreana, Ecuador" },
  {  value: "Florence", label: "Florence, Italy" },
  {  value: "Florida", label: "Florida, United States Of America" },
  {  value: "Fortune", label: "Fortune, Canada" },
  {  value: "France", label: "France, France" },
  {  value: "Frankfurt", label: "Frankfurt, Germany" },
  {  value: "Freiburg", label: "Freiburg, Germany" },
  {  value: "Fuji", label: "Fuji, Japan" },
  {  value: "Galle", label: "Galle, Sri Lanka" },
  {  value: "Gangtey", label: "Gangtey, Bhutan" },
  {  value: "Garden", label: "Garden, Singapore" },
  {  value: "Gardenroute", label: "Gardenroute, Kenya" },
  {  value: "Garelt", label: "Garelt, Canada" },
  {  value: "Gaul", label: "Gaul, MiddleEast" },
  {  value: "Gawagala", label: "Gawagala, Sri Lanka" },
  {  value: "Geilo", label: "Geilo, Europe" },
  {  value: "Gelang", label: "Gelang, Malaysia" },
  {  value: "Gemini", label: "Gemini, Singapore" },
  {  value: "George", label: "George, South Africa" },
  {  value: "Georgetown", label: "Georgetown, United States Of America" },
  {  value: "Georgia", label: "Georgia, Georgia" },
  {  value: "Georgiaritreamoroccony", label: "Georgiaritreamoroccony, Europe" },
  {  value: "Gerik", label: "Gerik, Malaysia" },
  {  value: "Ghana", label: "Ghana, Ghana" },
  {  value: "Ghent", label: "Ghent, Belgium" },
  {  value: "Ghorepani", label: "Ghorepani, Nepal" },
  {  value: "Giza", label: "Giza, Egypt" },
  {  value: "Glacier", label: "Glacier, New Zealand" },
  {  value: "Glasgow", label: "Glasgow, Great Britain" },
  {  value: "Glattbrugg", label: "Glattbrugg, Switzerland" },
  {  value: "Glendale", label: "Glendale, United States Of America" },
  {  value: "Godagari", label: "Godagari, Bangladesh" },
  {  value: "Goias", label: "Goias, South America" },
  {  value: "Gokyo", label: "Gokyo, Nepal" },
  {  value: "Gold", label: "Gold, United Arab Emirates" },
  {  value: "Gold Coast", label: "Gold Coast, Australia" },
  {  value: "Golden", label: "Golden, United States Of America" },
  {  value: "Gorakshep", label: "Gorakshep, Nepal" },
  {  value: "Gorey", label: "Gorey, Europe" },
  {  value: "Gorkha", label: "Gorkha, Nepal" },
  {  value: "Gorky", label: "Gorky, Kazakhstan" },
  {  value: "Gornergrat", label: "Gornergrat, Switzerland" },
  {  value: "Granada", label: "Granada, Spain" },
  {  value: "Grandcanyon", label: "Grandcanyon, North America" },
  {  value: "Greece", label: "Greece, Greece" },
  {  value: "Greenland", label: "Greenland, United States Of America" },
  {  value: "Grindelwald", label: "Grindelwald, Switzerland" },
  {  value: "Gruyeres", label: "Gruyeres, Switzerland" },
  {  value: "Guam", label: "Guam, Guam" },
  {  value: "Guanabara", label: "Guanabara, Brazil" },
  {  value: "Guanabarabay", label: "Guanabarabay, South America" },
  {  value: "Guangzhou", label: "Guangzhou, China" },
  {  value: "Gumpa", label: "Gumpa, Dominica" },
  {  value: "Gwadar", label: "Gwadar, Pakistan" },
  {  value: "Haifa", label: "Haifa, Israel" },
  {  value: "Hajj", label: "Hajj, Saudi Arabia" },
  {  value: "Halong", label: "Halong, Cambodia" },
  {  value: "Halongbay", label: "Halongbay, Cambodia" },
  {  value: "Hambantota", label: "Hambantota, Sri Lanka" },
  {  value: "Hamburg", label: "Hamburg, Germany" },
  {  value: "Hamilton", label: "Hamilton, New Zealand" },
  {  value: "Hammerfest", label: "Hammerfest, Europe" },
  {  value: "Hangzhou", label: "Hangzhou, China" },
  {  value: "Hanoi", label: "Hanoi, Vietnam" },
  {  value: "Hardangerfjord", label: "Hardangerfjord, Europe" },
  {  value: "Harrisburg", label: "Harrisburg, United States Of America" },
  {  value: "Hatshpsut", label: "Hatshpsut, Egypt" },
  {  value: "Haugesund", label: "Haugesund, Norway" },
  {  value: "Havana", label: "Havana, Cuba" },
  {  value: "Havant", label: "Havant, Great Britain" },
  {  value: "Hawaiian", label: "Hawaiian, United States Of America" },
  {  value: "Hawally", label: "Hawally, Kuwait" },
  {  value: "Heckenberg", label: "Heckenberg, Australia" },
  {  value: "Heidelberg", label: "Heidelberg, France" },
  {  value: "Helsinki", label: "Helsinki, Finland" },
  {  value: "Hemel", label: "Hemel, Great Britain" },
  {  value: "Heraklion", label: "Heraklion, Europe" },
  {  value: "Heroica", label: "Heroica, Mexico" },
  {  value: "Hikkaduwa", label: "Hikkaduwa, Sri Lanka" },
  {  value: "Hiroshima", label: "Hiroshima, Japan" },
  {  value: "Hochiminhcity", label: "Hochiminhcity, Vietnam" },
  {  value: "Hokkaido", label: "Hokkaido, Japan" },
  {  value: "Holland", label: "Holland, Austria" },
  {  value: "Holyland", label: "Holyland, MiddleEast" },
  {  value: "Homestead", label: "Homestead, United States Of America" },
  {  value: "Honduras", label: "Honduras, Honduras" },
  {  value: "Hongkong", label: "Hongkong, Hong Kong" },
  {  value: "Honningsvag", label: "Honningsvag, Europe" },
  {  value: "Honolulu", label: "Honolulu, United States Of America" },
  {  value: "Hooghly", label: "Hooghly, Bangladesh" },
  {  value: "Houston", label: "Houston, United States Of America" },
  {  value: "Hujand", label: "Hujand, MiddleEast" },
  {  value: "Humble", label: "Humble, United Kingdom" },
  {  value: "Hungary", label: "Hungary, Hungary" },
  {  value: "Hurghada", label: "Hurghada, Egypt" },
  {  value: "Ibiza", label: "Ibiza, Spain" },
  {  value: "Ice", label: "Ice, Canada" },
  {  value: "Iguassu", label: "Iguassu, Brazil" },
  {  value: "Ile", label: "Ile, Africa" },
  {  value: "Ileauxcerfs", label: "Ileauxcerfs, Africa" },
  {  value: "Imilchil", label: "Imilchil, Morocco" },
  {  value: "Incheon", label: "Incheon, South Korea" },
  {  value: "Indiana", label: "Indiana, United States Of America" },
  {  value: "Indianapolis", label: "Indianapolis, United States Of America" },
  {  value: "Indonasia", label: "Indonasia, Indonesia" },
  {  value: "Indus", label: "Indus, Dominica" },
  {  value: "Inglewood", label: "Inglewood, United States Of America" },
  {  value: "Inle", label: "Inle, Myanmar" },
  {  value: "Bern", label: "Bern, Switzerland" },
  {  value: "Geneva", label: "Geneva, Switzerland" },
  {  value: "Innersbruck", label: "Innersbruck, United Kingdom" },
  {  value: "Innsbruck", label: "Innsbruck, Austria" },
  {  value: "Vienna", label: "Vienna, Austria" },
  {  value: "Interlaken", label: "Interlaken, Switzerland" },
  {  value: "Ipoh", label: "Ipoh, Malaysia" },
  {  value: "Iraklio", label: "Iraklio, Greece" },
  {  value: "Iran", label: "Iran, Spain" },
  {  value: "Iraq", label: "Iraq, MiddleEast" },
  {  value: "Ireland", label: "Ireland, United Kingdom" },
  {  value: "Israel", label: "Israel, Israel" },
  {  value: "Issykkul", label: "Issykkul, Kyrgyzstan" },
  {  value: "Istanbul", label: "Istanbul, Turkey" },
  {  value: "Italy", label: "Italy, France" },
  {  value: "Itlay", label: "Itlay, Italy" },
  {  value: "Izmir", label: "Izmir, Turkey" },
  {  value: "Jacksonville", label: "Jacksonville, United States Of America" },
  {  value: "Jaffna", label: "Jaffna, Sri Lanka" },
  {  value: "Jakar", label: "Jakar, Bhutan" },
  {  value: "Jakarta", label: "Jakarta, Indonesia" },
  {  value: "Jamaica", label: "Jamaica, Jamaica" },
  {  value: "Jamalpur", label: "Jamalpur, Bangladesh" },
  {  value: "Jasper", label: "Jasper, Canada" },
  {  value: "Jeddah", label: "Jeddah, Saudi Arabia" },
  {  value: "Jerash", label: "Jerash, Jordan" },
  {  value: "Jerusalem", label: "Jerusalem, Israel" },
  {  value: "Jessore", label: "Jessore, Bangladesh" },
  {  value: "Jizan", label: "Jizan, Saudi Arabia" },
  {  value: "Johannesburg", label: "Johannesburg, South Africa" },
  {  value: "Johor", label: "Johor, Malaysia" },
  {  value: "Johorbahru", label: "Johorbahru, Malaysia" },
  {  value: "Jomsom", label: "Jomsom, Nepal" },
  {  value: "Jonesborough", label: "Jonesborough, United States Of America" },
  {  value: "Jordan", label: "Jordan, Jordan" },
  {  value: "Jim Corbett", label: "Jim Corbett, India" },
  {  value: "Jumeirah", label: "Jumeirah, United Arab Emirates" },
  {  value: "Junrog", label: "Junrog, Singapore" },
  {  value: "Jurong", label: "Jurong, Singapore" },
  {  value: "Kagbeni", label: "Kagbeni, Nepal" },
  {  value: "Kaghan", label: "Kaghan, Pakistan" },
  {  value: "Kaikoura", label: "Kaikoura, New Zealand" },
  {  value: "Kailash", label: "Kailash, Nepal" },
  {  value: "Kajang", label: "Kajang, Malaysia" },
  {  value: "Kalba", label: "Kalba, United Arab Emirates" },
  {  value: "Kaley", label: "Kaley, Myanmar" },
  {  value: "Kalipokhri", label: "Kalipokhri, Nepal" },
  {  value: "Kamchatka", label: "Kamchatka, Russia" },
  {  value: "Kamloops", label: "Kamloops, Canada" },
  {  value: "Kampala", label: "Kampala, Uganda" },
  {  value: "Kanazawa", label: "Kanazawa, Japan" },
  {  value: "Kandy", label: "Kandy, Sri Lanka" },
  {  value: "Kansas", label: "Kansas, United States Of America" },
  {  value: "Kanthmandu", label: "Kanthmandu, Nepal" },
  {  value: "Kapilavastu", label: "Kapilavastu, Nepal" },
  {  value: "Karachi", label: "Karachi, Pakistan" },
  {  value: "Karak", label: "Karak, Jordan" },
  {  value: "Karank", label: "Karank, Egypt" },
  {  value: "Karbi", label: "Karbi, Thailand" },
  {  value: "Karlaplan", label: "Karlaplan, Sweden" },
  {  value: "Karnak", label: "Karnak, Egypt" },
  {  value: "Karnaktemple", label: "Karnaktemple, Egypt" },
  {  value: "Katakombs", label: "Katakombs, Egypt" },
  {  value: "Katar", label: "Katar, United Arab Emirates" },
  {  value: "Kataragama", label: "Kataragama, Sri Lanka" },
  {  value: "Katarakama", label: "Katarakama, Sri Lanka" },
  {  value: "Kathmandu", label: "Kathmandu, Nepal" },
  {  value: "Katy", label: "Katy, United States Of America" },
  {  value: "Kauai", label: "Kauai, United States Of America" },
  {  value: "Kawaguchiko", label: "Kawaguchiko, Japan" },
  {  value: "Kazan", label: "Kazan, Russia" },
  {  value: "Kedah", label: "Kedah, Malaysia" },
  {  value: "Kef", label: "Kef, Iceland" },
  {  value: "Kelala", label: "Kelala, Ethiopia" },
  {  value: "Kemerovo", label: "Kemerovo, Russia" },
  {  value: "Kendal", label: "Kendal, United Kingdom" },
  {  value: "Kentucky", label: "Kentucky, United States Of America" },
  {  value: "Kenya", label: "Kenya, Kenya" },
  {  value: "Kerry", label: "Kerry, Ireland" },
  {  value: "Key", label: "Key, United States Of America" },
  {  value: "Khardong", label: "Khardong, Sri Lanka" },
  {  value: "Kharkiv", label: "Kharkiv, Ukraine" },
  {  value: "Khuan", label: "Khuan, Thailand" },
  {  value: "Khulna", label: "Khulna, Bangladesh" },
  {  value: "Kiev", label: "Kiev, Europe" },
  {  value: "Killarney", label: "Killarney, Ireland" },
  {  value: "Kingston", label: "Kingston, Canada" },
  {  value: "Kintamani", label: "Kintamani, Indonesia" },
  {  value: "Kitulgala", label: "Kitulgala, Sri Lanka" },
  {  value: "Kleine", label: "Kleine, Italy" },
  {  value: "Kloten", label: "Kloten, Switzerland" },
  {  value: "Knysna", label: "Knysna, South Africa" },
  {  value: "Kohsamui", label: "Kohsamui, Thailand" },
  {  value: "Koneswaram", label: "Koneswaram, Sri Lanka" },
  {  value: "Konigsberg", label: "Konigsberg, Russia" },
  {  value: "Konya", label: "Konya, Turkey" },
  {  value: "Kophangan", label: "Kophangan, Thailand" },
  {  value: "Kotiyal", label: "Kotiyal, Far East" },
  {  value: "Krabhi", label: "Krabhi, Thailand" },
  {  value: "Krabi", label: "Krabi, Thailand" },
  {  value: "Krakow", label: "Krakow, Germany" },
  {  value: "Krong", label: "Krong, Cambodia" },
  {  value: "Kruger", label: "Kruger, South Africa" },
  {  value: "Krugerregion", label: "Krugerregion, South Africa" },
  {  value: "Kuala", label: "Kuala, Singapore" },
  {  value: "Kuala Lumpur", label: "Kuala Lumpur, Malaysia" },
  {  value: "Kuching", label: "Kuching, Malaysia" },
  {  value: "Kudacan", label: "Kudacan, Great Britain" },
  {  value: "Kuden", label: "Kuden, Europe" },
  {  value: "Kufa", label: "Kufa, MiddleEast" },
  {  value: "Kumai", label: "Kumai, Indonesia" },
  {  value: "Kunming", label: "Kunming, China" },
  {  value: "Kusadasi", label: "Kusadasi, Turkey" },
  {  value: "Kuta", label: "Kuta, Indonesia" },
  {  value: "Kuzuko", label: "Kuzuko, South Africa" },
  {  value: "Kwantu", label: "Kwantu, South Africa" },
  {  value: "Kynsna", label: "Kynsna, South Africa" },
  {  value: "Kyoto", label: "Kyoto, Japan" },
  {  value: "Kyrgyzstan", label: "Kyrgyzstan, Kyrgyzstan" },
  {  value: "Lahijan", label: "Lahijan, Iran" },
  {  value: "Lahore", label: "Lahore, Pakistan" },
  {  value: "Lakenaivasha", label: "Lakenaivasha, Kenya" },
  {  value: "Lakenakuru", label: "Lakenakuru, Kenya" },
  {  value: "Lanarkshire", label: "Lanarkshire, United Kingdom" },
  {  value: "Landan", label: "Landan, United Kingdom" },
  {  value: "Landon", label: "Landon, United Kingdom" },
  {  value: "Lankanfinolhu", label: "Lankanfinolhu, Maldives" },
  {  value: "Laohekou", label: "Laohekou, Europe" },
  {  value: "Laos", label: "Laos, Laos" },
  {  value: "Larkana", label: "Larkana, Pakistan" },
  {  value: "Larnaca", label: "Larnaca, Cyprus" },
  {  value: "Las", label: "Las, Spain" },
  {  value: "Las Vegas", label: "Las Vegas, United States Of America" },
  {  value: "Latvia", label: "Latvia, Latvia" },
  {  value: "Lausanne", label: "Lausanne, Switzerland" },
  {  value: "Lawrenceville", label: "Lawrenceville, United States Of America" },
  {  value: "Lea", label: "Lea, Great Britain" },
  {  value: "Lebanon", label: "Lebanon, MiddleEast" },
  {  value: "Lehi", label: "Lehi, United States Of America" },
  {  value: "Lehigh", label: "Lehigh, United States Of America" },
  {  value: "Leicester", label: "Leicester, Great Britain" },
  {  value: "Leuven", label: "Leuven, Belgium" },
  {  value: "Lexington", label: "Lexington, United States Of America" },
  {  value: "Liberia", label: "Liberia, Africa" },
  {  value: "Libreville", label: "Libreville, Gabon" },
  {  value: "Liechtenstein", label: "Liechtenstein, France" },
  {  value: "Lima", label: "Lima, Peru" },
  {  value: "Limerick", label: "Limerick, Ireland" },
  {  value: "Linggi", label: "Linggi, Malaysia" },
  {  value: "Lisbon", label: "Lisbon, Portugal" },
  {  value: "Lithuania", label: "Lithuania, Lithuania" },
  {  value: "Lo Manthang", label: "Lo Manthang, Nepal" },
  {  value: "Lobuche", label: "Lobuche, Nepal" },
  {  value: "Lomani", label: "Lomani, Iceland" },
  {  value: "London", label: "London, United Kingdom" },
  {  value: "Long", label: "Long, United States Of America" },
  {  value: "Longford", label: "Longford, Great Britain" },
  {  value: "Longyearbyen", label: "Longyearbyen, Svalbard &amp" },
  {  value: "Los Angeles", label: "Los Angeles, United States Of America" },
  {  value: "Lost", label: "Lost, United Arab Emirates" },
  {  value: "Lucern", label: "Lucern, United Kingdom" },
  {  value: "Lucerne", label: "Lucerne, Switzerland" },
  {  value: "Lugano", label: "Lugano, Switzerland" },
  {  value: "Lukla", label: "Lukla, Nepal" },
  {  value: "Lumbadzi", label: "Lumbadzi, Malawi" },
  {  value: "Lumpur", label: "Lumpur, Singapore" },
  {  value: "Luxembourg", label: "Luxembourg, Europe" },
  {  value: "Luxor", label: "Luxor, Egypt" },
  {  value: "Luzern", label: "Luzern, Switzerland" },
  {  value: "Lyon", label: "Lyon, France" },
  {  value: "Maasai", label: "Maasai, Kenya" },
  {  value: "Macau", label: "Macau, Hong Kong" },
  {  value: "Macca", label: "Macca, Saudi Arabia" },
  {  value: "Maccau", label: "Maccau, Hong Kong" },
  {  value: "Macedonia", label: "Macedonia, Europe" },
  {  value: "Machu", label: "Machu, Peru" },
  {  value: "Macquarie", label: "Macquarie, Australia" },
  {  value: "Madaba", label: "Madaba, Jordan" },
  {  value: "Madagascar", label: "Madagascar, Africa" },
  {  value: "Madame", label: "Madame, Switzerland" },
  {  value: "Madarihat", label: "Madarihat, Bhutan" },
  {  value: "Madina", label: "Madina, Saudi Arabia" },
  {  value: "Madrid", label: "Madrid, Spain" },
  {  value: "Mainland", label: "Mainland, United Kingdom" },
  {  value: "Makati", label: "Makati, Far East" },
  {  value: "Makka", label: "Makka, Saudi Arabia" },
  {  value: "Makkah", label: "Makkah, Saudi Arabia" },
  {  value: "Malaca", label: "Malaca, Thailand" },
  {  value: "Malacca", label: "Malacca, Malaysia" },
  {  value: "Malas", label: "Malas, Singapore" },
  {  value: "Malawi", label: "Malawi, Malawi" },
  {  value: "Maldives", label: "Maldives, Maldives" },
  {  value: "Malta", label: "Malta, Malta" },
  {  value: "Manado", label: "Manado, Indonesia" },
  {  value: "Manaiceland", label: "Manaiceland, Fiji" },
  {  value: "Manakamana", label: "Manakamana, Nepal" },
  {  value: "Manama", label: "Manama, Bahrain" },
  {  value: "Manaus", label: "Manaus, Brazil" },
  {  value: "Manchester", label: "Manchester, Great Britain" },
  {  value: "Mandalay", label: "Mandalay, Myanmar" },
  {  value: "Mandao", label: "Mandao, Philippines" },
  {  value: "Mangolia", label: "Mangolia, Russia" },
  {  value: "Manila", label: "Manila, Philippines" },
  {  value: "Manile", label: "Manile, Philippines" },
  {  value: "Mansarovar", label: "Mansarovar, Nepal" },
  {  value: "Manyara", label: "Manyara, Kenya" },
  {  value: "Marble", label: "Marble, South Africa" },
  {  value: "Marikina", label: "Marikina, Pacific/Oceania" },
  {  value: "Marina", label: "Marina, United States Of America" },
  {  value: "Marine", label: "Marine, Indonesia" },
  {  value: "Market", label: "Market, Dominica" },
  {  value: "Marrakech", label: "Marrakech, Morocco" },
  {  value: "Masai", label: "Masai, Kenya" },
  {  value: "Masaimara", label: "Masaimara, Kenya" },
  {  value: "Mascow", label: "Mascow, Russia" },
  {  value: "Mashhad", label: "Mashhad, Iran" },
  {  value: "Masouleh", label: "Masouleh, Iran" },
  {  value: "Masouri", label: "Masouri, Greece" },
  {  value: "Massachusetts", label: "Massachusetts, United States Of America" },
  {  value: "Matale", label: "Matale, Sri Lanka" },
  {  value: "Maui", label: "Maui, United States Of America" },
  {  value: "Maurices", label: "Maurices, Ireland" },
  {  value: "Mauritania", label: "Mauritania, Africa" },
  {  value: "Maurtius", label: "Maurtius, Mauritius" },
  {  value: "Mecca", label: "Mecca, MiddleEast" },
  {  value: "Meccah", label: "Meccah, Saudi Arabia" },
  {  value: "Medan", label: "Medan, Indonesia" },
  {  value: "Medina", label: "Medina, Saudi Arabia" },
  {  value: "Mekong", label: "Mekong, Vietnam" },
  {  value: "Melaka", label: "Melaka, Malaysia" },
  {  value: "Melbourne", label: "Melbourne, Australia" },
  {  value: "Memphis", label: "Memphis, Egypt" },
  {  value: "Mergouga", label: "Mergouga, Morocco" },
  {  value: "Merlion", label: "Merlion, Singapore" },
  {  value: "Mexico", label: "Mexico, United States Of America" },
  {  value: "Miami", label: "Miami, United States Of America" },
  {  value: "Michigan", label: "Michigan, United States Of America" },
  {  value: "Mihintale", label: "Mihintale, Sri Lanka" },
  {  value: "Milan", label: "Milan, France" },
  {  value: "Millicent", label: "Millicent, Australia" },
  {  value: "Milpitas", label: "Milpitas, United States Of America" },
  {  value: "Minneapolis", label: "Minneapolis, United States Of America" },
  {  value: "Minneriya", label: "Minneriya, Sri Lanka" },
  {  value: "Minorca", label: "Minorca, Spain" },
  {  value: "Minsk", label: "Minsk, Belarus" },
  {  value: "Minuwangoda", label: "Minuwangoda, Sri Lanka" },
  {  value: "Moheshpur", label: "Moheshpur, Bangladesh" },
  {  value: "Moka", label: "Moka, Africa" },
  {  value: "Moldova", label: "Moldova, Moldova" },
  {  value: "Mombasa", label: "Mombasa, Kenya" },
  {  value: "Monaco", label: "Monaco, Monaco" },
  {  value: "Monestries", label: "Monestries, Dominica" },
  {  value: "Montenegro", label: "Montenegro, Montenegro" },
  {  value: "Monteriggioni", label: "Monteriggioni, Italy" },
  {  value: "Montevideo", label: "Montevideo, United States Of America" },
  {  value: "Montreal", label: "Montreal, Canada" },
  {  value: "Montreux", label: "Montreux, Switzerland" },
  {  value: "Moratuwa", label: "Moratuwa, Sri Lanka" },
  {  value: "Moreshes", label: "Moreshes, Thailand" },
  {  value: "Morocco", label: "Morocco, Morocco" },
  {  value: "Morosis", label: "Morosis, Indonesia" },
  {  value: "Moscow", label: "Moscow, Russia" },
  {  value: "Moskow", label: "Moskow, Far East" },
  {  value: "Muktinath", label: "Muktinath, Nepal" },
  {  value: "Munich", label: "Munich, Germany" },
  {  value: "Muscat", label: "Muscat, Oman" },
  {  value: "Musikot", label: "Musikot, Nepal" },
  {  value: "Myconos", label: "Myconos, Greece" },
  {  value: "Mykonos", label: "Mykonos, Greece" },
  {  value: "Myrtle", label: "Myrtle, United States Of America" },
  {  value: "Nadi", label: "Nadi, Fiji" },
  {  value: "Nagoya", label: "Nagoya, Japan" },
  {  value: "Naiobi", label: "Naiobi, Kenya" },
  {  value: "Nairobi", label: "Nairobi, Kenya" },
  {  value: "Najaf", label: "Najaf, MiddleEast" },
  {  value: "Nakuru", label: "Nakuru, Kenya" },
  {  value: "Namche", label: "Namche, Nepal" },
  {  value: "Nanaimo", label: "Nanaimo, Canada" },
  {  value: "Naples", label: "Naples, Italy" },
  {  value: "Nara", label: "Nara, Japan" },
  {  value: "Narail", label: "Narail, Bangladesh" },
  {  value: "Narita", label: "Narita, Japan" },
  {  value: "Natal", label: "Natal, Brazil" },
  {  value: "Natore", label: "Natore, Bangladesh" },
  {  value: "Nauru", label: "Nauru, Nauru" },
  {  value: "Nazareth", label: "Nazareth, Israel" },
  {  value: "Ncr", label: "Ncr, Far East" },
  {  value: "Negombo", label: "Negombo, Sri Lanka" },
  {  value: "Nellaidhoo", label: "Nellaidhoo, Maldives" },
  {  value: "Nepal", label: "Nepal, Nepal" },
  {  value: "Nepalgunj", label: "Nepalgunj, Nepal" },
  {  value: "Netherlands", label: "Netherlands, Netherlands" },
  {  value: "Nevada", label: "Nevada, United States Of America" },
  {  value: "Newark", label: "Newark, United States Of America" },
  {  value: "Newport", label: "Newport, United States Of America" },
  {  value: "Newyork", label: "Newyork, United States Of America" },
  {  value: "Ngurah", label: "Ngurah, Indonesia" },
  {  value: "Niagara Falls", label: "Niagara Falls, United States Of America" },
  {  value: "Nigerisrael", label: "Nigerisrael, Caribbean" },
  {  value: "Nile", label: "Nile, Egypt" },
  {  value: "Nilecruise", label: "Nilecruise, Egypt" },
  {  value: "Niue", label: "Niue, Niue" },
  {  value: "Nomads", label: "Nomads, Morocco" },
  {  value: "Nong", label: "Nong, Thailand" },
  {  value: "Norfolk", label: "Norfolk, United States Of America" },
  {  value: "Northtour", label: "Northtour, Africa" },
  {  value: "Norway", label: "Norway, United States Of America" },
  {  value: "Nottingham", label: "Nottingham, Great Britain" },
  {  value: "Nusa", label: "Nusa, Indonesia" },
  {  value: "Nuwara", label: "Nuwara, Sri Lanka" },
  {  value: "Nuwaraeliya", label: "Nuwaraeliya, Sri Lanka" },
  {  value: "Nyalam", label: "Nyalam, Nepal" },
  {  value: "Oklahoma", label: "Oklahoma, Far East" },
  {  value: "Omaha", label: "Omaha, United States Of America" },
  {  value: "Ontario", label: "Ontario, Canada" },
  {  value: "Oosterhout", label: "Oosterhout, Netherlands" },
  {  value: "Orange", label: "Orange, United States Of America" },
  {  value: "Orchard", label: "Orchard, Singapore" },
  {  value: "Oregon", label: "Oregon, United States Of America" },
  {  value: "Orkney", label: "Orkney, Great Britain" },
  {  value: "Orlando", label: "Orlando, United States Of America" },
  {  value: "Osaka", label: "Osaka, Japan" },
  {  value: "Oslo", label: "Oslo, Norway" },
  {  value: "Ottawa", label: "Ottawa, Canada" },
  {  value: "Ouarzazate", label: "Ouarzazate, Morocco" },
  {  value: "Oudtshoorn", label: "Oudtshoorn, South Africa" },
  {  value: "Pabna", label: "Pabna, Bangladesh" },
  {  value: "Pacific", label: "Pacific, Iceland" },
  {  value: "Padang", label: "Padang, Indonesia" },
  {  value: "Pahang", label: "Pahang, Malaysia" },
  {  value: "Pakistan", label: "Pakistan, Pakistan" },
  {  value: "Palastine", label: "Palastine, Israel" },
  {  value: "Palau", label: "Palau, Italy" },
  {  value: "Palma", label: "Palma, Spain" },
  {  value: "Pamplemousse", label: "Pamplemousse, Mauritius" },
  {  value: "Pamukkale", label: "Pamukkale, Turkey" },
  {  value: "Panama", label: "Panama, South America" },
  {  value: "Pangboche", label: "Pangboche, Nepal" },
  {  value: "Pangkalanbun", label: "Pangkalanbun, Indonesia" },
  {  value: "Papeete", label: "Papeete, France" },
  {  value: "Papua", label: "Papua, Papua New Guinea" },
  {  value: "Paradise", label: "Paradise, United States Of America" },
  {  value: "Paraguay", label: "Paraguay, Paraguay" },
  {  value: "Paris", label: "Paris, France" },
  {  value: "Parish", label: "Parish, United States Of America" },
  {  value: "Pasay", label: "Pasay, Philippines" },
  {  value: "Pashupatinath", label: "Pashupatinath, Nepal" },
  {  value: "Pattaya", label: "Pattaya, Thailand" },
  {  value: "Pattivasal", label: "Pattivasal, Indian Subcontinent" },
  {  value: "Penang", label: "Penang, Malaysia" },
  {  value: "Pennington", label: "Pennington, United States Of America" },
  {  value: "Pennsylvania", label: "Pennsylvania, United States Of America" },
  {  value: "Perdana", label: "Perdana, Singapore" },
  {  value: "Perhentian", label: "Perhentian, Malaysia" },
  {  value: "Perth", label: "Perth, Australia" },
  {  value: "Peru", label: "Peru, Peru" },
  {  value: "Peshawar", label: "Peshawar, Pakistan" },
  {  value: "St. Petersburg", label: "St. Petersburg, Russia" },
  {  value: "Petra", label: "Petra, Jordan" },
  {  value: "Phakding", label: "Phakding, Nepal" },
  {  value: "Phang", label: "Phang, Thailand" },
  {  value: "Pheuntsholing", label: "Pheuntsholing, Bhutan" },
  {  value: "Phi Phi Islands", label: "Phi Phi Islands, Thailand" },
  {  value: "Philadelphia", label: "Philadelphia, United States Of America" },
  {  value: "Philippines", label: "Philippines, Pacific/Oceania" },
  {  value: "Phnompenh", label: "Phnompenh, Cambodia" },
  {  value: "Phuket", label: "Phuket, Thailand" },
  {  value: "Phulshillong", label: "Phulshillong, Bhutan" },
  {  value: "Phunakha", label: "Phunakha, Bhutan" },
  {  value: "Phunchulling", label: "Phunchulling, Bhutan" },
  {  value: "Phuntsholing", label: "Phuntsholing, Bhutan" },
  {  value: "Pilgrims", label: "Pilgrims, United Kingdom" },
  {  value: "Pillipens", label: "Pillipens, Far East" },
  {  value: "Pinnawala", label: "Pinnawala, Sri Lanka" },
  {  value: "Pisa", label: "Pisa, Switzerland" },
  {  value: "Plan", label: "Plan, Spain" },
  {  value: "Plymouth", label: "Plymouth, United States Of America" },
  {  value: "Poblet", label: "Poblet, Spain" },
  {  value: "Pointe", label: "Pointe, Mauritius" },
  {  value: "Poland", label: "Poland, Poland" },
  {  value: "Polannaruwa", label: "Polannaruwa, Sri Lanka" },
  {  value: "Polonnaruwa", label: "Polonnaruwa, Sri Lanka" },
  {  value: "Polynesia", label: "Polynesia, Samoa" },
  {  value: "Pondoktangguy", label: "Pondoktangguy, Indonesia" },
  {  value: "Pontianak", label: "Pontianak, Indonesia" },
  {  value: "Poon", label: "Poon, Nepal" },
  {  value: "Portland", label: "Portland, United States Of America" },
  {  value: "Porto", label: "Porto, Spain" },
  {  value: "Portugal", label: "Portugal, Europe" },
  {  value: "Prague", label: "Prague, Czech Republic" },
  {  value: "Prasilin", label: "Prasilin, Seychelles" },
  {  value: "Praslin", label: "Praslin, Seychelles" },
  {  value: "Princes", label: "Princes, Australia" },
  {  value: "Puebla", label: "Puebla, Mexico" },
  {  value: "Puerto", label: "Puerto, Spain" },
  {  value: "Puket", label: "Puket, Thailand" },
  {  value: "Pullman", label: "Pullman, United States Of America" },
  {  value: "Purang", label: "Purang, Nepal" },
  {  value: "Putra", label: "Putra, Singapore" },
  {  value: "Putrajaya", label: "Putrajaya, Malaysia" },
  {  value: "Queensland", label: "Queensland, Australia" },
  {  value: "Queenstown", label: "Queenstown, New Zealand" },
  {  value: "Quezon", label: "Quezon, Philippines" },
  {  value: "Quito", label: "Quito, Ecuador" },
  {  value: "Qutar", label: "Qutar, MiddleEast" },
  {  value: "Kuwait", label: "Kuwait, Saudi Arabia" },
  {  value: "Raba", label: "Raba, Morocco" },
  {  value: "Rangpur", label: "Rangpur, Bangladesh" },
  {  value: "Ranipauwa", label: "Ranipauwa, Nepal" },
  {  value: "Ravanusa", label: "Ravanusa, Italy" },
  {  value: "Red", label: "Red, United Arab Emirates" },
  {  value: "Redwood", label: "Redwood, United States Of America" },
  {  value: "Rennes", label: "Rennes, France" },
  {  value: "Reston", label: "Reston, United States Of America" },
  {  value: "Rhine", label: "Rhine, United Kingdom" },
  {  value: "Richmond", label: "Richmond, United States Of America" },
  {  value: "Rio De Janeiro", label: "Rio De Janeiro, Brazil" },
  {  value: "Riska", label: "Riska, Europe" },
  {  value: "Ritigala", label: "Ritigala, Sri Lanka" },
  {  value: "River", label: "River, Great Britain" },
  {  value: "Riyadh", label: "Riyadh, Saudi Arabia" },
  {  value: "Road", label: "Road, Virgin Islands" },
  {  value: "Rocca", label: "Rocca, Italy" },
  {  value: "Romania", label: "Romania, Romania" },
  {  value: "Rome", label: "Rome, Italy" },
  {  value: "Rotorua", label: "Rotorua, New Zealand" },
  {  value: "Roudkhan", label: "Roudkhan, Iran" },
  {  value: "Sagam", label: "Sagam, Dominica" },
  {  value: "Sagarmatha", label: "Sagarmatha, Nepal" },
  {  value: "Saint", label: "Saint, Caribbean" },
  {  value: "Saitama", label: "Saitama, Japan" },
  {  value: "Sakkra", label: "Sakkra, Egypt" },
  {  value: "Salalah", label: "Salalah, Oman" },
  {  value: "Salvador", label: "Salvador, Brazil" },
  {  value: "Salzburg", label: "Salzburg, Czech Republic" },
  {  value: "Samarkand", label: "Samarkand, Uzbekistan" },
  {  value: "Samburu", label: "Samburu, Kenya" },
  {  value: "San Diego", label: "San Diego, United States Of America" },
  {  value: "San Francisco", label: "San Francisco, United States Of America" },
  {  value: "Sangir", label: "Sangir, Indonesia" },
  {
     value: "Sanluisobipspo",
    label: "Sanluisobipspo, United States Of America",
  },
  {  value: "Santa", label: "Santa, Bolivia" },
  {  value: "Santacruze", label: "Santacruze, Ecuador" },
  {  value: "Santiago", label: "Santiago, South America" },
  {  value: "Santo", label: "Santo, Dominican Republic" },
  {  value: "Santorini", label: "Santorini, Greece" },
  {  value: "Sarangkot", label: "Sarangkot, Nepal" },
  {  value: "Saratoga", label: "Saratoga, Spain" },
  {  value: "Scandinavia", label: "Scandinavia, Europe" },
  {  value: "Schaffhausen", label: "Schaffhausen, Europe" },
  {  value: "Schiphol", label: "Schiphol, Netherlands" },
  {  value: "Scotland", label: "Scotland, Europe" },
  {  value: "Sea", label: "Sea, Singapore" },
  {  value: "Seattle", label: "Seattle, United States Of America" },
  {  value: "Semarang", label: "Semarang, Indonesia" },
  {  value: "Senegal", label: "Senegal, Senegal" },
  {  value: "Sentosa", label: "Sentosa, Indonesia" },
  {  value: "Sentosa Island", label: "Sentosa Island, Singapore" },
  {  value: "Seorak", label: "Seorak, South Korea" },
  {  value: "Seoul", label: "Seoul, South Korea" },
  {  value: "Sepang", label: "Sepang, Malaysia" },
  {  value: "Serengeti", label: "Serengeti, Tanzania" },
  {  value: "Seri", label: "Seri, Indian Subcontinent" },
  {  value: "Serua", label: "Serua, Pacific/Oceania" },
  {  value: "Sevilla", label: "Sevilla, Spain" },
  {  value: "Shanghai", label: "Shanghai, China" },
  {  value: "Shanti", label: "Shanti, Dominica" },
  {  value: "Sharjah", label: "Sharjah, United Arab Emirates" },
  {  value: "Shenzhen", label: "Shenzhen, China" },
  {  value: "Shinjuku", label: "Shinjuku, Japan" },
  {  value: "Shinkansen", label: "Shinkansen, Japan" },
  {  value: "Shiraz", label: "Shiraz, Iran" },
  {  value: "Siberia", label: "Siberia, Russia" },
  {  value: "Sierranegravocano", label: "Sierranegravocano, Ecuador" },
  {  value: "Sigiriya", label: "Sigiriya, Sri Lanka" },
  {  value: "Siingapore", label: "Siingapore, Far East" },
  {  value: "Silang", label: "Silang, Philippines" },
  {  value: "Simalla", label: "Simalla, Ethiopia" },
  {  value: "Simmikot", label: "Simmikot, Nepal" },
  {  value: "Simsbury", label: "Simsbury, United States Of America" },
  {  value: "Sinagapore", label: "Sinagapore, Singapore" },
  {  value: "Skopje", label: "Skopje, Macedonia" },
  {  value: "Skoura", label: "Skoura, Morocco" },
  {  value: "Slovakia", label: "Slovakia, Germany" },
  {  value: "Slovenia", label: "Slovenia, Italy" },
  {  value: "Smila", label: "Smila, Ukraine" },
  {  value: "Smithsonian", label: "Smithsonian, United States Of America" },
  {  value: "Snow", label: "Snow, United Arab Emirates" },
  {  value: "Sochi", label: "Sochi, Russia" },
  {  value: "Soekarno", label: "Soekarno, Indonesia" },
  {  value: "Sofia", label: "Sofia, Europe" },
  {  value: "Solomon", label: "Solomon, Solomon Islands" },
  {  value: "Soule", label: "Soule, South Korea" },
  {  value: "Southampton", label: "Southampton, United Kingdom" },
  {  value: "Spain", label: "Spain, Spain" },
  {  value: "Sparta", label: "Sparta, United States Of America" },
  {  value: "Sphinx", label: "Sphinx, Egypt" },
  {  value: "Sri lanka", label: "Srilanka, Sri Lanka" },
  {  value: "Stanfordville", label: "Stanfordville, United States Of America" },
  {  value: "Stockholm", label: "Stockholm, Sweden" },
  {  value: "Storm", label: "Storm, Australia" },
  {  value: "Strasbourg", label: "Strasbourg, France" },
  {  value: "Stumpa", label: "Stumpa, Dominica" },
  {  value: "Stuttgart", label: "Stuttgart, Germany" },
  {  value: "Sucre", label: "Sucre, Venezuela" },
  {  value: "Summerkhand", label: "Summerkhand, Uzbekistan" },
  {  value: "Superstargeminicruise", label: "Superstargeminicruise, Malaysia" },
  {  value: "Surabaya", label: "Surabaya, Indonesia" },
  {  value: "Surry", label: "Surry, Australia" },
  {  value: "Swakopmund", label: "Swakopmund, Namibia" },
  {  value: "Swarovski", label: "Swarovski, Europe" },
  {  value: "Switzerland", label: "Switzerland, France" },
  {  value: "Sydney", label: "Sydney, Australia" },
  {  value: "Sylhet", label: "Sylhet, Bangladesh" },
  {  value: "Syria", label: "Syria, MiddleEast" },
  {  value: "Taiwang", label: "Taiwang, Far East" },
  {  value: "Tanjungharapan", label: "Tanjungharapan, Indonesia" },
  {  value: "Tannersville", label: "Tannersville, United States Of America" },
  {  value: "Tanzania", label: "Tanzania, Kenya" },
  {  value: "Tarboche", label: "Tarboche, Nepal" },
  {  value: "Taringa", label: "Taringa, Australia" },
  {  value: "Tashkent", label: "Tashkent, Uzbekistan" },
  {  value: "Tasmania", label: "Tasmania, Australia" },
  {  value: "Taveuni", label: "Taveuni, Fiji" },
  {  value: "Tawangmangu", label: "Tawangmangu, Indonesia" },
  {  value: "Thailand", label: "Thailand, Thailand" },
  {  value: "Thakudwara", label: "Thakudwara, Nepal" },
  {  value: "Thangnak", label: "Thangnak, Nepal" },
  {  value: "Thinadhoo", label: "Thinadhoo, Maldives" },
  {  value: "Thirappane", label: "Thirappane, Sri Lanka" },
  {  value: "Thulusdhoo", label: "Thulusdhoo, Maldives" },
  {  value: "Tiananmen", label: "Tiananmen, China" },
  {  value: "Tibet", label: "Tibet, China" },
  {  value: "Timberlake", label: "Timberlake, United States Of America" },
  {  value: "Timor", label: "Timor, Indonesia" },
  {  value: "Tioman", label: "Tioman, Malaysia" },
  {  value: "Tirana", label: "Tirana, Europe" },
  {  value: "Tirinadad", label: "Tirinadad, Caribbean" },
  {  value: "Tissa", label: "Tissa, Sri Lanka" },
  {  value: "Todra", label: "Todra, Morocco" },
  {  value: "Tokyo", label: "Tokyo, Japan" },
  {  value: "Tongi", label: "Tongi, Bangladesh" },
  {  value: "Tongsa", label: "Tongsa, Bhutan" },
  {  value: "Tonka", label: "Tonka, United Kingdom" },
  {  value: "Tonle", label: "Tonle, Far East" },
  {  value: "Tornado", label: "Tornado, United States Of America" },
  {  value: "Toronto", label: "Toronto, Canada" },
  {  value: "Triconomallee", label: "Triconomallee, Sri Lanka" },
  {  value: "Trincomalee", label: "Trincomalee, Sri Lanka" },
  {  value: "Trinidad", label: "Trinidad, Trinidad And Tobago" },
  {  value: "Trongsa", label: "Trongsa, Bhutan" },
  {  value: "Trou", label: "Trou, Mauritius" },
  {  value: "Tsavo", label: "Tsavo, Kenya" },
  {  value: "Tunisia", label: "Tunisia, Tunisia" },
  {  value: "Turkey", label: "Turkey, Turkey" },
  {  value: "Turku", label: "Turku, Finland" },
  {  value: "Uganda", label: "Uganda, Uganda" },
  {  value: "Ukraine", label: "Ukraine, Ukraine" },
  {  value: "Umarh", label: "Umarh, Saudi Arabia" },
  {  value: "Unawatuna", label: "Unawatuna, Sri Lanka" },
  {  value: "Universal", label: "Universal, Singapore" },
  {  value: "Uruguay", label: "Uruguay, Uruguay" },
  {  value: "USA", label: "USA, United States Of America" },
  {  value: "Uzbekistan", label: "Uzbekistan, Uzbekistan" },
  {  value: "Vancouver", label: "Vancouver, Canada" },
  {  value: "Vanish", label: "Vanish, United Kingdom" },
  {  value: "Vapiano", label: "Vapiano, Great Britain" },
  {  value: "Varani", label: "Varani, United States Of America" },
  {  value: "Vatican", label: "Vatican, Europe" },
  {  value: "Vaughan", label: "Vaughan, Canada" },
  {  value: "Venezuela", label: "Venezuela, Venezuela" },
  {  value: "Venice", label: "Venice, Italy" },
  {  value: "Verginia", label: "Verginia, United States Of America" },
  {  value: "Victoria", label: "Victoria, Malta" },
  {  value: "Vietnam", label: "Vietnam, Vietnam" },
  {  value: "Virginia", label: "Virginia, United States Of America" },
  {  value: "Waitomo", label: "Waitomo, New Zealand" },
  {  value: "Wanak", label: "Wanak, New Zealand" },
  {  value: "Wanaka", label: "Wanaka, New Zealand" },
  {  value: "Wangdi", label: "Wangdi, Bhutan" },
  {  value: "Warsaw", label: "Warsaw, Poland" },
  {  value: "Tallinn", label: "Tallinn, Estonia" },
  {  value: "Washington", label: "Washington, United States Of America" },
  {  value: "Wellington", label: "Wellington, New Zealand" },
  {  value: "Williamsburg", label: "Williamsburg, United States Of America" },
  {  value: "Yangon", label: "Yangon, Myanmar" },
  {  value: "Yojiya", label: "Yojiya, Sweden" },
  {  value: "Zagreb", label: "Zagreb, Italy" },
  {  value: "Zambezi", label: "Zambezi, MiddleEast" },
  {  value: "Zamboanga", label: "Zamboanga, Philippines" },
  {  value: "Zanasker", label: "Zanasker, Dominica" },
  {  value: "Zanzibar", label: "Zanzibar, United States Of America" },
  {  value: "Zaragoza", label: "Zaragoza, Spain" },
  {  value: "Zermatt", label: "Zermatt, Switzerland" },
  {  value: "Zimbabwe", label: "Zimbabwe, South Africa" },
  {  value: "Zimut", label: "Zimut, MiddleEast" },
  {  value: "Zurich", label: "Zurich, Switzerland" },
  {  value: "Zuthulphuk", label: "Zuthulphuk, Nepal" },
  {  value: "Portblair", label: "Portblair, India" },
  {  value: "Rohtang Pass", label: "Rohtang Pass, India" },
  {  value: "Kashmir", label: "Kashmir, India" },
  {  value: "Vaishno Devi", label: "Vaishno Devi, India" },
  {  value: "Tamilnadu", label: "Tamilnadu, India" },
  {  value: "Dharamsala", label: "Dharamsala, India" },
  {  value: "Singapore", label: "Singapore, Singapore" },
  {
     value: "United Arab Emirates",
    label: "United Arab Emirates, United Arab Emirates",
  },
  {  value: "Indonesia", label: "Indonesia, Indonesia" },
  {
     value: "United States Of America",
    label: "United States Of America, United States Of America",
  },
  {  value: "Europe", label: "Europe, Europe" },
  {  value: "Saudi Arabia", label: "Saudi Arabia, Saudi Arabia" },
  {  value: "United Kingdom", label: "United Kingdom, United Kingdom" },
  {  value: "Russia", label: "Russia, Russia" },
  {  value: "Seychelles", label: "Seychelles, Seychelles" },
  {  value: "South Africa", label: "South Africa, South Africa" },
  {  value: "Belarus", label: "Belarus, Belarus" },
  {  value: "South Korea", label: "South Korea, South Korea" },
  {  value: "New Zealand", label: "New Zealand, New Zealand" },
  {  value: "South America", label: "South America, South America" },
  {  value: "Qatar", label: "Qatar, Qatar" },
  {  value: "Iceland", label: "Iceland, Iceland" },
  {  value: "North America", label: "North America, North America" },
  {  value: "Czech Republic", label: "Czech Republic, Czech Republic" },
  {  value: "Sweden", label: "Sweden, Sweden" },
  {  value: "Kazakhstan", label: "Kazakhstan, Kazakhstan" },
  {  value: "Oman", label: "Oman, Oman" },
  {  value: "Myanmar", label: "Myanmar, Myanmar" },
  {  value: "Argentina", label: "Argentina, Argentina" },
  {  value: "Namibia", label: "Namibia, Namibia" },
  {  value: "New Delhi", label: "New Delhi, India" },
  {  value: "New York", label: "New York, United States Of America" },
  {  value: "Durban", label: "Durban, South Africa" },
  {  value: "pretoria", label: "pretoria, South Africa" },
  {  value: "Windhoek", label: "Windhoek, Namibia" },
  {  value: "Bali", label: "Bali, Indonesia" },
  {  value: "Bintan", label: "Bintan, Indonesia" },
  {  value: "Tangier", label: "Tangier, Morocco" },
  {  value: "Port Louis", label: "Port Louis, Mauritius" },
  {  value: "Tel Aviv", label: "Tel Aviv, Israel" },
  {  value: "Beersheba", label: "Beersheba, Israel" },
  {  value: "Netanya", label: "Netanya, Israel" },
  {  value: "Acre", label: "Acre, Israel" },
  {  value: "Islamabad", label: "Islamabad, Pakistan" },
  {  value: "Aqaba", label: "Aqaba, Jordan" },
  {  value: "Sao Paulo", label: "Sao Paulo, Brazil" },
  {  value: "Brasilia", label: "Brasilia, Brazil" },
  {  value: "Buenos Aires", label: "Buenos Aires, Argentina" },
];

export default optionList;
