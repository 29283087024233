// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tab-buttons {
  display: flex;
  padding: 8px 8px 0 8px;
  border-bottom: 1px solid #ececec;
  overflow-x: auto; 
  margin-bottom: 8px;  
  flex-wrap: nowrap;
}

.tab-button {
  padding: 8px 12px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: center;
  font-weight: 600;
  color: #555;
  transition: background-color 0.3s ease-in-out;
  font-size: 14px;
}

.tab-button:hover {
  color: #594cda;
}

.tab-button.active {
  color: #594cda;
  border-bottom: 2px solid #594cda;
}

@media screen and (max-width: 768px) {
  .tab-buttons {
    overflow-x: scroll; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }

  .tab-buttons::-webkit-scrollbar {
    display: none; 
  }

  .tab-button {
    padding: 8px 12px;
    white-space: nowrap;
  }
}
@media screen and (min-width: 769px) {
  .tab-button {
    padding: 8px 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Tabs.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gCAAgC;EAChC,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,6CAA6C;EAC7C,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,gCAAgC;AAClC;;AAEA;EACE;IACE,kBAAkB;IAClB,wBAAwB;IACxB,qBAAqB;EACvB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".tabs-container {\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.tab-buttons {\r\n  display: flex;\r\n  padding: 8px 8px 0 8px;\r\n  border-bottom: 1px solid #ececec;\r\n  overflow-x: auto; \r\n  margin-bottom: 8px;  \r\n  flex-wrap: nowrap;\r\n}\r\n\r\n.tab-button {\r\n  padding: 8px 12px;\r\n  cursor: pointer;\r\n  border: none;\r\n  outline: none;\r\n  background-color: transparent;\r\n  text-align: center;\r\n  font-weight: 600;\r\n  color: #555;\r\n  transition: background-color 0.3s ease-in-out;\r\n  font-size: 14px;\r\n}\r\n\r\n.tab-button:hover {\r\n  color: #594cda;\r\n}\r\n\r\n.tab-button.active {\r\n  color: #594cda;\r\n  border-bottom: 2px solid #594cda;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .tab-buttons {\r\n    overflow-x: scroll; \r\n    -ms-overflow-style: none; \r\n    scrollbar-width: none; \r\n  }\r\n\r\n  .tab-buttons::-webkit-scrollbar {\r\n    display: none; \r\n  }\r\n\r\n  .tab-button {\r\n    padding: 8px 12px;\r\n    white-space: nowrap;\r\n  }\r\n}\r\n@media screen and (min-width: 769px) {\r\n  .tab-button {\r\n    padding: 8px 16px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
