// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table th,
.table td {
    border: none;
    padding: 15px 5px;
    text-align: center;
    /* color: #76838f; */
    font-size: 14px;
}

.lead-table {
    width: 100%;
    overflow: auto;
    max-height: 400px;
    position: relative;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table thead {
    position: sticky;
    top: 0;
    background-color: #fff;
}
.table tbody {
    max-height: 300px;
    overflow-y: auto;
}

.fixed-buttons {
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 2;
    padding: 10px;
}

.mylead-table tbody tr {
    transition: transform 0.2s ease; 
  }
  
  .mylead-table tbody tr:hover {
    background-color: #e0e0e0; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    cursor: pointer; 
    transform: translateY(-2px); 
  }
  /* .assigned select {
    background: #0000;
    border: 1px solid #ccc;
    outline: none;
    padding: 4px 8px;

} */

    .assigned select {
    background: #0000;
    border: 1px solid #ccc;
    max-width: 100px;
    min-width: 100px;
    outline: none;
    padding: 4px 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Table.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,sBAAsB;AAC1B;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,SAAS;IACT,uBAAuB;IACvB,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,+BAA+B;EACjC;;EAEA;IACE,yBAAyB;IACzB,uCAAuC;IACvC,eAAe;IACf,2BAA2B;EAC7B;EACA;;;;;;GAMC;;IAEC;IACA,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".table th,\r\n.table td {\r\n    border: none;\r\n    padding: 15px 5px;\r\n    text-align: center;\r\n    /* color: #76838f; */\r\n    font-size: 14px;\r\n}\r\n\r\n.lead-table {\r\n    width: 100%;\r\n    overflow: auto;\r\n    max-height: 400px;\r\n    position: relative;\r\n}\r\n\r\n.table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n}\r\n\r\n.table thead {\r\n    position: sticky;\r\n    top: 0;\r\n    background-color: #fff;\r\n}\r\n.table tbody {\r\n    max-height: 300px;\r\n    overflow-y: auto;\r\n}\r\n\r\n.fixed-buttons {\r\n    position: sticky;\r\n    bottom: 0;\r\n    background-color: white;\r\n    z-index: 2;\r\n    padding: 10px;\r\n}\r\n\r\n.mylead-table tbody tr {\r\n    transition: transform 0.2s ease; \r\n  }\r\n  \r\n  .mylead-table tbody tr:hover {\r\n    background-color: #e0e0e0; \r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); \r\n    cursor: pointer; \r\n    transform: translateY(-2px); \r\n  }\r\n  /* .assigned select {\r\n    background: #0000;\r\n    border: 1px solid #ccc;\r\n    outline: none;\r\n    padding: 4px 8px;\r\n\r\n} */\r\n\r\n    .assigned select {\r\n    background: #0000;\r\n    border: 1px solid #ccc;\r\n    max-width: 100px;\r\n    min-width: 100px;\r\n    outline: none;\r\n    padding: 4px 8px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
