// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-button-container label {
    display: flex; 
    align-items: flex-start;
}

.radio-button-container input[type="radio"] {
    margin-right: 5px;
}

.radio-button-container input[type="radio"]:checked+label {
    font-weight: bold;
    color: #007bff;
}`, "",{"version":3,"sources":["webpack://./src/components/RadioButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".radio-button-container label {\r\n    display: flex; \r\n    align-items: flex-start;\r\n}\r\n\r\n.radio-button-container input[type=\"radio\"] {\r\n    margin-right: 5px;\r\n}\r\n\r\n.radio-button-container input[type=\"radio\"]:checked+label {\r\n    font-weight: bold;\r\n    color: #007bff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
