import React, { useState, useEffect } from "react";
import InnerHeader from "../components/InnerHeader";
import Card from "../components/Card";
import Tabs from "../components/Tabs";
import "../pages/MyAccount.css";
import Settings from "./Settings";
import MyPlan from "./MyPlan";
import Kyc from "./Kyc";
import Profile from "./Profile";
import LeadManagement from "./LeadManagement";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTitle } from "../hooks/useTitle";
import trackEvent from "../utils/gaTracker";
import Catalog from "./Catalog";

const MyAccount = () => {
  const user = useSelector((state) => state.auth);
  const [authId, setAuthID] = useState(user.auth_id);
  const [nextActiveTab, setNextActiveTab] = useState("1");
  let tabs;
  useTitle("My Accounts -  Travel Agents CRM - Hellotravel");

  if (user.role === "2") {
    tabs = [
      { label: "My Plan", value: "My Plan" },
      { label: "Lead Management", value: "lead-management" },
      // { label: "Catalog", value: "catalog" },
      { label: "Profile", value: "Profile" },
      { label: "KYC", value: "KYC" },
      { label: "Settings", value: "Settings" },
    ];
  } else {
    tabs = [{ label: "Settings", value: "Settings" }];
  }

  const [selectedTab, setSelectedTab] = useState(tabs[0]["label"]);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("tab");
    if (tabParam && tabs.some((tab) => tab.value === tabParam)) {
      setSelectedTab(tabParam);
    }
  }, [location.search]);

  const handleTabChange = (value) => {
    trackEvent("My Account Page", "click", `tab Name: ${value}`);
    setSelectedTab(value);
  };
  return (
    <div className="main-content-padding">
      <InnerHeader
        heading="My Account"
        showButton={false}
        txtSubHeading="Effortlessly take control of your account and customize your preferences and settings to your liking."
      />
      <Card className="bg5">
        <Tabs
          tabs={tabs}
          showCheckboxes={false}
          showFilter={false}
          onTabChange={handleTabChange}
          selectedTab={selectedTab}
          nextActiveTab={nextActiveTab}
        />
        {selectedTab === "My Plan" && <MyPlan />}
        {selectedTab === "Settings" && <Settings />}
        {selectedTab === "catalog" && <Catalog />}
        {selectedTab === "KYC" && <Kyc handleSelectTab={handleTabChange} />}
        {selectedTab === "Profile" && (
          <Profile handleSelectTab={handleTabChange} />
        )}
        {selectedTab === "lead-management" && (
          <LeadManagement handleSelectTab={handleTabChange} />
        )}
      </Card>
    </div>
  );
};

export default MyAccount;
