// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-card-header{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/MyPlan.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,4BAA4B;AAChC","sourcesContent":[".profile-card-header{\r\n    border-top-left-radius: 8px;\r\n    border-top-right-radius: 8px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
