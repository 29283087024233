import React, { useState } from 'react';
import { FiSearch } from "react-icons/fi";


function SearchInput({ onSearch,searchValue,setSearchValue }) {
 
  const handleSearch = () => {
    if (searchValue.trim() !== '') {
      onSearch(searchValue);
    }else{
      onSearch("");
    }
  };

  const handleInputChange = (e) => {
    if(e.target.value==''){
      onSearch("");
    }
    setSearchValue(e.target.value);
  };

  return (
    <div className="search-input df aic">
      <input
        type="search"
        placeholder="Search Destination"
        value={searchValue}
        onChange={handleInputChange}
        className='p8 brd1 outn br4 w100'
      />
      <button onClick={handleSearch} className='p6 bg1 fc3 br4 ml8 cp'><FiSearch className='fs20'/></button>
    </div>
  );
}

export default SearchInput;
