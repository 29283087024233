// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myteam-button svg {
  width: 20px;
  height: 20px;
  font-weight: 800;
}
.myteam-button button {
  color: #fff;
}
.myteam-button button {
  padding: 12px 20px;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  border: none;
  cursor: pointer;
}
.btn-blue {
  background-color: #594cda;
  color: #fff;
}

@media screen and (max-width: 480px) {
  .myteam-button {
    margin-left: 0;
    margin-top: 12px;
    /* justify-content: start; */
  }
  .inner-header .flx75,
  .myteam-button {
    flex: 0 0 100%;
    max-width: 100%;  
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .myteam-button {
    margin-left: 0;
    margin-top: 12px;
    /* justify-content: start; */
  }
  .inner-header .flx75,
  .myteam-button {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 1024px)  {
  .hitworks-popup{
    max-width: 35% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/InnerHeader.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE;IACE,cAAc;IACd,gBAAgB;IAChB,4BAA4B;EAC9B;EACA;;IAEE,cAAc;IACd,eAAe;EACjB;AACF;;AAEA;EACE;IACE,cAAc;IACd,gBAAgB;IAChB,4BAA4B;EAC9B;EACA;;IAEE,cAAc;IACd,eAAe;EACjB;AACF;AACA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".myteam-button svg {\n  width: 20px;\n  height: 20px;\n  font-weight: 800;\n}\n.myteam-button button {\n  color: #fff;\n}\n.myteam-button button {\n  padding: 12px 20px;\n  border-radius: 64px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-weight: 500;\n  font-size: 14px;\n  border: none;\n  cursor: pointer;\n}\n.btn-blue {\n  background-color: #594cda;\n  color: #fff;\n}\n\n@media screen and (max-width: 480px) {\n  .myteam-button {\n    margin-left: 0;\n    margin-top: 12px;\n    /* justify-content: start; */\n  }\n  .inner-header .flx75,\n  .myteam-button {\n    flex: 0 0 100%;\n    max-width: 100%;  \n  }\n}\n\n@media screen and (min-width: 481px) and (max-width: 1024px) {\n  .myteam-button {\n    margin-left: 0;\n    margin-top: 12px;\n    /* justify-content: start; */\n  }\n  .inner-header .flx75,\n  .myteam-button {\n    flex: 0 0 100%;\n    max-width: 100%;\n  }\n}\n@media screen and (min-width: 1024px)  {\n  .hitworks-popup{\n    max-width: 35% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
