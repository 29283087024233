import axios from "axios";
import React, { useState, useEffect } from "react";
import Card from "../components/Card";
import "../pages/MyAccount.css";
import { BsInfoCircleFill } from "react-icons/bs";
import Box from "../components/Box";
import Addons from "../components/Addons";
import user from "../assets/user.png";
import "../pages/MyPlan.css";
import Tooltip from "../components/Tooltip";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../hooks/useTitle";
import PlanPopup from "../components/Popup/PlanPopup.js";
import trackEvent from "../utils/gaTracker";

const MyPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useTitle("My Plan -  Travel Agents CRM - Hellotravel");

  const user = useSelector((state) => state.auth);

  const [authId, setAuthID] = useState(user.auth_id);
  const [dataStatus, setDataStatus] = useState(false);
  const [planDatas, setPlanDatas] = useState([]);
  const planData = {
    logo: "https://example.com/logo.png",
    name: "Nand Kumar Jha",
    currentPlanName: "Basic Pro",
    planHighlights: [
      { name: "Published Packages", value: 24 },
      { name: "Premium listing", value: 1 },
      { name: "Team Size", value: 3 },
      { name: "Total Leads Received", value: 6300 },
    ],

    plans: [
      {
        name: "Basic",
        price: "22000",
        inauguralOffer: "18000",
        validity: "20 Days",
        noCostEMI: "NA",
        numberOfConnects: "4",
        surgePricingApplicable: "Yes",
        approxLeads: "150/75",
        leadsOnWhatsApp: "Yes",
        premiumListing: "NA",
        webCatalog: "NA",
        leadsFromPackages: "NA",
        topUpOption: "NA",
        rmAssistance: "NA",
        inboundUserCalls: "NA",
        inboundCallsOfTotalLeads: "NA",
        highlightedListing: "NA",
        gstApplicable: "18% GST extra: Applicable",
      },
      {
        name: "Basic Pro",
        price: "22000",
        inauguralOffer: "17000",
        validity: "45 Days",
        noCostEMI: "NA",
        numberOfConnects: "4",
        surgePricingApplicable: "Yes",
        approxLeads: "150/75",
        leadsOnWhatsApp: "Yes",
        premiumListing: "NA",
        webCatalog: "NA",
        leadsFromPackages: "NA",
        topUpOption: "NA",
        rmAssistance: "NA",
        inboundUserCalls: "NA",
        inboundCallsOfTotalLeads: "NA",
        highlightedListing: "NA",
        gstApplicable: "18% GST extra: Applicable",
      },
      {
        name: "Gold",
        price: "22000",
        inauguralOffer: "16000",
        validity: "60 Days",
        noCostEMI: "NA",
        numberOfConnects: "4",
        surgePricingApplicable: "Yes",
        approxLeads: "150/75",
        leadsOnWhatsApp: "Yes",
        premiumListing: "NA",
        webCatalog: "NA",
        leadsFromPackages: "NA",
        topUpOption: "NA",
        rmAssistance: "NA",
        inboundUserCalls: "NA",
        inboundCallsOfTotalLeads: "NA",
        highlightedListing: "NA",
        gstApplicable: "18% GST extra: Applicable",
      },
    ],
    rmNumber: "+91-9818250039",
    addons: [
      {
        name: "Itinerary Builder",
        text: "Effortless package management: Create, send, share securely, and download your itineraries as PDFs.",
        price: "10,000",
        inaugural_offer: "Free",
        Validity: "As per plan",
      },
    ],
  };

  const [isExpiringSoon, setIsExpiringSoon] = useState(false);
  const [remainingDaysleft, setRemainingDaysLeft] = useState(false);

  const [rmName, setRmName] = useState("");
  const [rmNumber, setRmNumber] = useState("");
  const [rmEmail, setRmEmail] = useState("");
  const [showRMContact, setShowRMContact] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [currentPlanName, setCurrentPlanName] = useState("Free Plan");
  const [companyName, setCompanyName] = useState("");
  const [userimg, setUserImg] = useState(
    "/static/media/user.5faf09a7795d28bf5a2b.png"
  );

  const [publishedPkg, setPublishedPkg] = useState("0");
  const [premiumListing, setPremiumListing] = useState("0");
  const [teamSize, setTeamSize] = useState("0");
  const [totalLeadRecvd, setTotalLeadRecvd] = useState("0");
  const [showPlanDetails, setShowPlanDetails] = useState(false);

  const [showRMNumber, setShowRMNumber] = useState(false);

  // useEffect(() => {
  //   // Calculate the remaining days here
  //   const remainingDays = 10; // Replace with the actual calculation
  //   setRemainingDaysLeft(remainingDays);
  //   // Check if the expiration date is 10 days or less
  //   if (remainingDays <= 10) {
  //     setIsExpiringSoon(true);
  //   } else {
  //     setIsExpiringSoon(false);
  //   }
  // }, []);

  const toggleRMContact = () => {
    setShowRMContact(!showRMContact);
    handleSendMailFun(currentPlanName, "3");
    trackEvent("My Plan Page", "click", "Contact RM");
  };
  const toggleRMNumber = (plan_name) => {
    trackEvent("My Plan Page", "click", `View All - ${plan_name} - Contact Sales`);
    setShowRMNumber(!showRMNumber);
    handleSendMailFun(plan_name, "1");
  };

  const handleViewAllClick = (plan) => {
    trackEvent("My Plan Page", "click", `View All - ${plan[0].value}`);
    setShowPlanDetails(true);
    setSelectedPlan(plan);
    // window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    getPlanData();
  }, []);

  function getPlanData() {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=getMyPlanData`,
      headers: {},
      data: {
        auth_id: authId,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }

      if (response.data.data.plans) {
        setPlanDatas(response.data.data.plans);
      }

      if (response.data.data.otherdetail) {
        setRmNumber(response.data.data.otherdetail.RMMOBILE);
        setCurrentPlanName(response.data.data.otherdetail.PKGNAME);
        setRemainingDaysLeft(response.data.data.otherdetail.PKGDAYLIMIT);
        setCompanyName(response.data.data.otherdetail.TBL_LOGIN_COMPANYNAME);
        if (response.data.data.otherdetail.USER_SIGNATURE_IMAGE) {
          setUserImg(
            user.img_url +
              "logo/" +
              response.data.data.otherdetail.USER_SIGNATURE_IMAGE
          );
        }
        setPublishedPkg(response.data.data.otherdetail.TOTALPKGCOUNT);
        setPremiumListing(response.data.data.otherdetail.SPONSTOTALPKGCOUNT);
        setTeamSize(response.data.data.otherdetail.TOTALTEAM);
        setTotalLeadRecvd(response.data.data.otherdetail.TOTALLEADCOUNT);
        setRmEmail(response.data.data.otherdetail.RMEMAIL);
        setRmName(response.data.data.otherdetail.RMNAME);

        if (response.data.data.otherdetail.PKGDAYLIMIT < 40) {
          setIsExpiringSoon(true);
        }
      }

      setDataStatus(true);
    });
  }

  const handleLogout = () => {
    // Dispatch the logout action to clear user data
    dispatch(logout());
    // Redirect to the login page
    navigate("/login");
  };

  const handleSendMailFun = (plan_name, Type) => {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=sendMailToRm`,
      headers: {},
      data: {
        auth_id: user.auth_id,
        plan_name: plan_name,
        rm_name: rmName,
        rm_email: rmEmail,
        type: Type ? Type : "",
      },
    }).then(function (response) {});
  };

  return (
    <div className="df mr20">
      <div className="w66">
        <div className="account-details df pl20 pr20 fdc">
          <h3 className=" fc1 fw5 fs20 mt16 mb24">
            Plan
            <div className="fs14 fc16 mt8 fw4 lh16">
              Manage your subscription, upgrade or set payments.
            </div>
          </h3>
          <div className="card-wrapper">
            <Card className="card-info bg17 df fdc aic">
              <div className="pimg text-center">
                <img src={userimg} alt="" width="70" height="70" />
              </div>
              <div className="text-center fc3 fs18 fw6 mt4 ls1 lh22 captw">
                {companyName}
              </div>
              <div className="text-center fc3 fs14 fw5 mt12 v-center">
                {currentPlanName}{" "}
                <Tooltip title="upgrade for more">
                  <BsInfoCircleFill className="ml4 fs12 fc17" />
                </Tooltip>
              </div>
              <div className="brd-b1 w100 mt24 mb24"></div>
              <div className="df jcsb w100">
                {currentPlanName !== "Free Plan" && (
                  <div className="df fdc lead-credits">
                    <div className="fc3 fs14 v-center ls1">
                      Expiry In
                      {/* <BsInfoCircleFill className="ml4 fs12 fc5 fc17" /> */}
                    </div>
                    <div className="fc3 fs14 fw5 lh18 mt8 ls1">
                      {isExpiringSoon ? (
                        <span className="blink-text">
                          {remainingDaysleft} Days
                        </span>
                      ) : (
                        <span className="fc2">{remainingDaysleft} Days</span>
                      )}
                    </div>
                  </div>
                )}

                <div
                  onClick={toggleRMContact}
                  className="show-rm fc3 br8 p12 cp ls2 box-center fs14 bg4"
                  style={{
                    width: "150px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <p className={`${showRMContact ? "slide-in" : ""}`}>
                    {showRMContact ? `${rmNumber}` : "Contact RM"}
                  </p>
                </div>
              </div>
            </Card>
            <Card className="card-info bg7 ml24">
              <div className="brd-b2 fs14 pb16 pt16 df jcsb fc16 ls1 lh22">
                Published Packages
                <span className="fc15 fw6 pull-right">
                  <span className="">{publishedPkg}</span>
                </span>
              </div>
              <div className="brd-b2 fs14 pb16 pt16 df jcsb fc16 ls1 lh22">
                Premium Listing
                <span className="fc15 fw6 pull-right">
                  <span className="">{premiumListing}</span>
                </span>
              </div>

              <div className="brd-b2 fs14 pb16 pt16 df jcsb fc16 ls1 lh22">
                Team Size
                <span className="fc15 fw6 pull-right">
                  <span className="">{teamSize}</span>
                </span>
              </div>

              <div className="brd-b2 fs14 pb16 pt16 df jcsb fc16 ls1 lh22">
                {currentPlanName === "Free Plan"
                  ? "Total Leads Received "
                  : "Leads Since Renewal"}
                <span className="fc15 fw6 pull-right">
                  <span className="">{totalLeadRecvd}</span>
                </span>
              </div>
            </Card>
          </div>

          <div className="available-plans">
            <div className="available-plans-heading mb12 mt24">
              <h3 className="fc15 fs18 fw5">Available Plans </h3>
            </div>
            <div className="card-wrapper fww">
              {planDatas.map((plan, index) => (
                <Box
                  className="card-info"
                  plan={plan}
                  key={index}
                  currentPlanName={currentPlanName}
                  rmNumber={rmNumber}
                  handleSendMail={() =>
                    handleSendMailFun(
                      plan.find((item) => item.key === "Name")?.value,
                      "1"
                    )
                  }
                  onViewAllClick={() => handleViewAllClick(plan)}
                />
              ))}
            </div>
          </div>

          <div className="available-addons">
            <div className="available-addons-heading mb12 mt24">
              <h3 className="fc15 fs18 fw5">Available Addons </h3>
            </div>
            <div className="card-wrapper fww">
              {planData.addons.map((addon, index) => (
                <Addons
                  className="card-info"
                  currentPlanName={currentPlanName}
                  addon={addon}
                  key={index}
                  rmNumber={rmNumber}
                  handleSendMail={() => handleSendMailFun(addon.name, "2")}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {showPlanDetails && (
        <PlanPopup
          show={showPlanDetails}
          onClose={() => {
            setShowPlanDetails(false);
          }}
        >
          <div className="selected-plan-details">
            <div className="df jcsb profile-card-header brd-b1 p12 box-center bg7  w100 fc1 ls2 lh22">
              <p>
                {" "}
                {
                  selectedPlan.find((item) => item.key === "Name")?.value
                } Plan{" "}
              </p>
              <button
                onClick={() => {
                  setShowPlanDetails(false);
                  document.body.style.overflow = "auto";
                  trackEvent(
                    "My Plan Page",
                    "click",
                    `View All - ${selectedPlan[0].value} - close`
                  );
                }}
                className="lead-close-button"
              >
                X
              </button>
            </div>
            <div className="profile-info">
              <ul>
                {selectedPlan.map((item, index) => (
                  <li
                    className="profile-info-item df jcsb aic p12 lh16"
                    key={index}
                  >
                    <span className="info-label fs14 fs14 fc16">
                      {item.key}:
                    </span>
                    <span className="info-value fw6 fs14 lh16">
                      {item.value}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={`p12 box-center bg1 w100 fc3 ls1 cp`}
              onClick={() =>
                toggleRMNumber(
                  selectedPlan.find((item) => item.key === "Name")?.value
                )
              }
            >
              {showRMNumber ? (
                <p className={` ${showRMNumber ? "slide-in" : ""}`}>
                  {rmNumber}
                </p>
              ) : (
                <p>Contact Sales</p>
              )}
            </div>
          </div>
        </PlanPopup>
      )}
    </div>
  );
};

export default MyPlan;
