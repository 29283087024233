import React, { useState, useRef } from "react";
import "../components/DestinationSearch.css";
import MultiSelect from "./MultiSelect";
import datacity from "../data/pttt"

const DestinationSearch = ({onFilterCity,MultiSelectcity,readonly}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);

  const cities = datacity;
 
  
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value.length >= 3) {
      const filteredSuggestions = cities.filter(
        (city) =>
          city.toLowerCase().includes(value.toLowerCase()) &&
          !selectedSuggestions.includes(city)
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    setSelectedSuggestions((prevSelectedSuggestions) => {
      if(MultiSelectcity){
        var splitcity  = suggestion.split(",")[0];
        const newSelectedSuggestions = [...prevSelectedSuggestions , splitcity];
        setInputValue("");
        inputRef.current.focus();
        setSuggestions([]);
        onFilterCity(newSelectedSuggestions);
        return newSelectedSuggestions;
      }
      else{
        var splitcity  = suggestion.split(",")[0];
        const newSelectedSuggestions = [splitcity];
        setInputValue("");
        inputRef.current.focus();
        setSuggestions([]);
        onFilterCity(splitcity);
        return newSelectedSuggestions;
      }
     
    });
  };

  const handleRemoveSelectedSuggestion = (suggestion) => {
    setSelectedSuggestions((prevSelectedSuggestions) => {
      const updatedSuggestions = prevSelectedSuggestions.filter(
        (selected) => selected !== suggestion
      );
      onFilterCity(updatedSuggestions);
      return updatedSuggestions;
    });
  };

  return (
    <div className="destination-search">
      <div className="selected-suggestions v-center fww">
        {selectedSuggestions.map((selected, index) => (
          <div
            className="selected-suggestion-chip"
            key={index}
            onClick={() => handleRemoveSelectedSuggestion(selected)}
          >
            {selected}
            <span
              className="remove-selected-suggestion-icon"
              onClick={() => handleRemoveSelectedSuggestion(selected)}
            >
              &times;
            </span>
          </div>
        ))}
      </div>
      <input
        className="destination-search-input"
        type="text"
        placeholder="Search 3 letters for cities..."
        value={inputValue}
        onChange={handleInputChange}
        ref={inputRef}
        readOnly={readonly}
        title={readonly ? "Upgrade Your Plan To Use This Filter":""}
      />
      {inputValue.length >= 3 ? (
        suggestions.length > 0 ? (
          <div className="dsuggestions">
            {suggestions.map((suggestion, index) => (
              <div
                className="p8"
                key={index}
                onClick={() => handleSuggestionSelect(suggestion)}
              >
                {suggestion}
              </div>
            ))}
          </div>
        ) : (
          <div className="dsuggestions">Suggestions not found</div>
        )
      ) : null}
    </div>
  );
};

export default DestinationSearch;
