import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import ImageUpload from "../components/ImageUpload";
import DocUpload from "../components/DocUpload";
import "../pages/Kyc.css";
import { FaAngleDown } from "react-icons/fa";
import Toggle from "../components/Toggle";
import BusinessTimings from "../components/BusinessTimings";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTitle } from "../hooks/useTitle";
import trackEvent from "../utils/gaTracker";

const moment = require("moment-timezone");

const Kyc = ({ handleSelectTab }) => {
  const userTimeZone = moment.tz.guess();
  const allTimeZones = moment.tz.names();
  const timezoneOptions = {};
  useTitle("KYC -  Travel Agents CRM - Hellotravel");

  allTimeZones.forEach((timeZone) => {
    const utcOffset = moment.tz(timeZone).utcOffset();
    const offsetHours = Math.floor(Math.abs(utcOffset) / 60);
    const offsetMinutes = Math.abs(utcOffset) % 60;
    const offsetString =
      (utcOffset >= 0 ? "+" : "-") +
      ("00" + offsetHours).slice(-2) +
      ":" +
      ("00" + offsetMinutes).slice(-2);
    timezoneOptions[timeZone] = `UTC ${offsetString} ${timeZone}`;
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth);
  const [baseUrl, setBaseUrl] = useState(user.base_url);
  const [siteUrl, setSiteUrl] = useState(user.site_url);
  const [authId, setAuthID] = useState(user.auth_id);
  const [dataStatus, setDataStatus] = useState("false");
  const [formErrors, setFormErrors] = useState({});
  const [errorStatus, setErrorStatus] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [companyType, setCompanyType] = useState("");
  const [timezone, setTimezone] = useState("");
  const [timezoneValue, setTimezoneValue] = useState(userTimeZone);
  const [showTimeZoneDropdown, setShowTimeZoneDropdown] = useState(false);
  const [aadharId, setAadhaarID] = useState("");
  const [pan, setPan] = useState("");
  const registrationInputRef = useRef(null);
  const addressInputRef = useRef(null);
  const aadharInputRef = useRef(null);
  const panInputRef = useRef(null);
  const regProofRef = useRef(null);
  const addressProofRef = useRef(null);
  const timezoneRef = useRef(null);

  const accountNoRef = useRef(null);
  const accountNameRef = useRef(null);
  const ifscCodeRef = useRef(null);
  const bankNameRef = useRef(null);
  const upiRef = useRef(null);
  const aadharImgRef = useRef(null);
  const panImgRef = useRef(null);
  const registrationImgRef = useRef(null);
  const addressImgRef = useRef(null);
  // const selectedRegistrationIdRef =useRef(null);
  // const selectedAddressIdRef =useRef(null);

  const [registrationImg, setRegistrationImg] = useState("");
  const [addressImg, setAddressImg] = useState("");
  const [aadharImg, setAadharImg] = useState("");
  const [panImg, setPanImg] = useState("");
  const [stcheqImg, setStcheqImg] = useState("");

  // Bank details states
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [upi, setUpi] = useState("");
  const [showRegisdrop, setShowRegisDrop] = useState(false);
  const [selectedRegistrationId, setSelectedRegistrationId] = useState("");

  const [registrationDoc, setRegistraionDoc] = useState("");
  const [showAddressdrop, setShowAddressDrop] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [addressDoc, setAddressDoc] = useState("");
  const [settingsTab, setSettingsTab] = useState(false);
  const [addStatus, setAddStatus] = useState("");
  const [regStatus, setRegStatus] = useState("");
  const [statusCheque, setStatusCheque] = useState("");
  const [panStatus, setPanStatus] = useState("");
  const [aadhaarStatus, setAadhaarStatus] = useState("");
  const [tourismStatus, setTourismStatus] = useState("");
  

  const [uploadImgLocal, setUploadImgLocal] = useState("");
  const [tourismImg, setTourismImg] = useState("");



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (regProofRef.current && !regProofRef.current.contains(event.target)) {
        setShowRegisDrop(false);
      }

      if (
        addressProofRef.current &&
        !addressProofRef.current.contains(event.target)
      ) {
        setShowAddressDrop(false);
      }

      if (timezoneRef.current && !timezoneRef.current.contains(event.target)) {
        setShowTimeZoneDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleTimeZoneSelect = (selectedTimeZone, value) => {
    trackEvent("KYC Page", "click", "Timezone Change");
    setTimezoneValue(selectedTimeZone);
    setTimezone(value);
    setShowTimeZoneDropdown(false);
  };

  const [dayTimings, setDayTimings] = useState([
    {
      day: "Monday",
      startTime: "09:30",
      endTime: "18:30",
      status: true,
    },
    {
      day: "Tuesday",
      startTime: "09:30",
      endTime: "18:30",
      status: true,
    },
    {
      day: "Wednesday",
      startTime: "09:30",
      endTime: "18:30",
      status: true,
    },
    {
      day: "Thursday",
      startTime: "09:30",
      endTime: "18:30",
      status: true,
    },
    {
      day: "Friday",
      startTime: "09:30",
      endTime: "18:30",
      status: true,
    },
    {
      day: "Saturday",
      startTime: "09:30",
      endTime: "18:30",
      status: true,
    },
    {
      day: "Sunday",
      startTime: "09:30",
      endTime: "18:30",
      status: true,
    },
  ]);

  const updateDayTimings = (newDayTimings) => {
    setDayTimings(newDayTimings);
  };

  const registrationOptions = {
    GST: "GST",
    "Current A/c Latest Statement": "Current A/c Latest Statement",
    "Current A/c Cancelled Cheque": "Current A/c Cancelled Cheque",
    "Udyog Aadhaar": "Udyog Aadhaar",
    PAN: "Company PAN",
    "Partnership Deed": "Partnership Deed",
    "Incorporation Certificate": "Incorporation Certificate",
    "Tourism Board Ceritifacte": "Tourism Board Ceritifacte",
  };

  const addressOptions = {
    Elect:
      "Latest Elect/Phone/Water/Property Tax Bill or Receipt in Company/Proprietorship Name",
    "Current A/c Latest Statement": "Current A/c Latest Statement",
    "Udyog Aadhaar": "Udyog Aadhaar",
    "Rent Agreement":
      "Rent Agreement in Company/Proprietorship Name (ONLY Registered, Non-Notorized) - Max 3 Months Old",
  };

  const handleRegistarionIdSelect = (selectedregisId) => {
    setSelectedRegistrationId(selectedregisId);
    setRegistraionDoc("");
    setShowRegisDrop(false);
  };
  const handleAddressIdSelect = (selectedadressId) => {
    setSelectedAddressId(selectedadressId);
    setAddressDoc("");
    setShowAddressDrop(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getKycData();
  }, []);

  function getKycData() {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=kyc`,
      headers: {},
      data: {
        auth_id: authId,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }

      if (response.data.data.data !== null) {
        setCompanyType(response.data.data.companystatus);
      }
      if (response.data.data.data !== "") {
        if (response.data.data.data.acc_name) {
          setAccountName(response.data.data.data.acc_name);
        }

        if (response.data.data.data.acc_number) {
          setAccountNumber(response.data.data.data.acc_number);
        }

        if (response.data.data.data.branch_ifsc) {
          setIfscCode(response.data.data.data.branch_ifsc);
        }

        if (response.data.data.data.bank_name) {
          setBankName(response.data.data.data.bank_name);
        }

        if (response.data.data.data.json_kyc_data !== null) {
          setDayTimings(response.data.data.data.json_kyc_data.dayTimings);
          setUpi(response.data.data.data.json_kyc_data.upi.upiId);
          if (response.data.data.data.json_kyc_data.timezone.timezone) {
            setTimezoneValue(
              response.data.data.data.json_kyc_data.timezone.timezone
            );
          } else {
            setTimezoneValue(userTimeZone);
          }
        }

        if (response.data.data.docdata) {
          setSelectedRegistrationId(response.data.data.docdata.regname);
          setRegistraionDoc(response.data.data.docdata.regno);

          if (response.data.data.docdata.regimg) {
            setRegistrationImg(response.data.data.docdata.regurl);
          }

          setSelectedAddressId(response.data.data.docdata.addname);
          setAddressDoc(response.data.data.docdata.addno);

          if (response.data.data.docdata.addimg) {
            setAddressImg(response.data.data.docdata.addurl);
          }

          setAadhaarID(response.data.data.docdata.aadhaarno);

          if (response.data.data.docdata.aadhaarimg) {
            setAadharImg(response.data.data.docdata.aadhaarurl);
          }

          setPan(response.data.data.docdata.panno);

          if (response.data.data.docdata.panimg) {
            setPanImg(response.data.data.docdata.panurl);
          }
          if (response.data.data.docdata.statcheqimg) {
            setStcheqImg(response.data.data.docdata.statchequrl);
          }

          if (response.data.data.docdata.tourismimg) {
            setTourismImg(response.data.data.docdata.tourismurl);
          }

          setAddStatus(response.data.data.docdata.addstatus);
          setRegStatus(response.data.data.docdata.regstatus);
          setStatusCheque(response.data.data.docdata.statcheqstatus);
          setAadhaarStatus(response.data.data.docdata.aadhaarstatus);
          setPanStatus(response.data.data.docdata.panstatus);
          setTourismStatus(response.data.data.docdata.tourismstatus);
        }
      }

      setDataStatus(true);
    });
  }

  const handleLogout = () => {
    // Dispatch the logout action to clear user data
    dispatch(logout());
    // Redirect to the login page
    navigate("/login");
  };

  const myKycValidate = (
    companyType,
    timezoneValue,
    selectedRegistrationId,
    registrationDoc,
    selectedAddressId,
    addressDoc,
    aadharId,
    pan,
    accountName,
    accountNumber,
    ifscCode,
    bankName,
    upi,
    registrationImg,
    addressImg,
    aadharImg,
    panImg,
    stcheqImg
  ) => {
    const errors = {};
    errors.timezoneValue = [];
    errors.selectedRegistrationId = [];
    errors.registrationDoc = [];
    errors.selectedAddressId = [];
    errors.addressDoc = [];
    errors.aadharId = [];
    errors.pan = [];
    errors.accountName = [];
    errors.accountNumber = [];
    errors.ifscCode = [];
    errors.bankName = [];
    errors.upi = [];
    errors.registrationImg = [];
    errors.addressImg = [];
    errors.aadharImg = [];
    errors.panImg = [];
    errors.stcheqImg = [];

    if (timezoneValue.length === 0) {
      errors.timezoneValue.push("TimeZone is empty!");
      setErrorStatus(true);
    }

    if (selectedRegistrationId.length === 0) {
      errors.selectedRegistrationId.push("Please select Registration Proof!");
      setErrorStatus(true);
    }

    if (selectedRegistrationId.length > 0 && registrationDoc.length === 0) {
      errors.registrationDoc.push("Registration ID is empty!");
      setErrorStatus(true);
    }

    if (selectedRegistrationId.length > 0 && registrationImg.length === 0) {
      errors.registrationImg.push("Please upload Registration Proof!");
      setErrorStatus(true);
    }

    if (selectedAddressId.length === 0) {
      errors.selectedAddressId.push("Please select Address Proof");
      setErrorStatus(true);
    }

    if (selectedAddressId.length > 0 && addressDoc.length === 0) {
      errors.addressDoc.push("Address Proof ID is empty!");
      setErrorStatus(true);
    }

    if (selectedAddressId.length > 0 && addressImg.length === 0) {
      errors.addressImg.push("Please upload Adress Proof!");
      setErrorStatus(true);
    }

    if (companyType === "1") {
      if (aadharId.length === 0) {
        errors.aadharId.push("Aadhar ID is empty!");
        setErrorStatus(true);
      }

      if (pan.length === 0) {
        errors.pan.push("Pan ID is empty!");
        setErrorStatus(true);
      }

      if (aadharImg.length === 0) {
        errors.aadharImg.push("Please upload Aadhar Proof!");
        setErrorStatus(true);
      }

      if (panImg.length === 0) {
        errors.panImg.push("Please upload Pan Proof!");
        setErrorStatus(true);
      }
    }

    if (
      accountNumber.length > 0 ||
      accountName.length > 0 ||
      ifscCode.length > 0 ||
      bankName.length > 0
    ) {
      if (accountNumber.length === 0) {
        errors.accountNumber.push("Account Number is empty!");
        setErrorStatus(true);
      } else {
        const accountRegex = /^\d{5,20}$/;
        if (!accountRegex.test(accountNumber)) {
          errors.accountNumber.push(
            "Account Number is Invalid: " + accountNumber
          );
          setErrorStatus(true);
        }
      }

      if (accountName.length === 0) {
        errors.accountName.push("Account Name is empty!");
        setErrorStatus(true);
      }

      if (ifscCode.length === 0) {
        errors.ifscCode.push("IFSC Code is empty!");
        setErrorStatus(true);
      } else {
        if (ifscCode.length < 4 || ifscCode.length > 12) {
          errors.ifscCode.push("Invalid Pincode: " + ifscCode);
          setErrorStatus(true);
        }
      }

      if (bankName.length === 0) {
        errors.bankName.push("Bank Name is empty!");
        setErrorStatus(true);
      }

      if (stcheqImg.length === 0) {
        errors.stcheqImg.push("Upload Statement/Cancelled Cheque!");
        setErrorStatus(true);
      }
    }

    if (upi.length > 0) {
      const upiIdRegex = /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/;
      if (!upiIdRegex.test(upi)) {
        errors.upi.push("Invalid Upi No: " + upi);
        setErrorStatus(true);
      }
    }

    const returnError = {};
    if (companyType === "1") {
      if (
        errors.aadharId.length === 0 &&
        errors.pan.length === 0 &&
        errors.aadharImg.length === 0 &&
        errors.panImg.length === 0 &&
        errors.registrationImg.length === 0 &&
        errors.addressImg.length === 0 &&
        errors.timezoneValue.length === 0 &&
        errors.selectedRegistrationId.length === 0 &&
        errors.registrationDoc.length === 0 &&
        errors.selectedAddressId.length === 0 &&
        errors.addressDoc.length === 0 &&
        errors.accountName.length === 0 &&
        errors.accountNumber.length === 0 &&
        errors.bankName.length === 0 &&
        errors.ifscCode.length === 0 &&
        errors.upi.length === 0 &&
        errors.stcheqImg.length === 0
      ) {
        returnError.status = false;
        setErrorStatus(false);
      } else {
        returnError.status = true;
      }
    } else {
      if (
        errors.registrationImg.length === 0 &&
        errors.addressImg.length === 0 &&
        errors.timezoneValue.length === 0 &&
        errors.selectedRegistrationId.length === 0 &&
        errors.registrationDoc.length === 0 &&
        errors.selectedAddressId.length === 0 &&
        errors.addressDoc.length === 0 &&
        errors.accountName.length === 0 &&
        errors.accountNumber.length === 0 &&
        errors.bankName.length === 0 &&
        errors.ifscCode.length === 0 &&
        errors.upi.length === 0 &&
        errors.stcheqImg.length === 0
      ) {
        returnError.status = false;
        setErrorStatus(false);
      } else {
        returnError.status = true;
      }
    }

    returnError.errors = errors;

    return returnError;
  };

  const handlekycData = async (e) => {
    e.preventDefault();
    trackEvent("KYC Page", "click", "Save Changes");
    setFormSubmitted(true);
    const kycErData = myKycValidate(
      companyType,
      timezoneValue,
      selectedRegistrationId,
      registrationDoc,
      selectedAddressId,
      addressDoc,
      aadharId,
      pan,
      accountName,
      accountNumber,
      ifscCode,
      bankName,
      upi,
      registrationImg,
      addressImg,
      aadharImg,
      panImg,
      stcheqImg
    );

    if (kycErData.status === true) {
      setFormErrors(kycErData.errors);

      if (kycErData.errors.selectedRegistrationId.length) {
        registrationInputRef.current.focus();
        //window.scrollTo({ top: 200, behavior: "smooth" });
        return false;
      }

      if (kycErData.errors.registrationDoc.length) {
        registrationInputRef.current.focus();
        return false;
      }

      if (kycErData.errors.registrationImg.length) {
        registrationInputRef.current.focus();
        return false;
      }

      if (kycErData.errors.selectedAddressId.length) {
        addressInputRef.current.focus();
        return false;
      }

      if (kycErData.errors.addressDoc.length) {
        addressInputRef.current.focus();
        return false;
      }

      if (kycErData.errors.addressImg.length) {
        addressImgRef.current.focus();
        return false;
      }

      if (companyType === "1") {
        if (kycErData.errors.aadharId.length) {
          aadharInputRef.current.focus();
          return false;
        }

        if (kycErData.errors.aadharImg.length) {
          aadharInputRef.current.focus();
          return false;
        }

        if (kycErData.errors.pan.length) {
          panInputRef.current.focus();
          return false;
        }

        if (kycErData.errors.panImg.length) {
          panInputRef.current.focus();
          return false;
        }
      }

      if (kycErData.errors.accountName.length) {
        accountNameRef.current.focus();
        return false;
      }

      if (kycErData.errors.accountNumber.length) {
        accountNoRef.current.focus();
        return false;
      }

      if (kycErData.errors.ifscCode.length) {
        ifscCodeRef.current.focus();
        return false;
      }

      if (kycErData.errors.bankName.length) {
        bankNameRef.current.focus();
        return false;
      }

      if (kycErData.errors.upi.length) {
        upiRef.current.focus();
        return false;
      }

      if (kycErData.errors.stcheqImg.length) {
        return false;
      }
    } else {
      axios({
        method: "post",
        url: `${user.base_url}/subuser/myaccount.php?fun=kycupdate`,
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          auth_id: authId,
          dayTimings: dayTimings,
          timezoneValue: timezoneValue,
          selectedRegistrationId: selectedRegistrationId,
          registrationDoc: registrationDoc,
          registrationImg: registrationImg,
          selectedAddressId: selectedAddressId,
          addressDoc: addressDoc,
          addressImg: addressImg,
          aadharId: aadharId,
          aadharImg: aadharImg,
          pan: pan,
          panImg: panImg,
          stcheqImg: stcheqImg,
          accountName: accountName,
          accountNumber: accountNumber,
          ifscCode: ifscCode,
          bankName: bankName,
          tourismBoardImg:tourismImg,
          upi: upi,
        },
      })
        .then(function (response) {
          setSettingsTab(true);
          // window.scrollTo({ top: 0, behavior: "smooth" });
          // handleSelectTab("Settings");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="account-details df w66 fdc kyc main-content-padding">
      <h3 className="fc1 fw5 fs20 mb24">
        Business Timings
        <div className="fs14 fc16 mt8 fw4 lh16">
          Choose a standard timezone and add working hours for all your teams
        </div>
      </h3>
      <div className="timezone">
        <div className="fc14 fw5 fs14 ls1 v-center">Select Timezone</div>
        <div className="form-group-settings v-center jcsb">
          <div className="role-dropdown">
            <div
              className="selected-role fs14"
              ref={timezoneRef}
              onClick={() => setShowTimeZoneDropdown(!showTimeZoneDropdown)}
            >
              {timezoneOptions[timezoneValue] || "Select TimeZone"}{" "}
              <FaAngleDown className="fc16 fs14" />
            </div>
            {showTimeZoneDropdown && (
              <ul className="role-options fs14">
                {Object.entries(timezoneOptions).map(([key, value]) => (
                  <li
                    key={key}
                    value={key}
                    onClick={() => handleTimeZoneSelect(key, value)}
                  >
                    {value}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      {dataStatus === true && (
        <div className="set-business-hours mb24">
          <div className="business-hours-head fc14 fw5 ls1 fs14 mb4">
            Set Your Business Hours{" "}
          </div>

          <BusinessTimings
            updateDayTimings={updateDayTimings}
            dayTimings={dayTimings}
            setDayTimings={setDayTimings}
          />
        </div>
      )}
      <div className="upload-certificates brd-t1 mt24 pt24">
        <h3 className="fc1 fw5 fs20 mt24 mb32 ls1">
          Upload Documents
          <div className="fs14 fc16 mt8 fw4 lh16">
            Upload Documents for account verification and seamless access to
            premium leads
          </div>
        </h3>
        <div className="registration-proof ls1 ">
          <div className="form-group-settings df">
            <div className="role-dropdown df fdc flx1 mr16 reg-proof">
              <p className="fc14 fw5 fs14 ls1">Registration Proof</p>
              <div
                className="selected-role fs14"
                ref={regProofRef}
                onClick={() => setShowRegisDrop(!showRegisdrop)}
              >
                {selectedRegistrationId || "Select Registration ID"}{" "}
                <FaAngleDown className="fc16 fs14" />
              </div>
              {showRegisdrop && (
                <ul className="role-options fs14">
                  {Object.entries(registrationOptions).map(([key, value]) => (
                    <li
                      key={key}
                      value={value}
                      onClick={() => handleRegistarionIdSelect(key, value)}
                    >
                      {value}
                    </li>
                  ))}
                </ul>
              )}
              {formSubmitted && (
                <div className="form-error-messages error mt8 blink-text-normal-error">
                  {Object.keys(formErrors).map((key) => (
                    <div key={key}>
                      {key === "selectedRegistrationId" &&
                        formErrors[key].map((error, index) => (
                          <p key={index}>{error}</p>
                        ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flx50 reg-id">
              <p className="fc14 fw5 fs14 ls1">Registration ID</p>
              <input
                ref={registrationInputRef}
                type="text"
                id="name"
                value={registrationDoc}
                placeholder={
                  (selectedRegistrationId &&
                    selectedRegistrationId ===
                      "Current A/c Latest Statement") ||
                  selectedRegistrationId === "Current A/c Cancelled Cheque"
                    ? `Enter Current A/c Number`
                    : `Enter ${selectedRegistrationId} Number`
                }
                onChange={(e) => setRegistraionDoc(e.target.value)}
                //disabled={!selectedRegistrationId} // Disable input if registrationId is empty
                //className={selectedRegistrationId ? "" : "disabled-input"}
              />
              {formSubmitted && (
                <div className="form-error-messages error mt8 blink-text-normal-error">
                  {Object.keys(formErrors).map((key) => (
                    <div key={key}>
                      {key === "registrationDoc" &&
                        formErrors[key].map((error, index) => (
                          <p key={index}>{error}</p>
                        ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {dataStatus === true && (
            <div className="upload-image v-center">
              <p
                className="flx1 fc14 fw5 fs14 mb12 ls1 lh22"
                ref={registrationImgRef}
              >
                Upload{" "}
                {selectedRegistrationId ? selectedRegistrationId : "Photo"}
              </p>
              <div className="image-upload-compoenent">
                <DocUpload
                  setWorkImage={setRegistrationImg}
                  imgData={registrationImg}
                  baseUrl=""
                  siteUrl=""
                  folder=""
                  delimg={true}
                  toast={toast}
                  addStatus={regStatus}
                />
                {formSubmitted && (
                  <div className="form-error-messages error mt8 blink-text-normal-error">
                    {Object.keys(formErrors).map((key) => (
                      <div key={key}>
                        {key === "registrationImg" &&
                          formErrors[key].map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* {dataStatus === true && (
        <div className="tourism-cetficate mt48 ls1 brd-t1 pt24 v-center jcsb">
          <p className="flx1 fc14 fw5 fs14 mb12 ls1 lh22">Tourism Board Certificate</p>
          <div className="image-upload-compoenent">
          <DocUpload
                  setWorkImage={setTourismImg}
                  imgData={tourismImg}
                  baseUrl=""
                  siteUrl=""
                  folder=""
                  delimg={true}
                  toast={toast}
                  addStatus={tourismStatus}
                />
          </div>
        </div>
        )} */}

        

        
        <div className="address-proof mt48 ls1 brd-t1 mt24 pt24">
          <div className="form-group-settings df">
            <div className="role-dropdown df fdc flx1 mr16 reg-proof">
              <p className="fc14 fw5 fs14 ls1">Address Proof</p>
              <div
                className="selected-role fs14"
                ref={addressProofRef}
                onClick={() => setShowAddressDrop(!showAddressdrop)}
              >
                {selectedAddressId || "Select Registration ID"}{" "}
                <FaAngleDown className="fc16 fs14" />
              </div>
              {showAddressdrop && (
                <ul className="role-options fs14">
                  {Object.entries(addressOptions).map(([key, value]) => (
                    <li
                      key={key}
                      value={value}
                      onClick={() => handleAddressIdSelect(key, value)}
                    >
                      {value}
                    </li>
                  ))}
                </ul>
              )}

              {formSubmitted && (
                <div className="form-error-messages error mt8 blink-text-normal-error">
                  {Object.keys(formErrors).map((key) => (
                    <div key={key}>
                      {key === "selectedAddressId" &&
                        formErrors[key].map((error, index) => (
                          <p key={index}>{error}</p>
                        ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flx50">
              <p className="fc14 fw5 fs14 ls1">Address Proof ID</p>
              <input
                ref={addressInputRef}
                type="text"
                id="name"
                value={addressDoc}
                placeholder={
                  selectedAddressId &&
                  selectedAddressId === "Current A/c Latest Statement"
                    ? `Enter Current A/c Number`
                    : selectedAddressId === "Elect"
                    ? `Enter Bill Or Receipt Number`
                    : `Enter ${selectedAddressId} Number`
                }
                onChange={(e) => setAddressDoc(e.target.value)}
                //disabled={!selectedAddressId}
                // className={selectedAddressId ? "" : "disabled-input"}
              />

              {formSubmitted && (
                <div className="form-error-messages error mt8 blink-text-normal-error">
                  {Object.keys(formErrors).map((key) => (
                    <div key={key}>
                      {key === "addressDoc" &&
                        formErrors[key].map((error, index) => (
                          <p key={index}>{error}</p>
                        ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {dataStatus === true && (
            <div className="upload-image v-center">
              <p className="flx1 fc14 fw5 fs14 mb12 ls1 lh22">
                Upload {selectedAddressId ? selectedAddressId : "Photo"}
              </p>
              <div className="image-upload-compoenent">
                <DocUpload
                  setWorkImage={setAddressImg}
                  imgData={addressImg}
                  baseUrl=""
                  siteUrl=""
                  folder=""
                  delimg={true}
                  toast={toast}
                  addStatus={addStatus}
                />

                {formSubmitted && (
                  <div className="form-error-messages error mt8 blink-text-normal-error">
                    {Object.keys(formErrors).map((key) => (
                      <div key={key}>
                        {key === "addressImg" &&
                          formErrors[key].map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {dataStatus === true && (companyType === "1" || companyType === "") && (
          <div className="aadhar-id mt48 ls1 brd-t1 mt24 pt24">
            <div className="form-group-settings">
              <div className="addhar-input flx1">
                <p className="fc14 fw5 fs14 ls1">Aadhar ID</p>
                <input
                  ref={aadharInputRef}
                  type="text"
                  placeholder="Enter Aadhar ID"
                  value={aadharId}
                  onChange={(e) => setAadhaarID(e.target.value)}
                />
              </div>
              {/* <button className="digilocker-btn df bg1 fc3 ml16 br4 ls1 cp">
              Verify via DigiLocker
            </button> */}
            </div>
            {formSubmitted && (
              <div className="form-error-messages error blink-text-normal-error">
                {Object.keys(formErrors).map((key) => (
                  <div key={key}>
                    {key === "aadharId" &&
                      formErrors[key].map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                  </div>
                ))}
              </div>
            )}
            {dataStatus === true && (
              <div className="upload-image v-center">
                <p className="flx1 fc14 fw5 fs14 mb12 ls1" ref={aadharImgRef}>
                  Upload Aadhar
                </p>
                <div className="image-upload-compoenent">
                  <DocUpload
                    setWorkImage={setAadharImg}
                    imgData={aadharImg}
                    baseUrl=""
                    siteUrl=""
                    folder=""
                    delimg={true}
                    toast={toast}
                    addStatus={aadhaarStatus}
                  />

                  {formSubmitted && (
                    <div className="form-error-messages error mt8 blink-text-normal-error">
                      {Object.keys(formErrors).map((key) => (
                        <div key={key}>
                          {key === "aadharImg" &&
                            formErrors[key].map((error, index) => (
                              <p key={index}>{error}</p>
                            ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {dataStatus === true && (companyType === "1" || companyType === "") && (
          <div className="pan-card mt48 ls1 brd-t1 mt24 pt24">
            <div className="form-group-settings">
              <div className="addhar-input flx1">
                <p className="fc14 fw5 fs14 ls1">PAN Number</p>
                <input
                  ref={panInputRef}
                  type="text"
                  placeholder="Enter Pan Number"
                  value={pan}
                  onChange={(e) => setPan(e.target.value)}
                />
              </div>
              {/* <button className="digilocker-btn df bg1 fc3 ml16 br4 ls1 cp">
              Verify via DigiLocker
            </button> */}
            </div>
            {formSubmitted && (
              <div className="form-error-messages error blink-text-normal-error">
                {Object.keys(formErrors).map((key) => (
                  <div key={key}>
                    {key === "pan" &&
                      formErrors[key].map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                  </div>
                ))}
              </div>
            )}

            {dataStatus === true && (
              <div className="upload-image v-center">
                <p className="flx1 fc14 fw5 fs14 mb12 ls1" ref={panImgRef}>
                  Upload PAN
                </p>
                <div className="image-upload-compoenent">
                  <DocUpload
                    setWorkImage={setPanImg}
                    imgData={panImg}
                    baseUrl=""
                    siteUrl=""
                    folder=""
                    delimg={true}
                    toast={toast}
                    addStatus={panStatus}
                  />

                  {formSubmitted && (
                    <div className="form-error-messages error blink-text-normal-error mb16">
                      {Object.keys(formErrors).map((key) => (
                        <div key={key}>
                          {key === "panImg" &&
                            formErrors[key].map((error, index) => (
                              <p key={index}>{error}</p>
                            ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="bank-details brd-t1 pt24 ls1">
        <h3 className="fc1 fw5 fs20 mt24 mb32">
          Bank Details
          <div className="fs14 fc16 mt8 fw4 lh16">
            Share your bank details for convenient payment processing and
            itinerary display
          </div>
        </h3>
        <div className="form-group-settings">
          <p className="fc14 fw5 fs14 ls1">Account Holder Name</p>
          <input
            ref={accountNameRef}
            type="text"
            id="name"
            placeholder="Enter Account Holder Name"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
          />
          {formSubmitted && (
            <div className="form-error-messages error mt10 blink-text-normal-error">
              {Object.keys(formErrors).map((key) => (
                <div key={key}>
                  {key === "accountName" &&
                    formErrors[key].map((error, index) => (
                      <p key={index}>{error}</p>
                    ))}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="form-group-settings">
          <p className="fc14 fw5 fs14 ls1">Account Number</p>
          <input
            ref={accountNoRef}
            type="text"
            id="name"
            placeholder="Enter Account Number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
          {formSubmitted && (
            <div className="form-error-messages mt10 error blink-text-normal-error">
              {Object.keys(formErrors).map((key) => (
                <div key={key}>
                  {key === "accountNumber" &&
                    formErrors[key].map((error, index) => (
                      <p key={index}>{error}</p>
                    ))}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="form-group-settings">
          <p className="fc14 fw5 fs14 ls1">IFSC Code</p>
          <input
            ref={ifscCodeRef}
            type="text"
            id="name"
            placeholder="Enter IFSC Code"
            value={ifscCode}
            onChange={(e) => setIfscCode(e.target.value)}
          />

          {formSubmitted && (
            <div className="form-error-messages error mt10 blink-text-normal-error">
              {Object.keys(formErrors).map((key) => (
                <div key={key}>
                  {key === "ifscCode" &&
                    formErrors[key].map((error, index) => (
                      <p key={index}>{error}</p>
                    ))}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="form-group-settings">
          <p className="fc14 fw5 fs14 ls1">Bank Name</p>
          <input
            ref={bankNameRef}
            type="text"
            id="name"
            placeholder="Enter Bank Name"
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
          />

          {formSubmitted && (
            <div className="form-error-messages error mt10 blink-text-normal-error">
              {Object.keys(formErrors).map((key) => (
                <div key={key}>
                  {key === "bankName" &&
                    formErrors[key].map((error, index) => (
                      <p key={index}>{error}</p>
                    ))}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="form-group-settings">
          <p className="fc14 fw5 fs14 ls1">UPI ID</p>
          <input
            ref={upiRef}
            type="text"
            id="name"
            placeholder="Enter UPI ID"
            value={upi}
            onChange={(e) => setUpi(e.target.value)}
          />
          {formSubmitted && (
            <div className="form-error-messages error mt10 blink-text-normal-error">
              {Object.keys(formErrors).map((key) => (
                <div key={key}>
                  {key === "upi" &&
                    formErrors[key].map((error, index) => (
                      <p key={index}>{error}</p>
                    ))}
                </div>
              ))}
            </div>
          )}
        </div>

        {dataStatus === true && (
          <div className="upload-image v-center">
            <p className="flx1 fc14 fw5 fs14 mb12 ls1 lh22">
              Upload Statement/Cancelled Cheque
            </p>
            <div className="image-upload-compoenent">
              <DocUpload
                setWorkImage={setStcheqImg}
                imgData={stcheqImg}
                baseUrl=""
                siteUrl=""
                folder=""
                delimg={true}
                toast={toast}
                eventLabel={"Upload Cheque"}
                eventCategory={"KYC Page"}
                addStatus={statusCheque}
              />
              {formSubmitted && (
                <div className="form-error-messages error mt8 blink-text-normal-error">
                  {Object.keys(formErrors).map((key) => (
                    <div key={key}>
                      {key === "stcheqImg" &&
                        formErrors[key].map((error, index) => (
                          <p key={index}>{error}</p>
                        ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="button-container born mt24">
        {/*
          <button type="button" className="btn-cancel cp">
            Cancel
          </button>*/}
        <button
          type="button"
          className="btn-blue bg1 br24 fs14 cp pl16 pr16 pt10 pb10"
          onClick={handlekycData}
        >
          Save Changes
        </button>
      </div>
      <div>
        <ToastContainer position="bottom-right" />
      </div>
    </div>
  );
};

export default Kyc;
