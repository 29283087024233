import React, { useState } from "react";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { BiLink } from "react-icons/bi";
import "../../components/Forms/SharePackageForm.css";
import { useSelector } from "react-redux";
import trackEvent from "../../utils/gaTracker";

const SharePackageForm = ({ onClose, selectedPackage }) => {
  const user = useSelector((state) => state.auth);
  const { shareLink } = selectedPackage || {};
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = () => {
    if (shareLink) {
      navigator.clipboard
        .writeText(shareLink)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 2000);
        })
        .catch((error) => {
          console.error("Error copying to clipboard:", error);
          // Handle error if copying to clipboard fails
        });
    }
  };

  const socialContent = () => {
    const contentDiv = document.createElement("div");

    contentDiv.innerHTML = `
      Hi,

      Warm greetings from ${user.company_name}!

      We are thrilled to have the opportunity to serve you. We would like to provide you with the confirmed quote details for your reference:
      
      *Package Details:*
      Destination: ${selectedPackage.destination}
      Duration: ${selectedPackage.daynight}
      Price: ${selectedPackage.displayprice}/-  ${selectedPackage.sharing} ${selectedPackage.tax}
      
      For full package details, please check out the link below:
      <a href=${selectedPackage.shareLink}>${selectedPackage.shareLink}</a>
      If you have any questions or wish to proceed, feel free to call me anytime at <a href="tel:${user.mobile}">${user.mobile}</a> or email me at <a href="mailto:${user.email}">${user.email}</a>. We're here to assist you!`;
    // Use innerText to get the text content
    return contentDiv.innerText;
  };
  const socialContentForEmail = () => {
    return `
      <div>
        <p>Hi,</p>
        <p>Warm greetings from ${user.company_name}!</p>
        <p>We are thrilled to have the opportunity to serve you. We would like to provide you with the confirmed quote details for your reference:</p>
        <p><strong>Package Details:</strong></p>
        <ul>
          <li>Destination: ${selectedPackage.destination}</li>
          <li>Duration: ${selectedPackage.daynight}</li>
          <li>Price: ${selectedPackage.displayprice}/-  ${selectedPackage.sharing} ${selectedPackage.tax}</li>
        </ul>
        <p>For full package details, please check out the link below:</p>
        <a href=${selectedPackage.shareLink}>${selectedPackage.shareLink}</a>
        <p>If you have any questions or wish to proceed, feel free to call me anytime at <a href="tel:${user.mobile}">${user.mobile}</a> or email me at <a href="mailto:${user.email}">${user.email}</a>. We're here to assist you!
        </p>
      </div>
    `;
  };

  const clickOnWhatsapp = (e) => {
    e.preventDefault();
    trackEvent("My Itineraries Page", "click", `Share - WhatsApp`);
    // Get the text content from socialContent
    let content = socialContent();
    // Encode the content for a URL
    let encodedContent = encodeURIComponent(content);
    // Appending the encoded content to the WhatsApp URL
    let url = `https://wa.me/?text=${encodedContent}`;
    window.open(url);
  };
  const clickOnEmail = (e) => {
    e.preventDefault();
    trackEvent("My Itineraries Page", "click", `Share - Email`);

    // Get the HTML content from socialContentForEmail
    let content = socialContentForEmail();
    let encodedContent = encodeURIComponent(content);
    // Use window.open or any other method to open a new email window
    window.open(
      `mailto:?subject=Warm greetings from ${user.company_name}&body=${encodedContent}`
    );
  };
  const clickOnfacebook = (e) => {
    e.preventDefault();
    trackEvent("My Itineraries Page", "click", `Share - Facebook`);
    var urlToShare = selectedPackage.shareLink;
    var shareLink = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
      urlToShare
    )}&t=aaaa`;
    window.open(shareLink);
  };

  return (
    <div className="share-package-form">
      <div className="form-input">
        <input
          type="text"
          placeholder="Share your package URL"
          value={shareLink || ""}
        />
        <button type="submit" className="v-center " onClick={copyToClipboard}>
          <span className="ls1">{isCopied ? "Copied!" : "Copy link"}</span>{" "}
          <BiLink className="ml8 fs18" />
        </button>
      </div>
      <div className="social-icons">
        <div className="v-center fdc cp">
          <FaFacebook className="icon fs36 fc1" onClick={clickOnfacebook} />
          <span className="mt8 fc5 fs14">Facebook</span>
        </div>
        <div className="v-center fdc cp">
          <FaWhatsapp className="icon fs36 fc2" onClick={clickOnWhatsapp} />
          <span className="mt8 fc5 fs14">WhatsApp</span>
        </div>
        <div className="v-center fdc cp">
          <SiGmail className="icon fs36 fc4" onClick={clickOnEmail} />
          <span className="mt8 fc5 fs14">Mail</span>
        </div>
        <div className="v-center fdc cp" onClick={copyToClipboard}>
          <BiLink className="icon fs36" />
          <span className="mt8 fc5 fs14">Copy Link</span>
        </div>
      </div>

      <div className="message-container mt24 brd1 p12 bg7" readOnly>
        <p>Hi,</p>
        <p className="mt16 ls1">Warm greetings from {user.company_name}!</p>
        <p className="mt16 ls1 lh18">
          We are thrilled to have the opportunity to serve you. We would like to
          provide you with the confirmed quote details for your reference:
        </p>
        <p className="mt16 ls1 fw6">Package Details :</p>
        <p className="mt8 ls1">Destination: {selectedPackage.destination}</p>
        {/* <p className="mt8 ls1">Travel Date: {3}</p> */}
        <p className="mt8 ls1">Duration: {selectedPackage.daynight}</p>
        <p className="mt8 ls1">
          Price: {selectedPackage.displayprice}/- {selectedPackage.sharing}{" "}
          {selectedPackage.tax}
        </p>
        <p className="mt8 ls1">
          For full package details, please checkout the bellow link:{" "}
        </p>
        <a className="mt8 ls1" href={selectedPackage.shareLink}>
          {selectedPackage.shareLink}
        </a>
        <p className="mt16 ls1 lh18">
          If you have any questions or wish to proceed, feel free to call me
          anytime at <a href={`tel:${user.mobile}`}>{user.mobile}</a> or email
          me at <a href={`mailto:${user.email}`}>{user.email}</a>. We're here to
          assist you!
        </p>
      </div>
      <div className="button-container">
        <button type="button" className="btn-cancel" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SharePackageForm;
