import React, { useState, useEffect } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import "./NumberCounter.css";

function NumberCounter({ countLead, onCountChange, numError}) {
  const [count, setCount] = useState(countLead);

  
  const handleIncrement = () => {
    const newCount = count + 1;
    setCount(newCount);
    onCountChange(newCount);
  };

  const handleDecrement = () => {
    if (count > 0) {
      const newCount = count - 1;
      setCount(newCount);
      onCountChange(newCount);
    }
  };

  const handleCountChange = (event) => {
    let newValue = event.target.value;
    newValue = newValue.replace(/^0+(?=\d)/, "");
    // Ensure it's a non-negative integer or blank
    if (/^\d+$/.test(newValue)) {
      // Check if the value is exactly four digits
      if (newValue.length <= 4) {
        setCount(parseInt(newValue, 10));
        // Notify the parent component of the count change
        numError("");
        onCountChange(parseInt(newValue, 10));
      } else {
        numError("Enter exactly four digits");
      }
    } else {
      numError("Only numeric values allowed");
    }
  };

  return (
    <div className="number-counter df jcsa aic">
      <button
        onClick={handleDecrement}
        className="button-minus cp df fc1 fs14 fw5"
      >
        <FaMinus />
      </button>
      <input
        type="number"
        value={count}
        onChange={handleCountChange}
        className="count-input"
      />
      <button
        onClick={handleIncrement}
        className="button-plus cp df fc1 fs14 fw5"
      >
        <FaPlus />
      </button>
      
      </div>
      
  );
}

export default NumberCounter;
