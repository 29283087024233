import React from "react";
import "./Checkbox.css";

const Checkbox = ({ label, checked = false, onChange, value }) => {
  const handleCheckboxChange = () => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <label className="checkbox-container">
      <input
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
      />
       {label}
    </label>
  );
};

export default Checkbox;
