import React from "react";
import { FaEnvelope, FaBell } from "react-icons/fa";
import "./Timeline.css";
import Tooltip from "./Tooltip";

const Timeline = ({ commentData, dataStatus }) => {
  return (
    <div className="timeline">
      {dataStatus === true &&
        Object.entries(commentData).map(([key, history]) => (
          <div key={key}>
            <button className="timeline-date">{key}</button>
            {history.map((item, Index) => (
              <div className="timeline-box pr" key={Index}>
                <div className="timeline-icon">
                  <FaEnvelope />
                </div>
                <div className="timeline-content">
                  <div className="comment-timeline df jcsb">
                    <div>
                      <p>{item.TBL_LOGIN_CONTACTPERSON}</p>
                    </div>
                    <div className="df timeline-status">
                      {item.lead_status && (
                        <p className="comments-status">
                          Status :
                          <span className="comment-status">
                            {item.lead_status}
                          </span>
                        </p>
                      )}

                      {item.lead_status && item.reminder_date && (
                        <Tooltip title="Follow-up Date">
                          {" "}
                          <p className="v-center">
                            <FaBell className="bell-icon" />
                            {item.reminder_date}
                          </p>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div
                    className="comment-message fc16 lh18"
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.activity_note}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default Timeline;
