import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";
import "../components/ReviewCard.css";
import Tooltip from "./Tooltip";

const ReviewCard = ({
  averageRating,
  totalReviews,
  starRatingsCount,
  dataStatus,
}) => {
  return (
    <div className="review-card-container brd1 bg8 df jcsb pl48 pr48 mt24">
      <div className="rating-review flx50 v-center jcsa mr24">
        <div className="box-center fdc">
          <p className="fs48">{totalReviews}</p>
          <p className="mt8">
            <FaUserAlt />
            <span className="ml8 fs16">Total Reviews</span>
          </p>
        </div>
        <div className="box-center fdc">
          <div className="df aic">
            <div className="fs48">{averageRating}</div>
            <div className="mt8 ml8">
              <BsStarFill className="fc6" />
            </div>
          </div>
          <p className="fs16 mt8">Average Rating</p>
        </div>
      </div>
      <div className="review-list-show pl20 pt20 pb20 w100 flx1">
        {dataStatus &&
          starRatingsCount.map((item, index) => (
            <Tooltip title={item.value || 0}>
              <div className="v-center pb10 star-fill-bar" key={item.index}>
                <BsStarFill className={`fs18 mr4 fc${13 - index}`} />
                <div className="fw5 fs16 lh26">{item.rating}</div>
                <div className="rating-bar w100 ml16">
                  {item.value > 0 && (
                    <div
                      className={`fill-bar bg${13 - index}`}
                      style={{ width: `${(item.value / totalReviews) * 100}%` }}
                    ></div>
                  )}
                </div>
              </div>
            </Tooltip>
          ))}
      </div>
    </div>
  );
};

export default ReviewCard;
