import axios from "axios";
import React, { useState, useRef } from "react";
import "../components/ImageUpload.css";
import trackEvent from "../utils/gaTracker";
import pdf from "../assets/pdf.png";

const DocUpload = ({
  authId,
  baseUrl,
  imgData,
  siteUrl,
  folder,
  uploadUrl,
  deleteUrl,
  setWorkImage,
  delimg,
  toast,
  eventLabel,
  eventCategory,
  addStatus,
}) => {
  let imgobj;

  if (imgData !== "") {
    imgobj = {
      url: `${siteUrl}${folder}${imgData}`,
      name: imgData,
    };
  } else {
    imgobj = null;
  }

  const [selectedImage, setSelectedImage] = useState(imgobj);
  const [selectedImageData, setSelectedImageData] = useState({});
  const fileInputRef = useRef();

  const handleFileInputChange = (e) => {
    const allowedTypess = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "application/pdf",
    ];

    const file = e.target.files[0];
    if (file) {
      if (allowedTypess.includes(file?.type)) {
        setSelectedImage({
          url: URL.createObjectURL(file),
          name: file.name,
          type: file.type, // Add type to identify whether it's an image or PDF
        });

        setWorkImage(file);

        if (file) {
          toast.success("Successfully Uploaded");
        }
      } else {
        toast.error("Problem in uploading");
      }
    }
  };

  const handleUploadButtonClick = () => {
    if (addStatus !== "1") {
      fileInputRef.current.click();
      trackEvent(eventCategory, "click", eventLabel);
    } else if (addStatus === "1") {
      toast.info("Please contact your RM to change");
    }
  };

  const handleDeleteButtonClick = () => {
    setSelectedImage(null);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedImage({ url: URL.createObjectURL(file), name: file.name });
      setWorkImage(file);
    }
  };

  const handlePreviewClick = () => {
    if (selectedImage.type === "application/pdf") {
      window.open(selectedImage.url, "_blank");
    } else {
      window.open(selectedImage.url, "_blank");
    }
  };

  return (
    <div className="image-upload-container pr ofh">
      <div
        className="image-preview"
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleUploadButtonClick}
        style={{
          cursor: "pointer",
          border: selectedImage ? "2px solid #ccc" : "2px dashed #ccc",
          padding: selectedImage ? "20px" : "20px",
          backgroundColor: selectedImage ? "transparent" : "#f9f9f9",
          maxWidth: "320px",
          width: "80%",
          height: selectedImage ? "160px" : "160px",
          display: selectedImage ? "flex" : "flex",
          alignItems: selectedImage ? "center" : "center",
          justifyContent: selectedImage ? "center" : "center",
        }}
      >
        {selectedImage ? (
          <>
            {
              <>
                <img

				src={
                    selectedImage.type === "application/pdf"
                      ? pdf
                      : selectedImage.url.endsWith(".pdf")
                      ? pdf
                      : selectedImage.url
                  }

                  alt={selectedImage.name}
                />
                <div
                  className={`status pa ${
                    addStatus === "1"
                      ? "bg4"
                      : addStatus === "2"
                      ? "bg9"
                      : "bg21"
                  }  fc3 br4 fs12`}
                >
                  {addStatus === "1"
                    ? "Verified"
                    : addStatus === "2"
                    ? "Rejected"
                    : "Pedning"}
                </div>
                {addStatus !== "1" && (
                  <div className="image-overlay">
                    <p>{selectedImage.name}</p>
                  </div>
                )}
              </>
            }
          </>
        ) : (
          <p className="box-center">Click or drag & drop an image here</p>
        )}
      </div>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*,.pdf"
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />
      {selectedImage && (
        <button onClick={handlePreviewClick} className="mt8 ls1 fs14 fc1 tdu">
          View
        </button>
      )}
    </div>
  );
};

export default DocUpload;
