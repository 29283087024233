import React, { useEffect, useState } from "react";
import "../components/ReportTable.css";
import moment from "moment/moment";

const ReportTable = ({
  data,
  columnMapping,
  datas,
  dataStatus,
  selectedTab,
  reportWise,
}) => {
  const [evenRows, setEvenRows] = useState([]);

  return (
    <div className="booked df w100 fdc mt16" style={{ overflow: "auto" }}>
      {dataStatus && Object.keys(data).length > 0 ? (
        <table>
          <thead>
            <tr>
              {Object.keys(columnMapping).map((columnName, index) => (
                <th key={index}>{columnName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {selectedTab === "Lead Status" &&
              Object.entries(data).map(([date, statuses]) => (
                <tr key={date}>
                  <td>{date}</td>
                  <td>{statuses["TOTAL_COUNT"] || "0"}</td>
                  <td>{statuses.Booked || "0"}</td>
                  <td>{statuses.Hot || "0"}</td>
                  <td>{statuses.Followup || "0"}</td>
                  <td>{statuses["No Response"] || "0"}</td>
                  <td>{statuses.Junk || "0"}</td>
                  <td>{statuses.new || "0"}</td>
                </tr>
              ))}
            {selectedTab === "Conversion Report" &&
              Object.entries(data).map(([date, statuses]) => (
                <tr key={date}>
                  <td>{date}</td>
                  <td>{statuses["TotalLead"] || "0"}</td>
                  <td>{statuses["BookingCount"] || "0"}</td>
                  <td>
                    {statuses["PartialCount"] > 0 ? "₹" : ""}
                    {statuses["PartialCount"] || "--"}
                  </td>
                  <td>
                    {statuses["FullPayCount"] > 0 ? "₹" : ""}
                    {statuses["FullPayCount"] || "--"}
                  </td>
                  <td>{statuses["Conversion"].toFixed(2) || "0"}</td>
                  <td>
                    {statuses["FileSize"] > 0 ? "₹" : ""}
                    {statuses["FileSize"] || "--"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                {Object.keys(columnMapping).map((columnName, index) => (
                  <th key={index}>{columnName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="12" className="no-data-message fc5 bg7">
                  No data available
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default ReportTable;
