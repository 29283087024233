// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-package-form {
  display: flex;
  flex-direction: column;
}

.form-input {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.share-package-form input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
  outline: none;
}

.share-package-form button[type="submit"] {
  padding: 10px 20px;
  background-color: #594cda;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-width: 136px;
}
.share-package-form button[type="submit"] span {
  width: 70px;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.message-container {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 240px;
}

@media screen and (max-width: 480px) {
  .form-input {
    flex-direction: column;
  }
  .share-package-form input[type="text"],
  .share-package-form button[type="submit"] {
    width: 100%;
  }
  .share-package-form button[type='submit']{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Forms/SharePackageForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;;IAEE,WAAW;EACb;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;AACF","sourcesContent":[".share-package-form {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.form-input {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 10px;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.share-package-form input[type=\"text\"] {\r\n  padding: 10px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 4px;\r\n  flex-grow: 1;\r\n  outline: none;\r\n}\r\n\r\n.share-package-form button[type=\"submit\"] {\r\n  padding: 10px 20px;\r\n  background-color: #594cda;\r\n  color: white;\r\n  border: none;\r\n  border-radius: 4px;\r\n  cursor: pointer;\r\n  min-width: 136px;\r\n}\r\n.share-package-form button[type=\"submit\"] span {\r\n  width: 70px;\r\n}\r\n\r\n.social-icons {\r\n  display: flex;\r\n  gap: 10px;\r\n}\r\n\r\n.message-container {\r\n  overflow: hidden;\r\n  overflow-y: scroll;\r\n  max-height: 240px;\r\n}\r\n\r\n@media screen and (max-width: 480px) {\r\n  .form-input {\r\n    flex-direction: column;\r\n  }\r\n  .share-package-form input[type=\"text\"],\r\n  .share-package-form button[type=\"submit\"] {\r\n    width: 100%;\r\n  }\r\n  .share-package-form button[type='submit']{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
