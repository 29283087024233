import React from "react";
import moment from "moment";
import trackEvent from "../utils/gaTracker";
const FilterChips = ({ data, onRemove }) => {
  // console.log("chips data",data);
  const chipobj = {
    selectedHotel: {
      "1 star": "1 Star",
      "2 star": "2 Star",
      "3 star": "3 Star",
      "4 star": "4 Star",
      "5 star": "5 Star",
      "5plusstar": "5+ Star",
      unrated: "Unrated",
      "service apartment": "Service Apartment",
      "guest house": "Guest House",
    },
    selectedRecency: {
      "15-m": "15 Minute",
      "30-m": "30 Minute",
      "10-h": "10 Hour",
      "1-d": "1 Day",
      "2-d": "3 day",
      "5-d": "5 days",
    },
    endDate: "",
    startDate: "",
    selectedLeadType: {
      outbound: "Outbound",
      inbound: "Inbound",
      international: "International",
      domestic: "Domestic",
    },
    priceRange: "Price Range",
    selectedLeadQualty: "Leads Quality",
    selectedPassengers: {
      1: "1 Person",
      2: "2-3 People",
      3: "4-5 People",
      4: "6-10 People",
      5: "10+ People",
    },
    selectedDuration: {
      1: "1 - 3 Days",
      2: "4 - 7 Days",
      3: "8 - 14 Days",
      4: "14+ Days",
    },
    selectedTbooked: {
      immediately: "Immediately",
      exploring: "Just Exploring",
    },
    selectedSource: { package: "Package", other: "Non Package" },
    selectedMemberSince: {
      0: "0 - 1 Month",
      1: "1 - 12 Months",
      2: "1 - 2 Years",
      3: "2 - 5 Years",
      4: "5 - 10 Years",
      5: "10+ Years",
    },
    selectedSold: {
      0: "Virgin",
      1: "1 Time",
      2: "2 Times",
      3: "3 Times",
      4: "4 Times",
    },
  };
  const chipobjname = {
    selectedRecency: "Recency",
    endDate: "Travel End Date",
    startDate: "Travel Start Date",
    selectedLeadType: "Leads Type",
    selectedPassengers: "Traveler",
    selectedLeadQualty: "Leads Quality",
    selectedTrequired: "Tickets Required",
    selectedTheme: "Themes",
    selectedTbooked: "Need to book",
    selectedSource: "Source",
    selectedSold: "Leads Sold",
    selectedNeedBooked: "Tickets Booked",
    selectedMemberSince: "Member Since",
    selectedHotel: "Hotel Type",
    selectedGender: "Gender",
    selectedFromCities: "From City",
    selectedDuration: "Duration",
    selectedCities: "To City",
    priceRange: "Budget",
    selectedLeadStatus: "Lead Status",
  };
  const ShowClearAllButton = Object.values(data).some(
    (value) => value.length > 0
  );
  return (
    <div className="df fww">
      {Object.entries(data).map(
        ([key, value], index) =>
          value.length > 0 &&
          value !== "19700101" && (
            <div key={index} className="df">
              <span className="mt20 fc5 fs14 ml10  fw7 ">
                {chipobjname[key] ? chipobjname[key] : key}:
              </span>
              {key !== "priceRange" &&
              key !== "selectedLeadQualty" &&
              Array.isArray(value)
                ? value.length > 0 &&
                  value.map(
                    (item, i) =>
                      item !== "*" &&
                      item !== 1 && (
                        <div
                          key={i}
                          className="filter-chip p10 brd1 br24 mt10 fc5 closefilter fs12 ml10 df jcsb bg7 cp"
                          style={{ textTransform: "capitalize" }}
                          /* onClick={() => onRemove(key, item)}*/
                        >
                          {item == "*"
                            ? "1000000"
                            : chipobj[key]
                            ? chipobj[key][item]
                            : item}{" "}
                          {/*value.length > 1 && <span className="ml8" onClick={() => onRemove(key, item)}>
                    x 
                  </span> */}
                        </div>
                      )
                  )
                : value.length > 0 &&
                  value !== "19700101" && (
                    <div
                      className="filter-chip p10 brd1 br24 mt10 fc5 closefilter fs12 ml10 jcsb bg7 cp"
                      /* onClick={() => onRemove(key, value)}*/
                    >
                      {(key === "startDate" && value !== "19700101") ||
                      (key === "endDate" && value !== "19700101")
                        ? moment(value).format("D MMM Y")
                        : key === "priceRange"
                        ? "Rs. " + value[0] + " To Rs. " + value[1]
                        : key === "selectedLeadQualty"
                        ? value[0] + "% To " + value[1] + "%"
                        : value}
                      {/* value.length >1 && key!=="priceRange" &&  value!=='19700101' && <span className="ml8 " onClick={() => onRemove(key, value)}>
                    x
                  </span> */}
                    </div>
                  )}
            </div>
          )
      )}
       {ShowClearAllButton && (
        <button
          className="p10 brd1 br24 mt10 closefilter fs12 ml10 jcsb bg7 cp"
          onClick={() => {
            onRemove(); 
            trackEvent("Buylead Page", "click", ` Clear`);
          }}
        >
          Clear All
        </button>
      )}
    </div>
  );
};

export default FilterChips;
