import React, { useState, useEffect, useRef } from "react";
import "../components/AddTeamMemberForm.css";

const AddTeamMemberForm = ({
  onClose,
  onSubmit,
  show,
  editedMember,
  setSuccessType,
  setSuccessMessage,
  setErrorMessage,
  roleOptions,
  errorStatus,
  setErrorStatus,
  formErrors,
  setFormErrors,
}) => {
  const [role, setRole] = useState("");
  const [roleId, setRoleId] = useState("");
  const [emails, setEmails] = useState([]);
  const [showRoleDropdown, setShowRoleDropdown] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formRef = useRef(null);
  const roleRef = useRef(null);

  useEffect(() => {
    if (editedMember) {
      setRole(editedMember.USER_ROLE || "");
      setRoleId(editedMember.FK_ROLEID || "");
      setEmails(editedMember.TBL_LOGIN_EMAIL || "");
    } else {
      setRole("");
      setEmails([]);
    }
  }, [editedMember]);

  const handleSubmit = () => {
    const newMember = {
      role,
      roleId,
      emails,
    };
    setFormSubmitted(true);

    const errors = validate({ email: emails, roles: role });
    //return;
    setErrorStatus(errors.status);
    if (errors.status == false) {
      if (editedMember) {
        // For editing an existing member
        const updatedMember = {
          ...editedMember,
          ...newMember,
        };

        onSubmit(updatedMember);

        // setSuccessType("update");
        // setSuccessMessage("Updated Successfully");
      } else {
        onSubmit(newMember);
      }
      //});

      // Close the form
      onClose();
    } else {
      setFormErrors(errors.errors);
    }
  };

  const validate = (values) => {
    const errors = {};
    errors.email = [];
    errors.role = [];
    if (!editedMember) {
      if (values.email.length === 0) {
        errors.email.push("Email field should not be blank");
        setErrorStatus(true);
      } else {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        values.email.forEach((em) => {
          if (!emailRegex.test(em)) {
            setErrorStatus(true);
            errors.email.push("Invalid email: " + em);
          }
        });
      }
    }
    if (values.roles.length === 0 || values.roles === "") {
      setErrorStatus(true);
      errors.role.push("User Role field should not be blank");
    }

    const s = {};
    if (errors.role.length === 0 && errors.email.length === 0) {
      s.status = false;
      setErrorStatus(false);
    } else {
      s.status = true;
    }
    s.errors = errors;

    return s;
  };

  const isEmailInputDisabled = !!editedMember;

  const handleRoleSelect = (selectedRole, id) => {
    setRoleId(id);
    setRole(selectedRole);
    setShowRoleDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target) && show) {
      onClose();
    }
    if (roleRef.current && !roleRef.current.contains(event.target)) {
      setShowRoleDropdown(false);
    }
  };

  return (
    <div className={`popup ${show ? "show" : ""}`}>
      <div className="popup-content" ref={formRef}>
        <div className="modal-header" style={{ borderBottom: "0 none" }}>
          <h3>
            {editedMember ? "Update Team Member" : "Add New Member(s)"}
            <div className="new-team-text">
              {editedMember
                ? "Manage team member roles and permissions"
                : "Add new team members - an invite email is sent to each to accept and join the team"}
            </div>
          </h3>
        </div>
        <div className="modal-body">
          <form className="add-team">
            <div className="form-group">
              <label htmlFor="email">
                Email Address{!editedMember ? "(es)" : ""}
              </label>
              <input
                type="text"
                id="email"
                placeholder="Enter email addresses separated by commas..."
                value={
                  editedMember
                    ? editedMember.TBL_LOGIN_EMAIL
                    : emails.join(", ")
                }
                onChange={(e) =>
                  setEmails(
                    e.target.value.split(",").map((email) => email.trim())
                  )
                }
                disabled={isEmailInputDisabled}
              />

              {formSubmitted && (
                <div className="form-error-messages error mt10">
                  {Object.keys(formErrors).map((key) => (
                    <div key={key}>
                      {key === "email" &&
                        formErrors[key].map((error, index) => (
                          <p key={index}>{error}</p>
                        ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="form-group mt16">
              <label htmlFor="buckets">Define a Role</label>
              <div className="role-dropdown" ref={roleRef}>
                <div
                  className="selected-role"
                  onClick={() => setShowRoleDropdown(!showRoleDropdown)}
                >
                  {role || "Pick a role"}
                </div>
                {showRoleDropdown && (
                  <ul className="role-options">
                    {roleOptions.map((option) => (
                      <li
                        key={option.ID}
                        onClick={() =>
                          handleRoleSelect(option.ROLE_NAME, option.ID)
                        }
                      >
                        {option.ROLE_NAME}
                      </li>
                    ))}
                  </ul>
                )}

                {formSubmitted && (
                  <div className="form-error-messages error mt10">
                    {Object.keys(formErrors).map((key) => (
                      <div key={key}>
                        {key === "role" &&
                          formErrors[key].map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group buttons-right">
              <button type="button" onClick={onClose} className="btn-cancel">
                Cancel
              </button>
              <button type="button" onClick={handleSubmit} className="btn-blue">
                {editedMember ? "Update Team" : "Invite Team"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTeamMemberForm;
