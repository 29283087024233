import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import "../components/ImageUpload.css";
import trackEvent from "../utils/gaTracker";

const ImageUpload = ({
  authId,
  baseUrl,
  imageUrl,
  uploadUrl,
  deleteUrl,
  setWorkImage,
  delimg,
  toast,
  eventLabel,
  eventCategory,
  editStatus,
  uploadImgLocal,
  setUploadImgLocal,
  hotelDetails,
  activityDetails,
  uploadtype,
  setImgFile
}) => {
  // let imgobj;

  // useEffect(() => {
  //   if (imageUrl) {
  //     setimageUrl(imageUrl);
  //   }
  // }, [imageUrl]);

  // const [imageUrl, setimageUrl] = useState("");
  const fileInputRef = useRef();

  const handleFileInputChange = (e) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const file = e.target.files[0];

    if (file) {
      if (allowedTypes.includes(file?.type)) {
        const imageSetURL = URL.createObjectURL(file);
        setUploadImgLocal(imageSetURL);
        setWorkImage(file);
        uploadImg(file);
      } else {
        toast.error("Problem in uploading");
      }
    }
  };

  function uploadImg(file) {
    let formData = new FormData();
    formData.append("file", file);
    if(uploadtype==='hotelimage'){
      formData.append("hotelDetails", JSON.stringify(hotelDetails));
    }
    if(uploadtype==='activityimage'){
      formData.append("activityDetails", JSON.stringify(activityDetails));
      formData.set("fun", "activityimageUpload");
    }
    else{
      formData.set("fun", "uploadImg");
    }
    formData.set("auth_id", authId);
    

    axios
      .post(`${baseUrl}${uploadUrl}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if(response.data.data.status==='0' && response.data.data.msg){
          toast.error(response.data.data.msg);
        }
        else if(response.data.data.data){
          setImgFile(response.data.data.data);
        }
      })
      .catch((error) => {});
  }

  function deleteImg() {
    axios({
      method: "post",
      url: `${baseUrl}${deleteUrl}`,
      headers: {},
      data: {
        auth_id: authId, // This is the body part
      },
    })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
    trackEvent(eventCategory, "click", eventLabel);
  };

  const handleDeleteButtonClick = () => {
    // setimageUrl(null);
    deleteImg();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   const file = e.dataTransfer.files[0];
  //   if (file) {
  //     setimageUrl({ url: URL.createObjectURL(file), name: file.name });
  //     setWorkImage(file);
  //     toast.success("Successfully Uploaded");
  //   }
  // };

  return (
    <div className="image-upload-container">
      <div
        className="image-preview"
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        // onDrop={handleDrop}
        onClick={handleUploadButtonClick}
        style={{
          cursor: "pointer",
          border: imageUrl ? "2px solid #ccc" : "2px dashed #ccc",
          padding: imageUrl ? "20px" : "20px",
          backgroundColor: imageUrl ? "transparent" : "#f9f9f9",
          maxWidth: "320px",
          width: "80%",
          height: imageUrl ? "160px" : "160px",
          display: imageUrl ? "flex" : "flex",
          alignItems: imageUrl ? "center" : "center",
          justifyContent: imageUrl ? "center" : "center",
        }}
      >
        {imageUrl ? (
          <>
            <img
              className="testing"
              src={uploadImgLocal ? uploadImgLocal : imageUrl}
              alt={"Upload"}
            />
            <div className="image-overlay">{/* <p>{imageUrl.name}</p> */}</div>
          </>
        ) : (
          <p className="box-center">Click to upload images</p>
        )}
      </div>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*,.pdf"
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />
      {/* {!delimg && imageUrl && editStatus && (
        <button onClick={handleDeleteButtonClick} className="df mt8 ls1 fs14">
          Delete
        </button>
      )} */}
    </div>
  );
};

export default ImageUpload;
