// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transfer-image img {
  width: 60px;
  height: 60px;
}

.flight-line {
  width: 40%;
  background-color: #ccc;
  height: 1px;
}

.flight-line::after,
.flight-line::before {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  content: "";
  border: 1px solid #ccc;
}

.flight-line::before {
  right: 0;
}

.icon-container {
  position: absolute;
  top: -7px;
  left: 45%;
  transform: translateX(-45%);
}

.rotated-icon {
  transform: rotate(90deg);
}

@media screen and (max-width: 400px) {
  .custom-transfer {
    flex-direction: column;
    align-items: start;
  }
}
@media screen and (max-width: 480px) {
  .transfer-image img {
    width: 40px;
    height: 40px;
  }
  .custom-transfer .image-contain {
    margin-right: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PackageBuilder/TransferCard.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,sBAAsB;EACtB,WAAW;AACb;;AAEA;;EAEE,UAAU;EACV,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE;IACE,sBAAsB;IACtB,kBAAkB;EACpB;AACF;AACA;EACE;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".transfer-image img {\r\n  width: 60px;\r\n  height: 60px;\r\n}\r\n\r\n.flight-line {\r\n  width: 40%;\r\n  background-color: #ccc;\r\n  height: 1px;\r\n}\r\n\r\n.flight-line::after,\r\n.flight-line::before {\r\n  width: 8px;\r\n  height: 8px;\r\n  background-color: #fff;\r\n  border-radius: 50%;\r\n  position: absolute;\r\n  content: \"\";\r\n  border: 1px solid #ccc;\r\n}\r\n\r\n.flight-line::before {\r\n  right: 0;\r\n}\r\n\r\n.icon-container {\r\n  position: absolute;\r\n  top: -7px;\r\n  left: 45%;\r\n  transform: translateX(-45%);\r\n}\r\n\r\n.rotated-icon {\r\n  transform: rotate(90deg);\r\n}\r\n\r\n@media screen and (max-width: 400px) {\r\n  .custom-transfer {\r\n    flex-direction: column;\r\n    align-items: start;\r\n  }\r\n}\r\n@media screen and (max-width: 480px) {\r\n  .transfer-image img {\r\n    width: 40px;\r\n    height: 40px;\r\n  }\r\n  .custom-transfer .image-contain {\r\n    margin-right: 12px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
