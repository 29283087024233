import axios from "axios";
import React, { useState, useEffect } from "react";
import { TiTick } from "react-icons/ti";
import logoImage from "../assets/hellotravel-logo.png";
import "../pages/Login.css";
import { Link } from "react-router-dom";
import SmallLoader from "../components/SmallLoader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../hooks/useTitle";
import forgotImage from "../assets/forgot.jpg";
import trackEvent from "../utils/gaTracker";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);
  const [forgotEmail, setForgotEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [errorStatus, setErrorStatus] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  useTitle("Forget Password -  Travel Agents CRM - Hellotravel");

  function handleCheckLogin(auth_id) {
    axios({
      method: "post",
      url: `${user.base_url}/login.php?fun=getUser`,
      headers: {},
      data: {
        auth_id: auth_id,
      },
    }).then(function (response) {
      if (response) {
        if (response.data.login.status == "1") {
          navigate("/");
        }
      }
    });
  }

  useEffect(() => {
    if (user.auth_id !== null) {
      handleCheckLogin(user.auth_id);
    }
  }, []);
  const forgotpwdValidate = (email) => {
    const errors = {};
    errors.email = [];

    errors.authenticate = [];
    errors.unauthenticate = [];

    if (email.length === 0) {
      errors.email.push("Email is empty!");
      setErrorStatus(true);
    }

    const returnError = {};

    if (errors.email.length === 0) {
      returnError.status = false;
      setErrorStatus(false);
    } else {
      returnError.status = true;
    }

    returnError.errors = errors;

    return returnError;
  };

  const handleForgotPwd = async (e) => {
    e.preventDefault();
    trackEvent("Forgot Password Page", "click", "Submit");

    setDataStatus(false);
    setLoading(true);
    setFormSubmitted(true);

    const loginErrors = forgotpwdValidate(forgotEmail);

    if (loginErrors.status === true) {
      setFormErrors(loginErrors.errors);
      setLoading(false);
      return false;
    } else {
      axios({
        method: "post",
        url: `${user.base_url}/forgotpwd.php?fun=forgotpwd`,
        headers: {},
        data: {
          forgotEmail: forgotEmail,
        },
      }).then(function (response) {
        if (response) {
          if (response.data.data.status === "1") {
            loginErrors.errors.authenticate.push(response.data.data.msg);
            setFormErrors(loginErrors.errors);
            setLoading(false);
            //return false;
          } else {
            loginErrors.errors.unauthenticate.push(response.data.data.msg);
            setFormErrors(loginErrors.errors);
            setLoading(false);
            //return false;
          }
          setDataStatus(true);
        }
      });
    }
  };

  return (
    <div className="login-container box-center ">
      <div className="login-left-side-image pr">
        {/* <div className="slider-logo">
          <img src={logoImage} alt="logo" className="slider-company-logo" />
        </div> */}
        <img src={forgotImage} alt="login" className="imgrad" />

        <div className="pa box-center fdc fc3 login-left-text w100">
          <div className="fc3 fs20 fw6 mb8 lh22">How It Works</div>
          <ul className="fc3 fs14 fw5 ls2 lh18">
            <li>
              <TiTick /> &nbsp;Enter the email associated with your account
            </li>
            <li className="mt4">
              <TiTick /> &nbsp;Check your email for a password reset link
            </li>
            <li className="mt4">
              <TiTick /> &nbsp;Click on the shared link to access the secure
              password reset page
            </li>
            <li className="mt4">
              <TiTick /> &nbsp;Set a new password for your account
            </li>
          </ul>
        </div>
      </div>
      <div className="login-right-side-form">
        <div className="pd20 w100">
          <div className="box-center login-head fdc">
            <img src={logoImage} alt="logo" className="slider-company-logo" />
            <h3 className="fc15 fw5 fs20 mt24 ls1 tac">
              Forgot Password
              <div className="fs14 fc16 mt12 fw4 ls1">
                Don't worry. Resetting your password is!
              </div>
            </h3>
          </div>

          <form className="mt24 mb48 login-forms">
            <div className="col-xl-12">
              <input
                type="text"
                id="email"
                name="email"
                className="form-control br4"
                placeholder="Enter registered email address"
                onChange={(e) => setForgotEmail(e.target.value)}
              />
            </div>
            {formSubmitted && (
              <div className="form-error-messages error mt4 ml4 blink-text-normal-error">
                {Object.keys(formErrors).map((key) => (
                  <div key={key}>
                    {key === "email" &&
                      formErrors[key].map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                  </div>
                ))}
              </div>
            )}

            {dataStatus && formSubmitted && (
              <div className="form-error-messages error  mb8 ml4 mt10 blink-text-error-normal">
                {Object.keys(formErrors).map((key) => (
                  <div key={key}>
                    {key === "unauthenticate" &&
                      formErrors[key].map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                  </div>
                ))}
              </div>
            )}

            {dataStatus && formSubmitted && (
              <div
                className="form-error-messages error  mb8 ml4 mt10  blink-text-success"
                style={{ color: "green" }}
              >
                {Object.keys(formErrors).map((key) => (
                  <div key={key}>
                    {key === "authenticate" &&
                      formErrors[key].map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                  </div>
                ))}
              </div>
            )}
            <div className="box-center">
              <button
                className="login-email box-center w100 mt24 lh22 ls1 cp flx33"
                onClick={handleForgotPwd}
              >
                {loading ? <SmallLoader /> : "Submit"}
              </button>
            </div>
          </form>
          <div className="box-center ls1 fc16 have-accout">
            Already have an account?{" "}
            <Link
              to="/login"
              className="fc1 ml4 fs16 login-here"
              onClick={() => trackEvent("Forgot Password Page", "click", " Login Here")}
            >
              Login Here
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
