import React, { useState } from "react";
import Toggle from "./Toggle";
import "./BusinessTimings.css";
import trackEvent from "../utils/gaTracker";

function BusinessTimings({ dayTimings, setDayTimings, updateDayTimings }) {
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const startTimeOptions = {
    "00:00": "00:00",
    "00:30": "00:30",
    "01:00": "01:00",
    "01:30": "01:30",
    "02:00": "02:00",
    "02:30": "02:30",
    "03:00": "03:00",
    "03:30": "03:30",
    "04:00": "04:00",
    "04:30": "04:30",
    "05:00": "05:00",
    "05:30": "05:30",
    "06:00": "06:00",
    "06:30": "06:30",
    "07:00": "07:00",
    "07:30": "07:30",
    "08:00": "08:00",
    "08:30": "08:30",
    "09:00": "09:00",
    "09:30": "09:30",
    "10:00": "10:00",
    "10:30": "10:30",
    "11:00": "11:00",
    "11:30": "11:30",
    "12:00": "12:00",
    "12:30": "12:30",
    "13:00": "13:00",
    "13:30": "13:30",
    "14:00": "14:00",
    "14:30": "14:30",
    "15:00": "15:00",
    "15:30": "15:30",
    "16:00": "16:00",
    "16:30": "16:30",
    "17:00": "17:00",
    "17:30": "17:30",
    "18:00": "18:00",
    "18:30": "18:30",
    "19:00": "19:00",
    "19:30": "19:30",
    "20:00": "20:00",
    "20:30": "20:30",
    "21:00": "21:00",
    "21:30": "21:30",
    "22:00": "22:00",
    "22:30": "22:30",
    "23:00": "23:00",
    "23:30": "23:30",
    "24:00": "24:00",
  };

  const endTimeOptions = {
    "00:00": "00:00",
    "00:30": "00:30",
    "01:00": "01:00",
    "01:30": "01:30",
    "02:00": "02:00",
    "02:30": "02:30",
    "03:00": "03:00",
    "03:30": "03:30",
    "04:00": "04:00",
    "04:30": "04:30",
    "05:00": "05:00",
    "05:30": "05:30",
    "06:00": "06:00",
    "06:30": "06:30",
    "07:00": "07:00",
    "07:30": "07:30",
    "08:00": "08:00",
    "08:30": "08:30",
    "09:00": "09:00",
    "09:30": "09:30",
    "10:00": "10:00",
    "10:30": "10:30",
    "11:00": "11:00",
    "11:30": "11:30",
    "12:00": "12:00",
    "12:30": "12:30",
    "13:00": "13:00",
    "13:30": "13:30",
    "14:00": "14:00",
    "14:30": "14:30",
    "15:00": "15:00",
    "15:30": "15:30",
    "16:00": "16:00",
    "16:30": "16:30",
    "17:00": "17:00",
    "17:30": "17:30",
    "18:00": "18:00",
    "18:30": "18:30",
    "19:00": "19:00",
    "19:30": "19:30",
    "20:00": "20:00",
    "20:30": "20:30",
    "21:00": "21:00",
    "21:30": "21:30",
    "22:00": "22:00",
    "22:30": "22:30",
    "23:00": "23:00",
    "23:30": "23:30",
    "24:00": "24:00",
  };

  const [toggleStates, setToggleStates] = useState({
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
    Sunday: true,
  });

  const handleToggle = (day) => {
    setToggleStates({
      ...toggleStates,
      [day]: !toggleStates[day],
    });
    trackEvent("KYC Page", "click", `${day} ${!toggleStates[day]}`);
    const newDayTimings = [...dayTimings];
    const dayIndex = daysOfWeek.indexOf(day);
    if (dayIndex !== -1) {
      // console.log("Selected Data:", newDayTimings[dayIndex],newDayTimings[dayIndex].status);
    }

    let newStatus = newDayTimings[dayIndex].status;
    if (newStatus === false) {
      newStatus = true;
    } else {
      newStatus = false;
    }

    setDayTimings((prevDayTimings) =>
      prevDayTimings.map((item) => {
        if (item.day === day) {
          return { ...item, status: newStatus };
        }
        return item;
      })
    );
  };

  const handleStartTime = (selectedStartTime, day) => {
    trackEvent("KYC Page", "click", "Start Date Changed");
    const newDayTimings = [...dayTimings];
    const dayIndex = daysOfWeek.indexOf(day);
    if (dayIndex !== -1) {
      newDayTimings[dayIndex] = {
        day: day,
        startTime: selectedStartTime,
        endTime: newDayTimings[dayIndex] && newDayTimings[dayIndex].endTime > selectedStartTime
          ? newDayTimings[dayIndex].endTime
          : endTime,
        status: true,
      };
      setDayTimings(newDayTimings);
    }
  };
  
  const handleEndTime = (selectedEndTime, day) => {
    trackEvent("KYC Page", "click", "End Date Changed");
    const newDayTimings = [...dayTimings];
    const dayIndex = daysOfWeek.indexOf(day);
    if (dayIndex !== -1 && newDayTimings[dayIndex].startTime < selectedEndTime) {
      newDayTimings[dayIndex] = {
        day: day,
        startTime: newDayTimings[dayIndex] && newDayTimings[dayIndex].startTime < selectedEndTime
          ? newDayTimings[dayIndex].startTime
          : startTime,
        endTime: selectedEndTime,
        status: true,
      };
      setDayTimings(newDayTimings);
      setEndTime(selectedEndTime); // Update the endTime state here
    }
  };

  const handleApplyToAll = (day) => {
    trackEvent("KYC Page", "click", `Apply to All`);
    const newDayTimings = [...dayTimings];
    const dayIndex = daysOfWeek.indexOf(day);
    if (dayIndex !== -1) {
      // console.log("Selected Data:", newDayTimings[dayIndex]);
    }

    const updatedDayTimings = newDayTimings.map((item) => ({
      ...item,
      startTime: newDayTimings[dayIndex].startTime,
      endTime: newDayTimings[dayIndex].endTime,
    }));
    setDayTimings(updatedDayTimings);
    updateDayTimings(updatedDayTimings);

    setStartTime(newDayTimings[dayIndex].startTime);
    setEndTime(newDayTimings[dayIndex].endTime);
  };

  return (
    <div>
      {dayTimings.map((data, index) => (
        <div className="business-timings mt10" key={index}>
          <div className="toggle-day mr8">
            <Toggle
              initialValue={data.status}
              onChange={(newValue) => handleToggle(data.day)}
            />
            <span className="fs14 fc18 ml8">{data.day.slice(0, 3)}</span>
          </div>
          {data.day && data.status === true ? (
            <div className="time-select-container">
              <div className="flx1 mb20 df aic jcsb">
                <select
                  className="form-control br4 start fs14"
                  value={data.startTime ? data.startTime : startTime}
                  onChange={(e) => handleStartTime(e.target.value, data.day)}
                >
                  {Object.entries(startTimeOptions).map(([key, value]) => (
                    <option
                      key={key}
                      value={value}
                      onClick={() => handleStartTime(key, data.day)}
                    >
                      {value}
                    </option>
                  ))}
                </select>
                <span className="fs14 fc17">to</span>
                <select
                  className="form-control br4 start fs14"
                  value={data.endTime ? data.endTime : endTime}
                  onChange={(e) => handleEndTime(e.target.value, data.day)}
                >
                  {Object.entries(endTimeOptions).map(([key, value]) => (
                    <option
                      key={key}
                      value={value}
                      onClick={() => handleEndTime(key, data.day)}
                    >
                      {value}
                    </option>
                  ))}
                </select>
                <span
                  className="apply-all-button fc1 fw5 fs14 cp tdu"
                  onClick={() => handleApplyToAll(data.day)}
                >
                  Apply to All
                </span>
              </div>
            </div>
          ) : (
            <div className="off-text fc17 fs14 ls1 ml20">Off Time</div>
          )}
        </div>
      ))}
    </div>
  );
}

export default BusinessTimings;
