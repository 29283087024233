import React from "react";
import "../components/PackageInformation.css";
import {FaRegClock, FaPalette } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { BsCurrencyRupee } from "react-icons/bs";
import { useNavigate, useResolvedPath } from "react-router-dom";
import trackEvent from "../utils/gaTracker";


const PackageInformation = ({ data, pkgSelectAction, leadid }) => {
  const navigate = useNavigate();
  //const { id, image, destinations, duration, theme, price, status } = data;
  const defaultimg = "https://www.hlimg.com/images/holiday.png";
  const imgurl = "https://www.hlimg.com/images/deals/360X230/";
  const {
    id,
    nid,
    fcp_uid,
    title,
    price,
    duration,
    destinations_covered_text,
    status,
    path,
    themes,
  } = data;
  const statusClass = status !== 1 ? "sold-out" : "";

  const handleSelectButtonClick = () => {
    pkgSelectAction(id);
    trackEvent("Lead Detail", "click", "Send Quote - Select");
  };

  const handlePreviewButtonClick = () => {
    trackEvent("Lead Detail", "click", "Send Quote - Preview");
    window.open(
      `https://www.hellotravel.com/hellotravel2.0/package_itinerary_detail.php?nid=${nid}&fcpuid=${fcp_uid}&leadid=${leadid}`
    );
  };

  const handleEditButtonClick = () => {
    trackEvent("Lead Detail", "click", "Send Quote - Edit");
    navigate("/package-builder/" + id);
  };

  const durationVal = duration.split("/");
  let days = durationVal[0].replace(" Days", "D");
  let night = durationVal[1].replace(/ Night(s)?/gi, "N");

  const destinations = destinations_covered_text.replace(/,\s*/g, ", ");

  return (
    <div className="component-container df fdc  br8">
      <div className="df mb8">
        <div className="image-container pr ofh">
          <img src={path ? imgurl + path : defaultimg} alt="Destination" />
          <div className={`status pa bg1 fc3 br4 fs12 ${statusClass}`}>
            {status === 1 ? "Public" : "Private"}
          </div>
        </div>
        <div className="df fdc ">
          <div className="info-container df flx100">
            <div className="">
              <p className="df aic">
                <p className="fs14 fw5 fc14 text-row lh18 lc2 ofh ls1">{title}</p>
              </p>
              <p className="df aic w100">
                <IoLocationSharp className="fc17 mt8 mr4 fs16" />{" "}
                <span
                  className="fs14 fw5 fc17 text-row lc1 mt8 ofh"
                  style={{ textTransform: "capitalize" }}
                >
                  {destinations}
                </span>
              </p>

              {themes && (
                <p
                  className="pt8 df aic fs14 fw5 fc17 text-row lc1 mt8"
                  style={{ textTransform: "capitalize" }}
                >
                  <FaPalette className="fc17 mr4" />
                  {themes.slice(0, 4).map((option, index) => (
                    <React.Fragment key={index}>
                      {option}
                      {index === 3 && <br />}{" "}
                      {/* Insert a <br> after the 4th element */}
                    </React.Fragment>
                  ))}
                </p>
              )}

              <div className="df aic">
                <p className="pt16 df aic">
                  <FaRegClock className="fw5 fc17 fs14" />{" "}
                  <p className="fs14 ml4 fc17">
                    {days.trim()}/{night.trim()}
                  </p>
                </p>
                <div>
                  <p className="pt16 df aic ml24">
                    <BsCurrencyRupee className="fw5 fc14 fs14" />{" "}
                    <p className="fs14 fw5 fc14">{price}/-</p>
                    <span className="fs12 fc5 fw4"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-center mb8">
        <button
          className="preview-select-button bg1 fc3 born cp fs12 br4 mt8 fw6"
          onClick={handleSelectButtonClick}
          //selectedId={id}
        >
          Select
        </button>
        <button
          className="preview-select-button bg1 fc3 born cp fs12 br4 mt8 ml8 fw6"
          onClick={handlePreviewButtonClick}
        >
          Preview
        </button>
        <button
          className="preview-select-button bg1 fc3 born cp fs12 br4 mt8 ml8 fw6"
          onClick={handleEditButtonClick}
        >
          Edit
        </button>
        {/* <button className="preview-select-button bg1 fc3 born cp fs12 br4 mt8 ml8 fw6">
          Copy
        </button> */}
      </div>
    </div>
  );
};

export default PackageInformation;
