import React, { useState, useEffect } from "react";
import axios from "axios";
import InnerHeader from "../components/InnerHeader";
import Card from "../components/Card";
import "./BuyLead.css";
import CardLead from "../components/CardLead";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import BuyLeadFilter from "../components/Filters/BuyLeadFilter";
import LeadDetailsPopup from "../components/Popup/LeadDetailsPopup";
import { useLocation } from "react-router-dom";
import FilterChips from "../components/FilterChips.js";
import SmallLoader from "../components/SmallLoader.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTitle } from "../hooks/useTitle";
import PlanPopup from "../components/Popup/PlanPopup.js";
import { FaFilter } from "react-icons/fa";
import trackEvent from "../utils/gaTracker";
import Banner from "../components/Banner.js";


const BuyLeads = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useTitle("Buy Leads -  Travel Agents CRM - Hellotravel");

  const [BuyLeadsData, setBuyLeadsData] = useState([]);
  const [BuyLeadsData_New, setBuyLeadsDataNew] = useState([]);
  const [BuyLeadsDataCount, setBuyLeadsDataCount] = useState(0);
  const user = useSelector((state) => state.auth);
  const userpkgid = user.otherdata.other_details.PKGID;
  const premium_filter = user.otherdata.other_details.premium;
  var primium = true;
  if (
    userpkgid === "15" ||
    userpkgid === "39" ||
    userpkgid === "40" ||
    userpkgid === "41" ||
    user.userid === "630" ||
    premium_filter === 1
  ) {
    primium = false;
  }
  const [pkgID, setpkgID] = useState(primium);
  const [authId, setAuth] = useState(user.auth_id);
  const [dataStatus, setDataStatus] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [startRow, setStartRow] = useState(0);
  const [updateFilter, setupdateFilter] = useState([]);
  const [newLeadCount, setnewLeadCount] = useState(0);
  const [oldTimetogetNewLead, setoldTimetogetNewLead] = useState(
    getCurrentDate()
  );
  const [autoLoader, setAutoLoader] = useState(true);
  const handleCardClick = (lead) => {
    setSelectedLead(lead);
    setPopupVisible(true);
    trackEvent("Buylead Page", "click", "Buylead Detail Popup");
  };

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
    trackEvent("Buylead Page", "click", "Buylead Detail Popup - Close");
  };
  const [selectedLead, setSelectedLead] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [removeChipFilter, setremoveChipFilter] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);

  const [param, setParam] = useState({
    dest_type: "DOM",
    datefilter: oldTimetogetNewLead,
    //datefilter:"20231101142105",
    agentcountrycode: user.country_iso_code,
    leadsdata: "1",
    showrelevant: "0",
    aqs_type: "2",
    alert_city: "",
    usr_id: user.userid,
    lat_long: "28.6667,77.2167",
    start_row: startRow,
  });

  const handleFilterData = (filteredData, type) => {
    const updatedfilter = { ...updateFilter, ...filteredData };
    setupdateFilter(updatedfilter);
    const updatedParam = { ...param, start_row: 0 };
    setParam(updatedParam);
    setStartRow(0);
    setBuyLeadsData([]);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const fetchAndUpdateData = () => {
    fetchbuyleaddataLatest(param, updateFilter);
  };
  const handleShowMoreLead = () => {
    const currenttime = getCurrentDate();
    const updatedParam = { ...param, datefilter: currenttime, start_row: 0 };
    setParam(updatedParam);
    // Filter out the new leads that don't already exist in BuyLeadsData by comparing their IDs
    const filteredNewLeads = BuyLeadsData_New.filter((newLead) => {
      return !BuyLeadsData.some(
        (existingLead) => existingLead.id === newLead.id
      );
    });
    // Prepend the filtered new leads to the existing BuyLeadsData
    setBuyLeadsData((prevData) => [...filteredNewLeads, ...prevData]);
    // Reset the newLeadCount to 0
    setnewLeadCount(0);
  };

  useEffect(() => {
    if (Object.keys(updateFilter).length > 0) {
      fetchbuyleaddata(param, updateFilter);
      setremoveChipFilter(false);
    }
  }, [updateFilter]);

  useEffect(() => {
    const intervalId = setInterval(fetchAndUpdateData, 60000);
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [param]);
  useEffect(() => {
    const scrollHandler = () => {
      const { scrollHeight, scrollTop, clientHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 70 && !isFetching) {
        setIsFetching(true);
        const updatedParam = { ...param, start_row: startRow };
        setParam(updatedParam);
        trackEvent("Buylead Page", "click", "Load More");
        if (startRow < BuyLeadsDataCount) {
          fetchbuyleaddata(updatedParam, updateFilter);
        }
      }
    };
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [isFetching, param, startRow, BuyLeadsDataCount]);

  function getCurrentDate() {
    var someDate = new Date();
    someDate.setMinutes(someDate.getMinutes() - 3);
    var dd = someDate.getDate();
    var mm = someDate.getMonth() + 1;
    var y = someDate.getFullYear();
    var s = someDate.getSeconds();
    var i = someDate.getMinutes();
    var h = someDate.getHours();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    h = h < 10 ? "0" + h : h;
    i = i < 10 ? "0" + i : i;
    s = s < 10 ? "0" + s : s;
    y = y.toString();
    mm = mm.toString();
    dd = dd.toString();
    h = h.toString();
    i = i.toString();
    s = s.toString();

    var someFormattedDate = y + mm + dd + h + i + s;
    //var dateval = 20181002000000;
    return someFormattedDate;
  }

  function fetchbuyleaddataLatest(params, filteredData) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/services/buylead.php?fun=allLeads_new_count`,
      headers: {},
      data: {
        auth_id: authId,
        param: params,
        filteredData: filteredData,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
      } else {
        setBuyLeadsDataNew(response.data.data.buylead_data);
        setnewLeadCount(response.data.data.buylead_count);
      }
    });
  }

  //=============start Fetch data=================//
  const fetchbuyleaddata = async (params, filteredData) => {
    setAutoLoader(true);
    await axios({
      method: "post",
      url: `${user.base_url}/subuser/services/buylead.php?fun=allLeads_new`,
      headers: {},
      data: {
        auth_id: authId,
        param: params,
        filteredData: filteredData,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
      } else {
        if (response.data.data.buylead_data.length === 0) {
          setHasMore(false);
        }
        const updatedBuyLeadsData = [
          ...BuyLeadsData,
          ...response.data.data.buylead_data,
        ];
        setBuyLeadsData(updatedBuyLeadsData);
        setBuyLeadsDataCount(response.data.data.buylead_count);
        let counterBuylead=updatedBuyLeadsData.length;
        setStartRow(counterBuylead);
      }
      setTimeout(() => {
        setDataStatus(true);
        setIsFetching(false);
        setAutoLoader(false);
      }, 1000);
    });
  };
  const removeFilter = () => {
    setClearFilter(true);
  };
  const [filterPopupVisible, setFilterPopupVisible] = useState(false);

  const toggleFilterPopup = () => {
    setFilterPopupVisible(!filterPopupVisible);
    trackEvent("Buylead Page", "click", `Filters`);
  };
  useEffect(() => {
    const scrollHandler = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition >= 1);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);
  return (
    <div className="main-content-padding">
     {/* <Banner/>*/}
      <div className="df jcsb buy-lead-container">
        <div className="left-side-buy mr16 flx66">
          <InnerHeader
            heading="Buy Leads"
            howitworksShow={true}
            showButton={true}
            txtSubHeading="Unlock your potential and explore fresh opportunities for growth and increased sales."
          />
          {<FilterChips data={updateFilter} onRemove={removeFilter} />}
          <Card className={"bg5"}>
            <div
              className="filter-mobile pl8 pr8 pt8 fc5 df aic cp"
              onClick={toggleFilterPopup}
            >
              <p className="fs16 mr8">Filters</p>{" "}
              <FaFilter className="fc5 fs14" />
            </div>
            {newLeadCount > 0 && (
              <div
                className="box-center mt16 pt16 fw7 fs14 cp"
                onClick={handleShowMoreLead}
              >
                Show {newLeadCount} More Leads Available
              </div>
            )}
            {dataStatus === true && BuyLeadsDataCount > 0 && (
              <div className="buy-lead-container df fww pt16 pb16 pl8 pr8">
                {Object.entries(BuyLeadsData).map(([index, lead]) => (
                  <CardLead
                    lead={lead}
                    key={index}
                    param={param}
                    updateFilter={updateFilter}
                    className="card-lead"
                    onClick={handleCardClick}
                    toast={toast}
                  />
                ))}
              </div>
            )}
            {autoLoader && (
              <div className="box-center mb12">
                <SmallLoader className={"mb12"} />
              </div>
            )}
            {BuyLeadsDataCount === 0 && !autoLoader && (
              <div className="box-center mt16 pt16 fw7 fs14">
                No Record Found...
              </div>
            )}
          </Card>
        </div>
        <div className="lead-details-popup-container">
          <LeadDetailsPopup
            lead={selectedLead}
            show={popupVisible}
            setPopupVisible={setPopupVisible}
            onClose={togglePopup}
            toast={toast}
          />
        </div>
        {filterPopupVisible && (
          <PlanPopup
            show={filterPopupVisible}
            onClose={() => {
              setFilterPopupVisible(false);
            }}
          >
            <BuyLeadFilter
              onFilterData={handleFilterData}
              primium={primium}
              filterPopupVisible={filterPopupVisible}
              onClose={() => {
                setFilterPopupVisible(false);
                document.body.style.overflow = "auto";
              }}
              updateFilter={updateFilter}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
            />
          </PlanPopup>
        )}
        <div className="right-side-buy flx1">
          <div className={`buy-lead-filters fixed-filters`}>
            <Card className={"bg5"}>
              <BuyLeadFilter
                onFilterData={handleFilterData}
                primium={primium}
                updateFilter={updateFilter}
                clearFilter={clearFilter}
                setClearFilter={setClearFilter}
              />
            </Card>
          </div>
          <div className="lead-how-it-works dn">
            <h2 className="p16 fc1 fs18">How It Works</h2>
            <Card className={"bg5"}>
              <div className="how-works-step p16">
                <div className="df brd-b1 fdc">
                  <div className="df">
                    <p className="fc1 fs14">1</p>
                    <span className="ml16">Set Up Your Account:</span>
                  </div>
                  <p className="ml24 lh22 fc5 mt16  pb16 fs14">
                    Begin by signing up and creating your Hellotravel admin
                    account. Choose the plan that best fits your specific needs.
                  </p>
                </div>
                <div className="df brd-b1 fdc mt16">
                  <div className="df">
                    <p className="fc1 fs14">2</p>
                    <span className="ml16">Set Lead Preferences:</span>
                  </div>
                  <p className="ml24 lh22 fc5 mt16 pb16 fs14">
                    Customize your lead preferences to match your specific
                    requirements. Get leads automatically delivered to your
                    inbox, streamlining your lead acquisition process.
                  </p>
                </div>
                <div className="df brd-b1 fdc mt16">
                  <div className="df">
                    <p className="fc1 fs14">3</p>
                    <span className="ml16">Create Itineraries:</span>
                  </div>
                  <p className="ml24 lh22 fc5 mt16 pb16 fs14">
                    Craft both public and private itineraries and effortlessly
                    share them with potential travelers. Showcase your expertise
                    and entice your audience.
                  </p>
                </div>
                <div className="df fdc mt16">
                  <div className="df">
                    <p className="fc1 fs14">4</p>
                    <span className="ml16">Choose Leads:</span>
                  </div>
                  <p className="ml24 lh22 fc5 mt16  pb16 fs14">
                    Manual lead selection allows you to pick and purchase leads
                    that align with your current demands, ensuring flexibility
                    and control.
                  </p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" className="mt70" />
    </div>
  );
};

export default BuyLeads;
