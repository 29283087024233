import React, { useState } from 'react';
import './TimeSelect.css';

const TimeSelect = ({ time1, time2, onTime1Change, onTime2Change }) => {
  const handleTime1Change = (event) => {
    onTime1Change(event.target.value);
  };

  const handleTime2Change = (event) => {
    onTime2Change(event.target.value);
  };

  return (
    <div className="time-select-container">
      <select
        onChange={handleTime1Change}
        value={time1}
      >
        <option value="09:00 AM">09:00 AM</option>
        <option value="10:00 AM">10:00 AM</option>
        <option value="11:00 AM">11:00 AM</option>
        <option value="12:00 PM">12:00 PM</option>
      </select>
      <select
        onChange={handleTime2Change}
        value={time2}
      >
        <option value="01:00 PM">01:00 PM</option>
        <option value="02:00 PM">02:00 PM</option>
        <option value="03:00 PM">03:00 PM</option>
        <option value="04:00 PM">04:00 PM</option>
      </select>
    </div>
  );
};

export default TimeSelect;
