import React, { useState, useEffect } from "react";
import "./LeadDetailsPopup.css";
import "./PackageTransferPopup.css";
import SearchInput from "../SearchInput";
import privateImg from "../../assets/private.png";
import publicImg from "../../assets/public.png";
import groupImg from "../../assets/group.png";
import groupTransfer from "../../assets/groupTransfer.jpeg";
import { FaCar, FaTrain, FaBus, FaCarSide,FaSailboat  } from "react-icons/fa6";
import { TbBus } from "react-icons/tb";
import { MdFlight,MdFlightTakeoff  } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BsCarFrontFill } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sedan from "../../assets/sedan.png";
import muv from "../../assets/muv.png";
import bus from "../../assets/bus.png";
import hatchback from "../../assets/hatchpack.png";
import suv from "../../assets/suv.png";
import tempo from "../../assets/tempo.png";
import ship from "../../assets/boat.png";
import train from "../../assets/train.jpeg";
import flight from "../../assets/flight.png";
import metro from "../../assets/metro.png";

const PackageTransferPopup = ({ show, onClose, onTransferSelect,toast }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTypeOption, setSelectedTypeOption] = useState(null);
  const [flightDetails, setFlightDetails] = useState({
    flightName: "",
    departureTime: "",
    arrivalTime: "",
    from: "",
    to: "",
    flightNumber: "",
  });
  const [trainDetails, setTrainDetails] = useState({
    trainName: "",
    departureTime: "",
    arrivalTime: "",
    from: "",
    to: "",
    trainNumber: "",
  });
  
  const transferDetails = [
    {
      type: "Private",
      description: "Select private transport for an exclusive and personalized travel experience.",
      options: [
        { name: "Sedan", img: sedan },
        { name: "MUV", img: muv },
        { name: "SUV", img: suv },
        { name: "Hatchback", img: hatchback },
        { name: "Tempo Traveller", img: tempo },
        { name: "Bus", img: bus },
        { name: "Ship/Boat", img: ship }
      ],
      image: privateImg,
    },
    {
      type: "Public",
      description: "Select public transport to provide cost-effective and efficient travel solutions.",
      options: [
        { name: "Flight", img: flight },
        { name: "Train", img: train },
        { name: "Metro/City Bus", img: metro },
        { name: "Ship/Boat", img: ship }
      ],
      image: publicImg,
    },
    {
      type: "Group",
      description: "Choose group transport when arranging travel for multiple individuals.",
      options: [
        { name: "Sedan", img: sedan },
        { name: "MUV", img: muv },
        { name: "SUV", img: suv },
        { name: "Hatchback", img: hatchback },
        { name: "Tempo Traveller", img: tempo },
        { name: "Bus", img: bus },
        { name: "Ship/Boat", img: ship }
      ],
      image: groupTransfer,
    },
  ];

  useEffect(() => {
    if (show) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [show]);

  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const handleTypeClick = (type) => {
    setSelectedType(type);
  };

  const handleOptionClick = (option) => {
    setSelectedTypeOption(option);

    if (option === "Flight" || option === "Train") {
      // alert(`fill ${option} details`)
      return;
    }

    onTransferSelect({
      selectedType: selectedType,
      selectedTypeOption: option,
    });

    setSelectedType(null);
    setSelectedTypeOption(null);
  };

  const handleBackClick = () => {
    setSelectedType(null);
  };

  if (!show) return null;
  const handleFlightDateChange = (date, field) => {
    setFlightDetails((prevDetails) => ({
      ...prevDetails,
      [field]: date,
    }));
  };

  const handleTrainDateChange = (date, field) => {
    setTrainDetails((prevDetails) => ({
      ...prevDetails,
      [field]: date,
    }));
  };

  const handleFlightChange = (e) => {
    const { name, value } = e.target;
    setFlightDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleTrainChange = (e) => {
    const { name, value } = e.target;
    setTrainDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const handleFlightSubmit = () => {
    if(!flightDetails.from){
      toast.error("Enter from city.");
      return false;
    }
    if(!flightDetails.to){
      toast.error("Enter to city.");
      return false;
    }
    onTransferSelect({
      selectedType: selectedType,
      selectedTypeOption: selectedTypeOption,
      flightDetails: flightDetails,
    });

    setFlightDetails({
      flightName: "",
      departureTime: "",
      arrivalTime: "",
      from: "",
      to: "",
      flightNumber: "",
    });

    setSelectedType(null);
    setSelectedTypeOption(null);
  };
  const handleTrainSubmit = () => {
    if(!trainDetails.from){
      toast.error("Enter from city.");
      return false;
    }
    if(!trainDetails.to){
      toast.error("Enter to city.");
      return false;
    }
    onTransferSelect({
      selectedType: selectedType,
      selectedTypeOption: selectedTypeOption,
      trainDetails: trainDetails,
    });

    setTrainDetails({
      trainName: "",
      departureTime: "",
      arrivalTime: "",
      from: "",
      to: "",
      trainNumber: "",
    });
    setSelectedType(null);
    setSelectedTypeOption(null);
  };

  return (
    <div className="lead-popup-overlay" >
      <div className={`lead-popup-content p12 pr ${show ? "slide-in" : ""} cp`}>
        <div className="v-center jcsb">
          {selectedType ? (
            <button
              onClick={handleBackClick}
              className="transfer-back bg5 cp fc1 v-center"
            >
              <IoMdArrowRoundBack className="mr8" />
              Transfer Type
            </button>
          ) : (
            <p>Add Mode of Transfer</p>
          )}
          <button onClick={()=>{
            onClose();
            setSelectedType(null);
          }} className="lead-close-button">
            X
          </button>
        </div>
        {selectedType ? (
          // options for the selected transfer type
          <>
            <div className="tranfer-type fc14 fs18 ls2 box-center mt12">
              Transfer Type:<span className="fc1 ml4">{selectedType}</span>
            </div>

            <div className="options-container df fww mt12">
              {transferDetails
                .find((transfer) => transfer.type === selectedType)
                .options.map((option, index) => (
                  <div
                    key={index}
                    className={`popup-card-wrapper option-card transfer brd1 pt12 pb12 pl8 df mt12 mb12 box-center flx30 fdc ${
                      option.name === selectedTypeOption ? "selected" : ""
                    } ml12`}
                    onClick={() => handleOptionClick(option.name)}
                  >
                    <div className="option-icon transfer-icon-list fs30 mb12 mt12">
                      {/* {option.icon} */}
                      <img src={option.img} alt={option.name}/>
                    </div>
                    {option.name}
                  </div>
                ))}
            </div>
            {selectedType === "Public" && (
              <div className="public-specific-form">
                {selectedTypeOption === "Flight" && (
                  // Render Flight specific form
                  <div className="flight-form mt12">
                    <p className="box-center">Add Flight</p>
                    <div className="box-center fs14 fw4 mb24 c2">
                    Include flight details to ensure a smooth journey for your clients.
                    </div>  
                    <div className="df jcc">
                      <div className="flx45 req-star">
                        <label>From City<span>*</span></label>
                        <input
                          type="text"
                          name="from"
                          value={flightDetails.from}
                          onChange={handleFlightChange}
                          className="form-input"
                        />
                      </div>
                      <div className="flx45 ml24 req-star">
                        <label>To City<span>*</span></label>
                        <input
                          type="text"
                          name="to"
                          value={flightDetails.to}
                          onChange={handleFlightChange}
                          className="form-input"
                        />
                      </div>
                    </div>
                    <div className="df jcc mt12">
                      <div className="flx45 dep-time">
                        <label>Departure Time</label>
                        <DatePicker
                          selected={flightDetails.departureTime}
                          onChange={(date) =>
                            handleFlightDateChange(date, "departureTime")
                          }
                          showTimeSelect
                          dateFormat="Pp"
                          className="form-input"
                        />
                      </div>
                      <div className="flx45 ml24 arrival-time">
                        <label>Arrival Time</label>
                        <DatePicker
                          selected={flightDetails.arrivalTime}
                          onChange={(date) =>
                            handleFlightDateChange(date, "arrivalTime")
                          }
                          showTimeSelect
                          dateFormat="Pp"
                          className="form-input"
                        />
                      </div>
                    </div>
                    <div className="df jcc mt12">
                      <div className="flx45">
                        <label>Flight Name</label>
                        <input
                          type="text"
                          name="flightName"
                          value={flightDetails.flightName}
                          onChange={handleFlightChange}
                          className="form-input"
                        />
                      </div>
                      <div className="flx45 ml24">
                        <label>Flight Number</label>
                        <input
                          type="text"
                          name="flightNumber"
                          value={flightDetails.flightNumber}
                          onChange={handleFlightChange}
                          className="form-input"
                        />
                      </div>
                    </div>
                    <div className="box-center mb12">
                      <button
                        onClick={handleFlightSubmit}
                        className="form-button submit-button bg1 fc4 mt16"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
                {selectedTypeOption === "Train" && (
                  // Render Train specific form
                  <div className="flight-form mt12">
                    <p className="box-center">Add Train</p>
                    <div className="box-center fs14 fw4 mb24 c2">
                    Include train details to ensure a smooth journey for your clients.
                    </div>
                    <div className="df jcc">
                      <div className="flx45 req-star">
                        <label>From City<span>*</span></label>
                        <input
                          type="text"
                          name="from"
                          value={trainDetails.from}
                          onChange={handleTrainChange}
                          className="form-input"
                        />
                      </div>
                      <div className="flx45 ml24 req-star">
                        <label>To City<span>*</span></label>
                        <input
                          type="text"
                          name="to"
                          value={trainDetails.to}
                          onChange={handleTrainChange}
                          className="form-input"
                        />
                      </div>
                    </div>
                    <div className="df jcc mt12">
                      <div className="flx45 dep-time">
                        <label>Departure Time</label>
                        <DatePicker
                          selected={trainDetails.departureTime}
                          onChange={(date) =>
                            handleTrainDateChange(date, "departureTime")
                          }
                          showTimeSelect
                          dateFormat="Pp"
                          className="form-input"
                        />
                      </div>
                      <div className="flx45 ml24 arrival-time">
                        <label>Arrival Time</label>
                        <DatePicker
                          selected={trainDetails.arrivalTime}
                          onChange={(date) =>
                            handleTrainDateChange(date, "arrivalTime")
                          }
                          showTimeSelect
                          dateFormat="Pp"
                          className="form-input"
                        />
                      </div>
                    </div>

                    <div className="df jcc mt12">
                      <div className="flx45">
                        <label>Train Name</label>
                        <input
                          type="text"
                          name="trainName"
                          value={trainDetails.trainName}
                          onChange={handleTrainChange}
                          className="form-input"
                        />
                      </div>
                      <div className="flx45 ml24">
                        <label>Train Number</label>
                        <input
                          type="text"
                          name="trainNumber"
                          value={trainDetails.trainNumber}
                          onChange={handleTrainChange}
                          className="form-input"
                        />
                      </div>
                    </div>
                    <div className="box-center mb12">
                      <button
                        onClick={handleTrainSubmit}
                        className="form-button submit-button bg1 fc4 box-center mt16"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          // transfer type cards
          transferDetails.map((transfer, index) => (
            <div
              key={index}
              className={`popup-card-wrapper transfer brd1 pt8 pb8 pl8 df mt24 mb24 box-center`}
              onClick={() => handleTypeClick(transfer.type)}
            >
              <div className="left-image">
                <div className="image-contain pr">
                  <img src={transfer.image} alt={"Mode of Transfer"} />
                </div>
              </div>
              <div className="pl16 w100">
                <div className="fs18 fw6 fc1 ls1">{transfer.type}</div>
                <div className="fs14 pt8 fc5 ls1 lh22">{transfer.description}</div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PackageTransferPopup;
