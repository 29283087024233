// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sucess-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0.9375rem 1.875rem;
    background: #F3F3F9;
    transition: all .2s ease;
    border-bottom: 1px solid #dcdaf5;
    background-color: #f4f3ff;
}

.fw5{
    font-weight: 500;
}
.fw6{
    font-weight: 600;
}
.fs16{
    font-size: 16px;
}
.sucess{
    color: #008000;
    margin-right: 16px;
}
.error{
    color: #d93d49;
    margin-right: 16px;
}

.clear{
    text-decoration: underline;
    color: #000;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/SucessMessage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,2BAA2B;IAC3B,mBAAmB;IACnB,wBAAwB;IACxB,gCAAgC;IAChC,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB;AACA;IACI,cAAc;IACd,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,WAAW;IACX,eAAe;AACnB","sourcesContent":[".sucess-message {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex-wrap: wrap;\r\n    padding: 0.9375rem 1.875rem;\r\n    background: #F3F3F9;\r\n    transition: all .2s ease;\r\n    border-bottom: 1px solid #dcdaf5;\r\n    background-color: #f4f3ff;\r\n}\r\n\r\n.fw5{\r\n    font-weight: 500;\r\n}\r\n.fw6{\r\n    font-weight: 600;\r\n}\r\n.fs16{\r\n    font-size: 16px;\r\n}\r\n.sucess{\r\n    color: #008000;\r\n    margin-right: 16px;\r\n}\r\n.error{\r\n    color: #d93d49;\r\n    margin-right: 16px;\r\n}\r\n\r\n.clear{\r\n    text-decoration: underline;\r\n    color: #000;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
