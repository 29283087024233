import React, { useState, useEffect } from "react";
import Card from "./Card";
import "./Table.css";
import { FaPencilAlt } from "react-icons/fa";
import Popup from "./Popup/Popup";
import StatusUpdateForm from "./Forms/StatusUpdateForm";
import { getStatusBackgroundColor } from "../helpers/statusColors";
import { getLeadViaTextColor } from "../helpers/textColors";
import CustomDropdown from "./CustomDropdown";
import AppBarButton from "./AppBarButton";
import Filter from "./FilterMyLead";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import SmallLoader from "./SmallLoader";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../hooks/useTitle";
import Tooltip from "./Tooltip.js";
import trackEvent from "../utils/gaTracker";

const LeadListing = ({ onRowClick }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isUpdateStatusPopupVisible, setUpdateStatusPopupVisible] =
    useState(false);
  const [selectedAgents, setSelectedAgents] = useState("");
  const [isAssignToPopupVisible, setAssignToPopupVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [clearFilterData, setClearFilterData] = useState(false);
  const user = useSelector((state) => state.auth);
  useTitle("My Leads -  Travel Agents CRM - Hellotravel");

  //my states for leadlisting
  const [myLeadListingData, setMyLeadListingData] = useState([]);
  const [myLeadAgents, setMyLeadAgents] = useState([]);
  const [countTab, setCountTab] = useState("");
  const [isFetching, setFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(localStorage.getItem("selectedTab")?localStorage.getItem("selectedTab"):"All");
  const [selectedFilt, setSelectedFilter] = useState("");
  const [filterLabel, setFilterLabel] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [totalPage, setTotalPage] = useState("");
  const [isCheckLoginType, setisCheckLoginType] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openPopup = () => {
    setPopupVisible(true);
  };

  const updateLeadAssign = async (
    selectedRows,
    newAssignedTo,
    myLeadListingData
  ) => {
    try {
      const requestBody = {
        auth_id: user.auth_id,
        lids: selectedRows,
        newAssignedTo: newAssignedTo,
      };

      const leadAssignApiResponse = await fetch(
        `${user.base_url}/subuser/lead-listing.php?fun=updateleadassign`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const leadAssignJson = await leadAssignApiResponse.json();
      if (leadAssignJson.login.login.status === 0) {
        handleLogout();
        return false;
      }
      if (leadAssignApiResponse.ok) {
        const updatedData = myLeadListingData.map((lead) => {
          const leadIdAsString = String(lead.tbl_lead_id);

          if (selectedRows.includes(leadIdAsString)) {
            return { ...lead, assignedTo: newAssignedTo.value };
          }
          return lead;
        });

        setMyLeadListingData(updatedData);
        setSelectedRows([]);
        toast.success("Agent updated successfully");
      } else {
        console.error("Failed to update lead assignment");
        toast.error("Failed to update Agent");
      }
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const updateleadStatus = async (
    selectedRows,
    selectedLeadid,
    myLeadListingData
  ) => {
    try {
      const requestBody = {
        auth_id: user.auth_id,
        selectedid: selectedLeadid,
        statuslead: selectedRows,
      };

      const updateStatusApiResponse = await fetch(
        `${user.base_url}/subuser/lead-listing.php?fun=updateleadstatus`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const updateStatusJson = await updateStatusApiResponse.json();
      if (updateStatusApiResponse.ok) {
        const updatedData = myLeadListingData.map((lead) => {
          const leadIdAsString = String(lead.tbl_lead_id);
          return lead;
        });

        setMyLeadListingData(updatedData);
        setSelectedRows([]);
        toast.success("Status updated successfully");
        handleTabLeadCount();
        handleApiMyLeadListing(activeTab, 1);
      } else {
        console.error("Failed to update lead assignment");
        toast.error("Failed to update Agent");
      }
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const closePopup = () => {
    setPopupVisible(false);
    setUpdateStatusPopupVisible(false);
    setAssignToPopupVisible(false);
  };

  const handleEditStatus = (statusData) => {
    setSelectedStatus(statusData);
    openPopup();
  };

  const isRowSelected = (index) => {
    return selectedRows.includes(index);
  };

  const handleRowClick = (leadData, index) => {
    if (!isRowSelected(index)) {
      setSelectedLead(leadData);
      navigate(`/my-leads/${leadData.tbl_lead_id}`);
      // Call the onRowClick callback to update the selectedLead in the Tabs component
      onRowClick(leadData);
      trackEvent("My Lead Page", "click", "View Lead Detail");
    }
  };
  const filters = [
    { label: "Email", value: "email" },
    { label: "Phone", value: "phone" },
    { label: "Lead Id", value: "leadId" },
    { label: "Destination", value: "destination" },
    { label: "Lead Received Date", value: "leadReceivedDate" },
    { label: "Lead Travel Date", value: "leadTravelDate" },
    { label: "Agent", value: "agent" },
    { label: "Lead Via", value: "leadvia" },
    { label: "Status", value: "status" },
  ];
  const handleCheckboxChange = (leadId) => {
    const isSelected = selectedRows.includes(leadId);

    if (!isSelected) {
      setSelectedRows([...selectedRows, leadId]);
      trackEvent("My Lead Page", "click", `Multi Assigned Changes`);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== leadId));
    }
  };

  const handleAssignToButtonClick = () => {
    setAssignToPopupVisible(true);
  };
  const handleTabClick = (tabName) => {
    setPage(1);
    setMyLeadListingData([]);
	  localStorage.setItem("selectedTab",tabName);
    setActiveTab(tabName);
    setFilterValue("");
    setFilterLabel("");
    setSelectedFilter("");
    //handleApiMyLeadListing(tabName, 1);
    trackEvent("My Lead Page", "click", `Tab - ${tabName}`);
  };
  const handleUpdateStatus = (updatedStatus) => {
    updateleadStatus(
      updatedStatus,
      [updatedStatus.tbl_lead_id],
      myLeadListingData
    );
    closePopup();
  };
  const handleUpdateAssignedTo = (newAssignedTo) => {
    updateLeadAssign(selectedRows, newAssignedTo, myLeadListingData);
    closePopup();
  };
  const handleApiMyLeadListing = async (
    activeTab,
    page,
    filterLabel,
    filterValue
  ) => {
    setFetching(true);
    let searchBy = "";
    let searchText = "";

    if (filterLabel && filterValue) {
      searchBy = filterLabel;
      searchText = filterValue;
    } else {
      const savedSelectedFilter = JSON.parse(
        localStorage.getItem("selectedFilter")
      );
      const savedFilterValue = JSON.parse(localStorage.getItem("filterValue"));

      if (savedSelectedFilter && savedSelectedFilter.label) {
        searchBy = savedSelectedFilter.label;
      }

      if (savedFilterValue) {
        searchText = savedFilterValue;
      }
    }

    try {
      const requestBody = {
        auth_id: user.auth_id,
        activeTab: activeTab,
        pages: page ? page : 1,
        searchBy: searchBy,
        searchText: searchText,
      };
      const myLeadListingApiResponse = await fetch(
        `${user.base_url}/subuser/lead-listing_v1.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const myLeadListingApiJson = await myLeadListingApiResponse.json();
      if (myLeadListingApiJson.login.status === 0) {
        handleLogout();
        return false;
      }

      if (page == 1) {
        setMyLeadListingData([
          ...myLeadListingApiJson.data.datalisting.leadsdata,
        ]);
        setFilterCount(myLeadListingApiJson.data.datalisting.filter_count)
        setTotalPage(myLeadListingApiJson.data.datalisting.offset_auto);
      } else {
        setMyLeadListingData([
          ...myLeadListingData,
          ...myLeadListingApiJson.data.datalisting.leadsdata,
        ]);
      }

      setPage(page + 1);
      setFetching(false);
      setMyLeadAgents(myLeadListingApiJson.data.agentlist);
      localStorage.setItem(
        "agent",
        JSON.stringify(myLeadListingApiJson.data.agentlist)
      );
      setFetching(false);
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setFetching(false);
    }
  };

  const handleTabLeadCount = async () => {
    try {
      const requestBody = {
        auth_id: user.auth_id,
      };
      const myLeadCountApiResponse = await fetch(
        `${user.base_url}/subuser/lead-listing.php?fun=tableadcount`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const myLeadCountJson = await myLeadCountApiResponse.json();
      if (myLeadCountJson.login.status === 0) {
        handleLogout();
        return false;
      }
      setisCheckLoginType(myLeadCountJson.login);
      setCountTab(myLeadCountJson.data.tableadcount);
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const handleAssignedToClick = (e) => {
    e.stopPropagation();
  };

  const handleAssignedToChange = (newValue, myLeadId) => {
    const leadAssignId = [myLeadId];
    updateLeadAssign(leadAssignId, newValue, myLeadListingData);
    trackEvent("My Lead Page", "click", " Assigned Change");
  };
  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const handleFilterData = (selectedFilter, filterValue) => {
    //alert(1);
    setPage(1);
    setFilterValue(filterValue);
    setFilterLabel(selectedFilter.label);
    handleApiMyLeadListing(activeTab, 1, selectedFilter.label, filterValue);
  };

  const onClickFilter = (selectedFilter, filterValue) => {
 /*   trackEvent(
      "My Lead Page",
      "click",
      `Filter - ${selectedFilter.label} - ${filterValue}`
    );
*/
    handleFilterData(selectedFilter, filterValue);
  };

  useEffect(() => {
    //alert(2);
	  localStorage.setItem("selectedTab",activeTab);
    setPage(1);
    setClearFilterData(true);
    var getoldfilter = localStorage.getItem('allfilteroption');
    if(getoldfilter){
    var oldFilter = JSON.parse(getoldfilter);
    var currenttabfilter = oldFilter[activeTab];
      if(!currenttabfilter){
        handleApiMyLeadListing(activeTab, 1);
      }
    }
    else{
      handleApiMyLeadListing(activeTab, 1);
    }
    handleTabLeadCount();
  }, [activeTab]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollHeight, scrollTop, clientHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 70) {
        if (page <= totalPage) {
          trackEvent("My Lead Page", "click", "Load More");
          if (filterValue !== "" && filterLabel !== "") {
            handleApiMyLeadListing(activeTab, page, filterLabel, filterValue);
          } else {
            handleApiMyLeadListing(activeTab, page);
          }
        } else {
          setPage(page + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page]);

  return (
    <div>
      
      {isPopupVisible && (
        <Popup onClose={closePopup} title={"Update Status"}>
          <StatusUpdateForm
            selectedStatus={selectedStatus}
            onUpdateStatus={handleUpdateStatus}
            onClose={closePopup}
          />
        </Popup>
      )}
      {isAssignToPopupVisible && (
        <Popup onClose={closePopup} title={"Select Agent"}>
          <div className="assigned">
            <label>Assign To</label>
            <CustomDropdown
              options={myLeadAgents}
              selectedOption={selectedAgents}
              onSelect={(option) => setSelectedAgents(option)}
              placeholder="Select agent"
            />
          </div>
          <div className="button-container">
            <button type="button" className="btn-cancel" onClick={closePopup}>
              Cancel
            </button>
            <button
              className="update-button btn-blue box-center"
              onClick={() => handleUpdateAssignedTo(selectedAgents)}
            >
              Update
            </button>
          </div>
        </Popup>
      )}
      <Card className={"bg5"}>
        <div className="tabs-container">
          <div className="tab-buttons">
            <button
              className={`tab-button ${activeTab === "All" ? "active" : ""}`}
              onClick={() => handleTabClick("All")}
            >
              All ({countTab.Total ? countTab.Total : 0} Leads)
            </button>
            <button
              className={`tab-button ${
                activeTab === "Followups" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Followups")}
            >
              Follow-up ({countTab.Followup ? countTab.Followup : 0} Leads)
            </button>
            <button
              className={`tab-button ${activeTab === "Hot" ? "active" : ""}`}
              onClick={() => handleTabClick("Hot")}
            >
              Hot ({countTab.Hot ? countTab.Hot : 0} Leads)
            </button>
            <button
              className={`tab-button ${activeTab === "Booked" ? "active" : ""}`}
              onClick={() => handleTabClick("Booked")}
            >
              Booked ({countTab.Booked ? countTab.Booked : 0} Leads)
            </button>
          </div>
          
          <div className="df aic fww brd-b1">
            <Filter
              filters={filters}
              agents={myLeadAgents}
              onClickFilter={onClickFilter}
              selectedFilt={selectedFilt}
              filterVal={filterValue}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              setSelectedFilterLable={setFilterLabel}
              apiCall={handleApiMyLeadListing}
              handleTabClick={handleTabClick}
              activeTab={activeTab}
              clearFilterData={clearFilterData}
              setClearFilterData={setClearFilterData}
            />
          </div>
          <div class="mylead-filters v-center jcsb pl16 brd-b1 pb8 pt8 fww fs12 ">Total Results: {filterCount}</div>
          <div className="booked w100 mt16" style={{ overflow: "auto" }}>
            <table className="mylead-table table cp">
              <thead>
                <tr>
                  <th></th>
                  <th>Status</th>
                  <th>Leads Via</th>
                  <th>Name</th>
                  <th>Destination</th>
                  <th>From City</th>
                  <th>Travel Date</th>
                  <th>Duration</th>
                  <th>Budget</th>
                  {myLeadAgents.length > 1 && <th>Assigned</th>}
                  <th>Lead Recv.</th>
                  <th>Follow-up Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              {activeTab === "All" && (
                <tbody>
                  {myLeadListingData.length > 0 ? (
                    myLeadListingData.map((record, index) => (
                      <tr key={index} onClick={() => handleRowClick(record)}>
                        <td>
                          <label
                            className="checkbox-label"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <input
                              type="checkbox"
                              className={`${
                                isCheckLoginType.access_cid_by > 0
                                  ? "disabled-input"
                                  : "cp"
                              }`}
                              onChange={() =>
                                handleCheckboxChange(record.tbl_lead_id)
                              }
                              checked={isRowSelected(record.tbl_lead_id)}
                              disabled={
                                isCheckLoginType.access_cid_by > 0
                                  ? true
                                  : false
                              }
                            />
                          </label>
                        </td>
                        <td>
                          <span
                            style={{
                              backgroundColor: getStatusBackgroundColor(
                                record.status === "Full Payment Received" ||
                                  record.booking_status == 2
                                  ? "Full Payment Received"
                                  : record.status === "Advance Received" ||
                                    record.booking_status == 1
                                  ? "Advance Received"
                                  : record.status
                              ),
                              padding: "4px 12px",
                              display: "inline-block",
                              color: "#fff",
                              borderRadius: "8px",
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                            }}
                          >
                            {record.status === "Full Payment Received" ||
                            record.booking_status == 2
                              ? "FPR"
                              : record.status === "Advance Received" ||
                                record.booking_status == 1
                              ? "AR"
                              : record.status === "No Response"
                              ? "NR"
                              : record.status === "Followup"
                              ? "Follow-up"
                              : record.status}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              padding: "4px 12px",
                              display: "inline-block",
                              color: getLeadViaTextColor(
                                record.source === "website"
                                  ? "Website"
                                  : record.source === "manually"
                                  ? "Manual"
                                  : record.source === "autobuy"
                                  ? "Auto Buy"
                                  : record.source === "package"
                                  ? "Package"
                                  : record.source
                              ),
                              borderRadius: "8px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textTransform: "capitalize",
                            }}
                          >
                            {record.source}
                          </span>
                        </td>
                        <td className="captw ls1">
                          <Tooltip title={record.tbl_lead_name}>
                            {record.tbl_lead_name.slice(0, 8)}
                          </Tooltip>
                        </td>

                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100px",
                          }}
                        >
                          {record.tbl_lead_destination_value}
                        </td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "80px",
                          }}
                        >
                          {record.tbl_lead_city}
                        </td>
                        <td>{record.tbl_lead_travel_date}</td>
                        <td>{record.tbl_lead_duration_value}</td>
                        <td>{record.fk_tbl_budget_bkp}</td>
                        {myLeadAgents.length > 1 && (
                          <td className="assigned">
                            <select
                              value={record.assignedTo}
                              onChange={(e) =>
                                handleAssignedToChange(
                                  {
                                    label:
                                      e.target.selectedOptions[0].attributes
                                        .datav.value,
                                    value: e.target.value,
                                  },
                                  record.tbl_lead_id
                                )
                              }
                              onClick={handleAssignedToClick}
                              disabled={
                                isCheckLoginType.access_cid_by > 0
                                  ? true
                                  : false
                              }
                              style={{ textTransform: "capitalize" }}
                            >
                              {myLeadAgents.map((agrecord, agindex) => (
                                <option
                                  title={agrecord.label}
                                  value={agrecord.value}
                                  datav={agrecord.label}
                                  key={agindex}
                                >
                                  {agrecord.label.length > 10
                                    ? agrecord.label.slice(0, 10) + " ..."
                                    : agrecord.label}
                                </option>
                              ))}
                            </select>
                          </td>
                        )}
                        <td>{record.tbl_lead_recd_date_time}</td>
                        <td>
                          {record.status === "new" ||
                          record.status === "Untouched"
                            ? "---"
                            : record.followupdate
                            ? record.followupdate
                            : "---"}
                        </td>

                        <td
                          className={`${
                            isCheckLoginType.access_cid_by > 0
                              ? "disabled-input"
                              : "cp"
                          } box-center mt8`}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (isCheckLoginType.access_cid_by <= 0) {
                              handleEditStatus(record);
                            }
                            trackEvent("My Lead Page", "click", "Edit");
                          }}
                        >
                          <FaPencilAlt />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="no-data-message">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              )}

              {activeTab === "Followups" && (
                <tbody>
                  {myLeadListingData.length > 0 ? (
                    myLeadListingData.map((record, index) => (
                      <tr key={index} onClick={() => handleRowClick(record)}>
                        <td>
                          <label
                            className="checkbox-label"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <input
                              type="checkbox"
                              className="cp"
                              onChange={() =>
                                handleCheckboxChange(record.tbl_lead_id)
                              }
                              checked={isRowSelected(record.tbl_lead_id)}
                            />
                          </label>
                        </td>
                        <td>
                          <span
                            style={{
                              backgroundColor: getStatusBackgroundColor(
                                record.status === "Full Payment Received" ||
                                  record.booking_status == 2
                                  ? "Full Payment Received"
                                  : record.status === "Advance Received" ||
                                    record.booking_status == 1
                                  ? "Advance Received"
                                  : record.status
                              ),
                              padding: "4px 12px",
                              display: "inline-block",
                              color: "#fff",
                              borderRadius: "8px",
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                            }}
                          >
                            {record.status === "Full Payment Received" ||
                            record.booking_status == 2
                              ? "FPR"
                              : record.status === "Advance Received" ||
                                record.booking_status == 1
                              ? "AR"
                              : record.status === "No Response"
                              ? "NR"
                              : record.status === "Followup"
                              ? "Follow-up"
                              : record.status}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              padding: "4px 12px",
                              display: "inline-block",
                              color: getLeadViaTextColor(
                                record.source === "website"
                                  ? "Website"
                                  : record.source === "manually"
                                  ? "Manual"
                                  : record.source === "autobuy"
                                  ? "Auto Buy"
                                  : record.source === "package"
                                  ? "Package"
                                  : record.source
                              ),
                              borderRadius: "8px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textTransform: "capitalize",
                            }}
                          >
                            {record.source}
                          </span>
                        </td>
                        <td className="captw ls1 leads-tool-fix">
                          <Tooltip title={record.tbl_lead_name}>
                            {record.tbl_lead_name.slice(0, 8)}
                          </Tooltip>
                        </td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100px",
                          }}
                        >
                          {record.tbl_lead_destination_value}
                        </td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "80px",
                          }}
                        >
                          {record.tbl_lead_city}
                        </td>
                        <td>{record.tbl_lead_travel_date}</td>
                        <td>{record.tbl_lead_duration_value}</td>
                        <td>{record.fk_tbl_budget_bkp}</td>
                        {myLeadAgents.length > 1 && (
                          <td className="assigned">
                            {/* Dropdown for Assigned To */}

                            <select
                              value={record.assignedTo}
                              onChange={(e) =>
                                handleAssignedToChange(
                                  {
                                    label:
                                      e.target.selectedOptions[0].attributes
                                        .datav.value,
                                    value: e.target.value,
                                  },
                                  record.tbl_lead_id
                                )
                              }
                              onClick={handleAssignedToClick}
                              style={{ textTransform: "capitalize" }}
                            >
                              {Object.entries(myLeadAgents).map(
                                ([agindex, agrecord]) => (
                                  <option
                                    title={agrecord.label}
                                    value={agrecord.value}
                                    datav={agrecord.label}
                                    key={agindex}
                                  >
                                    {agrecord.label.length > 10
                                      ? agrecord.label.slice(0, 10) + " ..."
                                      : agrecord.label}
                                  </option>
                                )
                              )}
                            </select>
                          </td>
                        )}
                        <td>{record.tbl_lead_recd_date_time}</td>
                        <td>
                          {record.status === "new" ||
                          record.status === "Untouched"
                            ? "---"
                            : record.followupdate
                            ? record.followupdate
                            : "---"}
                        </td>

                        <td
                          className="box-center cp mt8"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditStatus(record);
                            trackEvent("My Lead Page", "click", "Edit");
                          }}
                        >
                          <FaPencilAlt />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="no-data-message">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
              {activeTab === "Hot" && (
                <tbody>
                  {myLeadListingData.length > 0 ? (
                    myLeadListingData.map((record, index) => (
                      <tr key={index} onClick={() => handleRowClick(record)}>
                        <td>
                          <label
                            className="checkbox-label"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <input
                              type="checkbox"
                              className="cp"
                              onChange={() =>
                                handleCheckboxChange(record.tbl_lead_id)
                              }
                              checked={isRowSelected(record.tbl_lead_id)}
                            />
                          </label>
                        </td>
                        <td>
                          <span
                            style={{
                              backgroundColor: getStatusBackgroundColor(
                                record.status === "Full Payment Received" ||
                                  record.booking_status == 2
                                  ? "Full Payment Received"
                                  : record.status === "Advance Received" ||
                                    record.booking_status == 1
                                  ? "Advance Received"
                                  : record.status
                              ),
                              padding: "4px 12px",
                              display: "inline-block",
                              color: "#fff",
                              borderRadius: "8px",
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                            }}
                          >
                            {record.status === "Full Payment Received" ||
                            record.booking_status == 2
                              ? "FPR"
                              : record.status === "Advance Received" ||
                                record.booking_status == 1
                              ? "AR"
                              : record.status === "No Response"
                              ? "NR"
                              : record.status === "Followup"
                              ? "Follow-up"
                              : record.status}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              padding: "4px 12px",
                              display: "inline-block",
                              color: getLeadViaTextColor(
                                record.source === "website"
                                  ? "Website"
                                  : record.source === "manually"
                                  ? "Manual"
                                  : record.source === "autobuy"
                                  ? "Auto Buy"
                                  : record.source === "package"
                                  ? "Package"
                                  : record.source
                              ),
                              borderRadius: "8px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textTransform: "capitalize",
                            }}
                          >
                            {record.source}
                          </span>
                        </td>
                        <td className="captw ls1 leads-tool-fix">
                          <Tooltip title={record.tbl_lead_name}>
                            {record.tbl_lead_name.slice(0, 8)}
                          </Tooltip>
                        </td>

                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100px",
                          }}
                        >
                          {record.tbl_lead_destination_value}
                        </td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "80px",
                          }}
                        >
                          {record.tbl_lead_city}
                        </td>
                        <td>{record.tbl_lead_travel_date}</td>
                        <td>{record.tbl_lead_duration_value}</td>
                        <td>{record.fk_tbl_budget_bkp}</td>
                        {myLeadAgents.length > 1 && (
                          <td className="assigned">
                            {/* Dropdown for Assigned To */}

                            <select
                              value={record.assignedTo}
                              onChange={(e) =>
                                handleAssignedToChange(
                                  {
                                    label:
                                      e.target.selectedOptions[0].attributes
                                        .datav.value,
                                    value: e.target.value,
                                  },
                                  record.tbl_lead_id
                                )
                              }
                              onClick={handleAssignedToClick}
                              style={{ textTransform: "capitalize" }}
                            >
                              {Object.entries(myLeadAgents).map(
                                ([agindex, agrecord]) => (
                                  <option
                                    title={agrecord.label}
                                    value={agrecord.value}
                                    datav={agrecord.label}
                                    key={agindex}
                                  >
                                    {agrecord.label.length > 10
                                      ? agrecord.label.slice(0, 10) + " ..."
                                      : agrecord.label}
                                  </option>
                                )
                              )}
                            </select>
                          </td>
                        )}
                        <td>{record.tbl_lead_recd_date_time}</td>
                        <td>
                          {record.status === "new" ||
                          record.status === "Untouched"
                            ? "---"
                            : record.followupdate
                            ? record.followupdate
                            : "---"}
                        </td>

                        <td
                          className="box-center cp mt8"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditStatus(record);
                            trackEvent("My Lead Page", "click", "Edit");
                          }}
                        >
                          <FaPencilAlt />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="no-data-message">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
              {activeTab === "Booked" && (
                <tbody>
                  {myLeadListingData.length > 0 ? (
                    myLeadListingData.map((record, index) => (
                      <tr key={index} onClick={() => handleRowClick(record)}>
                        <td>
                          <label
                            className="checkbox-label"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <input
                              type="checkbox"
                              className="cp"
                              onChange={() =>
                                handleCheckboxChange(record.tbl_lead_id)
                              }
                              checked={isRowSelected(record.tbl_lead_id)}
                            />
                          </label>
                        </td>
                        <td>
                          <span
                            style={{
                              backgroundColor: getStatusBackgroundColor(
                                record.status === "Full Payment Received" ||
                                  record.booking_status == 2
                                  ? "Full Payment Received"
                                  : record.status === "Advance Received" ||
                                    record.booking_status == 1
                                  ? "Advance Received"
                                  : record.status
                              ),
                              padding: "4px 12px",
                              display: "inline-block",
                              color: "#fff",
                              borderRadius: "8px",
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                            }}
                          >
                            {record.status === "Full Payment Received" ||
                            record.booking_status == 2
                              ? "FPR"
                              : record.status === "Advance Received" ||
                                record.booking_status == 1
                              ? "AR"
                              : record.status === "No Response"
                              ? "NR"
                              : record.status === "Followup"
                              ? "Follow-up"
                              : record.status}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              padding: "4px 12px",
                              display: "inline-block",
                              color: getLeadViaTextColor(
                                record.source === "website"
                                  ? "Website"
                                  : record.source === "manually"
                                  ? "Manual"
                                  : record.source === "autobuy"
                                  ? "Auto Buy"
                                  : record.source === "package"
                                  ? "Package"
                                  : record.source
                              ),
                              borderRadius: "8px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textTransform: "capitalize",
                            }}
                          >
                            {record.source}
                          </span>
                        </td>
                        <td className="captw ls1 leads-tool-fix">
                          <Tooltip title={record.tbl_lead_name}>
                            {record.tbl_lead_name.slice(0, 8)}
                          </Tooltip>
                        </td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100px",
                          }}
                        >
                          {record.tbl_lead_destination_value}
                        </td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "80px",
                          }}
                        >
                          {record.tbl_lead_city}
                        </td>
                        <td>{record.tbl_lead_travel_date}</td>
                        <td>{record.tbl_lead_duration_value}</td>
                        <td>{record.fk_tbl_budget_bkp}</td>
                        {myLeadAgents.length > 1 && (
                          <td className="assigned">
                            {/* Dropdown for Assigned To */}

                            <select
                              value={record.assignedTo}
                              onChange={(e) =>
                                handleAssignedToChange(
                                  {
                                    label:
                                      e.target.selectedOptions[0].attributes
                                        .datav.value,
                                    value: e.target.value,
                                  },
                                  record.tbl_lead_id
                                )
                              }
                              onClick={handleAssignedToClick}
                              style={{ textTransform: "capitalize" }}
                            >
                              {Object.entries(myLeadAgents).map(
                                ([agindex, agrecord]) => (
                                  <option
                                    title={agrecord.label}
                                    value={agrecord.value}
                                    datav={agrecord.label}
                                    key={agindex}
                                  >
                                    {agrecord.label.length > 10
                                      ? agrecord.label.slice(0, 10) + " ..."
                                      : agrecord.label}
                                  </option>
                                )
                              )}
                            </select>
                          </td>
                        )}
                        <td>{record.tbl_lead_recd_date_time}</td>
                        <td>
                          {record.status === "new" ||
                          record.status === "Untouched"
                            ? "---"
                            : record.followupdate
                            ? record.followupdate
                            : "---"}
                        </td>

                        <td
                          className="box-center cp mt8"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditStatus(record);
                            trackEvent("My Lead Page", "click", "Edit");
                          }}
                        >
                          <FaPencilAlt />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="no-data-message">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
            {isFetching && (
              <div className="box-center mb12">
                <SmallLoader className={"mb12"} />
              </div>
            )}
          </div>
        </div>
        {selectedRows.length > 0 && !selectedLead && (
          <div className="df fc3 fixed-buttons-mylead">
            <AppBarButton
              content="Assign To"
              color="#594cda"
              onClick={handleAssignToButtonClick}
            />
          </div>
        )}
      </Card>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default LeadListing;
