import React, { useState, useEffect, useRef } from "react";
import { NavLink, Route, Routes, Link } from "react-router-dom";
import "./Layout.css";
import {
  FaHome,
  FaCalendarAlt,
  FaBookReader,
  FaInfoCircle,
  FaThList,
} from "react-icons/fa";
import { FaQuestion } from "react-icons/fa6";
import {
  MdGroup,
  MdWidgets,
  MdEmail,
  MdArrowUpward,
  MdPrivacyTip,
  MdMessage,
} from "react-icons/md";
import {
  BiSolidEdit,
  BiSolidContact,
  BiUser,
  BiPowerOff,
} from "react-icons/bi";
import { IoLogOut } from "react-icons/io5";
import { RxReader } from "react-icons/rx";
import { BiSupport } from "react-icons/bi";
import { IoMdCall } from "react-icons/io";
import logoImage from "../../assets/hellotravel-logo.png";
import mobileImage from "../../assets/mobile-logo.jpeg";
import Team from "../../pages/Team";
import BuyLeads from "../../pages/BuyLeads";
import MyLeads from "../../pages/MyLeads";
import Packages from "../../pages/Packages";
import MyReports from "../../pages/MyReports";
import MyAccount from "../../pages/MyAccount";
import MyReviews from "../../pages/MyReviews";
import Settings from "../../pages/Settings";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../store/authSlice.js";
import PackageBuilder from "../../pages/PackageBuilder";
import Tooltip from "../Tooltip.js";
import profileImage from "../../assets/user.png";
import { IoMdMenu } from "react-icons/io";
import trackEvent from "../../utils/gaTracker";
import axios from "axios";

const ScrollToTopButton = ({ show }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    trackEvent(
      pathname === "/"
        ? "Buy Lead"
        : pathname === "/my-leads"
        ? "My Leads"
        : pathname === "/packages"
        ? "My Itineraries"
        : pathname === "/my-reports"
        ? "My Reports"
        : pathname === "/my-reviews"
        ? "My Reviews"
        : pathname === "/my-account"
        ? "My Account"
        : pathname === "/my-team"
        ? "My Team"
        : pathname,
      "click",
      `Go to Top`
    );
  };

  return (
    <div
      className={`scroll-to-top df fdc ${show ? "show" : ""}`}
      onClick={handleScrollToTop}
    >
      <MdArrowUpward />
    </div>
  );
};

const Layout = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [rmDropdownOpen, setRmDropdownOpen] = useState(false);
  const [profileDropdownOpen, setprofileDropdownOpen] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const user = useSelector((state) => state.auth);
  const name = user.user_name;
  const email = user.email;
  const access_cid_by = user.access_cid_by;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileRef = useRef(null);
  const rmRef = useRef(null);
  const getSavedLocalData = JSON.parse(localStorage.getItem("htloginDetails"));
  const [isCheckLoginType, setisCheckLoginType] = useState([]);
  const rmInfo = JSON.parse(getSavedLocalData.json_login_data);
  const toggleDropdown = () => {
    setprofileDropdownOpen(!profileDropdownOpen);
    trackEvent("Header", "click", "User Icon");
  };
  const toggleRmDropdown = () => {
    setRmDropdownOpen(!rmDropdownOpen);
    // trackEvent("Header", "click", "Help & Support");
  };
  const handleScroll = () => {
    const scrollY = window.scrollY;
    setShowScrollToTop(scrollY > 400);
  };

  const handleLogout = () => {
    dispatch(logout());
    trackEvent("Header", "click", "Logout");
    navigate("/login");
  };
  const trackNavLinkClick = (label) => {
    trackEvent("Sidebar Menu", "click", label);
  };
  const handleSendMailFun = (plan_name, Type) => {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=sendMailToRm`,
      headers: {},
      data: {
        auth_id: user.auth_id,
        plan_name: plan_name,
		rm_name: rmInfo.other_details.RMNAME,
        rm_email: rmInfo.other_details.RMEMAIL,
        type: Type ? Type : "",
      },
    }).then(function (response) {
      navigate("/my-account");
    });
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setprofileDropdownOpen(false);
    }
    if (rmRef.current && !rmRef.current.contains(event.target)) {
      setRmDropdownOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="layout">
      <header className="appbar">
        <div className="left-appbar v-center">
          <div
            className="hamburger-icon cp"
            onClick={() => {
              setShowSidebar(!showSidebar);
              trackNavLinkClick("Header", "Mobile Menu");
            }}
          >
            <IoMdMenu className="fc5 fs24 cp" />
          </div>
          <div className="logo">
            <Link to="/">
              <img
                src={logoImage}
                alt="Logo"
                className="logo"
                onClick={() => trackEvent("Header", "click", "Logo")}
              />
            </Link>
          </div>
          <div className="mobile-logo">
            <Link to="/">
              <img
                src={mobileImage}
                alt="Logo"
                onClick={() => trackEvent("Header", "click", "Logo mobile")}
              />
            </Link>
          </div>
        </div>
        <div className="appbar-actions">
          <button
            className="bg1 fc3 fs14 pl12 pr12 pt8 pb8 br24 ls1 blink-textr"
            onClick={() => handleSendMailFun("Recharge", "")}
          >
            Recharge Now
          </button>

          <Link to="/">
            <Tooltip title="Buy Leads">
              <FaHome
                className="fc5 fs20 mr16 ml12"
                onClick={() => trackEvent("Header", "click", "Buylead")}
              />
            </Tooltip>
          </Link>
          <Tooltip title="Help & Support">
            <span className="fc5 fs20 mr16 cp">
              <BiSupport className="fc5" onClick={toggleRmDropdown} />
            </span>
          </Tooltip>
          {rmDropdownOpen && (
            <div
              className={`dropdown-menu ${rmDropdownOpen ? "open" : ""}`}
              ref={rmRef}
            >
              <div className="profie-name-email">
                <ul>
                  <li className="fs14 rname pb8 pl12 pr12 fw6">
                    RM: {rmInfo.other_details.RMNAME}
                  </li>
                  <li
                    className="fs14 mt4 fc5 ls1 pt8 pb8 v-center rmobile  pl12 pr12"
                    onClick={() => trackEvent("Header", "click", "Help Call")}
                  >
                    <IoMdCall className="fs20 mr8 fc5" />{" "}
                    <a href={`tel:${rmInfo.other_details.RMMOBILE_ORIGINAL}`}>
                      {rmInfo.other_details.RMMOBILE_ORIGINAL}
                    </a>
                  </li>
                  <li
                    className="fs14 mt4 fc5 ls1 remail pt8 pb8 v-center  pl12 pr12"
                    onClick={() => trackEvent("Header", "click", "Help Email")}
                  >
                    <MdEmail className="fs20 mr8 fc5" />
                    <a href={`mailto:${rmInfo.other_details.RMEMAIL}`}>
                      {rmInfo.other_details.RMEMAIL}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
          <div className="v-center">
            <div
              className="profile box-center"
              ref={profileRef}
              onClick={toggleDropdown}
            >
              <img src={profileImage} alt="profile" />
              <div
                className={`dropdown-menu ${profileDropdownOpen ? "open" : ""}`}
              >
                <div className="profie-name-email">
                  <ul>
                    <li className="fs14 pl12 pr12">{name && name}</li>
                    {access_cid_by<=0 && <li className="fs14 mt4 fc5 ls1 pl12 pr12">
                      {email && email}
                    </li>}
                  </ul>
                </div>
                <ul className="profile-list-items">
                  <NavLink to="/my-account">
                    <li
                      className="v-center"
                      onClick={() =>
                        trackEvent("Header", "click", "My Account")
                      }
                    >
                      <BiUser className="fs20 mr24 fc5" />
                      My Account
                    </li>
                  </NavLink>
                  <NavLink
                    to="https://www.hellotravel.com/about-us.html"
                    target="_blank"
                  >
                    <li
                      className="v-center"
                      onClick={() => trackEvent("Header", "click", "About Us")}
                    >
                      <FaInfoCircle className="fs18 mr24 fc5" />
                      About Us
                    </li>
                  </NavLink>
                  <NavLink
                    to="https://www.hellotravel.com/contact-us"
                    target="_blank"
                  >
                    <li className="v-center">
                      <FaQuestion className="fs20 mr24 fc5" />
                      FAQ
                    </li>
                  </NavLink>
                  <NavLink
                    to="https://www.hellotravel.com/terms-conditions"
                    target="_blank"
                  >
                    <li
                      className="v-center"
                      onClick={() => trackEvent("Header", "click", "Term")}
                    >
                      <FaThList className="fs18 mr24 fc5" />
                      Term & Conditions
                    </li>
                  </NavLink>
                  <NavLink
                    to="https://www.hellotravel.com/privacy-policy"
                    target="_blank"
                  >
                    <li
                      className="v-center"
                      onClick={() => trackEvent("Header", "click", "Policy")}
                    >
                      <MdPrivacyTip className="fs18 mr24 fc5" />
                      Privacy Policy
                    </li>
                  </NavLink>
                </ul>
                <ul className="mt4 ps brd-t2">
                  <NavLink to="">
                    <li className="v-center" onClick={handleLogout}>
                      <IoLogOut className="fs20 mr24 fc5" />
                      Logout
                    </li>
                  </NavLink>
                </ul>
              </div>
            </div>

            <div className="fc5 fs14 ml8 profile-name ">
              <p className="ls1">{name && name}</p>
              <p className="mt4 fs12 v-center fw5 ls2">
                Active<span className="login-status"></span>
              </p>
            </div>
          </div>
        </div>
        {showSidebar && (
          <div className="overlay" onClick={() => setShowSidebar(false)}></div>
        )}
      </header>
      <div className="content-container">
        <nav className={`sidebar ${showSidebar ? "show" : "hide"}`}>
          {getSavedLocalData.user_mbl !== "" && (
            <>
              <NavLink
                to="/"
                activeclassname="active"
                exact="true"
                onClick={() => {
                  setShowSidebar(false);
                  trackNavLinkClick("Buy Leads");
                }}
              >
                <span>
                  <FaHome />
                  Buy Leads
                </span>
              </NavLink>
              <NavLink
                to="/my-leads"
                activeclassname="active"
                exact="true"
                onClick={() => {
                  setShowSidebar(false);
                  trackNavLinkClick("My Leads");
                }}
              >
                <span>
                  <FaCalendarAlt />
                  My Leads
                </span>
              </NavLink>
              <NavLink
                to="/packages"
                activeclassname="active"
                exact="true"
                onClick={() => {
                  setShowSidebar(false);
                  trackNavLinkClick("My Itineraries");
                }}
              >
                <span>
                  <BiSolidEdit />
                  My Itineraries
                </span>
              </NavLink>
              <NavLink
                to="/my-reports"
                activeclassname="active"
                exact="true"
                onClick={() => {
                  setShowSidebar(false);
                  trackNavLinkClick("My Report");
                }}
              >
                <span>
                  <MdWidgets />
                  My Report
                </span>
              </NavLink>
              <NavLink
                to="/my-reviews"
                activeclassname="active"
                exact="true"
                onClick={() => {
                  setShowSidebar(false);
                  trackNavLinkClick("My Reviews");
                }}
              >
                <span>
                  <BiSolidContact />
                  My Reviews
                </span>
              </NavLink>
            </>
          )}
          <NavLink
            to="/my-account"
            activeclassname="active"
            exact="true"
            onClick={() => {
              setShowSidebar(false);
              trackNavLinkClick("My Account");
            }}
          >
            <span>
              <BiUser />
              My Account
            </span>
          </NavLink>

          {getSavedLocalData.sub_user_role === "1" ||
            (getSavedLocalData.role === "2" && (
              <NavLink
                to="/my-team"
                activeclassname="active"
                exact="true"
                onClick={() => {
                  setShowSidebar(false);
                  trackNavLinkClick("My Team");
                }}
              >
                <span>
                  <MdGroup /> My Team
                </span>
              </NavLink>
            ))}
        </nav>
        <div className="main-container">
          <main className="main-content">
            <Routes>
              <Route path="/" exact element={<BuyLeads />} />
              <Route path="/my-leads" element={<MyLeads />} />
              <Route path="/my-leads/:id" element={<MyLeads />} />
              <Route path="/packages" element={<Packages />} />
              <Route path="/my-reports" element={<MyReports />} />
              <Route path="/my-reviews" element={<MyReviews />} />
              <Route path="/my-team" element={<Team />} />
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/package-builder" element={<PackageBuilder />} />
              <Route
                path="/package-builder/:pid"
                element={<PackageBuilder />}
              />
              <Route path="/settings" element={<Settings />} />
            </Routes>
          </main>
        </div>
      </div>
      <ScrollToTopButton show={showScrollToTop} />
    </div>
  );
};

export default Layout;
