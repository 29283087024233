// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader, .loader:before, .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
  }
  .loader {
    color: #594cda;
    font-size: 7px;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  
  @keyframes bblFadInOut {
    0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
    40% { box-shadow: 0 2.5em 0 0 }
  }
      
  .overlay-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  `, "",{"version":3,"sources":["webpack://./src/components/DotLoader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,gDAAgD;EAClD;EACA;IACE,cAAc;IACd,cAAc;IACd,oBAAoB;IACpB,wBAAwB;IACxB,uBAAuB;IACvB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;EACf;EACA;;IAEE,WAAW;IACX,kBAAkB;IAClB,MAAM;EACR;EACA;IACE,YAAY;IACZ,uBAAuB;EACzB;EACA;IACE,WAAW;EACb;;EAEA;IACE,gBAAgB,6BAA6B;IAC7C,MAAM,wBAAwB;EAChC;;EAEA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf","sourcesContent":[".loader, .loader:before, .loader:after {\r\n    border-radius: 50%;\r\n    width: 2.5em;\r\n    height: 2.5em;\r\n    animation-fill-mode: both;\r\n    animation: bblFadInOut 1.8s infinite ease-in-out;\r\n  }\r\n  .loader {\r\n    color: #594cda;\r\n    font-size: 7px;\r\n    text-indent: -9999em;\r\n    transform: translateZ(0);\r\n    animation-delay: -0.16s;\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    z-index: 1000;\r\n  }\r\n  .loader:before,\r\n  .loader:after {\r\n    content: '';\r\n    position: absolute;\r\n    top: 0;\r\n  }\r\n  .loader:before {\r\n    left: -3.5em;\r\n    animation-delay: -0.32s;\r\n  }\r\n  .loader:after {\r\n    left: 3.5em;\r\n  }\r\n  \r\n  @keyframes bblFadInOut {\r\n    0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }\r\n    40% { box-shadow: 0 2.5em 0 0 }\r\n  }\r\n      \r\n  .overlay-loader {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background: rgba(0, 0, 0, 0.7);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 1000; \r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
