import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./Tabs.css";
import Filter from "./Filter";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
const Tabs = ({
  tabs,
  filters,
  onTabChange,
  showCheckboxes,
  OnclickFilter,
  dataStatus,
  showFilter,
  className,
  domClick,
  selectedTab,
  nextActiveTab,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [agentDataStatus, setAgentDataStatus] = useState(false);
  const [agentsData, setAgentsData] = useState([]);
  const user = useSelector((state) => state.auth);
  const [authId, setAuthID] = useState(user.auth_id);
  useEffect(() => {
    fetchAgentData();
  }, []);

  function fetchAgentData() {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/agentReport.php`,
      headers: {},
      data: {
        auth_id: authId,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }

      setAgentsData(response.data.data.agentlist); // Corrected the property name
      setAgentDataStatus(true);
    });
  }

  const [activeTab, setActiveTab] = useState(
    tabs && tabs.length > 0 ? tabs[0].value : ""
  );
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    onTabChange(tabName);
  };

  const handleLogout = () => {
    // Dispatch the logout action to clear user data
    dispatch(logout());
    // Redirect to the login page
    navigate("/login");
  };

  return (
    <div className="tabs-container">
      {tabs && tabs.length > 0 && (
        <div className="tab-buttons">
          {tabs.map((tab) => (
            <button
              key={tab.value}
              className={`tab-button ${
                tab.value === selectedTab ||
                (activeTab === tab.value && nextActiveTab !== "1")
                  ? "active"
                  : ""
              } ${className}`}
              onClick={() => handleTabClick(tab.value)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      )}
      {agentDataStatus === true && showFilter && (
        <Filter
          filters={filters}
          OnclickFilter={OnclickFilter}
          agents={agentsData}
          showCheckboxes={showCheckboxes}
          domClick={domClick}
        />
      )}
    </div>
  );
};

export default Tabs;
