import React, { useState, useEffect } from "react";
import "../components/ImageSlider.css";
import logoImage from "../assets/hellotravel-logo.png";
import slideimageone from "../assets/signupone.jpg";
import slideimagetwo from "../assets/signupsix.jpg";
import slideimagethree from "../assets/signupseven.jpg";
const ImageSlider = () => {
  const images = [
    {
      image_name: slideimageone,
      message:
        "Discover Our All-Inclusive Leads and Travel Solutions to Supercharge Your Business Growth.",
    },
    {
      image_name: slideimagetwo,
      message:
        "Get phone verified enquiries at a cost less than that of a cup of coffee.",
    },
    {
      image_name: slideimagethree,
      message: "Delivering more relevance with quality enquiries",
    },
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    const intervalId = setInterval(nextImage, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const currentImage = images[currentImageIndex];

  return (
    <div className="image-slider">
      {/* <div className="slider-logo">
        <img src={logoImage} alt="logo" className="slider-company-logo" />
      </div> */}
      <div className="slider-images pr">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slider-image-container ${
              index === currentImageIndex ? "active " : ""
            }`}
          >
            <img
              src={image.image_name}
              alt={`img ${index}`}
              className={`slider-image ${
                index === currentImageIndex ? "slide-in-forward " : ""
              }`}
            />
            <p className="slide-signup-text">{image.message}</p>
          </div>
        ))}
      </div>
      <div className="slider-dots">
        {images.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentImageIndex ? "active" : ""}`}
            onClick={() => setCurrentImageIndex(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;