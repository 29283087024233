import axios from "axios";
import React, { useState, useEffect } from "react";
import InnerHeader from "../components/InnerHeader";
import Card from "../components/Card";
import ReviewCard from "../components/ReviewCard";
import Dropdown from "../components/Dropdown";
import AppBarButton from "../components/AppBarButton";
import { FaUserCircle } from "react-icons/fa";
import { CiViewTimeline } from "react-icons/ci";
import { IoLocationSharp } from "react-icons/io5";
import { MdVerified } from "react-icons/md";
import { AiFillCalendar, AiOutlineEdit } from "react-icons/ai";
import { BsStarFill } from "react-icons/bs";
import SearchInput from "./../components/SearchInput";
import "../pages/MyReview.css";
import SmallLoader from "../components/SmallLoader";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import Tooltip from "../components/Tooltip";
import { useTitle } from "../hooks/useTitle";
import trackEvent from "../utils/gaTracker";

const MyReviews = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth);
  useTitle("My Reviews -  Travel Agents CRM - Hellotravel");

  const [authId, setAuth] = useState(user.auth_id);
  const [dataStatus, setDataStatus] = useState(false);

  const [selectedRatings, setSelectedRatings] = useState("Ratings");
  const [selectedDestinations, setSelectedDestinations] =
    useState("Destinations");
  const [expandedComments, setExpandedComments] = useState({});

  const [selectedCommentIndex, setSelectedCommentIndex] = useState(-1);
  const ratingsOptions = [
    { label: "All", value: "" },
    { label: "5", value: "5" },
    { label: "4", value: "4" },
    { label: "3", value: "3" },
    { label: "2", value: "2" },
    { label: "1", value: "1" },
  ];

  const destinationsOptions = [
    { label: "Shimla", value: "Shimla" },
    { label: "Manali", value: "Manali" },
    { label: "Goa", value: "Goa" },
    { label: "Delhi", value: "Delhi" },
    { label: "Mumbai", value: "Mumbai" },
  ];

  const [agentOptions, setAgentOptions] = useState([]);

  const [commentsData, setCommentsData] = useState([]);
  const [parentName, setParentName] = useState("");
  const [parentUid, setParentUid] = useState("");
  const [reviewCount, setReviewCount] = useState(0);
  const [reviewPageLimit, setReviewPageLimit] = useState(1);
  const [reviewPage, setReviewPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [avgRating, setAvgRating] = useState(0);
  const [starRatingArr, setStarRatingArr] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [autoLoader, setAutoLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isCheckLoginType, setisCheckLoginType] = useState([]);

  const [param, setParam] = useState({
    agent: "",
    destinations: "",
    ratings: "",
  });

  useEffect(() => {
    fetchReviewData(1);
  }, []);

  const handleCommentExpand = (index) => {
    setExpandedComments({
      ...expandedComments,
      [index]: !expandedComments[index],
    });
  };

  //=============start Fetch data=================//
  function fetchReviewData(page, params) {
    setAutoLoader(true);

    axios({
      method: "post",
      url: `${user.base_url}/subuser/myreview.php`,
      headers: {},
      data: {
        auth_id: authId,
        page: page,
        param: params,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }
      setisCheckLoginType(response.data.login);
      if (page === 1) {
        setAgentOptions(response.data.data.agentlist);
        setCommentsData(response.data.data.reviewData);
        setParentName(response.data.data.otherdetail.parentname);
        setParentUid(response.data.data.otherdetail.puid);
        setReviewCount(response.data.data.otherdetail.reviewcount);
        setReviewPageLimit(response.data.data.otherdetail.offset_auto);
        setAvgRating(response.data.data.otherdetail.avgrating);
        setStarRatingArr(response.data.data.otherdetail.ratingarr);
        setReviewPage(response.data.data.otherdetail.page);
        setDataStatus(true);
        setIsFetching(false);
        setAutoLoader(false);
      } else {
        response.data.data.reviewData.map((r, i) => {
          setCommentsData((prevState) => {
            const updatedMembers = [...prevState, r];
            return updatedMembers;
          });
        });

        setDataStatus(true);
        setIsFetching(false);
        setAutoLoader(false);
      }
    });
  }

  const handleLogout = () => {
    // Dispatch the logout action to clear user data
    dispatch(logout());
    // Redirect to the login page
    navigate("/login");
  };

  //==============End Fetch data====================//

  //==============Start Update data====================//

  function reviewReply(replyData) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myreview.php?fun=replyreview`,
      headers: {},
      data: {
        inputs: replyData,
        auth_id: authId, // This is the body part
        name: parentName,
        uid: parentUid,
      },
    }).then(function (response) {});
  }

  //==============End Update data====================//

  //====================filters===========================//
  const handleRatingsChange = (ratings, value) => {
    trackEvent("My Review Page", "click", `Rating Filter #${ratings}`);
    const updatedParam = { ...param, ratings: value };
    setParam(updatedParam);
    setIsFilter(true);
    fetchReviewData(1, updatedParam);
  };

  const handleDestinationsChange = (destinations, value) => {
    const updatedParam = { ...param, destinations: value };
    setParam(updatedParam);

    fetchReviewData(1, updatedParam);
  };

  const handleAssignedtoChange = (agent, value) => {
    const updatedParam = { ...param, agent: value };
    setParam(updatedParam);

    fetchReviewData(1, updatedParam);
  };
  //====================End Filter========================//

  const handleSearch = (searchQuery) => {
    trackEvent("My Review Page", "click", `Destination Filter #${searchQuery}`);
    const updatedParam = { ...param, destinations: searchQuery };
    setParam(updatedParam);
    setIsFilter(true);
    fetchReviewData(1, updatedParam);
  };

  //==============Start handleScroll data====================//

  useEffect(() => {
    const scrollHandler = (event) => {
      const { scrollHeight, scrollTop, clientHeight } =
        document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - 70 && !isFetching) {
        setIsFetching(true);

        // Increment the counter using setReviewPage
        setReviewPage((prevCounter) => prevCounter + 1);

        // Fetch data with the updated reviewPage and param
        if (reviewPage < reviewPageLimit) {
          fetchReviewData(reviewPage + 1, param);
        }
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => window.removeEventListener("scroll", scrollHandler);
  }, [param, isFetching, reviewPage, reviewPageLimit]);

  //==============End handleScroll data====================//

  const handleReplyClick = (commentIndex) => {
    trackEvent("My Review Page", "click", `${commentIndex===-1 ?"Reply Close":"Reply"}`);
    setSelectedCommentIndex(commentIndex);
  };

  const handleReplyChange = (e, commentIndex) => {
    const newReplyText = e.target.value;
    const updatedComments = [...commentsData];
    updatedComments[commentIndex].newReply = newReplyText;
    setCommentsData(updatedComments);
  };

  const handleReplySubmit = (e, commentIndex) => {
    trackEvent("My Review Page", "click", "Reply Send");

    e.preventDefault();

    const updatedComments = [...commentsData];
    const newReplyText = updatedComments[commentIndex].newReply;

    reviewReply(updatedComments[commentIndex]);

    updatedComments[commentIndex].replies.push({
      id: updatedComments[commentIndex].replies.length + 1,
      userName: parentName, // Replace with the actual Agent Name dynamically
      commentDate: moment().format("DD MMM YYYY | h:mm:ss A"),
      reply_testimonial: newReplyText,
    });

    updatedComments[commentIndex].newReply = "";
    setCommentsData(updatedComments);
  };

  // Calculate average review rating and star ratings count
  const totalReviews = commentsData.length;
  const totalRatings = commentsData.reduce(
    (total, comment) => total + parseInt(comment.star_rating / 20),
    0
  );
  const averageRating =
    totalReviews > 0 ? (totalRatings / totalReviews).toFixed(1) : "0.0";

  const starRatingsCount = [
    { rating: 5, count: 0 },
    { rating: 4, count: 0 },
    { rating: 3, count: 0 },
    { rating: 2, count: 0 },
    { rating: 1, count: 0 },
  ];

  /*if(dataStatus === true){
    commentsData.forEach((comment) => {
      const rating = parseInt(comment.star_rating);
      const index = starRatingArr.findIndex((item) => item.rating === rating);
      if (index !== -1) {
        starRatingArr[index].count++;
      }
    });
  }
  */

  const handleClearFilter = () => {
    setSelectedRatings("All");
    const updatedParam = {
      agent: "",
      destinations: "",
      ratings: "",
    };
    setParam((prevdata) => updatedParam);
    setSearchValue("");
    setIsFilter(false);
    fetchReviewData(1, updatedParam);
  };

  return (
    <div className="main-content-padding">
      <InnerHeader
        heading="My Reviews"
        showButton={false}
        txtSubHeading="Enhance your reputation and boost customer satisfaction by effectively managing and showcasing feedback. "
      />

      {dataStatus === true && (
        <ReviewCard
          averageRating={avgRating}
          totalReviews={reviewCount}
          starRatingsCount={starRatingArr}
          dataStatus={dataStatus}
        />
      )}
      <Card className={"bg5"}>
        <div className="teams-filters df">
          <Dropdown
            label="Ratings"
            options={ratingsOptions}
            selectedValue={selectedRatings}
            onValueChange={handleRatingsChange}
          />
          {/* <Dropdown
            label="Destinations"
            options={destinationsOptions}
            selectedValue={selectedDestinations}
           
            onValueChange={handleDestinationsChange}
          /> */}

          <SearchInput
            onSearch={handleSearch}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />

          {isFilter && (
            <button className="go-button fs14" onClick={handleClearFilter}>
              Clear
            </button>
          )}

          {/* <Dropdown
            label="Assigned to"
            options={agentOptions}
            selectedValue={selectedAssignedto}
           
            onValueChange={handleAssignedtoChange}
          /> */}
        </div>
        <div className="pt16 pb16 pl8 pr8">
          {dataStatus === true &&
            commentsData.map((comment, index) => (
              <div
                className={`pr20 pb8 pl20 pt20 bg7 ${
                  index !== commentsData.length - 1 ? "mb16" : ""
                }`}
                key={comment.ID}
              >
                <div className="review-text-section df jcsb">
                  <div className="df fdc flx1">
                    <div className="comment-user v-center">
                      <div className="owener-image box-center ofh br50 w44 h44 ">
                        <FaUserCircle className="w44 h44 fc1" />
                      </div>
                      <div className="commenter-name-date pl16">
                        <div className="commenter-name fw5  lh26 fs14 df aic">
                          {comment.TBL_LEAD_NAME}

                          {comment.status_verify === "1" ? (
                            <Tooltip title="Verified">
                              <MdVerified className="fc2 fs28 ml8" />
                            </Tooltip>
                          ) : comment.status_verify === "2" ? (
                            <Tooltip title="Not Verified">
                              <MdVerified className="fc17 fs28 ml8" />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Pending">
                              <MdVerified className="fc10 fs28 ml8" />
                            </Tooltip>
                          )}
                        </div>
                        <div className="commenter-date fw4 fs14 lh22 tac fc5 mt4">
                          {comment.review_post
                            ? moment(comment.review_post).format(
                                "DD MMM YYYY  h:mm A"
                              )
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className=" mt16">
                      {Array.from({
                        length: Number(comment.star_rating / 20),
                      }).map((_, i) => (
                        <BsStarFill key={i} className="fc6 ml4 fs14" />
                      ))}
                    </div>

                    <div className=" mt8 ls1 lh22 fs14">
                      {comment.body.length > 220 ? (
                        <div>
                          {expandedComments[index] ? (
                            <div>
                              <div>{comment.body}</div>
                              <span
                                className="fc1 cp"
                                onClick={() => handleCommentExpand(index)}
                              >
                                Read less
                              </span>
                            </div>
                          ) : (
                            <div>
                              <div>{`${comment.body.slice(0, 220)}...`}</div>
                              <span
                                className="fc1 cp"
                                onClick={() => handleCommentExpand(index)}
                              >
                                Read more
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>{comment.body}</div>
                      )}
                    </div>

                    <div className="mt16 ls1 p8 reply-texting">
                      {comment.replies.map((reply) => (
                        <div
                          key={reply.id}
                          className="displayed-text-reply mb16 p16"
                        >
                          <div className="agent-info df jcsb">
                            <div className="agent-name fw5 lh26 fs14 df aic fw6">
                              {reply.userName}
                            </div>
                            <div className="date-time fw4 fs14 lh22 tac fc5">
                              {reply.reply_date
                                ? moment(reply.reply_date).format(
                                    "DD MMM YYYY  h:mm A"
                                  )
                                : ""}
                            </div>
                          </div>
                          <div className="message-reply lh22 mt8 fs14">
                            <div>{reply.reply_testimonial}</div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {selectedCommentIndex === index && (
                      <div>
                        <form
                          className="df review-form mt16"
                          onSubmit={(e) => handleReplySubmit(e, index)}
                        >
                          <input
                            type="text"
                            value={comment.newReply}
                            onChange={(e) => handleReplyChange(e, index)}
                            placeholder="Type your reply..."
                            className="p8 br4 flx1"
                            autoFocus
                          />
                          <div className="review-buttons df aic">
                            <button
                              type="submit"
                              className="bg1 fc3 p8 cp br16 pl16 pr16 pt8 pb8 ml8 fs14"
                            >
                              Send
                            </button>
                            <button
                              onClick={() => handleReplyClick(-1)}
                              className="bg7 fc4 ml16 cp"
                            >
                              Close
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                  <div className="card-lead-right add-enq-btn bt1 ml20 mr20 pb20 df jcsb aie">
                    <div className="duration fs14">
                      <div className="buy-lead-name df jcc fdc aifs">
                        {/* <p className="df aic fc5">
                        <CiViewTimeline className="mr4 fs18" />
                        <Link to="/my-reviews">
                        <span className="
                        fc1">View Shared Itinerary</span>
                        </Link>
                      </p> */}

                        {comment.TBL_LEAD_DESTINATION_VALUE && (
                          <Tooltip
                            title={
                              "Travel Destinations:" +
                              comment.TBL_LEAD_DESTINATION_VALUE.split(",")
                                .map((str) => str.trim())
                                .join(", ")
                            }
                          >
                            <p className="df aic fc5 mb16 location-review">
                              <IoLocationSharp className="mr4 fs18 " />

                              {comment.TBL_LEAD_DESTINATION_VALUE.length >
                                20 && (
                                <span className="fc5">
                                  {comment.TBL_LEAD_DESTINATION_VALUE.slice(
                                    0,
                                    20
                                  )}{" "}
                                  ...
                                </span>
                              )}
                              {comment.TBL_LEAD_DESTINATION_VALUE.length <
                                20 && (
                                <span className="fc5">
                                  {comment.TBL_LEAD_DESTINATION_VALUE.slice(
                                    0,
                                    20
                                  )}
                                </span>
                              )}
                            </p>
                          </Tooltip>
                        )}

                        {comment.TBL_LEAD_TRAVEL_DATE && (
                          <Tooltip title="Travel Date">
                            <p className="df aic fc5 mb16">
                              <AiFillCalendar className="mr4 fs18 " />
                              <span className="fc5">
                                {comment.TBL_LEAD_TRAVEL_DATE
                                  ? moment(comment.TBL_LEAD_TRAVEL_DATE).format(
                                      "DD MMM YYYY"
                                    )
                                  : ""}
                              </span>
                            </p>
                          </Tooltip>
                        )}
                        {comment.lead_id && (
                          <Tooltip title="Lead ID">
                            <p className="df aic fc5">
                              <AiOutlineEdit className="mr4 fs18 " />
                              <span className="fc5">{comment.lead_id}</span>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    <button
                      onClick={() => {if(isCheckLoginType.access_cid_by<=0){handleReplyClick(index)}}}
                      className={`${isCheckLoginType.access_cid_by>0?"disabled-input":"cp"} bg1 pl16 pr16 pt8 pb8 fs14 fc3 fw5 br16 mt24 ls1`}
                      disabled={isCheckLoginType.access_cid_by>0?true:false}
                    >
                      Reply
                    </button>
                  </div>
                </div>
              </div>
            ))}
          {commentsData.length === 0 && (
            <div className="box-center mt16 pt16 fw7 fs14">
              No Record Available
            </div>
          )}
        </div>
        {autoLoader && (
          <div className="box-center mb12">
            <SmallLoader className={"mb12"} />
          </div>
        )}
      </Card>
    </div>
  );
};

export default MyReviews;
