// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-details {
  display: flex;
}

.card-wrapper {
  display: flex;
  justify-content: space-between;
}

.card-info {
  flex: 1 1;
  padding: 20px;
}
.pimg img {
  border: 2px solid #ffffff80;
  border-radius: 50px;
  padding: 2px;
  width: 70px;
  height: 70px;
}

.lead-credits {
  align-self: flex-start;
}
.blink-text {
  animation: blink 2s linear infinite;
  color: red;
  font-size: 16px;
  font-weight: 600;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 480px) {
  .account-details {
    padding-left: 16px;
    padding-right: 16px;
  }
  .card-wrapper{
    flex-direction: column;
  }
  .card-info{
      margin-left: 0;
      margin-top: 0;
  }
  .card-info .fs18{
    font-size: 16px;
}
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .account-details {
    padding-left: 16px;
    padding-right: 16px;
  }
  .card-info .fs18{
    font-size: 16px;
}
}
`, "",{"version":3,"sources":["webpack://./src/pages/MyAccount.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,SAAO;EACP,aAAa;AACf;AACA;EACE,2BAA2B;EAC3B,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,mCAAmC;EACnC,UAAU;EACV,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,YAAY;EACd;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,sBAAsB;EACxB;EACA;MACI,cAAc;MACd,aAAa;EACjB;EACA;IACE,eAAe;AACnB;AACA;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,eAAe;AACnB;AACA","sourcesContent":[".account-details {\r\n  display: flex;\r\n}\r\n\r\n.card-wrapper {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.card-info {\r\n  flex: 1;\r\n  padding: 20px;\r\n}\r\n.pimg img {\r\n  border: 2px solid #ffffff80;\r\n  border-radius: 50px;\r\n  padding: 2px;\r\n  width: 70px;\r\n  height: 70px;\r\n}\r\n\r\n.lead-credits {\r\n  align-self: flex-start;\r\n}\r\n.blink-text {\r\n  animation: blink 2s linear infinite;\r\n  color: red;\r\n  font-size: 16px;\r\n  font-weight: 600;\r\n}\r\n\r\n@keyframes blink {\r\n  0% {\r\n    opacity: 1;\r\n  }\r\n  50% {\r\n    opacity: 0.4;\r\n  }\r\n  100% {\r\n    opacity: 1;\r\n  }\r\n}\r\n\r\n@media only screen and (max-width: 480px) {\r\n  .account-details {\r\n    padding-left: 16px;\r\n    padding-right: 16px;\r\n  }\r\n  .card-wrapper{\r\n    flex-direction: column;\r\n  }\r\n  .card-info{\r\n      margin-left: 0;\r\n      margin-top: 0;\r\n  }\r\n  .card-info .fs18{\r\n    font-size: 16px;\r\n}\r\n}\r\n\r\n@media only screen and (min-width: 481px) and (max-width: 768px) {\r\n  .account-details {\r\n    padding-left: 16px;\r\n    padding-right: 16px;\r\n  }\r\n  .card-info .fs18{\r\n    font-size: 16px;\r\n}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
