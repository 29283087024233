// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this to your CSS */
.toggle {
    width: 36px;
    height: 16px;
    background-color: #ccc;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.2s ease-in-out;
  }
  
  .toggle-circle {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translate(3px,-50%);
    transition: transform 0.2s ease-in-out;
  }
  
  .toggle.active {
    background-color: #5cb85c;
  }
  
  .toggle.inactive {
    background-color: #d9534f;
  }
  
  .toggle.active .toggle-circle {
    transform: translate(20px, -6px);
  }
  
  `, "",{"version":3,"sources":["webpack://./src/components/Toggle.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,6CAA6C;EAC/C;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ;IACR,8BAA8B;IAC9B,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gCAAgC;EAClC","sourcesContent":["/* Add this to your CSS */\r\n.toggle {\r\n    width: 36px;\r\n    height: 16px;\r\n    background-color: #ccc;\r\n    border-radius: 15px;\r\n    cursor: pointer;\r\n    position: relative;\r\n    transition: background-color 0.2s ease-in-out;\r\n  }\r\n  \r\n  .toggle-circle {\r\n    width: 12px;\r\n    height: 12px;\r\n    background-color: #fff;\r\n    border-radius: 50%;\r\n    position: absolute;\r\n    top: 50%;\r\n    transform: translate(3px,-50%);\r\n    transition: transform 0.2s ease-in-out;\r\n  }\r\n  \r\n  .toggle.active {\r\n    background-color: #5cb85c;\r\n  }\r\n  \r\n  .toggle.inactive {\r\n    background-color: #d9534f;\r\n  }\r\n  \r\n  .toggle.active .toggle-circle {\r\n    transform: translate(20px, -6px);\r\n  }\r\n  \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
