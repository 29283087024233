import React, { useState, useEffect } from "react";
import Card from "../components/Card";
import "../pages/MyPackages.css";
import "../pages/MyPackages.css";
import { FaPencilAlt, FaDownload, FaCarSide, FaHotel } from "react-icons/fa";
import { FaPersonSkiing } from "react-icons/fa6";
import axios from "axios";
import {
  MdPreview,
  MdKeyboardArrowDown,
  MdArrowUpward,
  MdAddCard,
  MdWorkspacePremium,
} from "react-icons/md";
import { BsShareFill, BsTrash } from "react-icons/bs";
import { MdOutlineFlight } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import FilterPackage from "../components/FilterPackage.js";
import defaultImage from "../assets/image-not-uploaded.jpg";
import Toggle from "../components/Toggle";
import Popup from "../components/Popup/Popup";
import InnerHeader from "../components/InnerHeader";
import SharePackageForm from "../components/Forms/SharePackageForm";
import SucessMessage from "../components/SucessMessage";
import { BsStarFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../store/authSlice.js";
import SmallLoader from "../components/SmallLoader.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTitle } from "../hooks/useTitle";
import trackEvent from "../utils/gaTracker";
import Dropdown from "../components/TeamDropdown.js";
import { handleImageError } from "../utils/imageUtils.js";
import Banner from "../components/Banner.js";

const Packages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useTitle("My Itinerary -  Travel Agents CRM - Hellotravel");

  const user = useSelector((state) => state.auth);

  const [authId, setAuthID] = useState(user.auth_id);
  const filters = [
    { label: "ID", value: "id" },
    { label: "Status", value: "status" },
    //{ label: "Destination", value: "destination" },
    { label: "Travel Duration", value: "travelDuration" },
    { label: "Type", value: "type" },
    { label: "Visibility", value: "packageVisibility" },
    { label: "Last Update", value: "lastupdated" },
  ];

  const agents = [
    { label: "Agent 1", value: "agent1" },
    { label: "Agent 2", value: "agent2" },
    { label: "Agent 3", value: "agent3" },
  ];

  const [packagesData, setPackagesData] = useState([]);
  const [packagesDataCount, setPackagesDataCount] = useState(0);
  const [toggleValues, setToggleValues] = useState("");
  const [dataStatus, setDataStatus] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [showDeleteSuccessMessage, setShowDeleteSuccessMessage] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [pushedToTopId, setPushedToTopId] = useState(null);
  const [showPackageDropdowns, setShowPackageDropdowns] = useState("");
  const [selectedPackageOptions, setSelectedPackageOptions] = useState("");
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [startRow, setStartRow] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [premiumPackage, setpremiumPackage] = useState(0);
  const [destinationPkg, setdestinationPkg] = useState(0);
  const [highlightedPkg, sethighlightedPkg] = useState(0);
  const [pushedToTopIdStatus, setpushedToTopIdStatus] = useState(false);
  const [isCheckLoginType, setisCheckLoginType] = useState([]);
  const [isPremium, setIsPremium] = useState(false);

  const handlePremiumClick = () => {
    setIsPremium(!isPremium);
  };

  const [param, setParam] = useState({
    start_row: startRow,
    filtervalue: "",
    filterlable: "",
  });

  const [autoLoader, setAutoLoader] = useState(true);

  const handlePushToTopClick = (packageId) => {
    // Toggle the state for the specific package
    trackEvent("My Itineraries Page", "click", `Push To Top`);
    if(packageId.version==='1'){
      if (highlightedPkg === 1) {
        if (pushedToTopId === packageId.nid) {
          setPushedToTopId(null);
        } else {
          setPushedToTopId(packageId.nid);
        }
        setIsButtonClicked(true);
        setpushedToTopIdStatus(false);
        pushtotop(packageId.nid, "1");
        // Add the "tick-animation" class
        setTimeout(() => {
          setIsButtonClicked(false);
        }, 1000);
      } else {
        toast.warn("Upgrade your plan");
      }
    }
    else{
      toast.warn("Please comply with our latest posting form before proceeding with this action");
    }
  };

  // Callback function to handle Toggle value change
  const handleToggleChange = (newValue, index) => {
    packageActiveInActive(newValue, index);
    const newToggleValues = [...toggleValues];
    newToggleValues[index] = newValue;
    setToggleValues(newToggleValues);
  };

  const handleCloseStatusPopup = () => {
    setIsShareOpen(false);
  };
  const handleStatusButtonClick = (packageItem) => {
    if(packageItem.version==='1'){
    trackEvent("My Itineraries Page", "click", `Itinerary Share`);
    setIsShareOpen(true);
    setSelectedPackage(packageItem);
    }
    else{
      toast.warn("Please comply with our latest posting form before proceeding with this action"); 
    }
  };
  const handleButtonClickArchive = (packageId, index) => {
    deletePackage(packageId, index);
  };
  const handleButtonClick = (packageId, action) => {
    if (action === "Edit") {
      navigate("/package-builder/" + packageId);
      trackEvent("My Itineraries Page", "click", `Itinerary Edit`);
    } else if (action === "Copy") {
      trackEvent("My Itineraries Page", "click", `Itinerary Copy`);
    } else if (action === "Archive") {
      trackEvent("My Itineraries Page", "click", `Itinerary Delete`);
      //setSuccessMessage("Deleted Successfully");
      deletePackage(packageId);
      //setShowDeleteSuccessMessage(true);
    } else if (action === "Preview") {
      if(packageId.version==='1'){
        trackEvent("My Itineraries Page", "click", `Itinerary Preview`);

        window.open(
          `https://www.hellotravel.com/hellotravel2.0/package_itinerary_detail.php?nid=${packageId.nid}&fcpuid=${packageId.fcp_uid}`
        );
      }
      else{
        toast.warn("Please comply with our latest posting form before proceeding with this action");
      }
    } else if (action === "Download") {
      if(packageId.version==='1'){
      trackEvent("My Itineraries Page", "click", `Itinerary Download`);

      window.open(
        `https://www.hellotravel.com/hellotravel2.0/package_itinerary_detail.php?nid=${packageId.nid}&fcpuid=${packageId.fcp_uid}&download=1`
      );
      }
      else{
        toast.warn("Please comply with our latest posting form before proceeding with this action");
      }
    }
  };

  const onClickFilterGo = (filterlab, filterval) => {
    trackEvent("My Itineraries Page", "click", filterlab.label);

    if (filterlab.value === "lastupdated" && filterval.value !== "") {
      const updatedParam = {
        ...param,
        filtervalue: filterval,
        filterlable: filterlab.value,
        start_row: 0,
      };
      setParam(updatedParam);
      setPackagesData([]);
      setPackagesDataCount(0);
      setStartRow(0);
      getPackagelist(updatedParam);
    } else if (
      filterlab.value !== "" &&
      filterval !== "" &&
      filterlab.value !== "lastupdated"
    ) {
      const updatedParam = {
        ...param,
        filtervalue: filterval,
        filterlable: filterlab.value,
        start_row: 0,
      };
      setParam(updatedParam);
      setPackagesData([]);
      setPackagesDataCount(0);
      setStartRow(0);
      getPackagelist(updatedParam);
    } else if (filterval === "" && param.filtervalue !== "") {
      const updatedParam = {
        ...param,
        filtervalue: "",
        filterlable: "",
        start_row: 0,
      };
      setParam(updatedParam);
      setPackagesData([]);
      setPackagesDataCount(0);
      setStartRow(0);
      getPackagelist(updatedParam);
    }
  };
  // Modify the handlePackageSelect function to update the selected option for the selected package
  const handlePackageSelect = (packagedata,sponserdst, index) => {
     if(packagedata.version==='1'){
      if (premiumPackage === 1) {
        if(sponserdst==="1"){
          premiumPackagecall(packagedata.nid, index, "false");
        }
        else{
          premiumPackagecall(packagedata.nid, index, "true");
        }
        //destinationPackagecall(packagenid, selectedPackage, index, "false");
        //const newSelectedPackageOptions = [...selectedPackageOptions];
        //newSelectedPackageOptions[index] = selectedPackage;
        //setSelectedPackageOptions(newSelectedPackageOptions);
      } else {
        toast.warn("Upgrade your plan");
      }
    }else{
      toast.warn("Please comply with our latest posting form before proceeding with this action");
    }
    /*else if (selectedPackage === "Destination Leader") {
      if (destinationPkg === 1) {
        destinationPackagecall(packagenid, selectedPackage, index, "true");
        premiumPackagecall(packagenid, selectedPackage, index, "false");
        const newSelectedPackageOptions = [...selectedPackageOptions];
        newSelectedPackageOptions[index] = selectedPackage;
        setSelectedPackageOptions(newSelectedPackageOptions);
      } else {
        toast.warn("Upgrade your plan");
      }
    } else {
      premiumPackagecall(packagenid, selectedPackage, index, "false");
      destinationPackagecall(packagenid, selectedPackage, index, "false");
      const newSelectedPackageOptions = [...selectedPackageOptions];
      newSelectedPackageOptions[index] = selectedPackage;
      setSelectedPackageOptions(newSelectedPackageOptions);
    }*/

    // Create a copy of the array and update the selected option for the selected package

    setShowPackageDropdowns(false);
  };
  const packageOptions = [
    "Normal Package",
    "Premium Package",
    "Destination Leader",
  ];

  const handleLogout = () => {
    // Dispatch the logout action to clear user data
    dispatch(logout());
    // Redirect to the login page
    navigate("/login");
  };
  useEffect(() => {
    getPackagelist(param);
  }, []);

  useEffect(() => {
    const scrollHandler = () => {
      const { scrollHeight, scrollTop, clientHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 70 && !isFetching) {
        trackEvent("My Itineraries Page", "click", "Load More");
        setIsFetching(true);
        const updatedParam = { ...param, start_row: startRow };
        setParam(updatedParam);
        if (startRow < packagesDataCount) {
          getPackagelist(updatedParam);
        }
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => window.removeEventListener("scroll", scrollHandler);
  }, [isFetching, param, startRow, packagesDataCount]);

  function deletePackage(packagenid, removeindex) {
    trackEvent("My Itineraries Page", "click", `Itinerary Delete`);

    axios({
      method: "post",
      url: `${user.base_url}/subuser/getTitleSuggestion.php?fun=deletepackage`,
      headers: {},
      data: {
        auth_id: authId,
        pkgnid: packagenid,
        status: true,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }
      if (response.data.data.status === 1) {
        const newArray = packagesData.filter(
          (_, index) => index !== removeindex
        );
        setPackagesData(newArray);
      }

      toast.info(response.data.data.msg);
    });
  }
  function packageActiveInActive(status, index) {
    var nid = packagesData[index].nid;
    axios({
      method: "post",
      url: `${user.base_url}/subuser/getTitleSuggestion.php?fun=activeinactive`,
      headers: {},
      data: {
        auth_id: authId,
        pkgnid: nid,
        status: !status,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }
      if (response.data.data.status === 1) {
        const updateddata = { ...packagesData[index], status: 1 };
        packagesData[index] = updateddata;
        setPackagesData(packagesData);
      } else if (response.data.data.status === 4) {
        const updateddata = { ...packagesData[index], status: 4 };
        packagesData[index] = updateddata;
        setPackagesData(packagesData);
      }

      toast.info(response.data.data.msg);
    });
  }
  function destinationPackagecall(pkgnid, selectedPackage, index, status) {
    var destinationval = packagesData[index].dest_value;
    axios({
      method: "post",
      url: `${user.base_url}/subuser/getTitleSuggestion.php?fun=destination`,
      headers: {},
      data: {
        auth_id: authId,
        pkgnid: pkgnid,
        status: status,
        dest_val: destinationval,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }
      if (response.data.data.status === 1) {
        const updateddata = { ...packagesData[index], sponsored_status: "1" };
        packagesData[index] = updateddata;
        setPackagesData(packagesData);
      }
      if (status == "true") {
        toast.info(response.data.data.msg);
      }
    });
  }
  function premiumPackagecall(pkgnid, index, status) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/getTitleSuggestion.php?fun=make_premium_package`,
      headers: {},
      data: {
        auth_id: authId,
        pkgnid: pkgnid,
        status: status,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }
      if (response.data.data.status === 1) {
          const updateddata = { ...packagesData[index], sponsored_status: "1" };
          packagesData[index] = updateddata;
          setPackagesData([...packagesData]);
      }
      else if(response.data.data.status === 0){
          const updateddata = { ...packagesData[index], sponsored_status: "0" };
          packagesData[index] = updateddata;
          setPackagesData([...packagesData]);
      }
        toast.info(response.data.data.msg);
    });
  }
  function pushtotop(pkgnid, status) {
    setpushedToTopIdStatus(false);
    axios({
      method: "post",
      url: `${user.base_url}/subuser/getTitleSuggestion.php?fun=pushtotop`,
      headers: {},
      data: {
        auth_id: authId,
        pkgnid: pkgnid,
        status: status,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }
      if (response.data.data.status === 1) {
        setpushedToTopIdStatus(true);
      } else {
        setpushedToTopIdStatus(false);
      }
      toast.info(response.data.data.msg);
    });
  }
  function getPackagelist(paramdata) {
    setAutoLoader(true);
    axios({
      method: "post",
      url: `${user.base_url}/subuser/getTitleSuggestion.php?fun=getPackagesLisitng`,
      headers: {},
      data: {
        auth_id: authId,
        paramdata: paramdata,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }
      setisCheckLoginType(response.data.login);

      /*
        const updatedBuyLeadsData = [
          ...BuyLeadsData,
          ...response.data.data.buylead_data,
        ];
        setBuyLeadsData(updatedBuyLeadsData);
        setBuyLeadsDataCount(response.data.data.buylead_count);
        let counterBuylead=updatedBuyLeadsData.length;
        setStartRow(counterBuylead);

     */

      /* if (paramdata.start_row === 0) {
        setPackagesData([...response.data.data.itinerary]);
      } else {
        setPackagesData([...packagesData, ...response.data.data.itinerary]);
      }*/

      if (paramdata.start_row === 0) {
        const updatedBuyLeadsData = [...response.data.data.itinerary];

        setPackagesData(updatedBuyLeadsData);
        let counterBuylead = updatedBuyLeadsData.length;

        setStartRow(counterBuylead);
      } else {
        const updatedBuyLeadsData = [
          ...packagesData,
          ...response.data.data.itinerary,
        ];

        setPackagesData(updatedBuyLeadsData);
        let counterBuylead = updatedBuyLeadsData.length;

        setStartRow(counterBuylead);
      }
      setPackagesDataCount(response.data.data.packageCount);
      setpremiumPackage(response.data.data.premium_pkg);
      setdestinationPkg(response.data.data.destination_pkg);
      sethighlightedPkg(response.data.data.highlighted_pkg);

      setIsFetching(false);
      setAutoLoader(false);
      setDataStatus(true);
    });
  }
  const domOptions = [
    { ID: "day", ROLE_NAME: "ID" },
    { ID: "month", ROLE_NAME: "Last Update" },
  ];
  const [selectedDomStatus, setSelectedDomStatus] = useState("Sort By");
  const handleDomChange = (status, id) => {
    setSelectedDomStatus(status);
  };
  return (
    <>
      {showDeleteSuccessMessage && (
        <SucessMessage
          successType={""}
          message={successMessage}
          onClose={() => setShowDeleteSuccessMessage(false)}
        />
      )}
      {isShareOpen && (
        <Popup
          onClose={handleCloseStatusPopup}
          title={"Share Your Package Details"}
        >
          <SharePackageForm
            onClose={handleCloseStatusPopup}
            selectedPackage={selectedPackage}
          />
        </Popup>
      )}
      <div className="main-content-padding">
      <Banner
      condition={"itilist"}
      />
        <InnerHeader
          heading="My Itineraries"
          showButton={true}
          iconText={"Add Itinerary"}
          icon={<MdAddCard />}
        />

        <Card className="bg5">
          <div className="v-center jcsb">
            <FilterPackage
              filters={filters}
              agents={agents}
              onClickFilterGo={onClickFilterGo}
              setPackagesData={setPackagesData}
              param={param}
            />
            {/* <Dropdown
          label="Status"
          options={domOptions}
          selectedValue={selectedDomStatus}
          onValueChange={handleDomChange}
        /> */}
          </div>
          <div className="p16">
            {dataStatus &&
              packagesData.map((packageItem, index) => (
                <div
                  className={`${
                    packageItem.sponsored_status === "1" ? "brd3" : "brd1"
                  } bg16 br8 pb24 package-listing`}
                  key={packageItem.id}
                >
                  <div className="df jcsb pr24 package-top-lead ">
                    <div className="total-leads pl16 pr16 pt8 fs14 pb8 fc3">
                      {packageItem.leadsCount > 1
                        ? packageItem.leadsCount + " Leads"
                        : packageItem.leadsCount + " Lead"}
                    </div>
                    <div className="itinenary-status-info">
                      <ul className="df pt12">
                        <li className="df">
                          <p className="fc14 fs16 ls1">ID :</p>&nbsp;
                          <span className="fc5">{packageItem.nid}</span>
                        </li>
                        <li className="ml8">|</li>
                        <li className="ml8 df">
                          <p className="fc14 fs16 ls1">Status :</p>&nbsp;
                          <span className={packageItem.status_text_class}>
                            {toggleValues[index] !== undefined
                              ? toggleValues[index]
                                ? "Active"
                                : "Inactive"
                              : packageItem.status_text}
                          </span>
                        </li>
                        <li className="ml8">|</li>
                        <li className="ml8 df">
                          <p className="fc14 fs16 ls1">Visibility :</p>&nbsp;
                          <span className="fw6">{packageItem.visibility}</span>
                        </li>
                        <li className="ml8">|</li>
                        {(packageItem.status === "1" ||
                          packageItem.status === "4" ||
                          packageItem.status === 1 ||
                          packageItem.status === 4) && (
                          <li className="ml8 df">
                            <Toggle
                              initialValue={packageItem.isActive}
                              onChange={(newValue) =>
                                handleToggleChange(newValue, index)
                              }
                            />
                            <span
                              className={`ml8 fs16 ls1 ${
                                packageItem.isActive
                                  ? "active-text"
                                  : "inactive-text"
                              }`}
                            >
                              {(
                                toggleValues[index] !== undefined
                                  ? toggleValues[index]
                                  : packageItem.isActive
                              )
                                ? "Active"
                                : "Inactive"}
                            </span>
                          </li>
                        )}
                        {(packageItem.status === "1" ||
                          packageItem.status === "4") && (
                          <li className="ml8">|</li>
                        )}
                        <li className="ml8 df">
                          <p className="fc14 fs16 ls1">Last Update :</p> &nbsp;
                          <span className="fc5">{packageItem.lastUpdate}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="inner-box">
                    <div className="left-image">
                      <div className="image-contain dib pr">
                        <img
                          onError={(event) =>
                            handleImageError(event, defaultImage)
                          }
                          src={
                            packageItem.pkgimg !== ""
                              ? packageItem.pkgimg
                              : defaultImage
                          }
                          alt="packages"
                        />
                        {packageItem.hotel_rating > 0 && (
                          <div className="rating df aisc">
                            <p className="fs14 fc3">
                              {packageItem.hotel_rating}
                            </p>{" "}
                            <BsStarFill className="fs12 ml4" />
                          </div>
                        )}
                        <div className="duration">{packageItem.daynight}</div>
                      </div>
                    </div>
                    <div className="right-column pl24">
                      <div className="df w100 pr24 fdc">
                        <div className="df flx1 fdc  br8">
                          <div className="df jcsb">
                            <div className="df package-details-grp w100">
                              <div className="package-information flx75">
                                <div className="fs16 ls1  lh22">
                                  {toggleValues[index] !== undefined ? (
                                    toggleValues[index] &&
                                    packageItem.visibility === "Public" ? (
                                      <a
                                        href={packageItem.shareLink}
                                        className="fc14"
                                        target="_blank"
                                      >
                                        {packageItem.title}
                                      </a>
                                    ) : (
                                      packageItem.title
                                    )
                                  ) : packageItem.visibility === "Public" &&
                                    packageItem.status === "1" ? (
                                    <a
                                      className="fc14"
                                      href={packageItem.shareLink}
                                      target="_blank"
                                    >
                                      {packageItem.title}
                                    </a>
                                  ) : (
                                    packageItem.title
                                  )}
                                </div>
                                <div className="df mt8 aic">
                                  <p className="fs14 fc5 mr4 ls1">From</p>
                                  <p className="fw6 mr8 fs14 ls1">
                                    {packageItem.displayprice}
                                  </p>
                                  <p className="fc5 fs14 df aic ls1">
                                    ({packageItem.isCustomizable})
                                  </p>
                                </div>
                                <div className="package-day-night df mt16 aic">
                                  <p className="fs14 mr4 lh18">
                                    {Object.entries(packageItem.cityparday).map(
                                      ([city, count], index) => (
                                        <span key={index}>
                                          {count}D{" "}
                                          <span className="fc5">{city}</span>
                                          {index <
                                            Object.keys(packageItem.cityparday)
                                              .length -
                                              1 && " | "}
                                        </span>
                                      )
                                    )}
                                    {packageItem.cityparday.length === 0 && (
                                      <span className="fc5">
                                        {packageItem.destination}
                                      </span>
                                    )}
                                  </p>
                                </div>

                                <div className="inclusions-container df fs14 mt16">
                                  <div className="df aic fdc">
                                    <p className="inclusion-icon fs20">
                                      <MdOutlineFlight
                                        className={
                                          packageItem.FlightAvailable === 0
                                            ? "fc5"
                                            : ""
                                        }
                                      />
                                    </p>
                                    <p
                                      className={
                                        packageItem.FlightAvailable === 0
                                          ? "inclusion-text  mt8 ls1 fc5"
                                          : "inclusion-text  mt8 ls1"
                                      }
                                    >
                                      {packageItem.FlightAvailable > 0
                                        ? packageItem.FlightAvailable
                                        : ""}{" "}
                                      {packageItem.FlightAvailable === 1
                                        ? "FLIGHT"
                                        : "FLIGHTS"}
                                    </p>
                                  </div>
                                  <div className="df aic fdc">
                                    <p className="inclusion-icon fs20">
                                      <FaPersonSkiing
                                        className={
                                          packageItem.activity_count === 0
                                            ? "fc5"
                                            : ""
                                        }
                                      />
                                    </p>
                                    <p
                                      className={
                                        packageItem.activity_count === 0
                                          ? "inclusion-text  mt8 ls1 fc5"
                                          : "inclusion-text  mt8 ls1"
                                      }
                                    >
                                      {packageItem.activity_count > 0
                                        ? packageItem.activity_count
                                        : ""}{" "}
                                      {packageItem.activity_count === 1
                                        ? "ACTIVITIES"
                                        : "ACTIVITIES"}
                                    </p>
                                  </div>
                                  <div className="df aic fdc">
                                    <p className="inclusion-icon fs20">
                                      <FaHotel
                                        className={
                                          packageItem.hotel_count === 0
                                            ? "fc5"
                                            : ""
                                        }
                                      />
                                    </p>
                                    <p
                                      className={
                                        packageItem.hotel_count === 0
                                          ? "inclusion-text  mt8 ls1 fc5"
                                          : "inclusion-text  mt8 ls1"
                                      }
                                    >
                                      {packageItem.hotel_count > 0
                                        ? packageItem.hotel_count
                                        : ""}{" "}
                                      {packageItem.hotel_count === 1
                                        ? "HOTEL"
                                        : "HOTELS"}
                                    </p>
                                  </div>
                                  <div className="df aic fdc">
                                    <p className="inclusion-icon fs20">
                                      <FaCarSide
                                        className={
                                          packageItem.transfer_count === 0
                                            ? "fc5"
                                            : ""
                                        }
                                      />
                                    </p>
                                    <p
                                      className={
                                        packageItem.transfer_count === 0
                                          ? "inclusion-text  mt8 ls1 fc5"
                                          : "inclusion-text  mt8 ls1"
                                      }
                                    >
                                      {packageItem.transfer_count > 0
                                        ? packageItem.transfer_count
                                        : ""}{" "}
                                      {packageItem.transfer_count === 1
                                        ? "TRANSFER"
                                        : "TRANSFERS"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="push-top flx1">
                                {((toggleValues[index] !== undefined &&
                                  toggleValues[index] &&
                                  packageItem.visibility === "Public") ||
                                  (toggleValues[index] === undefined &&
                                    packageItem.isActive &&
                                    packageItem.visibility === "Public")) && (
                                  <div
                                    className={`push-top-button brd2 df jcsb p8 fs14 bg5 fc3 born cp br4 mt8 fc14 v-center ${
                                      isButtonClicked &&
                                      pushedToTopId === packageItem.id &&
                                      pushedToTopIdStatus
                                        ? "tick-animation"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handlePushToTopClick(packageItem)
                                    }
                                  >
                                    <p
                                      className={`fs14 ${
                                        pushedToTopId === packageItem.nid &&
                                        pushedToTopIdStatus &&
                                        "fc2"
                                      }`}
                                    >
                                      {pushedToTopId === packageItem.nid &&
                                      pushedToTopIdStatus
                                        ? "Pushed"
                                        : "Push To Top"}
                                    </p>
                                    {!isButtonClicked &&
                                    pushedToTopId === packageItem.nid &&
                                    pushedToTopIdStatus ? (
                                      <TiTick className="ml8 fs18 fc2" />
                                    ) : (
                                      <MdArrowUpward className="ml8 fs18" />
                                    )}
                                  </div>
                                )}
                                {((toggleValues[index] !== undefined &&
                                  toggleValues[index] &&
                                  packageItem.visibility === "Public") ||
                                  (toggleValues[index] === undefined &&
                                    packageItem.isActive &&
                                    packageItem.visibility === "Public")) && (<div
                                  className={`push-top-button df jcsb p8 fs14 bg5 br4 fc3 born cp mt8 fc14 v-center ${
                                    packageItem.sponsored_status === "1" ? "brd3" : "brd2"
                                  }`}
                                  onClick={() =>handlePackageSelect(
                                    packageItem,
                                    packageItem.sponsored_status,
                                    index
                                  )}
                                >
                                  <p className={`fs14 ${
                                      packageItem.sponsored_status === "1" ? "fc10" : ""
                                    }`}>
                                    {packageItem.sponsored_status === "1"
                                      ? "Premium Package"
                                      : "Mark as Premium"}
                                  </p>
                                  <MdWorkspacePremium
                                    className={`ml8 fs18 ${
                                      packageItem.sponsored_status === "1" ? "fc10" : ""
                                    }`}
                                  />
                                </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="package-buttons df mt16">
                          <button
                            className="p8 bg5 fc3 born cp fs12 br4 mt8 fc14 df aic mr12"
                            onClick={() =>
                              handleButtonClick(packageItem.id, "Edit")
                            }
                          >
                            <span className="fs14">Edit</span>
                            <FaPencilAlt className="ml8 fs12" />
                          </button>
                          {/* <button
                          className="p8 bg5 fc3 born cp br4 mt8 ml12 fc14 v-center"
                          onClick={() =>
                            handleButtonClick(packageItem.id, "Copy")
                          }
                        >
                          <span className="fs14">Copy</span>
                          <FaRegCopy className="ml8 fs12" />
                        </button> */}
                          {((toggleValues[index] !== undefined &&
                            toggleValues[index]) ||
                            (toggleValues[index] === undefined &&
                              packageItem.isActive)) && (
                            <button
                              className="p8 bg5 fc3 born cp br4 mt8 mr12 fc14 v-center"
                              onClick={() =>
                                handleButtonClick(packageItem, "Preview")
                              }
                            >
                              <span className="fs14">Preview</span>
                              <MdPreview className="ml8 fs16" />
                            </button>
                          )}
                          {((toggleValues[index] !== undefined &&
                            toggleValues[index]) ||
                            (toggleValues[index] === undefined &&
                              packageItem.isActive)) && (
                            <button
                              className="p8 bg5 fc3 born cp br4 mt8 mr12 fc14 v-center"
                              onClick={() =>
                                handleStatusButtonClick(packageItem)
                              }
                            >
                              <span className="fs14">Share</span>
                              <BsShareFill className="ml8 fs12" />
                            </button>
                          )}
                          {((toggleValues[index] !== undefined &&
                            toggleValues[index]) ||
                            (toggleValues[index] === undefined &&
                              packageItem.isActive)) && (
                            <button
                              className="p8 bg5 fc3 born cp br4 mt8 mr12 fc14 v-center"
                              onClick={() =>
                                handleButtonClick(packageItem, "Download")
                              }
                            >
                              <span className="fs14">Download</span>
                              <FaDownload className="ml8 fs12" />
                            </button>
                          )}
                          <button
                            className={`${
                              isCheckLoginType.access_cid_by > 0
                                ? "disabled-input"
                                : "cp"
                            } p8 bg5 fc3 born  br4 mt8 fc14 v-center`}
                            onClick={() =>
                              handleButtonClickArchive(packageItem.nid, index)
                            }
                            disabled={
                              isCheckLoginType.access_cid_by > 0 ? true : false
                            }
                          >
                            <span className="fs14">Archive</span>
                            <BsTrash className="ml8 fs14" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {autoLoader && (
              <div className="box-center mb12">
                <SmallLoader className={"mb12"} />
              </div>
            )}
            {packagesDataCount === "0" && (
              <div className="box-center mt16 pt16 fw7 fs14">
                No Record Found...
              </div>
            )}
          </div>
        </Card>
        <ToastContainer position="bottom-right" />
      </div>
    </>
  );
};

export default Packages;
