import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Userimage from "../assets/user.png";
import Card from "../components/Card";
import "../pages/LeadDetail.css";
import { BsWhatsapp } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import Timeline from "../components/Timeline";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PackageInformation from "./../components/PackageInformation";
import { BiArrowBack } from "react-icons/bi";
import SearchInput from "./../components/SearchInput";
import { FaEdit, FaInfoCircle, FaQuoteLeft, FaThumbsUp } from "react-icons/fa";
import { HiSave } from "react-icons/hi";
import Popup from "../components/Popup/Popup";
import StatusUpdateForm from "../components/Forms/StatusUpdateForm";
import SucessMessage from "../components/SucessMessage";
import { useSelector } from "react-redux";
import countrynamelist from "../data/countryNameList";
import momenttz from "moment-timezone";
import { useNavigate, useResolvedPath } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTitle } from "../hooks/useTitle";
import trackEvent from "../utils/gaTracker";
import Tooltip from "../components/Tooltip";

const LeadDetail = ({
  leadData,
  InnerHeader,
  rightContent,
  showQuoteContent,
  setShowQuoteContent,
  isLeadDetailEditable,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);
  useTitle("Lead Detail -  Travel Agents CRM - Hellotravel");
  const { id } = useParams();
  const [authId, setAuthID] = useState(user.auth_id);
  const [formLeadData, setFormLeadData] = useState();
  const [commentData, setCommentData] = useState([]);
  const [packageData, setPackageData] = useState([]);
  const [packagePageLimit, setPackagePageLimit] = useState(0);
  const [pkgPage, setpkgPage] = useState(1);
  const nid = id ? id : leadData.tbl_lead_id;
  const [leadAllData, setLeadAllData] = useState({});
  const [isEditable, setIsEditable] = useState(isLeadDetailEditable);
  const [dataStatus, setDataStatus] = useState(false);
  const [pkgStatus, setPkgStatus] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successType, setSuccessType] = useState(null);
  const [searchPackage, setSearchPackage] = useState(null);
  const [reviewTravelMessage, setReviewTravelMessage] = useState("");
  const [agentlists, setAgentlist] = useState({});
  const [showExtraField, setShowExtraField] = useState(false);
  const [leadLastStatus, setLeadLastStatus] = useState("");
  const [isCheckLoginType, setisCheckLoginType] = useState([]);
  const commentHistoryRef = useRef(null);

  const [searchValue, setSearchValue] = useState("");
  const dateOfBirth = new Date();
  dateOfBirth.setFullYear(dateOfBirth.getFullYear() - 16);

  useEffect(() => {
    if (id) {
      getLeadDetail(nid);
    } else {
      getLeadDetail(nid);
    }
    getPackageInfo(1);
  }, []);

  //getLeadDetail(id);
   function getLeadDetail(nid) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/lead-listing.php?fun=getdetail`,
      data: {
        auth_id: authId,
        leadId: nid,
      },
    })
      .then(function (response) {
        setFormLeadData(response.data.data.rowdetail);
        setCommentData(response.data.data.commenthistory);
        setAgentlist(response.data.data.agentlist);
        setLeadLastStatus(response.data.data.laststatus);
        setDataStatus(true);
      })
      .catch(function (error) {
        if (error.response) {
          console.error(error.response.data);
        } else if (error.request) {
          console.log(error.request);
          alert("Please check your internet connection and try again.");
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }

  
  function getPackageInfo(pkgPages, searchcity = "") {
    // setPkgStatus(false);

    if (searchcity === "") {
      //	   	searchcity=formLeadData.tbl_lead_destination_value;
    }

    axios({
      method: "post",
      url: `${user.base_url}/subuser/lead-listing.php?fun=packageinfo`,
      headers: {},
      data: {
        auth_id: authId,
        pkgPage: pkgPages, // This is the body part
        searchcity: searchcity,
        leadid: nid,
      },
    }).then(function (response) {
      if (pkgPages === 1) {
        setPackageData(response.data.data.pacakageData);
        setPackagePageLimit(response.data.data.offset_auto);
        setPkgStatus(true);
      } else {
        setPackagePageLimit(response.data.data.offset_auto);
        setPackageData([...packageData, ...response.data.data.pacakageData]);
        // response.data.data.pacakageData.map((r,i)=>{
        //   setPackageData((prevState) => {
        //     const updatedMembers = [...prevState, r];
        //     return updatedMembers;
        //   });
        // })
      }
      setisCheckLoginType(response.data.login);
    });
  }

  useEffect(() => {
    if (pkgPage <= packagePageLimit) {
      getPackageInfo(pkgPage, searchPackage);
    }
  }, [pkgPage, searchPackage]);

  const handleScroll = () => {
    setpkgPage(pkgPage + 1);
  };

  const firstInputRef = useRef(null);

  useEffect(() => {
    if (isEditable) {
      firstInputRef.current.focus();
    }
  }, [isEditable]);

  const handleEditClick = () => {
    setShowExtraField(!showExtraField);
    setIsEditable(!isEditable);
    trackEvent("Lead Detail", "click", `Edit`);
  };

  function updateLeadDetail() {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/lead-listing.php?fun=update`,
      headers: {},
      data: {
        inputs: formLeadData,
        auth_id: authId, // This is the body part
      },
    }).then(function (response) {
      toast.success("Details saved successfully");
    });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the input name is 'pax', update the pax object property
    if (name.startsWith("pax.")) {
      const paxKey = name.split(".")[1];
      setFormLeadData({
        ...formLeadData,
        pax: {
          ...formLeadData.pax,
          [paxKey]: value,
        },
      });
    } else {
      setFormLeadData({
        ...formLeadData,
        [name]: value,
      });
    }
  };
  const handleDatePickerChange = (date) => {
    const istDate = new Date(date);
    const formattedDate = istDate.toISOString();
    var jun = momenttz(formattedDate);
    var newindiadate = jun.tz("Asia/Calcutta").format("DD-MMM-YY");

    setFormLeadData({
      ...formLeadData,
      tbl_lead_travel_date: newindiadate,
    });
  };
  const handleDatePickerChangeDOB = (date) => {
    const istDate = new Date(date);
    const formattedDate = istDate.toISOString();
    var jun = momenttz(formattedDate);
    var newindiadate = jun.tz("Asia/Calcutta").format("DD-MMM-YY");

    setFormLeadData({
      ...formLeadData,
      tbl_lead_dob: newindiadate,
    });
  };

  const handleHistoryClick = () => {
    if (commentHistoryRef.current) {
      commentHistoryRef.current.scrollIntoView({ behavior: "smooth" });
      trackEvent("Lead Detail", "click", `History`);
    }
  };

  const durationOptions = {
    "2 Days/ 1 Nights": "1 Night/2 Days",
    "3 Days/ 2 Nights": "3 Nights/ 2 Days",
    "4 Days/ 3 Nights": "3 Nights/4 Days",
    "5 Days/ 4 Nights": "4 Nights/5 Days",
    "6 Days/ 5 Nights": "5 Nights/6 Days",
    "7 Days/ 6 Nights": "6 Nights/7 Days",
    "8 Days/ 7 Nights": "7 Nights/8 Days",
    "9 Days/ 8 Nights": "8 Nights/9 Days",
    "10 Days/ 9 Nights": "9 Nights/10 Days",
    "11 Days/ 10 Nights": "10 Nights/11 Days",
    "12 Days/ 11 Nights": "11 Nights/12 Days",
    "13 Days/ 12 Nights": "12 Nights/13 Days",
    "14 Days/ 13 Nights": "13 Nights/14 Days",
    "15 Days/ 14 Nights": "14 Nights/15 Days",
    "16 Days/ 15 Nights": "15 Nights/16 Days",
    "17 Days/ 16 Nights": "16 Nights/17 Days",
    "18 Days/ 17 Nights": "17 Nights/18 Days",
    "19 Days/ 18 Nights": "18 Nights/19 Days",
    "20 Days/ 19 Nights": "19 Nights/20 Days",
    "21 Days/ 20 Nights": "20 Nights/21 Days",
    "22 Days/ 21 Nights": "21 Nights/22 Days",
    "23 Days/ 22 Nights": "22 Nights/23 Days",
    "24 Days/ 23 Nights": "23 Nights/24 Days",
    "25 Days/ 24 Nights": "24 Nights/25 Days",
  };

  const travelWithOptions = {
    "Only Me": "only_me",
    "Me With Kid": "me_with_kid",
    "Me With Kids": "me_with_kids",
    "With Spouse": "with_spouse",
    "With Spouse & kid": "with_spouse_kid",
    "With Spouse & kids": "with_spouse_kids",
    "With Parents": "with_parents",
    "With Friends": "with_friends",
    "With Family And Friends": "with_family_friends",
    Other: "Other",
  };

  const hotelTypeOptions = {
    "1 Star": "1_star",
    "2 Star": "2_star",
    "3 Star": "3 star",
    "4 Star": "4 star",
    "5 Star": "5 star",
    "5+ Star": "5plusstar",
    Unrated: "unrated",
    "Guest House": "guesthouse",
    "Service Apartment": "serviceapartment",
  };

  const trasportOptions = {
    Flight: "flight",
    Train: "train",
    Bus: "bus",
    Taxi: "taxi",
  };

  const responseStatus = { Yes: "yes", No: "no" };

  const needtobook = {
    Immediately: "immediately",
    "Just Exploring": "exploring",
  };

  const maritialstatuslist = {
    Married: "married",
    Single: "single",
    Widow: "widow",
    "Married with 1 kid": "married_with_1_kid",
    "Married with 2 kids": "married_with_2_kid",
    "Married with 2+ kids": "married_with_2_plus_kid",
  };

  const incomepermonth = {
    "Below 15K": "Below 15K",
    "15K - 29K": "15K-29K",
    "30K - 49K": "30K-49K",
    "50K - 99K": "50K-99K",
    "1L - 3L": "1L-3L",
    "3L - 10L": "3L-10L",
  };

  const internationaltriplist = {
    "not-yet": "Not Yet",
    "1-5": "1-5 International Trips",
    "6-10": "6-10 International Trips",
    "10plus": "10+ International Trips",
  };

  const genderOptions = {
    Male: "male",
    Female: "female",
    "Not Prefer to Say": "transgender",
  };
  //*********options**********//

  const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);
  const [isReviewPopupOpen, setIsReviewPopupOpen] = useState(false);

  const handleStatusButtonClick = () => {
    setIsStatusPopupOpen(true);
    trackEvent("Lead Detail", "click", `Update Status`);
  };

  const handleCloseStatusPopup = () => {
    setIsStatusPopupOpen(false);
    trackEvent("Lead Detail", "click", `Update Status - Submit`);
  };

  const handleCloseReviewPopup = () => {
    setIsReviewPopupOpen(false);
    trackEvent("Lead Detail", "click", "Ask For Review - Close");
  };
  const handleAddPackage = () => {
    navigate("/package-builder");
    trackEvent("Lead Detail", "click", "Send Quote - Add Package");
  };

  const handleSaveClick = () => {
    //return false;
    updateLeadDetail();
    setLeadAllData((prevLeadData) => ({
      ...prevLeadData,
      ...formLeadData,
    }));
    // setIsEditable(isEditable);
    setIsEditable(!isEditable);
    setShowExtraField(false);
    trackEvent("Lead Detail", "click", `Save`);
  };

  function updateleadStatus(selectedStatus, selectedRows) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/lead-listing.php?fun=updateleadstatus`,
      headers: {},
      data: {
        statuslead: selectedStatus,
        selectedid: selectedRows,
        auth_id: authId, // This is the body part
      },
    }).then(function (response) {
      setFormLeadData(selectedStatus);
      getLeadDetail(selectedStatus.tbl_lead_id);
      toast.success("Status updated successfully");
    });
  }

  function updateleadStatusReview(selectedStatus, selectedRows) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/lead-listing.php?fun=updateleadstatus`,
      headers: {},
      data: {
        statuslead: selectedStatus,
        selectedid: selectedRows,
        auth_id: authId, // This is the body part
      },
    }).then(function (response) {
      setFormLeadData(selectedStatus);
      handleReviewButtonClickPop(selectedStatus);
      handleCloseReviewPopup();
      getLeadDetail(selectedStatus.tbl_lead_id);
      toast.success("Status updated successfully");
    });
  }

  const handleUpdateStatus = (updatedStatus) => {
    trackEvent("Lead Detail", "click", `Save`);
    const leadArray = [updatedStatus.tbl_lead_id];
    updateleadStatus(updatedStatus, leadArray);
  };

  const handleAskReview = (selectedStatus) => {
    if (
      selectedStatus.status !== "Booked" &&
      selectedStatus.status !== "Advance Received" &&
      selectedStatus.status !== "Full Payment Received"
    ) {
      // setSuccessType("quotesent");
      toast.success("Update status 'Booked' for sent mail");
      trackEvent("Lead Detail", "click", "Ask For Review - Submit");
      return false;
    }
    const leadArray = [selectedStatus.tbl_lead_id];
    handleDatePickerChange(selectedStatus.tbl_lead_travel_date);
    let ustatus = updateleadStatusReview(selectedStatus, leadArray);
    trackEvent("Lead Detail", "click", "Ask For Review - Submit");
  };
  const handleReviewButtonClickPop = (statusdata) => {
    if (
      (statusdata.status === "Booked" ||
        statusdata.status === "Advance Received" ||
        statusdata.status === "Full Payment Received") &&
      statusdata.tbl_lead_duration_value !== "" &&
      statusdata.tbl_lead_travel_date !== ""
    ) {
      const stringArray = statusdata.tbl_lead_duration_value.split("-");
      const today = moment().format("YYYY-MM-DD");
      const firstElement = stringArray[0];

      const parsedDate = moment(statusdata.tbl_lead_travel_date);

      const newDate = parsedDate.add(firstElement, "days");

      const limitDate = newDate.format("YYYY-MM-DD");

      if (today > limitDate) {
        setIsReviewPopupOpen(false);
        sendTravellerMail(statusdata, "Review", "");
      } else {
        setIsReviewPopupOpen(false);
        // setSuccessType("quotesent");
        toast.success("Please try again after the traveler's departure date");
      }
    } else if (
      statusdata.status === "Booked" &&
      statusdata.tbl_lead_travel_date === ""
    ) {
      setIsReviewPopupOpen(false);
      // setSuccessType("quotesent");
      toast.success("Please Enter travel date");
    } else {
      setIsReviewPopupOpen(true);
      setReviewTravelMessage("");
    }
  };

  const handleReviewButtonClick = () => {
    trackEvent("Lead Detail", "click", "Ask For Review");
    if (
      formLeadData.status === "Booked" &&
      formLeadData.tbl_lead_duration_value !== "" &&
      formLeadData.tbl_lead_travel_date !== ""
    ) {
      const stringArray = formLeadData.tbl_lead_duration_value.split("-");
      const today = moment().format("YYYY-MM-DD");
      const firstElement = stringArray[0];

      const parsedDate = moment(formLeadData.tbl_lead_travel_date);

      const newDate = parsedDate.add(firstElement, "days");

      const limitDate = newDate.format("YYYY-MM-DD");

      if (today > limitDate) {
        setIsReviewPopupOpen(false);
        sendTravellerMail(formLeadData, "Review", "");
      } else {
        setIsReviewPopupOpen(false);
        // setSuccessType("quotesent");
        toast.success("Please try again after the traveler's departure date");
      }
    } else if (
      formLeadData.status === "Booked" &&
      formLeadData.tbl_lead_travel_date === ""
    ) {
      setIsReviewPopupOpen(false);
      // setSuccessType("quotesent");
      toast.success("Please Enter travel date");
    } else {
      setIsReviewPopupOpen(true);
      setReviewTravelMessage("");
    }
  };

  function updateLeadAssign(leadidarray, newAssignedTo) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/lead-listing.php?fun=updateleadassign`,
      headers: {},
      data: {
        lids: leadidarray,
        newAssignedTo: newAssignedTo,
        auth_id: authId, // This is the body part
      },
    }).then(function (response) {
      setFormLeadData({
        ...formLeadData,
        assignedTo: newAssignedTo.value,
      });
      toast.success("Agent updated successfully");
      getLeadDetail(formLeadData.tbl_lead_id);
    });
  }

  const handleAssignedToChange = (assignTo) => {
    const leadsArray = [formLeadData.tbl_lead_id];
    updateLeadAssign(leadsArray, assignTo);
    trackEvent("Lead Detail", "click", "Assigned Change");
  };

  function sendTravellerMail(travelerData, Type, pkgId) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/mailer_template.php?fun=travellerMail`,
      headers: {},
      data: {
        travelerData: travelerData,
        Type: Type,
        pkgId: pkgId,
        auth_id: authId, // This is the body part
      },
    })
      .then(function (response) {
        // setSuccessType("quotesent");
        toast.success(response.data.data.msg);
        setShowQuoteContent(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  const pkgSelectActionHandle = (id) => {
    sendTravellerMail(formLeadData, "Quote", id);
  };

  const handleQuoteButtonClick = () => {
    trackEvent("Lead Detail", "click", "Send Quote");
    setShowQuoteContent(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    return false;
  };

  const handleSearch = (searchQuery) => {
    setPackageData([]);
    setPackagePageLimit(0);
    setSearchPackage(searchQuery);
    getPackageInfo(1, searchQuery);
    trackEvent("Lead Detail", "click", "Send Quote - Search");
  };
  const handlePackageUrl = (previewUrl) => {
    window.open(previewUrl, "_blank");
  };


  return (
    <>
      {dataStatus && (
    <div className="">
      <div className="df aic jcsb lead-detail-header mobile-lead-detail">
        {InnerHeader}
        {rightContent}
        <div className="df aic lead-detail-button jce">
          <button
             className={`${isCheckLoginType.access_cid_by>0?"disabled-input":""} btn-edit bg1 br24 fs14 mt4 fc3 pl12 pr12 pt8 pb8 mr8 v-center cp`}
            onClick={handleEditClick}
            disabled={isCheckLoginType.access_cid_by>0?true:false}
          >
            {isEditable ? (
              <div onClick={handleSaveClick}>
                <HiSave className="pr8" />
                Save
              </div>
            ) : (
              <>
                <FaEdit className="pr8" />
                Edit
              </>
            )}
          </button>
          <button
            className={`${isCheckLoginType.access_cid_by>0?"disabled-input":""} btn-status bg1 br24 fs14 mt4 fc3 pl12 pr12 pt8 pb8 mr8 v-center cp`}
            disabled={isCheckLoginType.access_cid_by>0?true:false}
            onClick={handleStatusButtonClick}
          >
            <FaInfoCircle className="pr8" />
            Update Status
          </button>
          <button
            className="btn-ask-for-review bg1 br24 fs14 mt4 fc3 pl12 pr12 pt8 pb8 mr8 v-center cp"
            onClick={handleReviewButtonClick}
          >
            <FaThumbsUp className="pr8" />
            Ask for Review
          </button>
          {leadData && leadData.status!=null &&  leadData.status!=undefined && leadData.status !== "Booked" && (
            <button
            className={`${isCheckLoginType.access_cid_by>0?"disabled-input":"cp"} btn-quote bg1 br24 fs14 mt4 fc3 pl12 pr12 pt8 pb8 v-center`}
            disabled={isCheckLoginType.access_cid_by>0?true:false}
              onClick={handleQuoteButtonClick}
            >
              <FaQuoteLeft className="pr8" />
              Send Quote
            </button>
          )}
        </div>
      </div>
      {successType && (
        <SucessMessage
          successType={successType}
          message={successMessage}
          msgColor="sucess"
          onClose={() => setSuccessType(null)}
        />
      )}

      {dataStatus === true &&
        formLeadData &&
        Object.keys(formLeadData).length && (
          <div className="container">
            <div className="left-side">
              {isStatusPopupOpen && (
                <Popup onClose={handleCloseStatusPopup} title={"Update Status"}>
                  <StatusUpdateForm
                    reviewTravelMessage={reviewTravelMessage}
                    setReviewTravelMessage={setReviewTravelMessage}
                    selectedStatus={formLeadData}
                    onUpdateStatus={handleUpdateStatus}
                    onClose={handleCloseStatusPopup}
                  />
                </Popup>
              )}

              {isReviewPopupOpen && (
                <Popup
                  onClose={handleCloseReviewPopup}
                  title={"Ask for Review"}
                >
                  <StatusUpdateForm
                    reviewTravelMessage={reviewTravelMessage}
                    setReviewTravelMessage={setReviewTravelMessage}
                    selectedStatus={formLeadData}
                    onUpdateStatus={handleAskReview}
                    onClose={handleCloseReviewPopup}
                  />
                </Popup>
              )}

              <div className="df aic jcsb lead-detail-header desk-lead-detail fww">
                {InnerHeader}
                {rightContent}
                <div className="df aic lead-detail-button jce ">
                 <button
                    className={`${isCheckLoginType.access_cid_by>0?"disabled-input":""} btn-edit bg1 br24 fs14 mt4 fc3 pl12 pr12 pt8 pb8 mr8 v-center cp`}
                    onClick={handleEditClick}
                    disabled={isCheckLoginType.access_cid_by>0?true:false}
                  >
                    {isEditable ? (
                      <div onClick={handleSaveClick}>
                        <HiSave className="pr8" />
                        Save
                      </div>
                    ) : (
                      <>
                        <FaEdit
                          className="pr8"
                          onClick={trackEvent(
                            "Lead Detail",
                            "click",
                            "Send Quote - Select"
                          )}
                        />
                        Edit
                      </>
                    )}
                  </button>
                  <button
                   className={`${isCheckLoginType.access_cid_by>0?"disabled-input":""} btn-status bg1 br24 fs14 mt4 fc3 pl12 pr12 pt8 pb8 mr8 v-center cp`}
                   disabled={isCheckLoginType.access_cid_by>0?true:false}
                    onClick={handleStatusButtonClick}
                  >
                    <FaInfoCircle className="pr8" />
                    Update Status
                  </button>
                  <button
                    className="btn-ask-for-review bg1 br24 fs14 mt4 fc3 pl12 pr12 pt8 pb8 mr8 v-center cp"
                    onClick={handleReviewButtonClick}
                  >
                    <FaThumbsUp className="pr8" />
                    Ask for Review
                  </button>
                  {leadData &&  leadData.status !== "Booked" && (
                    <button
                    className={`${isCheckLoginType.access_cid_by>0?"disabled-input":"cp"} btn-quote bg1 br24 fs14 mt4 fc3 pl12 pr12 pt8 pb8 v-center `}
                    disabled={isCheckLoginType.access_cid_by>0?true:false}
                      onClick={handleQuoteButtonClick}
                    >
                      <FaQuoteLeft className="pr8" />
                      Send Quote
                    </button>
                  )}
                </div>
              </div>
              {/* <div className="">
            Setup your team, invite and manage them. Invite team members as
            admin, editor, or sales agent
          </div> */}

              <Card className={"bg5"}>
                <div className="left-content">
                  {/* Travel Information Form */}
                  <form className="lead-detail-form">
                    {/* Add a new form row for the hide inputs */}

                    <div className="form-row">
                      {showExtraField ? (
                        <>
                          <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                              type="text"
                              id="name"
                              name="tbl_lead_name"
                              value={formLeadData.tbl_lead_name}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              ref={firstInputRef}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              type="text"
                              id="email"
                              name="tbl_lead_email"
                              value={formLeadData.tbl_lead_email}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="mobile">Mobile</label>
                            <input
                              type="text"
                              id="mobile"
                              name="mobile"
                              value={formLeadData.mobile}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="gender">Gender</label>
                            <select
                              id="gender"
                              name="gender"
                              className="dropdown-select"
                              value={formLeadData.gender}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                            >
                              <option value="">Select Gender</option>
                              {Object.entries(genderOptions).map(
                                ([key, value]) => (
                                  <option key={key} value={value}>
                                    {key}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="form-group">
                        <label htmlFor="fromCity">From City</label>
                        <input
                          type="text"
                          id="fromCity"
                          name="tbl_lead_city"
                          value={formLeadData.tbl_lead_city}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${!isEditable ? "bg6" : ""}`}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="destination">Destination</label>
                        <input
                          type="text"
                          id="destination"
                          name="tbl_lead_destination_value"
                          value={formLeadData.tbl_lead_destination_value}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${!isEditable ? "bg6" : ""}`}
                        />
                      </div>
                      <div className="form-group travel-date">
                        <label htmlFor="travelDate">Travel Date</label>
                        <DatePicker
                          dateFormat={"dd/MM/yyyy"}
                          selected={
                            formLeadData.tbl_lead_travel_date
                              ? moment(
                                  formLeadData.tbl_lead_travel_date,
                                  "DD-MMM-YY"
                                ).toDate()
                              : null
                          }
                          onChange={handleDatePickerChange}
                          placeholderText="---- Select Date ----"
                          disabled={!isEditable}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          peekNextMonth
                          className={`${!isEditable ? "bg6" : ""}`}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="tbl_lead_duration_value">
                          Duration{" "}
                        </label>
                        <select
                          id="tbl_lead_duration_value"
                          name="tbl_lead_duration_value"
                          value={formLeadData.tbl_lead_duration_value}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                        >
                          <option value="">Select duration</option>
                          {Object.entries(durationOptions).map(
                            ([key, value]) => (
                              <option key={key} value={value}>
                                {key}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      {Object.keys(formLeadData.pax).map((paxKey) => (
                        <div className="form-group" key={paxKey}>
                          <label
                            htmlFor={`pax.${paxKey}`}
                            style={{ textTransform: "capitalize" }}
                          >
                            {paxKey}
                          </label>
                          <input
                            type="number"
                            id={`pax.${paxKey}`}
                            name={`pax.${paxKey}`}
                            value={formLeadData.pax[paxKey]}
                            onChange={handleInputChange}
                            min={0}
                            disabled={!isEditable}
                            className={`${!isEditable ? "bg6" : ""}`}
                          />
                        </div>
                      ))}
                      <div className="form-group">
                        <label htmlFor="duration">Total Budget (₹)</label>
                        <input
                          type="number"
                          id="budget"
                          name="fk_tbl_budget_bkp"
                          value={formLeadData.fk_tbl_budget_bkp}
                          onChange={handleInputChange}
                          min={1000}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                          placeholder="Total Budget"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="travelDate">Travel with</label>
                        <select
                          id="travelling with"
                          name="travel_with"
                          value={formLeadData.travel_with}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                        >
                          <option value="">Select option</option>
                          {Object.entries(travelWithOptions).map(
                            ([key, value]) => (
                              <option key={key} value={value}>
                                {key}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="duration">Trip Theme</label>
                        <input
                          type="text"
                          id="tourTheme"
                          name="TBL_LEAD_THEME_VALUE"
                          value={formLeadData.TBL_LEAD_THEME_VALUE}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${!isEditable ? "bg6" : ""}`}
                          placeholder="Trip Theme"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="travelDate">Required Rooms</label>
                        <input
                          type="number"
                          id="room"
                          name="room"
                          value={formLeadData.room}
                          onChange={handleInputChange}
                          min={0}
                          disabled={!isEditable}
                          className={`${!isEditable ? "bg6" : ""}`}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="duration">Hotel Type</label>
                        <select
                          id="hotel type"
                          name="hotel_preferences"
                          value={formLeadData.hotel_preferences}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                        >
                          <option value="">Select Hotel Status</option>
                          {Object.entries(hotelTypeOptions).map(
                            ([key, value]) => (
                              <option key={key} value={value}>
                                {key}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="duration">Tickets Booked</label>
                        <select
                          id="ticket-booked"
                          name="ticket_booked"
                          value={formLeadData.ticket_booked}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                        >
                          <option value="">Select Ticked Status</option>
                          {Object.entries(responseStatus).map(
                            ([key, value]) => (
                              <option key={key} value={value}>
                                {key}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="duration">Tickets Required</label>
                        <select
                          id="ticketRequired"
                          name="ticket_required"
                          value={formLeadData.ticket_required}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                        >
                          <option value="">Select Required</option>
                          {Object.entries(responseStatus).map(
                            ([key, value]) => (
                              <option key={key} value={value}>
                                {key}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="transportation_mode">
                          Transportation Mode
                        </label>
                        <select
                          id="transportation_mode"
                          name="transportation_mode"
                          value={formLeadData.transportation_mode}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                        >
                          <option value=""> Select Transp.. Mode</option>
                          {Object.entries(trasportOptions).map(
                            ([key, value]) => (
                              <option key={key} value={value}>
                                {key}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="travel_class">Travel Class </label>
                        <input
                          type="text"
                          id="travel_class"
                          name="travel_class"
                          value={formLeadData.travel_class}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          placeholder="Enter Travel Class"
                          className={`${!isEditable ? "bg6" : ""}`}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="need to book">Need to Book</label>
                        <select
                          id="need to book"
                          name="need_to_book"
                          value={formLeadData.need_to_book}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                        >
                          <option value="">Select Book Status</option>
                          {Object.entries(needtobook).map(([key, value]) => (
                            <option key={key} value={value}>
                              {key}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="international_trip">
                          International Trips
                        </label>
                        <select
                          id="international_trip"
                          name="international_trip"
                          value={formLeadData.international_trip}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                        >
                          <option value="">Select Internat.. Trips</option>
                          {Object.entries(internationaltriplist).map(
                            ([key, value]) => (
                              <option key={value} value={key}>
                                {value}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="passport">Passport Available</label>
                        <select
                          id="passport"
                          name="passport"
                          value={formLeadData.passport}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                        >
                          <option value="">Select Passport Status</option>
                          {Object.entries(responseStatus).map(
                            ([key, value]) => (
                              <option key={key} value={value}>
                                {key}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="visa_available">Visa Available</label>
                        <select
                          id="visa_available"
                          name="visa_available"
                          value={formLeadData.visa_available}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                        >
                          <option value="">Select Visa Availability</option>
                          {Object.entries(responseStatus).map(
                            ([key, value]) => (
                              <option key={key} value={value}>
                                {key}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="drink">Nationality</label>
                        <select
                          id="nationanamelist"
                          name="nationanamelist"
                          value={formLeadData.nationanamelist}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                        >
                          <option value="">Select Nationality</option>
                          {countrynamelist.map((value) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="drink">Language Prefered</label>
                        <input
                          type="text"
                          id="languagename"
                          name="languagename"
                          value={formLeadData.languagename}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          placeholder="Enter Language Prefered"
                          className={`${!isEditable ? "bg6" : ""}`}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="food">Income (Per Month)</label>
                        <select
                          id="incomestatus"
                          name="incomestatus"
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                          value={formLeadData.incomestatus}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        >
                          <option value="">Select Income</option>
                          {Object.entries(incomepermonth).map(
                            ([key, value]) => (
                              <option key={key} value={value}>
                                {key}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="food">Marital Status</label>
                        <select
                          id="martialstatus"
                          name="martialstatus"
                          value={formLeadData.martialstatus}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? "bg6" : ""
                          } dropdown-select`}
                        >
                          <option value="">Select Marital Status</option>
                          {Object.entries(maritialstatuslist).map(
                            ([key, value]) => (
                              <option key={key} value={value}>
                                {key}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className="form-group  travel-date">
                        <label htmlFor="travelDate">Date Of Birth</label>
                        <DatePicker
                          dateFormat={"dd/MM/yyyy"}
                          selected={
                            formLeadData.tbl_lead_dob
                              ? moment(
                                  formLeadData.tbl_lead_dob,
                                  "DD-MMM-YY"
                                ).toDate()
                              : null
                          }
                          onChange={handleDatePickerChangeDOB}
                          placeholderText="---- Select Date ----"
                          disabled={!isEditable}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          peekNextMonth
                          maxDate={dateOfBirth}
                          className={`${!isEditable ? "bg6" : ""}`}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="drink">Preferred Call Time</label>
                        <input
                          type="text"
                          id="prefercalltime"
                          name="prefercalltime"
                          value={formLeadData.prefercalltime}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          placeholder="Enter Preferred Call Time"
                          className={`${!isEditable ? "bg6" : ""}`}
                        />
                      </div>
                      <div className="form-group"></div>
                      <div className="form-group"></div>
                    </div>
                    {isEditable ? (
                      <div className="df aic jcc mt16 lead-detail-button">
                        <button
                          className="btn-save bg1 br24 fs14 mt4 fc3 pl12 pr12 pt8 pb8 mr8 v-center cp"
                          onClick={handleSaveClick}
                        >
                          <HiSave className="pr8" />
                          Save
                        </button>
                      </div>
                    ) : null}
                  </form>
                  <div
                    className="df aic jcsb ml24 leadUpdate"
                    ref={commentHistoryRef}
                  >
                    <p className="fs14">Comment History</p>
                    <button
                      className={`${isCheckLoginType.access_cid_by>0?"disabled-input":""} btn-status bg1`}
                      disabled={isCheckLoginType.access_cid_by>0?true:false}
                      onClick={handleStatusButtonClick}
                    >
                      <FaInfoCircle className="pr8" />
                      Update Status
                    </button>
                    {/* {isEditable ? (
                  <button onClick={handleUpdateClick}>Update</button>
                ) : (
                  <button onClick={handleEditClick}>Edit</button>
                )} */}
                  </div>
                  <div>
                    <Timeline
                      commentData={commentData}
                      dataStatus={dataStatus}
                    />
                  </div>
                </div>
              </Card>
            </div>
            <div className="right-side">
              <Card className={"bg5 mt0"}>
                <div className="right-content">
                  {showQuoteContent ? (
                    <div className="df aic fdc">
                      <div className="df aic w100 search-back-info p8 ">
                        <BiArrowBack
                          className="fs20 fc1 mr16 cp"
                          onClick={() => {
                            setShowQuoteContent(false);
                            trackEvent(
                              "Lead Detail",
                              "click",
                              `Send Quote - Back`
                            );
                          }}
                        />
                        <div className="flx1">
                          <SearchInput
                            onSearch={handleSearch}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                          />
                        </div>
                      </div>

                      <div
                        className="package-info-container"
                        onScroll={handleScroll}
                      >
                        {packageData.map((destination, index) => (
                          <PackageInformation
                            key={index}
                            data={destination}
                            pkgSelectAction={pkgSelectActionHandle}
                            leadid={formLeadData.tbl_lead_id}
                          />
                        ))}
                        {packageData.length === 0 && (
                          <div className="box-center pt24 pb24 fw7 fs16">
                            No package available as per lead...
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    /* Traveler Details */
                    <>
                      <div className="status-pending-history-header p8 brd-b1 df jcsb aic ls1">
                        <p className="fs14">
                          Status:
                          <span
                            className="fc2 ml4"
                            style={{ textTransform: "capitalize" }}
                          >
                            {leadLastStatus != "" &&
                            leadLastStatus !== undefined
                              ? leadLastStatus
                              : formLeadData.status}
                          </span>
                        </p>
                        <p
                          className="fc1 tdu cp p4  fs14 pt4 pb4 pl8 pr8 br4"
                          onClick={handleHistoryClick}
                        >
                          History
                        </p>
                      </div>
                      <div className="traveler-all-details df fdc pl8 pr8">
                        <div className="profile-image">
                          <img
                            src={
                              formLeadData.photo
                                ? formLeadData.photo
                                : Userimage
                            }
                            alt="Traveler"
                          />
                          <p className="traveler-name fs16 fw6 mt4 captw">
                            {formLeadData.tbl_lead_name}
                          </p>
                          {formLeadData.age !== "N/A" && (
                            <span className="age fs14 mb4 mt4">
                              {formLeadData.age} years old
                            </span>
                          )}
                          <p className="member-since mb8">
                            Member Since: {formLeadData.memberSince}
                          </p>

                          <div className="assigned" >
                            <span className="info-label fw6 fs14 fc1 mt8">
                              Assigned to:{" "}
                            </span>
                            <select
                              value={formLeadData.assignedTo}
                              onChange={(e) =>
                                handleAssignedToChange({
                                  label:
                                    e.target.selectedOptions[0].attributes.datav
                                      .value,
                                  value: e.target.value,
                                })
                              }
                              className="fs14"
                              disabled={isCheckLoginType.access_cid_by>0?true:false}
                            >
                              {agentlists.map((option) => (
                                <option
                                  value={option.value}
                                  key={option.value}
                                  datav={option.label}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="lead-profile-info mt12">
                          <ul className="ls1">
                            {isCheckLoginType.access_cid_by<=0 && <><li className="lead-profile-info df jcsb aic p12 df jcsb aic p12">
                              <span className="info-label fs14 fs14 fc16">
                                Email:
                              </span>
                              <span className="info-value fs14">
                                <a
                                  href={`mailto:${formLeadData.tbl_lead_email}`}
                                  className="fc14"
                                >
                                  {formLeadData.tbl_lead_email}
                                </a>
                              </span>
                            </li>
                            <li className="lead-profile-info df jcsb aic p12 df jcsb aic p12">
                              <span className="info-label fs14 fs14 fc16">
                                Mobile:
                              </span>
                              <span className="info-value fs14 captw v-center">
                                <a
                                  href={`tel:${formLeadData.mobile}`}
                                  className="fc14"
                                >
                                  {formLeadData.mobile}
                                </a>

                                {formLeadData.whatsapp_verify === "1" && (
                                  <Tooltip title={"Verified"}>
                                    <TiTick className="fs18 fc2" />{" "}
                                    <BsWhatsapp className="fs18 fc2" />
                                  </Tooltip>
                                )}
                              </span>
                            </li></>}
                            <li className="lead-profile-info df jcsb aic p12 df jcsb aic p12">
                              <span className="info-label fs14 fs14 fc16">
                                Gender:
                              </span>
                              <span className="info-value fs14 captw">
                                {formLeadData.gender !== "" &&
                                formLeadData.gender !== null
                                  ? formLeadData.gender == "transgender"
                                    ? "Not Prefer to Say"
                                    : formLeadData.gender
                                  : "--"}
                              </span>
                            </li>
                            <li className="lead-profile-info df jcsb aic p12 df jcsb aic p12">
                              <span className="info-label fs14 fs14 fc16">
                                Married Status:
                              </span>
                              <span className="info-value fs14 v-center captw">
                                {formLeadData.martialstatus !== null &&
                                formLeadData.martialstatus !== ""
                                  ? formLeadData.martialstatus
                                  : "--"}
                              </span>
                            </li>
                            <li className="lead-profile-info df jcsb aic p12 df jcsb aic p12">
                              <span className="info-label fs14 fs14 fc16">
                                Language Preferred:
                              </span>
                              <span className="info-value fs14 captw">
                                {formLeadData.languagename !== "" &&
                                formLeadData.languagename !== null &&
                                formLeadData.languagename !== " "
                                  ? formLeadData.languagename
                                  : "--"}
                              </span>
                            </li>
                            <li className="lead-profile-info df jcsb aic p12 df jcsb aic p12">
                              <span className="info-label fs14 fs14 fc16">
                                Preferred Time to Call:
                              </span>
                              <span className="info-value fs14 captw">
                                {formLeadData.prefercalltime != 0 &&
                                formLeadData.prefercalltime !== null &&
                                formLeadData.prefercalltime !== ""
                                  ? formLeadData.prefercalltime
                                  : "--"}
                              </span>
                            </li>
                            <li
                              className={`${
                                formLeadData.package_url !== "" ? "cp" : ""
                              } lead-profile-info df jcsb aic p12`}
                              onClick={() => {
                                if (formLeadData.package_url !== "") {
                                  handlePackageUrl(formLeadData.package_url);
                                }
                              }}
                            >
                              <span className="info-label fs14 fc16">
                                Lead via:
                              </span>
                              <span className={`${formLeadData.package_url !== ""? "fc1":""} info-value fs14 captw`}>
                                {formLeadData.source}
                              </span>
                            </li>


                            <li className="lead-profile-info df jcsb aic p12 df jcsb aic p12">
                              <span className="info-label fs14 fs14 fc16">
                                Lead ID:
                              </span>
                              <span className="info-value fs14 captw">
                                {formLeadData.tbl_lead_id}
                              </span>
                            </li>
                            <li className="lead-profile-info df jcsb aic p12 df jcsb aic p12">
                              <span className="info-label fs14 fs14 fc16">
                                Lead Received Date:
                              </span>
                              <span className="info-value fs14 captw">
                                {formLeadData.lead_recv_time}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  )}
                  {showQuoteContent && (
                    <div className="fixed-button pt24">
                      <button
                        className="bg1 p12 fc3 w100 br4 cp"
                        onClick={handleAddPackage}
                      >
                        Add Package
                      </button>
                    </div>
                  )}
                </div>
              </Card>
              <ToastContainer position="bottom-right" />
            </div>
          </div>
        )}
    </div>
     )}
    </>
  );
};

export default LeadDetail;
