import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import "../pages/LeadManagement.css";
import MultiSelect from "../components/MultiSelect";
import { FaAngleDown } from "react-icons/fa";
import NumberCounter from "../components/NumberCounter";
import "./LeadManagement.css";
import Tabs from "../components/Tabs";
import { BsTrash } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimeSelect from "../components/TimeSelect";
import Popup from "../components/Popup/Popup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import datacity from "../data/pttt";
import { useTitle } from "../hooks/useTitle";
import trackEvent from "../utils/gaTracker";

const LeadManagement = ({ handleSelectTab }) => {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const user = useSelector((state) => state.auth);
  useTitle("Lead Management -  Travel Agents CRM - Hellotravel");

  const [profileTab, setProfileTab] = useState(false);
  const [authId, setAuth] = useState(user.auth_id);
  const [dataStatus, setDataStatus] = useState(false);

  const [website, setWebsite] = useState("");
  const [validWebsite, setValidWebsite] = useState("");
  const [currentLeadSource, setCurrentLeadSource] = useState([]);
  const [autoBuyDestinationName, setAutoBuyDestinationName] = useState("");

  const [leadServiceDayMonth, setLeadServiceDayMonth] = useState("Per Month");
  const [leadServiceNumber, setLeadServiceNumber] = useState(0);
  const [leadRequiredNumber, setLeadRequiredNumber] = useState(0);
  const [leadRequiredDayMonth, setLeadRequiredDayMonth] = useState("Per Day");
  const [showLeadServiceDropdown, setShowLeadServiceDropdown] = useState(false);
  const [numReqError, setNumReqError] = useState("");
  const [numServError, setNumServError] = useState("");
  const [showLeadRequiredDropdown, setShowLeadRequiredDropdown] =
    useState(false);
  const [
    showDestinationLeadRequiredDropdown,
    setShowDestinationLeadRequiredDropdown,
  ] = useState(false);
  const [protocolDropdown, setProtocolDropdown] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Domestic");
  const [showAutobuySection, setShowAutobuySection] = useState(false);
  const [showNoLeadDropdown, setShowNoLeadDropdown] = useState(false);
  const [showVerfiedByDropdown, setShowVerfiedByDropdown] = useState(false);
  const [websiteAvailable, setWebsiteAvailable] = useState(true);
  const [protocol, setProtocol] = useState("https://");
  const protocolOptions = ["http://", "https://"];
  const leadDestinationServedData = datacity;
  const roleRef = useRef(null);
  const protocolRef = useRef(null);

  const handleDestinationLeadRequiredSelect = (index, selectedOption) => {
    selectedDestinations[index]["buyLead"] = selectedOption;
    updateLeadsRequirementByCity(index, selectedDestinations[index]);

    setOpenDropdowns((prevDropdowns) => {
      return {
        ...prevDropdowns,
        [index]: false,
      };
    });
  };

  // setauto buy wale states
  const [autoBuyDestinationNames, setAutoBuyDestinationNames] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [autoBuyPauseDays, setAutoBuyPauseDays] = useState([]);
  const [autoBuyleadType, setAutoBuyLeadType] = useState([]);
  const [verifiedBy, setVerifiedBy] = useState("");
  const [autoStartBuyTime, SetAutoStartBuyTime] = useState("09:00 AM");
  const [autoEndBuyTime, SetAutoEndBuyTime] = useState("07:00 PM");
  const [numberOfLead, setNumberOfLead] = useState("");
  const [excludeSolo, setExcludeSolo] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowNoLeadDropdown(false);
      }
      if (protocolRef.current && !protocolRef.current.contains(event.target)) {
        setProtocolDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedRegion({ name: "All", ID: "All" });
  }, []);
  const leadService = ["Per Day", " Per Month"];
  const leadRequired = ["Per Day", " Per Month"];
  const currentLeadScourceOptions = [
    "Inhouse",
    "Google",
    "Facebook",
    "Website",
    "Others",
  ];

  const pauseDaysOptions = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const leadTypeOptions = [
    "Still Virgin",
    "1 Time Sold",
    "2 Time Sold",
    "3 Time Sold",
  ];
  const verfieddByOptions = ["Any", "WhatsApp", "Call", "Email"];
  const tabs = [
    //{ label: "Domestic", value: "Domestic" },
    //{ label: "Inbound", value: "Inbound" },
    //{ label: "Outbound", value: "Outbound" },
    //{ label: "International", value: "International" },
    //{ label: "Selected Destinations", value: "Selected Destinations" },
  ];

  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  const handleleadsSelect = (selectedleadsnumber) => {
    setNumberOfLead(selectedleadsnumber);
    setShowNoLeadDropdown(false);
  };
  const handleVerifiedBySelect = (selectedVerified) => {
    setVerifiedBy(selectedVerified);
    setShowVerfiedByDropdown(false);
  };

  const handlestartDateChange = (date) => {
    setStartDate(date);
  };
  const handlendDateChange = (date) => {
    setEndDate(date);
  };
  const leadsOptions = ["5", "10", "15"];

  const handleProtocolSelect = (selectedProtocol) => {
    setProtocol(selectedProtocol);
    setProtocolDropdown(false);
    if (website !== "") {
      saveLeadManagementData("website", `${selectedProtocol}${website}`);
    }
  };
  const handleWebsiteCheckboxChange = () => {
    setWebsiteAvailable(!websiteAvailable);
    setWebsite("");
    saveLeadManagementData("website", "");
  };
  const handleNextTab = () => {
    setProfileTab(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleSelectTab("Profile");
    trackEvent("LM Page", "click", "Save Changes");
  };

  const [selectedServeItem, setSelectedServeItem] = useState({});
  // my logic here
  const [selectedRegion, setSelectedRegion] = useState("");
  const [userInput, setUserInput] = useState("");
  const getFilteredSuggestions = () => {
    if (!selectedRegion || !userInput) {
      return [];
    }
    return datacity.filter((item) =>
      item.toLowerCase().includes(userInput.toLowerCase())
    );
  };

  const [destinationIdCounter, setDestinationIdCounter] = useState(1);
  const handleRemoveDestination = (destinationName, index) => {
    trackEvent("LM Page", "click", `Delete Destination - ${destinationName}`);
    const updateleadsReq = [...selectedDestinations];
    updateleadsReq.splice(index, 1);
    setSelectedDestinations(updateleadsReq);
    removeLeadsReqData(index);
  };

  const handleAddDestination = (suggestion) => {
    trackEvent("LM Page", "click", `Add Destination - ${suggestion}`);
    // Check if the destination with the same name already exists
    if (
      selectedDestinations.some(
        (dest) => dest.destinationName === suggestion.split(",")[0]
      )
    ) {
      // destination already exists then show an error message to the user
      // setError("Destination already exists");
      return;
    }
    // Generate a unique ID for the new destination
    const uniqueID = destinationIdCounter;

    // Increment the counter for the next destination
    setDestinationIdCounter(destinationIdCounter + 1);

    // If the destination is not already in the list, add it
    const newDestination = {
      id: selectedDestinations.length,
      destinationName: suggestion.split(",")[0],
      leadCount: 1,
      buyLead: "Per Day",
      setAutoBuy: false,
      autoBuyDetails: {
        name: autoBuyDestinationNames,
        startDate,
        endDate,
        pauseDays: autoBuyPauseDays,
        leadType: autoBuyleadType,
        verifiedBy,
        autoStartBuyTime,
        autoEndBuyTime,
        numberOfLead,
        excludeSolo,
      },
    };

    addLeadsReqData(newDestination);
    // Create a copy of the selectedDestinations array and add the new destination
    const updatedDestinations = [...selectedDestinations, newDestination];

    // Update the state with the modified array
    setSelectedDestinations(updatedDestinations);
    // Clear userInput and close the dropdown
    setUserInput("");
    setShowDestinationLeadRequiredDropdown(false);
  };

  const handleSaveAutoBuyDetails = () => {
    const updatedDestinations = selectedDestinations.map((destination) => {
      if (destination.id === selectedDestinationId) {
        if (destination.setAutoBuy) {
          // Destination is selected for AutoBuy, update its autoBuyDetails
          destination.autoBuyDetails = {
            name: autoBuyDestinationName,
            startDate,
            endDate,
            pauseDays: autoBuyPauseDays,
            leadType: autoBuyleadType,
            verifiedBy,
            autoStartBuyTime,
            autoEndBuyTime,
            numberOfLead,
            excludeSolo,
          };
          updateAutobuyDetail(destination);
        } else {
          // Destination is deselected for AutoBuy, clear autoBuyDetails
          destination.autoBuyDetails = null;
        }
      }
      return destination;
    });

    setSelectedDestinations(updatedDestinations);

    // Close the AutoBuy section or perform other necessary actions
    setShowAutobuySection(false);

    // Close the AutoBuy popup
    setPopupVisible(false);
  };
  const [isPopupVisible, setPopupVisible] = useState(false);

  const closePopup = () => {
    setPopupVisible(false);
  };
  const [selectedDestinationId, setSelectedDestinationId] = useState(null); // To track the selected destination

  const handleAutoBuyCheckboxChange = (destinationId) => {
    setSelectedDestinationId(destinationId);

    const updatedDestinations = selectedDestinations.map((destination) => {
      if (destination.id === destinationId) {
        setSelectedServeItem(destination);
        setAutoBuyDestinationName(destination.destinationName);
        setAutoBuyLeadType(destination.autoBuyDetails.leadType);
        setVerifiedBy(destination.autoBuyDetails.verifiedBy);
        setAutoBuyPauseDays(destination.autoBuyDetails.pauseDays);
        SetAutoStartBuyTime(destination.autoBuyDetails.autoStartBuyTime);
        SetAutoEndBuyTime(destination.autoBuyDetails.autoEndBuyTime);
        setNumberOfLead(destination.autoBuyDetails.numberOfLead);
        setExcludeSolo(destination.autoBuyDetails.excludeSolo);

        return {
          ...destination,
          setAutoBuy: !destination.setAutoBuy,
        };
      }

      return destination;
    });

    // Update the 'showAutobuySection' state
    const showSection = updatedDestinations.some(
      (destination) => destination.setAutoBuy
    );
    setShowAutobuySection(showSection);

    // Set the popup visibility for the specific destination
    const isAutoBuy = updatedDestinations.find(
      (destination) => destination.id === destinationId
    ).setAutoBuy;
    setPopupVisible(isAutoBuy);

    // Update the state with the modified array
    setSelectedDestinations(updatedDestinations);
  };

  const handleExcludeSoloChange = () => {
    setExcludeSolo(!excludeSolo); // Toggle the "excludeSolo" state when the checkbox changes
  };

  // Function to handle changes in Run AutoBuy Between
  const handleTime1Change = (newTime) => {
    SetAutoStartBuyTime(newTime);
  };

  const handleTime2Change = (newTime) => {
    SetAutoEndBuyTime(newTime);
  };
  // Callback functions to update selected items
  const handlePauseDaysSelect = (items) => {
    setAutoBuyPauseDays(items);
  };

  const handleLeadTypeSelect = (items) => {
    setAutoBuyLeadType(items);
  };

  const updateLeadCount = (index, newLeadCount) => {
    selectedDestinations[index]["leadCount"] = newLeadCount;
    updateLeadsRequirementByCity(index, selectedDestinations[index]);
    // setSelectedDestinations((prevSelectedDestinations) => {
    //   return prevSelectedDestinations.map((destination) => {
    //     if (destination.id === id) {
    //       updateLeadCountNDay(id,newLeadCount,"LeadCount");
    //       return {
    //         ...destination,
    //         leadCount: newLeadCount,
    //       };

    //     }
    //     return destination;
    //   });
    // });
  };

  const [openDropdowns, setOpenDropdowns] = useState({});
  // Function to toggle the dropdown for a specific destination by ID
  const toggleDropdown = (id) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    getLeadManageData();
  }, []);

  //==============Start updateLeadCount====================//

  function updateLeadCountNDay(dest_id, value, type) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/leadSetup.php?fun=updateLeadCountNDay`,
      headers: {},
      data: {
        auth_id: authId, // This is the body part
        dest_id: dest_id,
        value: value,
        type: type,
      },
    }).then(function (response) {});
  }

  function updateLeadsRequirementByCity(index, value) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=addLeadsReqData`,
      headers: {},
      data: {
        auth_id: authId, // This is the body part
        index: index,
        LeadsReqDetails: value,
      },
    }).then(function (response) {});
  }

  //==============End updateLeadCount====================//

  //==============Start AutobuyDetail====================//

  function updateAutobuyDetail(destautobuydata) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/leadSetup.php?fun=updateAutobuyDetail`,
      headers: {},
      data: {
        auth_id: authId, // This is the body part
        value: destautobuydata,
      },
    }).then(function (response) {});
  }

  //==============End AutobuyDetail====================//

  function getLeadManageData() {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/leadSetup.php`,
      headers: {},
      data: {
        auth_id: authId,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }

      if (response.data.data.rowdata) {
        setCurrentLeadSource(response.data.data.rowdata.lead_source);
        setWebsite(response.data.data.rowdata.website);
        setProtocol(
          response.data.data.rowdata.https === "0" ? "http://" : "https://"
        );

        if (response.data.data.rowdata.lead_served) {
          setLeadServiceNumber(response.data.data.rowdata.lead_served);
        }

        if (response.data.data.rowdata.lead_require) {
          setLeadRequiredNumber(response.data.data.rowdata.lead_require);
        }

        setLeadRequiredDayMonth(response.data.data.rowdata.lead_requiredom);
        setLeadServiceDayMonth(response.data.data.rowdata.lead_servedom);
        setWebsiteAvailable(response.data.data.rowdata.website_flag==="0"?false:true);
      }
      if (response.data.data.rowdata.leadsbycity) {
        setSelectedDestinations(response.data.data.rowdata.leadsbycity);
      }
      //setLeadDestinationServedData(response.data.data.domestic);
      setDataStatus(true);
    });
  }

  const handleLogout = () => {
    // Dispatch the logout action to clear user data
    dispatch(logout());
    // Redirect to the login page
    navigate("/login");
  };

  //==============Start Destination Serve====================//

  function saveDestServeData(destdata) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/leadSetup.php?fun=saveDestServe`,
      headers: {},
      data: {
        auth_id: authId, // This is the body part
        value: destdata,
      },
    }).then(function (response) {});
  }

  //==============End Destination Serve ====================//

  //==============Start Save data====================//

  function saveLeadManagementData(key, value) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/leadSetup.php?fun=saveLeadManagement`,
      headers: {},
      data: {
        auth_id: authId, // This is the body part
        key: key,
        value: value,
      },
    }).then(function (response) {});
  }

  //==============End Save data====================//

  function addLeadsReqData(LeadsReqDetails) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=addLeadsReqData`,
      headers: {},
      data: {
        auth_id: authId,
        LeadsReqDetails: LeadsReqDetails,
      },
    })
      .then(function (response) {})
      .catch((error) => {
        console.log(error);
      });
  }
  function removeLeadsReqData(index) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=removeLeadsReqData`,
      headers: {},
      data: {
        auth_id: authId,
        indexremove: index,
      },
    })
      .then(function (response) {
        //getLeadManageData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const setWebsiteFun = (value) => {
    if (value != "") {
      var regex = /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/;
      if (!regex.test(value)) {
        // Invalid domain
        setValidWebsite("Invalid domain entered");
      } else {
        setValidWebsite("");
        if (value.length > 0) {
          saveLeadManagementData("website", `${protocol}${value}`);
        }
        setWebsite(value);
        setValidWebsite("");
      }
    }
  };

  const handleCurrentLeadSourceSelect = (items) => {
    saveLeadManagementData("LeadSource", items);
    setCurrentLeadSource(items);
  };

  const handleLeadServiceNumberChange = (newLeadServiceNumber) => {
    setLeadServiceNumber((prevLeadServiceNumber) => {
      return newLeadServiceNumber;
    });

    saveLeadManagementData("LeadServed", newLeadServiceNumber);
  };

  const handleLeadRequiredNumberChange = (newLeadRequiredNumber) => {
    setLeadRequiredNumber((prevLeadRequiredNumber) => {
      return newLeadRequiredNumber;
    });

    saveLeadManagementData("LeadRequire", newLeadRequiredNumber);
  };

  const handleLeadServiceSelect = (selectedLeadServiceDayMonth) => {
    setLeadServiceDayMonth(selectedLeadServiceDayMonth);
    saveLeadManagementData("LeadServeDoM", selectedLeadServiceDayMonth);
    setShowLeadServiceDropdown(false);
  };

  const handleLeadRequiredSelect = (selectedLeadRequiredDayMonth) => {
    setLeadRequiredDayMonth(selectedLeadRequiredDayMonth);
    saveLeadManagementData("LeadRequireDoM", selectedLeadRequiredDayMonth);
    setShowLeadRequiredDropdown(false);
  };

  return (
    <>
      {/* {profileTab && <Profile/>} */}
      <div className="df mb24">
        {isPopupVisible && (
          <Popup onClose={closePopup} title={"Set AutoBuy Details"}>
            <div className="form-group-settings">
              <p className="fc15 fw6 fs14 ls1">Destinations </p>

              <input
                type="text"
                id="name"
                placeholder="Destinations Name"
                value={autoBuyDestinationName}
                onChange={(e) => setAutoBuyDestinationName(e.target.value)}
                readOnly
              />
            </div>

            <div>
              <p className="fc15 fw6 fs14 mb12 ls1">Start Date and End Date</p>
              <div className="df aic brd1 v-center p12 mb12">
                <DatePicker
                  dateFormat="dd-MMM-yyyy"
                  selected={startDate}
                  onChange={handlestartDateChange}
                  placeholderText="---- Start Date ----"
                  isClearable={true}
                />
                <DatePicker
                  dateFormat="dd-MMM-yyyy"
                  selected={endDate}
                  onChange={handlendDateChange}
                  placeholderText="---- End Date ----"
                  isClearable={true}
                />
              </div>
            </div>

            <div className="form-group-settings mutli-select">
              <p className="fc15 fw6 fs14 mb12 ls1">Pause Days</p>
              <MultiSelect
                travelDestination={pauseDaysOptions}
                placeholder={"Pause Days"}
                selectedItems={autoBuyPauseDays}
                setSelectedItems={setAutoBuyPauseDays}
                onSave={handlePauseDaysSelect}
              />
            </div>
            <div className="form-group-settings mutli-select">
              <p className="fc15 fw6 fs14 mb12 ls1">Lead type</p>
              <MultiSelect
                travelDestination={leadTypeOptions}
                placeholder={"Lead Type"}
                selectedItems={autoBuyleadType}
                setSelectedItems={setAutoBuyLeadType}
                onSave={handleLeadTypeSelect}
              />
            </div>
            <div className="form-group-settings">
              <label htmlFor="gender" className="fc15 fw6 fs14 mb12 ls1">
                Verified By
              </label>
              <div className="role-dropdown">
                <div
                  className="selected-role fs14"
                  onClick={() =>
                    setShowVerfiedByDropdown(!showVerfiedByDropdown)
                  }
                >
                  {verifiedBy || "Select verified by"}{" "}
                  <FaAngleDown className="fc16 fs14" />
                </div>
                {showVerfiedByDropdown && (
                  <ul className="role-options fs14">
                    {verfieddByOptions.map((option) => (
                      <li
                        key={option}
                        onClick={() => handleVerifiedBySelect(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="mb12">
              <p className="fc15 fw6 fs14 mb12 ls1">Run Autobuy Between</p>
              <TimeSelect
                time1={autoStartBuyTime}
                time2={autoEndBuyTime}
                onTime1Change={handleTime1Change}
                onTime2Change={handleTime2Change}
              />
            </div>
            <div className="form-group-settings">
              <label htmlFor="gender" className="fc15 fw6 fs14 mb12 ls1">
                No of Leads per day
              </label>
              <div className="role-dropdown">
                <div
                  className="selected-role fs14"
                  onClick={() => setShowNoLeadDropdown(!showNoLeadDropdown)}
                >
                  {numberOfLead || "Select No of leads"}{" "}
                  <FaAngleDown className="fc16 fs14" />
                </div>
                {showNoLeadDropdown && (
                  <ul className="role-options fs14" ref={dropdownRef}>
                    {leadsOptions.map((option) => (
                      <li
                        key={option}
                        onClick={() => handleleadsSelect(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="exclude-solo df">
              <input
                type="checkbox"
                name="exclude"
                className="mr8"
                checked={excludeSolo}
                onChange={handleExcludeSoloChange}
              />
              <p>Exclude Solo</p>
            </div>
            <div className="button-container mt48">
              <button type="button" className="btn-cancel" onClick={closePopup}>
                Cancel
              </button>
              <button
                type="button"
                className="btn-blue bg1 br24 fs14 cp pl16 pr16 pt10 pb10"
                onClick={() => handleSaveAutoBuyDetails()}
              >
                Save
              </button>
            </div>
          </Popup>
        )}

        <div className="w66 lead-mang-container">
          <div className="account-details df w100 fdc settings main-content-padding lead-management">
            <h3 className="fc1 fw5 fs20 mb24">
              Lead Management
              <div className="fs14 fc16 mt8 fw4 lh16">
                Streamline your business with powerful lead management
              </div>
            </h3>
            <div className="form-group-settings">
              <div className="df jcsb aic">
                <p className="fc14 fw5 fs14 ls1">Website</p>
              </div>
              <div className="df">
                <div className="input-group df pr w66 fww aisc lead-website">
                  <div className="input-group-prepend df">
                    <div className="role-dropdown" ref={protocolRef}>
                      <div
                        className="selected-role fs14 h40 country-code"
                        onClick={() => setProtocolDropdown(!protocolDropdown)}
                      >
                        {protocol || "Select Protocol"}
                        <FaAngleDown className="fc16 fs14" />
                      </div>
                      {protocolDropdown && (
                        <ul className="role-options fs14">
                          {protocolOptions.map((option) => (
                            <li
                              key={option}
                              onClick={() => {
                                handleProtocolSelect(option);
                              }}
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control br4 ${
                      !websiteAvailable ? "disabled-input-website " : ""
                    }`}
                    placeholder="Enter website URL"
                    id="website"
                    name="website"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    onBlur={(e) => setWebsiteFun(e.target.value)}
                    disabled={!websiteAvailable}
                  />
                </div>
                <div className="v-center website-checkbox">
                  <input
                    type="checkbox"
                    checked={websiteAvailable}
                    onChange={handleWebsiteCheckboxChange}
                  />
                  <p className="fs12 fc14 fw5 mt10">Website Available</p>
                </div>
              </div>
            </div>

            {validWebsite && (
              <div className="form-error-messages error mb24 blink-text-normal-error">
                <div>
                  <p>{validWebsite}</p>
                </div>
                <div></div>
                <div></div>
              </div>
            )}

            {dataStatus && (
              <div className="form-group-settings mutli-select">
                <p className="fc14 fw5 fs14 mb12 ls1">Current Leads Sources</p>
                <MultiSelect
                  travelDestination={currentLeadScourceOptions}
                  placeholder={"Current Lead Sources"}
                  selectedItems={currentLeadSource}
                  setSelectedItems={setCurrentLeadSource}
                  onSave={handleCurrentLeadSourceSelect}
                />
              </div>
            )}

            {dataStatus && (
              <div className="form-group-settings v-center ">
                <p className="fc14 fw5 fs14 ls1 df lead-serv-text mr16 flx25">
                  Leads Serviced
                </p>
                <div className="lead-service-section df aic flx1">
                  <NumberCounter
                    countLead={leadServiceNumber}
                    onCountChange={handleLeadServiceNumberChange}
                    numError={setNumServError}
                  />
                  <div className="role-dropdown ml24">
                    <div
                      className="selected-role fs14"
                      onClick={() =>
                        setShowLeadServiceDropdown(!showLeadServiceDropdown)
                      }
                    >
                      {leadServiceDayMonth || "Select Month or Day"}{" "}
                      <FaAngleDown className="fc16 fs14" />
                    </div>
                    {showLeadServiceDropdown && (
                      <ul className="role-options fs14">
                        {leadService.map((option) => (
                          <li
                            key={option}
                            onClick={() => handleLeadServiceSelect(option)}
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            )}
            {dataStatus && (
              <div className="form-group-settings v-center">
                <p className="fc14 fw5 fs14 ls1 df lead-serv-text mr16 flx25">
                  Leads Required
                </p>
                <div className="lead-service-section df aic flx1">
                  <NumberCounter
                    countLead={leadRequiredNumber}
                    onCountChange={handleLeadRequiredNumberChange}
                    numError={setNumReqError}
                  />

                  <div className="role-dropdown ml24">
                    <div
                      className="selected-role fs14"
                      onClick={() =>
                        setShowLeadRequiredDropdown(!showLeadRequiredDropdown)
                      }
                    >
                      {leadRequiredDayMonth || "Select Month or Day"}{" "}
                      <FaAngleDown className="fc16 fs14" />
                    </div>
                    {showLeadRequiredDropdown && (
                      <ul className="role-options fs14">
                        {leadRequired.map((option) => (
                          <li
                            key={option}
                            onClick={() => handleLeadRequiredSelect(option)}
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="destination-served brd-t1">
              <h3 className="fc1 fw5 fs20 mt24 mb24">
                Destination Served
                <div className="fs14  fc16 mt8 fw4 lh16">
                  Specify your dealing destinations and lead requirements
                </div>
              </h3>
              <Tabs
                tabs={tabs}
                onTabChange={handleTabChange}
                showCheckboxes={false}
                showFilter={false}
              />

              <div className="destination-served-content df mt16 mb16">
                <div className="flx1">
                  {selectedTab === "Domestic" && (
                    <>
                      <div className="form-group-settings add-destinations pr">
                        <p className="fc15 fw6 fs14 ls1 ">Add Destinations</p>
                        <div className="input-button-container pr">
                          <input
                            type="text"
                            value={userInput}
                            placeholder="Search your destination"
                            onChange={(e) => setUserInput(e.target.value)}
                          />
                          {userInput && (
                            <ul className="city-suggestions">
                              {getFilteredSuggestions().length === 0 &&
                              userInput ? (
                                <li className="no-suggestion">
                                  No suggestion found
                                </li>
                              ) : (
                                getFilteredSuggestions().map(
                                  (suggestion, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        handleAddDestination(suggestion)
                                      }
                                    >
                                      {suggestion}
                                    </li>
                                  )
                                )
                              )}
                            </ul>
                          )}
                        </div>
                      </div>
                      {dataStatus &&
                        selectedDestinations.length > 0 &&
                        selectedDestinations.map(
                          (selectedDestination, index) => (
                            <div key={index} className="destination-buy-lead">
                              <div className="form-group-settings v-center">
                                <p className="fc15 fw6 fs14 ls1 df lead-serv-text mr16 flx25 ">
                                  {selectedDestination.destinationName}
                                </p>
                                <div className="lead-service-section df aic flx1">
                                  <NumberCounter
                                    countLead={selectedDestination.leadCount}
                                    onCountChange={(newLeadCount) =>
                                      updateLeadCount(index, newLeadCount)
                                    }
                                  />
                                  <div
                                    className="role-dropdown ml24"
                                    ref={roleRef}
                                  >
                                    <div
                                      className="selected-role fs14"
                                      onClick={() => toggleDropdown(index)}
                                    >
                                      {selectedDestination.buyLead ||
                                        "Select Lead service"}{" "}
                                      <FaAngleDown className="fc16 fs14" />
                                    </div>
                                    {openDropdowns[index] && (
                                      <ul className="role-options fs14">
                                        {leadRequired.map((option) => (
                                          <li
                                            key={option}
                                            onClick={() =>
                                              handleDestinationLeadRequiredSelect(
                                                index,
                                                option
                                              )
                                            }
                                          >
                                            {option}
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </div>
                                  <BsTrash
                                    className="cp fc9 ml16 fs30"
                                    onClick={() =>
                                      handleRemoveDestination(
                                        selectedDestination.destinationName,
                                        index
                                      )
                                    }
                                  />
                                </div>

                                {/* <div className="autobuy df">
                            <input
                              type="checkbox"
                              name="autobuy"
                              checked={selectedDestination.setAutoBuy}
                              onChange={() =>
                                handleAutoBuyCheckboxChange(
                                  selectedDestination.id
                                )
                              }
                            />
                            <p className="fs14 ">Set AutoBuy</p>
                          </div> */}
                              </div>
                            </div>
                          )
                        )}
                    </>
                  )}
                  {selectedTab === "Selected Destinations" && (
                    <div>
                      {selectedDestinations.length > 0 &&
                        selectedDestinations.map(
                          (selectedDestination, index) => (
                            <div key={index} className="destination-buy-lead">
                              <div className="form-group-settings v-center jcsb">
                                <p className="fc15 fw6 fs14 ls1 df">
                                  {selectedDestination.destinationName}
                                </p>
                                <NumberCounter
                                  countLead={selectedDestination.leadCount}
                                  onCountChange={(newLeadCount) =>
                                    updateLeadCount(
                                      selectedDestination.id,
                                      newLeadCount
                                    )
                                  }
                                />
                                <div className="role-dropdown flx33">
                                  <div
                                    className="selected-role fs14"
                                    onClick={() =>
                                      toggleDropdown(selectedDestination.id)
                                    }
                                  >
                                    {selectedDestination.buyLead ||
                                      "Select Lead service"}{" "}
                                    <FaAngleDown className="fc16 fs14" />
                                  </div>
                                  {openDropdowns[selectedDestination.id] && (
                                    <ul className="role-options fs14">
                                      {leadRequired.map((option) => (
                                        <li
                                          key={option}
                                          onClick={() =>
                                            handleDestinationLeadRequiredSelect(
                                              selectedDestination.id,
                                              option
                                            )
                                          }
                                        >
                                          {option}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                                <BsTrash
                                  className="cp fc9"
                                  onClick={() =>
                                    handleRemoveDestination(
                                      selectedDestination.id
                                    )
                                  }
                                />
                                <div className="autobuy df">
                                  <input
                                    type="checkbox"
                                    name="autobuy"
                                    checked={selectedDestination.setAutoBuy}
                                    onChange={() =>
                                      handleAutoBuyCheckboxChange(
                                        selectedDestination.id
                                      )
                                    }
                                  />
                                  <p className="fs14 ">Set AutoBuy</p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="button-container born">
              {/*
            <button type="button" className="btn-cancel cp">
              Cancel
            </button>
	    */}
              <button
                type="button"
                className="btn-blue bg1 br24 fs14 cp pl16 pr16 pt10 pb10"
                onClick={handleNextTab}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadManagement;
