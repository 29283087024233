import axios from "axios";
import React, { useState, useEffect } from "react";
import InnerHeader from "../components/InnerHeader";
import Card from "../components/Card";
import Tabs from "../components/Tabs";
import ReportTable from "../components/ReportTable";
import "./MyReports.css";
import SmallLoader from "../components/SmallLoader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../hooks/useTitle";
import trackEvent from "../utils/gaTracker";

const MyReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useTitle("My Reports -  Travel Agents CRM - Hellotravel");

  const user = useSelector((state) => state.auth);

  const [authId, setAuth] = useState(user.auth_id);
  const [selectedTab, setSelectedTab] = useState("Conversion Report");

  const [conversionData, setConversionData] = useState([]);
  const [leadStatusData, setLeadStatusData] = useState([]);

  const [dataStatus, setDataStatus] = useState(false);
  const [reportWise, setreportWise] = useState("MONTH");

  const [totalLead, setTotalLead] = useState(0);
  const [totalBookedLead, setTotalBookedLead] = useState(0);
  const [totalHotLead, setTotalHotLead] = useState(0);
  const [totalFileSize, setTotalFileSize] = useState(0);

  const [agents, setAgents] = useState({});

  const [dom, setDom] = useState({});
  const [autoLoader, setAutoLoader] = useState(false);

  const tabs = [
    { label: "Conversion Report", value: "Conversion Report" },
    { label: "Lead Status", value: "Lead Status" },
  ];
  const filters = [
    { label: "Agent", value: "agent" },
    // { label: "Lead Type", value: "leadType" },
    // { label: "Day Type", value: "dayType" },
    // { label: "Destination ", value: "destination" },
    // { label: "Travel Duration ", value: "travelDuration" },
    { label: "Lead Received Date", value: "leadReceivedDate" },
    { label: "Lead Via", value: "leadVia" },
    // { label: "Lead Status", value: "leadStatus" },
  ];

  const conversionColumnsMapping = {
    Date: "date",
    "Total Leads": "totalLeads",
    "Total Booked": "totalBooked",
    "Partial Amount Received": "partialAmountReceived",
    "Total Amount Received": "totalAmountReceived",
    "Conversion (%)": "conversion",
    Filesize: "filesize",
  };

  const leadStatusColumnsMapping = {
    Date: "date",
    "Total Lead": "Total Lead",
    Booked: "Booked",
    Hot: "Hot",
    Followup: "Followup",
    "No Response": "No Response",
    Junk: "Junk",
    New: "New",
  };

  // Function to dynamically get the table heading based on the selected tab
  const getTableHeading = () => {
    switch (selectedTab) {
      case "Conversion Report":
        return "Conversion Report";
      case "Lead Status":
        return "Lead Status";
      default:
        return "";
    }
  };

  //================calling Apis===========//

  const handleTabChange = (value) => {
    trackEvent("My Report Page", "click", `Tab Name-${value}`);
    if (value === "Lead Status") {
      fetchReportStatusData("", "", "");
    } else {
      fetchReportConversionData("", "", "");
    }
    setSelectedTab(value);
  };

  function fetchReportStatusData(searchBy = "", searchText = "", dom) {
    setAutoLoader(true);
    setDataStatus(false);
    axios({
      method: "post",
      url: `${user.base_url}/subuser/agentReport.php?fun=status`,
      headers: {},
      data: {
        auth_id: authId,
        searchBy: searchBy,
        searchText: searchText,
        dom: dom,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }

      setAgents(response.data.data.agentlist);
      setLeadStatusData(response.data.data.tableadcount);
      setreportWise(response.data.data.dayormonth);
      setTotalLead(response.data.data.total_lead);
      setTotalBookedLead(response.data.data.total_booked_lead);
      setTotalHotLead(response.data.data.total_hot_lead);

      setDataStatus(true);
      setAutoLoader(false);
    });
  }

  useEffect(() => {
    //fetchReportStatusData();
    fetchReportConversionData("", "", "");
  }, []);

  function fetchReportConversionData(searchBy, searchText, dom) {
    setAutoLoader(true);
    axios({
      method: "post",
      url: `${user.base_url}/subuser/agentReport.php`,
      headers: {},
      data: {
        auth_id: authId,
        searchBy: searchBy,
        searchText: searchText,
        dom: dom,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }

      setAgents(response.data.data.agentlist);
      setConversionData(response.data.data.tableadcount);
      setreportWise(response.data.data.dayormonth);
      setTotalLead(response.data.data.total_lead);
      setTotalBookedLead(response.data.data.total_booked_lead);
      setTotalFileSize(response.data.data.total_file_size);

      setDataStatus(true);
      setAutoLoader(false);
    });
  }

  const handleLogout = () => {
    // Dispatch the logout action to clear user data
    dispatch(logout());
    // Redirect to the login page
    navigate("/login");
  };

  const handleOnclickFilter = (selectedFilter, filterValue) => {
    if (selectedTab === "Conversion Report") {
      fetchReportConversionData(selectedFilter, filterValue, dom);
      trackEvent("My Report Page","click",`Conversion Filter - ${selectedFilter} - ${filterValue}`);
    } else if (selectedTab === "Lead Status") {
      fetchReportStatusData(selectedFilter, filterValue, dom);
      trackEvent("My Report Page","click",`Lead Filter - ${selectedFilter} - ${filterValue}`);
    }
  };

  const domClickHandle = (id) => {
    setDom(id);
  };

  return (
    <div className="main-content-padding">
      <InnerHeader
        heading="My Reports"
        showButton={false}
        txtSubHeading="Stay informed and gain valuable insights with comprehensive data and analytics right at your fingertips - upto 1 year data."
      />

      <Card className={"bg5"}>
        <Tabs
          tabs={tabs}
          OnclickFilter={handleOnclickFilter}
          filters={filters}
          onTabChange={handleTabChange}
          showCheckboxes={false}
          dataStatus={dataStatus}
          showFilter={true}
          domClick={domClickHandle}
        />

        <div className="card-content df pt24 brd-b1 pb24 pl24 jcc">
          <div className="card-item df fdc jcc br4">
            <span className="fs24 fc1 mb8">
              {selectedTab === "Conversion Report"
                ? totalLead
                : selectedTab === "Lead Status"
                ? totalLead
                : ""}
            </span>
            <p className="fw6  fs16 fc5 ">
              {selectedTab === "Conversion Report"
                ? "Total Leads"
                : selectedTab === "Lead Status"
                ? "Total Lead"
                : ""}
            </p>
          </div>
          <div className="card-item df fdc jcc br4">
            <span className="fs24 fc1 mb8">
              {selectedTab === "Conversion Report"
                ? totalBookedLead
                : selectedTab === "Lead Status"
                ? totalBookedLead
                : ""}
            </span>
            <p className="fw6  fs16 fc5">
              {selectedTab === "Conversion Report"
                ? "Total Booked"
                : selectedTab === "Lead Status"
                ? "Booked"
                : ""}
            </p>
          </div>
          <div className="card-item df fdc jcc br4">
            <span className="fs24 fc1 mb8">
              {selectedTab === "Conversion Report"
                ? "₹" + totalFileSize
                : selectedTab === "Lead Status"
                ? totalHotLead
                : ""}
            </span>
            <p className="fw6 fs16 fc5">
              {selectedTab === "Conversion Report"
                ? "Filesize"
                : selectedTab === "Lead Status"
                ? "Hot"
                : ""}
            </p>
          </div>
        </div>

        <div className="sections-container">
          <div className="">
            <p className="tdu fc1 fs16 ls1 fw6 pl16 mb16 mt16">
              {getTableHeading()}
            </p>
            {autoLoader && (
              <div className="box-center mb12">
                <SmallLoader className={"mb12"} />
              </div>
            )}
            {dataStatus === true && selectedTab === "Conversion Report" && (
              <ReportTable
                columnMapping={conversionColumnsMapping}
                data={conversionData}
                dataStatus={dataStatus}
                setDataStatus={setDataStatus}
                selectedTab={selectedTab}
                reportWise={reportWise}
              />
            )}
            {dataStatus === true && selectedTab === "Lead Status" && (
              <ReportTable
                columnMapping={leadStatusColumnsMapping}
                data={leadStatusData}
                dataStatus={dataStatus}
                setDataStatus={setDataStatus}
                selectedTab={selectedTab}
                reportWise={reportWise}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MyReports;
