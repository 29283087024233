// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  padding-bottom: 16px;
  margin: 16px 0;
  border-radius: 0.625rem;
  box-shadow: 6px 11px 41px -28px #a99de7;
}`, "",{"version":3,"sources":["webpack://./src/components/Card.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,cAAc;EACd,uBAAuB;EACvB,uCAAuC;AACzC","sourcesContent":[".card {\r\n  padding-bottom: 16px;\r\n  margin: 16px 0;\r\n  border-radius: 0.625rem;\r\n  box-shadow: 6px 11px 41px -28px #a99de7;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
