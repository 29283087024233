import React, { useState, useEffect, useRef } from "react";
import "quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../../store/authSlice.js";
import { useNavigate } from "react-router-dom";

import ReactQuill from "react-quill";
import "../PackageBuilder/TextEditor.css";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";
import { AiFillPlusCircle } from "react-icons/ai";
import { HiSave } from "react-icons/hi";

const TextEditor = ({ tblLeadId, nid, toast, renderBackButton }) => {
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [policies, setPolicies] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editPolicyId, setEditPolicyId] = useState(null);
  const quillRef = useRef(null);
  const formRef = useRef(null);

  const user = useSelector((state) => state.auth);

  const [authId, setAuthID] = useState(user.auth_id);
  const [baseUrl, setBaseUrl] = useState(user.base_url);
  const [siteUrl, setSiteUrl] = useState(user.site_url);

  useEffect(() => {
    handleGetDeals(tblLeadId);
  }, [tblLeadId]);

  const handleGetDeals = async (tblLeadId) => {
    try {
      const requestBody = {
        id: tblLeadId,
        auth_id: authId,
      };

      const getDealsApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=deals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const dealDetailsData = await getDealsApiResponse.json();
      const termAndConditions = JSON.parse(
        dealDetailsData.data.deal_object.terms_struct || "[]"
      );
      setPolicies(termAndConditions);
      setIsEditing(false);
    } catch (error) {
      console.error("API call error:", error);
    }
  };
  const handleEditButtonClick = (id) => {
    setEditPolicyId(id);
    setIsEditing(true);
    const selectedPolicy = policies.find((policy) => policy.id === id);
    if (selectedPolicy) {
      setTitle(selectedPolicy.title);
      setDescription(selectedPolicy.description);
    }
    // Scroll to the form when editing
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleApiPostPolicy = async () => {
    try {
      const requestBody = {
        auth_id: authId,
        id: editPolicyId ? editPolicyId : "",
        nid: nid,
        tbl_deal_id: tblLeadId,
        title: title,
        description: description,
      };

      const postPolicyApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=terms_struct`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const postPolicyApiDetails = await postPolicyApiResponse.json();
      handleGetDeals(tblLeadId);
      setEditPolicyId(null);
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const handleAddButtonClick = (e) => {
    e.preventDefault();
    if (title === "") {
      toast.error("Please enter policie title.");
      return false;
    }
    if (description === "" || description === "<p><br></p>") {
      toast.error("Please enter policie description.");
      return false;
    }
    handleApiPostPolicy();
    setTitle("");
    setDescription("");
    setEditPolicyId(null);
    if (quillRef.current) {
      quillRef.current.getEditor().setText("");
    }
  };

  const handleDeletePolicy = async (id) => {
    try {
      const requestBody = {
        auth_id: authId,
        nid: nid,
        tbl_deal_id: tblLeadId,
        upid: id,
      };

      const postPolicyApiResponse = await fetch(
        `${user.base_url}/subuser/package_insert_update_select.php?fun=deactiveterms`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const postPolicyApiDetails = await postPolicyApiResponse.json();
      handleGetDeals(tblLeadId);
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  return (
    <div className="flx100 pl12 pr12">
      <div className="fs14 labels-heading fw4 mb16 c2">
        Ensure clarity and transparency in your Itinerary by seamlessly
        integrating policies and terms.
      </div>
      {policies.map((item) => (
        <div className="package-policies" key={item.id}>
          <div className="mb16">
            <div className="title-policies v-center jcsb">
              <h3 className="ls2 fs16 mb12 v-center fc1 fw5">{item.title}</h3>
              <div className="df jce">
                <FaPencilAlt
                  className="icon edit-icon mr8 cp"
                  onClick={() => handleEditButtonClick(item.id)}
                />
                <FaTrash
                  className="icon edit-icon cp"
                  onClick={() => handleDeletePolicy(item.id)}
                />
              </div>
            </div>
            <div
              className="policies-description fs14"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </div>
        </div>
      ))}

      <form className=" br16 " ref={formRef}>
        <div className="bg5 br16">
          {/* <p className="box-center fw6 fs18 ls1 mt8 fc1">
            {isEditing ? "EDIT PACKAGE POLICY" : "ADD PACKAGE POLICIES"}
          </p> */}

          <div className="form-group-settings req-star">
            <label className="labels-heading fw6 fs14">
              Title<span>*</span>
            </label>
            <input
              type="text"
              id="currency"
              placeholder="Enter Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="req-star">
            <label className="labels-heading fw6 fs14">
              Description<span>*</span>
            </label>
            <ReactQuill
              theme="snow"
              modules={{
                toolbar: [
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
              formats={[
                "header",
                "height",
                "bold",
                "italic",
                "underline",
                "list",
                "color",
                "bullet",
                "indent",
                "link",
                "image",
                "align",
                "size",
              ]}
              placeholder="write your description ...."
              onChange={setDescription}
              style={{ height: "150px", marginTop: "8px" }}
              ref={quillRef}
              value={description}
            />
          </div>

          <div className="df aic jcsb mt36">
            {renderBackButton()}
            <button
              className={`term-button pt12 pb12 pl16 pr16 br24 bg1 born cp fc3 v-center fs14`}
              onClick={handleAddButtonClick}
            >
              {isEditing ? (
                <HiSave className="mr8 fs18 fc3" />
              ) : (
                <AiFillPlusCircle className="mr8 fs18" />
              )}
              {isEditing ? "Update Policies" : "Add Policies"}
            </button>
            <p></p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TextEditor;
