import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./SearchDropdown.css";
import optionList from "../data/ThemeOptions.js";

export const SearchTheme = ({ onSelectionChange, tourDestination }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Set selectedOptions when tourDestination is updated
  useEffect(() => {
    if (tourDestination && tourDestination.length > 0) {
      const selectedValues = optionList.filter(option => tourDestination.includes(option.value));
      setSelectedOptions(selectedValues);
    }
  }, [tourDestination]);

  function handleSelect(selectedValues) {
    setSelectedOptions(selectedValues);
    const selectedDestinations = selectedValues.map((option) => option.value);
    onSelectionChange(selectedDestinations);
  }

  return (
    <div className="search-slect-dropdown">
      <div className="pr">
        <Select
          options={optionList}
          placeholder="Select Destinations"
          value={selectedOptions}
          onChange={handleSelect}
          isSearchable={true}
          isMulti
        />
      </div>
    </div>
  );
};
