import React, { useState, useEffect } from "react";
import "./PriceRangeSlider.css";
import Tooltip from "../Tooltip";

const PriceRangeSlider = ({
  min,
  max,
  onRangeChange,
  currencyType,
  disabled,
}) => {
  const [values, setValues] = useState([min, max]);

  useEffect(() => {
    setValues([min, max]);
  }, [min, max]);

  const handleMinChange = (e) => {
    const minValue = parseInt(e.target.value, 10);
    if (minValue <= values[1]) {
      setValues([minValue, values[1]]);
      onRangeChange([minValue, values[1]]);
    }
  };

  const handleMaxChange = (e) => {
    const maxValue = parseInt(e.target.value, 10);
    if (maxValue >= values[0]) {
      setValues([values[0], maxValue]);
      onRangeChange([values[0], maxValue]);
    }
  };

  return (
    <div className="range w100">
      <div className="range-slider">
        <span className="range-selected" />
      </div>
      {disabled && (
        <Tooltip title="Upgrade your plan to use this filter">
          <div className="range-input">
            <input
              type="range"
              min={min}
              max={max}
              value={values[0]}
              onChange={handleMinChange}
              disabled={disabled}
              title={disabled ? "Upgrade your plan to use this filter" : ""}
            />
            <input
              type="range"
              min={min}
              max={max}
              value={values[1]}
              onChange={handleMaxChange}
              disabled={disabled}
              title={disabled ? "Upgrade your plan to use this filter" : ""}
            />
          </div>
        </Tooltip>
      )}
      {!disabled && (
        <div className="range-input">
          <input
            type="range"
            min={min}
            max={max}
            value={values[0]}
            onChange={handleMinChange}
            disabled={disabled}
            title={disabled ? "Upgrade your plan to use this filter" : ""}
          />
          <input
            type="range"
            min={min}
            max={max}
            value={values[1]}
            onChange={handleMaxChange}
            disabled={disabled}
            title={disabled ? "Upgrade your plan to use this filter" : ""}
          />
        </div>
      )}
      <div className="slider-labels mt24">
        {currencyType == "₹" ? (
          <>
            <p>
              Min:{currencyType}
              {values[0]}
              <span className="ml4 fs12"></span>
            </p>
            <p>
              Max:{currencyType}
              {values[1]}
              <span className="ml4 fs12"></span>
            </p>
          </>
        ) : (
          <>
            <p>
              Min:{values[0]}
              <span className="ml4 fs12">{currencyType}</span>
            </p>
            <p>
              Max:{values[1]}
              <span className="ml4 fs12">{currencyType}</span>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default PriceRangeSlider;
