import { useEffect } from "react";
import ReactGA from 'react-ga';
import {BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
const TRACKING_ID = "G-EH3W0WJ4YX"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export const useTitle = (title) => {

    useEffect(() => {
        document.title = `${title}`;
	 /* initGA();

    // Track a page view
    window.gtag('config', 'G-EH3W0WJ4YX', {
      page_path: window.location.pathname,
    });*/
    ReactGA.pageview(window.location.pathname + window.location.search);


    }, [title]);

const initGA = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    window.gtag = gtag;

    gtag('js', new Date());
    gtag('config', 'G-EH3W0WJ4YX');
  };


    return null;
}
