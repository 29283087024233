// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.destination-search {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  
  .destination-search-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
  }
  .selected-suggestion-chip {
    background-color: #594cda;
    color: #fff;
    padding: 5px 10px;
    border-radius: 15px;
    margin: 5px;
    display: flex;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
  }
  
  .remove-suggestion-icon {
    cursor: pointer;
    margin-left: 5px;
  }
  .dsuggestions{
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 150px;
    overflow-y: auto;
    padding: 8px;
    font-size: 14px;
    cursor:pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/DestinationSearch.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,aAAa;EACf;EACA;IACE,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,sBAAsB;IACtB,gBAAgB;IAChB,0BAA0B;IAC1B,sBAAsB;IACtB,wCAAwC;IACxC,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,cAAc;EAChB","sourcesContent":[".destination-search {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n    margin-top: 12px;\r\n    margin-bottom: 12px;\r\n  }\r\n  \r\n  .destination-search-input {\r\n    padding: 8px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 5px;\r\n    font-size: 16px;\r\n    outline: none;\r\n  }\r\n  .selected-suggestion-chip {\r\n    background-color: #594cda;\r\n    color: #fff;\r\n    padding: 5px 10px;\r\n    border-radius: 15px;\r\n    margin: 5px;\r\n    display: flex;\r\n    align-items: center;\r\n    font-size: 13px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .remove-suggestion-icon {\r\n    cursor: pointer;\r\n    margin-left: 5px;\r\n  }\r\n  .dsuggestions{\r\n    border: 1px solid #ccc;\r\n    border-top: none;\r\n    border-radius: 0 0 4px 4px;\r\n    background-color: #fff;\r\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n    max-height: 150px;\r\n    overflow-y: auto;\r\n    padding: 8px;\r\n    font-size: 14px;\r\n    cursor:pointer;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
