// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxbd {
    background: #fff;
    border: 1px solid #D4D9E6;
    border-radius: 4px;
    flex: 0 0 49%;
    max-width: 49%;
    margin-bottom: 24px;
}
@media only screen and (max-width: 480px) {
    .boxbd{
      flex:0 0 100%;
      max-width: 100%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Box.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,cAAc;IACd,mBAAmB;AACvB;AACA;IACI;MACE,aAAa;MACb,eAAe;IACjB;EACF","sourcesContent":[".boxbd {\r\n    background: #fff;\r\n    border: 1px solid #D4D9E6;\r\n    border-radius: 4px;\r\n    flex: 0 0 49%;\r\n    max-width: 49%;\r\n    margin-bottom: 24px;\r\n}\r\n@media only screen and (max-width: 480px) {\r\n    .boxbd{\r\n      flex:0 0 100%;\r\n      max-width: 100%;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
