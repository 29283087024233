import React, { useState } from "react";
import CustomDropdown from "./CustomDropdown";
import "../components/Filter.css";
import moment from "moment/moment";
import MultiSelect from "./MultiSelect";
import DatePicker from "react-datepicker";
import Dropdown from "../components/TeamDropdown";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "./Checkbox";

const Filter = ({
  filters,
  agents,
  showCheckboxes,
  OnclickFilter,
  domClick,
}) => {
  const [selectedFilter, setSelectedFilter] = useState(filters[0]);
  const [filterValue, setFilterValue] = useState("");
  const [selectedAgent, setSelectedAgent] = useState(agents[0]);
  const [isFilter, setIsFilter] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const leadsVia = [
    { label: "Select Lead Via", value: "" },
    { label: "Package", value: "Package" },
    { label: "Autobuy", value: "Autobuy" },
    { label: "Manual", value: "Manully" },
  ];
  const timeOptions = [
    { label: "Select Date", value: "" },
    { label: "Today", value: moment().format("YYYY-MM-DD") },
    {
      label: "Yesterday",
      value: moment().subtract(1, "days").format("YYYY-MM-DD"),
    },
    {
      label: "Last 7 Days",
      value: moment().subtract(7, "days").format("YYYY-MM-DD"),
    },
    {
      label: "Last 30 Days",
      value: moment().subtract(30, "days").format("YYYY-MM-DD"),
    },
    {
      label: "This Month",
      value: moment().startOf("month").format("YYYY-MM-DD"),
    },
    {
      label: "Custom",
      value: dateRange,
    },
  ];
  const dayTypeOptions = [
    { label: "Day", value: "day" },
    { label: "Month", value: "month" },
  ];
  const [selectedDayType, setSelectedDayType] = useState("day");
  const travelDestination = ["Goa", "Mumbai", "Punjab", "Shimla", "Panji"];
  const travelDuration = ["1 Days", "2 Days", "3 Days", "4 Days", "5 Days"];
  const [selectedLeadVia, setSelectedLeadVia] = useState(
    leadsVia.find((option) => option.value === "Package")
  );
  const [selectedLeadType, setSelectedLeadType] = useState("Domestic");
  const leadTypeOptions = ["Domestic", "International"];
  const [selectedLeadReceivedDateLabel, setSelectedLeadReceivedDateLabel] =
    useState(timeOptions[0]);

  const [selectedLeadTravelDateLabel, setSelectedLeadTravelDateLabel] =
    useState(timeOptions[0]);
  const [selectedLeadReceivedDate, setSelectedLeadReceivedDate] =
    useState(null);
  const [selectedLeadTravelDate, setSelectedLeadTravelDate] = useState(null);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState("Hot");
  const [selectedPackageType, setSelectedPackageType] = useState("Customized");
  const [packageStatus, setPackageStatus] = useState("Live");
  const [packageVisibility, setPackageVisibility] = useState("Public");
  const [status, setStatus] = useState("In-progress");

  const leadStatusOptions = [
    { label: "Hot", value: "Hot" },
    { label: "Follow-up", value: "Follow-up" },
    { label: "Booked", value: "Booked" },
  ];

  const packageTypeOptions = [
    { label: "Customized", value: "Customized" },
    { label: "Fixed", value: "Fixed" },
  ];
  const packageStatusOptions = [
    { label: "Live", value: "Live" },
    { label: "Reject", value: "Reject" },
    { label: "Draft", value: "Draft" },
  ];
  const packageVisibilityOptions = [
    { label: "Public", value: "Public" },
    { label: "Private", value: "Private" },
  ];
  const packageListstatus = [
    { label: "In-progress", value: "In-progress" },
    { label: "Progress", value: "Progress" },
  ];

  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);

    setFilterValue("");
  };

  const handleGoButtonClick = () => {
    if (selectedFilter.value === "leadReceivedDate") {
      if (filterValue.label === "Custom") {
        // console.log("leadReceivedDate custom", dateRange);
        // OnclickFilter(selectedFilter.label, dateRange);
        OnclickFilter(selectedFilter.label, [
          moment(dateRange[0]).format("YYYY-MM-DD"),
          moment(dateRange[1]).format("YYYY-MM-DD"),
        ]);
      } else {
        OnclickFilter(selectedFilter.label, [filterValue.value]);
      }
    } else {
      // console.log("selectedFilter", selectedFilter);
      // console.log("filterValue", filterValue);
      OnclickFilter(selectedFilter.label, filterValue.value);
    }
  };

  const handleAgentSelect = (selectedOption) => {
    setFilterValue(selectedOption);
    setSelectedAgent(selectedOption);
    setIsFilter(true);
  };

  const handleLeadViaSelect = (selectedOption) => {
    setFilterValue(selectedOption);
    setSelectedLeadVia(selectedOption);
    setIsFilter(true);
  };

  const handleMultiSelectChange = (selectedOption) => {
    setFilterValue(selectedOption);
    setIsFilter(true);
  };

  const handleLeadReceivedDateChange = (date) => {
    setSelectedLeadReceivedDateLabel(date);
    setFilterValue(date);
    setSelectedLeadReceivedDate(date);
    setIsFilter(true);
  };

  const handleLeadTravelDateChange = (date) => {
    setSelectedLeadTravelDateLabel(date);
    setSelectedLeadTravelDate(date);
    setFilterValue(date);
    setIsFilter(true);
  };

  const handleLeadTypeSelect = (selectedOption) => {
    setSelectedLeadType(selectedOption.value);
    setIsFilter(true);
  };

  const handleDayTypeSelect = (selectedOption) => {
    setFilterValue(selectedOption);
    setSelectedDayType(selectedOption.value);
    setIsFilter(true);
  };

  const handleLeadStatusSelect = (selectedOption) => {
    setFilterValue(selectedOption);
    setIsFilter(true);
  };

  const handlePackageTypeSelect = (selectedOption) => {
    setSelectedPackageType(selectedOption.value);
  };
  const handlePackageVisibility = (selectedOption) => {
    setPackageVisibility(selectedOption.value);
  };
  const handlePackageStatus = (selectedOption) => {
    setPackageStatus(selectedOption.value);
  };

  const optionLabels = ["Outbound", "Domestic", "Inbound"];
  const [checkboxValues, setCheckboxValues] = useState({});

  const handleCheckboxChange = (value) => {
    // Used a callback function to log the updated state synchronously
    setCheckboxValues((prevValues) => {
      const updatedValues = { ...prevValues, [value]: !prevValues[value] };
      return updatedValues;
    });
  };

  const [selectedDomStatus, setSelectedDomStatus] = useState("Month");

  const domOptions = [
    { ID: "day", ROLE_NAME: "Day" },
    { ID: "month", ROLE_NAME: "Month" },
  ];

  const handleDomChange = (status, id) => {
    domClick(id);
    setSelectedDomStatus(status);
  };

  const handleClearFilter = () => {
    setSelectedFilter(filters[0]);
    setFilterValue("");
    setSelectedAgent(agents[0]);
    setIsFilter(false);
    setSelectedLeadReceivedDateLabel(timeOptions[0]);
    setDateRange([null, null]);
    OnclickFilter(selectedFilter.label, "");
  };
  const handleDateRangeChange = (update) => {
    // console.log("Selected Date Range:", update);

    // let formattedStartDate = null;
    // let formattedEndDate = null;

    // if (update[0] !== null) {
    //   formattedStartDate = moment(update[0]).format("YYYY-MM-DD");
    // }

    // if (update[1] !== null) {
    //   formattedEndDate = moment(update[1]).format("YYYY-MM-DD");
    // }

    // const updatedDateRange = [formattedStartDate, formattedEndDate];

    setDateRange(update);
  };
  return (
    <div className="filter-container brd-b1 fdc">
      <div className="v-center fww">
        <Dropdown
          label="Status"
          options={domOptions}
          selectedValue={selectedDomStatus}
          onValueChange={handleDomChange}
        />

        <CustomDropdown
          options={filters}
          selectedOption={selectedFilter}
          onSelect={handleFilterChange}
        />

        {selectedFilter.value === "email" ||
        selectedFilter.value === "phone" ||
        selectedFilter.value === "name" ||
        selectedFilter.value === "leadId" ||
        selectedFilter.value === "id" ? (
          <div className="input-container">
            <input
              className="input-field"
              type="text"
              placeholder={`Enter ${
                selectedFilter.value === "leadId" ? "Lead " : ""
              }${selectedFilter.value}...`}
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
            <button className="go-button fs14" onClick={handleGoButtonClick}>
              GO
            </button>
          </div>
        ) : null}

        {selectedFilter.value === "destination" ? (
          <>
            <div>
              <MultiSelect
                travelDestination={travelDestination}
                placeholder={"Destination"}
                onSelect={handleMultiSelectChange}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
          </>
        ) : null}
        {selectedFilter.value === "travelDuration" ? (
          <>
            <div>
              <MultiSelect
                travelDestination={travelDuration}
                placeholder={"Duration"}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
          </>
        ) : null}
        {selectedFilter.value === "leadReceivedDate" ? (
          <div className="report-date df">
            {selectedLeadReceivedDateLabel.label === "Custom" ? (
              <div className="df aic ">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleDateRangeChange}
                  isClearable={true}
                  placeholderText="---Select Date Range---"
                />
                <button
                  className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                  onClick={handleGoButtonClick}
                >
                  GO
                </button>
              </div>
            ) : (
              <div className="df">
                <CustomDropdown
                  options={timeOptions}
                  selectedOption={selectedLeadReceivedDateLabel}
                  //onSelect={(option) => setSelectedLeadReceivedDateLabel(option)}
                  onSelect={handleLeadReceivedDateChange}
                />
                <button
                  className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                  onClick={handleGoButtonClick}
                >
                  GO
                </button>
              </div>
            )}
          </div>
        ) : null}
        {selectedFilter.value === "leadTravelDate" ? (
          <div className="ml24 calendar-date">
            {selectedLeadTravelDateLabel.value === "custom" ? (
              <>
                <DatePicker
                  dateFormat="dd-MMM-yyyy"
                  selected={selectedLeadTravelDate}
                  onChange={handleLeadTravelDateChange}
                  placeholderText="---- Select Date ----"
                />
                <button
                  className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                  onClick={handleGoButtonClick}
                >
                  GO
                </button>
              </>
            ) : (
              <div className="df">
                <CustomDropdown
                  options={timeOptions}
                  selectedOption={selectedLeadTravelDateLabel}
                  // onSelect={(option) => setSelectedLeadTravelDateLabel(option)}
                  onSelect={handleLeadTravelDateChange}
                />
                <button
                  className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
                  onClick={handleGoButtonClick}
                >
                  GO
                </button>
              </div>
            )}
          </div>
        ) : null}

        {selectedFilter.value === "leadVia" ? (
          <>
            <div className="ml24 df aic jcc">
              <CustomDropdown
                options={leadsVia}
                selectedOption={selectedLeadVia}
                onSelect={handleLeadViaSelect}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
          </>
        ) : null}
        {selectedFilter.value === "agent" ? (
          <>
            <div className="select-agent mr8">
              <CustomDropdown
                options={agents}
                selectedOption={selectedAgent}
                onSelect={handleAgentSelect}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
          </>
        ) : null}
        {selectedFilter.value === "leadType" ? (
          <>
            <div className="ml24 df aic">
              <CustomDropdown
                options={leadTypeOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
                selectedOption={{
                  label: selectedLeadType,
                  value: selectedLeadType,
                }}
                onSelect={handleLeadTypeSelect} // Update the selected lead type
              />
            </div>

            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
          </>
        ) : null}
        {selectedFilter.value === "dayType" ? (
          <>
            <div className="ml24 df aic">
              <CustomDropdown
                options={dayTypeOptions.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                selectedOption={{
                  label: selectedDayType,
                  value: selectedDayType,
                }}
                onSelect={handleDayTypeSelect}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
          </>
        ) : null}
        {selectedFilter.value === "leadStatus" ? (
          <>
            <div className="ml24 df aic">
              <CustomDropdown
                options={leadStatusOptions.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                selectedOption={{
                  label: selectedLeadStatus,
                  value: selectedLeadStatus,
                }}
                onSelect={handleLeadStatusSelect}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
          </>
        ) : null}
        {selectedFilter.value === "type" ? (
          <>
            <div className="ml24 df aic">
              <CustomDropdown
                options={packageTypeOptions.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                selectedOption={{
                  label: selectedPackageType,
                  value: selectedPackageType,
                }}
                onSelect={handlePackageTypeSelect}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
          </>
        ) : null}
        {selectedFilter.value === "packageStatus" ? (
          <>
            <div className="ml24 df aic">
              <CustomDropdown
                options={packageStatusOptions.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                selectedOption={{
                  label: packageStatus,
                  value: packageStatus,
                }}
                onSelect={handlePackageStatus}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
          </>
        ) : null}
        {selectedFilter.value === "packageVisibility" ? (
          <>
            <div className="ml24 df aic">
              <CustomDropdown
                options={packageVisibilityOptions.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                selectedOption={{
                  label: packageVisibility,
                  value: packageVisibility,
                }}
                onSelect={handlePackageVisibility}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
          </>
        ) : null}
        {selectedFilter.value === "status" ? (
          <>
            <div className="ml24 df aic">
              <CustomDropdown
                options={packageListstatus.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                selectedOption={{
                  label: status,
                  value: status,
                }}
                onSelect={handlePackageVisibility}
              />
            </div>
            <button
              className="go-filter bg1 fc3 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
              onClick={handleGoButtonClick}
            >
              GO
            </button>
          </>
        ) : null}

        {isFilter && (
          <button
            className="clear-filter bg19 fc9 fs12 pl12 pr12 pt8 pb8 cp br16 ls1"
            onClick={handleClearFilter}
          >
            Clear
          </button>
        )}
      </div>

      {showCheckboxes && (
        <div className="df aic pl8 pr8 pt16">
          {optionLabels.map((label, index) => (
            <Checkbox
              key={label}
              label={label}
              checked={checkboxValues[label]}
              onChange={() => handleCheckboxChange(label)}
              value={label}
              className={index > 0 ? "ml16" : ""}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Filter;
