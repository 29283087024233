import React from 'react';
import "../components/SucessMessage.css";

const SucessMessage = ({ successType,msgColor, message,onClose }) => {
  
  return (
    <div className={`sucess-message ${successType ? "show" : ""}`}>
      <div className={`fw5 fs16 ${msgColor ? msgColor : "sucess"}`}>{message}</div>
      <div className='fw6 fs16 clear' onClick={onClose}>Clear</div>
    </div>
  );
};

export default SucessMessage;
