import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./SignupForm.css";
import logoImage from "../assets/hellotravel-logo.png";
import { Link } from "react-router-dom";
import google from "../assets/google.png";
import { FaAngleDown, FaEye, FaEyeSlash } from "react-icons/fa";
import ImageSlider from "../components/ImageSlider.js";
import { useDispatch } from "react-redux";
import { setUser } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useSelector } from "react-redux";
import countryCodes from "../data/countryCodes";
import SmallLoader from "../components/SmallLoader.js";
import { useTitle } from "../hooks/useTitle";
import trackEvent from "../utils/gaTracker";
import { useParams } from "react-router-dom";

function SignupForm() {
  const { rmid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const countryRef = useRef(null);
  const serviceRef = useRef(null);
  const user = useSelector((state) => state.auth);
  useTitle(
    "Signup - Get Verified Travel Leads for Free, Promote your Travel Business - HelloTravel"
  );
  var rmcare = rmid!=undefined ? rmid:'';
  let state = {};
  if (location.state !== null) {
    state = location.state;
  }

  const [email, setEmail] = useState(state.email || "");

  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState(state.name || "");
  const [mobileNumber, setMobileNumber] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [serviceOffered, setServiceOffered] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [showServiceDropdown, setShowServiceDropdown] = useState(false);
  const [countryCodeDropdown, setCountryCodeDropdown] = useState(false);
  const [countryCode, setCountryCode] = useState("91");
  //const { itemId} = route.params;
  const [errorStatus, setErrorStatus] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [signupStatus, setSignupStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSignupGoogle, setisSignupGoogle] = useState(
    state.email ? true : false
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    const label = showPassword ? "Hide Password" : "Show Password";
    trackEvent("Signup Page", "click", label);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!showConfirmPassword);
    const label = showConfirmPassword
      ? "Hide Confirmed Password"
      : "Show Confirmed Password";
    trackEvent("Signup Page", "click", label);
  };
  const handleServiceSelect = (selectedService) => {
    setServiceOffered(selectedService);
    setShowServiceDropdown(false);
  };
  const handleCountryCodeSelect = (selectedCountry) => {
    setCountryCode(selectedCountry);
    setCountryCodeDropdown(false);
  };

  function handleCheckLogin(auth_id) {
    axios({
      method: "post",
      url: `${user.base_url}/login.php?fun=getUser`,
      headers: {},
      data: {
        auth_id: auth_id,
      },
    }).then(function (response) {
      if (response) {
        if (response.data.login.status === "1") {
          navigate("/");
        }
      }
    });
  }

  useEffect(() => {
    if (user.auth_id !== null) {
      handleCheckLogin(user.auth_id);
    }

    const compName = document.getElementById("companyname");
    if (compName) {
      compName.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSignUp(e);
    }
  };
  function validateName(name) {
    // Regular expression pattern: allows only letters (a-z), spaces, dots, and the total length should be at most 30 characters.
    const nameRegex = /^[a-zA-Z .]{1,30}$/;

    // Test the name against the regular expression
    const isValid = nameRegex.test(name);
    return isValid;
  }

  const serviceOptions = [
    "Travel Agency",
    "Hotel",
    "DMC",
    /*"Taxi",
    "Ticketing",
    "Forex",
    "Visa",
    "Others",*/
  ];

  const countryCodeOptions = countryCodes.sort(
    (a, b) => parseInt(a, 10) - parseInt(b, 10)
  );
  const signupValidate = (
    email,
    contactName,
    companyName,
    mobileNumber,
    pinCode,
    password,
    confirmPassword,
    serviceOffered
  ) => {
    const errors = {};
    errors.email = [];
    errors.contactName = [];
    errors.companyName = [];
    errors.mobileNumber = [];
    errors.pinCode = [];
    errors.password = [];
    errors.confirmPassword = [];
    errors.serviceOffered = [];
    errors.signupmsgError = [];
    errors.signupmsgSuccess = [];
    const patternpassword = /^([A-Za-z\d\@\$\!\%\*\?]{8,})$/;
    if (email.length === 0) {
      errors.email.push("Email is empty!");
      setErrorStatus(true);
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(email)) {
        errors.email.push("Invalid email: " + email);
        setErrorStatus(true);
      }
    }
    var isvalid = validateName(contactName);
    if (!isvalid) {
      errors.contactName.push("Contact name max 30 chars, a-z, space, dot!");
      setErrorStatus(true);
    } else {
      setErrorStatus(false);
    }

    if (companyName.length === 0) {
      errors.companyName.push("Company Name is empty!");
      setErrorStatus(true);
    }

    if (mobileNumber.length === 0) {
      errors.mobileNumber.push("Mobile No is empty!");
      setErrorStatus(true);
    } else {
      if (countryCode === "91") {
        const mobileRegex = /^[6789]\d{9}$/i;

        if (!mobileRegex.test(mobileNumber)) {
          errors.mobileNumber.push("Invalid mobile: " + mobileNumber);
          setErrorStatus(true);
        }
      } else {
        const mobileRegex = /^\d{5,20}$/i;

        if (!mobileRegex.test(mobileNumber)) {
          errors.mobileNumber.push("Invalid mobile: " + mobileNumber);
          setErrorStatus(true);
        }
      }
    }

    if (pinCode.length === 0) {
      errors.pinCode.push("Pincode is empty!");
      setErrorStatus(true);
    } else {
      if (pinCode.length < 4 || pinCode.length > 12) {
        errors.pinCode.push("Invalid Pincode: " + pinCode);
        setErrorStatus(true);
      }
    }
    const isValid = patternpassword.test(password);
    if (!isValid) {
      errors.password.push(
        "Minimum 8 characters, alphanumeric and symbols (@ $ ! % * ?) allowed!"
      );
      setErrorStatus(true);
    } else {
      setErrorStatus(false);
    }

    if (password !== confirmPassword) {
      errors.confirmPassword.push("Password do not match!");
      setErrorStatus(true);
    } else {
      setErrorStatus(false);
    }

    if (serviceOffered.length === 0) {
      errors.serviceOffered.push("Service Offered is empty!");
      setErrorStatus(true);
    }

    const returnError = {};

    if (
      errors.email.length === 0 &&
      errors.contactName.length === 0 &&
      errors.companyName.length === 0 &&
      errors.mobileNumber.length === 0 &&
      errors.pinCode.length === 0 &&
      errors.password.length === 0 &&
      errors.confirmPassword.length === 0 &&
      errors.serviceOffered.length === 0
    ) {
      returnError.status = false;
      setErrorStatus(false);
    } else {
      returnError.status = true;
    }

    returnError.errors = errors;

    return returnError;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    trackEvent("Signup Page", "click", "Login Here");
    setLoading(true);
    setFormSubmitted(true);
    const signupError = signupValidate(
      email,
      contactName,
      companyName,
      mobileNumber,
      pinCode,
      password,
      confirmPassword,
      serviceOffered
    );

    if (signupError.status === true) {
      setFormErrors(signupError.errors);
      setLoading(false);
      return false;
    } else {
      // Simulate an asynchronous login process
      try {
        const response = await axios.post(`${user.base_url}/signup.php`, {
          email: email,
          contactName: contactName,
          companyName: companyName,
          mobileNumber: mobileNumber,
          pinCode: pinCode,
          countryCode: countryCode,
          password: password,
          confirmPassword: confirmPassword,
          serviceOffered: serviceOffered,
          rmcare:rmcare
        });

        if (response.data.data.status === 1) {
          signupError.errors.signupmsgSuccess.push(response.data.data.msg);
          setFormErrors(signupError.errors);

          // Dispatch the user data to Redux store
          dispatch(setUser(response.data.data.data));
          localStorage.setItem(
            "htloginDetails",
            JSON.stringify(response.data.data.data)
          );
          // Redirect to the desired page after successful login

          if (response.data.data.data.role === "2") {
            navigate("/my-account?tab=lead-management");
          } else {
            navigate("/my-account");
          }
        } else {
          setLoading(false);
          signupError.errors.signupmsgError.push(response.data.data.msg);
          setFormErrors(signupError.errors);
        }
      } catch (error) {
        setLoading(false);
        // Handle API request errors here (e.g., show an error message)
        signupError.errors.signupmsgError.push(
          "Something went Wrong try again later"
        );
        setFormErrors(signupError.errors);
      } finally {
      }
    }
    setLoading(false);
  };

  const [gLoginUser, setGLoginUser] = useState([]);
  const [gLoginProfile, setgLoginProfile] = useState([]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setGLoginUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (countryRef.current && !countryRef.current.contains(event.target)) {
      setCountryCodeDropdown(false);
    }
    if (serviceRef.current && !serviceRef.current.contains(event.target)) {
      setShowServiceDropdown(false);
    }
  };

  useEffect(() => {
    if (gLoginUser) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${gLoginUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${gLoginUser.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setgLoginProfile(res.data);
          createGoogleUser(gLoginUser, res.data);
          setisSignupGoogle(true);
        })
        .catch((err) => console.log(err));
    }
  }, [gLoginUser]);

  // function createGoogleUser(gLoginUser,gLoginProfile){

  const createGoogleUser = async (gLoginUser, gLoginProfile) => {
    if (gLoginProfile) {
      setContactName(gLoginProfile.name);
      setEmail(gLoginProfile.email);
    }
    if (location.state !== null) {
      setContactName(location.state.name);
      setEmail(location.state.email);
    }

    // Simulate an asynchronous login process
    try {
      const responses = await axios.post(
        `${user.base_url}/subuser/google.php?fun=create`,
        {
          userdata: gLoginUser,
          pageType: "login",
          userprofile: gLoginProfile,
        }
      );

      const loginGoogleUser = responses.data;

      if (loginGoogleUser && loginGoogleUser !== "") {
        setSignupStatus(loginGoogleUser.data.status);

        if (loginGoogleUser.data.status === 1) {
          // Dispatch the user data to Redux store
          dispatch(setUser(loginGoogleUser.data.login));

          localStorage.setItem(
            "htloginDetails",
            JSON.stringify(loginGoogleUser.data.login)
          );
          // Redirect to the desired page after successful login

          navigate("/");
        }
      } else {
        // Handle unsuccessful login here (e.g., show an error message)
      }
    } catch (error) {
      // Handle API request errors here (e.g., show an error message)

      console.error("API request failed:", error);
    } finally {
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-left">
        <ImageSlider />
      </div>

      <div className="signup-right">
        <div className="box-center fdc">
          <img src={logoImage} alt="logo" className="" />
          <h3 className="fc15 fw5 fs20 mt12 ls1 box-center fdc">
            Sign Up
            <div className="fs14 fc16 mt12 fw4 ls1">
              Welcome to the HelloTravel family!
            </div>
          </h3>
        </div>

        <form className="mt16 mb16 login-forms">
          <div className="social-form-group box-center">
            {!isSignupGoogle && (
              <button
                className="sign-in-microsoft df aic ls2 cp flx45"
                onClick={(e) => {
                  e.preventDefault();
                  login();
                  trackEvent("Signup Page", "click", "Sign in with Gmail");
                }}
              >
                <img src={google} alt="google" className="ml4 mr8 fc1" />
                Sign in with Google
              </button>
            )}

            {/* <button className="sign-in-google  df aic ls2 cp ml24 ">
              <img src={facebook} alt="google" className="ml4 mr8 fc1" />
              Sign in with Facebook
            </button> */}
          </div>
          {!isSignupGoogle && (
            <div className="hr-line pr mt36 mb24">
              <hr
                style={{
                  height: 1,
                  backgroundColor: "#9fa5bc",
                  border: "none",
                }}
              />
              <span
                className="fc17 tac"
                style={{
                  position: "absolute",
                  top: "-12px",
                  left: 0,
                  width: "100%",
                }}
              >
                <span style={{ background: "#fff", padding: 20 }}>or</span>
              </span>
            </div>
          )}
          <div className="signup-form-row">
            <div className="input-pair">
              <div className="input-item mr8">
                <input
                  type="text"
                  name="contactName"
                  value={gLoginProfile.name ? gLoginProfile.name : contactName}
                  onChange={(e) => setContactName(e.target.value)}
                  placeholder="Contact Name"
                  id="companyname"
                  onKeyDown={handleKeyDown}
                />
                {formSubmitted && (
                  <div className="form-error-messages error mt8 blink-text-normal-error">
                    {Object.keys(formErrors).map((key) => (
                      <div key={key}>
                        {key === "contactName" &&
                          formErrors[key].map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="input-item">
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={gLoginProfile.email ? gLoginProfile.email : email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  autoComplete="email"
                  onKeyDown={handleKeyDown}
                />
                {formSubmitted && (
                  <div className="form-error-messages error mt8 blink-text-normal-error">
                    {Object.keys(formErrors).map((key) => (
                      <div key={key}>
                        {key === "email" &&
                          formErrors[key].map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="signup-form-row">
            <div className="input-pair">
              <div className="input-group df pr input-item fdc mr8">
                <div className="input-group-prepend df country-mobile">
                  <div
                    className="role-dropdown signup-country flx33"
                    ref={countryRef}
                  >
                    <div
                      className="selected-role fs14 h40 country-code"
                      onClick={() =>
                        setCountryCodeDropdown(!countryCodeDropdown)
                      }
                    >
                      {countryCode || "Country Code"}
                      <FaAngleDown className="fc16 fs14" />
                    </div>
                    {countryCodeDropdown && (
                      <ul className="role-options fs14">
                        {countryCodeOptions.map((option) => (
                          <li
                            key={option}
                            onClick={() => handleCountryCodeSelect(option)}
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <input
                    type="number"
                    className="form-control br4"
                    maxLength={15}
                    placeholder="Mobile Number"
                    id="phone"
                    name="phone"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>

                {formSubmitted && (
                  <div className="form-error-messages error mt8 blink-text-normal-error">
                    {Object.keys(formErrors).map((key) => (
                      <div key={key}>
                        {key === "mobileNumber" &&
                          formErrors[key].map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="input-item">
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Company Name"
                  onKeyDown={handleKeyDown}
                />
                {formSubmitted && (
                  <div className="form-error-messages error mt8 blink-text-normal-error">
                    {Object.keys(formErrors).map((key) => (
                      <div key={key}>
                        {key === "companyName" &&
                          formErrors[key].map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="signup-form-row">
            <div className="input-pair">
              <div className="input-item mr8">
                <input
                  type="text"
                  id="pinCode"
                  name="pinCode"
                  value={pinCode}
                  onChange={(e) => setPinCode(e.target.value)}
                  placeholder="Pin code / Zip code"
                  onKeyDown={handleKeyDown}
                />

                {formSubmitted && (
                  <div className="form-error-messages error mt8 blink-text-normal-error">
                    {Object.keys(formErrors).map((key) => (
                      <div key={key}>
                        {key === "pinCode" &&
                          formErrors[key].map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div
                className="role-dropdown input-item service-offered"
                ref={serviceRef}
              >
                <div
                  className="selected-role fs14"
                  onClick={() => setShowServiceDropdown(!showServiceDropdown)}
                >
                  {serviceOffered || "Service Offered"}{" "}
                  <FaAngleDown className="fc16 fs14" />
                </div>
                {showServiceDropdown && (
                  <ul className="role-options fs14">
                    {serviceOptions.map((option) => (
                      <li
                        key={option}
                        onClick={() => handleServiceSelect(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}

                {formSubmitted && (
                  <div className="form-error-messages error mt8 blink-text-normal-error">
                    {Object.keys(formErrors).map((key) => (
                      <div key={key}>
                        {key === "serviceOffered" &&
                          formErrors[key].map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="signup-form-row">
            <div className="input-pair">
              <div className="input-item password-input pr mr8">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  onKeyDown={handleKeyDown}
                />
                <span onClick={togglePasswordVisibility}>
                  {!showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
                {formSubmitted && (
                  <div className="form-error-messages error mt8 blink-text-normal-error">
                    {Object.keys(formErrors).map((key) => (
                      <div key={key}>
                        {key === "password" &&
                          formErrors[key].map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="input-item pr password-input">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  onKeyDown={handleKeyDown}
                />
                <span onClick={toggleConfirmPasswordVisibility}>
                  {!showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
                {formSubmitted && (
                  <div className="form-error-messages error mt8 blink-text-normal-error">
                    {Object.keys(formErrors).map((key) => (
                      <div key={key}>
                        {key === "confirmPassword" &&
                          formErrors[key].map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {formSubmitted && (
            <div className="form-error-messages error mb8 blink-text-success">
              {Object.keys(formErrors).map((key) => (
                <div key={key}>
                  {key === "signupmsgSuccess" &&
                    formErrors[key].map((error, index) => (
                      <p key={index}>{error}</p>
                    ))}
                </div>
              ))}
            </div>
          )}

          {formSubmitted && (
            <div className="form-error-messages error mb8 blink-text-error">
              {Object.keys(formErrors).map((key) => (
                <div key={key}>
                  {key === "signupmsgError" &&
                    formErrors[key].map((error, index) => (
                      <p key={index}>{error}</p>
                    ))}
                </div>
              ))}
            </div>
          )}

          <div className="box-center">
            <button
              className="login-email box-center lh22 ls1 cp flx33"
              onClick={handleSignUp}
            >
              {loading ? <SmallLoader /> : "Sign Up"}
            </button>
          </div>
        </form>
        <div className="df aic jcc ls1 fc16 ais mb16 fs12 ">
          <input
            type="checkbox"
            id="agreeCheckbox"
            checked="true"
            className="mr8"
          />
          <label htmlFor="agreeCheckbox" className="fsi">
            I agree to get Email/SMS from you
          </label>
        </div>

        <div className="box-center ls1 fc16 have-accout">
          Already have an account?{" "}
          <Link to="/login" className="fc1 ml4 fs14 login-here">
            Login Here
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignupForm;
