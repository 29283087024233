// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Checkbox.css */
.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    margin-bottom: 8px;
  }
  
  .checkbox-container input {
    margin-right: 8px;
  }
  
  .checkbox-container input:checked + .checkmark:after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #333;
    border-radius: 50%;
  }
  .checkbox-container:not(:first-child) {
    margin-left: 8px; 
  }`, "",{"version":3,"sources":["webpack://./src/components/Checkbox.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;EACA;IACE,gBAAgB;EAClB","sourcesContent":["/* Checkbox.css */\r\n.checkbox-container {\r\n    display: flex;\r\n    align-items: center;\r\n    cursor: pointer;\r\n    user-select: none;\r\n    margin-bottom: 8px;\r\n  }\r\n  \r\n  .checkbox-container input {\r\n    margin-right: 8px;\r\n  }\r\n  \r\n  .checkbox-container input:checked + .checkmark:after {\r\n    content: \"\";\r\n    width: 10px;\r\n    height: 10px;\r\n    background-color: #333;\r\n    border-radius: 50%;\r\n  }\r\n  .checkbox-container:not(:first-child) {\r\n    margin-left: 8px; \r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
