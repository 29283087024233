// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-gallery {
    justify-content: flex-start; 
    align-items: stretch; 
    border: 1px solid #e4e4e4;
  }
  
  .media-card {
    border: 1px solid #e4e4e4;
    margin: 10px;
    padding: 10px;
    width: 200px;
  }
  
  .image-card img {
    max-width: 100%;
    max-height: 100px;
  }
  
  .video-card video {
    max-width: 100%;
    max-height: 100px;
  }
  
  .remove-icon {
    top: 2px;
    right: 4px;
    color: #d50707;
  }
  
  .media-upload-button {
    padding: 32px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    transition: background-color 0.2s;
    margin: 10px; 
    width: 200px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    /* height: 100px; */
  }
  
  
.media-upload-button:hover {
    background-color: #e0e0e0;
  }
  .media-card-container {
    justify-content: flex-start;
    align-items: stretch; 
  }`, "",{"version":3,"sources":["webpack://./src/components/ImageGallery.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,oBAAoB;IACpB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,YAAY;EACd;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,QAAQ;IACR,UAAU;IACV,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,iCAAiC;IACjC,YAAY;IACZ,YAAY;IACZ,wCAAwC;IACxC,mBAAmB;EACrB;;;AAGF;IACI,yBAAyB;EAC3B;EACA;IACE,2BAA2B;IAC3B,oBAAoB;EACtB","sourcesContent":[".image-gallery {\r\n    justify-content: flex-start; \r\n    align-items: stretch; \r\n    border: 1px solid #e4e4e4;\r\n  }\r\n  \r\n  .media-card {\r\n    border: 1px solid #e4e4e4;\r\n    margin: 10px;\r\n    padding: 10px;\r\n    width: 200px;\r\n  }\r\n  \r\n  .image-card img {\r\n    max-width: 100%;\r\n    max-height: 100px;\r\n  }\r\n  \r\n  .video-card video {\r\n    max-width: 100%;\r\n    max-height: 100px;\r\n  }\r\n  \r\n  .remove-icon {\r\n    top: 2px;\r\n    right: 4px;\r\n    color: #d50707;\r\n  }\r\n  \r\n  .media-upload-button {\r\n    padding: 32px;\r\n    border: 1px solid #ccc;\r\n    background-color: #f0f0f0;\r\n    transition: background-color 0.2s;\r\n    margin: 10px; \r\n    width: 200px; \r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); \r\n    /* height: 100px; */\r\n  }\r\n  \r\n  \r\n.media-upload-button:hover {\r\n    background-color: #e0e0e0;\r\n  }\r\n  .media-card-container {\r\n    justify-content: flex-start;\r\n    align-items: stretch; \r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
