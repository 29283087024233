import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import ImageUpload from "../components/ImageUpload";
import "../pages/Profile.css";
import { FaAngleDown } from "react-icons/fa";
import ImageGallery from "../components/ImageGallery";
import Tnc from "../components/Tnc";
import { BsTrash } from "react-icons/bs";
import { FaPencilAlt } from "react-icons/fa";
import MultiSelect from "../components/MultiSelect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTitle } from "../hooks/useTitle";
import trackEvent from "../utils/gaTracker";

const Profile = ({ handleSelectTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useTitle("Company Profile -  Travel Agents CRM - Hellotravel");

  const user = useSelector((state) => state.auth);

  const [authId, setAuthID] = useState(user.auth_id);
  const [baseUrl, setBaseUrl] = useState(user.base_url);
  const [siteUrl, setSiteUrl] = useState(user.site_url);
  const [folder, setFolder] = useState("");

  const [hiwEditStatus, setHiwEditStatus] = useState(false);
  const [editWorkId, setEditWorkId] = useState("");
  const [selectedWorkDetailIndex, setSelectedWorkDetailIndex] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [errorStatus, setErrorStatus] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [companyImg, setCompanyImg] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyStatus, setCompanyStatus] = useState("");
  const [companyStatusVal, setCompanyStatusVal] = useState("");
  const [pincode, setPincode] = useState("");
  const [numberStaff, setNumberStaff] = useState("");
  const [numberStaffVal, setNumberStaffVal] = useState("");
  const [aboutCompany, setAboutCompany] = useState("");
  const [showCompanyStatusDropdown, setShowCompanyStatusDropdown] =
    useState(false);
  const [showStaffDropdown, setShowStaffDropdown] = useState(false);
  // how it works state
  const [workTitle, setWorkTitle] = useState("");
  const [workDescription, setWorkDescription] = useState("");
  const [workImage, setWorkImage] = useState("");
  const [workDetails, setWorkDetails] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);

  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const [media, setMedia] = useState([]);
  const [mediaFile, setMediaFile] = useState([]);
  const [kycTab, setKycTab] = useState(false);
  const [serviceOfferedVal, setServiceOfferedVal] = useState([]);
  const [docstatus, setDocstatus] = useState("0");

  const [uploadImgLocal, setUploadImgLocal] = useState("");

  const companyAddressRef = useRef(null);
  const companyCityRef = useRef(null);
  const pincodeRef = useRef(null);
  const companyStatusValRef = useRef(null);
  const companyNameRef = useRef(null);
  const staffRef = useRef(null);

  const handleCompanyStatusSelect = (selectedCompanyStatus, value) => {
    setCompanyStatus(value);
    setCompanyStatusVal(selectedCompanyStatus);
    setShowCompanyStatusDropdown(false);
  };

  const handleStaffSelect = (selectedStaff, value) => {
    setNumberStaffVal(selectedStaff);
    setNumberStaff(value);
    setShowStaffDropdown(false);
  };

  const companyStatusOptions = {
    1: "Sole Proprietorship",
    2: "Partnership",
    3: "LLP",
    4: "Private Limited",
    5: "Public Limited",
  };

  const staffOptions = { 5: "1-5", 15: "6-15", 50: "16-50", 51: "50+" };

  const serviceofferedOptions = [
    "B2B Services",
    "Car Rental",
    "Coach Rental",
    "Charted Private Planes",
    "DMC",
    "Food and Meal Plans",
    "Forex Services",
    "Full Service agency",
    "Guides",
    "Hotel & Lodging",
    "Insurance",
    "Local Transportation",
    "Logistic Services",
    "MICE Services",
    "Package Booking",
    "Resort Booking",
    "Ticketing",
    "Villa Rentals",
    "Visa and Passport",
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        companyStatusValRef.current &&
        !companyStatusValRef.current.contains(event.target)
      ) {
        setShowCompanyStatusDropdown(false);
      }

      if (staffRef.current && !staffRef.current.contains(event.target)) {
        setShowStaffDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleAddClick = () => {
    trackEvent("Profile Page", "click", "How to Works - ADD");
    if (workDetails.length >= 3) {
      toast.error("Limit: 3 Steps Maximum.");
      return;
    }
    if (workDescription === "" || workTitle === "") {
      toast.error("Work Title and Work Description is empty!");
      return;
    }
    if (workImage === "") {
      toast.error("Please upload the image");
      return;
    }
    const newWorkDetail = {
      id: workDetails.length,
      title: workTitle,
      description: workDescription,
      image: workImage,
    };

    howItWorks(newWorkDetail);
    setWorkDetails([...workDetails, newWorkDetail]);
    setWorkTitle("");
    setWorkDescription("");
    setWorkImage("");
    setUploadImgLocal("");

    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };
  const handleEditClick = (index, detail) => {
    // console.log("detail edit", detail);
    // console.log("workDetails[index]", workDetails[index]);
    setHiwEditStatus(true);
    trackEvent("Profile Page", "click", "How to Works - Edit");

    // setWorkTitle(detail.title);
    // setWorkDescription(detail.description);
    // setWorkImage(detail.image.url);
    const editedWorkDetail = workDetails[index];
    // console.log("editedWorkDetail.image", editedWorkDetail.image.url);
    setEditWorkId(index);
    setWorkTitle(detail.title);
    setWorkDescription(detail.description);
    setWorkImage(detail.image.url);
    setFolder("fcphiwimg/");
    setSelectedWorkDetailIndex(index);

    // const editedWorkDetail = workDetails[index];

    // setWorkTitle(editedWorkDetail.title);
    // setWorkDescription(editedWorkDetail.description);
    // setWorkImage(editedWorkDetail.image);

    // Set the image for editing
    // const updatedWorkDetails = [...workDetails];
    // updatedWorkDetails.splice(index, 1);
    // setWorkDetails(updatedWorkDetails);
    // setCurrentStep(currentStep - 1); // Update the current step
  };

  const handleUpdateClick = () => {
    trackEvent("Profile Page", "click", "How to Works - Update");
    const updatedWorkDetails = [...workDetails];
    // console.log("@@@@ updatedWorkDetails", updatedWorkDetails);
    if (
      selectedWorkDetailIndex === undefined ||
      selectedWorkDetailIndex < 0 ||
      selectedWorkDetailIndex >= updatedWorkDetails.length
    ) {
      console.error(
        "Invalid selectedWorkDetailIndex:",
        selectedWorkDetailIndex
      );
      return;
    }
    const updatedWorkDetail = {
      id: selectedWorkDetailIndex,
      title: workTitle,
      description: workDescription,
      image: workImage,
    };
    // console.log("@@@@@-->updatedWorkDetail", updatedWorkDetail);
    updatedWorkDetails[selectedWorkDetailIndex] = updatedWorkDetail;
    setWorkDetails(updatedWorkDetails);
    howItWorks(updatedWorkDetails[selectedWorkDetailIndex]);
    setWorkTitle("");
    setWorkDescription("");
    setWorkImage("");
    setHiwEditStatus(false);
    setUploadImgLocal("");
  };

  const handleDeleteClick = (index, detail) => {
    trackEvent("Profile Page", "click", "How to Works - Delete");
    delworkDetail(index);
    const updatedWorkDetails = [...workDetails];
    updatedWorkDetails.splice(index, 1);
    setWorkDetails(updatedWorkDetails);
  };

  const handleServiceOffSelect = (items) => {
    setServiceOfferedVal(items);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  function getProfileData() {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=getProfile`,
      headers: {},
      data: {
        auth_id: authId,
      },
    }).then(function (response) {
      if (response.data.login.status === 0) {
        handleLogout();
        return false;
      }

      setCompanyName(response.data.data.data.TBL_LOGIN_COMPANYNAME);
      setCompanyAddress(response.data.data.data.TBL_LOGIN_ADDRESS);
      setCompanyCity(response.data.data.data.TBL_LOGIN_CITY);
      setPincode(response.data.data.data.TBL_LOGIN_PINCODE);
      setNumberStaffVal(response.data.data.data.no_staff);
      setCompanyStatusVal(response.data.data.data.tbl_company_status);

      if (response.data.data.data.fcpdata.docstatus) {
        setDocstatus(response.data.data.data.fcpdata.docstatus);
      }

      if (response.data.data.data.fcpdata.services_offered) {
        setServiceOfferedVal(response.data.data.data.fcpdata.services_offered);
      }

      if (response.data.data.data.fcpdata.about) {
        setAboutCompany(response.data.data.data.fcpdata.about);
      }

      if (response.data.data.data.fcpdata.logo) {
        setCompanyImg(response.data.data.data.fcpdata.logo_full_path);
      }

      if (response.data.data.data.fcpdata.workDetails !== null) {
        setWorkDetails(response.data.data.data.fcpdata.workDetails);
      }

      if (response.data.data.data.fcpdata.termsAndConditions !== null) {
        setTermsAndConditions(
          response.data.data.data.fcpdata.termsAndConditions
        );
      }

      if (response.data.data.data.fcpdata.fcp_images !== null) {
        setMedia(response.data.data.data.fcpdata.fcp_images);
      }

      setDataStatus(true);
    });
  }

  const handleLogout = () => {
    // Dispatch the logout action to clear user data
    dispatch(logout());
    // Redirect to the login page
    navigate("/login");
  };

  const myProfileValidate = (
    companyName,
    companyAddress,
    companyCity,
    pincode,
    companyStatusVal
  ) => {
    const errors = {};
    errors.companyName = [];
    errors.companyAddress = [];
    errors.companyCity = [];
    errors.pincode = [];
    errors.companyStatusVal = [];
    errors.authenticate = [];
    errors.unauthenticate = [];

    if (companyCity.length === 0) {
      errors.companyCity.push("Company city is empty!");
      setErrorStatus(true);
    }

    if (companyName.length === 0) {
      errors.companyName.push("Company name is empty!");
      setErrorStatus(true);
    }

    if (companyAddress.length === 0) {
      errors.companyAddress.push("Address is empty!");
      setErrorStatus(true);
    } else {
      const addressRegex = /^[0-9a-zA-Z,.-\s#\/()&]+$/;
      const isValid = addressRegex.test(companyAddress);

      if (!isValid) {
        errors.companyAddress.push(
          "Allow some char like , . # / () & in address including 0-9,a-z"
        );
        setErrorStatus(true);
      }
    }

    if (pincode.length === 0) {
      errors.pincode.push("Pincode is empty!");
      setErrorStatus(true);
    } else {
      if (pincode.length < 4 || pincode.length > 12) {
        errors.pincode.push("Invalid Pincode: " + pincode);
        setErrorStatus(true);
      }
    }

    if (companyStatusVal.length === 0) {
      errors.companyStatusVal.push("Company Status is empty!");
      setErrorStatus(true);
    }

    const returnError = {};

    if (
      errors.companyCity.length === 0 &&
      errors.companyName.length === 0 &&
      errors.companyAddress.length === 0 &&
      errors.pincode.length === 0 &&
      errors.companyStatusVal.length === 0
    ) {
      returnError.status = false;
      setErrorStatus(false);
    } else {
      returnError.status = true;
    }

    returnError.errors = errors;

    return returnError;
  };

  const handleProfileData = async (e) => {
    e.preventDefault();
    trackEvent("Profile Page", "click", " Save Company Detail");
    setFormSubmitted(true);
    const profileData = myProfileValidate(
      companyName,
      companyAddress,
      companyCity,
      pincode,
      companyStatusVal
    );

    if (profileData.status === true) {
      setFormErrors(profileData.errors);

      if (profileData.errors.companyName.length) {
        companyNameRef.current.focus();
        return false;
      }

      if (profileData.errors.companyAddress.length) {
        companyAddressRef.current.focus();
        return false;
      }

      if (profileData.errors.pincode.length) {
        pincodeRef.current.focus();
        return false;
      }

      if (profileData.errors.companyCity.length) {
        companyCityRef.current.focus();
        return false;
      }

      if (profileData.errors.companyStatusVal.length) {
        companyStatusValRef.current.focus();
        return false;
      }

      return false;
    } else {
      axios({
        method: "post",
        url: `${user.base_url}/subuser/myaccount.php?fun=profileupdate`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: {
          // fun:"profileupdate",
          auth_id: authId,
          companyName: companyName,
          companyAddress: companyAddress,
          companyCity: companyCity,
          companyStatus: companyStatusVal,
          pincode: pincode,
          numberStaff: numberStaffVal,
          aboutCompany: aboutCompany,
          termsAndConditions: termsAndConditions,
          workDetails: workDetails,
          mediaFile: mediaFile,
          serviceOfferedVal: serviceOfferedVal,
        },
      })
        .then(function (response) {
          toast.success("Successfully Updated");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function howItWorks(workDetails) {
    
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=howItWorks`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: {
        auth_id: authId,
        workDetails: workDetails,
      },
    })
      .then(function (response) {
        getProfileData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function delworkDetail(workDetailsid) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=howItWorksDelete`,
      headers: {},
      data: {
        auth_id: authId,
        id: workDetailsid,
      },
    })
      .then(function (response) {})
      .catch((error) => {
        console.log(error);
      });
  }

  function addTNCData(tncDetails) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=addTncData`,
      headers: {},
      data: {
        auth_id: authId,
        tncDetails: tncDetails,
      },
    })
      .then(function (response) {})
      .catch((error) => {
        console.log(error);
      });
  }
  function removeTNCData(index) {
    axios({
      method: "post",
      url: `${user.base_url}/subuser/myaccount.php?fun=removeTncData`,
      headers: {},
      data: {
        auth_id: authId,
        indexremove: index,
      },
    })
      .then(function (response) {})
      .catch((error) => {
        console.log(error);
      });
  }

  const gotoNextPage = () => {
    setKycTab(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleSelectTab("KYC");
  };

  return (
    <div className="account-details df w66 fdc settings main-content-padding">
      <div className="v-center jcsb">
        <h3 className="fc1 fw5 fs20 mb24">
          Company Profile
          <div className="fs14 fc16 mt8 fw4 lh16">
            Set or edit your company information for a professional presence
          </div>
        </h3>
      </div>
      {dataStatus === true && (
        <div className="upload-image v-center">
          <p className="flx1 fc14 fw5 fs14 mb12 ls1">Upload Company Logo</p>
          <div className="image-upload-compoenent">
            <ImageUpload
              authId={authId}
              baseUrl={baseUrl}
              imageUrl={companyImg}
              siteUrl={user.img_url}
              folder="logo/"
              uploadUrl="/subuser/myaccount.php?fun=uploadImg&type=logo"
              deleteUrl="/subuser/myaccount.php?fun=deleteImg&type=logo"
              setWorkImage={setCompanyImg}
              delimg={true}
              toast={toast}
              eventLabel={"Upload Company Logo"}
              eventCategory={"Profile Page"}
              uploadImgLocal={uploadImgLocal}
              setUploadImgLocal={setUploadImgLocal}
            />
          </div>
        </div>
      )}

      <div className="form-group-settings">
        <p className="fc14 fw5 fs14 ls1">Company Name </p>
        <input
          ref={companyNameRef}
          type="text"
          placeholder="Enter Company Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          readOnly={companyName ? true : false}
        />
        {formSubmitted && (
          <div className="form-error-messages error mt10 blink-text-normal-error">
            {Object.keys(formErrors).map((key) => (
              <div key={key}>
                {key === "companyName" &&
                  formErrors[key].map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="form-group-settings">
        <p className="fc14 fw5 fs14 ls1">Company Address</p>
        <input
          ref={companyAddressRef}
          type="text"
          placeholder="Enter Company Address"
          value={companyAddress}
          onChange={(e) => setCompanyAddress(e.target.value)}
          readOnly={docstatus === "1" && companyAddress ? true : false}
        />

        {formSubmitted && (
          <div className="form-error-messages error mt10 blink-text-normal-error">
            {Object.keys(formErrors).map((key) => (
              <div key={key}>
                {key === "companyAddress" &&
                  formErrors[key].map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="form-group-settings">
        <label htmlFor="gender" className="fc14 fw5 fs14 mb12 ls1">
          Company City
        </label>
        <input
          ref={companyCityRef}
          type="text"
          placeholder="Enter City"
          value={companyCity}
          onChange={(e) => setCompanyCity(e.target.value)}
          readOnly={docstatus === "1" && companyCity ? true : false}
        />

        {formSubmitted && (
          <div className="form-error-messages error mt10 blink-text-normal-error">
            {Object.keys(formErrors).map((key) => (
              <div key={key}>
                {key === "companyCity" &&
                  formErrors[key].map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="form-group-settings">
        <p className="fc14 fw5 fs14 ls1">Pin Code/ Zip Code</p>
        <input
          ref={pincodeRef}
          type="text"
          placeholder="Enter Pin Code"
          value={pincode}
          onChange={(e) => setPincode(e.target.value)}
          readOnly={docstatus === "1" && pincode ? true : false}
        />

        {formSubmitted && (
          <div className="form-error-messages error mt10 blink-text-normal-error">
            {Object.keys(formErrors).map((key) => (
              <div key={key}>
                {key === "pincode" &&
                  formErrors[key].map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="form-group-settings">
        <label htmlFor="gender" className="fc14 fw5 fs14 mb12 ls1">
          Company Status
        </label>
        <div className="role-dropdown">
          <div
            ref={companyStatusValRef}
            className="selected-role fs14"
            value={companyStatusVal}
            onClick={() =>
              setShowCompanyStatusDropdown(!showCompanyStatusDropdown)
            }
          >
            {/* {companyStatus || "Select Company Status"}{" "} */}
            {companyStatusOptions[companyStatusVal] ||
              "Select Company Status"}{" "}
            <FaAngleDown className="fc16 fs14" />
          </div>
          {showCompanyStatusDropdown && (
            <ul className="role-options fs14">
              {Object.entries(companyStatusOptions).map(([key, value]) => (
                <li
                  key={key}
                  value={value}
                  onClick={() => handleCompanyStatusSelect(key, value)}
                >
                  {value}
                </li>
              ))}
            </ul>
          )}
        </div>

        {formSubmitted && (
          <div className="form-error-messages error mt10 blink-text-normal-error">
            {Object.keys(formErrors).map((key) => (
              <div key={key}>
                {key === "companyStatusVal" &&
                  formErrors[key].map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="form-group-settings mutli-select">
        <p className="fc14 fw5 fs14 mb12 ls1">Service Offered</p>

        <MultiSelect
          travelDestination={serviceofferedOptions}
          placeholder={"Service Offered"}
          onSave={handleServiceOffSelect}
          selectedItems={serviceOfferedVal} // Pass selected languages as a prop
          setSelectedItems={setServiceOfferedVal}
        />
      </div>

      <div className="form-group-settings">
        <label htmlFor="gender" className="fc14 fw5 fs14 mb12 ls1">
          No of Staffs
        </label>
        <div className="role-dropdown">
          <div
            ref={staffRef}
            className="selected-role fs14"
            onClick={() => setShowStaffDropdown(!showStaffDropdown)}
          >
            {/* {numberStaffVal || "Select No of Staffs"}{" "} */}
            {staffOptions[numberStaffVal] || "Select No of Staffs"}{" "}
            <FaAngleDown className="fc16 fs14" />
          </div>
          {showStaffDropdown && (
            <ul
              className="role-options fs14"
              //value={numberStaffVal}
            >
              {Object.entries(staffOptions).map(([key, value]) => (
                <li
                  key={key}
                  value={value}
                  onClick={() => handleStaffSelect(key, value)}
                >
                  {value}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className="form-group-settings">
        <p className="fc14 fw5 fs14 ls1">About Company</p>

        <textarea
          id="about_company"
          placeholder="About Company"
          value={aboutCompany}
          onChange={(e) => setAboutCompany(e.target.value)}
          style={{
            height: "120px",
            padding: "10px",
            marginTop: "8px",
            resize: "none",
            width: "100%",
            border: "1px solid #ccc",
          }}
        />
      </div>

      <div className="v-center jce born mb24">
        <button
          type="button"
          className="btn-blue bg1 br24 fs14 cp pl16 pr16 pt10 pb10"
          onClick={handleProfileData}
        >
          Save Company Details
        </button>
      </div>

      <div className="how-it-works brd-t1">
        <div className="v-center jcsb ">
          <p className="fc1 fw5 fs20 mt24 mb24">
            How it works
            <div className="fs14 fc16 mt8 fw4 lh16">
              Enhance Your Profile: Showcase your expertise by adding a 'How It
              Works' section
            </div>
          </p>
          {/* <Toggle /> */}
        </div>
        <div className="work-steps df fdc">
          {workDetails.map((detail, index) => (
            <div key={index}>
              <div className="steppings fc1 fw6 fs14 ls2">Step {index + 1}</div>

              <div
                key={index}
                className="df jcsb mt16 mb16 brd-b1 pb16 term-item"
              >
                <div className="flx33 box-center show-work-image image-preview ">
                  {detail.image && (
                    <img
                      src={detail.image.url ? detail.image.url : detail.image}
                      alt={
                        detail.image.name === undefined
                          ? ""
                          : `Work ${detail.image.name}`
                      }
                    />
                  )}
                </div>
                <div className="df jcsb flx1 show-work-text ">
                  <div className="df fdc  show-work-description pl12">
                    <p className="steppings mb12 fs14 ls2 fw5">
                      {detail.title}
                    </p>
                    <p className="steppings fs14 ls2">{detail.description}</p>
                  </div>
                  <div className="icons">
                    <span
                      className="edit-icon"
                      onClick={() => handleEditClick(index, detail)}
                    >
                      <FaPencilAlt className="edit-button cp mr8" />
                    </span>
                    <span
                      className="delete-icon"
                      onClick={() => handleDeleteClick(index, detail)}
                    >
                      <BsTrash className="delete-button cp fc4" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {(workDetails.length < 3 || hiwEditStatus) && (
            <div className="how-it-worksgrp df jcsb">
              <div className="work-left df fdc flx50 aiss">
                <div className="steppings mb12 fc1 fw6 fs14 ls2">
                  {hiwEditStatus
                    ? `Edit Step ${editWorkId + 1}`
                    : `Step ${workDetails.length + 1}`}
                </div>
                <div className="input-label">
                  <label htmlFor="title" className="fs14 ls1 fw5 fc15">
                    Title
                  </label>
                  <input
                    type="text"
                    id="title"
                    placeholder="Title"
                    value={workTitle}
                    onChange={(e) => setWorkTitle(e.target.value)}
                    className="mb16 mt8"
                  />
                </div>
                <div className="input-label">
                  <label htmlFor="description" className="fs14 ls1 fw5 fc15 ">
                    Description
                  </label>
                  <textarea
                    id="description"
                    placeholder="Description"
                    value={workDescription}
                    onChange={(e) => setWorkDescription(e.target.value)}
                    style={{
                      height: "80px",
                      padding: "10px",
                      marginTop: "8px",
                      resize: "none",
                    }}
                  />
                </div>
              </div>

              <div className="work-right df aiss jcc mt32">
                {hiwEditStatus ? (
                  <ImageUpload
                    setWorkImage={setWorkImage}
                    imageUrl={workImage}
                    toast={toast}
                    siteUrl={siteUrl}
                    folder={folder}
                    editStatus={hiwEditStatus}
                    uploadImgLocal={uploadImgLocal}
                    setUploadImgLocal={setUploadImgLocal}
                  />
                ) : (
                  <ImageUpload
                    setWorkImage={setWorkImage}
                    imageUrl={workImage}
                    toast={toast}
                    uploadImgLocal={uploadImgLocal}
                    setUploadImgLocal={setUploadImgLocal}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        {(workDetails.length < 3 || hiwEditStatus) && (
          <div className="v-center jce born mt16  brd-b1">
            {hiwEditStatus ? (
              <button
                className={`btn-blue bg1 br24 fs14 cp pl16 pr16 pt10 pb10 mb24`}
                onClick={handleUpdateClick}
              >
                Update
              </button>
            ) : (
              <button
                className={`btn-blue bg1 br24 fs14 cp pl16 pr16 pt10 pb10 mb24`}
                onClick={handleAddClick}
              >
                Add
              </button>
            )}
          </div>
        )}
      </div>

      <div className="tnc">
        <h3 className="fc1 fw5 fs20 mt24 mb24">
          Terms & Conditions
          <div className="fs14 fc16 mt8 fw4 lh16">
            Centralized Compliance: Add Terms & Conditions once for all your
            itineraries
          </div>
        </h3>
        {dataStatus && (
          <Tnc
            termsAndConditions={termsAndConditions}
            setTermsAndConditions={setTermsAndConditions}
            insertData={addTNCData}
            removeTNCData={removeTNCData}
          />
        )}
      </div>

      {dataStatus === true && (
        <div className="brd-t1 pt32">
          <h3 className="fc1 fw5 fs20 mb24">
            Media Gallery
            <div className="fs14 fc16 mt8 fw4 lh16">
              Upload multiple images and videos for your profile
            </div>
          </h3>
          <ImageGallery
            media={media}
            setMedia={setMedia}
            mediaFile={mediaFile}
            setMediaFile={setMediaFile}
            dataStatus={dataStatus}
            baseUrl={baseUrl}
            authId={authId}
            uploadUrl="/subuser/myaccount.php?fun=uploadImg&type=gallary"
            deleteUrl="/subuser/myaccount.php?fun=deleteImg&type=gallary"
            toast={toast}
            eventLabel={"Upload Gallery Image"}
            eventCategory={"Profile Page"}
          />
        </div>
      )}
      <div className="v-center jce born mt24 mb24">
        {/*
        <button type="button" className="btn-cancel cp">
          Cancel
        </button>*/}
        <button
          type="button"
          className="btn-blue bg1 br24 fs14 cp pl16 pr16 pt10 pb10"
          onClick={gotoNextPage}
        >
          Save Changes
        </button>
      </div>
      <div>
        <ToastContainer position="bottom-right" className="mt70" />
      </div>
    </div>
  );
};

export default Profile;
