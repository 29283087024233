import React, { useState, useEffect } from "react";
import "./Dropdown.css"; 

const Dropdown = ({ label, options, selectedValue,onValueChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(label);

  useEffect(() => {
    setSelectedLabel(selectedValue);
  }, [selectedValue]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (label,value) => {
    onValueChange(label,value);
    setIsOpen(false);
    setSelectedLabel(label);
    
  };

  return (
    <div className="dropdown-container">
      <div className="dropdown-label" onClick={toggleDropdown}>
        {selectedLabel} 
        <span className={`custom-arrow ${isOpen ? "open" : ""}`}></span>
      </div>
      {isOpen && (
        <div className="dropdown-options">
          {options.map((option) => (
            <div
              key={option.value}
              className={`dropdown-option ${
                selectedValue === option.value ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option.label,option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;