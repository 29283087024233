import React from "react";
import { AiFillCalendar, AiFillStar, AiOutlineStar } from "react-icons/ai";
import defaultHotel from "../../assets/hoteldefault.jpg";
import { FaExchangeAlt } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import publicImg from "../../assets/public.png";

const HotelCard = ({
  hotelData,
  onHotelChangeClick,
  onHotelChange,
  onHotelDeleteClick,
}) => {
  const renderStars = (starRating) => {
    const filledStars = Array.from({ length: starRating }, (_, index) => (
      <AiFillStar key={index} className="fc6" />
    ));
    const outlineStars = Array.from({ length: 5 - starRating }, (_, index) => (
      <AiOutlineStar key={index} />
    ));

    return (
      <p className="">
        {filledStars}
        {outlineStars}
      </p>
    );
  };
  return (
    <>
      {hotelData.map((hotel) => (
        <div
          className="brd1 pt16 pb16 pl8 pr4 df mb16 w100 select-itinenary-card"
          key={hotel.id}
        >
          <div className="left-image w33">
            <div className="image-contain pr">
              <img
                src={
                  hotel.hotel_image
                    ? hotel.hotel_image
                    : hotel.in_transit === "1"
                    ? publicImg
                    : "https://www.hellotravel.com/hellotravel/images/slider/image-not-uploaded.jpg"
                }
                alt={hotel.city_name}
              />
            </div>
          </div>
          <div className="pl16 w100 hotel-rightcard-text">
            <div className="df jcsb">
              <div className="v-center">
                <p className="fc1 fw6 fs16 ls1 lh18">
                  {hotel.hotel_name ? hotel.hotel_name : hotel.in_transit === "1"? "In Transit":"Hotel Name"}
                </p>
              </div>
              <div className="df">
                <p
                  className="fs16 fw5 cp ls1 pr8"
                  onClick={() => {
                    onHotelChangeClick();
                    onHotelChange(hotel);
                  }}
                >
                  <FaExchangeAlt />
                </p>
                <p
                  className="fs16 fw5 cp ls1 pr8"
                  onClick={() => {
                    onHotelDeleteClick(hotel.id);
                  }}
                >
                  <BsTrash />
                </p>
              </div>
            </div>
            <div className="v-center mt4">
              {renderStars(parseInt(hotel.hotel_type, 10))}
            </div>
            <div className="fs14 fw5 ls1 lh18 fc16 mt4">{hotel.hotel_city}</div>
            <div className="fs14 pt24 fc5 ls1 lh22">
              {hotel.includes !== "" && (
                <p>
                  Includes: <span>{hotel.includes}</span>
                </p>
              )}
              {hotel.room_type !== "" && (
                <p>
                  Room Type: <span>{hotel.room_type}</span>
                </p>
              )}
            </div>
            {hotel.in_transit === "1" && (
              <p className="fs14 fc5 ls1 lh22">
                During this period, the traveler will travel by various means of
                transport like car, bus, train or flight
              </p>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default HotelCard;
