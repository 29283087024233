import axios from "axios";
import React, { useState, useEffect } from "react";
import { TiTick } from "react-icons/ti";
import { Link } from "react-router-dom";
import logoImage from "../assets/hellotravel-logo.png";
import google from "../assets/google.png";
import loginImage from "../assets/login.jpg";
import microsoft from "../assets/microsoft.png";
import "../pages/Login.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setUser } from "../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SmallLoader from "../components/SmallLoader";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import { useParams } from "react-router-dom";
import { useTitle } from "../hooks/useTitle";
import trackEvent from "../utils/gaTracker";

const Login = () => {
  const { id } = useParams();
  useTitle(
    "Login - Get Verified Travel Enquiries for Free, Promote your travel business - Hellotravel"
  );

  const user = useSelector((state) => state.auth);
  const [contactName, setContactName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [errorStatus, setErrorStatus] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    const label = showPassword ? "Hide Password" : "Show Password";
    trackEvent("Login Page", "click", label);
  };

  const [gLoginUser, setGLoginUser] = useState([]);
  const [gLoginProfile, setgLoginProfile] = useState([]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setGLoginUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
      trackEvent("Login Page", "click", "Login with Email");
    }
  };

  function handleCheckLogin(auth_id) {
    axios({
      method: "post",
      url: `${user.base_url}/login.php?fun=getUser`,
      headers: {},
      data: {
        auth_id: auth_id,
      },
    }).then(function (response) {
      if (response) {
        if (response.data.login.status == "1") {
          navigate("/");
        }
      }
    });
  }

  useEffect(() => {
    if (user.auth_id !== null) {
      handleCheckLogin(user.auth_id);
    }
    if (id) {
    var getSavedLocalData = (localStorage.getItem('htloginDetails'));
      axios({
        method: "post",
        url: `${user.base_url}/autologin.php?fun=autologin`,
        headers: {},
        data: {
          id: id,
	      getSavedLocalData:getSavedLocalData,	
        },
      }).then(function (response) {
        if (response.data.login.status === 1) {
          const verifyUser = response.data;
          const redirectpage = verifyUser.data.logintype;
          const verifyDetail = verifyUser.login;

          if (redirectpage === "invitaion_descline") {
            navigate("/");
          } else {
            if (verifyDetail && verifyDetail.userid !== "") {
              // Dispatch the user data to Redux store
              dispatch(setUser(verifyDetail));
              localStorage.setItem(
                "htloginDetails",
                JSON.stringify(verifyDetail)
              );
              // Redirect to the desired page after successful login

              if (redirectpage === "email_verify") {
                navigate("/my-account");
              } else if (redirectpage === "forgot_password") {
                navigate("/my-account?tab=Settings&section=newpass");
              } else if (redirectpage === "invitaion_accept") {
                navigate("/my-account?tab=Settings");
              }else if(redirectpage=='buylead'){
                navigate("/");
              } else if(redirectpage){
		navigate("/"+redirectpage);
	      }
            }
          }
        } else {
          const verifyUser = response.data;
          if (verifyUser.data.logintype == "invitaion_descline") {
            const redirectpage = verifyUser.data.logintype;
            if (redirectpage === "invitaion_descline") {
              navigate("/");
            }
          }
        }
      });
    }

    if (gLoginUser) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${gLoginUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${gLoginUser.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setgLoginProfile(res.data);
          createGoogleUser(gLoginUser, res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [gLoginUser, id]);

  useEffect(() => {
    const emailInput = document.getElementById("emailInput");
    localStorage.removeItem('allfilteroption');
    localStorage.removeItem('selectedTab');	
    localStorage.removeItem('filterValue');	
    localStorage.removeItem('selectedFilter');	
    if (emailInput) {
      emailInput.focus();
    }
  }, []);
  // function createGoogleUser(gLoginUser,gLoginProfile){

  const createGoogleUser = async (gLoginUser, gLoginProfile) => {
    if (gLoginProfile) {
      setContactName(gLoginProfile.name);
      setEmail(gLoginProfile.email);
    }

    // Simulate an asynchronous login process
    try {
      axios
        .post(`${user.base_url}/subuser/google.php?fun=create`, {
          userdata: gLoginUser,
          userprofile: gLoginProfile,
        })
        .then(function (response) {
          const loginGoogleUser = response.data;

          if (loginGoogleUser.data.status == 2) {
            const loginErrors = blankErrors();
            loginErrors.status = true;

            //navigator.navigate();
            navigate("/signup", {
              state: {
                name: loginGoogleUser.data.data.userprofile.name,
                email: loginGoogleUser.data.data.userprofile.email,
              },
            });
            //naviga("/signup",{"hello":"hello"});
          } else if (loginGoogleUser.data.status == 1) {
            const loginErrors = blankErrors();
            loginErrors.status = true;

            const loginUser = loginGoogleUser.data.login;

            loginErrors.errors.authenticate.push("You are Successfully Login");
            setFormErrors(loginErrors.errors);
            if (loginUser && loginUser.userid !== "") {
              dispatch(setUser(loginUser));
              localStorage.setItem("htloginDetails", JSON.stringify(loginUser));
              navigate("/");
            } else {
              loginErrors.status = true;
              loginErrors.errors.authenticate.push("Invalid User");
              setFormErrors(loginErrors.errors);
            }
          } else {
            const loginErrors = blankErrors();
            loginErrors.status = false;

            loginErrors.errors.authenticate.push("Invalid User");
            setFormErrors(loginErrors.errors);
            // Handle unsuccessful login here (e.g., show an error message)
          }
        });
    } catch (error) {
      // Handle API request errors here (e.g., show an error message)

      console.error("API request failed:", error);
    } finally {
    }
  };

  const blankErrors = () => {
    const errors = {};
    errors.email = [];
    errors.password = [];
    errors.authenticate = [];
    errors.unauthenticate = [];
    const returnError = {};
    //returnError.status=true;

    returnError.errors = errors;
    returnError.status = false;
    return returnError;
  };

  const loginValidate = (email, password) => {
    const errors = {};
    errors.email = [];
    errors.password = [];
    errors.authenticate = [];
    errors.unauthenticate = [];

    if (email.length === 0) {
      errors.email.push("Email is empty!");
      setErrorStatus(true);
    }

    if (password.length === 0) {
      errors.password.push("Password is empty!");
      setErrorStatus(true);
    }

    const returnError = {};

    if (errors.email.length === 0 && errors.password.length === 0) {
      returnError.status = false;
      setErrorStatus(false);
    } else {
      returnError.status = true;
    }

    returnError.errors = errors;

    return returnError;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    trackEvent("Login Page", "click", "Login with Email");

    setLoading(true);

    setFormSubmitted(true);

    const loginErrors = loginValidate(email, password);

    if (loginErrors.status === true) {
      setFormErrors(loginErrors.errors);
      setLoading(false);
      return false;
    } else {
      // Simulate an asynchronous login process
      try {
        const response = await axios.post(`${user.base_url}/login.php`, {
          username: email,
          password: password,
        });

        const loginUser = response.data.login;

        if (loginUser && loginUser.userid !== "") {
          // loginErrors.errors.authenticate.push("You are SuccessFully Login");
          // setFormErrors(loginErrors.errors);

          // Dispatch the user data to Redux store
          dispatch(setUser(loginUser));
          localStorage.setItem("htloginDetails", JSON.stringify(loginUser));
          // Redirect to the desired page after successful login
          navigate("/");
        } else {
          loginErrors.errors.unauthenticate.push("Invalid email or password");
          setFormErrors(loginErrors.errors);
          setLoading(false);
          return false;
          // Handle unsuccessful login here (e.g., show an error message)
        }
      } catch (error) {
        // Handle API request errors here (e.g., show an error message)
        loginErrors.errors.unauthenticate.push(
          "Something went wrong try again"
        );
        setFormErrors(loginErrors.errors);
        setLoading(false);
        return false;
        //console.error("API request failed:", error);
      } finally {
      }
    }
    setLoading(false);
  };

  return (
    <div className="login-container box-center ">
      <div className="login-left-side-image pr">
        {/* <div className="slider-logo">
          <img src={logoImage} alt="logo" className="slider-company-logo" />
        </div> */}
        <img src={loginImage} alt="login" className="imgrad" />
        <div className="pa box-center fdc fc3 login-left-text w100">
          <div className="fc3 fs20 fw6 mb8 lh22">
            Meet with Your Next Traveler Today!
          </div>
          <ul className="fc3 fs14 fw5 ls2 lh18">
            <li>
              <TiTick /> &nbsp;Automate your entire inbound sales process
            </li>
            <li className="mt4">
              <TiTick /> &nbsp;Convert more inquiries, and sell more tours
            </li>
            <li className="mt4">
              <TiTick /> &nbsp;Enjoy FREE package-based inquiries
            </li>
            <li className="mt4">
              <TiTick /> &nbsp;Free enquiry management tool
            </li>
          </ul>
        </div>
      </div>
      <div className="login-right-side-form">
        <div className="pd20 w100">
          <div className="box-center login-head fdc">
            <img src={logoImage} alt="logo" className="slider-company-logo" />
            <h3 className="fc15 fw5 fs20 ls1 tac mt12">
              Login
              <div className="fs14 fc16 mt12 fw4 ls1">
                Welcome to the HelloTravel family!
              </div>
            </h3>
          </div>
          <form className="mt24 mb48 login-forms">
            <div className="social-form-group box-center">
              <button
                className=" sign-in-microsoft ls2 flx45"
                onClick={(e) => {
                  e.preventDefault();
                  login();
                  trackEvent("Login Page", "click", "Sign in with Gmail")
                }}
              >
                <img src={google} alt="google" className="mr8 fc1" />
                Sign in with Google
              </button>
            </div>
            <div className="hr-line pr mt36 mb32">
              <hr
                style={{
                  height: 1,
                  backgroundColor: "#9fa5bc",
                  border: "none",
                }}
              />

              <span
                className="fc17 tac"
                style={{
                  position: "absolute",
                  top: "-12px",
                  left: 0,
                  width: "100%",
                }}
              >
                <span style={{ background: "#fff", padding: 20 }}>or</span>
              </span>
            </div>
            <div className="col-xl-12">
              <input
                type="text"
                name="email"
                className="form-control br4"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                onKeyDown={handleKeyDown}
                id="emailInput"
              />
            </div>
            {formSubmitted && (
              <div className="form-error-messages error mt4 ml4 blink-text-normal-error">
                {Object.keys(formErrors).map((key) => (
                  <div key={key}>
                    {key === "email" &&
                      formErrors[key].map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                  </div>
                ))}
              </div>
            )}
            <div className="col-xl-12 pr mt12 password-input">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control br4"
                autoComplete="current-password"
                onKeyDown={handleKeyDown}
              />
              <span onClick={togglePasswordVisibility}>
                {!showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {formSubmitted && (
              <div className="form-error-messages error mt4 ml4 blink-text-normal-error">
                {Object.keys(formErrors).map((key) => (
                  <div key={key}>
                    {key === "password" &&
                      formErrors[key].map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                  </div>
                ))}
              </div>
            )}
            <Link
              to="/forgot-password"
              className="df jce cp fc16 fs10 fw5 lh14 ls1 mt8"
              onClick={() =>
                trackEvent("Login Page", "click", "Forgot Password")
              }
            >
              Forgot Password?
            </Link>
            {formSubmitted && (
              <div className="form-error-messages error  mb8 blink-text-success">
                {Object.keys(formErrors).map((key) => (
                  <div key={key}>
                    {key === "authenticate" &&
                      formErrors[key].map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                  </div>
                ))}
              </div>
            )}
            {formSubmitted && (
              <div className="form-error-messages error  mb8 ml4 blink-text-normal-error">
                {Object.keys(formErrors).map((key) => (
                  <div key={key}>
                    {key === "unauthenticate" &&
                      formErrors[key].map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                  </div>
                ))}
              </div>
            )}
            <div className="box-center">
              <button
                className="login-email box-center mt16 lh22 ls1 cp flx33"
                onClick={handleLogin}
              >
                {loading ? <SmallLoader /> : "Login with email"}
              </button>
            </div>
          </form>

          <p className="box-center ls1 fc16">
            New User?
            <Link
              to="/signup"
              className="fc1 ml4 fs14 ls1"
              onClick={() =>
                trackEvent("Login Page", "click", " Sign up")
              }
            >
              Signup Here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
